import React from 'react';
import { Box, Typography, Chip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import StarBorderIcon from '@mui/icons-material/StarBorder';

const ShortcutBox = ({ icon, title, subtitle, path, label, diffView = false }) => {
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';
  const isAllakando = theme.palette.themeType === 'allakando';

  const getBackgroundColor = () => {
    if (isAllakando) return theme.palette.background.card;
    return isDark ? 'rgba(255, 255, 255, 0.05)' : 'rgba(223, 222, 225, 0.19)';
  };

  const getIconColor = () => {
    if (isAllakando) return theme.palette.primary.main;
    return isDark ? 'rgba(255, 255, 255, 0.7)' : '#8b5cf6';
  };

  const getHoverBackgroundColor = () => {
    if (isAllakando) return theme.palette.background.cardHover;
    return isDark ? 'rgba(255, 255, 255, 0.1)' : 'rgba(139, 92, 246, 0.1)';
  };

  const getHoverIconColor = () => {
    if (isAllakando) return theme.palette.primary.hover;
    return isDark ? 'rgb(16, 151, 218)' : '#6b46c1';
  };

  const getLabelStyle = labelText => {
    if (isAllakando) {
      return {
        backgroundColor: theme.palette.label.background,
        color: theme.palette.label.text,
      };
    }

    if (labelText === 'NYTT') {
      return {
        backgroundColor: isDark ? '#2B4C2A' : '#C5F2B8',
        color: isDark ? '#3bbd28' : '#249314',
      };
    }

    if (labelText === 'UPPDATERAD') {
      return {
        backgroundColor: isDark ? '#4C2B4C' : '#F2B8F2',
        color: isDark ? '#bd28bd' : '#931493',
      };
    }

    return {
      backgroundColor: isDark ? '#2a3b4c' : '#e0e7ff',
      color: isDark ? '#60a5fa' : '#3b82f6',
    };
  };

  return (
    <Link to={path} style={{ textDecoration: 'none' }}>
      <Box
        sx={{
          backgroundColor: getBackgroundColor(),
          borderRadius: '12px',
          padding: '16px',
          cursor: 'pointer',
          height: diffView ? '185px' : '100px',
          transition: 'all 0.3s ease',
          position: 'relative',
          border: isAllakando ? undefined : isDark ? undefined : '1px solid #e0e0e080',
          '&:hover': {
            backgroundColor: getHoverBackgroundColor(),
            transform: 'translateY(-2px)',
            '& .icon, & .star': {
              color: getHoverIconColor(),
            },
          },
        }}
      >
        {label && (
          <Chip
            label={label}
            size='small'
            sx={{
              position: 'absolute',
              top: '8px',
              right: '8px',
              fontWeight: 'bold',
              fontSize: isAllakando ? '0.8rem' : '0.7rem',
              height: '20px',
              ...getLabelStyle(label),
            }}
          />
        )}
        <StarBorderIcon
          className='star'
          sx={{
            position: 'absolute',
            bottom: '8px',
            right: '8px',
            fontSize: 23,
            color: isDark ? 'rgba(255, 255, 255, 0.5)' : 'rgba(255, 255, 255, 0.5)',
            transition: 'color 0.3s ease',
          }}
        />
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: diffView ? 'column' : 'row',
            alignItems: 'center',
            justifyContent: diffView ? 'center' : 'flex-start',
          }}
        >
          {typeof icon === 'string' ? (
            <img
              src={icon}
              alt={title}
              style={{
                width: 35,
                height: 35,
                objectFit: 'cover',
                borderRadius: '50%',
                marginRight: diffView ? 0 : 16,
                marginBottom: diffView ? 8 : 0,
              }}
            />
          ) : (
            React.cloneElement(icon, {
              sx: {
                fontSize: 40,
                color: getIconColor(),
                mr: diffView ? 0 : 2,
                mb: diffView ? 1 : 0,
                transition: 'color 0.3s ease',
              },
              className: 'icon',
              style: {
                marginBottom: diffView ? '8px' : 0,
                transition: 'color 0.3s ease',
              },
              size: 40,
              color: getIconColor(),
            })
          )}
          <Box
            sx={{
              textAlign: diffView ? 'center' : 'left',
              display: 'flex',
              flexDirection: 'column',
              alignItems: diffView ? 'center' : 'flex-start',
            }}
          >
            <Typography
              variant='subtitle1'
              sx={{
                color: isAllakando
                  ? theme.palette.text.primary
                  : isDark
                    ? 'rgba(255, 255, 255, 0.7)'
                    : '#2c2f33',
                fontWeight: 600,
                fontSize: diffView ? 19 : isAllakando ? 17 : 15,
                marginTop: diffView ? '5px' : undefined,
              }}
            >
              {title}
            </Typography>
            {subtitle && (
              <Typography
                variant='body2'
                sx={{
                  color: isAllakando
                    ? theme.palette.text.primary
                    : isDark
                      ? 'rgba(255, 255, 255, 0.5)'
                      : '#718096',
                  fontSize: isAllakando && !diffView ? 15 : 14,
                  marginTop: diffView ? '10px' : undefined,
                  width: diffView ? '80%' : undefined,
                }}
              >
                {subtitle}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Link>
  );
};

export default ShortcutBox;
