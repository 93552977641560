import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  Grid,
  Avatar,
  Divider,
  CircularProgress,
  IconButton,
  Paper,
  FormControl,
  Select,
  Tooltip,
  MenuItem,
  TextField,
} from '@mui/material';
import GenerateIcon from '@mui/icons-material/AutoAwesome';
import ImageIcon from '@mui/icons-material/Image';
import RefreshIcon from '@mui/icons-material/Refresh';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { callApi } from './api';
import { useTheme } from '@mui/material/styles';
import { getStyles } from '../index';
import { generateAndProcessImage, trackLesson } from './functions';
import { OrbitProgress } from 'react-loading-indicators';
import { useSnackbar } from './SnackbarHandler';
import LanguageSelector from './LanguageSelector';
import PrintIcon from '@mui/icons-material/Print';
import { handlePrintLetterBox } from './printFunctions'; // Adjust the import path as needed
import { TbChartGridDotsFilled } from 'react-icons/tb';
import { ThemeSelector } from './ThemeSelector';
import ImageGenerationModal from './ImageGenerationModal';
import GradeSelector, { gradeInstructions } from './GradeSelector';
import EditIcon from '@mui/icons-material/Edit';

import Logger from './Logger';
import LessonDrawer from './LessonDrawer';
import SaveLessonDialog from './SaveLessonDialog';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import HistoryIcon from '@mui/icons-material/History';
import HoverableImage from './HoverableImage';

import { ThemeSelectorFlow } from './ThemeSelectorFlow';

import {
  Science as ScienceIcon,
  Castle as CastleIcon,
  Psychology as PsychologyIcon,
  Pets as PetsIcon,
  Public as PublicIcon,
  EmojiEvents as EmojiEventsIcon,
  Museum as MuseumIcon,
  Nature as NatureIcon,
} from '@mui/icons-material';
import MenuBookIcon from '@mui/icons-material/MenuBook';

import InfoBox from './InfoBox';
const LetterBoxLesson = ({ user }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);

  const [words, setWords] = useState([]);
  const [selectedLetters, setSelectedLetters] = useState({});
  const [isGeneratingLesson, setIsGeneratingLesson] = useState(false);
  const [isGeneratingImages, setIsGeneratingImages] = useState(false);
  const [generatedImages, setGeneratedImages] = useState(new Set());
  const [themeColor, setThemeColor] = useState('#8a59f4');
  const { showSnackbar } = useSnackbar();
  const [grade, setGrade] = useState('åk6');
  const theme = useTheme();
  const styles = getStyles(theme);
  const [language, setLanguage] = useState('svenska');
  // New configuration states
  const [themeType, setThemeType] = useState('generateWithAi');
  const [customTheme, setCustomTheme] = useState('');
  const [wordCount, setWordCount] = useState(5);
  const [extraLettersCount, setExtraLettersCount] = useState(30);

  const [showImageModal, setShowImageModal] = useState(false);
  const [imageInstructions, setImageInstructions] = useState('');
  const [imageGenerationType, setImageGenerationType] = useState(null);

  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isEditingSubtitle, setIsEditingSubtitle] = useState(false);
  const [titleText, setTitleText] = useState('Bokstavslådor');
  const [generatingImageIndex, setGeneratingImageIndex] = useState(null);

  const [lessonTheme, setLessonTheme] = useState('generateWithAi');
  const [predefinedTheme, setPredefinedTheme] = useState(null);
  const lessonTitleRef = useRef(null); // Add this ref
  const transcriptRef = useRef('');
  const uploadedFileRef = useRef('');

  const wikipediaContentRef = useRef('');
  const randomizedContentRef = useRef('');
  const coursePlanContentRef = useRef('');

  const additionalThemes = [
    { type: 'subheader', label: 'Teman som lämpar sig extra bra för denna lektionstyp:' },
    {
      value: 'selma',
      label: 'Selma Lagerlöf',
      content: 'Gör en lektion om selma lagerlöf',
      Icon: MenuBookIcon,
    },
    {
      value: 'dronten',
      label: 'Dronten',
      content: 'Gör en lektion om den utdöda arten dronten',
      Icon: PetsIcon,
    },
    {
      value: 'vikingar',
      label: 'Vikingar',
      content: 'Gör en lektion om vikingatiden',
      Icon: CastleIcon,
    },
    {
      value: 'rymden',
      label: 'Rymden',
      content: 'Gör en lektion om vårt solsystem',
      Icon: PublicIcon,
    },
    {
      value: 'dinosaurier',
      label: 'Dinosaurier',
      content: 'Gör en lektion om olika dinosaurier',
      Icon: NatureIcon,
    },
    {
      value: 'nobel',
      label: 'Nobelpriset',
      content: 'Gör en lektion om nobelpriset och Alfred Nobel',
      Icon: EmojiEventsIcon,
    },
    {
      value: 'uppfinnare',
      label: 'Svenska uppfinnare',
      content: 'Gör en lektion om kända svenska uppfinnare',
      Icon: ScienceIcon,
    },
    {
      value: 'psychology',
      label: 'Känslor',
      content: 'Gör en lektion om olika känslor',
      Icon: PsychologyIcon,
    },
    {
      value: 'konst',
      label: 'Svensk konst',
      content: 'Gör en lektion om svensk konsthistoria',
      Icon: MuseumIcon,
    },
  ];

  useEffect(() => {
    Logger.log('lessonTheme: ', lessonTheme);
  }, [lessonTheme]);

  useEffect(() => {
    Logger.log('predefinedTheme: ', predefinedTheme);
  }, [predefinedTheme]);

  useEffect(() => {
    Logger.log('customTheme: ', customTheme);
  }, [customTheme]);

  useEffect(() => {
    Logger.log('youtubeTranscript: ', transcriptRef.current);
  }, [transcriptRef.current]);

  useEffect(() => {
    Logger.log('wikipediaContentRef: ', wikipediaContentRef.current);
  }, [wikipediaContentRef.current]);
  useEffect(() => {
    Logger.log('uploadedFileRef: ', uploadedFileRef.current);
  }, [uploadedFileRef.current]);

  useEffect(() => {
    Logger.log('randomizedContentRef: ', randomizedContentRef.current);
  }, [randomizedContentRef.current]);

  useEffect(() => {
    Logger.log('coursePlanContentRef: ', coursePlanContentRef.current);
  }, [coursePlanContentRef.current]);

  const handleSaveLessonClick = () => {
    setSaveDialogOpen(true);
  };

  const handleSaveWithComment = async (comment, name, coverImage, tags) => {
    const lessonData = {
      words: words,
      selectedLetters: selectedLetters,
      titleText: titleText,
    };

    await trackLesson(
      user.uid,
      'LetterBoxLesson.js',
      lessonData,
      null,
      true,
      comment,
      name,
      coverImage,
      tags
    );
    setDrawerOpen(true);
    showSnackbar('Lektionen sparad i din lektionsbank!', 'success');
  };

  const handleImageButtonClick = (type, wordIndex = null) => {
    setImageGenerationType(type);
    setGeneratingImageIndex(wordIndex); // Store the wordIndex for single image generation
    setShowImageModal(true);
  };

  const handleCloseModal = () => {
    setShowImageModal(false);
    setImageInstructions('');
  };

  const handleGenerateWithInstructions = async () => {
    handleCloseModal();

    if (generatingImageIndex !== null) {
      // Handle single image generation
      await handleSingleImageGeneration(generatingImageIndex);
    } else {
      // Handle bulk image generation
      await handleGenerateImages();
    }
  };

  const generateLetterPool = word => {
    const wordLetters = word.split('');
    const alphabet = 'abcdefghijklmnopqrstuvwxyzåäö'.split('');
    const additionalLetters = Array.from(
      { length: extraLettersCount - wordLetters.length },
      () => alphabet[Math.floor(Math.random() * alphabet.length)]
    );
    return [...wordLetters, ...additionalLetters].sort(() => Math.random() - 0.5);
  };

  const handleGenerateLesson = async () => {
    setIsGeneratingLesson(true);
    try {
      let themeToUse;

      if (lessonTheme === 'youtube') {
        themeToUse = transcriptRef.current;
      } else if (lessonTheme === 'customTheme') {
        themeToUse = customTheme;
      } else if (lessonTheme === 'predefined') {
        themeToUse = predefinedTheme;
      } else if (lessonTheme === 'fileUpload') {
        themeToUse = uploadedFileRef.current;
      } else if (lessonTheme === 'wikipedia') {
        themeToUse = wikipediaContentRef.current;
      } else if (lessonTheme === 'randomizer') {
        themeToUse = randomizedContentRef.current;
      } else {
        //
        themeToUse = coursePlanContentRef.current;
      }

      Logger.log('themeToUse: ', themeToUse);

      const gradeInstructionsText = gradeInstructions[grade];

      const response = await callApi(
        [
          {
            role: 'system',
            content: 'Du är en assistent som genererar enkla ord för bokstavsövningar.',
          },
          {
            role: 'user',
            content: `Generera JSON med exakt ${wordCount}   ord relaterade till temat "${themeToUse}". Orden ska vara på ${language}. Varje ord ska vara 3-8 bokstäver långt.
            ${gradeInstructionsText}

             Säkerställ att allt som genereras är enbart en JSON array. Exempel på format:
            {
              "words": [
                { "word": "katt" },
                { "word": "bil" },
                { "word": "hund" },
                { "word": "boll" },
                { "word": "fisk" }
              ]
            }`,
          },
        ],
        4000,
        true,
        user.uid
      );

      let messageContent = response.data.choices[0].message.content.trim();

      if (messageContent.startsWith('```json')) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith('```')) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const data = JSON.parse(messageContent);

      const wordsWithLetters = data.words.map(wordObj => ({
        ...wordObj,
        letters: generateLetterPool(wordObj.word),
        image: null,
      }));

      setWords(wordsWithLetters);
      setSelectedLetters({});
      showSnackbar('Nya ord har genererats', 'success');

      await trackLesson(user.uid, 'LetterBoxLesson.js', null, {
        wordCount: wordCount,
        language: language,
        lessonTheme: lessonTheme,
      });
    } catch (error) {
      console.error('Error generating lesson:', error);
      showSnackbar('Kunde inte generera nya ord', 'error');
    } finally {
      setIsGeneratingLesson(false);
    }
  };

  const handleImageUpdate = (wordIndex, newImageUrl) => {
    const updatedWords = [...words];
    updatedWords[wordIndex] = {
      ...updatedWords[wordIndex],
      image: newImageUrl,
    };
    setWords(updatedWords);
    showSnackbar('Bild har uppdaterats', 'success');
  };

  const handleSingleImageGeneration = async wordIndex => {
    try {
      const word = words[wordIndex];
      const prompt = `Create a simple, clear image representing the word "${word.word}". No text in the image. ${imageInstructions}`;

      const imageUrl = await generateAndProcessImage(prompt, user.uid, {
        size: '1024x1024',
        model: 'dall-e-3',
        quality: 'standard',
      });

      handleImageUpdate(wordIndex, imageUrl);
      showSnackbar('Bild har genererats', 'success');
    } catch (error) {
      console.error('Error generating image:', error);
      showSnackbar('Kunde inte generera bild', 'error');
    } finally {
      setGeneratingImageIndex(null);
    }
  };
  const handleGenerateImages = async () => {
    setIsGeneratingImages(true);
    setGeneratedImages(new Set());

    try {
      const updatedWords = [...words];

      for (const [index, word] of words.entries()) {
        try {
          const prompt = `Create a simple, clear image representing the  word "${word.word}".  No text in the image. ${imageInstructions}`;

          const imageUrl = await generateAndProcessImage(prompt, user.uid, {
            size: '1024x1024',
            model: 'dall-e-3',
            quality: 'standard',
          });

          updatedWords[index] = {
            ...updatedWords[index],
            image: imageUrl,
          };

          setGeneratedImages(prev => new Set([...prev, index]));
          setWords(updatedWords);
          showSnackbar(`Genererat bild ${index + 1} av ${words.length}`, 'info');
        } catch (error) {
          console.error(`Error generating image for word "${word.word}":`, error);
          showSnackbar(`Kunde inte generera bild för "${word.word}"`, 'error');
          continue;
        }
      }
      showSnackbar('Alla bilder har genererats!', 'success');
    } catch (error) {
      console.error('Error in image generation process:', error);
      showSnackbar('Ett fel uppstod när bilderna skulle genereras', 'error');
    } finally {
      setIsGeneratingImages(false);
    }
  };

  const handleLetterClick = (wordIndex, letter, letterIndex) => {
    setSelectedLetters(prev => {
      const currentSelected = prev[wordIndex] || [];
      if (!currentSelected.find(item => item.index === letterIndex)) {
        return {
          ...prev,
          [wordIndex]: [...currentSelected, { letter, index: letterIndex }],
        };
      }
      return prev;
    });
  };

  const handleClearWord = wordIndex => {
    setSelectedLetters(prev => ({
      ...prev,
      [wordIndex]: [],
    }));
  };

  const isWordComplete = wordIndex => {
    const selected = selectedLetters[wordIndex] || [];
    const targetWord = words[wordIndex]?.word || '';
    const selectedWord = selected.map(s => s.letter).join('');
    return selectedWord === targetWord;
  };

  return (
    <Box sx={styles.container}>
      <Box
        sx={{
          ...styles.innerContainer,
          width: { xs: '100%', md: '87%' },
          margin: '0 auto',
        }}
      >
        <Typography
          variant='h1'
          gutterBottom
          sx={{ fontWeight: 'bold', fontSize: '1.8em', mb: 3, mt: 5, textAlign: 'center' }}
        >
          Generera bokstavslådor
        </Typography>

        <Box sx={{ ...styles.buttonsContainer, ...styles.additionalButtonsContainer }}>
          <Tooltip title='Dina sparade lektioner'>
            <Button
              variant='contained'
              color='primary'
              disableRipple
              onClick={() => setDrawerOpen(true)}
              startIcon={<HistoryIcon />}
              sx={{
                padding: '8px',
                marginRight: '0px',
                paddingLeft: '14px',
                paddingRight: '3px',
                minWidth: 'auto',
                textTransform: 'none',
              }}
            />
          </Tooltip>
        </Box>
        <LessonDrawer
          userId={user.uid}
          lessonType='LetterBoxLesson.js'
          theme={theme}
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          lessonIcon={TbChartGridDotsFilled}
          onSelectLesson={lesson => {
            setWords(lesson.data.words);
            setSelectedLetters(lesson.data.selectedLetters);
            setTitleText(lesson.lessonName);

            setDrawerOpen(false);
            showSnackbar(`Lektionen har laddats in.`, 'success');
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'start', // This ensures it scrolls to the top of the element
              });
            }, 100);
          }}
        />

        <InfoBox
          icon={TbChartGridDotsFilled}
          title='Powerpoint Instructions'
          instructions={[
            'Generera ord som eleven ska hitta bland mängder av bokstäver',
            'Lägg till AI-genererade bilder för varje ord',
            'Skriv ut lektionen',
          ]}
          theme={theme}
          styles={styles}
        />

        <ThemeSelectorFlow
          value={lessonTheme}
          onChange={e => setLessonTheme(e.target.value)}
          customTheme={customTheme}
          onCustomThemeChange={e => setCustomTheme(e.target.value)}
          onPredefinedThemeChange={e => setPredefinedTheme(e.target.value)}
          onYoutubeTranscriptChange={transcriptContent => {
            Logger.log('onYoutubeTranscriptChange körs med: ', transcriptContent);
            transcriptRef.current = transcriptContent;
          }}
          onFileUpload={content => {
            Logger.log('onFileUpload körs med: ', content);
            uploadedFileRef.current = content;
          }}
          onWikipediaContentChange={content => {
            Logger.log('Parent: Wikipedia content received', content.substring(0, 100));
            wikipediaContentRef.current = content;
          }}
          onRandomizedContentChange={content => {
            Logger.log('randomizedContentRef', content.substring(0, 100));
            randomizedContentRef.current = content;
          }}
          onCoursePlanContentChange={content => {
            Logger.log('onCoursePlanChange', content.substring(0, 100));
            coursePlanContentRef.current = content;
          }}
          lessonType='LetterBoxLesson.js'
          user={user}
          theme={theme}
          styles={styles}
          onLessonSelect={lesson => {
            Logger.log('onLessonSelect körs');
            setWords(lesson.data.words);
            setSelectedLetters(lesson.data.selectedLetters);
            setTitleText(lesson.lessonName);
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'start', // This ensures it scrolls to the top of the element
              });
            }, 100);
            showSnackbar('Lektionen har laddats in.', 'success');
          }}
          additionalThemes={additionalThemes}
        />
        <Box sx={styles.headlineLessonBox}>
          <Typography variant='h6' sx={styles.headlineLessonBoxTitle}>
            2. Generera lektionen
          </Typography>
        </Box>

        <Box sx={{ ...styles.freshBox, padding: '30px' }}>
          {/* Configuration Section */}
          <Box sx={{ mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500', mb: 0 }}>
                  Årskurs:
                </Typography>
                <Box sx={{ mt: 1, mb: 3 }}>
                  <GradeSelector grade={grade} setGrade={setGrade} />
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500', mb: 0 }}>
                  Antal ord:
                </Typography>
                <FormControl fullWidth margin='normal' sx={{ mb: 3, mt: 1 }}>
                  <Select value={wordCount} onChange={e => setWordCount(e.target.value)}>
                    <MenuItem value={3}>3 ord</MenuItem>
                    <MenuItem value={4}>4 ord</MenuItem>
                    <MenuItem value={5}>5 ord</MenuItem>
                    <MenuItem value={6}>6 ord</MenuItem>
                    <MenuItem value={8}>8 ord</MenuItem>
                    <MenuItem value={10}>10 ord</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500', mb: 0 }}>
                  Antal extra bokstäver:
                </Typography>
                <FormControl fullWidth margin='normal' sx={{ mb: 3, mt: 1 }}>
                  <Select
                    value={extraLettersCount}
                    onChange={e => setExtraLettersCount(e.target.value)}
                  >
                    <MenuItem value={10}>10 bokstäver</MenuItem>
                    <MenuItem value={15}>15 bokstäver</MenuItem>
                    <MenuItem value={20}>20 bokstäver</MenuItem>
                    <MenuItem value={25}>25 bokstäver</MenuItem>
                    <MenuItem value={30}>30 bokstäver</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={12}>
                <Typography variant='subtitle1' sx={{ fontWeight: '500' }}>
                  Språk:
                </Typography>
                <Box sx={{ mt: 1 }}>
                  <LanguageSelector language={language} setLanguage={setLanguage} />
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Button
            variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
            color='primary'
            onClick={handleGenerateLesson}
            disabled={isGeneratingLesson}
            startIcon={!isGeneratingLesson ? <GenerateIcon /> : <CircularProgress size={20} />}
            sx={{ mr: 2, mt: 4 }}
          >
            {isGeneratingLesson ? 'Genererar...' : 'Generera lektion'}
          </Button>
        </Box>

        {words.length > 0 && (
          <>
            <Box ref={lessonTitleRef} sx={{ ...styles.headlineLessonBox, mt: 10 }}>
              <Typography variant='h6' sx={styles.headlineLessonBoxTitle}>
                3. Din lektion
              </Typography>
            </Box>
            <Box sx={{ ...styles.freshBox, padding: '30px' }}>
              <Box sx={styles.lessonControlPanel}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    px: 1,
                  }}
                >
                  <Box>
                    <Button
                      variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                      color='primary'
                      onClick={() => handleImageButtonClick('facts')}
                      disabled={isGeneratingImages}
                      startIcon={
                        !isGeneratingImages ? <ImageIcon /> : <CircularProgress size={20} />
                      }
                    >
                      {isGeneratingImages ? 'Genererar bilder...' : 'Generera bilder'}
                    </Button>

                    <SaveLessonDialog
                      open={saveDialogOpen}
                      onClose={() => setSaveDialogOpen(false)}
                      onSave={handleSaveWithComment}
                      theme={theme}
                      lessonObject={words}
                      lessonName={titleText}
                    />

                    <ImageGenerationModal
                      open={showImageModal}
                      onClose={handleCloseModal}
                      onGenerate={handleGenerateWithInstructions}
                      isGenerating={
                        imageGenerationType === 'topic' ? isGeneratingImages : isGeneratingImages
                      }
                      instructions={imageInstructions}
                      setInstructions={setImageInstructions}
                      generationType={imageGenerationType}
                      theme={theme}
                    />
                  </Box>
                  <Box>
                    <Button
                      variant='print'
                      color='primary'
                      onClick={() =>
                        handlePrintLetterBox(words, selectedLetters, themeColor, titleText)
                      }
                      startIcon={<PrintIcon />}
                      sx={{ ml: 2 }}
                    >
                      Skriv ut
                    </Button>

                    <Button
                      variant='print'
                      startIcon={<SaveAsIcon />}
                      sx={{ ml: 2 }}
                      onClick={handleSaveLessonClick}
                    >
                      Spara i lektionsbank
                    </Button>
                  </Box>
                </Box>
              </Box>

              {isEditingTitle ? (
                <TextField
                  value={titleText}
                  onChange={e => setTitleText(e.target.value)}
                  onBlur={() => {
                    setIsEditingTitle(false);
                    showSnackbar('Titeln har uppdaterats', 'success');
                  }}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      setIsEditingTitle(false);
                      showSnackbar('Titeln har uppdaterats', 'success');
                    }
                  }}
                  autoFocus
                  fullWidth
                  variant='standard'
                  sx={{
                    backgroundColor: 'transparent',
                    '& .MuiInputBase-input': {
                      textAlign: 'center',
                      fontWeight: 'bold',
                      fontSize: '2.6em',
                      marginBottom: '1.2rem',
                      marginTop: '1.5rem',
                    },
                  }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              ) : (
                <Box
                  sx={{
                    position: 'relative',
                    display: 'inline-block',
                    margin: '0 auto',
                    width: '100%',
                    mb: 3,
                    mt: 5,
                    ':hover .edit-icon': {
                      opacity: 1,
                    },
                  }}
                >
                  <Typography
                    variant='h1'
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '2.6em',

                      textAlign: 'center',
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: 2,
                    }}
                  >
                    {titleText || ' '}
                    <Box
                      component='span'
                      sx={{
                        opacity: 0,
                        transition: 'opacity 0.2s',
                        display: 'inline-flex',
                        gap: 0.5,
                      }}
                      className='edit-icon'
                    >
                      <Tooltip title='Redigera' placement='top'>
                        <IconButton size='small' onClick={() => setIsEditingTitle(true)}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Typography>
                </Box>
              )}

              <Box
                sx={{
                  borderRadius: '13px',
                }}
              >
                <Grid container spacing={3}>
                  {words.map((word, wordIndex) => (
                    <>
                      <Typography
                        sx={{
                          fontWeight: '700',
                          fontSize: '23px',
                          display: 'block',
                          mt: 8,
                          ml: 2,
                          textTransform: 'capitalize',
                        }}
                      >
                        {word.word}
                      </Typography>

                      <Grid item xs={12} key={wordIndex}>
                        <Box
                          sx={{
                            ...styles.freshBox,
                            p: 2,
                            display: 'flex',
                            alignItems: 'center',
                            gap: 3,
                            borderRadius: '13px',

                            backgroundColor: theme.palette.mode === 'dark' ? '#1a2027' : '#f5f5f5',
                          }}
                        >
                          <Box
                            sx={{ position: 'relative', width: 250, height: 250, flexShrink: 0 }}
                          >
                            <HoverableImage
                              imageUrl={word.image}
                              isLoading={isGeneratingImages || generatingImageIndex === wordIndex}
                              loadingIndex={generatingImageIndex}
                              currentIndex={wordIndex}
                              onGenerateClick={() => handleImageButtonClick('single', wordIndex)}
                              userId={user.uid}
                              onImageUpdate={newUrl => handleImageUpdate(wordIndex, newUrl)}
                              height='250px'
                              theme={theme}
                              showSnackbar={showSnackbar}
                            />
                          </Box>

                          <Box sx={{ flex: 1 }}>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mb: 2 }}>
                              {word.letters.map((letter, letterIndex) => (
                                <Box
                                  key={letterIndex}
                                  onClick={() => handleLetterClick(wordIndex, letter, letterIndex)}
                                  sx={{
                                    width: 44,
                                    height: 44,
                                    fontWeight: '600',
                                    borderRadius: '50%',
                                    border: `2px solid ${themeColor}`,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                    backgroundColor: selectedLetters[wordIndex]?.find(
                                      item => item.index === letterIndex
                                    )
                                      ? '#8b5cf6'
                                      : 'transparent',
                                    color: selectedLetters[wordIndex]?.find(
                                      item => item.index === letterIndex
                                    )
                                      ? '#fff'
                                      : `${themeColor}`,
                                    '&:hover': {
                                      backgroundColor: '#8b5cf6',
                                      color: '#fff',
                                    },
                                    transition: 'all 0.2s',
                                  }}
                                >
                                  {letter}
                                </Box>
                              ))}
                            </Box>

                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center', // Center the entire container vertically
                                gap: 2,
                                position: 'relative',
                                height: '100%', // Take full height of parent
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 1,
                                  height: '100%', // Take full height
                                }}
                              >
                                {(selectedLetters[wordIndex] || []).map((item, i) => (
                                  <Box
                                    key={i}
                                    sx={{
                                      width: 45,
                                      height: 45,
                                      borderRadius: '50%',
                                      backgroundColor: '#8b5cf6',
                                      color: '#fff',
                                      fontSize: '17px',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    {item.letter}
                                  </Box>
                                ))}
                              </Box>
                              {/*
                              <IconButton
                                onClick={() => handleClearWord(wordIndex)}
                                size='small'
                                sx={{ position: 'absolute', bottom: '-20px', right: '0px' }}
                              >
                                <RefreshIcon />
                              </IconButton>
*/}
                              {isWordComplete(wordIndex) && (
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                    color: 'success.main',
                                  }}
                                >
                                  <CheckCircleIcon />
                                  <Typography>Rätt!</Typography>
                                </Box>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </>
                  ))}
                </Grid>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default LetterBoxLesson;
