import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Container,
  Typography,
  Grid,
  Box,
  useTheme,
  useMediaQuery,
  Card,
  IconButton,
  CardContent,
  Chip,
} from '@mui/material';
import { features } from './app/functions';
import {
  StyledButton,
  ResponsiveVideo,
  FeatureSection,
  FeatureList,
  GradientBackground,
} from './StyledComponents';
import { Link } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { TypeAnimation } from 'react-type-animation';
import Header from './Header';
import Footer from './Footer';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { motion } from 'framer-motion';

import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { getAIModelImage } from './app/TestComponents2';
import UniversitySection from './UniversitySection';
import MediaSection from './MediaSection';
import { BenefitsSection } from './FeaturePageEnhancements';
import { HelmetProvider, Helmet } from 'react-helmet-async';

const FeaturePage = ({ toggleDarkMode, isDark }) => {
  const { featureId } = useParams();
  const feature = features.find(
    f =>
      f.title
        .toLowerCase()
        .replace(/ /g, '-')
        .replace(/å/g, 'a')
        .replace(/ä/g, 'a')
        .replace(/ö/g, 'o') === featureId
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const isVideo = url => url.endsWith('.mp4');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [featureId]);

  if (!feature) {
    return <Typography>Feature not found</Typography>;
  }

  return (
    <>
      <Helmet>
        <title>{`${feature.titleSEO} | Studera.ai`}</title>
        <meta name='description' content={feature.descriptionSEO} />
      </Helmet>

      <Header />

      <GradientBackground className='gradient-background'>
        <Container
          sx={{ pt: { xs: 10, md: 20 }, pb: 15, position: 'relative', textAlign: 'center' }}
        >
          <Grid container spacing={6} alignItems='center'>
            <Grid item xs={12} md={12} sx={{ textAlign: 'center' }}>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                  <Chip
                    label={
                      <span>
                        Studera.ai{' '}
                        <span style={{ opacity: 0.7 }}>
                          &nbsp;•&nbsp; Revolutionerar lärande med AI
                        </span>
                      </span>
                    }
                    sx={{
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                      color: 'white',
                      fontWeight: '600',
                      fontSize: '0.8rem',
                      padding: '2px 14px',

                      borderRadius: '16px',
                      '& .MuiChip-label': {
                        padding: '0 4px',
                      },
                    }}
                  />
                </Box>

                <Typography
                  variant='h2'
                  component='h1'
                  gutterBottom
                  sx={{
                    fontWeight: 900,
                    letterSpacing: -1,
                    fontSize: { xs: '2.5rem', md: '3.35rem' },
                  }}
                >
                  {feature.title}
                </Typography>
                <Typography
                  variant='h5'
                  sx={{
                    mb: 4,
                    opacity: 0.8,
                    fontSize: '1.3rem',
                    width: { xs: '100%', md: '64%' },
                    margin: '0 auto',
                    fontWeight: '500',
                    lineHeight: '31px',
                    display: 'block',
                    mt: 4,
                  }}
                >
                  {feature.descriptionLonger}
                </Typography>
                {/*
                <Typography
                  variant='h5'
                  sx={{
                    mb: 4,
                    opacity: 0.8,
                    fontSize: '1.3rem',
                    fontWeight: '600',
                    mt: 5,
                    display: { xs: 'none', md: 'block' },
                  }}
                >

                  <TypeAnimation
                    sequence={feature.typeAnimationSequence || []}
                    speed={50}
                    deletionSpeed={90}
                    style={{ fontSize: '1.3rem' }}
                    repeat={Infinity}
                  />

                </Typography>
                */}

                <Box sx={{ textAlign: 'center', mt: 7 }}>
                  <Link to='/app' style={{ textDecoration: 'none' }}>
                    <StyledButton
                      variant='contained'
                      color='primary'
                      size='large'
                      endIcon={<ArrowForwardIcon />}
                    >
                      Testa Studera.ai nu
                    </StyledButton>
                  </Link>
                </Box>
              </motion.div>
            </Grid>
          </Grid>
        </Container>
      </GradientBackground>

      <Container sx={{ textAlign: 'center', display: { xs: 'none', md: 'block' } }}>
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          <Link to='/app' style={{ textDecoration: 'none' }}>
            {isVideo(feature.heroImage.light) || isVideo(feature.heroImage.dark) ? (
              <ResponsiveVideo
                light={feature.heroImage.light}
                dark={feature.heroImage.dark}
                maxWidth='800'
                theme={theme}
                styled={true}
                alt={`${feature.title} demonstration`}
              />
            ) : (
              <Box
                component='img'
                src={
                  theme.palette.mode === 'dark' ? feature.heroImage.dark : feature.heroImage.light
                }
                alt='Studera med AI'
                sx={{
                  width: '80%',
                  height: 'auto',
                  borderRadius: '17px',
                  border: '2px solid #e8e8e8',
                  marginTop: '-3px',
                  boxShadow:
                    'rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px',
                }}
              />
            )}
          </Link>
        </motion.div>
      </Container>

      <Box sx={{ py: { xs: 6, md: 17 }, bgcolor: 'background.default' }}>
        <Container>
          <Typography
            variant='h3'
            align='center'
            gutterBottom
            sx={{
              fontWeight: 'bold',

              fontSize: '2.6em',
            }}
          >
            Funktioner
          </Typography>
          <Grid container spacing={5} sx={{ mt: { xs: 2, md: 7 } }}>
            {feature.details.map((detail, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Card
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    boxShadow: '0 8px 20px rgba(0, 0, 0, 0.1)',
                    borderRadius: 3,
                    transition: 'transform 0.3s',

                    '&:hover': {
                      transform: 'translateY(-10px)',
                    },
                  }}
                >
                  <CardContent>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4, mt: 2 }}>
                      {React.cloneElement(detail.icon, {
                        fontSize: 'large',
                        sx: {
                          fontSize: '50px',
                          color: theme.palette.mode === 'dark' ? '#9575F7' : '#6424c1',
                        },
                      })}
                    </Box>
                    <Typography
                      variant='h5'
                      align='center'
                      gutterBottom
                      sx={{ fontWeight: 'bold' }}
                    >
                      {detail.text.split(':')[0]}
                    </Typography>
                    <Typography variant='body1' align='center' color='text.secondary'>
                      {detail.text.split(':')[1]}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* New Steps Section */}
      <Box sx={{ py: { xs: 6, md: 17 }, bgcolor: 'background.paper' }}>
        <Container>
          <Typography
            variant='h3'
            align='center'
            gutterBottom
            sx={{ fontWeight: 'bold', fontSize: '2.6em' }}
          >
            Hur det fungerar
          </Typography>
          <Grid container spacing={4} justifyContent='center' sx={{ mt: { xs: 2, md: 7 } }}>
            {feature.steps.map((step, index) => (
              <Grid item xs={12} sm={6} md={4} key={index} sx={{ mt: { xs: 4, md: undefined } }}>
                <Card
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    boxShadow: '0 8px 20px rgba(0, 0, 0, 0.1)',
                    borderRadius: 3,
                    transition: 'transform 0.3s',
                    position: 'relative',
                    overflow: 'visible',
                    '&:hover': {
                      transform: 'translateY(-10px)',
                    },
                  }}
                >
                  <Box
                    sx={{
                      width: 60,
                      height: 60,
                      borderRadius: '50%',
                      bgcolor: 'primary.main',
                      color: 'white',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: 24,
                      fontWeight: 'bold',
                      position: 'absolute',
                      top: -38,
                      left: 'calc(50% - 30px)',
                      border: '4px solid white',
                      zIndex: 1,
                    }}
                  >
                    {index + 1}
                  </Box>
                  {step.image ? (
                    <Box
                      component='img'
                      src={step.image}
                      alt={step.title}
                      sx={{
                        width: '100%',
                        height: 200,
                        objectFit: 'cover',
                        borderTopLeftRadius: 'inherit',
                        borderTopRightRadius: 'inherit',
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        width: '100%',
                        height: 200,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'rgba(0, 0, 0, 0.1)',
                        borderTopLeftRadius: 'inherit',
                        borderTopRightRadius: 'inherit',
                      }}
                    >
                      <Typography variant='h4' sx={{ fontWeight: 'bold', color: 'text.secondary' }}>
                        Steg {index + 1}
                      </Typography>
                    </Box>
                  )}
                  <CardContent sx={{ flexGrow: 1, textAlign: 'center', pt: 5 }}>
                    <Typography variant='h5' gutterBottom sx={{ fontWeight: 'bold' }}>
                      {step.title}
                    </Typography>
                    <Typography variant='body1' color='text.secondary'>
                      {step.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      <UniversitySection bgcolor='background.default' />
      <Box sx={{ py: { xs: 6, md: 17 }, bgcolor: 'background.paper' }}>
        <Container sx={{ textAlign: 'center' }}>
          <Typography
            variant='h3'
            align='center'
            gutterBottom
            sx={{ fontWeight: 'bold', fontSize: '2.6em' }}
          >
            Lär dig mer
          </Typography>

          <Typography
            variant='body1'
            sx={{
              mt: { xs: 2, md: 7 },

              mb: 4,
              color: 'text.secondary',
              fontWeight: '400',
              fontSize: '1.15rem',
              lineHeight: '1.6',
              whiteSpace: 'pre-line',
            }}
          >
            {feature.aboutText}
          </Typography>

          <Link to='/app' style={{ textDecoration: 'none' }}>
            <StyledButton
              variant='contained'
              color='primary'
              size='large'
              endIcon={<ArrowForwardIcon />}
            >
              Börja din resa nu
            </StyledButton>
          </Link>
        </Container>
      </Box>

      <MediaSection bgcolor='background.paper' />

      <Footer />

      {!isMobile && (
        <Box
          sx={{
            position: 'fixed',
            bottom: 16,
            right: 16,
            zIndex: 9999,
          }}
        >
          <IconButton onClick={toggleDarkMode}>
            {isDark ? <LightModeIcon /> : <DarkModeIcon />}
          </IconButton>
        </Box>
      )}
    </>
  );
};

export default FeaturePage;
