import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  Checkbox,
  CircularProgress,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { getStyles } from '../index';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useNavigate } from 'react-router-dom';
import { callApi } from './api';
import { doc, updateDoc, collection, query, where, getDocs, getDoc } from 'firebase/firestore';
import { db } from './firebase';
import Logger from './Logger';

import { useSnackbar } from './SnackbarHandler';

const CourseChapters = ({
  selectedModule,
  setSelectedModule,
  handleChapterClick,
  handleChapterCompletion,
  selectedModuleIndex,
  courseName,
  user,
}) => {
  const theme = useTheme();
  const styles = getStyles(theme);
  const navigate = useNavigate();
  const [loadingChapter, setLoadingChapter] = useState(null);
  const { showSnackbar } = useSnackbar();
  useEffect(() => {
    Logger.log('selectedModuleIndex: ', selectedModuleIndex);
    Logger.log('selectedModule: ', selectedModule);
  }, [selectedModuleIndex]);

  const formatUrlPart = str => {
    return str
      .replace(/\s+/g, '-')
      .replace(/[åä]/g, 'a')
      .replace(/[ö]/g, 'o')
      .replace(/[^a-z0-9-]/gi, '')
      .toLowerCase();
  };

  const generateChapterContent = async (chapter, chapterIndex) => {
    setLoadingChapter(chapterIndex);

    const prompt = `Skapa en lektion i kursen "${courseName}" med 3 rubriker och 3 paragrafer per rubrik baserat på denna delkurs: ${chapter.chapter_title} - ${chapter.chapter_description} på ett tydligt, pedagogiskt sätt för en student. Lektionen är en del av delkursen med titeln  ${selectedModule.module_title} - ${selectedModule.module_description} .Använd <i></i> och <strong></strong>-taggar för att framhäva de delar du anser värdiga.  Svaret ska vara en array av JSON-objekt med följande struktur: [ { "chapter_content": "<h3>rubrik 1</h3> <p>paragraf 1</p> <p>paragraf 2</p> <p>paragraf 3</p> <h3>rubrik 2</h3> ", } ]`;

    try {
      if (!db) {
        throw new Error('Firestore database is not initialized');
      }
      if (!courseName) {
        throw new Error('Course name is missing');
      }
      if (!user || !user.uid) {
        throw new Error('User information is missing');
      }

      let response = await callApi(
        [
          { role: 'system', content: 'Du är en hjälpsam AI-assistent som genererar kursinnehåll.' },
          { role: 'user', content: prompt },
        ],
        undefined,
        undefined,
        user.uid
      );

      Logger.log('response: ', response);

      if (typeof response === 'string') {
        if (response.startsWith('```json')) {
          response = response.slice(7, -3).trim();
          Logger.log('removed 3 characters from json');
        } else if (response.startsWith('```')) {
          response = response.slice(3, -3).trim();
        }
      }

      const parsedResponse = JSON.parse(response);
      const chapterContent = parsedResponse[0].chapter_content;

      const q = query(collection(db, 'courses'), where('slug', '==', courseName));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const docRef = querySnapshot.docs[0].ref;

        // Fetch the whole document to get the current modules
        const courseDoc = await getDoc(docRef);
        const modules = courseDoc.data().modules;

        // Update the specific chapter content locally
        if (modules[selectedModuleIndex] && modules[selectedModuleIndex].chapters[chapterIndex]) {
          modules[selectedModuleIndex].chapters[chapterIndex].chapter_content = chapterContent;
        }

        // Write the updated modules array back to Firestore
        await updateDoc(docRef, { modules });

        // Update local state
        const updatedSelectedModule = { ...selectedModule };
        updatedSelectedModule.chapters[chapterIndex].chapter_content = chapterContent;
        setSelectedModule(updatedSelectedModule);

        // Navigate to the updated content
        navigate(
          `/app/kurser/${courseName}/${formatUrlPart(selectedModule.module_title)}/${formatUrlPart(chapter.chapter_title)}`,
          { state: { chapterContent } }
        );
      } else {
        throw new Error('No course found with the given name');
      }
    } catch (error) {
      Logger.error('Error generating chapter content:', error);
      showSnackbar('Nåt fick fel. Försök igen.', 'error');
    } finally {
      setLoadingChapter(null);
    }
  };

  const handleStartChapter = (chapter, chapterIndex) => {
    if (chapter.chapter_content) {
      navigate(
        `/app/kurser/${courseName}/${formatUrlPart(selectedModule.module_title)}/${formatUrlPart(chapter.chapter_title)}`,
        { state: { chapterContent: chapter.chapter_content } }
      );
    } else {
      generateChapterContent(chapter, chapterIndex);
    }
  };

  return (
    <Box>
      <Typography
        variant='h1'
        sx={{ ...styles.header, mb: 2, marginTop: '5px', marginLeft: { xs: '0px', md: 'inherit' } }}
      >
        {selectedModuleIndex + 1}. {selectedModule.module_title}
      </Typography>
      <Typography variant='body1' paragraph sx={{ ...styles.softerText, mb: 4 }}>
        {selectedModule.module_description}
      </Typography>
      <Grid container spacing={2}>
        {selectedModule.chapters.map((chapter, chapterIndex) => (
          <Grid item xs={12} key={chapterIndex}>
            <Card
              sx={{
                boxShadow: 'none',
                borderRadius: '7px',
                backgroundImage: 'none',
                border: chapter.chapter_completed
                  ? theme.palette.mode === 'dark'
                    ? '1px solid #366235' // Dark green for completed in dark mode
                    : '1px solid #CBF2C9' // Light green for completed in light mode
                  : theme.palette.mode === 'dark'
                    ? '1px solid #35414d' // Dark border for incomplete in dark mode
                    : '1px solid #eee', // Light border for incomplete in light mode
                transition: 'all 0.3s ease',

                '&:hover': {
                  transform: 'translateY(-2px)',
                },
              }}
            >
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  justifyContent: 'space-between',
                  alignItems: { xs: 'flex-start', md: 'center' },
                  padding: '30px !important',
                  border: 'none',
                  backgroundColor: chapter.chapter_completed
                    ? theme.palette.mode === 'dark'
                      ? 'rgba(86, 166, 83, 0.1)'
                      : 'rgba(86, 166, 83, 0.05)'
                    : theme.palette.mode === 'dark'
                      ? 'rgba(255, 255, 255, 0.05)'
                      : 'white',
                  '&:hover': {
                    backgroundColor: chapter.chapter_completed
                      ? theme.palette.mode === 'dark'
                        ? 'rgba(86, 166, 83, 0.15)'
                        : 'rgba(86, 166, 83, 0.1)'
                      : theme.palette.mode === 'dark'
                        ? 'rgba(255, 255, 255, 0.1)'
                        : 'inherit',
                  },
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', mb: { xs: 2, md: 0 } }}>
                  <Checkbox
                    checked={chapter.chapter_completed}
                    onChange={() => handleChapterCompletion(chapterIndex)}
                    icon={<CheckCircleOutlineIcon />}
                    checkedIcon={<CheckCircleIcon sx={{ color: '#56A653' }} />}
                    sx={{ mr: 2 }}
                  />
                  <Box>
                    <Typography
                      variant='h6'
                      sx={{
                        fontWeight: '600',
                        fontSize: '19px',
                        mb: 0.3,
                      }}
                    >
                      {`${selectedModuleIndex + 1}.${chapterIndex + 1} ${chapter.chapter_title}`}
                    </Typography>
                    <Typography variant='body2' sx={{ ...styles.softerText }}>
                      {chapter.chapter_description.length > 100
                        ? `${chapter.chapter_description.substring(0, 100)}...`
                        : chapter.chapter_description}
                    </Typography>
                  </Box>
                </Box>
                <Button
                  color='primary'
                  onClick={() => handleStartChapter(chapter, chapterIndex)}
                  variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                  startIcon={
                    loadingChapter === chapterIndex ? (
                      <CircularProgress sx={{ color: '#fff' }} size={16} />
                    ) : null
                  }
                  disabled={loadingChapter}
                  sx={{
                    mt: { xs: 2, md: 0 },
                    margin: { xs: '0 auto', md: 'inherit' },
                    ...(chapter.chapter_completed && {
                      backgroundColor: '#56A653',
                      '&:hover': {
                        backgroundColor: '#4a8f47',
                      },
                    }),
                  }}
                >
                  {loadingChapter === chapterIndex
                    ? 'Hämtar lektion...'
                    : chapter.chapter_content
                      ? 'Återuppta lektion'
                      : 'Hämta lektion'}
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CourseChapters;
