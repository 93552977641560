import React from 'react';
import { Box, Typography, LinearProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { getStyles } from '../index';

const formatEstimatedTime = minutes => {
  if (minutes >= 60) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    if (remainingMinutes === 0) {
      return `${hours} ${hours === 1 ? 'timme' : 'timmar'}`;
    }
    return `${hours} ${hours === 1 ? 'timme' : 'tim'} och ${remainingMinutes} min`;
  }
  return `${minutes} minuter`;
};

const CourseProgress = ({ totalModules, totalChapters, estimatedTime, courseProgress }) => {
  const theme = useTheme();
  const styles = getStyles(theme);

  const formattedTime = formatEstimatedTime(estimatedTime);

  return (
    <Box
      sx={{
        ...styles.softerBorder,
        mb: 4,
        backgroundImage: 'none',
        backgroundColor: theme.palette.mode === 'dark' ? 'rgb(30, 41, 53)' : 'white',
        borderRadius: '7px',
        padding: '30px',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
        <Typography
          variant='h6'
          sx={{
            ...styles.grayText,
            fontSize: '15px',
            display: 'flex',
            alignItems: 'center',
            mr: 3,
          }}
        >
          <MenuBookIcon sx={{ mr: 1 }} />
          Kursen har {totalChapters} kapitel fördelat på {totalModules} delkurser
        </Typography>
        <Typography
          variant='h6'
          sx={{ ...styles.grayText, fontSize: '15px', display: 'flex', alignItems: 'center' }}
        >
          <AccessTimeIcon sx={{ mr: 1 }} />
          {formattedTime} för att slutföra kursen
        </Typography>
      </Box>
      <LinearProgress
        variant='determinate'
        value={courseProgress}
        sx={{ height: 10, borderRadius: 5 }}
      />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
        <Typography variant='body2' sx={{ ...styles.grayText }}></Typography>
        <Typography
          variant='body2'
          sx={{ ...styles.grayText }}
        >{`${Math.round(courseProgress)}% avklarat`}</Typography>
      </Box>
    </Box>
  );
};

export default CourseProgress;
