import React, { useState, useEffect } from 'react';
import { TextField, Autocomplete, CircularProgress, Avatar, Typography, Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { collection, query, getDocs, getDoc, doc } from 'firebase/firestore';
import { db } from './firebase';
import { formatUpvotes } from './functions';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  width: 300,
  '& .MuiInputBase-root': {
    borderRadius: 20,
  },
}));

const ResultItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(1),
  alignItems: 'flex-start',
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: theme.spacing(2),
  width: '100%',
  marginTop: 0,
}));

const SearchAutocomplete = () => {
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (inputValue === '') {
      setOptions([]);
      return;
    }

    setLoading(true);
    const timer = setTimeout(async () => {
      try {
        const teachersRef = collection(db, 'publicTeachers');
        const q = query(teachersRef);
        const snapshot = await getDocs(q);

        const results = await Promise.all(
          snapshot.docs
            .filter(docSnapshot =>
              docSnapshot.data().characterName.toLowerCase().includes(inputValue.toLowerCase())
            )
            .map(async docSnapshot => {
              const teacherData = docSnapshot.data();
              let creatorDisplayName = 'Unknown';
              if (teacherData.userId) {
                const userDocRef = doc(db, 'users', teacherData.userId);
                const userDocSnap = await getDoc(userDocRef);
                if (userDocSnap.exists()) {
                  creatorDisplayName = userDocSnap.data().displayName || 'Unknown';
                }
              }
              return {
                id: docSnapshot.id,
                ...teacherData,
                creatorDisplayName,
              };
            })
        );

        setOptions(results);
      } catch (error) {
        console.error('Error searching teachers:', error);
        setOptions([]);
      } finally {
        setLoading(false);
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [inputValue]);

  return (
    <StyledAutocomplete
      freeSolo
      options={options}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
      loading={loading}
      getOptionLabel={option => option.characterName}
      loadingText='Söker...'
      renderInput={params => (
        <TextField
          {...params}
          placeholder='Sök karaktär'
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                {loading ? <CircularProgress color='inherit' size={20} /> : <SearchIcon />}
                {params.InputProps.startAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={(props, option) => (
        <li {...props}>
          <Link
            to={`/app/chat/${option.id}`}
            style={{ textDecoration: 'none', color: 'inherit', width: '100%' }}
          >
            <ResultItem>
              <Avatar
                src={option.imageUrl}
                alt={option.characterName}
                sx={{ width: 40, height: 40 }}
              />
              <ContentBox>
                <Typography variant='subtitle1' fontWeight='bold' sx={{ mt: 0 }}>
                  {option.characterName}
                </Typography>
                <Typography
                  variant='body2'
                  sx={{ mt: 0.3, mb: 0.3, fontSize: '13px', lineHeight: 1, fontWeight: '500' }}
                >
                  {option.description
                    ? option.description.length > 30
                      ? option.description.substring(0, 30) + '...'
                      : option.description
                    : 'Ingen beskrivning tillgänglig.'}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mt: 0.5,
                  }}
                >
                  <Typography
                    variant='body2'
                    color='text.secondary'
                    sx={{ fontSize: '13px', lineHeight: 1, fontWeight: '500' }}
                  >
                    @{option.creatorDisplayName}
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                    <ThumbUpOffAltIcon sx={{ fontSize: '14px' }} />
                    <Typography
                      variant='body2'
                      sx={{ fontSize: '13px', lineHeight: 1, fontWeight: '500' }}
                    >
                      {formatUpvotes(option.upvotes)}
                    </Typography>
                  </Box>
                </Box>
              </ContentBox>
            </ResultItem>
          </Link>
        </li>
      )}
    />
  );
};

export default SearchAutocomplete;
