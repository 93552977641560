import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Avatar,
  ListSubheader,
  Divider,
  CircularProgress,
  Fade,
  Grid,
} from '@mui/material';
import { callApi } from './api'; // Adjust import path as needed
import { marked } from 'marked';
import ReactMarkdown from 'react-markdown';
import { useTheme } from '@mui/material/styles';
import { getStyles } from '../index';
import ColoringLesson from './ColoringLesson';

import GrejOfTheDay from './GrejOfTheDay';
import TermsGrid from './TermsGrid'; // Adjust the import path as needed
import CharacterMindMap from './CharacterMindMap'; // Adjust the import path as needed
import SentenceFinisherLesson from './SentenceFinisherLesson'; // Adjust the import path as needed
import FormativeComments from './FormativeComments';
import NarrativeLesson from './NarrativeLesson'; // Import the new component
import LessonPlan from './LessonPlan'; // Import the new component

const VALID_LESSON_TYPES = [
  'narrative',
  'theme',
  'sentenceFinisher',
  'grejOfTheDay',
  'formative',
  'lessonPlan',
];

const LessonPlanForm = ({ user }) => {
  const [lessonType, setLessonType] = useState('lessonPlan');
  const [goal, setGoal] = useState('');
  const [develop, setDevelop] = useState('');
  const [grade, setGrade] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');

  const [planData, setPlanData] = useState(null);
  const [themeColor, setThemeColor] = useState('#000000'); // Default theme color
  const theme = useTheme();
  const styles = getStyles(theme);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    // Get the URL parameters
    const params = new URLSearchParams(window.location.search);
    const typeParam = params.get('typ');

    // If there's a type parameter and it's valid, set it
    if (typeParam && VALID_LESSON_TYPES.includes(typeParam)) {
      setLessonType(typeParam);
    }
  }, []); // Empty dependency array means this runs once when component mounts

  return (
    <Box sx={styles.container}>
      <Box
        sx={{
          ...styles.innerContainer,
          width: { xs: '100%', md: '85%' },
          margin: '0 auto',
        }}
      >
        <Typography
          variant='body1'
          sx={{ ...styles.grayText, mb: 0.4, textAlign: 'center', mt: 5 }}
        >
          TELLUSGRUPPEN
        </Typography>
        <Typography
          variant='h3'
          gutterBottom
          sx={{ fontWeight: 'bold', fontSize: '1.3em', mb: 4, mt: 0, textAlign: 'center' }}
        >
          Lektionspaket (BETA)
        </Typography>

        <Box sx={{ ...styles.freshBox, padding: '30px' }}>
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={12} sm={6}>
              <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500' }}>
                1. Typ:
              </Typography>
              <FormControl fullWidth margin='normal' sx={{ mb: 3 }}>
                <InputLabel>Typ</InputLabel>
                <Select
                  value={lessonType}
                  onChange={e => setLessonType(e.target.value)}
                  label='Typ'
                >
                  <ListSubheader>Lektionstyper</ListSubheader>
                  <MenuItem value='narrative'>Berättande text</MenuItem>
                  <MenuItem value='theme'>Glosor i lådor</MenuItem>
                  <MenuItem value='sentenceFinisher'>Fortsätt berättelsen</MenuItem>
                  <MenuItem value='grejOfTheDay'>Grej of the Day</MenuItem>
                  <MenuItem value='coloringLesson'>Målarbilder</MenuItem>
                  <ListSubheader>Bedömningar</ListSubheader>
                  <MenuItem value='formative'>Formativa bedömningar</MenuItem>
                  <ListSubheader>Planering</ListSubheader>
                  <MenuItem value='lessonPlan'>Lektionsplanering</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500' }}>
                2. Årskurs:
              </Typography>
              <FormControl fullWidth margin='normal' sx={{ mb: 3 }}>
                <InputLabel>Årskurs</InputLabel>
                <Select value={grade} onChange={e => setGrade(e.target.value)} label='Årskurs'>
                  <ListSubheader>Grundskolan</ListSubheader>
                  <MenuItem value='åk1'>Åk 1</MenuItem>
                  <MenuItem value='åk2'>Åk 2</MenuItem>
                  <MenuItem value='åk3'>Åk 3</MenuItem>
                  <MenuItem value='åk4'>Åk 4</MenuItem>
                  <MenuItem value='åk5'>Åk 5</MenuItem>
                  <MenuItem value='åk6'>Åk 6</MenuItem>
                  <MenuItem value='åk7'>Åk 7</MenuItem>
                  <MenuItem value='åk8'>Åk 8</MenuItem>
                  <ListSubheader>Gymnasiet</ListSubheader>
                  <MenuItem value='firstRing'>1:an</MenuItem>
                  <MenuItem value='secondRing'>2:an</MenuItem>
                  <MenuItem value='thirdRing'>3:an</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Divider sx={{ mb: 10 }} />
          {lessonType === 'coloringLesson' && (
            <ColoringLesson user={user} theme={theme} styles={styles} />
          )}
          {lessonType === 'theme' && <TermsGrid user={user} theme={theme} />}
          {lessonType === 'narrative' && <NarrativeLesson user={user} />}
          {lessonType === 'sentenceFinisher' && (
            <SentenceFinisherLesson user={user} theme={theme} styles={styles} />
          )}
          {lessonType === 'formative' && <FormativeComments user={user} />}
          {lessonType === 'lessonPlan' && <LessonPlan user={user} theme={theme} styles={styles} />}
          {lessonType === 'grejOfTheDay' && (
            <GrejOfTheDay user={user} theme={theme} styles={styles} grade={grade} />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default LessonPlanForm;
