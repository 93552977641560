import React, { useState, useEffect } from 'react';
import {
    Box,
    Drawer,
    Typography,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    TextField,
} from '@mui/material';
import { ClipLoader } from 'react-spinners';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { ChevronRight } from '@mui/icons-material';
import LoadingSpinner from './LoadingSpinner';

const ChatHistoryDrawer = ({
    drawerOpen,
    setDrawerOpen,
    oldishLoading,
    chats,
    editingChat,
    handleChatSelect,
    handleChatNameChange,
    handleSaveChatName,
    handleStartEditing,
    savingChatName,
    isDark,
}) => {
    const [groupedChats, setGroupedChats] = useState({
        today: [],
        yesterday: [],
        thisWeek: [],
        thisMonth: [],
        aWhileAgo: [],
    });

    useEffect(() => {
        const categorizeChats = () => {
            const now = new Date();
            const yesterday = new Date(now);
            yesterday.setDate(yesterday.getDate() - 1);
            const oneWeekAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
            const oneMonthAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);

            const grouped = chats.reduce(
                (acc, chat) => {
                    if (chat.createdAt && chat.createdAt.seconds) {
                        const chatDate = new Date(chat.createdAt.seconds * 1000);
                        if (chatDate >= new Date(now.setHours(0, 0, 0, 0))) {
                            acc.today.push(chat);
                        } else if (chatDate >= new Date(yesterday.setHours(0, 0, 0, 0))) {
                            acc.yesterday.push(chat);
                        } else if (chatDate >= oneWeekAgo) {
                            acc.thisWeek.push(chat);
                        } else if (chatDate >= oneMonthAgo) {
                            acc.thisMonth.push(chat);
                        } else {
                            acc.aWhileAgo.push(chat);
                        }
                    } else {
                        acc.aWhileAgo.push(chat);
                    }
                    return acc;
                },
                { today: [], yesterday: [], thisWeek: [], thisMonth: [], aWhileAgo: [] }
            );

            setGroupedChats(grouped);
        };

        categorizeChats();
    }, [chats]);

    const renderChatGroup = (groupTitle, chats) => {
        if (chats.length === 0) return null;

        return (
            <>
                <Typography
                    variant='subtitle1'
                    sx={{
                        fontWeight: '500',
                        fontSize: '15px',
                        paddingTop: '15px',
                        marginTop: '10px',
                    }}
                >
                    {groupTitle}
                </Typography>
                <List>
                    {chats.map(chat => (
                        <ListItem
                            variant='compactList'
                            className='rounded'
                            sx={{
                                padding: 0,
                                margin: 0,
                            }}
                            key={chat.id}
                            button={editingChat.id !== chat.id}
                            onClick={() => editingChat.id !== chat.id && handleChatSelect(chat.id)}
                        >
                            <ListItemIcon sx={{ minWidth: '33px !important' }}>
                                <ChatBubbleOutlineIcon
                                    sx={{ marginRight: '0px', marginLeft: '8px' }}
                                />
                            </ListItemIcon>
                            {editingChat.id === chat.id ? (
                                <>
                                    <TextField
                                        value={editingChat.name}
                                        onChange={handleChatNameChange}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault();
                                                handleSaveChatName();
                                            }
                                        }}
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                padding: '4px',
                                            },
                                        }}
                                        autoFocus
                                        onClick={e => e.stopPropagation()}
                                    />
                                    {savingChatName ? (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                marginLeft: 1,
                                            }}
                                        >
                                            <ClipLoader size={20} color={'#123abc'} />
                                        </Box>
                                    ) : (
                                        <IconButton
                                            onClick={e => {
                                                e.stopPropagation();
                                                handleSaveChatName();
                                            }}
                                        >
                                            <SaveIcon />
                                        </IconButton>
                                    )}
                                </>
                            ) : (
                                <>
                                    <ListItemText
                                        primary={chat.name}
                                        sx={{
                                            paddingLeft: 0,
                                            marginLeft: 0,
                                            fontSize: '14px',
                                            '& .MuiTypography-root': {
                                                color: isDark ? '#b0b4b8' : '#4a5568',
                                                fontSize: '14px',
                                                fontWeight: '500',
                                            },
                                        }}
                                    />
                                    <IconButton
                                        onClick={e => {
                                            e.stopPropagation();
                                            handleStartEditing(chat.id, chat.name);
                                        }}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </>
                            )}
                        </ListItem>
                    ))}
                </List>
            </>
        );
    };

    return (
        <Drawer
            PaperProps={{
                sx: {
                    width: { xs: '75%', sm: '50%', md: '25%' },
                    boxShadow: 'none',
                    borderLeft: isDark ? '1px solid #34414c' : '1px solid #e0e0e0',
                    backgroundImage: 'none',
                },
            }}
            ModalProps={{
                BackdropProps: {
                    invisible: true,
                },
            }}
            anchor='right'
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}
        >
            <Box sx={{ p: 3 }}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <IconButton
                        onClick={() => setDrawerOpen(false)}
                        color='inherit'
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <ChevronRight />
                    </IconButton>
                    <Typography
                        variant='h6'
                        sx={{
                            fontSize: '17px',
                            ml: 2,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        Historik
                    </Typography>
                </Box>

                {oldishLoading ? (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'top',
                            justifyContent: 'top',
                            height: '100%',
                        }}
                    >
                        <LoadingSpinner noWrapper='true' />
                        <Typography variant='body1' sx={{ marginLeft: 1 }}></Typography>
                    </Box>
                ) : (
                    <>
                        {renderChatGroup('I dag', groupedChats.today)}
                        {renderChatGroup('I går', groupedChats.yesterday)}
                        {renderChatGroup('Denna vecka', groupedChats.thisWeek)}
                        {renderChatGroup('Denna månad', groupedChats.thisMonth)}
                        {renderChatGroup('För ett tag sedan', groupedChats.aWhileAgo)}
                    </>
                )}
            </Box>
        </Drawer>
    );
};

export default ChatHistoryDrawer;
