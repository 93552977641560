import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Box,
  Button,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Paper,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';

const Header = ({ contactFormRef }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [openSubMenuIndex, setOpenSubMenuIndex] = useState(null);
  let submenuTimeout = null;

  useEffect(() => {
    const handleScroll = () => {
      const gradientBackground = document.querySelector('.gradient-background');
      if (gradientBackground) {
        const scrollPosition = window.scrollY;
        const gradientHeight = gradientBackground.offsetHeight;
        setIsScrolled(scrollPosition > gradientHeight);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToContact = () => {
    contactFormRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleSubMenuOpen = index => {
    if (submenuTimeout) {
      clearTimeout(submenuTimeout);
    }
    setOpenSubMenuIndex(index);
  };

  const handleSubMenuClose = () => {
    submenuTimeout = setTimeout(() => {
      setOpenSubMenuIndex(null);
    }, 200);
  };

  const menuItems = [
    // {
    //   label: 'Logga in',
    //   path: '/app/logga-in',
    // },

    // {
    //   label: 'För elever',
    //   path: '/ai-verktyg-elever',
    // },
    // { label: 'Priser', path: '/priser' },
    // { label: 'Om', path: '/om-oss' },
    { label: 'Boka en demo', action: scrollToContact }, // Changed to use action instead of path
  ];

  const renderMenuItem = (item, index) => {
    if (item.action) {
      return (
        <Button
          onClick={item.action}
          sx={{
            textTransform: 'none',
            color: 'white',
            fontWeight: '600',
            '&:hover': { background: '#ffffff12' },
            display: 'flex',
            alignItems: 'center',
            px: 2,
            py: 1,
            fontSize: '1.1rem',
          }}
        >
          {item.label}
        </Button>
      );
    }

    return (
      <Button
        component={Link}
        to={item.path}
        sx={{
          textTransform: 'none',
          color: 'white',
          fontWeight: '600',
          '&:hover': { background: '#ffffff12' },
          display: 'flex',
          alignItems: 'center',
          px: 2,
          py: 1,
          fontSize: '1.1rem',
        }}
      >
        {item.label}
        {item.submenu && <ExpandMoreIcon sx={{ ml: 0.5, fontSize: '1.25rem' }} />}
      </Button>
    );
  };

  return (
    <AppBar
      elevation={isScrolled ? 4 : 0}
      sx={{
        backdropFilter: isScrolled ? 'blur(6px)' : 'none',
        background: isScrolled
          ? 'linear-gradient(135deg, rgba(32, 6, 96, 0.8) 0%, rgba(61, 19, 104, 0.8) 100%)'
          : 'transparent',
        transition: 'all 0.3s ease-in-out',
        pl: { xs: 0, md: 4 },
      }}
    >
      <Container maxWidth='xl'>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', py: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Link to='/' style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
              <Box
                component='img'
                src='/img/AI.png'
                alt='Studera.AI Logo'
                sx={{ height: 40, marginRight: 2 }}
              />
              <Typography
                variant='h6'
                component='div'
                sx={{ fontWeight: 'bold', color: 'white', fontSize: '1.45rem' }}
              >
                Studera.ai
              </Typography>
            </Link>
          </Box>
          {isMobile ? (
            <IconButton
              edge='start'
              color='inherit'
              aria-label='menu'
              onClick={handleDrawerToggle}
              sx={{ color: 'white' }}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              {menuItems.map((item, index) => (
                <Box
                  key={item.label}
                  onMouseEnter={() => item.submenu && handleSubMenuOpen(index)}
                  onMouseLeave={handleSubMenuClose}
                  sx={{ position: 'relative' }}
                >
                  {renderMenuItem(item, index)}
                  {item.submenu && openSubMenuIndex === index && (
                    <Paper
                      elevation={4}
                      sx={{
                        position: 'absolute',
                        top: '100%',
                        left: 0,
                        backgroundColor: '#212121',
                        mt: 1,
                        zIndex: 2,
                        minWidth: 220,
                        borderRadius: 2,
                        overflow: 'hidden',
                      }}
                      onMouseEnter={() => clearTimeout(submenuTimeout)}
                      onMouseLeave={handleSubMenuClose}
                    >
                      {item.submenu.map(subItem => (
                        <Button
                          key={subItem.path}
                          component={Link}
                          to={subItem.path}
                          sx={{
                            textTransform: 'none',
                            color: 'white',
                            fontWeight: '600',
                            display: 'block',
                            width: '100%',
                            textAlign: 'left',
                            py: 1.5,
                            px: 3,
                            '&:hover': { background: '#ffffff12' },
                          }}
                        >
                          {subItem.label}
                        </Button>
                      ))}
                    </Paper>
                  )}
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Container>
      <Drawer
        anchor='right'
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{ keepMounted: true }}
      >
        <Box sx={{ width: 250, pt: 2 }} role='presentation'>
          <IconButton sx={{ ml: 1 }} onClick={handleDrawerToggle}>
            <CloseIcon />
          </IconButton>
          <List>
            {menuItems.map((item, index) => (
              <Box key={item.label}>
                <ListItem
                  button
                  onClick={() => {
                    if (item.action) {
                      item.action();
                      handleDrawerToggle();
                    }
                  }}
                  component={item.path ? Link : 'div'}
                  to={item.path}
                  sx={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <ListItemText primary={item.label} />
                  {item.submenu && <ExpandMoreIcon />}
                </ListItem>
                {item.submenu &&
                  item.submenu.map(subItem => (
                    <ListItem
                      key={subItem.path}
                      button
                      component={Link}
                      to={subItem.path}
                      sx={{ pl: 4 }}
                    >
                      <ListItemText primary={subItem.label} />
                    </ListItem>
                  ))}
              </Box>
            ))}
          </List>
        </Box>
      </Drawer>
    </AppBar>
  );
};

export default Header;
