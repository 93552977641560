import React, { useState, useEffect, useRef, lazy } from 'react';

import { db } from './firebase';
import {
  collection,
  addDoc,
  getDocs,
  query,
  where,
  deleteDoc,
  doc,
  getDoc,
} from 'firebase/firestore';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LanguageSelector from './LanguageSelector';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import ProgressBar from './ProgressBar';
import PrintIcon from '@mui/icons-material/Print';
import { trackLesson } from './functions';

import CheckCircleIcon from '@mui/icons-material/Check';
import { updateUserTokens } from './functions';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CancelIcon from '@mui/icons-material/Cancel';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { addNote } from './NotesView';
import { handlePrintQuiz } from './printFunctions';
import ExploreIcon from '@mui/icons-material/Explore';
import SettingsIcon from '@mui/icons-material/Settings';
import Logger from './Logger';

import { Helmet } from 'react-helmet-async';
import DrawerComponent from './quiz/DrawerComponent';

import QuestionTypeSelector from './quiz/QuestionTypeSelector';

import { Link as LinkIcon } from '@mui/icons-material';

import LoadingSpinner from './LoadingSpinner';

import { saveQuizResult } from './quizFunctions';
import { UserPercentile, UserProgressChart } from './quizResultComponents';

import TeacherQuizDisplay from './TeacherQuizDisplay';

import { Fade, Grid } from '@mui/material';

import AIDialog from './AIDialog';
import SegmentIcon from '@mui/icons-material/Segment';

import GenerateIcon from '@mui/icons-material/AutoAwesome';

import AddIcon from '@mui/icons-material/Add';

import { callApi, callClaude } from './api';
import { Howl } from 'howler';
import selectSoundFile from './sounds/select.mp3';
import select2 from './sounds/select2.mp3';
import done from './sounds/done.mp3';
import { useSnackbar } from './SnackbarHandler';

import { AIModelSelect } from './TestComponents2';

import { sanitizeInput } from './functions';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import { getStyles } from '../index';
import { useMediaQuery, Collapse } from '@mui/material';

import { ThemeSelectorFlow } from './ThemeSelectorFlow';

import QuizInProgress from './QuizInProgress';

import MyQuizzes from './quiz/MyQuizzes';

import AdditionalFields from './AdditionalFields'; // Regular import instead of lazy

import {
  History,
  Castle,
  Factory,
  PublicOutlined,
  Science,
  Biotech,
  Functions,
  Timeline,
  CalculateOutlined,
  MenuBook,
  Mosque,
} from '@mui/icons-material';

const QuizView = ({ user }) => {
  const [quiz, setQuiz] = useState([]);
  const [quizzes, setQuizzes] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [creatorInfo, setCreatorInfo] = useState(null);
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [viewIsLoading, setViewIsLoading] = useState(true);

  const [results, setResults] = useState([]);
  const [showSummary, setShowSummary] = useState(false);
  const [inputType, setInputType] = useState('topic');
  const [inputValue, setInputValue] = useState('');
  /// Moonshine: Set back to 10!
  const [numQuestions, setNumQuestions] = useState(5);
  const [quizName, setQuizName] = useState('');
  const [isManaging, setIsManaging] = useState(false);

  const [isGeneratingQuestionsInDialog, setIsGeneratingQuestionsInDialog] = useState(false);

  const [quizInProgress, setQuizInProgress] = useState(false);
  const [currentQuizId, setCurrentQuizId] = useState(null);
  const [loadingQuiz, setLoadingQuiz] = useState(false); // State for loading quiz

  const [loadingQuestions, setLoadingQuestions] = useState(false);

  const [loadingMyQuizzes, setLoadingMyQuizzes] = useState(false); // State for loading quiz

  const [selectedValue, setSelectedValue] = useState('topic');

  const [currentQuestionNumber, setCurrentQuestionNumber] = useState(1);

  const [showLibrary, setShowLibrary] = useState(false);

  const [isPublic, setIsPublic] = useState(true);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const [customTheme, setCustomTheme] = useState('');
  const [lessonTheme, setLessonTheme] = useState('');
  const [predefinedTheme, setPredefinedTheme] = useState(null);

  const transcriptRef = useRef('');
  const uploadedFileRef = useRef('');

  const wikipediaContentRef = useRef('');
  const randomizedContentRef = useRef('');
  const coursePlanContentRef = useRef('');

  const [originalQuizId, setOriginalQuizId] = useState('');

  const [dynamicTitle, setDynamicTitle] = useState('Quiz — Skapa quiz med AI — Studera.AI');
  const [dynamicDescription, setDynamicDescription] = useState(
    'Skapa quiz med AI. Du kan generera ett quiz med AI genom att skriva in ett ämne eller en längre textmassa.'
  );

  const [questionType, setQuestionType] = useState('multiple-choice');

  const currentQuestion = quiz[currentQuestionIndex];
  const [quizCreatedAt, setQuizCreatedAt] = useState([]);
  const [openAIDialogs, setOpenAIDialogs] = useState({});
  const theme = useTheme();
  const styles = getStyles(theme);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { showSnackbar } = useSnackbar();

  const { quizId, urlQuizName } = useParams();
  const navigate = useNavigate();

  const [showCreateQuizForm, setShowCreateQuizForm] = useState(true);
  const teacherQuizDisplayRef = useRef(null);
  const selectSound = new Howl({ src: [selectSoundFile], volume: 0.1 });
  const nextSound = new Howl({ src: [select2], volume: 0.6 });
  const doneSound = new Howl({ src: [done], volume: 0.2 });

  const [selectedLanguage, setSelectedLanguage] = useState('svenska');
  const [selectedModel, setSelectedModel] = useState('Studera.AI');
  const selectedSuggestionRef = useRef('');

  const [progress, setProgress] = useState(0);

  const progressIntervalRef = useRef(null);

  const progressRef = useRef(null);
  const progressPercentRef = useRef(null);

  const [aiDialogOpen, setAiDialogOpen] = useState(false);

  const [category, setCategory] = useState('Välj kategori');
  const [difficulty, setDifficulty] = useState('Medel');
  const [aiInstructions, setAiInstructions] = useState('');

  const subjectInputRef = useRef(null);

  const wikiUrlInputRef = useRef(null);
  const [bookmarks, setBookmarks] = useState({});

  const location = useLocation();

  useEffect(() => {
    Logger.log('lessonTheme: ', lessonTheme);
  }, [lessonTheme]);

  useEffect(() => {
    Logger.log('predefinedTheme: ', predefinedTheme);
  }, [predefinedTheme]);

  useEffect(() => {
    Logger.log('customTheme: ', customTheme);
  }, [customTheme]);

  useEffect(() => {
    Logger.log('youtubeTranscript: ', transcriptRef.current);
  }, [transcriptRef.current]);

  useEffect(() => {
    Logger.log('wikipediaContentRef: ', wikipediaContentRef.current);
  }, [wikipediaContentRef.current]);
  useEffect(() => {
    Logger.log('uploadedFileRef: ', uploadedFileRef.current);
  }, [uploadedFileRef.current]);

  useEffect(() => {
    Logger.log('randomizedContentRef: ', randomizedContentRef.current);
  }, [randomizedContentRef.current]);

  useEffect(() => {
    Logger.log('coursePlanContentRef: ', coursePlanContentRef.current);
  }, [coursePlanContentRef.current]);

  const additionalThemes = [
    { type: 'subheader', label: 'Teman som lämpar sig extra bra för quiz:' },
    {
      value: 'selma',
      label: 'Selma Lagerlöf',
      content: 'Gör ett quiz om selma lagerlöf',
      Icon: MenuBook,
    },
    {
      value: 'ancient-civilizations',
      label: 'Antikens civilisationer',
      content:
        'Generera ett quiz om de stora antika civilisationerna som Egypten, Grekland, Rom och Mesopotamien. Fokusera på deras kulturer, styrelseskick, arkitektur och bidrag till världshistorien.',
      Icon: History,
    },
    {
      value: 'medieval-feudalism',
      label: 'Medeltiden och feodalism',
      content:
        'Utforma ett quiz om det medeltida samhället i Europa, med fokus på feodalismen. Inkludera frågor om samhällsklasser, riddarväsendet, kyrkans roll och den medeltida ekonomin.',
      Icon: Castle,
    },
    {
      value: 'industrialization',
      label: 'Industrialiseringen',
      content:
        'Skapa ett quiz om industrialiseringens framväxt och effekter. Täck in viktiga uppfinningar, sociala förändringar, urbanisering och hur den påverkade olika länder och samhällsklasser.',
      Icon: Factory,
    },
    {
      value: 'colonialism-effects',
      label: 'Kolonialismens effekter',
      content:
        'Generera ett quiz om kolonialismens långsiktiga konsekvenser för både kolonisatörer och koloniserade. Inkludera frågor om ekonomisk exploatering, kulturell påverkan och avkolonialiseringen.',
      Icon: PublicOutlined,
    },
    {
      value: 'immune-evolution',
      label: 'Evolution av immunförsvar hos olika organismer',
      content:
        'Generera ett quiz om hur immunförsvaret har utvecklats hos olika organismgrupper. Inkludera frågor om medfödd och adaptiv immunitet, evolutionära anpassningar och jämförelser mellan arter.',
      Icon: Science,
    },
    {
      value: 'clinical-biology',
      label: 'Klinisk biologi och laboratoriemedicin',
      content:
        'Skapa ett quiz om biologiska tekniker som används inom klinisk diagnostik. Inkludera frågor om blodprovsanalyser, mikrobiologiska tester, genetisk screening och bilddiagnostik.',
      Icon: Biotech,
    },
    {
      value: 'probability-statistics',
      label: 'Sannolikhet och statistik',
      content:
        'Utforma ett quiz om grundläggande sannolikhetsteori och statistik. Täck in begrepp som sannolikhetsfördelningar, väntevärde, standardavvikelse, hypotesprövning och konfidensintervall.',
      Icon: Functions,
    },
    {
      value: 'mathematical-patterns',
      label: 'Matematiska mönster och sekvenser',
      content:
        'Skapa ett quiz om olika typer av matematiska mönster och sekvenser. Inkludera aritmetiska och geometriska följder, Fibonacci-sekvensen och rekursiva formler.',
      Icon: Timeline,
    },
    {
      value: 'trigonometry',
      label: 'Trigonometri',
      content:
        'Generera ett quiz om trigonometriska funktioner, identiteter och ekvationer. Inkludera frågor om enhetscirkeln, trigonometriska grafer och tillämpningar i verkliga livet.',
      Icon: CalculateOutlined,
    },
    {
      value: 'sufi-texts',
      label: 'Sufismens heliga texter',
      content:
        "Skapa ett quiz om de heliga texterna inom sufismen, som Rumi och Hafiz' verk. Fråga om deras centrala teman och poesi.",
      Icon: Mosque,
    },
    {
      value: 'quran-mysticism',
      label: 'Koranens mystiska tolkningar',
      content:
        'Generera ett quiz om de mystiska tolkningarna av Koranen inom islams traditioner, inklusive sufismens synsätt.',
      Icon: Mosque,
    },
  ];

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sourceParam = params.get('source');

    switch (sourceParam) {
      case 'youtube':
        setInputType('youtube');
        break;
      case 'wiki':
        setInputType('wikipedia');
        break;
      case 'word':
        setInputType('text');
        break;
      case 'topic':
        setInputType('topic');
        break;
      default:
        setInputType('topic');
        break;
    }

    // Optionally, update the URL to remove the query parameter
    // navigate('/app/quiz', { replace: true });
  }, [location, navigate]);

  const handleIdeaGenerated = idea => {
    subjectInputRef.current.value = idea;
  };
  const handleWikiIdeaGenerated = idea => {
    wikiUrlInputRef.current.value = idea;
  };

  const toggleBookmark = async () => {
    const currentQuestion = quiz[currentQuestionIndex];
    const content = `<strong>Fråga:</strong><br /> ${currentQuestion.question}\n <br /><br /><strong>Alternativ:</strong><br /> ${currentQuestion.options.join(', ')}\n <br /><br />Rätt svar: ${currentQuestion.correct}`;
    const conversationType = `quiz-${currentQuizId}`;

    if (bookmarks[currentQuestionIndex]) {
      // If already bookmarked, you might want to remove the bookmark
      // This depends on how you've implemented the addNote and removeNote functions
      // await removeNote(user.uid, bookmarks[currentQuestionIndex]);
      setBookmarks(prev => {
        const newBookmarks = { ...prev };
        delete newBookmarks[currentQuestionIndex];
        return newBookmarks;
      });
    } else {
      const noteId = await addNote(user.uid, content, null, conversationType);
      setBookmarks(prev => ({ ...prev, [currentQuestionIndex]: noteId }));
    }
  };

  const handleOpenAIDialog = index => {
    setOpenAIDialogs(prev => ({ ...prev, [index]: true }));
  };

  const handleCloseAIDialog = index => {
    setOpenAIDialogs(prev => ({ ...prev, [index]: false }));
  };
  const handleAskAI = () => {
    Logger.log('Opening AI Dialog from handleAskAI');
    setAiDialogOpen(true);
  };

  const formatUrlPart = str => {
    return str
      .replace(/\s+/g, '-')
      .replace(/[åä]/g, 'a')
      .replace(/[ö]/g, 'o')
      .replace(/[^a-z0-9-]/gi, '')
      .toLowerCase();
  };

  const updateProgress = value => {
    if (progressRef.current) {
      progressRef.current.style.width = `${value}%`;
    }
    if (progressPercentRef.current) {
      progressPercentRef.current.textContent = `${Math.round(value)}%`;
    }
  };

  const handleNewQuiz = () => {
    selectedSuggestionRef.current = '';

    setShowCreateQuizForm(true);
    setShowSummary(false);
    setQuiz([]);
    setQuizInProgress(false);
    setCurrentQuizId(null); // Lägg till denna rad
    setResults([]); // Lägg till denna rad

    // återställ alla fält

    setInputType('topic');
    setInputValue('');
    setOriginalQuizId('');
    setNumQuestions(5);
    setQuizName('');
    setSelectedLanguage('svenska');
    setSelectedModel('Studera.AI');
    setQuestionType('multiple-choice');
    setIsPublic(true);
    setCurrentQuestionIndex(0);
    setSelectedOption(null);

    navigate(`/app/quiz`);
  };

  const simulateProgress = (startProgress, endProgress, duration) => {
    const stepSize = 0.5;
    const steps = (endProgress - startProgress) / stepSize;
    const stepDuration = duration / steps;
    let currentProgress = startProgress;

    const intervalId = setInterval(() => {
      currentProgress += stepSize;
      if (currentProgress >= endProgress) {
        clearInterval(intervalId);
      } else {
        setProgress(currentProgress);
      }
    }, stepDuration);

    return () => clearInterval(intervalId);
  };

  const fetchQuiz = async (
    inputType,
    content,
    numQuestions,
    category,
    difficulty,
    aiInstructions
  ) => {
    setProgress(0);

    // let content = '';
    // if (inputType === 'wikipedia') {
    //   content = await fetchWikipediaContent(inputValue);
    // } else {
    //   content = inputValue;
    //}

    // if (selectedSuggestionRef.current) {
    //   content = selectedSuggestionRef.current;
    // }

    Logger.log('content to generate: ', content);
    Logger.log('from selectedSuggestion: ', selectedSuggestionRef.current);

    if (content.trim() === '') {
      content =
        'Ge mig ett quiz med blandade frågor som har med nåt som kräver åtminstone lite bildning.'; // Eller något annat standardvärde du vill använda
    }

    Logger.log('inputType: ', inputType);
    Logger.log('inputValue: ', inputValue);
    Logger.log('numQuestions: ', numQuestions);
    Logger.log('Selected language:', selectedLanguage);
    Logger.log('Selected model:', selectedModel);
    Logger.log('questionType: ', questionType);
    Logger.log('content: ', content);

    // let instructions = '';
    // if (questionType === "fill-in-the-blank")
    //   instructions = "Generate a fill in the blanks quiz with ${numQuestions} questions based on the provided text. Everything should be in Swedish. Each question should be a JSON object with a 'question' field containing the text with a blank (____),an 'options' field containing 4 options, and a 'correct' field. Ensure all questions are returned in a single JSON array. Format example: [{ 'question': 'En sekvens av ______ baser bestämmer genetisk information', 'answer': 'Kvävehaltiga', 'options': ['Kvävehaltiga', 'Guld', 'Brom', 'Gas'] }]";
    // else
    //   instructions = `Generate a quiz with ${numQuestions} questions. The quiz should be in ${selectedLanguage}. Each question should be a JSON object with a "question" field, an "options" field containing 4 options, and a "correct" field. Ensure all questions are returned in a single JSON array. Do not use any markdown formatting. Ensure that the JSON keys 'question', 'options', and 'correct' are kept in English, even when the content is in another language. Format example: [{ "question": "Who won the FIFA World Cup in 2018?", "options": ["Brazil", "France", "Germany", "Argentina"], "correct": "France" }]`;

    let instructions = '';
    if (questionType === 'fill-in-the-blank') {
      instructions = `Generate a fill in the blanks quiz with ${numQuestions} questions based on the provided text. Everything should be in ${selectedLanguage}. Each question should be a JSON object with a 'question' field containing the text with a blank (_), an 'options' field containing 4 options, and a 'correct' field. Ensure all questions are returned in a single JSON array. Format example: [{ 'question': 'En sekvens av _ baser bestämmer genetisk information', 'answer': 'Kvävehaltiga', 'options': ['Kvävehaltiga', 'Guld', 'Brom', 'Gas'] }]`;
    } else if (questionType === 'true-or-false') {
      instructions = `Generate a true or false quiz with ${numQuestions} questions. The quiz should be in ${selectedLanguage}. Each question should be a JSON object with a "question" field, an "options" field containing only "True" and "False" as options, and a "correct" field. Ensure all questions are returned in a single JSON array. Do not use any markdown formatting. Ensure that the JSON keys 'question', 'options', and 'correct' are kept in English, even when the content is in another language. Format example: [{ "question": "Is the Earth flat?", "options": ["Sant", "Falskt"], "correct": "Falskt" }]`;
    } else {
      instructions = `Generate a quiz with ${numQuestions} questions. The quiz should be in ${selectedLanguage}. Each question should be a JSON object with a "question" field, an "options" field containing 4 options, and a "correct" field. Ensure all questions are returned in a single JSON array. Do not use any markdown formatting. Ensure that the JSON keys 'question', 'options', and 'correct' are kept in English, even when the content is in another language. Format example: [{ "question": "Who won the FIFA World Cup in 2018?", "options": ["Brazil", "France", "Germany", "Argentina"], "correct": "France" }]`;
    }

    if (category && category !== 'Välj kategori') {
      instructions += ` The quiz should be focused on the category: ${category}.`;
    }

    if (difficulty) {
      instructions += ` The difficulty level should be ${difficulty}.`;
    }

    if (aiInstructions) {
      instructions += ` Additional instructions: ${aiInstructions}`;
    }

    let messages = '';

    if (selectedModel === 'Claude')
      messages = [{ role: 'user', content: `${instructions}\n\nTopic: ${content}` }];
    else
      messages = [
        { role: 'system', content: instructions },
        { role: 'user', content: `Topic: ${content}` },
      ];

    Logger.log('message to api: ', messages);

    let stopProgressSimulation = null;

    try {
      stopProgressSimulation = simulateProgress(0, 90, 15000);
      let response = '';

      if (selectedModel === 'Claude') response = await callApi(messages, 4000, true, user.uid);
      else response = await callApi(messages, 4000, true, user.uid);

      Logger.log('response: ', response);

      let messageContent = '';
      // if (selectedModel === 'Claude')
      //   messageContent = response.data.content[0].text;
      // else
      messageContent = response.data.choices[0].message.content.trim();

      Logger.log('API response content: ', messageContent);

      if (messageContent.startsWith('```json')) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith('```')) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      // Escapea dubbla citattecken inom strängar
      messageContent = messageContent.replace(/(:\s*")(.+?)(?=")/g, function (match, p1, p2) {
        return p1 + p2.replace(/"/g, '\\"');
      });

      let parsedResponse;
      try {
        parsedResponse = JSON.parse(messageContent);

        Logger.log('Total tokens for quiz:', response.data.usage.total_tokens);
        updateUserTokens(user.uid, response.data.usage.total_tokens);
      } catch (parseError) {
        console.error('Error parsing JSON response:', parseError);
        return [];
      }

      Logger.log('Parsed response: ', parsedResponse);

      if (stopProgressSimulation) {
        stopProgressSimulation();
      }

      setProgress(100);

      return parsedResponse;
    } catch (error) {
      console.error('Error fetching quiz:', error);

      showSnackbar('Knakar i fogarna: Försök generera quizzet igen.', 'info');
      handleNewQuiz();
      return [];
    } finally {
      //updateProgress(0);
    }
  };

  const toggleDrawer = open => () => {
    setDrawerOpen(open);
  };

  const handlePublicChange = event => {
    setIsPublic(event.target.checked);
  };
  const toggleLibrary = () => {
    setShowLibrary(!showLibrary);
  };

  useEffect(() => {
    setCurrentQuestionNumber(currentQuestionIndex + 1);
  }, [currentQuestionIndex]);

  // Funktion för att läsa tangenttryckningar
  useEffect(() => {
    const handleKeyDown = event => {
      Logger.log('isAIDialogOpen handleKeyDown: ', aiDialogOpen);
      if (aiDialogOpen) {
        return;
      }
      if (!quizInProgress) return;

      switch (event.key) {
        case '1':
        case '2':
        case '3':
        case '4':
          // Välj alternativ baserat på tangenttryckning
          const optionIndex = parseInt(event.key) - 1;
          if (optionIndex >= 0 && optionIndex < quiz[currentQuestionIndex].options.length) {
            handleOptionSelect(quiz[currentQuestionIndex].options[optionIndex]);
          }
          break;
        case ' ':
        case 'Enter':
          event.preventDefault();
          Logger.log('selectedOption: ', selectedOption);
          if (selectedOption === null) {
            showSnackbar('Välj ett svarsalternativ.', 'info');
          } else {
            handleNextQuestion();
          }
          break;
        case 'F':
        case 'f':
          handleAskAI();
          break;
        default:
          break;
      }
    };

    // Lägg till händelselyssnare för tangenttryckningar
    document.addEventListener('keydown', handleKeyDown);

    // Städa upp event listener
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [currentQuestionIndex, quiz, quizInProgress, aiDialogOpen, selectedOption]);

  const handleInputChange = e => {
    const { name, value } = e.target;

    if (name === 'inputType') {
      setInputType(value);
    } else if (name === 'quizName') {
      setQuizName(value);
    }
    setSelectedValue(e.target.value);
  };

  const generateRandomQuizName = () => {
    // const prefix = selectedSuggestionRef.current.slice(0, 10);
    // const randomId = Math.floor(1000 + Math.random() * 9000);
    // return `${prefix}-${randomId}`;
    return selectedSuggestionRef.current;
  };

  const loadQuiz = async (generateQuestionsInDialog = false) => {
    if (!quizName.trim()) {
      showSnackbar('Vänligen ange ett quiz-namn.', 'warning');
      return;
    }

    let content;

    if (lessonTheme === 'youtube') {
      content = transcriptRef.current;
    } else if (lessonTheme === 'customTheme') {
      content = customTheme;
    } else if (lessonTheme === 'predefined') {
      content = predefinedTheme;
    } else if (lessonTheme === 'fileUpload') {
      content = uploadedFileRef.current;
    } else if (lessonTheme === 'wikipedia') {
      content = wikipediaContentRef.current;
    } else if (lessonTheme === 'randomizer') {
      content = randomizedContentRef.current;
    } else {
      //
      content = coursePlanContentRef.current;
    }

    Logger.log('themeToUse: ', content);

    if (generateQuestionsInDialog) {
      setLoadingQuestions(true);
    } else {
      setLoadingQuiz(true);
    }

    setCurrentQuizId(null); // Reset currentQuizId before loading a new quiz

    try {
      setTimeout(() => {
        const progressBar = document.querySelector('.progress-bar-container');
        if (progressBar) {
          progressBar.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 500);

      await trackLesson(user.uid, 'QuizView.js', null, {
        lessonTheme: lessonTheme,
      });

      const fetchedQuiz = await fetchQuiz(
        inputType,
        content,
        numQuestions,
        category,
        difficulty,
        aiInstructions
      );
      Logger.log('user.accountType: ', user.accountType);
      if (generateQuestionsInDialog) {
        setIsGeneratingQuestionsInDialog(true);

        setQuiz(fetchedQuiz);

        showSnackbar('Quiz genererat!', 'success');
      } else {
        // Generate a quiz name if it's not provided
        let quizNameToSave = quizName || generateRandomQuizName();
        const sanitizedQuizName = sanitizeInput(quizName || generateRandomQuizName());
        // Save the quiz to the database
        const docRef = await addDoc(collection(db, 'quiz'), {
          userId: user.uid,
          results: fetchedQuiz,
          quizName: sanitizedQuizName,
          createdAt: Date.now(),
          byAdmin: 'No',
          isPublic: isPublic,
          questionType: questionType,
          category,
          difficulty,
          aiInstructions,
          lastTrainingSession: Date.now(),
          language: selectedLanguage,
          aiModel: selectedModel,
          upvotes: 0,
          downvotes: 0,
          userVotes: {},
        });

        Logger.log('Quiz saved with ID: ', docRef.id);

        setQuiz(fetchedQuiz);
        setQuizInProgress(true);
        setCurrentQuizId(docRef.id);

        // Navigate to the new quiz URL
        const formattedQuizName = formatUrlPart(sanitizedQuizName);
        navigate(`/app/quiz/${docRef.id}/${formattedQuizName}`);
      }
    } catch (error) {
      console.error('Error generating and saving quiz:', error);
      showSnackbar('Det uppstod ett fel vid generering av quizet.', 'error');
    } finally {
      setLoadingQuiz(false);
      setLoadingQuestions(false);
      setProgress(0);
      if (!isGeneratingQuestionsInDialog) {
        setTimeout(() => {
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        }, 500);
      }
    }
  };

  const loadQuizzes = async () => {
    try {
      setLoadingMyQuizzes(true);
      const q = query(collection(db, 'quiz'), where('userId', '==', user.uid));
      const querySnapshot = await getDocs(q);
      const quizzesData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      Logger.log('Fetched quizzes: ', quizzesData);
      setQuizzes(quizzesData);

      setViewIsLoading(false);
    } catch (error) {
      console.error('Error fetching quizzes:', error);
    } finally {
      setLoadingMyQuizzes(false);
    }
  };

  const loadQuizFromParams = async id => {
    try {
      const docRef = doc(db, 'quiz', id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const quizData = docSnap.data();

        if (!quizData.results || quizData.results.length === 0) {
          showSnackbar('Något gick fel. Försök igen eller generera nytt.', 'info');
          handleNewQuiz();
          return;
        }

        //Moonshine: ej säker på denna raden jag la till ,kanske ta bort
        setQuiz(quizData);

        if (quiz) Logger.log('quizzz: ', quizData.createdAt);
        setQuizCreatedAt(quizData.createdAt);

        handleQuizLoad({ id, ...quizData });

        setViewIsLoading(false);

        setDynamicTitle(`${quizData.quizName} — Quiz — Studera.AI`);
        setDynamicDescription(
          `Testa din kunskap i vårt quiz om ${quizData.quizName}. Svårighetsgraden är ${quizData.difficulty}.`
        );

        if (quizData.originalQuizId) {
          setOriginalQuizId(quizData.originalQuizId);
        } else {
          setOriginalQuizId(quizId);
        }

        // Fetch creator info
        if (quizData.userId) {
          const userIdToFetch =
            quizData.isCopied && quizData.originalUserId
              ? quizData.originalUserId
              : quizData.userId;

          const userDoc = await getDoc(doc(db, 'users', userIdToFetch));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setCreatorInfo({
              displayName: userData.displayName || userData.email || 'Unknown',
              avatarUrl: userData.photoURL || null,
              upvotes: quizData.upvotes || 0,
            });
          }
        }

        // Update URL if the name doesn't match
        const formattedQuizName = formatUrlPart(quizData.quizName);
        if (formattedQuizName !== urlQuizName) {
          navigate(`/app/quiz/${id}/${formattedQuizName}`, { replace: true });
        }
      } else {
        showSnackbar('No such quiz!', 'info');
        // Optionally, navigate away or show a different view
      }
    } catch (error) {
      console.error('Error loading quiz from params:', error);
      showSnackbar('Error loading quiz. Please try again.', 'error');
      // Optionally, navigate away or show a different view
    }
  };

  useEffect(() => {
    //loadQuizzes();
    if (quizId) {
      loadQuizFromParams(quizId);
    } else {
      setViewIsLoading(false);
    }
  }, [user, quizId]);

  useEffect(() => {
    if (quiz.length > 0) {
      setCurrentQuestionIndex(0);
      setSelectedOption(null);
      setResults(quiz.map(q => ({ ...q, selectedOption: null, isCorrect: false })));
      setShowSummary(false);
    }
  }, [quiz]);
  // Funktion för att hantera val av alternativ
  const handleOptionSelect = option => {
    // selectSound.play();
    nextSound.play();

    Logger.log(`handleOptionSelect called with option: ${option}`);
    setSelectedOption(option);

    // Uppdatera results omedelbart när ett alternativ väljs
    const currentQuestion = quiz[currentQuestionIndex];
    const isCorrect = option === currentQuestion.correct;
    const updatedResults = [...results];
    updatedResults[currentQuestionIndex] = {
      ...currentQuestion,
      selectedOption: option,
      isCorrect,
    };
    setResults(updatedResults);
  };

  useEffect(() => {
    Logger.log(`selectedOption updated: ${selectedOption}`);
  }, [selectedOption]);

  const handleNextQuestion = async () => {
    selectSound.play();
    Logger.log(`handleNextQuestion called. CurrentQuestionIndex: ${currentQuestionIndex}`);
    Logger.log(`Current quiz state:`, quiz);
    Logger.log(`Current results:`, results);

    if (!quiz || quiz.length === 0 || currentQuestionIndex >= quiz.length) {
      Logger.log('Quiz ended or invalid state');
      return;
    }

    if (currentQuestionIndex < quiz.length - 1) {
      Logger.log(`Moving to next question. New index: ${currentQuestionIndex + 1}`);
      setCurrentQuestionIndex(prevIndex => {
        Logger.log(`Updating currentQuestionIndex from ${prevIndex} to ${prevIndex + 1}`);
        return prevIndex + 1;
      });
      setSelectedOption(null);
    } else {
      doneSound.play();
      Logger.log('Quiz completed');

      const finalResults = [...results];
      finalResults[currentQuestionIndex] = {
        ...quiz[currentQuestionIndex],
        selectedOption,
        isCorrect: selectedOption === quiz[currentQuestionIndex].correct,
      };

      setResults(finalResults);

      setShowSummary(true);

      // oklart om hjäler
      setCurrentQuestionIndex(0);

      setQuizInProgress(false);

      if (currentQuizId) {
        Logger.log('Saving results to database');
        //await generateDummyData(currentQuizId);
        await saveQuizResult(currentQuizId, finalResults, user);
      }

      if (!currentQuizId) {
        Logger.log('Saving results to database');
        await saveResultsToDatabase(results);
      }
    }
  };

  const handleModelChange = (event, newModel) => {
    if (newModel !== null) {
      setSelectedModel(newModel);
    }
  };

  const saveResultsToDatabase = async resultsToSave => {
    try {
      let quizNameToSave;

      if (quizName) {
        quizNameToSave = quizName;
      } else if (inputType === 'wikipedia') {
        quizNameToSave = decodeURIComponent(inputValue.split('/').pop().replace(/_/g, ' '));
      } else if (selectedSuggestionRef.current) {
        quizNameToSave = generateRandomQuizName();
      } else {
        quizNameToSave = inputValue;
      }

      Logger.log('Förbereder sparande av ', quizNameToSave);
      Logger.log('Results to save: ', resultsToSave);

      const docRef = await addDoc(collection(db, 'quiz'), {
        userId: user.uid,
        results: resultsToSave,
        quizName: quizNameToSave,
        createdAt: Date.now(),
        byAdmin: 'No',
        isPublic: isPublic,
        category,
        difficulty,
        aiInstructions,
      });

      Logger.log('Quiz saved with ID: ', docRef.id);
      setInputValue('');
      setCurrentQuizId(docRef.id);
      loadQuizzes();

      await saveQuizResult(docRef.id, resultsToSave);
      //const formattedQuizName = formatUrlPart(quizNameToSave);
      //  navigate(`/app/quiz/${docRef.id}/${formattedQuizName}`);
    } catch (error) {
      console.error('Error saving quiz results:', error);
    }
  };

  const handleQuizLoad = quiz => {
    toggleDrawer(false)();

    Logger.log('Loading quiz: ', quiz);
    setQuiz(quiz.results);
    setQuizName(quiz.quizName);
    setCurrentQuizId(quiz.id);
    setCurrentQuestionIndex(0);
    setSelectedOption(null);
    setResults([]);
    setShowSummary(false);
    setQuizInProgress(true);

    const formattedQuizName = formatUrlPart(quiz.quizName);
    navigate(`/app/quiz/${quiz.id}/${formattedQuizName}`);
  };

  const handleDeleteQuiz = async quizId => {
    try {
      await deleteDoc(doc(db, 'quiz', quizId));
      loadQuizzes();
    } catch (error) {
      console.error('Error deleting quiz:', error);
    }
  };

  const toggleManaging = () => {
    setIsManaging(!isManaging);
  };

  // const backgroundColor = theme.palette.mode === 'dark' ? "#1b2b3c" : "#f1f1f1";
  // const foregroundColor =  theme.palette.mode === 'dark' ? "#2d3a48" : "gray";

  if (viewIsLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Helmet>
        <title>{dynamicTitle}</title>
        <meta name='description' content={dynamicDescription} />
      </Helmet>

      <Fade in={!viewIsLoading}>
        <Box sx={{ ...styles.container, textAlign: 'left' }}>
          <Box
            sx={{
              ...styles.innerContainer,
            }}
          >
            <Typography variant='h1' sx={styles.header}>
              &nbsp;
            </Typography>

            <AIDialog
              userId={user.uid}
              open={aiDialogOpen}
              onClose={() => {
                Logger.log('Closing AI Dialog');
                setAiDialogOpen(false);
              }}
              initialQuestion={
                currentQuestion
                  ? `Question: ${currentQuestion.question}\nOptions: ${currentQuestion.options.join(', ')}\nPlease explain this question and help me understand the answer options, the name of the quiz is ${quizName}. Skriv på svenska.`
                  : 'No question available'
              }
              systemMessage='You are a helpful AI assistant designed to explain and assist with the quiz question. Provide a short and simple explanation. Skriv på svenska'
              theme={theme}
            />

            <DrawerComponent
              user={user}
              theme={theme}
              open={drawerOpen}
              onClose={toggleDrawer(false)}
            />

            <Box sx={{ ...styles.buttonsContainer, ...styles.additionalButtonsContainer }}>
              {isMobile ? (
                <Button
                  variant='contained'
                  color='primary'
                  disableRipple
                  onClick={e => {
                    e.preventDefault();
                    navigate('/app/alla-quiz');
                  }}
                  startIcon={<ExploreIcon sx={{ ...styles.btnContainerIconColor }} />}
                  sx={styles.shrinkButton}
                />
              ) : (
                <>
                  {user.accountType === 'student' && (
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={e => {
                        e.preventDefault();
                        navigate('/app/alla-quiz');
                      }}
                      startIcon={<ExploreIcon sx={{ ...styles.btnContainerIconColor }} />}
                    >
                      {' '}
                      Alla quiz{' '}
                    </Button>
                  )}
                </>
              )}

              {isMobile ? (
                <Button
                  variant='contained'
                  color='primary'
                  disableRipple
                  onClick={handleNewQuiz}
                  startIcon={<AddIcon />}
                  sx={styles.shrinkButton}
                />
              ) : (
                <Button
                  variant='contained'
                  color='primary'
                  onClick={handleNewQuiz}
                  startIcon={<AddIcon />}
                >
                  {' '}
                  Nytt quiz{' '}
                </Button>
              )}

              <Button
                variant='contained'
                color='primary'
                disableRipple
                onClick={toggleDrawer(true)}
                startIcon={<SegmentIcon />}
                sx={{
                  padding: '8px',
                  marginRight: '0px',
                  paddingLeft: '14px',
                  paddingRight: '3px',
                  minWidth: 'auto',
                  textTransform: 'none',
                }}
              />
            </Box>

            {showSummary && (
              <Box
                sx={{
                  ...styles.container,
                  textAlign: 'left',
                  width: { xs: '100%', md: '100%' },

                  borderRadius: '10px',
                  padding: '20px',
                }}
              >
                {(() => {
                  const correctAnswers = results.filter(result => result.isCorrect).length;
                  return (
                    <>
                      <Box
                        sx={{
                          backgroundColor: theme.palette.background.paper,
                          padding: 3,
                          borderRadius: '8px',
                          marginBottom: 3,
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Typography variant='h5' sx={{ fontSize: '20px' }}>
                          Du fick {correctAnswers} av {results.length} rätt!
                        </Typography>
                        <Typography
                          variant='h4'
                          sx={{ color: theme.palette.secondary.main, fontSize: '20px' }}
                        >
                          {((correctAnswers / results.length) * 100).toFixed(0)}%
                        </Typography>
                      </Box>

                      <UserPercentile
                        quizId={currentQuizId}
                        userScore={
                          (results.filter(result => result.isCorrect).length / results.length) * 100
                        }
                      />

                      <Box sx={{ marginTop: 4 }}>
                        {results.map((result, index) => (
                          <Box
                            key={index}
                            sx={{
                              marginBottom: 4,
                              padding: 3,
                              backgroundColor: theme.palette.background.paper,
                              borderRadius: '8px',
                              borderLeft: `5px solid ${result.isCorrect ? theme.palette.success.main : theme.palette.error.main}`,
                            }}
                          >
                            <Typography variant='h6' sx={{ marginBottom: 2 }}>
                              Fråga {index + 1}:
                            </Typography>
                            <Typography
                              variant='body1'
                              sx={{ marginBottom: 2, fontWeight: 'bold' }}
                            >
                              {result.question}
                            </Typography>
                            <Box sx={{ marginLeft: 2 }}>
                              {result.options.map((option, optIndex) => (
                                <Typography
                                  key={optIndex}
                                  variant='body2'
                                  sx={{
                                    marginBottom: 1,
                                    color:
                                      option === result.correct
                                        ? theme.palette.success.main
                                        : option === result.selectedOption && !result.isCorrect
                                          ? theme.palette.error.main
                                          : 'inherit',
                                    fontWeight:
                                      option === result.correct || option === result.selectedOption
                                        ? 'bold'
                                        : 'normal',
                                  }}
                                >
                                  {option === result.selectedOption ? '➤ ' : '   '}
                                  {option}
                                  {option === result.correct && ' ✓'}
                                </Typography>
                              ))}
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
                              {result.isCorrect ? (
                                <CheckCircleIcon
                                  sx={{ color: theme.palette.success.main, marginRight: 1 }}
                                />
                              ) : (
                                <CancelIcon
                                  sx={{ color: theme.palette.error.main, marginRight: 1 }}
                                />
                              )}
                              <Typography variant='body2' sx={{ fontWeight: '500' }}>
                                {result.isCorrect
                                  ? 'Korrekt!'
                                  : `Inkorrekt. Det korrekta svaret var: ${result.correct}`}
                              </Typography>
                            </Box>

                            <Button
                              variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                              color='primary'
                              onClick={() => handleOpenAIDialog(index)}
                              sx={{ marginTop: 2 }}
                              startIcon={<GenerateIcon />}
                            >
                              Fråga AI
                            </Button>

                            <AIDialog
                              userId={user.uid}
                              open={openAIDialogs[index] || false}
                              onClose={() => handleCloseAIDialog(index)}
                              initialQuestion={`Fråga: ${result.question}\nAlternativ: ${result.options.join(', ')}\nKorrekt svar: ${result.correct}\nValt svar: ${result.selectedOption}\nFörklara denna fråga och hjälp mig förstå svarsalternativen. Skriv på svenska.`}
                              systemMessage='Du är en hjälpsam AI-assistent designad för att förklara och hjälpa till med quizfrågor. Ge en kort och enkel förklaring. Skriv på svenska.'
                              theme={theme}
                            />
                          </Box>
                        ))}
                      </Box>
                    </>
                  );
                })()}

                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Box
                    sx={{ ...styles.newBox, padding: '20px', flexGrow: 1, paddingBottom: '50px' }}
                  >
                    <UserProgressChart userId={user.uid} quizId={currentQuizId} />
                  </Box>
                </Box>
              </Box>
            )}

            {!quizInProgress && !showSummary && showCreateQuizForm && (
              <>
                <Box sx={{ width: { xs: '100%', md: '87%' }, margin: '0 auto' }}>
                  <ThemeSelectorFlow
                    value={lessonTheme}
                    onChange={e => setLessonTheme(e.target.value)}
                    customTheme={customTheme}
                    onCustomThemeChange={e => setCustomTheme(e.target.value)}
                    onPredefinedThemeChange={e => setPredefinedTheme(e.target.value)}
                    onYoutubeTranscriptChange={transcriptContent => {
                      Logger.log('onYoutubeTranscriptChange körs med: ', transcriptContent);
                      transcriptRef.current = transcriptContent;
                    }}
                    onFileUpload={content => {
                      Logger.log('onFileUpload körs med: ', content);
                      uploadedFileRef.current = content;
                    }}
                    onWikipediaContentChange={content => {
                      Logger.log('Parent: Wikipedia content received', content.substring(0, 100));
                      wikipediaContentRef.current = content;
                    }}
                    onRandomizedContentChange={content => {
                      Logger.log('randomizedContentRef', content.substring(0, 100));
                      randomizedContentRef.current = content;
                    }}
                    onCoursePlanContentChange={content => {
                      Logger.log('onCoursePlanChange', content.substring(0, 100));
                      coursePlanContentRef.current = content;
                    }}
                    lessonType='Quiz'
                    user={user}
                    theme={theme}
                    styles={styles}
                    additionalThemes={additionalThemes}
                    forQuiz={true}
                  />
                </Box>
                <Box sx={styles.headlineLessonBox}>
                  <Typography variant='h6' sx={styles.headlineLessonBoxTitle}>
                    2. Generera quiz
                  </Typography>
                </Box>
                <Box
                  sx={{
                    ...styles.darkBorder,

                    padding: '30px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#212c36' : 'white',
                    border:
                      theme.palette.mode === 'dark' ? '1px solid #263442' : '1px solid #e0e0e091',
                    width: { xs: '100%', md: '87%' },
                    margin: { xs: undefined, md: '0 auto' },
                  }}
                >
                  <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500' }}>
                    Typ av frågor
                  </Typography>
                  <Box sx={{ mt: 2 }}>
                    <QuestionTypeSelector
                      selected={questionType}
                      setQuestionType={setQuestionType}
                      disableTrueFalse={true}
                      theme={theme}
                    />
                  </Box>

                  <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                      <Typography
                        variant='formLabelSinUnderline'
                        sx={{ mt: 4, fontWeight: '500', display: 'block' }}
                      >
                        Namn på ditt quiz
                      </Typography>

                      <TextField
                        placeholder='T.ex. Bröderna Karamazov av Dostojevskij'
                        name='quizName'
                        value={quizName}
                        onChange={handleInputChange}
                        variant='outlined'
                        sx={{ mt: 1, width: '100%', mb: 4 }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Typography
                        variant='formLabelSinUnderline'
                        sx={{ fontWeight: '500', mt: 4, display: 'block' }}
                      >
                        Språk
                      </Typography>
                      <Box sx={{ mt: 1 }}>
                        <LanguageSelector
                          language={selectedLanguage}
                          setLanguage={setSelectedLanguage}
                        />
                      </Box>
                    </Grid>
                  </Grid>

                  <Collapse in={showMoreOptions}>
                    <AdditionalFields
                      aiInstructions={aiInstructions}
                      setAiInstructions={setAiInstructions}
                      numQuestions={numQuestions}
                      setNumQuestions={setNumQuestions}
                      category={category}
                      setCategory={setCategory}
                      difficulty={difficulty}
                      setDifficulty={setDifficulty}
                      questionType={questionType}
                      setQuestionType={setQuestionType}
                      selectedLanguage={selectedLanguage}
                      setSelectedLanguage={setSelectedLanguage}
                      isPublic={isPublic}
                      handlePublicChange={handlePublicChange}
                      theme={theme}
                      selectedModel={selectedModel}
                      setSelectedModel={setSelectedModel}
                    />
                  </Collapse>
                  <Box className='progress-bar-container'>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: '12px',
                        width: '100%',
                      }}
                    >
                      <Button
                        variant={theme.palette.mode === 'light' ? 'success' : 'contained'}
                        color='success'
                        onClick={() => loadQuiz(true)}
                        sx={{ mt: 5 }}
                        disabled={loadingQuiz || loadingQuestions}
                        startIcon={
                          loadingQuestions ? <CircularProgress size={18} /> : <ListAltIcon />
                        }
                      >
                        {loadingQuestions ? 'Genererar frågor...' : 'Generera frågor'}
                      </Button>

                      <Button
                        variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                        color='primary'
                        onClick={() => loadQuiz(false)}
                        sx={{ mt: 5 }}
                        disabled={loadingQuiz || loadingQuestions}
                        startIcon={
                          loadingQuiz ? (
                            <CircularProgress size={18} />
                          ) : (
                            <GenerateIcon style={{ color: '#fff' }} />
                          )
                        }
                      >
                        {loadingQuiz ? 'Genererar quiz...' : 'Quizza mig'}
                      </Button>

                      <Button
                        variant='print'
                        startIcon={showMoreOptions ? <ExpandLessIcon /> : <SettingsIcon />}
                        onClick={() => setShowMoreOptions(!showMoreOptions)}
                        sx={{ mt: 5 }}
                      >
                        {showMoreOptions ? 'Dölj inställningar' : 'Inställningar'}
                      </Button>
                    </div>

                    {/* After your generate quiz button */}
                    {isGeneratingQuestionsInDialog && quiz.length > 0 && !loadingQuestions && (
                      <div ref={teacherQuizDisplayRef}>
                        <TeacherQuizDisplay
                          quiz={quiz}
                          quizName={quizName}
                          questionType={questionType}
                          onPrint={() =>
                            handlePrintQuiz(
                              quiz,
                              quizName,
                              questionType,
                              theme.palette.primary.main
                            )
                          }
                        />
                      </div>
                    )}

                    {(loadingQuiz || loadingQuestions) && (
                      <>
                        <Box sx={{ mt: 6 }}>
                          <ProgressBar progress={progress} label='Genererar quiz...' />
                        </Box>
                      </>
                    )}
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: { xs: '100%', md: '87%' },
                    margin: { xs: undefined, md: '0 auto' },
                  }}
                >
                  <Box sx={styles.headlineLessonBox}>
                    <Typography variant='h6' sx={{ ...styles.headlineLessonBoxTitle, mt: 15 }}>
                      Mina quiz
                    </Typography>
                  </Box>

                  <Box sx={{ ...styles.freshBox }}>
                    <MyQuizzes user={user} theme={theme} styles={styles} />
                  </Box>
                </Box>
                {/*
                <QuizExplanation theme={theme} />
                */}
              </>
            )}

            {quiz.length > 0 && quizInProgress && (
              <QuizInProgress
                quiz={quiz}
                currentQuestionIndex={currentQuestionIndex}
                quizName={quizName}
                currentQuestion={currentQuestion}
                currentQuestionNumber={currentQuestionNumber}
                selectedOption={selectedOption}
                handleOptionSelect={handleOptionSelect}
                handleNextQuestion={handleNextQuestion}
                handleAskAI={handleAskAI}
                bookmarks={bookmarks}
                toggleBookmark={toggleBookmark}
                creatorInfo={creatorInfo}
                quizCreatedAt={quizCreatedAt}
                originalQuizId={originalQuizId}
                user={user}
                theme={theme}
                styles={styles}
                aiDialogOpen={aiDialogOpen}
                setAiDialogOpen={setAiDialogOpen}
              />
            )}
          </Box>
        </Box>
      </Fade>
    </>
  );
};

export default QuizView;
