import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import {
  doc,
  getDoc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
  orderBy,
} from 'firebase/firestore';
import { db } from './firebase';
import PrintIcon from '@mui/icons-material/Print';
import {
  Box,
  Typography,
  CircularProgress,
  Button,
  TextField,
  Tab,
  Tabs,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import { marked } from 'marked';
import { getStyles } from '../index';
import { useSnackbar } from './SnackbarHandler';
import { useTheme } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SaveIcon from '@mui/icons-material/Save';
import SchoolIcon from '@mui/icons-material/School';
import SegmentIcon from '@mui/icons-material/Segment';
import LanguageIcon from '@mui/icons-material/Language';
import GenerateIcon from '@mui/icons-material/AutoAwesome';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import EssayListDrawer from './EssayListDrawer';

const EssayResult = ({ user }) => {
  const { docId } = useParams();
  const [essayData, setEssayData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(false);
  const [editedResults, setEditedResults] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [reviewed, setReviewed] = useState(false);
  const { showSnackbar } = useSnackbar();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const printContentRef = useRef(null);
  const theme = useTheme();
  const styles = getStyles(theme);
  const [showOriginalText, setShowOriginalText] = useState(false);
  useEffect(() => {
    const fetchEssayData = async () => {
      try {
        const docRef = doc(db, 'essays', docId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setEssayData(data);
          setEditedResults(data.results);
          setReviewed(data.reviewed || false);
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error fetching essay data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchEssayData();
  }, [docId]);

  const [historicEssays, setHistoricEssays] = useState([]);

  useEffect(() => {
    const fetchEssayData = async () => {
      try {
        const docRef = doc(db, 'essays', docId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setEssayData(data);
          setEditedResults(data.results);
          setReviewed(data.reviewed || false);
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error fetching essay data:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchHistoricEssays = async () => {
      try {
        const q = query(
          collection(db, 'essays'),
          where('userId', '==', user.uid),
          orderBy('createdAt', 'desc') // Add this line to sort by createdAt in descending order
        );
        const querySnapshot = await getDocs(q);
        const essays = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setHistoricEssays(essays);
      } catch (error) {
        console.error('Error fetching historic essays:', error);
      }
    };

    fetchEssayData();
    fetchHistoricEssays();
  }, [docId, user.uid]);

  const handleEdit = () => {
    setEditing(true);
  };
  const handlePrint = () => {
    const printWindow = window.open('', '_blank');
    printWindow.document.write(`
      <html>
        <head>
          <title>Bedömning av uppsats från Studera.ai</title>
          <style>
            body { font-family: Arial, sans-serif; line-height: 1.6; color: #333; }
            h1 { color: #2c3e50; }
          </style>
        </head>
        <body>
          <h1>Bedömningsresultat</h1>
          ${printContentRef.current.innerHTML}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  const handleSave = async () => {
    try {
      const docRef = doc(db, 'essays', docId);
      await updateDoc(docRef, { results: editedResults });
      setEssayData({ ...essayData, results: editedResults });
      setEditing(false);
      showSnackbar('Ändringar sparade.', 'success');
    } catch (error) {
      console.error('Error updating essay data:', error);
    }
  };

  const handleCancel = () => {
    setEditedResults(essayData.results);
    setEditing(false);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleDelete = deletedId => {
    setHistoricEssays(historicEssays.filter(essay => essay.id !== deletedId));
  };

  const handleCopy = () => {
    navigator.clipboard
      .writeText(essayData.results)
      .then(() => showSnackbar('Kopierad till urklipp!', 'success'))
      .catch(err => console.error('Fel vid kopiering: ', err));
  };

  const handleReviewToggle = async () => {
    try {
      const docRef = doc(db, 'essays', docId);
      const newReviewedState = !reviewed;
      await updateDoc(docRef, { reviewed: newReviewedState });
      setReviewed(newReviewedState);

      if (newReviewedState) {
        showSnackbar('Omdöme markerat som granskat.', 'success');
      } else {
        showSnackbar('Omdöme markerat som icke granskat.', 'info');
      }
    } catch (error) {
      console.error('Error updating review status:', error);
      showSnackbar('Ett fel uppstod vid uppdatering av granskningsstatus.', 'error');
    }
  };

  const toggleOriginalText = () => {
    setShowOriginalText(!showOriginalText);
  };
  if (loading) {
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!essayData) {
    return <Typography>No essay data found.</Typography>;
  }

  return (
    <Box sx={{ ...styles.container }}>
      <Box sx={styles.innerContainer}>
        <Box sx={{ ...styles.buttonsContainer, ...styles.additionalButtonsContainer }}>
          <Button
            variant={'contained'}
            color='primary'
            component={Link}
            startIcon={<GenerateIcon />}
            to='/app/uppsats/ny'
          >
            Ny uppsats
          </Button>

          <Button
            variant={'contained'}
            color='primary'
            component={Link}
            startIcon={<AddIcon />}
            to='/app/matris/ny'
          >
            Ny bedömningsmatris
          </Button>

          <Button
            variant='contained'
            color='primary'
            disableRipple
            onClick={() => setDrawerOpen(true)}
            startIcon={<SegmentIcon />}
            sx={{}}
          >
            Uppsatser
          </Button>
        </Box>

        <Box sx={{ mt: 2 }}>
          <Typography sx={{ ...styles.grayText }}>
            <Link to='/app/uppsatser' style={{ textDecoration: 'none', color: 'inherit' }}>
              Uppsatser
            </Link>{' '}
            &nbsp; / &nbsp; {essayData.fileName}
          </Typography>
        </Box>
        <Typography
          variant='h2'
          sx={{ ...styles.header, borderBottom: '0px', marginTop: '20px', mb: 1 }}
        >
          {essayData.fileName}
        </Typography>

        <Box sx={{ display: 'flex', gap: '16px', marginBottom: '16px' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              ...styles.grayText,
            }}
          >
            <SchoolIcon sx={{ fontSize: '18px', mr: 0.3 }} />
            <Typography variant='body2' sx={{ ...styles.grayText, fontWeight: '500' }}>
              {essayData.educationLevel}
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px', ...styles.grayText }}>
            <HistoryEduIcon sx={{ fontSize: '18px', mr: 0.3 }} />
            <Typography variant='body2' sx={{ ...styles.grayText, fontWeight: '500' }}>
              {essayData.rubricName}
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px', ...styles.grayText }}>
            <LanguageIcon sx={{ fontSize: '18px', mr: 0.3 }} />
            <Typography variant='body2' sx={{ ...styles.grayText, fontWeight: '500' }}>
              {essayData.language}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px', ...styles.grayText }}>
            <CalendarTodayIcon sx={{ fontSize: '18px', mr: 0.3 }} />
            <Typography variant='body2' sx={{ ...styles.grayText, fontWeight: '500' }}>
              {essayData.createdAt.toDate().toLocaleString()}
            </Typography>
          </Box>
        </Box>
        <Divider />

        <Box elevation={3} sx={{ ...styles.freshBox, padding: '20px', marginTop: '20px' }}>
          <Box sx={{ marginTop: '20px' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '10px',
                marginBottom: '10px',
              }}
            >
              {!editing && (
                <>
                  <Button
                    variant='outlined'
                    startIcon={<EditIcon />}
                    onClick={handleEdit}
                    sx={{
                      ...styles.softerBtn,
                    }}
                  >
                    Redigera
                  </Button>
                  <Button
                    variant='outlined'
                    startIcon={<ContentCopyIcon />}
                    onClick={handleCopy}
                    sx={{
                      ...styles.softerBtn,
                    }}
                  >
                    Kopiera
                  </Button>
                  <Button
                    variant='outlined'
                    color='primary'
                    onClick={handlePrint}
                    startIcon={<PrintIcon />}
                    sx={{
                      ...styles.softerBtn,
                    }}
                  >
                    Skriv ut
                  </Button>
                  <Button
                    variant='outlined'
                    startIcon={
                      <CheckCircleIcon
                        sx={{
                          color: reviewed
                            ? theme.palette.mode === 'dark'
                              ? '#63e363'
                              : 'green'
                            : 'inherit',
                        }}
                      />
                    }
                    onClick={handleReviewToggle}
                    sx={{
                      ...styles.softerBtn,
                    }}
                  >
                    {reviewed ? 'Granskad' : 'Markera som granskad'}
                  </Button>
                </>
              )}
            </Box>
            {editing ? (
              <>
                <Box sx={{ mb: 3 }}>
                  <Button
                    onClick={handleSave}
                    variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                    sx={{ marginRight: '10px' }}
                    startIcon={<SaveIcon />}
                  >
                    Spara
                  </Button>
                  <Button onClick={handleCancel} variant='outlined'>
                    Avbryt
                  </Button>
                </Box>
                <Tabs value={activeTab} onChange={handleTabChange} sx={{ marginBottom: '10px' }}>
                  <Tab label='Redigera' />
                  <Tab label='Förhandsvisa' />
                </Tabs>
                {activeTab === 0 ? (
                  <TextField
                    multiline
                    fullWidth
                    rows={50}
                    value={editedResults}
                    onChange={e => setEditedResults(e.target.value)}
                    sx={{ marginTop: '10px', marginBottom: '10px' }}
                  />
                ) : (
                  <Box sx={{ border: '1px solid #ccc', padding: '10px', borderRadius: '4px' }}>
                    <div dangerouslySetInnerHTML={{ __html: marked(editedResults) }} />
                  </Box>
                )}
                <Button
                  onClick={handleSave}
                  variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                  sx={{ marginRight: '10px' }}
                  startIcon={<SaveIcon />}
                >
                  Spara
                </Button>
                <Button onClick={handleCancel} variant='outlined'>
                  Avbryt
                </Button>
              </>
            ) : (
              <Box
                sx={{
                  '& p': {
                    lineHeight: '27px',
                  },
                  '& li': {
                    marginBottom: '12px',
                    lineHeight: '27px',
                    '& strong': {
                      fontWeight: '600',
                    },
                  },
                  '& h3': {
                    marginTop: '40px',
                  },
                  '& hr': {
                    height: '1px',
                    border: 'none',
                    backgroundColor: theme => theme.palette.divider,
                    marginTop: '50px',
                    marginBottom: '50px',
                  },
                }}
              >
                <div
                  ref={printContentRef}
                  dangerouslySetInnerHTML={{ __html: marked(essayData.results) }}
                />
              </Box>
            )}
          </Box>
          <Box sx={{ marginTop: '20px' }}>
            <Button
              onClick={toggleOriginalText}
              startIcon={showOriginalText ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            >
              {showOriginalText ? 'Dölj originaltext' : 'Visa originaltext'}
            </Button>
            {showOriginalText && (
              <Typography variant='body1' sx={{ whiteSpace: 'pre-wrap', marginTop: '10px' }}>
                {essayData.content}
              </Typography>
            )}
          </Box>
        </Box>
        <EssayListDrawer
          open={drawerOpen}
          historicEssays={historicEssays}
          theme={theme}
          onClose={() => setDrawerOpen(false)}
          onDelete={handleDelete}
        />
      </Box>
    </Box>
  );
};

export default EssayResult;
