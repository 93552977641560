import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  Grid,
  Avatar,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  TextField,
  Tooltip,
  useTheme,
  Slider,
  Divider,
} from '@mui/material';
import GenerateIcon from '@mui/icons-material/AutoAwesome';
import ImageIcon from '@mui/icons-material/Image';
import PrintIcon from '@mui/icons-material/Print';
import { handlePrintWritingLesson } from './printFunctions';
import { OrbitProgress } from 'react-loading-indicators';
import { callApi } from './api';
import { generateAndProcessImage, trackLesson } from './functions';
import { useSnackbar } from './SnackbarHandler';
import GradeSelector, { gradeInstructions } from './GradeSelector';
import EditIcon from '@mui/icons-material/Edit';
import LanguageSelector from './LanguageSelector';
import SubjectIcon from '@mui/icons-material/Subject';
import ImageGenerationModal from './ImageGenerationModal';
import { getStyles } from '../index';
import { ThemeSelector } from './ThemeSelector';
import { Mosaic } from 'react-loading-indicators';
import InfoBox from './InfoBox';

import Logger from './Logger';
import LessonDrawer from './LessonDrawer';
import SaveLessonDialog from './SaveLessonDialog';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import HistoryIcon from '@mui/icons-material/History';
import HoverableImage from './HoverableImage';

import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';

import { ThemeSelectorFlow } from './ThemeSelectorFlow';

import {
  Science as ScienceIcon,
  Castle as CastleIcon,
  Psychology as PsychologyIcon,
  Pets as PetsIcon,
  Public as PublicIcon,
  EmojiEvents as EmojiEventsIcon,
  Museum as MuseumIcon,
  Nature as NatureIcon,
} from '@mui/icons-material';
import MenuBookIcon from '@mui/icons-material/MenuBook';

const WritingLesson = ({ user }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);

  const [words, setWords] = useState([]);
  const [isGeneratingLesson, setIsGeneratingLesson] = useState(false);
  const [isGeneratingImages, setIsGeneratingImages] = useState(false);
  const [generatedImages, setGeneratedImages] = useState(new Set());
  const [themeColor, setThemeColor] = useState('#8a59f4');
  const { showSnackbar } = useSnackbar();

  const [themeType, setThemeType] = useState('generateWithAi');
  const [customTheme, setCustomTheme] = useState('');
  const [wordCount, setWordCount] = useState(5);
  const [lineCount, setLineCount] = useState(4);
  const [lineHeight, setLineHeight] = useState(50); // New state for line height (in pixels)
  const [grade, setGrade] = useState('åk6');
  const [language, setLanguage] = useState('svenska');

  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isEditingSubtitle, setIsEditingSubtitle] = useState(false);
  const [titleText, setTitleText] = useState('Skrivlektion');

  const theme = useTheme();
  const styles = getStyles(theme);

  const [showImageModal, setShowImageModal] = useState(false);
  const [imageInstructions, setImageInstructions] = useState('');
  const [imageGenerationType, setImageGenerationType] = useState(null);

  const [generatingImageIndex, setGeneratingImageIndex] = useState(null);
  const [selectedWordIndex, setSelectedWordIndex] = useState(null);

  const [editingWordIndex, setEditingWordIndex] = useState(null);
  const [editedWord, setEditedWord] = useState('');

  const [lessonTheme, setLessonTheme] = useState('generateWithAi');
  const [predefinedTheme, setPredefinedTheme] = useState(null);
  const lessonTitleRef = useRef(null); // Add this ref
  const transcriptRef = useRef('');
  const uploadedFileRef = useRef('');

  const wikipediaContentRef = useRef('');
  const randomizedContentRef = useRef('');
  const coursePlanContentRef = useRef('');

  const additionalThemes = [
    { type: 'subheader', label: 'Teman som lämpar sig extra bra för denna lektionstyp:' },
    {
      value: 'selma',
      label: 'Selma Lagerlöf',
      content: 'Gör en lektion om selma lagerlöf',
      Icon: MenuBookIcon,
    },
    {
      value: 'dronten',
      label: 'Dronten',
      content: 'Gör en lektion om den utdöda arten dronten',
      Icon: PetsIcon,
    },
    {
      value: 'vikingar',
      label: 'Vikingar',
      content: 'Gör en lektion om vikingatiden',
      Icon: CastleIcon,
    },
    {
      value: 'rymden',
      label: 'Rymden',
      content: 'Gör en lektion om vårt solsystem',
      Icon: PublicIcon,
    },
    {
      value: 'dinosaurier',
      label: 'Dinosaurier',
      content: 'Gör en lektion om olika dinosaurier',
      Icon: NatureIcon,
    },
    {
      value: 'nobel',
      label: 'Nobelpriset',
      content: 'Gör en lektion om nobelpriset och Alfred Nobel',
      Icon: EmojiEventsIcon,
    },
    {
      value: 'uppfinnare',
      label: 'Svenska uppfinnare',
      content: 'Gör en lektion om kända svenska uppfinnare',
      Icon: ScienceIcon,
    },
    {
      value: 'psychology',
      label: 'Känslor',
      content: 'Gör en lektion om olika känslor',
      Icon: PsychologyIcon,
    },
    {
      value: 'konst',
      label: 'Svensk konst',
      content: 'Gör en lektion om svensk konsthistoria',
      Icon: MuseumIcon,
    },
  ];

  useEffect(() => {
    Logger.log('lessonTheme: ', lessonTheme);
  }, [lessonTheme]);

  useEffect(() => {
    Logger.log('predefinedTheme: ', predefinedTheme);
  }, [predefinedTheme]);

  useEffect(() => {
    Logger.log('customTheme: ', customTheme);
  }, [customTheme]);

  useEffect(() => {
    Logger.log('youtubeTranscript: ', transcriptRef.current);
  }, [transcriptRef.current]);

  useEffect(() => {
    Logger.log('wikipediaContentRef: ', wikipediaContentRef.current);
  }, [wikipediaContentRef.current]);
  useEffect(() => {
    Logger.log('uploadedFileRef: ', uploadedFileRef.current);
  }, [uploadedFileRef.current]);

  useEffect(() => {
    Logger.log('randomizedContentRef: ', randomizedContentRef.current);
  }, [randomizedContentRef.current]);

  useEffect(() => {
    Logger.log('coursePlanContentRef: ', coursePlanContentRef.current);
  }, [coursePlanContentRef.current]);

  // Add these handlers:
  const handleEditWord = index => {
    setEditingWordIndex(index);
    setEditedWord(words[index].word);
  };

  const handleSaveWord = index => {
    const updatedWords = [...words];
    updatedWords[index] = {
      ...updatedWords[index],
      word: editedWord,
    };
    setWords(updatedWords);
    setEditingWordIndex(null);
    showSnackbar('Ordet har uppdaterats', 'success');
  };

  const handleDeleteWord = index => {
    const updatedWords = words.filter((_, i) => i !== index);
    setWords(updatedWords);
    showSnackbar('Ordet har tagits bort', 'success');
  };

  const handleSaveLessonClick = () => {
    setSaveDialogOpen(true);
  };

  const handleSaveWithComment = async (comment, name, coverImage, tags) => {
    const lessonData = {
      words: words,
      titleText: titleText,
    };

    await trackLesson(
      user.uid,
      'WritingLesson.js',
      lessonData,
      null,
      true,
      comment,
      name,
      coverImage,
      tags
    );
    setDrawerOpen(true);
    showSnackbar('Lektionen sparad i din lektionsbank!', 'success');
  };

  const handleImageButtonClick = type => {
    setImageGenerationType(type);
    setShowImageModal(true);
  };

  const handleCloseModal = () => {
    setShowImageModal(false);
    setImageInstructions('');
  };

  const handleGenerateWithInstructions = async () => {
    handleCloseModal();

    if (imageGenerationType === 'single') {
      await handleGenerateSingleImage(selectedWordIndex, words[selectedWordIndex]);
    } else {
      await handleGenerateImages();
    }
  };

  // Handler for line count slider
  const handleLineCountChange = (event, newValue) => {
    setLineCount(newValue);
  };

  const LINE_HEIGHT_SCALE = 5; // Each slider unit represents 5 pixels

  // Convert actual pixels to slider value
  const pixelsToSliderValue = pixels => Math.round(pixels / LINE_HEIGHT_SCALE);

  // Convert slider value to actual pixels
  const sliderValueToPixels = value => value * LINE_HEIGHT_SCALE;

  // Updated handler for line height slider
  const handleLineHeightChange = (event, newValue) => {
    // Convert slider value to actual pixels before storing
    setLineHeight(sliderValueToPixels(newValue));
  };

  const handleGenerateLesson = async () => {
    setIsGeneratingLesson(true);
    try {
      let themeToUse;

      if (lessonTheme === 'youtube') {
        themeToUse = transcriptRef.current;
      } else if (lessonTheme === 'customTheme') {
        themeToUse = customTheme;
      } else if (lessonTheme === 'predefined') {
        themeToUse = predefinedTheme;
      } else if (lessonTheme === 'fileUpload') {
        themeToUse = uploadedFileRef.current;
      } else if (lessonTheme === 'wikipedia') {
        themeToUse = wikipediaContentRef.current;
      } else if (lessonTheme === 'randomizer') {
        themeToUse = randomizedContentRef.current;
      } else {
        //
        themeToUse = coursePlanContentRef.current;
      }

      Logger.log('themeToUse: ', themeToUse);

      const response = await callApi(
        [
          {
            role: 'system',
            content: 'Du är en assistent som genererar ord för skrivövningar.',
          },
          {
            role: 'user',
            content: `Generera JSON med exakt ${wordCount} ord på ${language} relaterade till temat "${themeToUse}".
          ${gradeInstructions[grade]}
          
          Exempel på svar:
          {
            "words": [
              { "word": "katt" },
              { "word": "hund" },
              { "word": "häst" }
            ]
          }`,
          },
        ],
        4000,
        true,
        user.uid
      );

      let messageContent = response.data.choices[0].message.content.trim();
      if (messageContent.startsWith('```json')) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith('```')) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const data = JSON.parse(messageContent);
      const wordsWithImages = data.words.map(wordObj => ({
        ...wordObj,
        image: null,
      }));

      setWords(wordsWithImages);
      showSnackbar('Nya ord har genererats', 'success');

      await trackLesson(user.uid, 'WritingLesson.js', null, {
        wordCount: wordCount,
        language: language,
        lessonTheme: lessonTheme,
      });
    } catch (error) {
      console.error('Error generating lesson:', error);
      showSnackbar('Kunde inte generera nya ord', 'error');
    } finally {
      setIsGeneratingLesson(false);
    }
  };

  const handleGenerateSingleImage = async (index, word) => {
    setGeneratingImageIndex(index);
    setIsGeneratingImages(true);

    try {
      const prompt = `Create a simple, clear image representing the ${language} word "${word.word}". No text in the image. ${imageInstructions}`;

      const imageUrl = await generateAndProcessImage(prompt, user.uid, {
        size: '1024x1024',
        model: 'dall-e-3',
        quality: 'standard',
      });

      const updatedWords = [...words];
      updatedWords[index] = {
        ...updatedWords[index],
        image: imageUrl,
      };
      setWords(updatedWords);
      showSnackbar(`Bild genererad för "${word.word}"`, 'success');
    } catch (error) {
      console.error(`Error generating image for word "${word.word}":`, error);
      showSnackbar(`Kunde inte generera bild för "${word.word}"`, 'error');
    } finally {
      setIsGeneratingImages(false);
      setGeneratingImageIndex(null);
    }
  };

  // Update handleGenerateImages to use the new index tracking
  const handleGenerateImages = async () => {
    setIsGeneratingImages(true);

    try {
      const updatedWords = [...words];

      for (const [index, word] of words.entries()) {
        setGeneratingImageIndex(index);
        try {
          const prompt = `Create a simple, clear image representing the ${language} word "${word.word}". No text in the image. ${imageInstructions}`;

          const imageUrl = await generateAndProcessImage(prompt, user.uid, {
            size: '1024x1024',
            model: 'dall-e-3',
            quality: 'standard',
          });

          updatedWords[index] = {
            ...updatedWords[index],
            image: imageUrl,
          };

          setWords(updatedWords);
          showSnackbar(`Genererat bild ${index + 1} av ${words.length}`, 'info');
        } catch (error) {
          console.error(`Error generating image for word "${word.word}":`, error);
          showSnackbar(`Kunde inte generera bild för "${word.word}"`, 'error');
          continue;
        }
      }
      showSnackbar('Alla bilder har genererats!', 'success');
    } catch (error) {
      console.error('Error in image generation process:', error);
      showSnackbar('Ett fel uppstod när bilderna skulle genereras', 'error');
    } finally {
      setIsGeneratingImages(false);
      setGeneratingImageIndex(null);
    }
  };

  return (
    <Box sx={styles.container}>
      <Box
        sx={{
          ...styles.innerContainer,
          width: { xs: '100%', md: '87%' },
          margin: '0 auto',
        }}
      >
        <Typography
          variant='h1'
          gutterBottom
          sx={{ fontWeight: 'bold', fontSize: '1.8em', mb: 3, mt: 5, textAlign: 'center' }}
        >
          Generera skrivövningar
        </Typography>

        <Box sx={{ ...styles.buttonsContainer, ...styles.additionalButtonsContainer }}>
          <Tooltip title='Dina sparade lektioner'>
            <Button
              variant='contained'
              color='primary'
              disableRipple
              onClick={() => setDrawerOpen(true)}
              startIcon={<HistoryIcon />}
              sx={{
                padding: '8px',
                marginRight: '0px',
                paddingLeft: '14px',
                paddingRight: '3px',
                minWidth: 'auto',
                textTransform: 'none',
              }}
            />
          </Tooltip>
        </Box>
        <LessonDrawer
          userId={user.uid}
          lessonType='WritingLesson.js'
          theme={theme}
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          lessonIcon={SubjectIcon}
          onSelectLesson={lesson => {
            setTitleText(lesson.lessonName);
            setWords(lesson.data.words);
            setDrawerOpen(false);
            showSnackbar(`Lektionen har laddats in.`, 'success');
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'start', // This ensures it scrolls to the top of the element
              });
            }, 100);
          }}
        />

        <InfoBox
          icon={SubjectIcon}
          title='Powerpoint Instructions'
          instructions={[
            'Generera skrivövningar med kontroll över antal rader och radavstånd',
            'Lägg till AI-genererade bilder för varje ord',
            'Skriv ut lektionen',
          ]}
          theme={theme}
          styles={styles}
        />

        <ThemeSelectorFlow
          value={lessonTheme}
          onChange={e => setLessonTheme(e.target.value)}
          customTheme={customTheme}
          onCustomThemeChange={e => setCustomTheme(e.target.value)}
          onPredefinedThemeChange={e => setPredefinedTheme(e.target.value)}
          onYoutubeTranscriptChange={transcriptContent => {
            Logger.log('onYoutubeTranscriptChange körs med: ', transcriptContent);
            transcriptRef.current = transcriptContent;
          }}
          onFileUpload={content => {
            Logger.log('onFileUpload körs med: ', content);
            uploadedFileRef.current = content;
          }}
          onWikipediaContentChange={content => {
            Logger.log('Parent: Wikipedia content received', content.substring(0, 100));
            wikipediaContentRef.current = content;
          }}
          onRandomizedContentChange={content => {
            Logger.log('randomizedContentRef', content.substring(0, 100));
            randomizedContentRef.current = content;
          }}
          onCoursePlanContentChange={content => {
            Logger.log('onCoursePlanChange', content.substring(0, 100));
            coursePlanContentRef.current = content;
          }}
          lessonType='WritingLesson.js'
          user={user}
          theme={theme}
          styles={styles}
          onLessonSelect={lesson => {
            Logger.log('onLessonSelect körs');
            setTitleText(lesson.lessonName);
            setWords(lesson.data.words);
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'start', // This ensures it scrolls to the top of the element
              });
            }, 100);
            showSnackbar('Lektionen har laddats in.', 'success');
          }}
          additionalThemes={additionalThemes}
        />
        <Box sx={styles.headlineLessonBox}>
          <Typography variant='h6' sx={styles.headlineLessonBoxTitle}>
            2. Generera lektionen
          </Typography>
        </Box>

        <Box sx={{ ...styles.freshBox, padding: '30px' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500', mb: 0 }}>
                Språk:
              </Typography>
              <Box sx={{ mt: 1 }}>
                <LanguageSelector language={language} setLanguage={setLanguage} />
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography
                variant='formLabelSinUnderline'
                sx={{ fontWeight: '500', display: 'block', mb: 0, mt: 2 }}
              >
                Årskurs:
              </Typography>
              <Box sx={{ mt: 1, mb: 3 }}>
                <GradeSelector grade={grade} setGrade={setGrade} />
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography
                variant='formLabelSinUnderline'
                sx={{ fontWeight: '500', display: 'block', mb: 0, mt: 2 }}
              >
                Antal ord:
              </Typography>
              <FormControl fullWidth margin='normal' sx={{ mb: 3, mt: 1 }}>
                <Select value={wordCount} onChange={e => setWordCount(e.target.value)}>
                  <MenuItem value={3}>3 ord</MenuItem>
                  <MenuItem value={4}>4 ord</MenuItem>
                  <MenuItem value={5}>5 ord</MenuItem>
                  <MenuItem value={6}>6 ord</MenuItem>
                  <MenuItem value={8}>8 ord</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500', mb: 0 }}>
                Antal rader per ord:
              </Typography>
              <Box sx={{ px: 2, mt: 2 }}>
                <Slider
                  value={lineCount}
                  onChange={handleLineCountChange}
                  step={1}
                  marks
                  min={1}
                  max={8}
                  valueLabelDisplay='auto'
                  sx={{
                    color: theme.palette.mode === 'light' ? '#8b5cf6' : 'primary',
                    '& .MuiSlider-markLabel': {
                      color: theme.palette.text.primary,
                    },
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500', mb: 0 }}>
                Radavstånd (pixlar):
              </Typography>
              <Box sx={{ px: 2, mt: 2 }}>
                <Slider
                  // Convert stored pixels back to slider value
                  value={pixelsToSliderValue(lineHeight)}
                  onChange={handleLineHeightChange}
                  step={1}
                  marks
                  min={0}
                  max={15} // Max value of 10 will represent 50 pixels
                  valueLabelDisplay='auto'
                  // Add custom value label to show actual pixels
                  valueLabelFormat={value => `${sliderValueToPixels(value)}px`}
                  sx={{
                    color: theme.palette.mode === 'light' ? '#8b5cf6' : 'primary',
                    '& .MuiSlider-markLabel': {
                      color: theme.palette.text.primary,
                    },
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          <Button
            variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
            color='primary'
            onClick={handleGenerateLesson}
            disabled={isGeneratingLesson}
            startIcon={!isGeneratingLesson ? <GenerateIcon /> : <CircularProgress size={20} />}
            sx={{ mr: 2, mt: 8 }}
          >
            {isGeneratingLesson ? 'Genererar...' : 'Generera lektion'}
          </Button>
        </Box>

        {words.length > 0 && (
          <>
            <Box ref={lessonTitleRef} sx={{ ...styles.headlineLessonBox, mt: 10 }}>
              <Typography variant='h6' sx={styles.headlineLessonBoxTitle}>
                3. Din lektion
              </Typography>
            </Box>
            <Box sx={{ ...styles.freshBox, padding: '30px' }}>
              <Box sx={styles.lessonControlPanel}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    px: 1,
                  }}
                >
                  <Box>
                    <Button
                      variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                      color='primary'
                      onClick={() => handleImageButtonClick('facts')}
                      disabled={isGeneratingImages}
                      startIcon={
                        !isGeneratingImages ? <ImageIcon /> : <CircularProgress size={20} />
                      }
                      sx={{ mr: 2 }}
                    >
                      {isGeneratingImages ? 'Genererar bilder...' : 'Generera bilder'}
                    </Button>

                    <SaveLessonDialog
                      open={saveDialogOpen}
                      onClose={() => setSaveDialogOpen(false)}
                      onSave={handleSaveWithComment}
                      theme={theme}
                      lessonObject={words}
                      lessonName={titleText}
                    />

                    <ImageGenerationModal
                      open={showImageModal}
                      onClose={handleCloseModal}
                      onGenerate={handleGenerateWithInstructions}
                      isGenerating={
                        imageGenerationType === 'topic' ? isGeneratingImages : isGeneratingImages
                      }
                      instructions={imageInstructions}
                      setInstructions={setImageInstructions}
                      generationType={imageGenerationType}
                      theme={theme}
                    />
                  </Box>
                  <Box>
                    <Button
                      variant='print'
                      color='primary'
                      onClick={() =>
                        handlePrintWritingLesson(
                          words,
                          lineCount,
                          lineHeight,
                          themeColor,
                          titleText
                        )
                      }
                      startIcon={<PrintIcon />}
                      sx={{}}
                    >
                      Skriv ut
                    </Button>

                    <Button
                      variant='print'
                      startIcon={<SaveAsIcon />}
                      sx={{ ml: 2 }}
                      onClick={handleSaveLessonClick}
                    >
                      Spara i lektionsbank
                    </Button>
                  </Box>
                </Box>
              </Box>

              {isEditingTitle ? (
                <TextField
                  value={titleText}
                  onChange={e => setTitleText(e.target.value)}
                  onBlur={() => {
                    setIsEditingTitle(false);
                    showSnackbar('Titeln har uppdaterats', 'success');
                  }}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      setIsEditingTitle(false);
                      showSnackbar('Titeln har uppdaterats', 'success');
                    }
                  }}
                  autoFocus
                  fullWidth
                  variant='standard'
                  sx={{
                    backgroundColor: 'transparent',
                    '& .MuiInputBase-input': {
                      textAlign: 'center',
                      fontWeight: 'bold',
                      fontSize: '2.6em',
                      marginBottom: '1.2rem',
                      marginTop: '1.5rem',
                    },
                  }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              ) : (
                <Box
                  sx={{
                    position: 'relative',
                    display: 'inline-block',
                    margin: '0 auto',
                    width: '100%',
                    mb: 3,
                    mt: 5,
                    ':hover .edit-icon': {
                      opacity: 1,
                    },
                  }}
                >
                  <Typography
                    variant='h1'
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '2.6em',

                      textAlign: 'center',
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: 2,
                    }}
                  >
                    {titleText || ' '}
                    <Box
                      component='span'
                      sx={{
                        opacity: 0,
                        transition: 'opacity 0.2s',
                        display: 'inline-flex',
                        gap: 0.5,
                      }}
                      className='edit-icon'
                    >
                      <Tooltip title='Redigera' placement='top'>
                        <IconButton size='small' onClick={() => setIsEditingTitle(true)}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Typography>
                </Box>
              )}

              <Grid container spacing={3}>
                {words.map((word, index) => (
                  <Box
                    key={index}
                    sx={{
                      position: 'relative',
                      ':hover .action-buttons': {
                        opacity: 1,
                      },
                      margin: '20px',
                      width: '100%',
                    }}
                  >
                    <Box
                      className='action-buttons'
                      sx={{
                        position: 'absolute',
                        top: '70px',
                        right: '0',
                        opacity: 0,
                        transition: 'opacity 0.2s',
                        zIndex: 1,
                        display: 'flex',
                        gap: 1,
                      }}
                    >
                      {editingWordIndex === index ? (
                        <Tooltip title='Spara ändringar' placement='top'>
                          <IconButton onClick={() => handleSaveWord(index)} size='small'>
                            <SaveAsIcon />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title='Redigera ord' placement='top'>
                          <IconButton onClick={() => handleEditWord(index)} size='small'>
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      <Tooltip title='Ta bort ord' placement='top'>
                        <IconButton onClick={() => handleDeleteWord(index)} size='small'>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>

                    {editingWordIndex === index ? (
                      <TextField
                        value={editedWord}
                        onChange={e => setEditedWord(e.target.value)}
                        sx={{
                          fontWeight: '500',
                          fontSize: '23px',
                          display: 'block',
                          width: '50%',
                          mb: 2,
                          mt: 8,
                          ml: 2,
                          '& .MuiInputBase-input': {
                            fontSize: '23px',
                            fontWeight: '500',
                            textTransform: 'capitalize',
                          },
                        }}
                        autoFocus
                      />
                    ) : (
                      <Typography
                        sx={{
                          fontWeight: '600',
                          fontSize: '26px',
                          display: 'block',
                          mt: 8,
                          ml: 2,
                          mb: 2,
                          textTransform: 'capitalize',
                        }}
                      >
                        {word.word}
                      </Typography>
                    )}
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          ...styles.freshBox,
                          p: 2,

                          display: 'flex',
                          alignItems: 'center',
                          gap: 3,
                          borderRadius: '13px',
                          border: `1px solid #e0e0e091`,
                          backgroundColor: theme.palette.mode === 'dark' ? '#1a2027' : '#f5f5f5',
                        }}
                      >
                        <Box sx={{ position: 'relative', width: 260, height: 260, flexShrink: 0 }}>
                          <HoverableImage
                            imageUrl={word.image}
                            isLoading={isGeneratingImages}
                            loadingIndex={generatingImageIndex}
                            currentIndex={index}
                            onGenerateClick={() => {
                              setImageGenerationType('single');
                              setSelectedWordIndex(index);
                              setShowImageModal(true);
                            }}
                            userId={user.uid}
                            onImageUpdate={newImageUrl => {
                              const updatedWords = [...words];
                              updatedWords[index] = {
                                ...updatedWords[index],
                                image: newImageUrl,
                              };
                              setWords(updatedWords);
                            }}
                            height='260px'
                            theme={theme}
                            showSnackbar={showSnackbar}
                          />
                        </Box>

                        <Box sx={{ flex: 1, mt: 7 }}>
                          {Array(lineCount)
                            .fill(0)
                            .map((_, lineIndex) => (
                              <Box
                                key={lineIndex}
                                sx={{
                                  borderBottom: '2px solid black',
                                  mb: `${lineHeight}px`,
                                  position: 'relative',
                                }}
                              />
                            ))}
                        </Box>
                      </Box>
                    </Grid>
                  </Box>
                ))}
              </Grid>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default WritingLesson;
