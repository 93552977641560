import React, { useEffect } from 'react';
import {
  Container,
  Typography,
  Grid,
  Box,
  useTheme,
  useMediaQuery,
  IconButton,
  Chip,
  Card,
  CardContent,
  Avatar,
  TextField,
  Button, // Add if not already imported
  Divider,
} from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { StyledButton, GradientBackground } from './StyledComponents';
import { Link } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import TestimonialsSection from './TestimonialsSection';
import { motion } from 'framer-motion';

import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import UniversitySection from './UniversitySection';
import MediaSection from './MediaSection';

import StarIcon from '@mui/icons-material/Star';
import PeopleIcon from '@mui/icons-material/People';
import TimelineIcon from '@mui/icons-material/Timeline';

const GrejPage = ({ toggleDarkMode, isDark }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Features Data
  const features = [
    {
      icon: <PeopleIcon fontSize='large' color='primary' />,
      title: 'Skapa grej of the day på 1 minut',
      description:
        'Skapa korta och intressanta mikrolektioner på 1 minut som fångar elevernas uppmärksamhet.',
    },
    {
      icon: <TimelineIcon fontSize='large' color='primary' />,
      title: 'AI-genererat innehåll',
      description:
        'Använd AI för att generera innehåll, bilder och presentationer på några sekunder.',
    },
    {
      icon: <StarIcon fontSize='large' color='primary' />,
      title: 'Enkel delning',
      description: 'Dela dina lektioner med kollegor och elever med bara ett klick.',
    },
  ];

  // Testimonials Data
  const testimonials = [
    {
      name: 'Anna Svensson',
      title: 'Lärare i Grundskolan',
      photo: 'https://via.placeholder.com/80',
      feedback:
        'Studera.ai har revolutionerat mitt sätt att undervisa. Eleverna älskar mikrolektionerna!',
    },
    {
      name: 'Erik Johansson',
      title: 'Gymnasielärare',
      photo: 'https://via.placeholder.com/80',
      feedback:
        'Med AI-genererade Grej of the day sparar jag massor av tid och kan fokusera mer på elevernas behov.',
    },
  ];

  // Pricing Data
  const pricingPlans = [
    {
      title: 'Gratis',
      price: '0 kr',
      features: ['Skapa upp till 5 lektioner', 'Grundläggande funktioner', 'Community support'],
      buttonText: 'Kom igång gratis',
    },
    {
      title: 'Premium',
      price: '99 kr/mån',
      features: ['Obegränsat antal lektioner', 'Avancerade funktioner', 'Prioriterad support'],
      buttonText: 'Uppgradera nu',
    },
  ];

  // Team Data
  const teamMembers = [
    {
      name: 'Maria Karlsson',
      position: 'Grundare & VD',
      photo: 'https://via.placeholder.com/150',
    },
    {
      name: 'Johan Lindberg',
      position: 'CTO',
      photo: 'https://via.placeholder.com/150',
    },
    {
      name: 'Emma Nilsson',
      position: 'Produktchef',
      photo: 'https://via.placeholder.com/150',
    },
  ];

  // Stats Data
  const stats = [
    {
      value: '2000+',
      label: 'Användare',
    },
    {
      value: '6,500+',
      label: 'Skapade lektioner',
    },
    {
      value: '40+',
      label: 'Skolor',
    },
  ];

  return (
    <>
      <Helmet>
        <title>{`Grej of the Day | Studera.ai`}</title>
        <meta
          name='description'
          content='Lär dig mer om Grej of the Day och hur Studera.ai revolutionerar undervisningen med AI-drivna mikrolektioner.'
        />
        <meta
          name='keywords'
          content='Grej of the Day, AI, undervisning, lärare verktyg, mikrolektioner, Studera.ai'
        />
        <meta property='og:title' content='Grej of the Day | Studera.ai' />
        <meta
          property='og:description'
          content='Upptäck hur Grej of the Day från Studera.ai kan förbättra din undervisning med AI-genererade mikrolektioner.'
        />
        <meta
          property='og:image'
          content='https://via.placeholder.com/1200x630.png?text=Grej+of+the+Day'
        />
        <meta property='og:url' content='https://studera.ai/funktioner/grej-of-the-day' />
        <meta name='twitter:card' content='summary_large_image' />
      </Helmet>

      <Header />

      <GradientBackground className='gradient-background'>
        <Container
          sx={{ pt: { xs: 10, md: 20 }, pb: 15, position: 'relative', textAlign: 'center' }}
        >
          <Grid container spacing={6} alignItems='center'>
            <Grid item xs={12} md={12} sx={{ textAlign: 'center' }}>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                  <Chip
                    label={
                      <span>
                        Studera.ai{' '}
                        <span style={{ opacity: 0.7 }}>
                          &nbsp;•&nbsp; Revolutionerar lärande med AI
                        </span>
                      </span>
                    }
                    sx={{
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                      color: 'white',
                      fontWeight: '600',
                      fontSize: '0.8rem',
                      padding: '2px 14px',
                      borderRadius: '16px',
                      '& .MuiChip-label': {
                        padding: '0 4px',
                      },
                    }}
                  />
                </Box>

                <Typography
                  variant='h2'
                  component='h1'
                  gutterBottom
                  sx={{
                    fontWeight: 900,
                    letterSpacing: -1,
                    fontSize: { xs: '2.5rem', md: '3.35rem' },
                  }}
                >
                  Grej of the Day
                </Typography>
                <Typography
                  variant='h5'
                  sx={{
                    mb: 4,
                    opacity: 0.8,
                    fontSize: '1.3rem',
                    width: { xs: '100%', md: '64%' },
                    margin: '0 auto',
                    fontWeight: '500',
                    lineHeight: '31px',
                    display: 'block',
                    mt: 4,
                  }}
                >
                  Upptäck mikrolektioner som engagerar och väcker nyfikenhet hos elever. Använd
                  Studera.ai för att skapa din egen "Grej of the Day" med hjälp av AI - inklusive
                  bilder och färdiga presentationer.
                </Typography>

                <Box sx={{ textAlign: 'center', mt: 7 }}>
                  <Link to='/app/grej-of-the-day' style={{ textDecoration: 'none' }}>
                    <StyledButton
                      variant='blue'
                      color='primary'
                      size='large'
                      endIcon={<ArrowForwardIcon />}
                    >
                      Skapa en lektion med AI nu
                    </StyledButton>
                  </Link>
                </Box>
              </motion.div>
            </Grid>
          </Grid>
        </Container>
      </GradientBackground>

      <Box
        sx={{
          '& p': {
            fontSize: '1.27rem',
            lineHeight: '2.2rem',
            fontWeight: '500',
            color: theme.palette.mode === 'dark' ? '#cac1d7' : '#333333',
          },
          '& h5': {
            marginTop: '50px',
          },
        }}
      >
        {/* Exempel på Grej of the Day

        <Container sx={{ my: 10, textAlign: 'center' }}>
          <Typography variant='h4' sx={{ mb: 4, fontWeight: '600' }}>
            Exempel på Grej of the Day
          </Typography>
          <Box
            component='img'
            src='https://via.placeholder.com/800x450.png?text=Bild+som+demonstrerar+Grej+of+the+Day'
            alt='Exempel på Grej of the Day'
            sx={{ width: '100%', maxWidth: 800, mb: 4 }}
          />
        </Container>
 */}
        {/* Video Demonstration */}
        <Container sx={{ mb: 10, mt: 12, textAlign: 'center', display: 'none' }}>
          <Typography
            variant='h3'
            gutterBottom
            sx={{ fontWeight: 'bold', fontSize: '2.3em', mb: 7, mt: 10 }}
          >
            Se hur det fungerar
          </Typography>
          <Box
            sx={{
              position: 'relative',
              overflow: 'hidden',
              paddingTop: '56.25%',
              mb: 4,
            }}
          >
            <iframe
              title='Grej of the Day video demonstration'
              src='https://www.youtube.com/embed/dQw4w9WgXcQ'
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                border: 'none',
              }}
              allowFullScreen
            ></iframe>
          </Box>
        </Container>

        {/* Vad är "Grej of the Day"? */}
        <Container id='grej' sx={{ my: 15, textAlign: 'center' }}>
          <Typography
            variant='h3'
            gutterBottom
            sx={{ fontWeight: 'bold', fontSize: '2.3em', mb: 4, mt: 10 }}
          >
            Vad är "Grej of the Day"?
          </Typography>
          <Typography variant='body1' sx={{ mb: 4, opacity: 0.8, lineHeight: '1.7rem' }}>
            "Grej of the Day" är en metod för att inspirera och engagera elever genom korta och
            minnesvärda mikrolektioner. Varje lektion handlar om ett spännande ämne, presenterat på
            ett sätt som främjar inlärning och minnesretention.
          </Typography>
          <Typography variant='body1' sx={{ mb: 4, opacity: 0.8, lineHeight: '1.7rem' }}>
            På Studera.ai kan du enkelt skapa dessa lektioner med AI som genererar innehåll, bilder
            och till och med PowerPoint-presentationer som hjälper dig att snabbt komma igång med
            undervisningen.
          </Typography>
          <Box sx={{ textAlign: 'center', mt: 4 }}>
            <Link to='/app/grej-of-the-day' style={{ textDecoration: 'none' }}>
              <StyledButton variant='contained' color='secondary' size='large'>
                Skapa din "Grej of the Day" nu
              </StyledButton>
            </Link>
          </Box>
        </Container>

        {/* Artikel om "Grej of the Day" */}
        <Container sx={{ my: 15 }}>
          <Box sx={{ maxWidth: '800px', margin: '0 auto', textAlign: 'left' }}>
            <Typography
              variant='h3'
              gutterBottom
              sx={{ fontWeight: 'bold', fontSize: '2.3em', mb: 4, mt: 10 }}
            >
              Fördelar med "Grej of the Day"
            </Typography>

            <Typography variant='body1' sx={{ mb: 4, opacity: 0.9, lineHeight: '1.8rem' }}>
              I dagens snabbrörliga utbildningslandskap är det avgörande att hitta innovativa
              metoder för att hålla eleverna engagerade och motiverade. "Grej of the Day" har visat
              sig vara ett kraftfullt verktyg som lärare kan använda för att stimulera nyfikenhet,
              främja kreativitet och förbättra inlärningsresultaten.
            </Typography>

            <Typography variant='body1' sx={{ mb: 4, opacity: 0.9, lineHeight: '1.8rem' }}>
              "Grej of the Day" är som sagt en daglig aktivitet eller ett föremål som introduceras i
              klassrummet för att fokusera elevernas uppmärksamhet på ett specifikt ämne eller
              koncept. Genom att variera dagens innehåll kan lärare skapa en dynamisk och interaktiv
              lärandemiljö som uppmuntrar eleverna att tänka kritiskt och delta aktivt i lektionen.
            </Typography>

            <Typography variant='h5' sx={{ mb: 4, fontWeight: '700' }}>
              Fördelar med "Grej of the Day"
            </Typography>
            <Box component='ol' sx={{ mb: 4, pl: 4 }}>
              <Box component='li' sx={{ mb: 2 }}>
                <Typography variant='body1' sx={{ opacity: 0.9, lineHeight: '1.8rem' }}>
                  <strong>Ökat engagemang:</strong> Genom att introducera nya och varierade
                  aktiviteter varje dag hålls elevernas intresse vid liv, vilket gör dem mer benägna
                  att delta och lära sig.
                </Typography>
              </Box>
              <Box component='li' sx={{ mb: 2 }}>
                <Typography variant='body1' sx={{ opacity: 0.9, lineHeight: '1.8rem' }}>
                  <strong>Flexibilitet:</strong> "Grej of the Day" kan anpassas efter olika ämnen,
                  åldersgrupper och inlärningsstilar, vilket gör det till ett mångsidigt verktyg i
                  olika utbildningsmiljöer.
                </Typography>
              </Box>
              <Box component='li' sx={{ mb: 2 }}>
                <Typography variant='body1' sx={{ opacity: 0.9, lineHeight: '1.8rem' }}>
                  <strong>Stimulering av kreativitet:</strong> Regelbundna variationer uppmuntrar
                  eleverna att tänka utanför boxen och utveckla sina kreativa färdigheter genom att
                  utforska nya idéer och koncept.
                </Typography>
              </Box>
              <Box component='li' sx={{ mb: 2 }}>
                <Typography variant='body1' sx={{ opacity: 0.9, lineHeight: '1.8rem' }}>
                  <strong>Förbättrad social interaktion:</strong> Många "Grej of the
                  Day"-aktiviteter kräver samarbete och kommunikation, vilket stärker klassens
                  samhörighet och utvecklar sociala färdigheter.
                </Typography>
              </Box>
              <Box component='li' sx={{ mb: 2 }}>
                <Typography variant='body1' sx={{ opacity: 0.9, lineHeight: '1.8rem' }}>
                  <strong>Daglig struktur:</strong> En fast daglig rutin kan ge eleverna en känsla
                  av trygghet och förutsägbarhet, vilket kan förbättra deras fokus och
                  inlärningsförmåga.
                </Typography>
              </Box>
            </Box>

            <Typography variant='h5' sx={{ mb: 4, fontWeight: '700' }}>
              Generera "Grej of the Day" med AI
            </Typography>
            <Typography variant='body1' sx={{ mb: 4, opacity: 0.9, lineHeight: '1.8rem' }}>
              Med Studera.ai blir det enklare än någonsin att integrera "Grej of the Day" i din
              undervisning. Vår AI-drivna plattform gör det möjligt för dig att generera engagerande
              och skräddarsydda mikrolektioner på bara en minut. Du kan välja mellan olika teman,
              språk, årskurser samt anpassa antalet fakta-sektioner och kuriositeter per sektion för
              att passa dina elevers behov.
            </Typography>
            <Typography variant='body1' sx={{ mb: 4, opacity: 0.9, lineHeight: '1.8rem' }}>
              Dessutom kan du generera passande bilder med AI, exportera innehållet som
              PowerPoint-presentationer eller skriva ut dem för att enkelt dela med dina elever och
              kollegor. Allt detta sparar tid och resurser samtidigt som du säkerställer att din
              undervisning är både aktuell och relevant.
            </Typography>

            <Typography variant='h5' sx={{ mb: 4, fontWeight: '700' }}>
              Utmaningar och lösningar
            </Typography>
            <Typography variant='body1' sx={{ mb: 4, opacity: 0.9, lineHeight: '1.8rem' }}>
              Trots de många fördelarna kan implementeringen av "Grej of the Day" medföra vissa
              utmaningar. Dessa inkluderar tidskrävande förberedelser, resursbegränsningar, olika
              inlärningshastigheter bland eleverna samt att hitta rätt balans mellan lek och
              lärande.
            </Typography>
            <Typography variant='body1' sx={{ mb: 4, opacity: 0.9, lineHeight: '1.8rem' }}>
              Med Studera.ai kan dessa utmaningar övervinnas genom:
            </Typography>
            <Box component='ol' sx={{ mb: 4, pl: 4 }}>
              <Box component='li' sx={{ mb: 2 }}>
                <Typography variant='body1' sx={{ opacity: 0.9, lineHeight: '1.8rem' }}>
                  <strong>Tidsbesparing:</strong> AI-genererat innehåll minskar den tid som krävs
                  för att förbereda nya aktiviteter dagligen.
                </Typography>
              </Box>
              <Box component='li' sx={{ mb: 2 }}>
                <Typography variant='body1' sx={{ opacity: 0.9, lineHeight: '1.8rem' }}>
                  <strong>Resursoptimering:</strong> Med en mängd förinstallerade teman och
                  anpassningsalternativ kan du skapa varierat innehåll utan att behöva extra
                  material.
                </Typography>
              </Box>
              <Box component='li' sx={{ mb: 2 }}>
                <Typography variant='body1' sx={{ opacity: 0.9, lineHeight: '1.8rem' }}>
                  <strong>Differentierad undervisning:</strong> Anpassa aktiviteterna för att möta
                  olika inlärningsstilar och behov, vilket säkerställer att alla elever kan delta
                  och dra nytta av lektionerna.
                </Typography>
              </Box>
              <Box component='li' sx={{ mb: 2 }}>
                <Typography variant='body1' sx={{ opacity: 0.9, lineHeight: '1.8rem' }}>
                  <strong>Balans mellan lek och lärande:</strong> Studera.ai hjälper dig att skapa
                  aktiviteter som är både roliga och pedagogiskt värdefulla, vilket främjar en
                  positiv lärandemiljö.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Container>

        {/* Funktioner */}
        <Container sx={{ my: 15 }}>
          <Typography variant='h4' sx={{ mb: 8, textAlign: 'center', fontWeight: '600' }}>
            Funktioner
          </Typography>
          <Grid container spacing={4}>
            {features.map((feature, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Card
                  sx={{
                    height: '100%',
                    padding: 3,
                    textAlign: 'center',
                    boxShadow: 'none',
                    border: `1px solid ${theme.palette.divider}`,
                    borderRadius: '12px',
                    transition: 'transform 0.3s',
                    '&:hover': {
                      transform: 'translateY(-10px)',
                      boxShadow: `0 4px 20px rgba(0,0,0,0.1)`,
                    },
                  }}
                >
                  <Box sx={{ mb: 2 }}>
                    {React.cloneElement(feature.icon, { sx: { fontSize: '7rem' } })}
                  </Box>
                  <Typography variant='h6' sx={{ mb: 2, fontWeight: '700', fontSize: '1.6rem' }}>
                    {feature.title}
                  </Typography>
                  <Typography variant='body2' sx={{ opacity: 0.8 }}>
                    {feature.description}
                  </Typography>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>

        {/* Anpassningsalternativ */}
        <Container sx={{ my: 15, textAlign: 'center' }}>
          <Typography variant='h4' sx={{ mb: 4, fontWeight: '600' }}>
            Anpassa din "Grej of the Day"
          </Typography>
          <Typography variant='body1' sx={{ mb: 6, opacity: 0.8, lineHeight: '1.7rem' }}>
            Med Studera.ai kan du skräddarsy varje "Grej of the Day" efter dina behov. Välj mellan
            olika teman eller låt AI slumpa fram ett spännande ämne. Anpassa språket, årskursen och
            bestäm hur många fakta-sektioner och kuriositeter du vill inkludera per sektion. Allt
            detta kan du göra på bara en minut!
          </Typography>
          {/*
          <Box
            component='img'
            src='https://via.placeholder.com/800x450.png?text=Anpassningsalternativ'
            alt='Anpassningsalternativ för Grej of the Day'
            sx={{ width: '100%', maxWidth: 800, mb: 4 }}
          />
          */}
        </Container>
      </Box>

      {/* Hur fungerar det? */}
      <Container sx={{ my: 15 }}>
        <Typography variant='h4' sx={{ mb: 8, textAlign: 'center', fontWeight: '600' }}>
          Så fungerar det
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Box
              component='img'
              src='https://via.placeholder.com/800x450.png?text=AI+genererar+Grej+of+the+Day'
              alt='AI genererar Grej of the Day'
              sx={{ width: '100%', borderRadius: '12px' }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant='h6' sx={{ mb: 2, fontWeight: '700' }}>
              1. Välj dina inställningar
            </Typography>
            <Typography variant='body1' sx={{ mb: 4, opacity: 0.8 }}>
              Anpassa ditt "Grej of the Day" genom att välja tema, språk, årskurs samt antal
              fakta-sektioner och kuriositeter.
            </Typography>
            <Typography variant='h6' sx={{ mb: 2, fontWeight: '700' }}>
              2. Låt AI arbeta
            </Typography>
            <Typography variant='body1' sx={{ mb: 4, opacity: 0.8 }}>
              Vår AI genererar automatiskt innehåll, bilder och presentationer baserat på dina valda
              inställningar.
            </Typography>
            <Typography variant='h6' sx={{ mb: 2, fontWeight: '700' }}>
              3. Exportera eller dela
            </Typography>
            <Typography variant='body1' sx={{ mb: 4, opacity: 0.8 }}>
              Exportera din "Grej of the Day" som en PowerPoint-presentation eller skriv ut den för
              att enkelt dela med dina elever och kollegor.
            </Typography>
          </Grid>
        </Grid>
      </Container>

      {/* Stats Section */}
      <Box sx={{ py: 10, backgroundColor: theme.palette.background.default }}>
        <Container>
          <Grid container spacing={4} justifyContent='center'>
            {stats.map((stat, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Box sx={{ textAlign: 'center' }}>
                  <Typography variant='h3' sx={{ fontWeight: '700' }}>
                    {stat.value}
                  </Typography>
                  <Typography variant='h6' sx={{ opacity: 0.8 }}>
                    {stat.label}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Testimonials Section */}
      <TestimonialsSection source='grejOfTheDay' />

      {/* Universitet och Media Sektion */}
      <UniversitySection bgcolor='background.paper' showHeadline={false} />
      <MediaSection bgcolor='background.default' />

      <Footer />

      {!isMobile && (
        <Box
          sx={{
            position: 'fixed',
            bottom: 16,
            right: 16,
            zIndex: 9999,
          }}
        >
          <IconButton onClick={toggleDarkMode}>
            {isDark ? <LightModeIcon /> : <DarkModeIcon />}
          </IconButton>
        </Box>
      )}
    </>
  );
};

export default GrejPage;
