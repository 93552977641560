import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { Link as MuiLink } from '@mui/material';
import { GradientBackground } from './StyledComponents';

const BackgroundAndVisionSection = ({ contactFormRef }) => {
  const scrollToContact = e => {
    e.preventDefault();
    contactFormRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <GradientBackground className='gradient-background' noSvg={true}>
      <Container maxWidth='lg' sx={{ pt: { xs: 10, md: 20 }, pb: 15, position: 'relative' }}>
        <Grid container spacing={8} alignItems='center'>
          <Grid item xs={12} md={9}>
            <Box sx={{ pl: { xs: 0, md: 4 } }}>
              <Typography
                variant='h2'
                component='h1'
                gutterBottom
                sx={{
                  fontWeight: 700,
                  letterSpacing: -1,
                  fontSize: { xs: '2.5rem', md: '2.25rem' },
                  mb: 3,
                }}
              >
                Bakgrund och vision
              </Typography>
              <Typography
                variant='h5'
                sx={{
                  mb: 4,
                  opacity: 0.8,
                  fontSize: '1.1rem',
                  fontWeight: '500',
                  lineHeight: '1.634',
                }}
              >
                Hej! Vad roligt att du har hittat till Studera.AI! Jag heter Peter Hellman och
                grundade Studera.AI utifrån ett personligt behov. Kort därefter började plattformen
                spridas bland lärare, framför allt på grundskolan. Det var lärarna som hörde av sig
                till mig och berättade att de saknade en resurs där man kunde generera konkret
                pedagogiskt material med AI, på olika språk och nivåer, med stöd av bilder och
                tydlig struktur.
                <br />
                <br />
                Min vision är att Studera.AI ska vara ett komplement till den ordinarie
                undervisningen — inte en ersättning. Jag uppskattar därför all feedback jag kan få
                för att förbättra tjänsten och möta lärares och elevers behov ännu bättre. Jag vill
                gärna höra dina tankar, tips och idéer! Tveka inte att höra av dig – tillsammans kan
                vi skapa något ännu bättre för lärare, elever, pedagoger & läxhjälpare!
              </Typography>

              <Grid
                container
                alignItems='center'
                sx={{
                  mt: 6,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '30px',
                  }}
                >
                  <Box
                    component='img'
                    src='/img/pete.jpg'
                    sx={{
                      width: '110px',
                      height: 'auto',
                      borderRadius: '100%',
                      border: '3px solid white',
                      flexShrink: 0,
                    }}
                  />
                  <Box>
                    <Box
                      component='img'
                      src='/img/signature.png'
                      sx={{
                        height: '38px',
                        width: 'auto',
                        mb: 0,
                      }}
                    />
                    <Typography variant='h5' sx={{ fontWeight: 600, mb: 0.2, fontSize: '1rem' }}>
                      Peter Hellman, VD & Grundare
                    </Typography>
                    <Typography variant='body2' sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                      Grundare av Studera.AI
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            md={3}
            sx={{ position: 'relative', display: { xs: 'none', md: 'block' } }}
          />
        </Grid>
      </Container>
    </GradientBackground>
  );
};

export default BackgroundAndVisionSection;
