import React, { useRef, useEffect } from 'react';
import { Box, Typography, Fade } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const ProgressBar = ({ progress, label }) => {
  const progressRef = useRef(null);
  const progressPercentRef = useRef(null);
  const theme = useTheme();

  useEffect(() => {
    if (progressRef.current) {
      progressRef.current.style.width = `${progress}%`;
    }
    if (progressPercentRef.current) {
      progressPercentRef.current.textContent = `${Math.round(progress)}%`;
    }
  }, [progress]);

  return (
    <Fade in={true} timeout={1000}>
      <Box sx={{ width: '60%', mt: 2, margin: '0 auto', textAlign: 'center' }}>
        {label && (
          <Typography
            variant='body2'
            color='text.secondary'
            sx={{ mb: 1, textAlign: 'center', fontWeight: '500' }}
          >
            {label}
          </Typography>
        )}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Box
            sx={{
              width: '100%',
              height: '8px',
              backgroundColor: theme.palette.mode === 'dark' ? '#283744' : '#f7f7f7',
              borderRadius: '14px',
              overflow: 'hidden',
            }}
          >
            <Box
              ref={progressRef}
              sx={{
                width: `${progress}%`,
                height: '100%',
                backgroundColor: theme => theme.palette.text.main,
                transition: 'width 0.1s linear',
              }}
            />
          </Box>
          <Typography
            ref={progressPercentRef}
            variant='body2'
            color='text.secondary'
            sx={{ fontWeight: '500' }}
          >
            {Math.round(progress)}%
          </Typography>
        </Box>
      </Box>
    </Fade>
  );
};

export default ProgressBar;
