import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Grid,
  FormControl,
  Select,
  MenuItem,
  Divider,
  Tooltip,
  IconButton,
  TextField,
  Card,
  CardContent,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Switch,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import GenerateIcon from '@mui/icons-material/AutoAwesome';

import { handlePrintGrammarLesson } from './printFunctions';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { callApi } from './api';
import { useSnackbar } from './SnackbarHandler';
import PrintIcon from '@mui/icons-material/Print';
import ProgressBar from './ProgressBar';
import Voice from './Voice';
import LanguageSelector from './LanguageSelector';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { CustomFormControlLabel, trackLesson } from './functions';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import InfoBox from './InfoBox';

import Logger from './Logger';
import LessonDrawer from './LessonDrawer';
import SaveLessonDialog from './SaveLessonDialog';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import HistoryIcon from '@mui/icons-material/History';

const grammarConcepts = {
  satsdelar: [
    { id: 'subjekt', label: 'Subjekt' },
    { id: 'predikat', label: 'Predikat' },
    { id: 'direkt_objekt', label: 'Direkt objekt' },
    { id: 'indirekt_objekt', label: 'Indirekt objekt' },
    { id: 'predikatsfyllnad', label: 'Predikatsfyllnad' },
    { id: 'attribut', label: 'Attribut' },
    { id: 'adverbial', label: 'Adverbial' },
  ],

  ordklasser: [
    { id: 'substantiv', label: 'Substantiv' },
    { id: 'verb', label: 'Verb' },
    { id: 'adjektiv', label: 'Adjektiv' },
    { id: 'adverb', label: 'Adverb' },
    { id: 'preposition', label: 'Preposition' },
    { id: 'konjunktion', label: 'Konjunktion' },
    { id: 'pronomen', label: 'Pronomen' },
    { id: 'artikel', label: 'Artikel' },
    { id: 'interjektion', label: 'Interjektion' },
    { id: 'räkneord', label: 'Räkneord' },
  ],

  tempus: [
    { id: 'presens', label: 'Presens' },
    { id: 'preteritum', label: 'Preteritum' },
    { id: 'perfekt', label: 'Perfekt' },
    { id: 'pluskvamperfekt', label: 'Pluskvamperfekt' },
    { id: 'futurum_simple', label: 'Futurum (ska/kommer att)' },
    { id: 'futurum_preteritum', label: 'Futurum preteritum (skulle)' },
  ],

  modus: [
    { id: 'indikativ', label: 'Indikativ' },
    { id: 'imperativ', label: 'Imperativ' },
    { id: 'konjunktiv', label: 'Konjunktiv' },
  ],

  diatesis: [
    { id: 'aktiv', label: 'Aktiv' },
    { id: 'passiv', label: 'Passiv' },
  ],

  species: [
    { id: 'obestämd_form', label: 'Obestämd form' },
    { id: 'bestämd_form', label: 'Bestämd form' },
  ],

  numerus: [
    { id: 'singularis', label: 'Singularis' },
    { id: 'pluralis', label: 'Pluralis' },
  ],

  genus: [
    { id: 'utrum', label: 'Utrum (en-ord)' },
    { id: 'neutrum', label: 'Neutrum (ett-ord)' },
  ],

  kasus: [
    { id: 'grundform', label: 'Grundform' },
    { id: 'genitiv', label: 'Genitiv' },
  ],

  komparation: [
    { id: 'positiv', label: 'Positiv' },
    { id: 'komparativ', label: 'Komparativ' },
    { id: 'superlativ', label: 'Superlativ' },
  ],

  satstyper: [
    { id: 'huvudsats', label: 'Huvudsats' },
    { id: 'bisats', label: 'Bisats' },
  ],

  verbform: [
    { id: 'infinitiv', label: 'Infinitiv' },
    { id: 'supinum', label: 'Supinum' },
    { id: 'presens_particip', label: 'Presens particip' },
    { id: 'perfekt_particip', label: 'Perfekt particip' },
  ],

  pronomentyper: [
    { id: 'personliga', label: 'Personliga' },
    { id: 'possessiva', label: 'Possessiva' },
    { id: 'demonstrativa', label: 'Demonstrativa' },
    { id: 'relativa', label: 'Relativa' },
    { id: 'interrogativa', label: 'Interrogativa' },
    { id: 'indefinita', label: 'Indefinita' },
    { id: 'reflexiva', label: 'Reflexiva' },
    { id: 'reciproka', label: 'Reciproka' },
  ],

  ordföljd: [
    { id: 'rak', label: 'Rak ordföljd' },
    { id: 'omvänd', label: 'Omvänd ordföljd' },
    { id: 'bisatsordföljd', label: 'Bisatsordföljd' },
  ],
};

const GrammarLesson = ({ theme, styles, user }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);

  const [sentenceCount, setSentenceCount] = useState(2);
  const [isGenerating, setIsGenerating] = useState(false);
  const [lessonData, setLessonData] = useState(null);
  const [progress, setProgress] = useState(0);
  const { showSnackbar } = useSnackbar();
  const [language, setLanguage] = useState('svenska');
  const [showTranslation, setShowTranslation] = useState(false);
  const [translationLanguage, setTranslationLanguage] = useState('arabiska');
  const [selectedConcepts, setSelectedConcepts] = useState({});

  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isEditingSubtitle, setIsEditingSubtitle] = useState(false);
  const [titleText, setTitleText] = useState('Grammatikövning');
  const [editingExampleId, setEditingExampleId] = useState(null);
  const [editingTranslationId, setEditingTranslationId] = useState(null);
  const [isEditingStory, setIsEditingStory] = useState(false);
  const [isEditingStoryTranslation, setIsEditingStoryTranslation] = useState(false);
  const [cardVariant, setCardVariant] = useState('classic');

  const [showAllCategories, setShowAllCategories] = useState(false);
  const [expandedCategories, setExpandedCategories] = useState({});

  const initialCategories = ['satsdelar', 'ordklasser', 'tempus', 'verbform'];

  const handleSaveLessonClick = () => {
    setSaveDialogOpen(true);
  };

  const handleSaveWithComment = async (comment, name, coverImage, tags) => {
    Logger.log(JSON.stringify(lessonData, null, 2));
    await trackLesson(
      user.uid,
      'GrammarLesson.js',
      lessonData,
      null,
      true,
      comment,
      name,
      coverImage,
      tags
    );
    setDrawerOpen(true);
    showSnackbar('Lektionen sparad i din lektionsbank!', 'success');
  };

  const handleConceptChange = (category, conceptId) => {
    setSelectedConcepts(prev => ({
      ...prev,
      [category]: {
        ...prev[category],
        [conceptId]: !prev[category]?.[conceptId],
      },
    }));
  };

  const simulateProgress = (startProgress, endProgress, duration, setProgressFn) => {
    const stepSize = 0.5;
    const steps = (endProgress - startProgress) / stepSize;
    const stepDuration = duration / steps;
    let currentProgress = startProgress;

    const intervalId = setInterval(() => {
      currentProgress += stepSize;
      if (currentProgress >= endProgress) {
        clearInterval(intervalId);
      } else {
        setProgressFn(currentProgress);
      }
    }, stepDuration);

    return () => clearInterval(intervalId);
  };

  const handleGenerateGrammar = async () => {
    setIsGenerating(true);
    setProgress(0);
    setLessonData(null);
    let stopProgressSimulation = null;

    try {
      stopProgressSimulation = simulateProgress(0, 90, 15000, setProgress);

      const selectedConceptsArray = Object.entries(selectedConcepts).flatMap(
        ([category, concepts]) =>
          Object.entries(concepts)
            .filter(([_, isSelected]) => isSelected)
            .map(([conceptId]) => conceptId)
      );

      const response = await callApi(
        [
          {
            role: 'system',
            content: `Du är en språklärare som specialiserar sig på grammatik och språkstruktur.`,
          },
          {
            role: 'user',
            content: `Skapa en grammatiklektion med ${sentenceCount} exempelmeningar på ${language}${showTranslation ? ` med översättning till ${translationLanguage}` : ''}.
            Använd följande grammatiska koncept: ${selectedConceptsArray.join(', ')}.

            Returnera ett JSON-objekt med följande struktur:
            {
              "title": "Grammatikövning",
              "instructions": "En kort instruktion om hur man ska arbeta med meningarna",
              "sentences": [
                {
                  "sentence": "exempelmening",
                  ${showTranslation ? `"translation": "översättning",` : ''}
                  "analysis": "grammatisk analys av meningen som pekar ut de valda koncepten"
                }
              ],
              "story": "En kort berättelse (8-10 meningar) som använder de grammatiska koncepten"${showTranslation ? ',\n              "storyTranslation": "Berättelsens översättning"' : ''}
            }`,
          },
        ],
        8000,
        true,
        user.uid
      );

      if (stopProgressSimulation) {
        stopProgressSimulation();
      }

      setProgress(95);

      let messageContent = response.data.choices[0].message.content.trim();
      if (messageContent.startsWith('```json')) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith('```')) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const data = JSON.parse(messageContent);
      setProgress(100);
      await new Promise(resolve => setTimeout(resolve, 800));

      setLessonData(data);
      showSnackbar('Grammatiklektion har genererats!', 'success');

      await trackLesson(user.uid, 'GrammarLesson.js', null, {
        language,
        translationLanguage,

        concepts: selectedConceptsArray.join(', '),
      });
    } catch (error) {
      console.error('Error generating grammar lesson:', error);
      showSnackbar('Ett fel uppstod när lektionen skulle genereras', 'error');
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <Box sx={styles.container}>
      <Box
        sx={{
          ...styles.innerContainer,
          width: {
            xs: '100%',
            md: '87%',
          },
          margin: '0 auto',
        }}
      >
        <Typography
          variant='h1'
          gutterBottom
          sx={{
            fontWeight: 'bold',
            fontSize: '1.8em',
            mb: 3,
            mt: 5,
            textAlign: 'center',
          }}
        >
          Grammatiklektion
        </Typography>

        <Box sx={{ ...styles.buttonsContainer, ...styles.additionalButtonsContainer }}>
          <Tooltip title='Dina sparade lektioner'>
            <Button
              variant='contained'
              color='primary'
              disableRipple
              onClick={() => setDrawerOpen(true)}
              startIcon={<HistoryIcon />}
              sx={{
                padding: '8px',
                marginRight: '0px',
                paddingLeft: '14px',
                paddingRight: '3px',
                minWidth: 'auto',
                textTransform: 'none',
              }}
            />
          </Tooltip>
        </Box>

        <InfoBox
          icon={MenuBookIcon}
          title='Instruktioner för Grammatiklektion'
          instructions={[
            'Ställ in antal exempelmeningar',
            'Välj språk för meningarna',
            'Välj grammatiska koncept att inkludera',
            'Aktivera översättning om så önskas',
          ]}
          theme={theme}
          styles={styles}
        />
        <Box
          sx={{
            ...styles.freshBox,
            padding: '30px',
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Typography
                variant='subtitle1'
                sx={{
                  fontWeight: '500',
                  mb: 1,
                }}
              >
                Antal meningar:
              </Typography>
              <FormControl fullWidth>
                <Select value={sentenceCount} onChange={e => setSentenceCount(e.target.value)}>
                  {[2, 4, 6, 8, 10].map(num => (
                    <MenuItem key={num} value={num}>
                      {num}&nbsp; meningar
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                variant='subtitle1'
                sx={{
                  fontWeight: '500',
                  mb: 1,
                }}
              >
                Språk:
              </Typography>
              <LanguageSelector language={language} setLanguage={setLanguage} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant='subtitle1' sx={{ fontWeight: '500', mb: 1 }}>
                Visa översättning?
              </Typography>

              <FormControlLabel
                control={
                  <Switch
                    checked={showTranslation}
                    onChange={e => setShowTranslation(e.target.checked)}
                  />
                }
                label={showTranslation ? 'Ja' : 'Nej'}
              />
            </Grid>
            {showTranslation && (
              <Grid item xs={12} md={12}>
                <Typography
                  variant='subtitle1'
                  sx={{
                    fontWeight: '500',
                    mb: 1,
                  }}
                >
                  Språk för översättning:
                </Typography>
                <LanguageSelector
                  language={translationLanguage}
                  setLanguage={setTranslationLanguage}
                />
              </Grid>
            )}

            <Typography
              variant='subtitle1'
              sx={{ fontWeight: '500', margin: '20px', mb: 1, mt: 4 }}
            >
              Välj grammatiskt koncept:
            </Typography>

            <Box
              sx={{
                backgroundColor: theme.palette.mode === 'dark' ? '#15202b' : '#f9f9f9',
                padding: '20px',
                borderRadius: '8px',
                margin: '20px',
                width: '100% ',
                mt: 0,
                mb: 2,
              }}
            >
              <Grid container spacing={3}>
                {(showAllCategories
                  ? Object.entries(grammarConcepts)
                  : Object.entries(grammarConcepts).filter(([key]) =>
                      initialCategories.includes(key)
                    )
                ).map(([category, concepts]) => (
                  <Grid item xs={12} md={3} key={category}>
                    <Typography
                      variant='subtitle1'
                      sx={{
                        fontWeight: '500',
                        mb: 1,
                        textTransform: 'capitalize',

                        color: theme =>
                          theme.palette.mode === 'dark'
                            ? 'rgba(255, 255, 255, 0.7)'
                            : 'rgba(0, 0, 0, 0.6)',
                      }}
                    >
                      {category.replace('_', ' ')}:
                    </Typography>
                    <FormGroup>
                      {(expandedCategories[category] ? concepts : concepts.slice(0, 4)).map(
                        concept => (
                          <CustomFormControlLabel
                            theme={theme}
                            key={concept.id}
                            control={<Checkbox />}
                            label={
                              <Box>
                                <Typography sx={{ fontSize: '0.975rem' }}>
                                  {concept.label}
                                </Typography>
                              </Box>
                            }
                            checked={selectedConcepts[category]?.[concept.id] || false}
                            onChange={() => handleConceptChange(category, concept.id)}
                          />
                        )
                      )}
                    </FormGroup>
                    {concepts.length > 4 && (
                      <Button
                        onClick={() =>
                          setExpandedCategories(prev => ({
                            ...prev,
                            [category]: !prev[category],
                          }))
                        }
                        sx={{
                          mt: 0.5,
                          textTransform: 'none',
                          color: theme =>
                            theme.palette.mode === 'dark'
                              ? 'rgba(255, 255, 255, 0.6)'
                              : 'rgba(0, 0, 0, 0.6)',
                          padding: '2px 8px',
                          minWidth: 'auto',
                          fontSize: '0.75rem',
                          '&:hover': {
                            backgroundColor: 'transparent',
                            color: theme =>
                              theme.palette.mode === 'dark'
                                ? 'rgba(255, 255, 255, 0.8)'
                                : 'rgba(0, 0, 0, 0.8)',
                          },
                          ml: 5.5,
                        }}
                        endIcon={
                          expandedCategories[category] ? (
                            <KeyboardArrowUpIcon sx={{ fontSize: '0.875rem' }} />
                          ) : (
                            <KeyboardArrowDownIcon sx={{ fontSize: '0.875rem' }} />
                          )
                        }
                      >
                        {expandedCategories[category] ? 'Visa färre' : 'Visa fler'}
                      </Button>
                    )}
                  </Grid>
                ))}
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                    }}
                  >
                    <Button
                      onClick={() => setShowAllCategories(!showAllCategories)}
                      sx={{
                        mt: 0.5,
                        textTransform: 'none',
                        color: theme =>
                          theme.palette.mode === 'dark'
                            ? 'rgba(255, 255, 255, 0.6)'
                            : 'rgba(0, 0, 0, 0.8)',
                        padding: '2px 8px',
                        minWidth: 'auto',
                        fontSize: '0.95rem',
                        '&:hover': {
                          backgroundColor: 'transparent',
                          color: theme =>
                            theme.palette.mode === 'dark'
                              ? 'rgba(255, 255, 255, 0.8)'
                              : 'rgba(0, 0, 0, 0.8)',
                        },
                      }}
                      endIcon={
                        expandedCategories[showAllCategories] ? (
                          <KeyboardArrowUpIcon sx={{ fontSize: '1rem' }} />
                        ) : (
                          <KeyboardArrowDownIcon sx={{ fontSize: '1rem' }} />
                        )
                      }
                      endIcon={
                        showAllCategories ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                      }
                    >
                      {showAllCategories
                        ? 'Visa färre kategorier'
                        : 'Visa alla grammatiska koncept'}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Button
            variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
            onClick={handleGenerateGrammar}
            disabled={isGenerating}
            sx={{
              mt: 6,
            }}
            startIcon={isGenerating ? <CircularProgress size={16} /> : <GenerateIcon />}
          >
            {isGenerating ? 'Genererar...' : 'Generera lektion'}
          </Button>
          {isGenerating && (
            <Box
              sx={{
                mt: 7,
              }}
            >
              <ProgressBar progress={progress} label='Genererar grammatiklektion...' />
            </Box>
          )}
          {lessonData && (
            <Box
              sx={{
                mt: 4,
              }}
            >
              <Divider
                sx={{
                  mt: 3,
                  mb: 4,
                }}
              />
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 4 }}>
                <Button
                  variant={theme.palette.mode === 'light' ? 'print' : 'contained'}
                  startIcon={<PrintIcon />}
                  onClick={() =>
                    handlePrintGrammarLesson(lessonData, titleText, showTranslation, theme)
                  }
                >
                  Skriv ut
                </Button>

                <Button
                  variant='print'
                  startIcon={<SaveAsIcon />}
                  sx={{ ml: 2 }}
                  onClick={handleSaveLessonClick}
                >
                  Spara i lektionsbank
                </Button>
              </Box>
              <SaveLessonDialog
                open={saveDialogOpen}
                onClose={() => setSaveDialogOpen(false)}
                onSave={handleSaveWithComment}
                theme={theme}
                lessonName={titleText}
              />
              <LessonDrawer
                userId={user.uid}
                lessonType='GrammarLesson.js'
                theme={theme}
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                lessonIcon={MenuBookIcon}
                onSelectLesson={lesson => {
                  setLessonData(lesson.data);
                  setTitleText(lesson.lessonName);
                  setDrawerOpen(false);

                  showSnackbar(`Lektionen har laddats in.`, 'success');
                  document.querySelector('hr')?.scrollIntoView({ behavior: 'smooth' });
                }}
              />
              {/* Title */}
              {isEditingTitle ? (
                <TextField
                  value={titleText}
                  onChange={e => setTitleText(e.target.value)}
                  onBlur={() => {
                    setIsEditingTitle(false);
                    showSnackbar('Titeln har uppdaterats', 'success');
                  }}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      setIsEditingTitle(false);
                      showSnackbar('Titeln har uppdaterats', 'success');
                    }
                  }}
                  autoFocus
                  fullWidth
                  variant='standard'
                  sx={{
                    backgroundColor: 'transparent',
                    '& .MuiInputBase-input': {
                      textAlign: 'center',
                      fontWeight: 'bold',
                      fontSize: '2.6em',
                      marginBottom: '1.2rem',
                      marginTop: '1.5rem',
                    },
                  }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              ) : (
                <Box
                  sx={{
                    position: 'relative',
                    display: 'inline-block',
                    margin: '0 auto',
                    width: '100%',
                    mb: 3,
                    mt: 5,
                    ':hover .edit-icon': {
                      opacity: 1,
                    },
                  }}
                >
                  <Typography
                    variant='h1'
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '2.6em',
                      cursor: 'pointer',
                      textAlign: 'center',
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: 2,
                    }}
                  >
                    {titleText || ' '}
                    <Box
                      component='span'
                      sx={{
                        opacity: 0,
                        transition: 'opacity 0.2s',
                        display: 'inline-flex',
                        gap: 0.5,
                      }}
                      className='edit-icon'
                    >
                      <Tooltip title='Redigera' placement='top'>
                        <IconButton size='small' onClick={() => setIsEditingTitle(true)}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Typography>
                </Box>
              )}
              <Grid container spacing={3} sx={{ mt: 6 }}>
                {lessonData.sentences.map((item, index) => (
                  <Grid item xs={12} md={6} key={index}>
                    <Card
                      sx={{
                        display: 'flex',
                        height: '100%',
                        '&:hover .delete-button': {
                          opacity: 1,
                          visibility: 'visible',
                        },
                        borderRadius: 2,
                        padding: '2px',
                        position: 'relative',
                        '&:before': {
                          content: '""',
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          right: 0,
                          height: '4px',
                          background: 'linear-gradient(90deg, primary.light, primary.main)',
                          borderTopLeftRadius: 8,
                          borderTopRightRadius: 8,
                        },
                      }}
                    >
                      <IconButton
                        className='delete-button'
                        size='small'
                        onClick={() => {
                          const updatedSentences = lessonData.sentences.filter(
                            (_, i) => i !== index
                          );
                          setLessonData({
                            ...lessonData,
                            sentences: updatedSentences,
                          });
                          showSnackbar('Meningen har tagits bort', 'success');
                        }}
                        sx={{
                          position: 'absolute',
                          top: 8,
                          right: 8,
                          opacity: 0,
                          visibility: 'hidden',
                          transition: 'all 0.2s ease',
                          zIndex: 1,
                        }}
                      >
                        <Tooltip title='Ta bort mening' placement='top'>
                          <DeleteOutlineIcon fontSize='small' />
                        </Tooltip>
                      </IconButton>

                      <CardContent
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          //justifyContent: 'center',
                          width: '100%',

                          minHeight: '120px',
                          position: 'relative',
                          padding: '16px',
                        }}
                      >
                        {/* Original Sentence */}
                        <Box
                          sx={{
                            '&:hover .controls': {
                              opacity: 1,
                            },
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            mb: showTranslation ? 2 : 0,
                          }}
                        >
                          {editingExampleId === index ? (
                            <TextField
                              fullWidth
                              value={item.sentence}
                              onChange={e => {
                                const updatedSentences = [...lessonData.sentences];
                                updatedSentences[index] = {
                                  ...item,
                                  sentence: e.target.value,
                                };
                                setLessonData({
                                  ...lessonData,
                                  sentences: updatedSentences,
                                });
                              }}
                              onBlur={() => {
                                setEditingExampleId(null);
                                showSnackbar('Meningen har uppdaterats', 'success');
                              }}
                              onKeyDown={e => {
                                if (e.key === 'Enter') {
                                  e.preventDefault();
                                  setEditingExampleId(null);
                                  showSnackbar('Meningen har uppdaterats', 'success');
                                }
                              }}
                              autoFocus
                            />
                          ) : (
                            <Typography
                              variant='h6'
                              sx={{
                                flex: 1,
                              }}
                            >
                              {item.sentence}
                              <Box
                                component='span'
                                className='controls'
                                sx={{
                                  opacity: 0,
                                  transition: 'opacity 0.2s',
                                  display: 'inline-flex',
                                  gap: 0.5,
                                  ml: 1,
                                }}
                              >
                                <Tooltip title='Redigera' placement='top'>
                                  <IconButton
                                    size='small'
                                    onClick={() => setEditingExampleId(index)}
                                  >
                                    <EditIcon fontSize='small' />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Typography>
                          )}
                        </Box>

                        {/* Translation if enabled */}
                        {showTranslation && (
                          <Box
                            sx={{
                              '&:hover .controls': {
                                opacity: 1,
                              },
                              display: 'flex',
                              alignItems: 'center',
                              gap: 1,
                            }}
                          >
                            {editingTranslationId === index ? (
                              <TextField
                                fullWidth
                                value={item.translation}
                                onChange={e => {
                                  const updatedSentences = [...lessonData.sentences];
                                  updatedSentences[index] = {
                                    ...item,
                                    translation: e.target.value,
                                  };
                                  setLessonData({
                                    ...lessonData,
                                    sentences: updatedSentences,
                                  });
                                }}
                                onBlur={() => {
                                  setEditingTranslationId(null);
                                  showSnackbar('Översättningen har uppdaterats', 'success');
                                }}
                                onKeyDown={e => {
                                  if (e.key === 'Enter') {
                                    e.preventDefault();
                                    setEditingTranslationId(null);
                                    showSnackbar('Översättningen har uppdaterats', 'success');
                                  }
                                }}
                                autoFocus
                              />
                            ) : (
                              <Typography
                                variant='body1'
                                sx={{
                                  flex: 1,
                                }}
                              >
                                {item.translation}
                                <Box
                                  component='span'
                                  className='controls'
                                  sx={{
                                    opacity: 0,
                                    transition: 'opacity 0.2s',
                                    display: 'inline-flex',
                                    gap: 0.5,
                                    ml: 1,
                                  }}
                                >
                                  <Tooltip title='Redigera översättning' placement='top'>
                                    <IconButton
                                      size='small'
                                      onClick={() => setEditingTranslationId(index)}
                                    >
                                      <EditIcon fontSize='small' />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              </Typography>
                            )}
                          </Box>
                        )}

                        <Box
                          sx={{
                            position: 'absolute',
                            right: showTranslation ? '45px' : '5px',
                            bottom: '8px',
                          }}
                        >
                          <Voice
                            content={item.sentence}
                            fontSize={24}
                            userId={user.uid}
                            language={language}
                          />
                        </Box>

                        {/* Voice Controls */}
                        {showTranslation && (
                          <>
                            <Box
                              sx={{
                                position: 'absolute',
                                right: '5px',
                                bottom: '8px',
                              }}
                            >
                              <Voice
                                content={item.translation}
                                fontSize={24}
                                userId={user.uid}
                                language={translationLanguage}
                              />
                            </Box>
                          </>
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  mt: 2,
                  mb: 4,
                }}
              >
                <Button
                  variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                  startIcon={<AddIcon />}
                  sx={{ mt: 5 }}
                  onClick={() => {
                    const newSentence = {
                      sentence: 'Ny exempelmening',
                      ...(showTranslation && {
                        translation: 'Översättningen här',
                      }),
                      analysis: 'Grammatisk analys kommer här',
                    };
                    setLessonData({
                      ...lessonData,
                      sentences: [...lessonData.sentences, newSentence],
                    });
                    showSnackbar('Ny mening har lagts till', 'success');
                  }}
                >
                  Lägg till mening
                </Button>
              </Box>
              {/* Story Section */} {/* Story Section */}
              <Card
                sx={{
                  mt: 10,
                  mb: 4,
                  background: theme.palette.mode === 'light' ? '#f5f5f5' : '#1e1e1e',
                  borderRadius: '15px',
                  padding: '20px',
                }}
              >
                <CardContent>
                  <Typography
                    variant='h5'
                    gutterBottom
                    sx={{ fontWeight: '600', fontSize: '25px', display: 'block', mb: 3 }}
                  >
                    Berättelse
                  </Typography>
                  {/* Original Story */}
                  <Box
                    sx={{
                      '&:hover .controls': {
                        opacity: 1,
                      },
                      display: 'flex',
                      alignItems: 'flex-start',
                      gap: 1,
                      mb: 3,
                    }}
                  >
                    {isEditingStory ? (
                      <TextField
                        fullWidth
                        multiline
                        rows={4}
                        value={lessonData.story}
                        onChange={e => {
                          setLessonData({
                            ...lessonData,
                            story: e.target.value,
                          });
                        }}
                        onBlur={() => {
                          setIsEditingStory(false);
                          showSnackbar('Berättelsen har uppdaterats', 'success');
                        }}
                        onKeyDown={e => {
                          if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault();
                            setIsEditingStory(false);
                            showSnackbar('Berättelsen har uppdaterats', 'success');
                          }
                        }}
                        autoFocus
                        sx={{
                          '& .MuiInputBase-input': {
                            lineHeight: 1.8,
                          },
                        }}
                      />
                    ) : (
                      <Typography
                        variant='body1'
                        sx={{
                          lineHeight: 1.8,
                          flex: 1,
                          display: 'flex',
                          alignItems: 'flex-start',
                          gap: 1,
                        }}
                      >
                        {lessonData.story}
                        <Box
                          component='span'
                          className='controls'
                          sx={{
                            opacity: 0,
                            transition: 'opacity 0.2s',
                            display: 'inline-flex',
                            gap: 0.5,
                            ml: 1,
                            mt: 0.5,
                          }}
                        >
                          <Voice
                            content={lessonData.story}
                            fontSize={20}
                            userId={user.uid}
                            language={language}
                            tooltipPlacement='top'
                          />
                          <Tooltip title='Redigera berättelse' placement='top'>
                            <IconButton size='small' onClick={() => setIsEditingStory(true)}>
                              <EditIcon fontSize='small' />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Typography>
                    )}
                  </Box>

                  {/* Story Translation if enabled */}
                  {showTranslation && (
                    <>
                      <Typography variant='h6' gutterBottom>
                        Översättning
                      </Typography>
                      <Box
                        sx={{
                          '&:hover .controls': {
                            opacity: 1,
                          },
                          display: 'flex',
                          alignItems: 'flex-start',
                          gap: 1,
                        }}
                      >
                        {isEditingStoryTranslation ? (
                          <TextField
                            fullWidth
                            multiline
                            rows={4}
                            value={lessonData.storyTranslation}
                            onChange={e => {
                              setLessonData({
                                ...lessonData,
                                storyTranslation: e.target.value,
                              });
                            }}
                            onBlur={() => setIsEditingStoryTranslation(false)}
                            autoFocus
                            sx={{
                              '& .MuiInputBase-input': {
                                lineHeight: 1.8,
                              },
                            }}
                          />
                        ) : (
                          <Typography
                            variant='body1'
                            sx={{
                              lineHeight: 1.8,
                              flex: 1,
                              color: 'text.secondary',
                              display: 'flex',
                              alignItems: 'flex-start',
                              gap: 1,
                            }}
                          >
                            {lessonData.storyTranslation}
                            <Box
                              component='span'
                              className='controls'
                              sx={{
                                opacity: 0,
                                transition: 'opacity 0.2s',
                                display: 'inline-flex',
                                gap: 0.5,
                                ml: 1,
                                mt: 0.5,
                              }}
                            >
                              <Voice
                                content={lessonData.storyTranslation}
                                fontSize={20}
                                userId={user.uid}
                                language={translationLanguage}
                                tooltipPlacement='top'
                              />
                              <Tooltip title='Redigera översättning' placement='top'>
                                <IconButton
                                  size='small'
                                  onClick={() => setIsEditingStoryTranslation(true)}
                                >
                                  <EditIcon fontSize='small' />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </Typography>
                        )}
                      </Box>
                    </>
                  )}
                </CardContent>
              </Card>
              <Card
                sx={{
                  mt: 10,
                  mb: 4,
                  background:
                    theme.palette.mode === 'light'
                      ? 'linear-gradient(145deg, #ffffff 0%, #f5f5f5 100%)'
                      : 'linear-gradient(145deg, #2d2d2d 0%, #1a1a1a 100%)',
                  borderRadius: '15px',
                  padding: '20px',
                  position: 'relative',
                  '&:before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    height: '4px',
                    background: 'linear-gradient(90deg, primary.light, primary.main)',
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                  },
                }}
              >
                <CardContent>
                  <Typography
                    variant='h5'
                    sx={{ fontWeight: '600', fontSize: '25px', display: 'block', mb: 3 }}
                    gutterBottom
                  >
                    Facit
                  </Typography>
                  {lessonData.sentences.map((item, index) => (
                    <Box
                      key={index}
                      sx={{
                        mb: 3,
                      }}
                    >
                      <Typography
                        variant='subtitle1'
                        sx={{
                          fontWeight: 'bold',
                          mb: 1,
                        }}
                      >
                        Mening {index + 1}:
                      </Typography>
                      <Typography
                        variant='body1'
                        sx={{
                          mb: 1,
                        }}
                      >
                        "{item.sentence}"
                      </Typography>
                      {showTranslation && (
                        <Typography
                          variant='body1'
                          sx={{
                            mb: 1,
                            color: 'text.secondary',
                          }}
                        >
                          "{item.translation}"
                        </Typography>
                      )}
                      <Typography
                        variant='body2'
                        sx={{
                          color: 'text.secondary',
                        }}
                      >
                        {item.analysis}
                      </Typography>
                    </Box>
                  ))}
                </CardContent>
              </Card>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default GrammarLesson;
