import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  IconButton,
  Typography,
  Box,
  ImageList,
  ImageListItem,
  Chip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import BlurryBackdrop from './BlurryBackdrop';
import { East as ArrowIcon } from '@mui/icons-material';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import SchoolIcon from '@mui/icons-material/School';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CommentIcon from '@mui/icons-material/Comment';
import { lessonMapping } from './functions';

import Logger from './Logger';

import LessonCard from './LessonCard';

const SaveLessonDialog = ({
  open,
  onClose,
  onSave,
  theme,
  lessonName,
  lessonObject,
  lessonType,
}) => {
  const [comment, setComment] = useState('');
  const [name, setName] = useState(lessonName || '');
  const [selectedImage, setSelectedImage] = useState('');
  const defaultGradient = `linear-gradient(135deg, #667eea 0%, #764ba2 100%)`;

  const [tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState('');

  // Add some suggested tags - you can modify these or load from your backend
  const suggestedTags = ['Matematik', 'Svenska', 'Engelska', 'Historia', 'Biologi', 'Gymnasiet'];

  //Logger.log(JSON.stringify(lessonObject, null, 2));

  const findImageUrls = obj => {
    let urls = [];

    const search = item => {
      if (typeof item === 'string' && item.startsWith('http')) {
        urls.push(item);
      } else if (typeof item === 'object' && item !== null) {
        Object.values(item).forEach(value => search(value));
      }
    };

    search(obj);
    return urls;
  };

  const imageUrls = findImageUrls(lessonObject);

  useEffect(() => {
    if (open) {
      setSelectedImage('');
      if (imageUrls.length > 0) {
        setSelectedImage(imageUrls[0]);
      }
    }
  }, [open]);

  // First useEffect - ONLY runs once when component mounts to set initial name
  useEffect(() => {
    setName(lessonName || '');
  }, []); // Empty dependency array = runs once on mount

  // Second useEffect - ONLY handles images
  useEffect(() => {
    if (imageUrls.length > 0 && !selectedImage) {
      setSelectedImage(imageUrls[0]);
    }
  }, [imageUrls]);

  const handleSave = () => {
    Logger.log('tags: ', tags);

    onSave(comment, name, selectedImage, tags);
    setComment('');
    setName(lessonName || '');
    onClose();
  };

  const formatDate = () => {
    return new Date().toLocaleDateString('sv-SE', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      BackdropComponent={BlurryBackdrop}
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: 1000,
          borderRadius: '20px',
          backgroundColor: theme.palette.mode === 'dark' ? '#22303C' : 'white',
          backgroundImage: theme.palette.mode === 'dark' ? 'none' : 'white',
          padding: { xs: '0px', md: '20px' },
        },
      }}
    >
      <DialogTitle
        sx={{
          px: 3,
          py: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
          <IconButton onClick={onClose} size='small' sx={{ position: 'absolute' }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography
          variant='h6'
          component='div'
          sx={{
            fontWeight: 'bold',
            fontSize: '1.6rem',
            padding: '5px',
            textAlign: 'center',
          }}
        >
          Spara lektion i din lektionsbank
        </Typography>
      </DialogTitle>

      <Typography
        variant='subtitle1'
        sx={{
          fontSize: '1.2rem',
          color: theme.palette.text.secondary,
          textAlign: 'center',
          mb: 3,
        }}
      >
        Vill du ändra namn och lägga till en beskrivning till lektionen? (valfritt!)
      </Typography>

      <DialogContent sx={{ p: 3, mt: 0 }}>
        <Box sx={{ display: 'flex', gap: 8 }}>
          {/* Left column - Form */}
          <Box sx={{ flex: 1 }}>
            <Typography variant='subtitle2' sx={{ mb: 1, mt: 0 }}>
              Lektionsnamn:
            </Typography>
            <TextField
              autoFocus
              fullWidth
              value={name}
              placeholder='Namn på lektionen'
              onChange={e => setName(e.target.value)}
              variant='outlined'
              inputProps={{
                maxLength: 50,
              }}
              sx={{
                mb: 3,
              }}
            />
            <Typography variant='subtitle2' sx={{ mb: 1, mt: 0 }}>
              Beskrivning:
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={3}
              value={comment}
              onChange={e => setComment(e.target.value)}
              placeholder='Beskrivning av lektionen, t.ex. lektion i svenska på onsdag för klass 9B'
              variant='outlined'
              sx={{
                mb: 3,
              }}
            />

            <Typography
              variant='subtitle2'
              sx={{ mb: 0, mt: 0, display: selectedImage ? 'block' : 'none' }}
            >
              Omslagsbild:
            </Typography>
            <ImageList sx={{ maxHeight: 70, mt: 0.2 }} cols={4} rowHeight={70}>
              {imageUrls.map((url, index) => (
                <ImageListItem
                  key={index}
                  onClick={() => setSelectedImage(url)}
                  sx={{
                    cursor: 'pointer',
                    border: url === selectedImage ? '2px solid #4f46e5' : 'none',
                    borderRadius: '5px',
                    padding: '2px',
                  }}
                >
                  <img
                    src={url}
                    alt={`Thumbnail ${index + 1}`}
                    style={{ objectFit: 'cover', height: '100%', borderRadius: '5px' }}
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </Box>

          {/* Right column - Preview */}
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography
              variant='subtitle2'
              sx={{
                mb: 2,
                textAlign: 'center',
              }}
            >
              Förhandsvisning av lektionskort:
            </Typography>

            <LessonCard
              name={name}
              comment={comment}
              timestamp={new Date()}
              lessonType={lessonType}
              imageUrl={selectedImage}
              size='large'
              theme={theme}
            />
          </Box>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography variant='subtitle2' sx={{ mb: 1 }}>
            Taggar (valfritt):
          </Typography>
          <Box sx={{ mb: 3 }}>
            <TextField
              fullWidth
              value={tagInput}
              onChange={e => setTagInput(e.target.value)}
              onKeyDown={e => {
                if (e.key === 'Enter' && tagInput.trim()) {
                  setTags([...new Set([...tags, tagInput.trim()])]);
                  setTagInput('');
                }
              }}
              placeholder='Skriv och tryck enter för att lägga till tagg'
              variant='outlined'
            />
            {tagInput && (
              <Box sx={{ mt: 1, mb: 1 }}>
                {suggestedTags
                  .filter(
                    tag => tag.toLowerCase().includes(tagInput.toLowerCase()) && !tags.includes(tag)
                  )
                  .slice(0, 5)
                  .map(suggestion => (
                    <Button
                      key={suggestion}
                      size='small'
                      onClick={() => {
                        setTags([...new Set([...tags, suggestion])]);
                        setTagInput('');
                      }}
                      sx={{ mr: 1, mt: 1 }}
                      variant='outlined'
                    >
                      {suggestion}
                    </Button>
                  ))}
              </Box>
            )}
            <Box sx={{ mt: 1, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {tags.map(tag => (
                <Chip
                  key={tag}
                  label={tag}
                  onDelete={() => setTags(tags.filter(t => t !== tag))}
                  sx={{ m: 0.5 }}
                />
              ))}
            </Box>
          </Box>
        </Box>
      </DialogContent>

      <DialogActions
        sx={{
          p: 2,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button
          variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
          color='primary'
          onClick={handleSave}
          sx={{ textTransform: 'none' }}
          startIcon={<SaveAsIcon />}
        >
          Spara lektion
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SaveLessonDialog;
