import React, { createContext, useState, useContext } from 'react';
import { Snackbar, Alert, Slide } from '@mui/material';

const SnackbarHandler = createContext();

function SlideTransition(props) {
  return <Slide {...props} direction='up' />;
}

export const SnackbarProvider = ({ children }) => {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info',
    duration: 2300,
  });

  const showSnackbar = (message, severity = 'info', duration = 2300) => {
    // Replace \n with <br /> for newline support
    const formattedMessage = message.replace(/\n/g, '<br />');
    setSnackbar({ open: true, message: formattedMessage, severity, duration });
  };

  const closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <SnackbarHandler.Provider value={{ showSnackbar }}>
      {children}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={snackbar.duration}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        //TransitionComponent={SlideTransition}
      >
        <Alert
          onClose={closeSnackbar}
          severity={snackbar.severity}
          sx={{ width: { xs: '100%', md: '100%' } }}
          variant='filled'
        >
          <span dangerouslySetInnerHTML={{ __html: snackbar.message }} />
        </Alert>
      </Snackbar>
    </SnackbarHandler.Provider>
  );
};

export const useSnackbar = () => useContext(SnackbarHandler);
