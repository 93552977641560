import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Fade } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ConstructionIcon from '@mui/icons-material/Construction';
import WestIcon from '@mui/icons-material/West';

const OverlayLesson = ({ message, submessage, user }) => {
  const navigate = useNavigate();
  const [showOverlay, setShowOverlay] = useState(false);

  const adminUsers = [
    'Z1dF5PGfjvdBGS9egzACvcSRZiN2',
    'x2rjH7JxWxc6GnFgPSTxGxN1bm63',
    'Z6BLSMsm38Un5mgW0O6Z3TToHq12',
  ];

  useEffect(() => {
    // Only show overlay if user is not an admin
    if (user && user.uid && !adminUsers.includes(user.uid)) {
      const timer = setTimeout(() => {
        setShowOverlay(true);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [user]);

  // Don't render anything if user is an admin
  if (!user || adminUsers.includes(user.uid)) {
    return null;
  }

  return (
    <Fade in={showOverlay}>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.9)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 9999,
          padding: '20px',
        }}
      >
        <Box
          sx={{
            backgroundColor: 'background.paper',
            borderRadius: '16px',
            padding: '40px',
            maxWidth: '600px',
            width: '90%',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 3,
          }}
        >
          <ConstructionIcon sx={{ fontSize: 54, color: '#733fea' }} />
          <img
            src='/img/skolverket.png'
            alt='Skolverket'
            style={{
              maxWidth: '200px',
              height: 'auto',
              marginBottom: '16px',
            }}
          />
          <Typography variant='h5' component='h2' gutterBottom sx={{ fontWeight: '600' }}>
            {message}
          </Typography>
          <Typography variant='body1' color='text.secondary' sx={{ mb: 4, fontSize: '1.05rem' }}>
            {submessage}
          </Typography>
          <Button
            type='submit'
            fullWidth
            onClick={() => navigate('/app')}
            sx={{
              mt: 2,
              mb: 0,
              py: 1.5,
              borderRadius: '12px',
              background: 'linear-gradient(135deg, #6366f1 0%, #8b5cf6 100%)',
              color: 'white',
              fontWeight: 600,
              fontSize: '1rem',
              textTransform: 'none',
              '&:hover': {
                background: 'linear-gradient(135deg, #4f46e5 0%, #7c3aed 100%)',
              },
              '&:disabled': {
                background: 'grey.500',
              },
            }}
            startIcon={<WestIcon />}
          >
            Tillbaka till lektionerna
          </Button>
        </Box>
      </Box>
    </Fade>
  );
};

export default OverlayLesson;
