import React from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Container,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const faqData = [
  {
    question: 'Vad kan jag som lärare & pedagog använda Studera.ai till?',
    answer:
      'Vi erbjuder ett brett utbud av AI-verktyg för lärare & pedagoger, däribland möjligheten att med hjälp av AI generera lektioner och pedagogiskt material i 50 olika språk för olika ämnen som t.ex. svenska, matematik och NO. Du kan skapa allt från grundläggande läsförståelseövningar till kreativa lektioner som "På spåret", "Grej of the day" och "Mystiska bilden".',
  },

  {
    question: 'Hur sparar jag lektionerna jag har genererat?',
    answer:
      'Du kan enkelt klicka på "Skriva ut"-knappen för att antingen skriva ut lektionen eller spara den som PDF. Du kan också spara lektionen direkt till Google Docs eller Google Classroom.',
  },
  {
    question: 'Hur säkerställs säkerheten för lärarna och elevernas data med GDPR?',
    answer:
      'Datasäkerhet är vår högsta prioritet. Alla data krypteras och lagras säkert enligt GDPR och andra dataskyddslagar. Du kan själv ställa in om lektionerna som genereras ska sparas i vårt system, eller ej — då kan du helt enkelt bara skriva ut lektionen eller spara som PDF. Endast behöriga lärare har tillgång till sina elevers data, och vi delar aldrig personlig information med tredje part.',
  },
  {
    question: 'Är det pedagogiska materialet inkluderande och präglat av mångfald?',
    answer:
      'Ja, att samtliga elever i klassrummet, oavsett bakgrund och personlig identitet, ska kunna känna sig inkluderade i det pedagogiska material som genereras är en av våra högsta prioriteter. Därför kommer bilderna och lektionsmaterialet som genereras representera ett brett spektrum av perspektiv, kulturer och erfarenheter. Vi strävar således efter att generera material som speglar en mångfald av berättelser och individer, för att främja en inkluderande och respektfull lärandemiljö där alla elever känner sig sedda och värdesatta.',
  },

  {
    question: 'Hur många språk stödjer ni?',
    answer:
      'Studera.ai stödjer över 50 olika språk, med särskild hänsyn till språk som talas av olika grupper i Sverige, såsom arabiska, somaliska, persiska (farsi), urdu och polska. Vi strävar efter att inkludera både stora världsspråk och mindre språk från Mellanöstern, Afrika och Asien för att säkerställa att vi möter behoven hos så många lärare och elever som möjligt.',
  },

  {
    question: 'Vilka AI-modeller använder ni?',
    answer:
      'Vi stödjer flera ledande AI-modeller såsom Claude Sonnet och Sonnet 3.5 från Anthropic,  Google Gemini, Mistral Large och ChatGPT 4o från OpenAI. Vi har även vår egen AI-modell som vi ständigt finjusterar för att optimera prestandan för våra användares behov och säkerställa att material som genereras håller bästa tänkbara kvalitet.',
  },

  // {
  //   question: 'Vilka presentationsverktyg finns tillgängliga?',
  //   answer:
  //     'Vår PowerPoint-generator kan skapa presentationer automatiskt. Du kan också använda vår AI-bildgenerator för att skapa visuellt material till dina presentationer och lektioner.',
  // },
  {
    question: 'Hur kan jag få support om jag behöver hjälp?',
    answer:
      'Du kan kontakta oss via e-post på info@studera.ai så svarar vi inom 12-24 timmar. Vi uppskattar även din feedback som t.ex. förslag på nya lektioner och funktioner. Så hör gärna av dig!',
  },
];

const SimpleFAQSection = () => {
  return (
    <Box sx={{ py: 8 }}>
      <Container maxWidth='md'>
        <Box>
          {faqData.map((faq, index) => (
            <Accordion
              key={index}
              sx={{
                mb: 2,
                boxShadow: 'none',
                border: 'none',
                '&:before': { display: 'none' },
                bgcolor: 'transparent',
                '& .MuiCollapse-root': {
                  bgcolor: 'transparent',
                },
                '& .MuiAccordion-region': {
                  bgcolor: 'transparent',
                },
              }}
              disableGutters
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ fontSize: '2.2rem' }} />}
                sx={{
                  bgcolor: 'transparent',
                  '& .MuiAccordionSummary-content': {
                    margin: '12px 0',
                  },
                  '& .MuiTypography-root': {
                    transition: 'color 0.3s ease',
                  },
                  '&:hover .MuiTypography-root': {
                    color: 'gray',
                  },
                }}
              >
                <Typography
                  variant='h6'
                  sx={{
                    fontWeight: '600',
                    fontSize: '1.3rem',
                  }}
                >
                  {faq.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ bgcolor: 'transparent', pt: 0 }}>
                <Typography sx={{ lineHeight: '1.9rem', fontSize: '1.1rem' }}>
                  {faq.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default SimpleFAQSection;
