import React from 'react';
import { Grid, Card, CardContent, Typography, Button, Box, LinearProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { getStyles } from '../index';
import CircleIcon from '@mui/icons-material/Circle';
import SquareIcon from '@mui/icons-material/Square';
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import StarIcon from '@mui/icons-material/Star';
import HexagonIcon from '@mui/icons-material/Hexagon';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PentagonIcon from '@mui/icons-material/Pentagon';
import DiamondIcon from '@mui/icons-material/Diamond';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const CourseModules = ({ courseModules, handleModuleClick }) => {
  const theme = useTheme();
  const styles = getStyles(theme);
  const iconList = [
    CircleIcon,
    SquareIcon,
    ChangeHistoryIcon,
    StarIcon,
    HexagonIcon,
    PentagonIcon,
    DiamondIcon,
  ];

  const getRandomIcon = () => {
    const RandomIcon = iconList[Math.floor(Math.random() * iconList.length)];
    return <RandomIcon size={38} sx={{ ...styles.grayText, fontSize: '40px' }} />;
  };

  const calculateModuleProgress = chapters => {
    const totalChapters = chapters.length;
    const completedChapters = chapters.filter(chapter => chapter.chapter_completed).length;
    return (completedChapters / totalChapters) * 100;
  };

  const calculateEstimatedTime = chapters => {
    const remainingChapters = chapters.filter(chapter => !chapter.chapter_completed).length;
    return remainingChapters * 20; // 20 minutes per remaining chapter
  };

  const isModuleCompleted = chapters => {
    return chapters.every(chapter => chapter.chapter_completed);
  };

  return (
    <Grid container spacing={3}>
      {courseModules.map((module, index) => {
        const moduleProgress = calculateModuleProgress(module.chapters);
        const estimatedTime = calculateEstimatedTime(module.chapters);
        const completed = isModuleCompleted(module.chapters);

        return (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{
                ...styles.softerBorder,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                boxShadow: 'none',
                backgroundImage: 'none',
                borderRadius: '7px',
                border: completed
                  ? theme.palette.mode === 'dark'
                    ? '1px solid #366235' // Dark green for completed in dark mode
                    : '1px solid #CBF2C9' // Light green for completed in light mode
                  : theme.palette.mode === 'dark'
                    ? '1px solid #35414d' // Dark border for incomplete in dark mode
                    : '1px solid #eee', // Light border for incomplete in light mode
                position: 'relative',
                transition: 'all 0.3s ease',

                '&:hover': {
                  transform: 'translateY(-2px)',
                },
              }}
            >
              {completed && (
                <CheckCircleIcon
                  sx={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    color: '#56A653',
                    fontSize: '24px',
                  }}
                />
              )}
              <CardContent
                sx={{
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  height: '100%',
                  padding: '30px',
                  border: 'none',

                  borderRadius: '7px',
                  backgroundColor: completed
                    ? theme.palette.mode === 'dark'
                      ? 'rgba(86, 166, 83, 0.1)'
                      : 'rgba(86, 166, 83, 0.05)'
                    : theme.palette.mode === 'dark'
                      ? 'rgba(255, 255, 255, 0.05)'
                      : 'white',
                  '&:hover': {
                    backgroundColor: completed
                      ? theme.palette.mode === 'dark'
                        ? 'rgba(86, 166, 83, 0.15)'
                        : 'rgba(86, 166, 83, 0.1)'
                      : theme.palette.mode === 'dark'
                        ? 'rgba(255, 255, 255, 0.1)'
                        : 'inherit',
                  },
                }}
              >
                <Box>{getRandomIcon(styles)}</Box>
                <Typography variant='h6' sx={{ mb: 2, mt: 1, fontWeight: '600' }}>
                  {`${index + 1}. ${module.module_title}`}
                </Typography>
                <Typography variant='body2' sx={{ ...styles.grayText }}>
                  {module.module_description}
                </Typography>
                <Box
                  sx={{
                    backgroundColor: theme.palette.mode === 'dark' ? '#2d3e4d' : '#f9f9f9',
                    borderRadius: '6px',
                    padding: '15px',
                    mt: 3,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      mb: 1,
                    }}
                  >
                    <Typography
                      variant='body2'
                      sx={{
                        ...styles.grayText,
                        fontSize: '13px',
                        display: 'flex',
                        alignItems: 'center',
                        mr: 3,
                      }}
                    >
                      <MenuBookIcon sx={{ mr: 1, fontSize: '16px' }} />
                      {module.chapters.length} kapitel
                    </Typography>
                    <Typography
                      variant='body2'
                      sx={{
                        ...styles.grayText,
                        fontSize: '13px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <AccessTimeIcon sx={{ mr: 1, fontSize: '16px' }} />
                      {estimatedTime} minuter kvar
                    </Typography>
                  </Box>

                  <LinearProgress
                    variant='determinate'
                    value={moduleProgress}
                    sx={{
                      height: 6,
                      borderRadius: 3,
                      mb: 0.5,
                      '& .MuiLinearProgress-bar': {
                        backgroundColor: completed ? '#56A653' : 'primary.main',
                      },
                    }}
                  />
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography
                      variant='body2'
                      sx={{ ...styles.grayText, fontSize: '12px' }}
                    ></Typography>
                    <Typography variant='body2' sx={{ ...styles.grayText, fontSize: '12px' }}>
                      {completed ? 'Slutfört' : `${Math.round(moduleProgress)}% avklarat`}
                    </Typography>
                  </Box>
                </Box>
                <Button
                  onClick={() => handleModuleClick(module)}
                  variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                  sx={{
                    width: '100%',
                    mt: 2,
                    ...(completed && {
                      backgroundColor: '#56A653',
                      '&:hover': {
                        backgroundColor: '#4a8f47',
                      },
                    }),
                  }}
                >
                  {completed ? 'Revidera kapitel' : 'Visa kapitel'}
                </Button>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default CourseModules;
