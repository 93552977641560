import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  Tooltip,
  IconButton,
  Typography,
  Divider,
  CircularProgress,
  Avatar,
  Grid,
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import GenerateIcon from '@mui/icons-material/AutoAwesome';
import ProgressBar from './ProgressBar';
import ImageIcon from '@mui/icons-material/Image';
import { callApi } from './api';
import { handlePrintSentenceFinisher } from './printFunctions';
import LoadingBar from 'react-top-loading-bar';
import LanguageSelector from './LanguageSelector';
import PrintIcon from '@mui/icons-material/Print';
import InfoBox from './InfoBox';
import SaveIcon from '@mui/icons-material/Save';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import { generateAndProcessImage, trackLesson } from './functions';
import { ThemeSelectorFlow } from './ThemeSelectorFlow';
import { useSnackbar } from './SnackbarHandler';
import { FourSquare } from 'react-loading-indicators';

import Logger from './Logger';

import { ThemeSelector } from './ThemeSelector';
import ImageGenerationModal from './ImageGenerationModal';
import GradeSelector, { gradeInstructions } from './GradeSelector';

import { useTheme } from '@mui/material/styles';
import { getStyles } from '../index';

import EditIcon from '@mui/icons-material/Edit';

import LessonDrawer from './LessonDrawer';
import SaveLessonDialog from './SaveLessonDialog';

import HistoryIcon from '@mui/icons-material/History';
import HoverableImage from './HoverableImage';

import {
  School as SchoolIcon,
  Watch as WatchIcon,
  LocalLibrary as LocalLibraryIcon,
  Castle as CastleIcon,
  Computer as ComputerIcon,
  Group as GroupIcon,
  LocationCity as LocationCityIcon,
  Public as PublicIcon,
  Person as PersonIcon,
  Shield as ShieldIcon,
  MenuBook as MenuBookIcon,
  Science as ScienceIcon,
} from '@mui/icons-material';

const SentenceFinisherLesson = ({ user }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);

  const [lessonTheme, setLessonTheme] = useState('generateWithAi');
  const [language, setLanguage] = useState('svenska');

  const [loadingImageIndex, setLoadingImageIndex] = useState(null);
  const [progress, setProgress] = useState(0);

  const [isGeneratingLesson, setIsGeneratingLesson] = useState(false);

  const [customTheme, setCustomTheme] = useState('');
  const [isGeneratingStoryImages, setIsGeneratingStoryImages] = useState(false);
  const { showSnackbar } = useSnackbar();
  const loadingBarRef = useRef(null);
  const [grade, setGrade] = useState('åk6');
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageInstructions, setImageInstructions] = useState('');
  const [imageGenerationType, setImageGenerationType] = useState(null);

  const [lessonData, setLessonData] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const [isEditingStory, setIsEditingStory] = useState(false);

  const theme = useTheme();
  const styles = getStyles(theme);

  const [isEditingTitle, setIsEditingTitle] = useState(false);

  const [editingSections, setEditingSections] = useState({
    continuations: false,
    plotTwists: false,
    environments: false,
  });

  const [predefinedTheme, setPredefinedTheme] = useState(null);
  const lessonTitleRef = useRef(null); // Add this ref
  const transcriptRef = useRef('');
  const uploadedFileRef = useRef('');

  const wikipediaContentRef = useRef('');
  const randomizedContentRef = useRef('');
  const coursePlanContentRef = useRef('');

  const additionalThemes = [
    { type: 'subheader', label: 'Teman som lämpar sig extra bra för denna lektionstyp:' },
    {
      value: 'mysteriet-pa-internatskolan',
      label: 'Mysteriet på internatskolan',
      content: 'Mysteriet på internatskolan',
      Icon: SchoolIcon,
    },
    {
      value: 'tidresenarens-dagbok',
      label: 'Tidresenärens dagbok',
      content: 'Tidresenärens dagbok',
      Icon: WatchIcon,
    },
    {
      value: 'det-forbjudna-biblioteket',
      label: 'Det förbjudna biblioteket',
      content: 'Det förbjudna biblioteket',
      Icon: LocalLibraryIcon,
    },
    {
      value: 'de-glomda-kungadomena',
      label: 'De glömda kungadömena',
      content: 'De glömda kungadömena',
      Icon: CastleIcon,
    },
    {
      value: 'aventyr-i-den-digitala-varlden',
      label: 'Äventyr i den digitala världen',
      content: 'Äventyr i den digitala världen',
      Icon: ComputerIcon,
    },
    {
      value: 'den-hemliga-klubben',
      label: 'Den hemliga klubben',
      content: 'Den hemliga klubben',
      Icon: GroupIcon,
    },
    {
      value: 'overgivna-platser',
      label: 'Övergivna platser och deras hemligheter',
      content: 'Övergivna platser och deras hemligheter',
      Icon: LocationCityIcon,
    },
    {
      value: 'varldernas-krig',
      label: 'Världarnas krig',
      content: 'Världarnas krig',
      Icon: PublicIcon,
    },
    {
      value: 'den-sista-overlevaren',
      label: 'Den sista överlevaren',
      content: 'Den sista överlevaren',
      Icon: PersonIcon,
    },
    {
      value: 'kampen-om-drakens-krona',
      label: 'Kampen om drakens krona',
      content: 'Kampen om drakens krona',
      Icon: ShieldIcon,
    },
  ];

  useEffect(() => {
    Logger.log('lessonTheme: ', lessonTheme);
  }, [lessonTheme]);

  useEffect(() => {
    Logger.log('predefinedTheme: ', predefinedTheme);
  }, [predefinedTheme]);

  useEffect(() => {
    Logger.log('customTheme: ', customTheme);
  }, [customTheme]);

  useEffect(() => {
    Logger.log('youtubeTranscript: ', transcriptRef.current);
  }, [transcriptRef.current]);

  useEffect(() => {
    Logger.log('wikipediaContentRef: ', wikipediaContentRef.current);
  }, [wikipediaContentRef.current]);
  useEffect(() => {
    Logger.log('uploadedFileRef: ', uploadedFileRef.current);
  }, [uploadedFileRef.current]);

  useEffect(() => {
    Logger.log('randomizedContentRef: ', randomizedContentRef.current);
  }, [randomizedContentRef.current]);

  useEffect(() => {
    Logger.log('coursePlanContentRef: ', coursePlanContentRef.current);
  }, [coursePlanContentRef.current]);

  const handleSaveLessonClick = () => {
    setSaveDialogOpen(true);
  };

  const handleSaveWithComment = async (comment, name, coverImage, tags) => {
    Logger.log(JSON.stringify(lessonData, null, 2));
    await trackLesson(
      user.uid,
      'SentenceFinisherLesson.js',
      lessonData,
      null,
      true,
      comment,
      name,
      coverImage,
      tags
    );
    setDrawerOpen(true);
    showSnackbar('Lektionen sparad i din lektionsbank!', 'success');
  };

  const handleSectionEdit = section => {
    setEditingSections(prev => ({
      ...prev,
      [section]: true,
    }));
  };

  const handleSectionSave = section => {
    setEditingSections(prev => ({
      ...prev,
      [section]: false,
    }));
    showSnackbar('Sektionen har uppdaterats', 'success');
  };

  const handleKeyDown = (e, section) => {
    if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
      handleSectionSave(section);
    }
  };

  const renderEditableSection = (section, titleKey, contentKey) => {
    const isEditing = editingSections[section];

    return (
      <Box
        sx={{
          position: 'relative',
          marginTop: 7,
          '&:hover': {
            '& .section-controls': {
              opacity: 1,
              visibility: 'visible',
            },
          },
        }}
      >
        <Box
          className='section-controls'
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            opacity: 0,
            visibility: 'hidden',
            transition: 'all 0.2s ease',
            display: 'flex',
            gap: 1,
            zIndex: 1,
          }}
        >
          <Tooltip title={isEditing ? 'Spara ändringar' : 'Redigera sektion'} placement='top'>
            <IconButton
              size='small'
              onClick={() => (isEditing ? handleSectionSave(section) : handleSectionEdit(section))}
            >
              {isEditing ? <SaveAsIcon fontSize='small' /> : <EditIcon fontSize='small' />}
            </IconButton>
          </Tooltip>
          <Tooltip title={`Ta bort sektion`} placement='top'>
            <IconButton
              size='small'
              onClick={() => {
                const updatedData = { ...lessonData };
                delete updatedData[contentKey];
                setLessonData(updatedData);
                showSnackbar(`Sektionen har tagits bort`, 'success');
              }}
            >
              <DeleteOutlineIcon fontSize='small' />
            </IconButton>
          </Tooltip>
        </Box>

        {isEditing ? (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              value={lessonData[titleKey] || ''}
              onChange={e => setLessonData(prev => ({ ...prev, [titleKey]: e.target.value }))}
              sx={{
                ...styles.editingTextField,
                width: '80%',
              }}
              InputProps={{
                sx: {
                  fontSize: '1.5em',
                  fontWeight: 'bold',
                },
              }}
            />
            <Box
              sx={{
                backgroundColor: theme => (theme.palette.mode === 'dark' ? '#15202b' : '#e0e0e029'),
                borderRadius: '10px',
                padding: '20px',
              }}
            >
              {lessonData[contentKey].map((item, index) => (
                <TextField
                  key={index}
                  fullWidth
                  multiline
                  value={item}
                  onChange={e => {
                    const newItems = [...lessonData[contentKey]];
                    newItems[index] = e.target.value;
                    setLessonData(prev => ({ ...prev, [contentKey]: newItems }));
                  }}
                  sx={{
                    mb: 2,
                    '& .MuiInputBase-root': {
                      fontSize: '1rem',
                      lineHeight: '1.5',
                    },
                    ...styles.editingTextField,
                  }}
                />
              ))}
              <Button
                variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                color='primary'
                startIcon={<SaveAsIcon />}
                onClick={() => handleSectionSave(section)}
                sx={{ mt: 2, textTransform: 'none' }}
              >
                Spara ändringar
              </Button>
            </Box>
          </Box>
        ) : (
          <>
            <Typography variant='h6' sx={{ fontWeight: 'bold', mb: 2, fontSize: '1.5em' }}>
              {lessonData[titleKey] || ''}
            </Typography>
            <Box
              sx={{
                backgroundColor: theme => (theme.palette.mode === 'dark' ? '#15202b' : '#e0e0e029'),
                borderRadius: '10px',
                padding: '20px',
                '& li': {
                  marginBottom: '12px',
                  lineHeight: '25px',
                  '& strong': {
                    fontWeight: '600',
                  },
                },
              }}
            >
              <ul>
                {lessonData[contentKey].map((item, index) => (
                  <li key={index}>
                    <Typography variant='body1'>{item}</Typography>
                  </li>
                ))}
              </ul>
            </Box>
          </>
        )}
      </Box>
    );
  };

  const handleImageButtonClick = (index = null) => {
    setSelectedImageIndex(index);
    setShowImageModal(true);
  };

  const handleCloseModal = () => {
    setShowImageModal(false);
    setImageInstructions('');
  };

  const handleGenerateWithInstructions = async () => {
    if (selectedImageIndex !== null) {
      handleCloseModal();
      await handleGenerateSpecificImage(selectedImageIndex);
    } else {
      handleCloseModal();
      await handleGenerateImages();
    }
  };

  const simulateProgress = (startProgress, endProgress, duration) => {
    const stepSize = 0.5;
    const steps = (endProgress - startProgress) / stepSize;
    const stepDuration = duration / steps;
    let currentProgress = startProgress;

    const intervalId = setInterval(() => {
      currentProgress += stepSize;
      if (currentProgress >= endProgress) {
        clearInterval(intervalId);
      } else {
        setProgress(currentProgress);
      }
    }, stepDuration);

    return () => clearInterval(intervalId);
  };

  const handleGenerateLesson = async () => {
    setProgress(0);
    setIsGeneratingLesson(true);
    const gradeInstructionsText = gradeInstructions[grade];
    let stopProgressSimulation = null;
    try {
      const generateTheme = lessonTheme === 'generateWithAi';
      let themeToUse;

      if (lessonTheme === 'youtube') {
        themeToUse = transcriptRef.current;
      } else if (lessonTheme === 'customTheme') {
        themeToUse = customTheme;
      } else if (lessonTheme === 'predefined') {
        themeToUse = predefinedTheme;
      } else if (lessonTheme === 'fileUpload') {
        themeToUse = uploadedFileRef.current;
      } else if (lessonTheme === 'wikipedia') {
        themeToUse = wikipediaContentRef.current;
      } else if (lessonTheme === 'randomizer') {
        themeToUse = randomizedContentRef.current;
      } else {
        //
        themeToUse = coursePlanContentRef.current;
      }

      Logger.log('themeToUse: ', themeToUse);
      stopProgressSimulation = simulateProgress(0, 90, 15000, setProgress);
      let response = await callApi(
        [
          {
            role: 'system',
            content:
              'You are an assistant that generates short stories with continuation suggestions, plot twists, and environment suggestions for language lessons.',
          },
          {
            role: 'user',
            content: `Generate a JSON object with a short story, 5 sentence continuation suggestions, 3 plot twists, and 3 environment suggestions in "${language}". Prioritize novelty and uniqueness in each generation. Avoid repeating themes, characters, or plot elements from previous responses. 

 ${gradeInstructionsText}
             The theme should be "${themeToUse}".

            Example response:
            {
              "theme": "theme in ${language}",
              "story": "Short story text in 10 sentences in ${language}...",
              "storyTitle": "Story title in ${language}...",
      
            "continuationTitle": "Förslag på nästa mening:",
              "continuations": [
                "Continuation suggestion 1",
                "Continuation suggestion 2",
                "Continuation suggestion 3",
                "Continuation suggestion 4",
                "Continuation suggestion 5"
              ],
                    "plotTwistTitle": "Förslag på plötsliga vändningar:",
              "plotTwists": [
                "Plot twist 1",
                "Plot twist 2",
                "Plot twist 3"
              ],
                            "environmentTitle": "Förslag på miljöer för nästa del av berättelsen:",
              "environments": [
                "Environment suggestion 1",
                "Environment suggestion 2",
                "Environment suggestion 3"
              ]
            }`,
          },
        ],
        6000,
        true,
        user.uid
      );

      if (stopProgressSimulation) {
        stopProgressSimulation();
      }

      setProgress(95);
      let messageContent = response.data.choices[0].message.content.trim();

      if (messageContent.startsWith('```json')) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith('```')) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const data = JSON.parse(messageContent);

      const dataWithImages = {
        ...data,
        images: [{ imageUrl: null }, { imageUrl: null }, { imageUrl: null }],
      };

      Logger.log(JSON.stringify(dataWithImages, null, 2));
      setProgress(100);

      setLessonData(dataWithImages);
      showSnackbar('Lektionen har genererats!', 'success');
      await trackLesson(user.uid, 'SentenceFinisherLesson.js', null, {
        lessonTheme: lessonTheme,
        language: language,
      });
    } catch (error) {
      console.error('Error generating lesson:', error);
    } finally {
      setIsGeneratingLesson(false);
    }
  };

  const handleGenerateImages = async () => {
    if (!lessonData) return;

    setIsGeneratingStoryImages(true);
    handleCloseModal();

    let successCount = 0;
    const totalImages = 3;

    try {
      const storyPrompts = [
        `Create an image representing the story theme "${lessonData.storyTitle}". Do not include any text in the image. ${imageInstructions}`,
        `Create a scene showing this moment from the story: "${lessonData.continuations[0]}". Set in the context of "${lessonData.storyTitle}". Style: narrative, dynamic, emotive, no text. ${imageInstructions}`,
        `Create an atmospheric scene combining the environment "${lessonData.environments[0]}" with this dramatic moment: "${lessonData.plotTwists[0]}". Style: atmospheric, dramatic, detailed, no text. ${imageInstructions}`,
      ];

      const updatedImages = await Promise.all(
        storyPrompts.map(async (prompt, index) => {
          try {
            const imageUrl = await generateAndProcessImage(prompt, user.uid);
            successCount++;
            return { imageUrl };
          } catch (error) {
            console.error(`Error generating image ${index + 1}:`, error);
            return { imageUrl: null };
          }
        })
      );

      setLessonData(prevData => ({
        ...prevData,
        images: updatedImages,
      }));

      if (successCount === totalImages) {
        showSnackbar('Alla bilder genererades framgångsrikt!', 'success');
      } else {
        showSnackbar(`${successCount}/${totalImages} bilder genererades framgångsrikt.`, 'warning');
      }
    } catch (error) {
      console.error('Error in story image generation process:', error);
      showSnackbar('Ett fel uppstod i bildgenereringsprocessen', 'error');
    } finally {
      setIsGeneratingStoryImages(false);
    }
  };

  const handleGenerateSpecificImage = async index => {
    setIsGeneratingStoryImages(true);
    setLoadingImageIndex(index);
    handleCloseModal();

    try {
      // Create prompts using story elements based on index
      const storyPrompts = [
        `Create an image representing the story theme "${lessonData.storyTitle}". Do not include any text in the image. ${imageInstructions}`,
        `Create a scene showing this moment from the story: "${lessonData.continuations[0]}". Set in the context of "${lessonData.storyTitle}". Style: narrative, dynamic, emotive, no text. ${imageInstructions}`,
        `Create an atmospheric scene combining the environment "${lessonData.environments[0]}" with this dramatic moment: "${lessonData.plotTwists[0]}". Style: atmospheric, dramatic, detailed, no text. ${imageInstructions}`,
      ];

      const imageUrl = await generateAndProcessImage(storyPrompts[index], user.uid);

      const updatedImages = [...lessonData.images];
      updatedImages[index] = { imageUrl };

      setLessonData({
        ...lessonData,
        images: updatedImages,
      });

      showSnackbar('Bilden har genererats!', 'success');
    } catch (error) {
      console.error('Error generating image:', error);
      showSnackbar('Ett fel uppstod när bilden skulle genereras', 'error');
    } finally {
      setIsGeneratingStoryImages(false);
      setLoadingImageIndex(null);
    }
  };

  return (
    <>
      <Box sx={styles.container}>
        <Box
          sx={{
            ...styles.innerContainer,
            width: { xs: '100%', md: '87%' },
            margin: '0 auto',
          }}
        >
          <Typography
            variant='h1'
            gutterBottom
            sx={{ fontWeight: 'bold', fontSize: '1.8em', mb: 3, mt: 5, textAlign: 'center' }}
          >
            Fortsätt berättelsen
          </Typography>
          <Box sx={{ ...styles.buttonsContainer, ...styles.additionalButtonsContainer }}>
            <Tooltip title='Dina sparade lektioner'>
              <Button
                variant='contained'
                color='primary'
                disableRipple
                onClick={() => setDrawerOpen(true)}
                startIcon={<HistoryIcon />}
                sx={{
                  padding: '8px',
                  marginRight: '0px',
                  paddingLeft: '14px',
                  paddingRight: '3px',
                  minWidth: 'auto',
                  textTransform: 'none',
                }}
              />
            </Tooltip>
          </Box>
          <InfoBox
            icon={AutoStoriesIcon}
            title='Powerpoint Instructions'
            instructions={[
              'Generera en kort berättelse med förslag på fortsättningar för eleverna',
              'Lägg till fantasieggande AI-genererade bilder',
              'Skriv ut lektionen',
            ]}
            theme={theme}
            styles={styles}
          />
          <LessonDrawer
            userId={user.uid}
            lessonType='SentenceFinisherLesson.js'
            theme={theme}
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}
            lessonIcon={AutoStoriesIcon}
            onSelectLesson={lesson => {
              setLessonData(lesson.data);

              setDrawerOpen(false);

              showSnackbar(`Lektionen har laddats in.`, 'success');
              setTimeout(() => {
                lessonTitleRef.current?.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start', // This ensures it scrolls to the top of the element
                });
              }, 100);
            }}
          />

          <ThemeSelectorFlow
            value={lessonTheme}
            onChange={e => setLessonTheme(e.target.value)}
            customTheme={customTheme}
            onCustomThemeChange={e => setCustomTheme(e.target.value)}
            onPredefinedThemeChange={e => setPredefinedTheme(e.target.value)}
            onYoutubeTranscriptChange={transcriptContent => {
              Logger.log('onYoutubeTranscriptChange körs med: ', transcriptContent);
              transcriptRef.current = transcriptContent;
            }}
            onFileUpload={content => {
              Logger.log('onFileUpload körs med: ', content);
              uploadedFileRef.current = content;
            }}
            onWikipediaContentChange={content => {
              Logger.log('Parent: Wikipedia content received', content.substring(0, 100));
              wikipediaContentRef.current = content;
            }}
            onRandomizedContentChange={content => {
              Logger.log('randomizedContentRef', content.substring(0, 100));
              randomizedContentRef.current = content;
            }}
            onCoursePlanContentChange={content => {
              Logger.log('onCoursePlanChange', content.substring(0, 100));
              coursePlanContentRef.current = content;
            }}
            lessonType='SentenceFinisherLesson.js'
            user={user}
            theme={theme}
            styles={styles}
            onLessonSelect={lesson => {
              Logger.log('onLessonSelect körs');
              setLessons(lesson.data);
              setTimeout(() => {
                lessonTitleRef.current?.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start', // This ensures it scrolls to the top of the element
                });
              }, 100);
              showSnackbar('Lektionen har laddats in.', 'success');
            }}
            additionalThemes={additionalThemes}
          />
          <Box sx={styles.headlineLessonBox}>
            <Typography variant='h6' sx={styles.headlineLessonBoxTitle}>
              2. Generera lektionen
            </Typography>
          </Box>

          <Box sx={{ ...styles.freshBox, padding: '30px' }}>
            <LoadingBar
              color={theme.palette.primary.main} // Use your theme color
              ref={loadingBarRef}
              shadow={true}
            />

            <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500', mb: 0 }}>
              Årskurs:
            </Typography>
            <Box sx={{ mt: 1, mb: 3 }}>
              <GradeSelector grade={grade} setGrade={setGrade} />
            </Box>

            <Typography variant='subtitle1' sx={{ fontWeight: '500', mt: 0 }}>
              Språk:
            </Typography>
            <Box sx={{ mt: 1, mb: 3 }}>
              <LanguageSelector language={language} setLanguage={setLanguage} />
            </Box>
            <Box>
              <Button
                variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                color='primary'
                onClick={handleGenerateLesson}
                disabled={isGeneratingLesson}
                sx={{ mt: 2 }}
                startIcon={
                  !isGeneratingLesson ? (
                    <GenerateIcon style={{ color: '#fff' }} />
                  ) : (
                    <CircularProgress size={16} />
                  )
                }
              >
                {isGeneratingLesson ? 'Genererar...' : 'Generera lektion'}
              </Button>
            </Box>
            {isGeneratingLesson && (
              <Box sx={{ mt: 6 }}>
                <ProgressBar progress={progress} label='Genererar lektion...' />
              </Box>
            )}
          </Box>

          <Box sx={{ mt: 4 }}>
            {lessonData && (
              <>
                <Box ref={lessonTitleRef} sx={{ ...styles.headlineLessonBox, mt: 10 }}>
                  <Typography variant='h6' sx={styles.headlineLessonBoxTitle}>
                    3. Din lektion
                  </Typography>
                </Box>
                <Box sx={{ ...styles.freshBox, padding: '30px' }}>
                  <Box sx={styles.lessonControlPanel}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        px: 1,
                      }}
                    >
                      <Box>
                        <Button
                          variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                          color='primary'
                          onClick={() => handleImageButtonClick(null)}
                          disabled={isGeneratingStoryImages}
                          startIcon={
                            !isGeneratingStoryImages ? (
                              <ImageIcon style={{ color: '#fff' }} />
                            ) : (
                              <CircularProgress size={16} />
                            )
                          }
                        >
                          {isGeneratingStoryImages ? 'Genererar...' : 'Generera alla bilder'}
                        </Button>
                      </Box>
                      <Box>
                        <Button
                          variant='print'
                          startIcon={<PrintIcon />}
                          sx={{ ml: 2 }}
                          onClick={() => handlePrintSentenceFinisher(lessonData, theme)}
                        >
                          Skriv ut
                        </Button>

                        <Button
                          variant='print'
                          startIcon={<SaveAsIcon />}
                          sx={{ ml: 2 }}
                          onClick={handleSaveLessonClick}
                        >
                          Spara i lektionsbank
                        </Button>
                      </Box>
                    </Box>
                  </Box>

                  <SaveLessonDialog
                    open={saveDialogOpen}
                    onClose={() => setSaveDialogOpen(false)}
                    onSave={handleSaveWithComment}
                    theme={theme}
                    lessonObject={lessonData}
                    lessonName={lessonData.storyTitle}
                  />

                  <ImageGenerationModal
                    open={showImageModal}
                    onClose={handleCloseModal}
                    onGenerate={handleGenerateWithInstructions}
                    isGenerating={isGeneratingStoryImages}
                    instructions={imageInstructions}
                    setInstructions={setImageInstructions}
                    generationType={imageGenerationType}
                    theme={theme}
                  />

                  <Box sx={{ mt: 7 }}>
                    {/* Title */}
                    {isEditingTitle ? (
                      <TextField
                        value={lessonData.storyTitle}
                        onChange={e =>
                          setLessonData(prev => ({ ...prev, storyTitle: e.target.value }))
                        }
                        onBlur={() => {
                          setIsEditingTitle(false);
                          showSnackbar('Titeln har uppdaterats', 'success');
                        }}
                        onKeyDown={e => {
                          if (e.key === 'Enter') {
                            setIsEditingTitle(false);
                            showSnackbar('Titeln har uppdaterats', 'success');
                          }
                        }}
                        autoFocus
                        fullWidth
                        variant='standard'
                        sx={{
                          backgroundColor: 'transparent',
                          '& .MuiInputBase-input': {
                            textAlign: 'center',
                            fontWeight: 'bold',
                            fontSize: '2.6em',
                            marginBottom: '1.2rem',
                            marginTop: '1.5rem',
                          },
                        }}
                        InputProps={{
                          disableUnderline: true,
                        }}
                      />
                    ) : (
                      <Box
                        sx={{
                          position: 'relative',
                          display: 'inline-block',
                          margin: '0 auto',
                          width: '100%',
                          mb: 3,
                          mt: 5,
                          ':hover .edit-icon': {
                            opacity: 1,
                          },
                        }}
                      >
                        <Typography
                          variant='h1'
                          sx={{
                            fontWeight: 'bold',
                            fontSize: '2.6em',

                            textAlign: 'center',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 2,
                          }}
                        >
                          {lessonData.storyTitle || ' '}
                          <Box
                            component='span'
                            sx={{
                              opacity: 0,
                              transition: 'opacity 0.2s',
                              display: 'inline-flex',
                              gap: 0.5,
                            }}
                            className='edit-icon'
                          >
                            <Tooltip title='Redigera' placement='top'>
                              <IconButton size='small' onClick={() => setIsEditingTitle(true)}>
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Typography>
                      </Box>
                    )}

                    {/* Story */}
                    <Box
                      sx={{
                        backgroundColor: theme =>
                          theme.palette.mode === 'dark' ? '#15202b' : '#e0e0e029',
                        borderRadius: '10px',
                        padding: '20px',
                        mb: 4,
                        mt: 4,
                      }}
                    >
                      <Box
                        sx={{
                          '&:hover .controls': {
                            opacity: 1,
                          },
                          display: 'flex',
                          alignItems: 'flex-start',
                          gap: 1,
                        }}
                      >
                        {isEditingStory ? (
                          <Box sx={{ display: 'flex', alignItems: 'flex-start', width: '100%' }}>
                            <TextField
                              fullWidth
                              multiline
                              rows={10}
                              value={lessonData.story}
                              onChange={e =>
                                setLessonData(prev => ({ ...prev, story: e.target.value }))
                              }
                              onBlur={() => {
                                setIsEditingStory(false);
                                showSnackbar('Berättelsen har uppdaterats', 'success');
                              }}
                              autoFocus
                              sx={{
                                ...styles.editingTextField,
                                '& .MuiInputBase-input': {
                                  lineHeight: 1.8,
                                },
                              }}
                            />
                            <Tooltip title='Spara ändringar' placement='top'>
                              <IconButton
                                onClick={() => {
                                  setIsEditingStory(false);
                                  showSnackbar('Berättelsen har uppdaterats', 'success');
                                }}
                              >
                                <SaveAsIcon fontSize='small' />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        ) : (
                          <Typography
                            variant='body1'
                            sx={{
                              lineHeight: 1.8,
                              flex: 1,
                              display: 'flex',
                              alignItems: 'flex-start',
                              gap: 1,
                              whiteSpace: 'pre-wrap',
                            }}
                          >
                            {lessonData.story || ' '}
                            <Box
                              component='span'
                              className='controls'
                              sx={{
                                opacity: 0,
                                transition: 'opacity 0.2s',
                                display: 'inline-flex',
                                gap: 0.5,
                                ml: 1,
                                mt: 0.5,
                              }}
                            >
                              <Tooltip title='Redigera' placement='top'>
                                <IconButton size='small' onClick={() => setIsEditingStory(true)}>
                                  <EditIcon fontSize='small' />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </Typography>
                        )}
                      </Box>
                    </Box>

                    {/* Images Grid */}
                    <Grid container spacing={2} sx={{ mb: 4 }}>
                      {lessonData.images.map((image, index) => (
                        <Grid item xs={12} md={4} key={index}>
                          <HoverableImage
                            imageUrl={image.imageUrl}
                            isLoading={isGeneratingStoryImages}
                            loadingIndex={loadingImageIndex}
                            currentIndex={index}
                            onGenerateClick={() => {
                              setSelectedImageIndex(index);
                              setShowImageModal(true);
                            }}
                            userId={user.uid}
                            onImageUpdate={newImageUrl => {
                              const updatedImages = [...lessonData.images];
                              updatedImages[index] = { imageUrl: newImageUrl };
                              setLessonData(prev => ({
                                ...prev,
                                images: updatedImages,
                              }));
                            }}
                            height='240px'
                            theme={theme}
                            showSnackbar={showSnackbar}
                          />
                        </Grid>
                      ))}
                    </Grid>

                    {lessonData?.continuations &&
                      renderEditableSection('continuations', 'continuationTitle', 'continuations')}
                    {lessonData?.plotTwists &&
                      renderEditableSection('plotTwists', 'plotTwistTitle', 'plotTwists')}
                    {lessonData?.environments &&
                      renderEditableSection('environments', 'environmentTitle', 'environments')}
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SentenceFinisherLesson;
