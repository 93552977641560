import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  Divider,
  CircularProgress,
  FormControl,
  IconButton,
  Tooltip,
  Avatar,
  Grid,
  TextField,
} from '@mui/material';

import GenerateIcon from '@mui/icons-material/AutoAwesome';
import ImageIcon from '@mui/icons-material/Image';
import PrintIcon from '@mui/icons-material/Print';
import { callApi } from './api';
import LoadingBar from 'react-top-loading-bar';
import LanguageSelector from './LanguageSelector';
import InfoBox from './InfoBox';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import SaveIcon from '@mui/icons-material/Save';
import { GiBookshelf } from 'react-icons/gi';
import { handlePrintReadingComp } from './printFunctions';
import CreateIcon from '@mui/icons-material/Create';
import { generateAndProcessImage } from './functions';
import { useSnackbar } from './SnackbarHandler';
import ProgressBar from './ProgressBar';
import HoverableImage from './HoverableImage';
import { ThemeSelector } from './ThemeSelector';
import ImageGenerationModal from './ImageGenerationModal';
import GradeSelector, { gradeInstructions } from './GradeSelector';
import Logger from './Logger';
import { useTheme } from '@mui/material/styles';
import { getStyles } from '../index';
import { trackLesson } from './functions';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import { FourSquare } from 'react-loading-indicators';

import LessonDrawer from './LessonDrawer';
import SaveLessonDialog from './SaveLessonDialog';

import HistoryIcon from '@mui/icons-material/History';

import { ThemeSelectorFlow } from './ThemeSelectorFlow';

import {
  Science as ScienceIcon,
  Castle as CastleIcon,
  Psychology as PsychologyIcon,
  Pets as PetsIcon,
  Public as PublicIcon,
  EmojiEvents as EmojiEventsIcon,
  Museum as MuseumIcon,
  Nature as NatureIcon,
} from '@mui/icons-material';
import MenuBookIcon from '@mui/icons-material/MenuBook';

const CreativeReadingCompLesson = ({ user }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);

  const [language, setLanguage] = useState('svenska');
  const [titleText, setTitleText] = useState('');
  const [grade, setGrade] = useState('åk6');
  const [progress, setProgress] = useState(0);
  const [isGeneratingLesson, setIsGeneratingLesson] = useState(false);
  const [isGeneratingImages, setIsGeneratingImages] = useState(false);
  const [editorContent, setEditorContent] = useState('');
  const [customTheme, setCustomTheme] = useState('');
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageInstructions, setImageInstructions] = useState('');
  const [openAiInstructions, setOpenAiInstructions] = useState('');
  const editorRef = useRef(null);
  const { showSnackbar } = useSnackbar();
  const theme = useTheme();
  const styles = getStyles(theme);
  const [lessonData, setLessonData] = useState(null);

  const [editingSections, setEditingSections] = useState({});
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isEditingContent, setIsEditingContent] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [loadingImageIndex, setLoadingImageIndex] = useState(null);

  const [lessonTheme, setLessonTheme] = useState('generateWithAi');
  const [predefinedTheme, setPredefinedTheme] = useState(null);
  const lessonTitleRef = useRef(null); // Add this ref
  const transcriptRef = useRef('');
  const uploadedFileRef = useRef('');

  const wikipediaContentRef = useRef('');
  const randomizedContentRef = useRef('');
  const coursePlanContentRef = useRef('');

  const additionalThemes = [
    { type: 'subheader', label: 'Teman som lämpar sig extra bra för denna lektionstyp:' },
    {
      value: 'selma',
      label: 'Selma Lagerlöf',
      content: 'Gör en lektion om selma lagerlöf',
      Icon: MenuBookIcon,
    },
    {
      value: 'dronten',
      label: 'Dronten',
      content: 'Gör en lektion om den utdöda arten dronten',
      Icon: PetsIcon,
    },
    {
      value: 'vikingar',
      label: 'Vikingar',
      content: 'Gör en lektion om vikingatiden',
      Icon: CastleIcon,
    },
    {
      value: 'rymden',
      label: 'Rymden',
      content: 'Gör en lektion om vårt solsystem',
      Icon: PublicIcon,
    },
    {
      value: 'dinosaurier',
      label: 'Dinosaurier',
      content: 'Gör en lektion om olika dinosaurier',
      Icon: NatureIcon,
    },
    {
      value: 'nobel',
      label: 'Nobelpriset',
      content: 'Gör en lektion om nobelpriset och Alfred Nobel',
      Icon: EmojiEventsIcon,
    },
    {
      value: 'uppfinnare',
      label: 'Svenska uppfinnare',
      content: 'Gör en lektion om kända svenska uppfinnare',
      Icon: ScienceIcon,
    },
    {
      value: 'psychology',
      label: 'Känslor',
      content: 'Gör en lektion om olika känslor',
      Icon: PsychologyIcon,
    },
    {
      value: 'konst',
      label: 'Svensk konst',
      content: 'Gör en lektion om svensk konsthistoria',
      Icon: MuseumIcon,
    },
  ];

  useEffect(() => {
    Logger.log('lessonTheme: ', lessonTheme);
  }, [lessonTheme]);

  useEffect(() => {
    Logger.log('predefinedTheme: ', predefinedTheme);
  }, [predefinedTheme]);

  useEffect(() => {
    Logger.log('customTheme: ', customTheme);
  }, [customTheme]);

  useEffect(() => {
    Logger.log('youtubeTranscript: ', transcriptRef.current);
  }, [transcriptRef.current]);

  useEffect(() => {
    Logger.log('wikipediaContentRef: ', wikipediaContentRef.current);
  }, [wikipediaContentRef.current]);
  useEffect(() => {
    Logger.log('uploadedFileRef: ', uploadedFileRef.current);
  }, [uploadedFileRef.current]);

  useEffect(() => {
    Logger.log('randomizedContentRef: ', randomizedContentRef.current);
  }, [randomizedContentRef.current]);

  useEffect(() => {
    Logger.log('coursePlanContentRef: ', coursePlanContentRef.current);
  }, [coursePlanContentRef.current]);

  const handleImageUpdate = (newImageUrl, index) => {
    setLessonData(prevData => {
      const newImages = [...prevData.images];
      newImages[index] = { imageUrl: newImageUrl };
      return {
        ...prevData,
        images: newImages,
      };
    });
    showSnackbar('Bilden har uppdaterats', 'success');
  };

  const handleSaveLessonClick = () => {
    setSaveDialogOpen(true);
  };

  const handleSaveWithComment = async (comment, name, coverImage, tags) => {
    Logger.log(JSON.stringify(lessonData, null, 2));
    await trackLesson(
      user.uid,
      'CreativeReadingCompLesson.js',
      lessonData,
      null,
      true,
      comment,
      name,
      coverImage,
      tags
    );
    setDrawerOpen(true);
    showSnackbar('Lektionen sparad i din lektionsbank!', 'success');
  };

  const simulateProgress = (startProgress, endProgress, duration) => {
    const stepSize = 0.5;
    const steps = (endProgress - startProgress) / stepSize;
    const stepDuration = duration / steps;
    let currentProgress = startProgress;

    const intervalId = setInterval(() => {
      currentProgress += stepSize;
      if (currentProgress >= endProgress) {
        clearInterval(intervalId);
      } else {
        setProgress(currentProgress);
      }
    }, stepDuration);

    return () => clearInterval(intervalId);
  };

  const handleGenerateLesson = async () => {
    setProgress(0);
    setLessonData(null);
    let stopProgressSimulation = null;
    setIsGeneratingLesson(true);
    const gradeInstructionsText = gradeInstructions[grade];

    try {
      let themeToUse;

      if (lessonTheme === 'youtube') {
        themeToUse = transcriptRef.current;
      } else if (lessonTheme === 'customTheme') {
        themeToUse = customTheme;
      } else if (lessonTheme === 'predefined') {
        themeToUse = predefinedTheme;
      } else if (lessonTheme === 'fileUpload') {
        themeToUse = uploadedFileRef.current;
      } else if (lessonTheme === 'wikipedia') {
        themeToUse = wikipediaContentRef.current;
      } else if (lessonTheme === 'randomizer') {
        themeToUse = randomizedContentRef.current;
      } else {
        //
        themeToUse = coursePlanContentRef.current;
      }

      Logger.log('themeToUse: ', themeToUse);

      stopProgressSimulation = simulateProgress(0, 90, 15000, setProgress);

      let response = await callApi(
        [
          {
            role: 'system',
            content: `You are an expert teacher creating engaging reading comprehension lessons 
          in ${language}. Follow the exact JSON structure provided in the prompt.`,
          },
          {
            role: 'user',
            content: `Generera en ofullständig berättelse uppdelad i tydliga stycken med kreativa uppgifter. Texten ska vara skriven på ${language}.
      
              Svara ALLTID i följande JSON-format:
              {
                "title": "Lektionens titel",
                "theme": "Berättelsetema",
                "content": "Den ofullständiga berättelsen här...",
                "exercises": [
                  {
                    "type": "comprehension",
                    "title": "Förstå berättelsen",
                    "content": ["Förståelsefråga 1", "Förståelsefråga 2", "Förståelsefråga 3"]
                  },
                  {
                    "type": "suggestions",
                    "title": "Möjliga fortsättningar",
                    "content": ["Förslag 1", "Förslag 2", "Förslag 3"]
                  },
                  {
                    "type": "writing",
                    "title": "Skriv din egen fortsättning",
                    "content":  ["Skrivuppgift 1", "Skrivuppgift 2", "Skrivuppgift 3"]
                  }
                ],
                "teacherNotes": "Noter för läraren här..."
              }
              Theme: "${themeToUse}".
              ${openAiInstructions}.
              ${gradeInstructionsText}`,
          },
        ],
        6000,
        true,
        user.uid
      );

      if (stopProgressSimulation) {
        stopProgressSimulation();
      }

      setProgress(95);
      let messageContent = response.data.choices[0].message.content.trim();

      if (messageContent.startsWith('```json')) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith('```')) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const data = JSON.parse(messageContent);

      setLessonData({
        ...data,
        images: [{ imageUrl: null }], // Add this to initialize the images array
      });

      setProgress(100);

      setTitleText(data.title);
      await new Promise(resolve => setTimeout(resolve, 800));

      await trackLesson(user.uid, 'CreativeReadingCompLesson.js', null, {
        language: language,
        lessonTheme: lessonTheme,
      });
    } catch (error) {
      console.error('Error generating lesson:', error);
      showSnackbar('Ett fel uppstod när lektionen skulle genereras. Försök igen!', 'error');
    } finally {
      setIsGeneratingLesson(false);
    }
  };

  const handleCloseModal = () => {
    setShowImageModal(false);
    setImageInstructions('');
  };

  const handleExerciseUpdate = (index, key, value) => {
    setLessonData(prev => {
      const newExercises = [...prev.exercises];
      newExercises[index] = {
        ...newExercises[index],
        [key]: value,
      };
      return {
        ...prev,
        exercises: newExercises,
      };
    });
  };

  const handleSectionEdit = (section, index) => {
    setEditingSections(prev => ({
      ...prev,
      [`${section}-${index}`]: true,
    }));
  };

  const handleSectionSave = (section, index) => {
    setEditingSections(prev => ({
      ...prev,
      [`${section}-${index}`]: false,
    }));
    showSnackbar('Sektionen har uppdaterats', 'success');
  };

  const handleGenerateSpecificImage = async index => {
    Logger.log('handleGenerateSpecificImage index: ', index);
    setIsGeneratingImages(true);
    setLoadingImageIndex(index);
    handleCloseModal();

    try {
      const imagePrompt = `Create an image about a story with the theme: "${titleText}". ${imageInstructions}`;
      const imageUrl = await generateAndProcessImage(imagePrompt, user.uid);

      if (imageUrl) {
        setLessonData(prevData => {
          const newImages = [...prevData.images];
          newImages[index] = { imageUrl };
          return {
            ...prevData,
            images: newImages,
          };
        });
        showSnackbar('Bilden har genererats!', 'success');
      }
    } catch (error) {
      console.error('Error generating image:', error);
      showSnackbar('Ett fel uppstod när bilden skulle genereras', 'error');
    } finally {
      setIsGeneratingImages(false);
      setLoadingImageIndex(null);
    }
  };

  // Update your existing handlers
  const handleImageButtonClick = (index = 0) => {
    setSelectedImageIndex(index);
    setShowImageModal(true);
  };

  const handleGenerateWithInstructions = async () => {
    if (selectedImageIndex !== null) {
      handleCloseModal();
      await handleGenerateSpecificImage(selectedImageIndex);
    } else {
      handleCloseModal();
      await handleGenerateSpecificImage(0);
    }
  };

  return (
    <Box sx={styles.container}>
      <Box
        sx={{
          ...styles.innerContainer,
          width: { xs: '100%', md: '87%' },
          margin: '0 auto',
        }}
      >
        <Typography
          variant='h1'
          gutterBottom
          sx={{
            fontWeight: 'bold',
            fontSize: '1.8em',
            mb: 3,
            mt: 5,
            textAlign: 'center',
          }}
        >
          Kreativ läsförståelse
        </Typography>

        <Box sx={{ ...styles.buttonsContainer, ...styles.additionalButtonsContainer }}>
          <Tooltip title='Dina sparade lektioner'>
            <Button
              variant='contained'
              color='primary'
              disableRipple
              onClick={() => setDrawerOpen(true)}
              startIcon={<HistoryIcon />}
              sx={{
                padding: '8px',
                marginRight: '0px',
                paddingLeft: '14px',
                paddingRight: '3px',
                minWidth: 'auto',
                textTransform: 'none',
              }}
            />
          </Tooltip>
        </Box>

        <LessonDrawer
          userId={user.uid}
          lessonType='CreativeReadingCompLesson.js'
          theme={theme}
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          lessonIcon={CreateIcon}
          onSelectLesson={lesson => {
            setLessonData(lesson.data);
            setDrawerOpen(false);

            showSnackbar(`Lektionen har laddats in.`, 'success');
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'start', // This ensures it scrolls to the top of the element
              });
            }, 100);
          }}
        />

        <InfoBox
          icon={CreateIcon}
          title='Kreativ läsförståelse'
          instructions={[
            'Generera en ofullständig berättelse med öppet slut',
            'Eleven analyserar berättelsens uppbyggnad och karaktärer',
            'Eleven skriver egna kreativa fortsättningar på berättelsen',
          ]}
          theme={theme}
          styles={styles}
        />

        <ThemeSelectorFlow
          value={lessonTheme}
          onChange={e => setLessonTheme(e.target.value)}
          customTheme={customTheme}
          onCustomThemeChange={e => setCustomTheme(e.target.value)}
          onPredefinedThemeChange={e => setPredefinedTheme(e.target.value)}
          onYoutubeTranscriptChange={transcriptContent => {
            Logger.log('onYoutubeTranscriptChange körs med: ', transcriptContent);
            transcriptRef.current = transcriptContent;
          }}
          onFileUpload={content => {
            Logger.log('onFileUpload körs med: ', content);
            uploadedFileRef.current = content;
          }}
          onWikipediaContentChange={content => {
            Logger.log('Parent: Wikipedia content received', content.substring(0, 100));
            wikipediaContentRef.current = content;
          }}
          onRandomizedContentChange={content => {
            Logger.log('randomizedContentRef', content.substring(0, 100));
            randomizedContentRef.current = content;
          }}
          onCoursePlanContentChange={content => {
            Logger.log('onCoursePlanChange', content.substring(0, 100));
            coursePlanContentRef.current = content;
          }}
          lessonType='CreativeReadingCompLesson.js'
          user={user}
          theme={theme}
          styles={styles}
          onLessonSelect={lesson => {
            Logger.log('onLessonSelect körs');
            setLessonData(lesson.data);
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'start', // This ensures it scrolls to the top of the element
              });
            }, 100);
            showSnackbar('Lektionen har laddats in.', 'success');
          }}
          additionalThemes={additionalThemes}
        />
        <Box sx={styles.headlineLessonBox}>
          <Typography variant='h6' sx={styles.headlineLessonBoxTitle}>
            2. Generera lektionen
          </Typography>
        </Box>

        <Box sx={{ ...styles.freshBox, padding: '30px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant='subtitle1' sx={{ fontWeight: '500', mb: 1, mt: 0 }}>
                Årskurs:
              </Typography>
              <Box sx={{ mt: 0.5, mb: 3 }}>
                <GradeSelector grade={grade} setGrade={setGrade} />
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant='subtitle1' sx={{ fontWeight: '500', mt: 0 }}>
                Språk:
              </Typography>
              <Box sx={{ mt: 1, mb: 3 }}>
                <LanguageSelector language={language} setLanguage={setLanguage} />
              </Box>
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant='subtitle1' sx={{ fontWeight: '500' }}>
                Specifika instruktioner:
              </Typography>
              <FormControl fullWidth margin='normal' sx={{ mb: 3, mt: 1 }}>
                <TextField
                  value={openAiInstructions}
                  onChange={e => setOpenAiInstructions(e.target.value)}
                  placeholder='T.ex. Skriv en spännande berättelse som utspelar sig i rymden.'
                  variant='outlined'
                />
              </FormControl>
            </Grid>
          </Grid>

          <Button
            variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
            color='primary'
            onClick={handleGenerateLesson}
            disabled={isGeneratingLesson}
            sx={{ mt: 4 }}
            startIcon={
              !isGeneratingLesson ? (
                <GenerateIcon style={{ color: '#fff' }} />
              ) : (
                <CircularProgress size={16} />
              )
            }
          >
            {isGeneratingLesson ? 'Genererar...' : 'Generera lektion'}
          </Button>

          {isGeneratingLesson && (
            <Box sx={{ mt: 6 }}>
              <ProgressBar progress={progress} label='Genererar lektion...' />
            </Box>
          )}
        </Box>
        <Box sx={{ mt: 7 }}>
          {lessonData && (
            <>
              <SaveLessonDialog
                open={saveDialogOpen}
                onClose={() => setSaveDialogOpen(false)}
                onSave={handleSaveWithComment}
                theme={theme}
                lessonObject={lessonData}
                lessonName={lessonData?.title || ''}
              />

              <Box ref={lessonTitleRef} sx={{ ...styles.headlineLessonBox, mt: 10 }}>
                <Typography variant='h6' sx={styles.headlineLessonBoxTitle}>
                  3. Din lektion
                </Typography>
              </Box>
              <Box sx={{ ...styles.freshBox, padding: '30px' }}>
                <Box sx={styles.lessonControlPanel}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      px: 1,
                    }}
                  >
                    <Box></Box>
                    <Box>
                      <Button
                        variant='print'
                        startIcon={<PrintIcon />}
                        sx={{ ml: 2 }}
                        onClick={() => handlePrintReadingComp(lessonData, theme)}
                      >
                        Skriv ut
                      </Button>
                      <Button
                        variant='print'
                        startIcon={<SaveAsIcon />}
                        sx={{ ml: 2 }}
                        onClick={handleSaveLessonClick}
                      >
                        Spara i lektionsbank
                      </Button>
                      <ImageGenerationModal
                        open={showImageModal}
                        onClose={handleCloseModal}
                        onGenerate={handleGenerateWithInstructions}
                        isGenerating={isGeneratingImages}
                        instructions={imageInstructions}
                        setInstructions={setImageInstructions}
                        theme={theme}
                      />
                    </Box>
                  </Box>
                </Box>

                <Box sx={{ mt: 4 }}>
                  {/* Title Section */}
                  {isEditingTitle ? (
                    <TextField
                      value={lessonData.title}
                      onChange={e => setLessonData(prev => ({ ...prev, title: e.target.value }))}
                      onBlur={() => {
                        setIsEditingTitle(false);
                        showSnackbar('Titeln har uppdaterats', 'success');
                      }}
                      onKeyDown={e => {
                        if (e.key === 'Enter') {
                          setIsEditingTitle(false);
                          showSnackbar('Titeln har uppdaterats', 'success');
                        }
                      }}
                      autoFocus
                      fullWidth
                      variant='standard'
                      sx={{
                        backgroundColor: 'transparent',
                        '& .MuiInputBase-input': {
                          textAlign: 'center',
                          fontWeight: 'bold',
                          fontSize: '2.6em',
                          marginBottom: '1.2rem',
                          marginTop: '1.5rem',
                        },
                      }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        position: 'relative',
                        display: 'inline-block',
                        margin: '0 auto',
                        width: '100%',
                        mb: 3,
                        mt: 5,
                        ':hover .edit-icon': {
                          opacity: 1,
                        },
                      }}
                    >
                      <Typography
                        variant='h1'
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '2.6em',
                          textAlign: 'center',
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: 2,
                        }}
                      >
                        {lessonData.title || ' '}
                        <Box
                          component='span'
                          sx={{
                            opacity: 0,
                            transition: 'opacity 0.2s',
                            display: 'inline-flex',
                            gap: 0.5,
                          }}
                          className='edit-icon'
                        >
                          <Tooltip title='Redigera' placement='top'>
                            <IconButton size='small' onClick={() => setIsEditingTitle(true)}>
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Typography>
                    </Box>
                  )}

                  {/* Content Section */}
                  <Box
                    sx={{
                      backgroundColor: theme =>
                        theme.palette.mode === 'dark' ? '#15202b' : '#e0e0e029',
                      borderRadius: '10px',
                      padding: '20px',
                      mb: 4,
                      mt: 4,
                    }}
                  >
                    <Box
                      sx={{
                        '&:hover .controls': {
                          opacity: 1,
                        },
                        display: 'flex',
                        alignItems: 'flex-start',
                        gap: 1,
                      }}
                    >
                      {isEditingContent ? (
                        <Box sx={{ display: 'flex', alignItems: 'flex-start', width: '100%' }}>
                          <TextField
                            fullWidth
                            multiline
                            rows={17}
                            value={lessonData.content}
                            onChange={e =>
                              setLessonData(prev => ({ ...prev, content: e.target.value }))
                            }
                            onBlur={() => {
                              setIsEditingContent(false);
                              showSnackbar('Innehållet har uppdaterats', 'success');
                            }}
                            autoFocus
                            sx={{
                              '& .MuiInputBase-input': {
                                lineHeight: 1.8,
                              },
                              ...styles.editingTextField,
                            }}
                          />
                          <Tooltip title='Spara ändringar' placement='top'>
                            <IconButton
                              onClick={() => {
                                setIsEditingContent(false);
                                showSnackbar('Innehållet har uppdaterats', 'success');
                              }}
                            >
                              <SaveAsIcon fontSize='small' />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      ) : (
                        <Typography
                          variant='body1'
                          sx={{
                            lineHeight: 1.8,
                            flex: 1,
                            display: 'flex',
                            alignItems: 'flex-start',
                            gap: 1,
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          {lessonData.content || ' '}
                          <Box
                            component='span'
                            className='controls'
                            sx={{
                              opacity: 0,
                              transition: 'opacity 0.2s',
                              display: 'inline-flex',
                              gap: 0.5,
                              ml: 1,
                              mt: 0.5,
                            }}
                          >
                            <Tooltip title='Redigera' placement='top'>
                              <IconButton size='small' onClick={() => setIsEditingContent(true)}>
                                <EditIcon fontSize='small' />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Typography>
                      )}
                    </Box>
                  </Box>

                  {/* Images Grid */}
                  <Grid container spacing={2} sx={{ mb: 4, justifyContent: 'center' }}>
                    {lessonData.images?.map((image, index) => (
                      <Grid item xs={12} md={6} key={index}>
                        <HoverableImage
                          imageUrl={image.imageUrl}
                          isLoading={isGeneratingImages}
                          loadingIndex={loadingImageIndex}
                          currentIndex={index}
                          onGenerateClick={() => handleImageButtonClick(index)}
                          userId={user.uid}
                          onImageUpdate={newImageUrl => handleImageUpdate(newImageUrl, index)}
                          height='400px'
                          theme={theme}
                          showSnackbar={showSnackbar}
                        />
                      </Grid>
                    ))}
                  </Grid>

                  {/* Exercises Sections */}
                  {lessonData.exercises.map((exercise, index) => (
                    <Box key={index}>
                      <Box
                        sx={{
                          position: 'relative',
                          marginTop: 7,
                          '&:hover': {
                            '& .section-controls': {
                              opacity: 1,
                              visibility: 'visible',
                            },
                          },
                        }}
                      >
                        <Box
                          className='section-controls'
                          sx={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            opacity: 0,
                            visibility: 'hidden',
                            transition: 'all 0.2s ease',
                            display: 'flex',
                            gap: 1,
                            zIndex: 1,
                          }}
                        >
                          <Tooltip
                            title={
                              editingSections[`${exercise.type}-${index}`]
                                ? 'Spara ändringar'
                                : 'Redigera sektion'
                            }
                            placement='top'
                          >
                            <IconButton
                              size='small'
                              onClick={() => {
                                if (editingSections[`${exercise.type}-${index}`]) {
                                  handleSectionSave(exercise.type, index);
                                } else {
                                  handleSectionEdit(exercise.type, index);
                                }
                              }}
                            >
                              {editingSections[`${exercise.type}-${index}`] ? (
                                <SaveAsIcon fontSize='small' />
                              ) : (
                                <EditIcon fontSize='small' />
                              )}
                            </IconButton>
                          </Tooltip>
                          <Tooltip title='Ta bort sektion' placement='top'>
                            <IconButton
                              size='small'
                              onClick={() => {
                                const updatedExercises = lessonData.exercises.filter(
                                  (_, i) => i !== index
                                );
                                setLessonData(prev => ({
                                  ...prev,
                                  exercises: updatedExercises,
                                }));
                                showSnackbar('Sektionen har tagits bort', 'success');
                              }}
                            >
                              <DeleteOutlineIcon fontSize='small' />
                            </IconButton>
                          </Tooltip>
                        </Box>

                        {editingSections[`${exercise.type}-${index}`] ? (
                          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <TextField
                              value={exercise.title}
                              onChange={e => handleExerciseUpdate(index, 'title', e.target.value)}
                              sx={{ width: '80%' }}
                              InputProps={{
                                sx: {
                                  fontSize: '1.5em',
                                  fontWeight: 'bold',
                                },
                              }}
                            />
                            <Box
                              sx={{
                                backgroundColor: theme =>
                                  theme.palette.mode === 'dark' ? '#15202b' : '#e0e0e029',
                                borderRadius: '10px',
                                padding: '20px',
                              }}
                            >
                              {exercise.content.map((item, i) => (
                                <TextField
                                  key={i}
                                  fullWidth
                                  multiline
                                  value={item}
                                  onChange={e => {
                                    const newItems = [...exercise.content];
                                    newItems[i] = e.target.value;
                                    handleExerciseUpdate(index, 'content', newItems);
                                  }}
                                  sx={{
                                    mb: 2,
                                    '& .MuiInputBase-root': {
                                      fontSize: '1rem',
                                      lineHeight: '1.5',
                                      ...styles.editingTextField['& .MuiInputBase-root'],
                                    },
                                  }}
                                />
                              ))}
                              <Button
                                variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                                color='primary'
                                startIcon={<SaveAsIcon />}
                                onClick={() => handleSectionSave(exercise.type, index)}
                                sx={{ mt: 2, textTransform: 'none' }}
                              >
                                Spara ändringar
                              </Button>
                            </Box>
                          </Box>
                        ) : (
                          <>
                            <Typography
                              variant='h6'
                              sx={{ fontWeight: 'bold', mb: 2, fontSize: '1.5em' }}
                            >
                              {exercise.title || ''}
                            </Typography>
                            <Box
                              sx={{
                                backgroundColor: theme =>
                                  theme.palette.mode === 'dark' ? '#15202b' : '#e0e0e029',
                                borderRadius: '10px',
                                padding: '20px',
                                '& li': {
                                  marginBottom: '12px',
                                  lineHeight: '25px',
                                },
                              }}
                            >
                              <ul>
                                {exercise.content.map((item, i) => (
                                  <li key={i}>
                                    <Typography variant='body1'>{item}</Typography>
                                  </li>
                                ))}
                              </ul>
                            </Box>
                          </>
                        )}
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default CreativeReadingCompLesson;
