import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    TextField,
    Button,
    Paper,
    Input,
    CircularProgress,
    LinearProgress,
    ListItem,
    List,
    ListItemIcon,
    ListItemText,
} from '@mui/material';
import { callApi } from './api';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import AnalyzeIcon from '@mui/icons-material/Analytics';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import mammoth from 'mammoth';
import { useTheme } from '@mui/material/styles';
import { getStyles } from '../index';
import { marked } from 'marked';
import ReactApexChart from 'react-apexcharts';
import WordIcon from './Icons/WordIcon';
import Logger from './Logger';

const styles = {
    buttonWrapper: {
        display: 'flex',
        gap: '10px',
        marginBottom: '20px',
    },
    paper: {
        padding: '20px',
        maxWidth: '800px',
        width: '100%',
        marginTop: '20px',
        marginBottom: '40px',
        borderRadius: '7px',
    },
    status: {
        color: 'red',
    },
    resultText: {
        whiteSpace: 'pre-wrap',
        overflowWrap: 'break-word',
    },
    counter: {
        marginTop: '10px',
        fontSize: '16px',
        color: '#777',
    },
};

marked.setOptions({
    gfm: true,
    breaks: true,
});

const getColor = probability => {
    if (probability < 30) return '#34D399'; // Green
    if (probability < 60) return '#FBBF24'; // Yellow
    return '#EF4444'; // Red
};

const getLabel = probability => {
    if (probability >= 80) return 'Högst sannolikt skrivet av en AI';
    if (probability >= 60) return 'Sannolikt skrivet av en AI';
    if (probability >= 40) return 'Osäkert om skrivet av AI eller människa';
    if (probability >= 20) return 'Sannolikt skrivet av en människa';
    return 'Högst sannolikt skrivet av en människa';
};

const AIProbabilityGraph = ({ probability }) => {
    const color = getColor(probability);

    const chartOptions = {
        chart: {
            type: 'radialBar',
            offsetY: -20,
        },
        plotOptions: {
            radialBar: {
                startAngle: -90,
                endAngle: 90,
                track: {
                    background: '#e7e7e7',
                    strokeWidth: '97%',
                },
                dataLabels: {
                    name: {
                        show: true,
                        fontSize: '16px',
                        offsetY: 50,
                    },
                    value: {
                        show: true,
                        fontSize: '24px',
                        offsetY: -30,
                        color: color,
                        fontWeight: 'bold',
                        formatter: function (val) {
                            return val + '%';
                        },
                    },
                },
            },
        },
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'dark',
                type: 'horizontal',
                shadeIntensity: 0.5,
                gradientToColors: [color],
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100],
            },
        },
        stroke: {
            lineCap: 'round',
        },
        labels: [getLabel(probability)],
        colors: [color],
    };

    return (
        <Box>
            <ReactApexChart
                options={chartOptions}
                series={[probability]}
                type='radialBar'
                height={350}
            />
        </Box>
    );
};

const DetectorView = ({ user }) => {
    const [input, setInput] = useState('');
    const [status, setStatus] = useState('');
    const [analysis, setAnalysis] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [analysisCount, setAnalysisCount] = useState(0);
    const [aiProbability, setAiProbability] = useState(null);
    const maxLength = 5000;
    const theme = useTheme();
    const styles = getStyles(theme);

    useEffect(() => {
        Logger.log('DetectorView user ', user.uid);
        if (input && input.length < 200) {
            setStatus('Texten är för kort (minst 200 tecken).');
        } else if (input && input.length > 3000) {
            setStatus('Texten är för lång (max 3000 tecken).');
        } else {
            setStatus('');
        }
    }, [input]);

    useEffect(() => {
        if (analysis) {
            setStatus('');
            incrementAnalysisCount();
        }
    }, [analysis]);

    const handleTextChange = event => {
        const newInput = event.target.value;
        if (newInput.length <= maxLength) {
            setInput(newInput);
        }
    };

    const handleFileChange = async event => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = async e => {
                const arrayBuffer = e.target.result;
                const result = await mammoth.extractRawText({ arrayBuffer });
                setInput(result.value);
            };
            reader.readAsArrayBuffer(file);
        }
    };

    const handleAnalyze = async () => {
        setStatus('');
        setAnalysis('');
        setIsLoading(true);

        if (input.length < 200 || input.length > 3000) {
            setStatus('Texten måste vara mellan 200 och 3000 tecken.');
            setIsLoading(false);
            return;
        }

        try {
            const systemMessage = `Du är en AI-detektor på svenska är redo att analysera den inskickade texten. Uppgiften är att noggrant bedöma om texten är författad av en människa eller av en artificiell intelligens. 

Använd Lingvistisk analys och statistiska mönster, Stilometrisk och stilistisk profilering, Semantisk och pragmatisk analys och Kognitiv-lingvistisk modellering.

                Var vänlig motivera ditt svar med tydliga anmärkningar och specifika karakteristika som stöder din analys. Utvärdera textens stil, syntaktiska mönster och eventuella indikationer på maskinskrift eller mänskligt ursprung.
                 Se till att ge användaren en omfattande förståelse för din bedömning. 
                 Använd markdown för att formattera omdömet.
                 Avsluta alltid ditt svar med en JSON-sträng i följande format: {"ai_probability": X}, där X är ett heltal mellan 1 och 100 som representerar sannolikheten att texten är skriven av en AI. Använd INTE markdown formatting. `;

            const response = await callApi(
                [
                    { role: 'system', content: systemMessage },
                    { role: 'user', content: input },
                ],
                undefined,
                undefined,
                user.uid
            );

            const jsonMatch = response.match(/{[\s\S]*}/);
            if (jsonMatch) {
                const jsonData = JSON.parse(jsonMatch[0]);
                setAiProbability(jsonData.ai_probability);
                setAnalysis(response.replace(jsonMatch[0], '').trim());
            } else {
                setAnalysis(response);
                setAiProbability(null); // Reset probability if not found
            }
        } catch (error) {
            console.error('Error while analyzing text:', error);
            setStatus('Ett fel inträffade vid analys av texten');
        } finally {
            setIsLoading(false);
        }
    };

    const incrementAnalysisCount = () => {
        setAnalysisCount(count => count + 1);
    };

    const remainingAnalyses = 25 - analysisCount;
    const canAnalyze =
        input.length >= 200 && input.length <= 5000 && !isLoading && remainingAnalyses > 0;

    return (
        <Box sx={styles.container}>
            <Box
                sx={{
                    ...styles.innerContainer,
                    width: { xs: '100%', md: '70%' },
                    margin: '0 auto',
                }}
            >
                <Typography variant='h4' sx={styles.header}>
                    AI Detektor
                </Typography>
                <Typography variant='body1' sx={{ ...styles.p, mb: 3 }}>
                    AI-detektorn analyserar den inskickade texten för att avgöra om den är skriven
                    av en människa eller av en AI. Den ger ett detaljerat svar som förklarar
                    bedömningen baserat på stilistiska och syntaktiska egenskaper.
                </Typography>

                <TextField
                    variant='outlined'
                    multiline
                    rows={14}
                    placeholder='Skriv eller klistra in din text här (minst 200, max 5000 tecken)'
                    value={input}
                    onChange={handleTextChange}
                    sx={{ ...styles.textarea, ...styles.whiteBg }}
                    error={!!status}
                    helperText={status}
                />

                <Box display='flex' justifyContent='flex-end' mt={1}>
                    <Typography variant='caption'>
                        {input.length}/{maxLength}
                    </Typography>
                </Box>

                <div style={{ ...styles.buttonWrapper, marginTop: '20px', marginBotton: '20px' }}>
                    <Button
                        variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                        color='primary'
                        onClick={handleAnalyze}
                        startIcon={isLoading ? <CircularProgress size={17} /> : <AnalyzeIcon />}
                        disabled={!canAnalyze}
                        sx={{ mb: 3 }}
                    >
                        {isLoading ? 'Analyserar...' : 'Analysera text'}
                    </Button>
                    <label htmlFor='file-upload'>
                        <Input
                            id='file-upload'
                            type='file'
                            sx={{ display: 'none' }}
                            onChange={handleFileChange}
                        />
                        <Button variant='outlined' component='span' startIcon={<WordIcon />}>
                            Ladda upp Word-fil
                        </Button>
                    </label>
                </div>

                {!isLoading && analysis && (
                    <Paper
                        elevation={3}
                        sx={{
                            ...styles.paper,
                            marginTop: '20px',
                            padding: '20px',
                            borderRadius: '15px',
                        }}
                    >
                        {aiProbability !== null && (
                            <AIProbabilityGraph probability={aiProbability} />
                        )}

                        <Typography
                            component='div'
                            sx={{
                                '& h2, & h3': { margin: '16px 0 8px', padding: 0 },
                                '& ul, & ol': { paddingLeft: '20px', marginBottom: '16px' },
                                '& li': { marginBottom: '8px' },
                                '& p': { marginBottom: '16px' },
                            }}
                        >
                            <span dangerouslySetInnerHTML={{ __html: marked(analysis) }} />
                        </Typography>
                    </Paper>
                )}

                <Box
                    sx={{
                        ...styles.newBox,
                        width: '100%',
                        marginTop: '20px',
                        maxWidth: '800px',
                        marginBottom: '50px',
                        background: theme.palette.mode === 'dark' ? '#212c36' : 'white',
                        borderRadius: '6px',
                        padding: '40px',
                        color: theme.palette.mode === 'dark' ? 'white' : 'black',
                    }}
                >
                    <Typography
                        variant='h6'
                        sx={{
                            color: theme.palette.mode === 'dark' ? 'white' : 'black',
                            marginBottom: '20px',
                        }}
                    >
                        Vad vår svenska AI-Detektor analyserar:
                    </Typography>
                    <List sx={{ backgroundColor: 'transparent' }}>
                        <ListItem>
                            <ListItemIcon>
                                <CheckCircleIcon
                                    style={{
                                        color:
                                            theme.palette.mode === 'dark' ? '#45bd45' : '#45bd45',
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary='Lingvistisk analys och statistiska mönster'
                                secondary='Detektorn utför en djupgående lingvistisk analys som inkluderar lexikal diversitet, syntaktisk komplexitet och n-gram frekvensanalyser. Den använder avancerade statistiska modeller för att identifiera subtila mönster i språkanvändning som kan skilja mellan mänskligt och AI-genererat innehåll.'
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CheckCircleIcon
                                    style={{
                                        color:
                                            theme.palette.mode === 'dark' ? '#45bd45' : '#45bd45',
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary='Stilometrisk och stilistisk profilering'
                                secondary='Genom att tillämpa stilometriska tekniker och maskininlärningsalgoritmer, skapar detektorn en stilistisk profil av texten. Den analyserar faktorer som meningslängd, ordval, användning av funktionsord och stilfigurer för att särskilja karakteristiska drag i mänskligt vs. AI-genererat skrivande.'
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CheckCircleIcon
                                    style={{
                                        color:
                                            theme.palette.mode === 'dark' ? '#45bd45' : '#45bd45',
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary='Semantisk och pragmatisk analys'
                                secondary='Detektorn använder avancerade Natural Language Processing (NLP) tekniker för att utföra en djup semantisk analys. Detta inkluderar latent semantisk analys (LSA) och transformer-baserade modeller för att utvärdera textens koherens, tematiska progression och pragmatiska aspekter som kontextuell lämplighet och impliciteter.'
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CheckCircleIcon
                                    style={{
                                        color:
                                            theme.palette.mode === 'dark' ? '#45bd45' : '#45bd45',
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary='Kognitiv-lingvistisk modellering'
                                secondary="Genom att implementera principer från kognitiv lingvistik, analyserar detektorn hur texten reflekterar mänskliga kognitiva processer. Detta inkluderar utvärdering av konceptuell metaforanvändning, 'frame semantics' och hur väl texten följer principerna för naturlig informationsbehandling och minnesbegränsningar som är karakteristiska för mänskligt skrivande."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CheckCircleIcon
                                    style={{
                                        color:
                                            theme.palette.mode === 'dark' ? '#45bd45' : '#45bd45',
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary='Diskursanalys och retorisk struktur'
                                secondary='Detektorn tillämpar teorier inom diskursanalys och Rhetorical Structure Theory (RST) för att undersöka textens övergripande organisation. Den analyserar kohesionsmarkörer, diskurspartiklar och retoriska relationer mellan textsegment för att bedöma hur väl texten uppvisar en naturlig argumentationsstruktur och diskursiv koherens.'
                            />
                        </ListItem>
                    </List>
                </Box>
            </Box>
        </Box>
    );
};

export default DetectorView;
