import React, { useState, useRef } from 'react';
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Grid,
  FormControl,
  FormGroup,
  Checkbox,
  Select,
  MenuItem,
  Divider,
  Tooltip,
  IconButton,
  ToggleButtonGroup,
  ToggleButton,
  TextField,
  Avatar,
} from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import AddIcon from '@mui/icons-material/Add';
import InfoBox from './InfoBox';
import BoltIcon from '@mui/icons-material/Bolt';
import ImageIcon from '@mui/icons-material/Image';
import GradeSelector from './GradeSelector';
import GenerateIcon from '@mui/icons-material/AutoAwesome';

import DateRangeIcon from '@mui/icons-material/DateRange';
import { CustomFormControlLabel, trackLesson } from './functions';
import { callApi } from './api';
import { generateAndProcessImage } from './functions';
import { useSnackbar } from './SnackbarHandler';
import ImageGenerationModal from './ImageGenerationModal';
import SaveDocumentButton from './SaveDocumentButton';
import PrintIcon from '@mui/icons-material/Print';
import ProgressBar from './ProgressBar';
import Voice from './Voice';
import { ThemeSelector } from './ThemeSelector';

import { handlePrintWeeklyWords } from './printFunctions';

import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'; // For AI mode
import EditNoteIcon from '@mui/icons-material/EditNote'; // For custom mode
import { FourSquare } from 'react-loading-indicators';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import Logger from './Logger';
import LessonDrawer from './LessonDrawer';
import SaveLessonDialog from './SaveLessonDialog';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import HistoryIcon from '@mui/icons-material/History';
import HoverableImage from './HoverableImage';

const WeeklyWordsLesson = ({ theme, styles, user }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);

  const [mode, setMode] = useState('ai'); // 'ai' or 'custom'
  const [lessonData, setLessonData] = useState(null);
  const [grade, setGrade] = useState('åk6');
  const [wordCount, setWordCount] = useState(4);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isGeneratingImages, setIsGeneratingImages] = useState(false);
  const [generatedContent, setGeneratedContent] = useState('');
  const [progress, setProgress] = useState(0);
  const { showSnackbar } = useSnackbar();
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageInstructions, setImageInstructions] = useState('');
  const editorRef = useRef(null);
  const [topic, setTopic] = useState('generateWithAi');
  const [customTheme, setCustomTheme] = useState('');

  const [selectedWordIndex, setSelectedWordIndex] = useState(null);

  const [selectedPatterns, setSelectedPatterns] = useState([]);
  const [customWords, setCustomWords] = useState('');

  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isEditingSubtitle, setIsEditingSubtitle] = useState(false);
  const [titleText, setTitleText] = useState('Veckans ord');
  const [loadingImageIndex, setLoadingImageIndex] = useState(null);
  const [editingExampleId, setEditingExampleId] = useState(null);
  const [editingWordId, setEditingWordId] = useState(null);

  const handleSaveLessonClick = () => {
    setSaveDialogOpen(true);
  };

  const handleSaveWithComment = async (comment, name, coverImage, tags) => {
    Logger.log(JSON.stringify(lessonData, null, 2));
    await trackLesson(
      user.uid,
      'WeeklyWordsLesson.js',
      lessonData,
      null,
      true,
      comment,
      name,
      coverImage,
      tags
    );
    setDrawerOpen(true);
    showSnackbar('Lektionen sparad i din lektionsbank!', 'success');
  };

  const handleImageUpdate = (index, newImageUrl) => {
    const updatedWords = [...lessonData.words];
    updatedWords[index] = { ...updatedWords[index], imageUrl: newImageUrl };
    setLessonData({
      ...lessonData,
      words: updatedWords,
    });
  };

  const handleEditWord = (index, newText) => {
    setLessonData(prevData => ({
      ...prevData,
      words: prevData.words.map((word, i) => (i === index ? { ...word, word: newText } : word)),
    }));
    setEditingWordId(null);
    showSnackbar('Ordet har uppdaterats', 'success');
  };

  const handleEditExampleSentence = (index, newText) => {
    setLessonData(prevData => ({
      ...prevData,
      words: prevData.words.map((word, i) =>
        i === index ? { ...word, exampleSentence: newText } : word
      ),
    }));
    setEditingExampleId(null);
    showSnackbar('Meningen har uppdaterats', 'success');
  };

  const [isEditingStory, setIsEditingStory] = useState(false);

  const handleEditStory = newText => {
    setLessonData(prevData => ({
      ...prevData,
      story: newText,
    }));
    setIsEditingStory(false);
    showSnackbar('Berättelsen har uppdaterats', 'success');
  };

  const getCurrentWeek = () => {
    const now = new Date();
    const onejan = new Date(now.getFullYear(), 0, 1);
    const week = Math.ceil(
      ((now.getTime() - onejan.getTime()) / 86400000 + onejan.getDay() + 1) / 7
    );
    return week;
  };

  const [subtitleText, setSubtitleText] = useState(`Vecka ${getCurrentWeek()}`);

  const handleClearText = setter => {
    setter('');
  };

  const soundPatterns = [
    { id: 'sje', label: 'Sje-ljud (skjuta, sjunga)', example: 'sk, sj, stj' },
    { id: 'tje', label: 'Tje-ljud (kjol, tjata)', example: 'tj, k, kj' },
    { id: 'ng', label: 'Ng-ljud (ring, bång)', example: 'ng, gn' },
    { id: 'j', label: 'J-ljud (gjuta, ljus)', example: 'j, gj, lj, hj' },
    { id: 'dubbel', label: 'Dubbelteckning (hälla, komma)', example: 'll, mm, nn' },
    { id: 'ck', label: 'Ck-ord (backa, vecka)', example: 'ck' },
  ];

  const simulateProgress = (startProgress, endProgress, duration, setProgressFn) => {
    const stepSize = 0.5;
    const steps = (endProgress - startProgress) / stepSize;
    const stepDuration = duration / steps;
    let currentProgress = startProgress;

    const intervalId = setInterval(() => {
      currentProgress += stepSize;
      if (currentProgress >= endProgress) {
        clearInterval(intervalId);
      } else {
        setProgressFn(currentProgress);
      }
    }, stepDuration);

    return () => clearInterval(intervalId);
  };

  const handleModeChange = (event, newMode) => {
    if (newMode !== null) {
      setMode(newMode);
    }
  };

  const generateCustomWordsLesson = async () => {
    if (!customWords.trim()) {
      showSnackbar('Ange minst ett ord', 'warning');
      return;
    }

    setIsGenerating(true);
    setProgress(0);
    let stopProgressSimulation = null;

    try {
      stopProgressSimulation = simulateProgress(0, 90, 15000, setProgress);

      const wordList = customWords
        .split('\n')
        .map(word => word.trim())
        .filter(word => word);

      const response = await callApi(
        [
          {
            role: 'system',
            content: `Du är en svensklärare som skapar lektioner för att öva på svenska ord.`,
          },
          {
            role: 'user',
            content: `Skapa en lektion med följande ord: ${wordList.join(', ')}.
            
            För varje ord, identifiera relevanta ljudmönster eller stavningsmönster.
            
            Returnera ett JSON-objekt med följande struktur:
            {
              "title": "Veckans ord",
              "instructions": "En kort instruktion till eleverna om hur de ska arbeta med orden",
              "words": [
                {
                  "word": "ordet",
                  "pattern": "relevant ljudmönster eller stavningsmönster",
                  "exampleSentence": "En mening som använder ordet",
                  "imagePrompt": "beskrivning för bildgenerering",
                  "imageUrl": null
                }
              ],
              "story": "En kort berättelse (7 meningar) som använder orden"
            }`,
          },
        ],
        8000,
        true,
        user.uid
      );

      if (stopProgressSimulation) {
        stopProgressSimulation();
      }

      setProgress(95);

      let messageContent = response.data.choices[0].message.content.trim();
      if (messageContent.startsWith('```json')) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith('```')) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const data = JSON.parse(messageContent);
      setProgress(100);
      await new Promise(resolve => setTimeout(resolve, 800));

      setLessonData(data);

      showSnackbar('Veckans ord har genererats!', 'success');

      await trackLesson(user.uid, 'WeeklyWordsLesson.js', null, {
        custom: true,
      });
    } catch (error) {
      console.error('Error generating custom words lesson:', error);
      showSnackbar('Ett fel uppstod när lektionen skulle genereras. Försök igen.', 'error');
    } finally {
      setIsGenerating(false);
    }
  };

  const handleGenerate = () => {
    if (mode === 'custom') {
      generateCustomWordsLesson();
    } else {
      handleGenerateWords();
    }
  };

  const handleGenerateWords = async () => {
    if (mode === 'ai' && selectedPatterns.length === 0) {
      showSnackbar('Du måste välja minst ett ljudmönster', 'warning');
      return;
    }

    setIsGenerating(true);
    setProgress(0);
    let stopProgressSimulation = null;

    try {
      stopProgressSimulation = simulateProgress(0, 90, 15000, setProgress);

      const themeToUse = topic === 'customTheme' ? customTheme : topic;
      const themeInstruction =
        topic === 'none' ? '' : `Orden ska vara relaterade till temat "${themeToUse}".`;

      const response = await callApi(
        [
          {
            role: 'system',
            content: `Du är en svensklärare som skapar lektioner för att öva på svenska språkljud och stavningsmönster. Returnera ENDAST JSON.`,
          },
          {
            role: 'user',
            content: `Skapa en lektion med ${wordCount} ord som innehåller följande mönster: ${selectedPatterns
              .map(p => {
                const pattern = soundPatterns.find(sp => sp.id === p);
                if (pattern.id === 'dubbel') {
                  return `ord med dubbelteckning (${pattern.example})`;
                } else if (pattern.id === 'ck') {
                  return `ord med ck-stavning`;
                } else {
                  return pattern.label;
                }
              })
              .join(', ')}.
        
      ${themeInstruction}

      Returnera ett JSON-objekt med följande struktur:
      {
        "title": "Veckans ord: [ange stavningsmönster/ljudmönster]",
        "instructions": "En kort instruktion till eleverna om hur de ska arbeta med orden",
        "words": [
          {
            "word": "ordet",
            "pattern": "För ljudmönster: ange vilket ljud (sje/tje/etc). För stavningsmönster: ange mönstret (t.ex. 'll' för dubbelteckning)",
            "exampleSentence": "En mening som använder ordet",
            "imagePrompt": "beskrivning för bildgenerering",
            "imageUrl": null
          }
        ],
        "story": "En kort berättelse (7 meningar) som använder orden"
      }

      För ord med dubbelteckning, fokusera på ord där samma konsonant skrivs två gånger (t.ex. hälla, komma).
      För ck-ord, välj ord där ck-ljudet hörs tydligt (t.ex. backa, vecka).
      För ljudmönster (sje, tje, ng, j), välj ord där ljudmönstret är tydligt."`,
          },
        ],
        8000,
        true,
        user.uid
      );

      if (stopProgressSimulation) {
        stopProgressSimulation();
      }

      setProgress(95);

      let messageContent = response.data.choices[0].message.content.trim();
      if (messageContent.startsWith('```json')) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith('```')) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const data = JSON.parse(messageContent);
      setProgress(100);
      await new Promise(resolve => setTimeout(resolve, 800));

      setLessonData(data);

      showSnackbar('Veckans ord har genererats!', 'success');

      await trackLesson(user.uid, 'WeeklyWordsLesson.js', null, {
        wordCount,
      });
    } catch (error) {
      console.error('Error generating words:', error);
      showSnackbar('Ett fel uppstod när orden skulle genereras', 'error');
    } finally {
      setIsGenerating(false);
    }
  };

  const handleImageButtonClick = () => {
    setShowImageModal(true);
  };

  const handleCloseModal = () => {
    setShowImageModal(false);
    setImageInstructions('');
    setSelectedWordIndex(null);
  };

  const handleGenerateSpecificImage = async index => {
    setIsGeneratingImages(true);
    setLoadingImageIndex(index);
    handleCloseModal();

    try {
      const word = lessonData.words[index];
      const imageUrl = await generateAndProcessImage(
        `${word.imagePrompt}. ${imageInstructions}`,
        user.uid
      );

      const updatedWords = [...lessonData.words];
      updatedWords[index] = { ...word, imageUrl };

      setLessonData({
        ...lessonData,
        words: updatedWords,
      });

      showSnackbar('Bilden har genererats!', 'success');
    } catch (error) {
      console.error('Error generating image:', error);

      // Handle specific error types
      if (error.response?.status === 500) {
        showSnackbar('Serverfel: Bilden kunde inte genereras', 'error');
      } else if (
        error.response?.status === 400 &&
        error.response?.data === 'Your request was rejected as a result of our safety system'
      ) {
        showSnackbar(
          `Säkerhetssystemet avvisade bilden för "${lessonData.words[index].word}".`,
          'error'
        );
      } else if (error.code === 'ERR_BAD_RESPONSE') {
        showSnackbar('Serverfel: Bilden kunde inte genereras', 'error');
      } else {
        showSnackbar('Ett fel uppstod när bilden skulle genereras', 'error');
      }
    } finally {
      setIsGeneratingImages(false);
      setLoadingImageIndex(null);
    }
  };

  const handleGenerateImages = async () => {
    if (!lessonData) return;

    setIsGeneratingImages(true);
    handleCloseModal();

    let successCount = 0;
    const totalImages = lessonData.words.length;

    try {
      const updatedWords = await Promise.all(
        lessonData.words.map(async word => {
          try {
            const imageUrl = await generateAndProcessImage(
              `${word.imagePrompt}. ${imageInstructions}`,
              user.uid
            );
            successCount++;
            return { ...word, imageUrl };
          } catch (error) {
            console.error(`Error generating image for ${word.word}:`, error);

            // Handle specific error types but with better feedback
            if (error.response?.status === 500) {
              showSnackbar(
                `Serverfel: ${successCount}/${totalImages} bilder genererades`,
                'warning'
              );
            } else if (
              error.response?.status === 400 &&
              error.response?.data === 'Your request was rejected as a result of our safety system'
            ) {
              showSnackbar(
                `Säkerhetssystemet avvisade bilden för "${word.word}". ${successCount}/${totalImages} bilder genererades.`,
                'warning'
              );
            } else if (error.code === 'ERR_BAD_RESPONSE') {
              showSnackbar(
                `Serverfel: ${successCount}/${totalImages} bilder genererades`,
                'warning'
              );
            } else {
              showSnackbar(
                `Problem med generering: ${successCount}/${totalImages} bilder genererades`,
                'warning'
              );
            }

            return word;
          }
        })
      );

      const updatedData = {
        ...lessonData,
        words: updatedWords,
      };
      setLessonData(updatedData);

      // Final status message
      if (successCount === totalImages) {
        showSnackbar('Alla bilder genererades framgångsrikt!', 'success');
      } else if (successCount === 0) {
        showSnackbar('Kunde inte generera några bilder. Försök igen.', 'error');
      } else {
        showSnackbar(
          `${successCount}/${totalImages} bilder genererades framgångsrikt. Försök igen med de som misslyckades.`,
          'warning'
        );
      }
    } finally {
      setIsGeneratingImages(false);
    }
  };

  return (
    <Box sx={styles.container}>
      <Box
        sx={{
          ...styles.innerContainer,
          width: { xs: '100%', md: '87%' },
          margin: '0 auto',
        }}
      >
        <Typography
          variant='h1'
          gutterBottom
          sx={{ fontWeight: 'bold', fontSize: '1.8em', mb: 3, mt: 5, textAlign: 'center' }}
        >
          Veckans ord
        </Typography>

        <Box sx={{ ...styles.buttonsContainer, ...styles.additionalButtonsContainer }}>
          <Tooltip title='Dina sparade lektioner'>
            <Button
              variant='contained'
              color='primary'
              disableRipple
              onClick={() => setDrawerOpen(true)}
              startIcon={<HistoryIcon />}
              sx={{
                padding: '8px',
                marginRight: '0px',
                paddingLeft: '14px',
                paddingRight: '3px',
                minWidth: 'auto',
                textTransform: 'none',
              }}
            />
          </Tooltip>
        </Box>

        <InfoBox
          icon={DateRangeIcon}
          title='Instruktioner för Veckans ord'
          instructions={[
            'Välj mellan AI-genererade ord eller ange egna ord',
            'För AI-genererade ord: Välj ljudmönster att öva på',
            'För egna ord: Skriv in orden, ett per rad',
            'Få exempel och meningar för varje ord',
          ]}
          theme={theme}
          styles={styles}
        />
        <LessonDrawer
          userId={user.uid}
          lessonType='WeeklyWordsLesson.js'
          theme={theme}
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          lessonIcon={DateRangeIcon}
          onSelectLesson={lesson => {
            setLessonData(lesson.data);
            setTitleText(lesson.lessonName);
            setDrawerOpen(false);

            showSnackbar(`Lektionen har laddats in.`, 'success');
            document.querySelector('hr')?.scrollIntoView({ behavior: 'smooth' });
          }}
        />
        <Box sx={{ ...styles.freshBox, padding: '30px' }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <ToggleButtonGroup
                value={mode}
                exclusive
                onChange={handleModeChange}
                sx={{
                  mb: 3,
                  '& .MuiToggleButtonGroup-grouped': {
                    borderRadius: '10px !important',
                    mx: 0.5,
                    border: '1px solid rgba(0, 0, 0, 0.12)',
                    fontSize: '14px',
                    fontWeight: '600',
                    '&:first-of-type': {
                      borderTopLeftRadius: '10px !important',
                      borderBottomLeftRadius: '10px !important',
                    },
                    '&:last-of-type': {
                      borderTopRightRadius: '10px !important',
                      borderBottomRightRadius: '10px !important',
                    },
                  },
                }}
              >
                <ToggleButton value='ai'>
                  <AutoFixHighIcon sx={{ mr: 1, color: 'gray', fontSize: '22px' }} /> AI-genererade
                  ord
                </ToggleButton>
                <ToggleButton value='custom'>
                  {' '}
                  <EditNoteIcon sx={{ mr: 1, color: 'gray', fontSize: '22px' }} /> Egna ord
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>

            {mode === 'custom' ? (
              <>
                <Grid item xs={12}>
                  <Typography variant='subtitle1' sx={{ fontWeight: '500', mb: 1 }}>
                    Skriv in egna ord
                  </Typography>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    value={customWords}
                    onChange={e => setCustomWords(e.target.value)}
                    placeholder='Skriv in dina ord, ett per rad'
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} md={6}>
                  <Typography variant='subtitle1' sx={{ fontWeight: '500', mb: 1 }}>
                    Årskurs:
                  </Typography>
                  <GradeSelector grade={grade} setGrade={setGrade} />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography variant='subtitle1' sx={{ fontWeight: '500', mb: 1 }}>
                    Antal ord:
                  </Typography>
                  <FormControl fullWidth>
                    <Select value={wordCount} onChange={e => setWordCount(e.target.value)}>
                      {[2, 4, 6, 8, 10, 12].map(num => (
                        <MenuItem key={num} value={num}>
                          {num} ord
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant='subtitle1' sx={{ fontWeight: '500', mb: 2, mt: 2 }}>
                    Välj ljudmönster att öva på:
                  </Typography>
                  <FormGroup>
                    <Grid container spacing={2}>
                      {soundPatterns.map(pattern => (
                        <Grid item xs={12} md={6} key={pattern.id}>
                          <CustomFormControlLabel
                            theme={theme}
                            control={<Checkbox />}
                            borderRadius='10px'
                            label={
                              <Box>
                                <Typography sx={{ fontSize: '0.975rem' }}>
                                  {pattern.label}
                                </Typography>
                                <Typography variant='caption' sx={{ color: 'text.secondary' }}>
                                  Exempel: {pattern.example}
                                </Typography>
                              </Box>
                            }
                            checked={selectedPatterns.includes(pattern.id)}
                            onChange={() => {
                              setSelectedPatterns(prev =>
                                prev.includes(pattern.id)
                                  ? prev.filter(p => p !== pattern.id)
                                  : [...prev, pattern.id]
                              );
                            }}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </FormGroup>
                </Grid>
              </>
            )}
          </Grid>

          <Button
            variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
            onClick={handleGenerate}
            disabled={isGenerating}
            sx={{ mt: 6 }}
            startIcon={isGenerating ? <CircularProgress size={16} /> : <GenerateIcon />}
          >
            {isGenerating ? 'Genererar...' : 'Generera veckans ord'}
          </Button>

          {isGenerating && (
            <Box sx={{ mt: 7 }}>
              <ProgressBar progress={progress} label='Genererar veckans ord...' />
            </Box>
          )}

          {lessonData && (
            <Box sx={{ mt: 4 }}>
              <Divider sx={{ mt: 4, mb: 4 }} />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Box>
                  <Button
                    variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                    onClick={handleImageButtonClick}
                    disabled={isGeneratingImages}
                    sx={{ mr: 2 }}
                    startIcon={isGeneratingImages ? <CircularProgress size={16} /> : <ImageIcon />}
                  >
                    {isGeneratingImages ? 'Genererar bilder...' : 'Generera bilder'}
                  </Button>
                </Box>
                <Box>
                  <Button
                    variant='print'
                    onClick={() =>
                      handlePrintWeeklyWords(lessonData, theme, titleText, subtitleText)
                    }
                    startIcon={<PrintIcon />}
                  >
                    Skriv ut
                  </Button>

                  <Button
                    variant='print'
                    startIcon={<SaveAsIcon />}
                    sx={{ ml: 2 }}
                    onClick={handleSaveLessonClick}
                  >
                    Spara i lektionsbank
                  </Button>
                </Box>
              </Box>

              <SaveLessonDialog
                open={saveDialogOpen}
                onClose={() => setSaveDialogOpen(false)}
                onSave={handleSaveWithComment}
                theme={theme}
                lessonObject={lessonData}
                lessonName={titleText}
              />

              <ImageGenerationModal
                open={showImageModal}
                onClose={handleCloseModal}
                onGenerate={
                  selectedWordIndex !== null
                    ? () => handleGenerateSpecificImage(selectedWordIndex)
                    : handleGenerateImages
                }
                isGenerating={isGeneratingImages}
                instructions={imageInstructions}
                setInstructions={setImageInstructions}
                generationType='words'
                theme={theme}
              />

              <Box sx={{ mt: 7 }}>
                {lessonData && (
                  <Box sx={{ mt: 8 }}>
                    {/* Title */}
                    {/* Title */}
                    {isEditingTitle ? (
                      <TextField
                        value={titleText}
                        onChange={e => setTitleText(e.target.value)}
                        onBlur={() => {
                          setIsEditingTitle(false);
                          showSnackbar('Titeln har uppdaterats', 'success');
                        }}
                        onKeyDown={e => {
                          if (e.key === 'Enter') {
                            setIsEditingTitle(false);
                            showSnackbar('Titeln har uppdaterats', 'success');
                          }
                        }}
                        autoFocus
                        fullWidth
                        variant='standard'
                        sx={{
                          backgroundColor: 'transparent',
                          '& .MuiInputBase-input': {
                            textAlign: 'center',
                            fontWeight: 'bold',
                            fontSize: '2.6em',
                            marginBottom: '1.2rem',
                            marginTop: '1.5rem',
                          },
                        }}
                        InputProps={{
                          disableUnderline: true,
                        }}
                      />
                    ) : (
                      <Box
                        sx={{
                          position: 'relative',
                          display: 'inline-block',
                          margin: '0 auto',
                          width: '100%',
                          mb: 3,
                          mt: 5,
                          ':hover .edit-icon': {
                            opacity: 1,
                          },
                        }}
                      >
                        <Typography
                          variant='h1'
                          sx={{
                            fontWeight: 'bold',
                            fontSize: '2.6em',
                            cursor: 'pointer',
                            textAlign: 'center',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 2,
                          }}
                        >
                          {titleText || ' '}
                          <Box
                            component='span'
                            sx={{
                              opacity: 0,
                              transition: 'opacity 0.2s',
                              display: 'inline-flex',
                              gap: 0.5,
                            }}
                            className='edit-icon'
                          >
                            <Tooltip title='Redigera' placement='top'>
                              <IconButton size='small' onClick={() => setIsEditingTitle(true)}>
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Typography>
                      </Box>
                    )}

                    {/* Subtitle */}
                    {isEditingSubtitle ? (
                      <TextField
                        value={subtitleText}
                        onChange={e => setSubtitleText(e.target.value)}
                        onBlur={() => setIsEditingSubtitle(false)}
                        onKeyDown={e => {
                          if (e.key === 'Enter') {
                            setIsEditingSubtitle(false);
                          }
                        }}
                        autoFocus
                        fullWidth
                        variant='standard'
                        sx={{
                          backgroundColor: 'transparent',
                          '& .MuiInputBase-input': {
                            textAlign: 'center',
                            fontSize: '1.2em',
                            marginBottom: '2rem',
                            color: theme.palette.mode === 'dark' ? '#aaa' : '#666',
                          },
                        }}
                        InputProps={{
                          disableUnderline: true,
                        }}
                      />
                    ) : (
                      <Box
                        sx={{
                          position: 'relative',
                          display: 'inline-block',
                          margin: '0 auto',
                          width: '100%',
                          mb: 4,
                          ':hover .edit-icon': {
                            opacity: 1,
                          },
                        }}
                      >
                        <Typography
                          variant='h2'
                          sx={{
                            fontSize: '1.2em',
                            cursor: 'pointer',
                            textAlign: 'center',
                            color: theme.palette.mode === 'dark' ? '#aaa' : '#666',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 2,
                          }}
                        >
                          {subtitleText || ' '}
                          <Box
                            component='span'
                            sx={{
                              opacity: 0,
                              transition: 'opacity 0.2s',
                              display: 'inline-flex',
                              gap: 0.5,
                            }}
                            className='edit-icon'
                          >
                            <Tooltip title='Redigera' placement='top'>
                              <IconButton size='small' onClick={() => setIsEditingSubtitle(true)}>
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Typography>
                      </Box>
                    )}

                    {lessonData &&
                      lessonData.words.map((word, index) => (
                        <Box
                          key={index}
                          sx={{
                            position: 'relative',
                            display: 'flex',
                            alignItems: 'center',
                            mb: 5,
                            backgroundColor: theme.palette.mode === 'dark' ? '#1e1e1e' : '#f5f5f5',
                            padding: '20px',
                            borderRadius: '8px',
                            '&:hover': {
                              '& .delete-button': {
                                // Changed to target the class directly on hover
                                opacity: 1,
                                visibility: 'visible',
                              },
                            },
                          }}
                        >
                          <IconButton
                            className='delete-button'
                            size='small'
                            onClick={() => {
                              const updatedWords = lessonData.words.filter((_, i) => i !== index);
                              setLessonData({ ...lessonData, words: updatedWords });
                              showSnackbar('Ordet har tagits bort', 'success');
                            }}
                            sx={{
                              position: 'absolute',
                              top: 8,
                              right: 8,
                              opacity: 0,
                              visibility: 'hidden',
                              transition: 'all 0.2s ease',
                              bgcolor:
                                theme.palette.mode === 'dark' ? 'rgba(0,0,0,0.2)' : 'transparent',
                              '&:hover': {
                                bgcolor:
                                  theme.palette.mode === 'dark'
                                    ? 'rgba(0,0,0,0.3)'
                                    : 'rgba(255,255,255,0.9)',
                              },
                            }}
                          >
                            <Tooltip title='Ta bort block' placement='top'>
                              <DeleteOutlineIcon fontSize='small' />
                            </Tooltip>
                          </IconButton>

                          <Box sx={{ flex: '0 0 260px', mr: 3, position: 'relative' }}>
                            <HoverableImage
                              imageUrl={word.imageUrl}
                              isLoading={isGeneratingImages}
                              loadingIndex={loadingImageIndex}
                              currentIndex={index}
                              onGenerateClick={() => {
                                setSelectedWordIndex(index);
                                setShowImageModal(true);
                              }}
                              userId={user.uid}
                              onImageUpdate={newImageUrl => handleImageUpdate(index, newImageUrl)}
                              height='260px'
                              theme={theme}
                              showSnackbar={showSnackbar}
                              tinyMode={false}
                            />
                          </Box>

                          <Box sx={{ flex: 1 }}>
                            <Box
                              sx={{
                                '&:hover .controls': {
                                  opacity: 1,
                                },
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                mb: 1,
                              }}
                            >
                              {editingWordId === index ? (
                                <TextField
                                  fullWidth
                                  value={word.word}
                                  onChange={e => {
                                    const updatedWords = [...lessonData.words];
                                    updatedWords[index] = { ...word, word: e.target.value };
                                    setLessonData({ ...lessonData, words: updatedWords });
                                  }}
                                  onBlur={() => handleEditWord(index, word.word)}
                                  onKeyDown={e => {
                                    if (e.key === 'Enter' && !e.shiftKey) {
                                      e.preventDefault();
                                      handleEditWord(index, word.word);
                                    }
                                  }}
                                  autoFocus
                                  sx={{
                                    '& .MuiInputBase-input': {
                                      fontSize: '24px',
                                      fontWeight: '600',
                                    },
                                  }}
                                />
                              ) : (
                                <Typography
                                  variant='h2'
                                  sx={{
                                    fontSize: '24px',
                                    fontWeight: '600',
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                  }}
                                >
                                  {word.word}
                                  <Box
                                    component='span'
                                    className='controls'
                                    sx={{
                                      opacity: 0,
                                      transition: 'opacity 0.2s',
                                      display: 'inline-flex',
                                      gap: 0.5,
                                      ml: 1,
                                    }}
                                  >
                                    <Voice
                                      content={word.word}
                                      fontSize={20}
                                      userId={user.uid}
                                      language='svenska'
                                      tooltipPlacement='top'
                                    />
                                    <Tooltip title='Redigera' placement='top'>
                                      <IconButton
                                        size='small'
                                        onClick={() => setEditingWordId(index)}
                                      >
                                        <EditIcon fontSize='small' />
                                      </IconButton>
                                    </Tooltip>
                                  </Box>
                                </Typography>
                              )}
                            </Box>

                            <Box
                              sx={{
                                '&:hover .controls': {
                                  opacity: 1,
                                },
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                              }}
                            >
                              {editingExampleId === index ? (
                                <TextField
                                  fullWidth
                                  multiline
                                  defaultValue={word.exampleSentence}
                                  onBlur={e => handleEditExampleSentence(index, e.target.value)}
                                  onKeyDown={e => {
                                    if (e.key === 'Enter' && !e.shiftKey) {
                                      e.preventDefault();
                                      handleEditExampleSentence(index, e.target.value);
                                    }
                                  }}
                                  autoFocus
                                />
                              ) : (
                                <Typography variant='body1' sx={{ flex: 1 }}>
                                  {word.exampleSentence || ' '}
                                  <Box
                                    component='span'
                                    className='controls'
                                    sx={{
                                      opacity: 0,
                                      transition: 'opacity 0.2s',
                                      display: 'inline-flex',
                                      gap: 0.5,
                                      ml: 1,
                                    }}
                                  >
                                    <Voice
                                      content={word.exampleSentence}
                                      fontSize={20}
                                      userId={user.uid}
                                      language='svenska'
                                      tooltipPlacement='top'
                                    />
                                    <Tooltip title='Redigera' placement='top'>
                                      <IconButton
                                        size='small'
                                        onClick={() => setEditingExampleId(index)}
                                      >
                                        <EditIcon fontSize='small' />
                                      </IconButton>
                                    </Tooltip>
                                  </Box>
                                </Typography>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      ))}
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, mb: 4 }}>
                      <Button
                        variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                        startIcon={<AddIcon />}
                        onClick={() => {
                          const newWord = {
                            word: 'Nytt ord',
                            exampleSentence: 'Skriv en exempelmening här.',
                            pattern: '',
                            imagePrompt: '',
                            imageUrl: null,
                          };
                          setLessonData({
                            ...lessonData,
                            words: [...lessonData.words, newWord],
                          });
                          showSnackbar('Nytt ord har lagts till', 'success');
                        }}
                        sx={{
                          mt: 2,
                          mb: 4,
                        }}
                      >
                        Lägg till ord
                      </Button>
                    </Box>
                    {/* Story */}
                    <Box
                      sx={{
                        mt: 8,
                        mb: 4,
                        padding: '30px',
                        backgroundColor: theme.palette.mode === 'dark' ? '#1e1e1e' : '#f5f5f5',
                        borderRadius: '8px',
                      }}
                    >
                      <Typography variant='h2' sx={{ fontSize: '1.5em', mb: 3, fontWeight: '600' }}>
                        Berättelse med veckans ord
                      </Typography>
                      <Box
                        sx={{
                          '&:hover .controls': {
                            opacity: 1,
                          },
                          display: 'flex',
                          alignItems: 'flex-start',
                          gap: 1,
                        }}
                      >
                        {isEditingStory ? (
                          <TextField
                            fullWidth
                            multiline
                            rows={4}
                            defaultValue={lessonData.story}
                            onBlur={e => handleEditStory(e.target.value)}
                            onKeyDown={e => {
                              if (e.key === 'Enter' && !e.shiftKey) {
                                e.preventDefault();
                                handleEditStory(e.target.value);
                              }
                            }}
                            autoFocus
                            sx={{
                              '& .MuiInputBase-input': {
                                lineHeight: 1.8,
                              },
                            }}
                          />
                        ) : (
                          <Typography
                            variant='body1'
                            sx={{
                              lineHeight: 1.8,
                              flex: 1,
                              display: 'flex',
                              alignItems: 'flex-start',
                              gap: 1,
                            }}
                          >
                            {lessonData.story || ' '}
                            <Box
                              component='span'
                              className='controls'
                              sx={{
                                opacity: 0,
                                transition: 'opacity 0.2s',
                                display: 'inline-flex',
                                gap: 0.5,
                                ml: 1,
                                mt: 0.5,
                              }}
                            >
                              <Voice
                                content={lessonData.story}
                                fontSize={20}
                                userId={user.uid}
                                language='svenska'
                                tooltipPlacement='top'
                              />
                              <Tooltip title='Redigera' placement='top'>
                                <IconButton size='small' onClick={() => setIsEditingStory(true)}>
                                  <EditIcon fontSize='small' />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default WeeklyWordsLesson;
