import React from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Container,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const faqData = [
  {
    question: 'Vad kan jag som lärare & pedagog använda Studera.ai till?',
    answer:
      'Vi erbjuder ett brett utbud av AI-verktyg för lärare & pedagoger, däribland möjligheten att med hjälp av AI generera lektioner och pedagogiskt material för mängder av olika ämnen som t.ex. svenska, matematik och NO. Exempelvis går det att snabbt skapa lektioner inom grundläggande läsförståelse och ordförståelse, men även kreativa lektioner som "Grej of the day" och "Mystiska bilden". Därutöver erbjuder vi textanalysverktyg för kritik och faktakoll, quiz-generatorer som kan skapa frågor från olika källor, samt AI-assistenter  som kan hjälpa till med diverse läraruppgifter. Vi har också verktyg för att skapa powerpoints, ai-genererade bilder, målarbilder och mycket mer.',
  },
  {
    question: 'Hur kan jag använda AI för bedömning och utvärdering?',
    answer:
      'Vi erbjuder flera verktyg för bedömning: vår betygsättningsfunktion som använder fördefinierade bedömningsmatriser, verktyg för formativ bedömning, och möjlighet att skapa egna avancerade bedömningsmatriser. Du kan också använda textlabbet för att analysera elevers texter, beräkna ordfrekvens och ge konstruktiv kritik. Dessa verktyg hjälper dig att ge objektiv och detaljerad feedback till dina elever.',
  },
  {
    question: 'Vilka verktyg finns för språkundervisning?',
    answer:
      'För språkundervisning erbjuder vi flera specialiserade lektioner: "Glosor i lådor" för att lära ut ord och begrepp med bilder, "Hitta orden" för ordletningsövningar, "Bilda ordet" för bokstavsövningar, samt "Skriv fint" för skrivövningar. Vi har också verktyg för läsförståelse på olika nivåer och textanalysverktyg som kan användas för språkinlärning.',
  },
  {
    question: 'Hur kan jag använda plattformen för kreativ skrivning?',
    answer:
      'Vi har flera verktyg för kreativt skrivande: "Fortsätt berättelsen" låter elever utveckla sin fantasi, "Berättande text" hjälper med att strukturera olika textgenrer, och "Mystiska bilden" inspirerar till historieberättande baserat på bilder. Du kan också använda "Argument"-verktyget för att träna på argumenterande text och använda textlabbet för feedback och analys.',
  },
  {
    question: 'Vilka presentationsverktyg finns tillgängliga?',
    answer:
      'Vår PowerPoint-generator kan skapa presentationer automatiskt. Du kan också generera bilder med AI för att skapa visuellt material till dina presentationer och lektioner.',
  },
  {
    question: 'Hur kan jag använda plattformens quiz-funktioner?',
    answer:
      'Vår plattform erbjuder flera sätt att skapa quiz: du kan generera quiz från valfritt ämne, ladda upp Word/PDF-filer för att skapa frågor, använda Wikipedia-artiklar som grund, eller skapa quiz baserade på YouTube-klipp. Detta ger dig flexibilitet att skapa engagerande frågor oavsett källmaterial.',
  },
  {
    question: 'Vilka verktyg finns för lektionsplanering?',
    answer:
      'För lektionsplanering erbjuder vi specifika verktyg som vår lektionsplanerare där du kan skapa strukturerade planer baserat på tid och ämne. Du kan också använda Max, vår AI-lärarassistent, för idéer och feedback på dina planeringar. Dessutom kan du använda våra kreativa verktyg för att förbereda engagerande lektionsmoment.',
  },
  {
    question: 'Hur kan jag använda AI-assistenten Max i min undervisning?',
    answer:
      'Max är vår specialiserade lärarassistent som kan hjälpa dig med allt från lektionsplanering och bedömning till att generera övningar och ge pedagogiska råd. Du kan ställa frågor, be om feedback på ditt material, eller få hjälp med att anpassa innehåll för olika elevgrupper. Max kan även hjälpa dig att utveckla nya undervisningsstrategier.',
  },
  {
    question: 'Vilka textanalysverktyg finns tillgängliga?',
    answer:
      'Vi erbjuder omfattande textanalysverktyg i vårt textlabb: du kan kritisera text för konstruktiv feedback, faktakolla innehåll, summera långa texter, rätta stavning och grammatik, beräkna ordfrekvens och göra djupgående textanalyser. Dessa verktyg hjälper dig att ge detaljerad och konstruktiv feedback på elevers texter.',
  },

  {
    question: 'Hur säkerställs säkerheten för elevernas data med GDPR?',
    answer:
      'Datasäkerhet är en högsta prioritet för oss på Studera.ai. Alla elevdata krypteras och lagras säkert. Vi följer GDPR och andra relevanta dataskyddslagar för att säkerställa att all information hanteras ansvarsfullt. Endast behöriga lärare har tillgång till sina elevers data, och vi delar aldrig personlig information med tredje part.',
  },

  {
    question: 'Hur stödjer Studera.ai inkluderande undervisning?',
    answer:
      'Vi erbjuder verktyg som hjälper dig att anpassa din undervisning för elever med olika behov. Du kan skapa material som är tillgängligt för elever med dyslexi, ADHD eller andra inlärningsutmaningar. AI:n kan föreslå anpassningar och ge dig resurser för att göra din undervisning mer inkluderande och effektiv för alla elever.',
  },

  {
    question: 'Hur integrerar jag Studera.ai i min befintliga undervisningsmetod?',
    answer:
      'Studera.ai är designat för att smidigt integreras i din nuvarande undervisningspraktik. Du kan använda våra verktyg som komplement till ditt dagliga arbete, lägga till AI-genererade quiz och övningar, samt använda AI-assistenter för att förbättra klassrumsinteraktionen. Plattformen är flexibel och kan anpassas efter dina specifika behov och preferenser, vilket gör det enkelt att börja använda AI i din undervisning.',
  },
  {
    question: 'Vilka AI-modeller använder vi?',
    answer:
      'Vi stödjer flera ledande AI-modeller för att ge dig bästa möjliga upplevelse. Vi använder ChatGPT 3.5 Turbo, 4o-mini, 4o, o1-mini och o1-preview från OpenAI, Claude Sonnet och Sonnet 3.5 från Anthropic, samt Google Gemini, Gemini Pro och Mistral Large. Därtill finjusterar vi ständigt vår egen AI-modell för att bli bättre på de uppgifter våra användare efterfrågar. Olika modeller används för olika uppgifter beroende på deras styrkor, vilket säkerställer optimal prestanda för varje funktion i plattformen.',
  },

  {
    question: 'Kan Studera.ai hjälpa mig som lärare att identifiera AI-genererat innehåll?',
    answer:
      'Ja, Studera.ai har en inbyggd svensk AI-detektor som kan analysera texter för att bedöma sannolikheten att den är AI-genererad. Detta verktyg använder avancerad lingvistisk analys, stilometrisk profilering och diskursanalys. Det kan hjälpa dig att skilja mellan mänskligt och AI-genererat innehåll. Det är särskilt användbart om du som lärare och pedagog vill veta huruvida en text skriven av en elev eller student de facto är AI-genererad eller inte.',
  },

  {
    question: 'Hur kan jag få support om jag behöver hjälp?',
    answer:
      'Om du behöver hjälp eller har frågor kan du kontakta oss via e-post genom att maila info@studera.ai. Vi erbjuder också en kunskapsbas och artiklar som hjälper dig att komma igång och få ut mesta möjliga av Studera.ai. Vårt mål är att stödja dig i din undervisning och säkerställa att du har alla verktyg du behöver för att lyckas.',
  },

  // Moonshine prompt: "lägg till några faq items baserat på de ai-verktyg för lärare och pedagoger som jag erbjuder nedan (dashboardtoolshortcuts.js): "

  // {
  //    question: 'Hur kan jag använda verktyget "Argument" i min undervisning?',
  //    answer:
  //      'Verktyget "Argument" låter dig generera för- och emot-argument inom ett ämnesområde du väljer. Detta är användbart för debattövningar, kritiskt tänkande och för att ge eleverna en balanserad syn på ämnen. Du kan enkelt skapa argument till olika perspektiv och ge eleverna en möjlighet att utforska båda sidor av en fråga.',
  //  },
  //  {
  //    question: 'Vad är "På spåret" och hur fungerar det?',
  //    answer:
  //      'I "På spåret" får eleverna gissa rätt svar utifrån en rad ledtrådar. Detta är ett roligt sätt att testa deras kunskaper inom olika ämnen och uppmuntra logiskt tänkande. Spelet kan användas i grupp eller individuellt för att skapa en engagerande och dynamisk lärmiljö.',
  //  },
  //  {
  //    question: 'Hur kan jag använda "Grej of the Day" i klassrummet?',
  //    answer:
  //      '"Grej of the Day" är ett perfekt sätt att väcka nyfikenhet hos eleverna. Verktyget levererar korta fakta om spännande ämnen som kan användas för att introducera lektionen eller som ett sätt att börja dagen på ett inspirerande sätt. Det är ett utmärkt verktyg för att främja allmänbildning och stimulera intresse för nya ämnesområden.',
  //  },
  //  {
  //    question: 'Vad är fördelen med "Glosor i lådor"?',
  //    answer:
  //      '"Glosor i lådor" hjälper elever att lära sig nya ord och begrepp genom bilder och ordassociation. Detta visuella stöd kan vara särskilt hjälpsamt för yngre elever eller de som lär sig svenska som andraspråk, då de kan koppla ord till bilder och på så sätt stärka sin språkutveckling.',
  //  },
  //  {
  //    question: 'Hur använder jag "Målarbilder" för att stödja kreativitet?',
  //    answer:
  //      'Med "Målarbilder" kan du generera målarbilder relaterade till olika teman. Dessa kan användas som en lugnande aktivitet för yngre elever eller som en kreativ ingång till ett specifikt ämnesområde. Målarbilderna är anpassade för att inspirera elevernas kreativitet samtidigt som de kan fokusera och reflektera över ämnet.',
  //  },
  //  {
  //    question: 'Hur fungerar "Mystiska bilden"?',
  //    answer:
  //      'I "Mystiska bilden" får eleverna skriva en berättelse utifrån vad de ser på en genererad bild. Detta verktyg utvecklar deras fantasi, skrivförmåga och observationsförmåga genom att de skapar egna historier baserade på visuella intryck.',
  //  },
];

const TeacherFAQSection = () => {
  return (
    <Box sx={{ bgcolor: 'background.paper', py: 8, boxShadow: 'none', border: 'none' }}>
      <Container maxWidth='lg' sx={{ boxShadow: 'none', border: 'none' }}>
        <Typography
          variant='h3'
          align='center'
          gutterBottom
          sx={{ fontWeight: 'bold', mb: 6, fontSize: '2.3em' }}
        >
          Vanliga frågor och svar
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            {faqData.slice(0, Math.ceil(faqData.length / 2)).map((faq, index) => (
              <Accordion
                key={index}
                sx={{
                  mb: 2,
                  boxShadow: 'none',
                  border: 'none',
                  '&:before': { display: 'none' },
                  bgcolor: 'transparent',
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{
                    boxShadow: 'none',
                    border: 'none',
                    '&:before': { display: 'none' },
                    borderRadius: '10px',
                  }}
                >
                  <Typography variant='h6' sx={{ fontWeight: '600', fontSize: '1.2rem' }}>
                    {faq.question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography sx={{ lineHeight: '1.7rem' }}>{faq.answer}</Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Grid>
          <Grid item xs={12} md={6}>
            {faqData.slice(Math.ceil(faqData.length / 2)).map((faq, index) => (
              <Accordion
                key={index + Math.ceil(faqData.length / 2)}
                id={`faq-${index}`}
                sx={{
                  mb: 2,
                  boxShadow: 'none',
                  border: 'none',
                  '&:before': { display: 'none' },
                  bgcolor: 'transparent',
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{
                    boxShadow: 'none',
                    border: 'none',
                    '&:before': { display: 'none' },
                  }}
                >
                  <Typography variant='h6' sx={{ fontWeight: '600', fontSize: '1.2rem' }}>
                    {faq.question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography sx={{ lineHeight: '1.7rem' }}>{faq.answer}</Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default TeacherFAQSection;
