import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import PromotionalWebsite from './PromotionalWebsite'; // Importera den nya välkomstsidan
import ExistingApp from './app/ExistingApp'; // Importera din befintliga app-struktur
import FeaturePage from './FeaturePage';
import GrejPage from './GrejPage';
import FAQPage from './FAQPage';
import AboutPage from './AboutPage';
import LessonsPage from './LessonsPage';
import PrivacyPolicyPage from './PrivacyPolicyPage';
import PricesPage from './PricesPage';
import StudentsPage from './StudentsPage';

function App({ isDark, toggleDarkMode, setOverrideTheme }) {
    return (
        <HelmetProvider>
            <Router>
                <Routes>
                    {/* Välkomstsida på rot-URL */}
                    <Route
                        path='/'
                        element={
                            <PromotionalWebsite isDark={isDark} toggleDarkMode={toggleDarkMode} />
                        }
                    />

                    <Route
                        path='/funktioner/:featureId'
                        element={<FeaturePage isDark={isDark} toggleDarkMode={toggleDarkMode} />}
                    />

                    <Route
                        path='/om-oss'
                        element={<AboutPage isDark={isDark} toggleDarkMode={toggleDarkMode} />}
                    />
                    <Route
                        path='/priser'
                        element={<PricesPage isDark={isDark} toggleDarkMode={toggleDarkMode} />}
                    />
                    <Route
                        path='/ai-verktyg-larare'
                        element={<LessonsPage isDark={isDark} toggleDarkMode={toggleDarkMode} />}
                    />
                    <Route
                        path='/ai-verktyg-elever'
                        element={<StudentsPage isDark={isDark} toggleDarkMode={toggleDarkMode} />}
                    />
                    <Route
                        path='/integritetspolicy-gdpr'
                        element={
                            <PrivacyPolicyPage isDark={isDark} toggleDarkMode={toggleDarkMode} />
                        }
                    />
                    <Route
                        path='/funktioner/grej-of-the-day'
                        element={<GrejPage isDark={isDark} toggleDarkMode={toggleDarkMode} />}
                    />

                    {/* Flytta befintlig app-struktur till /app */}
                    <Route
                        path='/app/*'
                        element={
                            <ExistingApp
                                isDark={isDark}
                                toggleDarkMode={toggleDarkMode}
                                setOverrideTheme={setOverrideTheme}
                            />
                        }
                    />

                    {/* Omdirigera odefinierade sökvägar till välkomstsidan */}
                    <Route path='*' element={<Navigate to='/' />} />
                </Routes>
            </Router>
        </HelmetProvider>
    );
}

export default App;
