import React, { useState, useEffect, useRef } from 'react';
import { db } from './firebase';
import {
  doc,
  setDoc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  addDoc,
  deleteDoc,
} from 'firebase/firestore';
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Card,
  Link,
  CardContent,
  TextField,
  Alert,
  LinearProgress,
  Fade,
  Drawer,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Chip,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { List, ListItem, ListItemText, ListItemSecondaryAction } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { getStyles } from '../index';
import Logger from './Logger';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { MathJaxContext, MathJax } from 'better-react-mathjax';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import AddIcon from '@mui/icons-material/Add';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import DeleteIcon from '@mui/icons-material/Delete';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import Looks4Icon from '@mui/icons-material/Looks4';
import ExploreIcon from '@mui/icons-material/Explore';
import RecommendationComponent from './RecommendationComponent';
import DatasetIcon from '@mui/icons-material/Dataset';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Voice from './Voice';
import HPDrawerComponent from './hp/HPDrawerComponent';
import { Helmet } from 'react-helmet-async';
import FocusIcon from '@mui/icons-material/FilterCenterFocus';
import CenterFocusWeakIcon from '@mui/icons-material/CenterFocusWeak';
import { Compare, PieChart } from '@mui/icons-material';
import {
  AutoStories,
  Spellcheck,
  TextFields,
  ElectricBolt,
  Calculate,
  Extension,
  Language,
} from '@mui/icons-material';
import TestRecommendation from './TestRecommendation';
import QuestionTypeSelector from './QuestionTypesHP';
import { v4 as uuidv4 } from 'uuid';
import LoadingSpinner from './LoadingSpinner';
import Looks5Icon from '@mui/icons-material/Looks5';
import { Grid, Tooltip } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Divider from '@mui/material/Divider';
import { Dialog, DialogContent, DialogTitle, useMediaQuery } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import StarIcon from '@mui/icons-material/Star';
import CloseIcon from '@mui/icons-material/Close';
import { Howl } from 'howler';
import selectSoundFile from './sounds/select.mp3';
import select2 from './sounds/select2.mp3';
import SegmentIcon from '@mui/icons-material/Segment';
import done from './sounds/done.mp3';
import { callApi } from './api';

import { useSnackbar } from './SnackbarHandler';

import GenerateIcon from '@mui/icons-material/AutoAwesome';

import InfoIcon from '@mui/icons-material/Info';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { keyframes } from '@mui/system';
import OptionButton from './OptionButton';
import QuestionNavigation from './QuestionNavigation';
///// TILL HIT //////
import { TestSelector, IncompleteTestDialog, AIModelSelect } from './TestComponents2';
import { getHpDataById, availableTests } from './hpData';

import AIDialog from './AIDialog';
import { useParams, useNavigate } from 'react-router-dom';

import DynamicMathChart from './DynamicMathChart';

const MathQuestion = ({ question }) => {
  return (
    <div className='math-question'>
      <h2>{question.question}</h2>
      <DynamicMathChart
        chartType={question.chartType}
        chartData={question.chartData}
        chartConfig={question.chartConfig}
      />
      <div className='options'>
        {question.options.map((option, index) => (
          <div key={index} className='option'>
            <input type='radio' id={`option-${index}`} name='answer' value={option} />
            <label htmlFor={`option-${index}`}>{option}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

const HP = ({ user }) => {
  const [hpTest, setHpTest] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isLoadingTests, setIsLoadingTests] = useState(true);
  const [results, setResults] = useState([]);
  const [showSummary, setShowSummary] = useState(false);

  const [isTestLoading, setIsTestLoading] = useState(false);
  const [openAIDialogs, setOpenAIDialogs] = useState({});

  const [testInProgress, setTestInProgress] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const [answeredQuestions, setAnsweredQuestions] = useState({});
  const [savedQuestions, setSavedQuestions] = useState({});

  const theme = useTheme();
  const styles = getStyles(theme);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const selectSound = new Howl({ src: [selectSoundFile], volume: 0.1 });
  const nextSound = new Howl({ src: [select2], volume: 0.6 });
  const doneSound = new Howl({ src: [done], volume: 0.2 });

  const [showTestSelector, setShowTestSelector] = useState(true);
  const [selectedTestId, setSelectedTestId] = useState(availableTests[0]?.id || null);
  const [isOverviewOpen, setIsOverviewOpen] = useState(false);
  const [userProgress, setUserProgress] = useState([]);

  const [incompleteDialogOpen, setIncompleteDialogOpen] = useState(false);
  const [focusModeActive, setFocusModeActive] = useState(false);

  const [refreshKey, setRefreshKey] = useState(0);

  const [aiDialogOpen, setAiDialogOpen] = useState(false);
  const [aiResponse, setaiResponse] = useState('');

  const [currentHpName, setCurrentHpName] = useState('');

  const messagesEndRef = React.useRef(null);

  const [isSaved, setIsSaved] = useState(false);

  const [generationStatus, setGenerationStatus] = useState({});
  const [isGenerating, setIsGenerating] = useState(false);
  const [generatedTestId, setGeneratedTestId] = useState(null);

  const { showSnackbar } = useSnackbar();

  const [allTests, setAllTests] = useState([]);
  const [generatedTests, setGeneratedTests] = useState([]);

  const [timeLeft, setTimeLeft] = useState(55 * 60);
  const timeLeftRef = useRef(55 * 60);
  const timerRef = useRef(null);
  const timerDisplayRef = useRef(null);

  const [selectedQuestionTypes, setSelectedQuestionTypes] = useState(['ORD']);
  const [difficulty, setDifficulty] = useState('hard');

  const [progress, setProgress] = useState(0);
  const [currentGeneratingType, setCurrentGeneratingType] = useState('');

  const [selectedModel, setSelectedModel] = useState('Studera.AI');

  const [showOverlay, setShowOverlay] = useState(false);
  const [overviewLoading, setOverviewLoading] = useState(false);

  const [showManualTest, setShowManualTest] = useState(false);

  const [manualTestName, setManualTestName] = useState('');
  const [manualTestJson, setManualTestJson] = useState('');
  const initialHpTestIds = [
    'LExXv2PZoCz2xbfBZ8xk',
    'f1BIyylYSivZM7vFzlQA',
    'iQzSOWvLHifNoqzcBJ1i',
    'sf09lktI5St09Z7nzkpr',
  ];

  const [dynamicTitle, setDynamicTitle] = useState(
    'Alla högskoleprov — Gamla högskoleprov & AI-genererade | Studera.AI'
  );
  const [dynamicDescription, setDynamicDescription] = useState(
    'Öva på gamla och AI-genererade högskoleprov. Förbered dig effektivt med vår omfattande provbank.'
  );

  const handleOpenAIDialog = index => {
    setOpenAIDialogs(prev => ({ ...prev, [index]: true }));
  };

  const handleCloseAIDialog = index => {
    setOpenAIDialogs(prev => ({ ...prev, [index]: false }));
  };

  useEffect(() => {
    addSpecializedHPTests();
  }, []);

  async function addSpecializedHPTests() {
    try {
      const hpTestsSnapshot = await getDocs(collection(db, 'hpPublic'));
      const allTests = hpTestsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      // Filter for official tests only
      const officialTests = allTests.filter(test => test.official === true);

      // Extract ORD questions from official tests
      const ordQuestions = officialTests.flatMap(test =>
        test.data.questions.filter(q => q.questionType === 'MEK').flatMap(q => q.questions)
      );

      // Extract LÄS questions from official tests
      const lasQuestions = officialTests.flatMap(test =>
        test.data.questions.filter(q => q.questionType === 'ELF')
      );

      // Create new ORD test
      const ordTest = {
        name: 'MEK-delen',
        official: false,
        questionTypeTest: 'MEK',
        specificPart: true,
        data: {
          questions: [
            {
              questionType: 'MEK',
              questions: ordQuestions,
            },
          ],
        },
        upvotes: 420,
        downvotes: 0,
        userVotes: {},
      };

      // Create new LÄS test
      const lasTest = {
        name: 'ELF-delen',
        official: false,
        specificPart: true,
        questionTypeTest: 'ELF',
        data: {
          questions: lasQuestions,
        },
        upvotes: 134,
        downvotes: 0,
        userVotes: {},
      };

      // const ordDocRef = await addDoc(collection(db, 'hpPublic'), ordTest);
      // Logger.log('ORD test added with ID: ', ordDocRef.id);

      // const lasDocRef = await addDoc(collection(db, 'hpPublic'), lasTest);
      // Logger.log('LÄS test added with ID: ', lasDocRef.id);

      Logger.log('Specialized HP tests added successfully!');
    } catch (error) {
      Logger.error('Error adding specialized HP tests: ', error);
    }
  }

  const copyInitialHpTestsForNewUser = async (userId, hpTestIds) => {
    try {
      for (const hpTestId of hpTestIds) {
        const publicHpTestRef = doc(db, 'hpPublic', hpTestId);
        const publicHpTestSnap = await getDoc(publicHpTestRef);

        if (publicHpTestSnap.exists()) {
          const hpTestData = publicHpTestSnap.data();

          const hpTestCopy = {
            ...hpTestData,
            uid: userId,
            createdAt: new Date(),
            official: true,
            isPublicCopy: true,
            isGenerated: true,
          };
          await setDoc(doc(db, 'hpTestData', hpTestId), hpTestCopy);
          Logger.log(`HP Test ${hpTestId} copied for new user ${userId}`);
        } else {
          console.error(`Public HP Test with ID ${hpTestId} not found`);
        }
      }
      Logger.log(`Initial HP Tests copied successfully for user ${userId}`);
    } catch (error) {
      console.error('Error copying initial HP Tests: ', error);
    }
  };

  const copyTest = async event => {
    await copyInitialHpTestsForNewUser(user.uid, initialHpTestIds);
  };

  const handleManualTestSubmit = async event => {
    event.preventDefault();
    try {
      const parsedJson = JSON.parse(manualTestJson);
      const hpPublicData = {
        name: manualTestName,
        official: true,
        data: parsedJson,
        upvotes: 0,
        downvotes: 0,
        userVotes: {},
      };
      const hpPublicDocRef = await addDoc(collection(db, 'hpPublic'), hpPublicData);
      Logger.log('Manual test added with ID: ', hpPublicDocRef.id);
      showSnackbar('Test added successfully!', 'success');
      setManualTestName('');
      setManualTestJson('');
    } catch (error) {
      console.error('Error adding manual test: ', error);
      showSnackbar('Error adding test. Please check your JSON.', 'error');
    }
  };

  const toggleFocusMode = () => {
    setFocusModeActive(!focusModeActive);
  };

  const { testId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (testId) {
      handleSelectTest(testId);
    }
  }, [testId]);

  const [selectedThemes, setSelectedThemes] = useState({
    ORD: 'AI får välja',
    LÄS: 'AI får välja',
    MEK: 'AI får välja',
    ELF: 'AI får välja',
    XYZ: 'AI får välja',
    DTK: 'AI får välja',
  });

  const [questionCounts, setQuestionCounts] = useState({
    ORD: 5,
    LÄS: 2,
    MEK: 5,
    ELF: 2,
    XYZ: 5,
    DTK: 3,
  });

  const handleQuestionCountChange = (type, count) => {
    setQuestionCounts(prev => ({
      ...prev,
      [type]: count,
    }));
  };

  const [selectedLanguage, setSelectedLanguage] = useState('Svenska');

  const handleThemeChange = (type, theme) => {
    Logger.log('them: ', theme);
    setSelectedThemes(prev => ({
      ...prev,
      [type]: theme,
    }));
  };

  const handleQuestionTypeChange = event => {
    const { name, checked } = event.target;
    setSelectedQuestionTypes(prev => {
      if (checked) {
        return [...prev, name];
      } else {
        // Ensure at least one type is always selected
        return prev.length > 1 ? prev.filter(type => type !== name) : prev;
      }
    });
  };

  const handleDeleteGeneratedTest = async testId => {
    if (!user) return;

    try {
      // Delete the test from Firestore
      await deleteDoc(doc(db, 'hpTestData', testId));

      // Remove the test from allTests state
      setAllTests(prevTests => prevTests.filter(test => test.id !== testId));

      // Remove any associated progress
      await deleteDoc(doc(db, 'hpTestProgress', `${user.uid}_${testId}`));

      // Remove from userProgress state
      setUserProgress(prevProgress => prevProgress.filter(p => p.testId !== testId));

      Logger.log(`Generated test ${testId} has been deleted`);
    } catch (error) {
      console.error('Error deleting generated test:', error);
    }
  };

  const fetchAllTests = async uid => {
    // Fetch user's tests from Firestore
    const userTestsQuery = query(collection(db, 'hpTestData'), where('uid', '==', uid));
    const querySnapshot = await getDocs(userTestsQuery);

    const userTests = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }));

    const updatedTests = await Promise.all([
      // Moonshine: tror jag använde detta för testsyfte eller? vetefan. jo, det är för gamla hp du lagt in i ny json redo att laddas upp, att testa innan uppladdning
      // ...availableTests.map(async (test) => {
      //   const testData = await getHpDataById(test.id);
      //   const questionTypes = [...new Set(testData.questions.map(q => q.questionType))];
      //   return { ...test, questionTypes, isGenerated: false, isFromHpData: true };
      // }),
      ...userTests.map(async test => {
        const questionTypes = [...new Set(test.data.questions.map(q => q.questionType))];
        return { ...test, questionTypes, isGenerated: true };
      }),
    ]);

    setAllTests(updatedTests);
  };

  useEffect(() => {
    if (showTestSelector) {
      setDynamicTitle('Skapa högskoleprov med AI | Studera.AI');
      setDynamicDescription(
        'Generera högskoleprov med AI för olika frågetyper som ORD, LÄS, MEK, XYZ, ELF, DTK m.fl. Perfekt alternativ till att träna på gamla högskoleprov.'
      );

      setIsLoadingTests(true);
      const timer = setTimeout(() => {
        fetchAllTests(user.uid).then(() => {
          setIsLoadingTests(false);
        });
      }, 2000); // Delay for 2 seconds

      return () => clearTimeout(timer);
    }
  }, [showTestSelector, user]);

  const pulse = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
`;

  const parseDTKQuestions = response => {
    // Ensure the response is a string
    if (typeof response !== 'string') {
      throw new Error('Response must be a string');
    }

    // Replace LaTeX backslashes with a temporary placeholder
    const tempResponse = response.replace(/\\/g, '||BACKSLASH||');

    try {
      // Parse the modified JSON
      const parsed = JSON.parse(tempResponse);

      // Restore the LaTeX backslashes
      const restoreBackslashes = obj => {
        for (const key in obj) {
          if (typeof obj[key] === 'string') {
            obj[key] = obj[key].replace(/\|\|BACKSLASH\|\|/g, '\\');
          } else if (typeof obj[key] === 'object') {
            restoreBackslashes(obj[key]);
          }
        }
      };

      restoreBackslashes(parsed);
      return parsed;
    } catch (error) {
      console.error('Error parsing DTK questions:', error);
      throw new Error('Failed to parse DTK questions');
    }
  };

  const extractJsonFromResponse = response => {
    if (Array.isArray(response) || typeof response === 'object') {
      return response;
    }
    if (typeof response === 'string') {
      try {
        return JSON.parse(response);
      } catch (e) {
        console.error('Error parsing JSON:', e);
        throw new Error('Failed to parse JSON from the response');
      }
    }
    throw new Error('Unexpected response format');
  };

  const progressRef = useRef(null);
  const progressPercentRef = useRef(null);
  const updateProgress = value => {
    if (progressRef.current) {
      progressRef.current.style.width = `${value}%`;
    }
    if (progressPercentRef.current) {
      progressPercentRef.current.textContent = `${Math.round(value)}%`;
    }
  };

  const handleGenerateQuestions = async event => {
    event.preventDefault();
    setIsGenerating(true);
    setGenerationStatus({});
    setGeneratedTestId(null);
    updateProgress(0);
    setCurrentGeneratingType('');

    setTimeout(() => {
      const progressBar = document.querySelector('.progress-bar-container');
      if (progressBar) {
        progressBar.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 500);

    Logger.log('Generating questions with difficulty:', difficulty);
    Logger.log('Selected themes:', selectedThemes);
    Logger.log('Selected language:', selectedLanguage);
    Logger.log('Selected model:', selectedModel);

    let diff = difficulty === 'hard' ? 'Extremt svåra ord, ytterst sällsynta.' : difficulty;
    const generatePrompt = type => {
      const theme =
        selectedThemes[type] !== 'AI får välja' ? `inom kategorin: ${selectedThemes[type]}.` : '';
      const count = questionCounts[type];
      switch (type) {
        case 'ORD':
          return `Generera ${count} frågor för ORD-sektionen (ordförståelse) för det svenska Högskoleprovet, exempel på ord: impertinent, verserad, renegat, juvenil - men använd inte dessa, de är bara exempel. Frågorna består endast av ett  ord på ${selectedLanguage}  sen ska användaren välja den närmaste  synonymen. Alla ord och alterantiv ska vara på ${selectedLanguage} ${theme}. Svårighetsgrad: ${diff}.  Följ denna JSON-struktur varje gång: [{"question": "word", "options": ["option1", "option2", "option3", "option4", "option5"], "correctAnswer": "correctOption"}]`;
        case 'LÄS':
          return `Generera ${count} frågor för LÄS-sektionen (svensk läsförståelse) för det svenska Högskoleprovet. Svårighetsgrad: ${diff}. Skriv allt på ${selectedLanguage} ${theme}. Texten ska vara minst 300 ord indelade i stycken med radbrytningar. The response should be an array of JSON objects with the following structure: [{"text": "passage", "questions": [{"question": "question text", "options": ["option1", "option2", "option3", "option4"], "correctAnswer": "correctOption"}]}]`;
        case 'MEK':
          return `Generera ${count} frågor för MEK-sektionen (meningskomplettering) för det svenska Högskoleprovet. Svårighetsgrad: ${diff}. Skriv allt på ${selectedLanguage} ${theme}. Det kan antingen vara en eller två luckor man ska fylla i det saknade ordet/orden för. Är det två luckor ska det finnas två ord per svarsalternativ (exempel: kriga - anpassa).  The response should be an array of JSON objects with the following structure: [{"question": "sentence with blanks", "options": ["option1", "option2", "option3", "option4"], "correctAnswer": "correctOption"}]`;
        case 'ELF':
          return `Generera ${count} frågor för ELF-sektionen (engelsk läsförståelse) för det svenska Högskoleprovet. Svårighetsgrad: ${diff}. Skriv allt på ${selectedLanguage} ${theme}. Texten ska vara minst 300 ord indelade i stycken med radbrytningar. The response should be an array of JSON objects with the following structure: [{"text": "English passage", "questions": [{"question": "question text", "options": ["option1", "option2", "option3", "option4"], "correctAnswer": "correctOption}]}]`;
        case 'XYZ':
          const mathTheme = selectedThemes[type];
          let problemTypes;
          if (mathTheme === 'AI får välja') {
            problemTypes = '"algebra|geometry|arithmetic|calculus|trigonometry"';
          } else {
            // Map Swedish terms to English for consistency with original structure
            const themeMap = {
              Algebra: 'algebra',
              Geometri: 'geometry',
              Aritmetik: 'arithmetic',
              Kalkyl: 'calculus',
              Trigonometri: 'trigonometry',
            };
            problemTypes = `"${themeMap[mathTheme] || mathTheme.toLowerCase()}"`;
          }
          return `Generera ${count} matematikfrågor med svårighetsgrad ${diff} inom området ${mathTheme}. Svaret ska vara ett JSON-objekt med följande struktur: 
  [
    {
      "difficulty": "${diff}",
      "problemType": ${problemTypes},
      "equation": "matematisk ekvation eller uttryck i LaTeX-format",
      "variables": {
        "x": 5, 
        "y": 3,
        // Ytterligare variabler vid behov
      },
      "question": "Beskrivning av problemet eller frågan",
      "options": ["alternativ1", "alternativ2", "alternativ3", "alternativ4"],
      "correctAnswer": "korrekt alternativ",
    }
  ]`;

        case 'DTK':
          const mathTheme2 = selectedThemes[type];
          let problemTypes2;
          if (mathTheme2 === 'AI får välja') {
            problemTypes2 =
              '"algebra|geometry|probability|calculus|statistics|functions|trigonometry|dataAnalysis|linearAlgebra"';
          } else {
            const themeMap = {
              Algebra: 'algebra',
              Geometri: 'geometry',
              Sannolikhet: 'probability',
              Kalkyl: 'calculus',
              Statistik: 'statistics',
              Funktioner: 'functions',
              Trigonometri: 'trigonometry',
              Dataanalys: 'dataAnalysis',
              'Linjär algebra': 'linearAlgebra',
            };
            problemTypes2 = `"${themeMap[mathTheme2] || mathTheme2.toLowerCase()}"`;
          }

          return `Generera ${count} matematikfrågor med svårighetsgrad ${diff} inom området ${mathTheme2}.

IMPORTANT:
1. Use only pure numeric values. No mathematical functions in the data itself.
2. Generate data that is well-suited for the specified chart type and showcases interesting patterns such as linear growth, exponential change, periodic behavior, or step functions when appropriate for the problem type.
3. DO NOT PRESENT THE ANSWER IN THE GRAPH!

     Svaret ska vara ett JSON-objekt med följande struktur: 
    [
      {
        "difficulty": "${diff}",
        "problemType": "statistics|probability|functions|dataAnalysis|timeSeries|financialMath|discreteMath|setTheory|graphTheory|numericalMethods|optimization|regressionAnalysis|experimentalDesign|sampling|informationTheory|gameTheory|operationsResearch|networkAnalysis",
        "question": "Frågan på svenska",
        "chartType": "LineChart|BarChart|ScatterChart|AreaChart|PieChart",
        "chartData": [
          {
            "x": 1,
            "y": 2,
            "z": 3
          // Ytterligare datapunkter vid behov. Använd endast numeriska värden, inga matematiska funktioner.
          }
          // Fler dataobjekt vid behov
        ],
        "chartConfig": {
          "xAxisLabel": "Etikett för x-axeln",
          "yAxisLabel": "Etikett för y-axeln",
          "series": [
            {
              "dataKey": "y",
              "name": "Serie 1",
              "color": "#8884d8"
            }
            // Fler serier vid behov
          ]
        },
        "options": [
          "Alternativ A",
          "Alternativ B",
          "Alternativ C",
          "Alternativ D"
        ],
        "correctAnswer": "Det korrekta alternativet"
      }
    ]`;

        default:
          return '';
      }
    };

    // Moonshine: Eventuell ändring av MEK instruktioner:

    // return `Generera ${count} frågor för MEK-sektionen (meningskomplettering) för det svenska Högskoleprovet. Svårighetsgrad: ${diff}. Skriv allt på ${selectedLanguage} ${theme}.

    // Instruktioner för meningskomplettering:
    // 1. Varje fråga ska ha antingen en eller två luckor som ska fyllas i.
    // 2. Om det är en lucka, ska varje svarsalternativ bestå av ett ord.
    // 3. Om det är två luckor, ska varje svarsalternativ bestå av två ord, separerade med bindestreck (exempel: "kriga - anpassa").
    // 4. Variera antalet luckor mellan frågorna för att skapa variation.

    // The response should be an array of JSON objects with the following structure:
    // [{
    //   "question": "mening med en eller två luckor",
    //   "options": ["alternativ1", "alternativ2", "alternativ3", "alternativ4"],
    //   "correctAnswer": "korrektAlternativ"
    // }]`;

    const simulateProgress = (startProgress, endProgress, duration) => {
      const stepSize = 0.5;
      const steps = (endProgress - startProgress) / stepSize;
      const stepDuration = duration / steps;
      let currentProgress = startProgress;

      const intervalId = setInterval(() => {
        currentProgress += stepSize;
        if (currentProgress >= endProgress) {
          clearInterval(intervalId);
        } else {
          updateProgress(currentProgress);
        }
      }, stepDuration);

      return () => clearInterval(intervalId);
    };

    try {
      const totalTypes = selectedQuestionTypes.length;
      const results = [];

      for (const [index, type] of selectedQuestionTypes.entries()) {
        setCurrentGeneratingType(type);
        setGenerationStatus(prev => ({ ...prev, [type]: 'genererar' }));

        const startProgress = (index / totalTypes) * 100;
        const endProgress = ((index + 1) / totalTypes) * 100;

        const stopSimulation = simulateProgress(startProgress, endProgress, 15000);

        const prompt = generatePrompt(type);
        let response = await callApi(
          [
            {
              role: 'system',
              content:
                'Du är en AI-assistent som genererar frågor för det svenska Högskoleprovet för att hjälpa elever träna på simulerade frågor. Respond with only the JSON array, no additional text.',
            },
            { role: 'user', content: prompt },
          ],
          undefined,
          undefined,
          user.uid
        );

        stopSimulation();

        //Logger.log("response: ", response);
        // Moonshine
        // let parsedQuestions;
        // try {
        //   const jsonString = extractJsonFromResponse(response);
        //   parsedQuestions = JSON.parse(jsonString);
        // } catch (parseError) {
        //   console.error(`Error parsing JSON for ${type}:`, parseError);
        //   Logger.log('Raw response:', response);
        //   throw new Error(`Failed to parse JSON for ${type}`);
        // }

        Logger.log('Raw response type:', typeof response);
        Logger.log('Raw response:', response);

        if (typeof response === 'string') {
          if (response.startsWith('```json')) {
            response = response.slice(7, -3).trim();
            Logger.log('removed 3 characters from json');
          } else if (response.startsWith('```')) {
            response = response.slice(3, -3).trim();
          }
        }

        // let extractedJson;
        // try {
        //   extractedJson = extractJsonFromResponse(response);
        //   Logger.log("Extracted JSON type:", typeof extractedJson);
        //   Logger.log("Extracted JSON:", extractedJson);
        // } catch (extractError) {
        //   console.error("Error in extractJsonFromResponse:", extractError);
        // }

        let parsedQuestions;
        if (type === 'XYZ') {
          try {
            parsedQuestions = parseDTKQuestions(response);
            Logger.log('Parsed DTK questions:', parsedQuestions);
          } catch (error) {
            console.error(`Error processing DTK questions:`, error);
            Logger.log('Raw response:', response);
            throw new Error(`Failed to process DTK questions`);
          }
        } else {
          // Use the existing extractJsonFromResponse for other question types
          parsedQuestions = extractJsonFromResponse(response);
        }

        setGenerationStatus(prev => ({ ...prev, [type]: 'klar' }));
        results.push({ questionType: type, questions: parsedQuestions });

        updateProgress(endProgress);
      }

      const finalJson = {
        questions: [],
      };

      results.forEach(result => {
        if (result.questionType === 'LÄS' || result.questionType === 'ELF') {
          result.questions.forEach(q => {
            finalJson.questions.push({
              questionType: result.questionType,
              text: q.text,
              questions: q.questions,
            });
          });
        } else if (result.questionType === 'XYZ') {
          finalJson.questions.push({
            questionType: 'XYZ',
            questions: result.questions,
          });
        } else {
          finalJson.questions.push(result);
        }
      });

      //   const newTestRef = await addDoc(collection(db, 'hpTestData'), {
      //     uid: user.uid,
      //     name: `Hp-${Date.now()}`,
      //     data: finalJson
      //   });

      //   const hpPublicData = {
      //     difficulty: difficulty,
      //     selectedThemes: selectedThemes,
      //     selectedLanguage: selectedLanguage,
      //     selectedModel: selectedModel,
      //     originallyCreatedByUserId: user.uid,
      //     refToOriginalTestId: newTestRef.id,
      //     official: false,
      //      createdAt: Date.now(),
      //     name: `Hp-${Date.now()}`,
      //     data: finalJson,
      //       upvotes: 0,
      // downvotes: 0,
      // userVotes: {}
      //   };

      //   const hpPublicDocRef = await addDoc(collection(db, 'hpPublic'), hpPublicData);

      const generate6DigitId = () => {
        const fullUuid = uuidv4();
        //moonshine regex replace
        const numbers = fullUuid.replace(/[^0-9]/g, '');
        return numbers.slice(0, 6);
      };

      const sixDigitId = generate6DigitId();
      const name = `Hp-${sixDigitId}`;

      // First, create a new document reference with a specific ID
      const newDocId = doc(collection(db, 'hpTestData')).id;

      // Use this ID to add the document to hpTestData
      const newTestRef = await setDoc(doc(db, 'hpTestData', newDocId), {
        uid: user.uid,
        name: name,
        data: finalJson,
      });

      const hpPublicData = {
        difficulty: difficulty,
        selectedThemes: selectedThemes,
        selectedLanguage: selectedLanguage,
        selectedModel: selectedModel,
        originallyCreatedByUserId: user.uid,
        refToOriginalTestId: newDocId,
        official: false,
        createdAt: Date.now(),
        name: name,
        data: finalJson,
        upvotes: 0,
        downvotes: 0,
        userVotes: {},
      };

      // Use the same ID to add the document to hpPublic
      const hpPublicDocRef = await setDoc(doc(db, 'hpPublic', newDocId), hpPublicData);

      const newGeneratedTest = {
        id: newDocId,
        name: name,
        data: finalJson,
      };

      setDynamicTitle(`${hpPublicData.name} — Öva på högskoleprovet — Studera.AI`);

      Logger.log(JSON.stringify(hpPublicData, null, 2));
      Logger.log('finalJson: ', JSON.stringify(finalJson, null, 2));

      setGeneratedTests(prevTests => [...prevTests, newGeneratedTest]);
      setGeneratedTestId(newDocId);

      //    setShowOverlay(true);

      updateProgress(0);
      handleSelectTest(newDocId);
      setGeneratedTestId('');

      // await new Promise(resolve => setTimeout(resolve, 1500));
      // setShowOverlay(false);

      navigate(`/app/hp/${newDocId}`);
    } catch (error) {
      console.error('Error generating questions:', error);
      showSnackbar(
        `Nåt gick fel när AI-modellen genererade frågorna. Det kan ske ibland. Klicka på Generera igen.`,
        'info',
        5000
      );
      setGenerationStatus(prev => ({ ...prev, error: error.message }));
    } finally {
      setIsGenerating(false);
      updateProgress(100);
      setCurrentGeneratingType('');
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [aiResponse]);

  // Ändra availableTests till allTests?
  useEffect(() => {
    if (availableTests.length > 0 && !selectedTestId) {
      setSelectedTestId(availableTests[0].id);
    }
  }, []);

  useEffect(() => {
    if (user) {
      fetchUserProgress().then(setUserProgress);
    }
  }, [user]);

  useEffect(() => {
    if (drawerOpen) {
      fetchUserProgress().then(setUserProgress);
    }
  }, [drawerOpen]);

  function getFirst6Digits(timestamp) {
    // Convert the timestamp to a string and take the first 6 characters
    return timestamp.toString().slice(0, 6);
  }

  const fetchUserProgress = async () => {
    if (!user) return [];
    try {
      const q = query(collection(db, 'hpTestProgress'), where('userId', '==', user.uid));
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map(doc => {
        const data = doc.data();
        const test = allTests.find(t => t.id === data.testId);
        const answeredQuestionsCount = data.results ? data.results.length : 0;
        Logger.log(`Test ${data.testId}:`, {
          results: data.results,
          answeredQuestionsCount,
          totalQuestions: data.totalQuestions || test?.questions?.length || 'unknown',
        });
        return {
          ...data,
          testName: test ? test.name : 'Okänt test',
          testId: data.testId,
          answeredQuestionsCount,
          totalQuestions: data.totalQuestions || test?.questions?.length || 0,
        };
      });
    } catch (error) {
      console.error('Error fetching user progress:', error);
      return [];
    }
  };

  useEffect(() => {
    Logger.log('selectedTestId changed to:', selectedTestId);
  }, [selectedTestId]);

  useEffect(() => {
    const fetchTestData = async () => {
      if (selectedTestId) {
        try {
          const testDoc = await getDoc(doc(db, 'hpTestData', selectedTestId));
          if (testDoc.exists()) {
            //saveProgress();
            const testData = testDoc.data();
            setCurrentHpName(testData.name);
            Logger.log('currentHpName: ', currentHpName);
            setHpTest(testData.data.questions);
            Logger.log('Set hpTest to:', testData);

            const titlePrefix = testData.official
              ? 'Gammalt högskoleprov'
              : 'AI-genererat högskoleprov';
            const descriptionPrefix = testData.official ? 'Öva på gammalt' : 'Öva på AI-genererat';

            setDynamicTitle(`${testData.name} — ${titlePrefix} — Studera.AI`);
            setDynamicDescription(`${descriptionPrefix} högskoleprov: ${testData.name}`);
          } else {
            Logger.log('No test found in database with the ID:', selectedTestId);

            //saveProgress();
            const fallbackData = getHpDataById(selectedTestId);

            setHpTest(fallbackData.questions);
            //Logger.log("Set hpTest to:", fallbackData.questions);
          }
        } catch (error) {
          console.error('Error loading HP test data:', error);
        }
      } else {
        Logger.log('No test selected yet or test not in progress');
      }
    };

    fetchTestData();
  }, [selectedTestId, testInProgress]);

  const calculateTotalQuestions = testData => {
    Logger.log('Calculating total questions for:', testData);
    const total = testData.reduce((total, section) => {
      //Logger.log("Section:", section);
      return total + (section.questions ? section.questions.length : 0);
    }, 0);
    Logger.log('Calculated total questions:', total);
    return total;
  };

  const handleSaveQuestion = () => {
    setResults(prevResults => {
      const existingIndex = prevResults.findIndex(
        r => r.sectionIndex === currentSectionIndex && r.questionIndex === currentQuestionIndex
      );

      let updatedResults;
      if (existingIndex !== -1) {
        // If the question is already in results, update its 'saved' status
        updatedResults = [...prevResults];
        updatedResults[existingIndex] = {
          ...updatedResults[existingIndex],
          saved: true,
        };
      } else {
        // If the question is not in results, add it with 'saved' status
        const newResult = {
          sectionIndex: currentSectionIndex,
          questionIndex: currentQuestionIndex,
          questionType: hpTest[currentSectionIndex].questionType,
          question: hpTest[currentSectionIndex].questions[currentQuestionIndex].question,
          saved: true,
        };
        updatedResults = [...prevResults, newResult];
      }

      // Call saveProgress with the updated results
      saveProgress({ results: updatedResults });
      showSnackbar('Fråga sparad!', 'success');
      return updatedResults;
    });

    // Set isSaved to true and schedule it to be set back to false after 3 seconds
    setIsSaved(true);
    setTimeout(() => setIsSaved(false), 3000);
  };

  const saveProgress = async (updatedState = {}) => {
    if (!user || !selectedTestId || !hpTest) {
      console.error('Missing required data for saving progress:', { user, selectedTestId, hpTest });
      return;
    }

    const totalQuestions = calculateTotalQuestions(hpTest);
    const answeredQuestionsCount = results.length;

    const progressData = {
      userId: user.uid,
      testId: selectedTestId,
      testName: currentHpName,
      currentSectionIndex,
      currentQuestionIndex,
      results,
      timeLeft: timeLeftRef.current,
      testInProgress: answeredQuestionsCount < totalQuestions,
      totalQuestions,
      lastUpdated: new Date(),
      ...updatedState,
    };

    try {
      Logger.log('Saving progress with data:', progressData);
      await setDoc(doc(db, 'hpTestProgress', `${user.uid}_${selectedTestId}`), progressData);
    } catch (error) {
      console.error('Error saving progress:', error);
    }
  };

  const loadProgress = async testId => {
    Logger.log('Loading progress for testId:', testId);
    if (!user) return;

    try {
      const docRef = doc(db, 'hpTestProgress', `${user.uid}_${testId}`);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        Logger.log('Loaded progress data:', data);

        // Fetch test data
        let testData = await getTestData(testId);

        const maxSectionIndex = testData.questions.length - 1;

        setCurrentSectionIndex(Math.min(data.currentSectionIndex, maxSectionIndex));
        setCurrentQuestionIndex(data.currentQuestionIndex);
        setResults(data.results);
        setTimeLeft(data.timeLeft);
        setTestInProgress(data.testInProgress);

        setHpTest(testData.questions);

        const titlePrefix = testData.official
          ? 'Gammalt högskoleprov'
          : 'AI-genererat högskoleprov';
        const descriptionPrefix = testData.official ? 'Öva på gammalt' : 'Öva på AI-genererat';

        setDynamicTitle(`${data.testName} — ${titlePrefix} — Studera.AI`);
        setDynamicDescription(`${descriptionPrefix} högskoleprov: ${data.testName}`);
      } else {
        Logger.log('No existing progress found for this test');
        // Set initial state
        setCurrentSectionIndex(0);
        setCurrentQuestionIndex(0);
        setResults([]);
        setTimeLeft(55 * 60);
        setTestInProgress(true);
      }
    } catch (error) {
      console.error('Error loading progress:', error);
    }
  };

  const getTestData = async testId => {
    const testDocRef = doc(db, 'hpTestData', testId);
    const testDocSnap = await getDoc(testDocRef);

    if (testDocSnap.exists()) {
      return testDocSnap.data().data;
    } else {
      return getHpDataById(testId);
    }
  };

  const resetTestState = () => {
    setCurrentSectionIndex(0);
    setCurrentQuestionIndex(0);
    setResults([]);
    setTimeLeft(55 * 60);
    setTestInProgress(false);
  };

  const handleSelectTest = async selectedTestId => {
    setIsTestLoading(true);
    Logger.log('Selected test:', selectedTestId);
    if (!selectedTestId) {
      console.error('No testId provided to handleSelectTest');
      return;
    }

    setSelectedTestId(selectedTestId);
    Logger.log('Set selectedTestId to:', selectedTestId);

    setShowTestSelector(false);
    setCurrentSectionIndex(0);
    setCurrentQuestionIndex(0);

    await loadProgress(selectedTestId);

    const docRef = doc(db, 'hpTestProgress', `${user.uid}_${selectedTestId}`);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      Logger.log('No existing progress found, starting new test');
      startTest(selectedTestId);
    } else {
      Logger.log('Existing progress found, loaded');
      setTestInProgress(true);
    }

    setIsTestLoading(false);
    //navigate(`/app/hp/${selectedTestId}`);
  };

  const handleBackToSelector = async () => {
    setIsTestLoading(true);
    setShowTestSelector(true);
    setTestInProgress(false);
    setShowSummary(false);
    setCurrentSectionIndex(0);
    setCurrentQuestionIndex(0);
    setResults([]);
    setSelectedOption(null);
    setSelectedTestId(null);
    setProgress(0);

    // Hämta den senaste användarframstegen
    const updatedProgress = await fetchUserProgress();
    setUserProgress(updatedProgress);

    // Tvinga en omrendering av vyn
    setRefreshKey(prevKey => prevKey + 1);

    setIsTestLoading(false);

    navigate('/app/hp');
  };

  const handleOpenOverview = async () => {
    setIsOverviewOpen(true);
    setOverviewLoading(true);
    // Moonshine, dont know if i can comment out loadprogress
    //await loadProgress(selectedTestId);
    //Logger.log('hpTest structure:', JSON.stringify(hpTest, null, 2));

    setOverviewLoading(false);
  };

  const handleCloseOverview = () => {
    setIsOverviewOpen(false);
  };
  useEffect(() => {
    Logger.log('Current progress:', progress);
  }, [progress]);

  useEffect(() => {
    const updateTimerDisplay = () => {
      if (timerDisplayRef.current) {
        const minutes = Math.floor(timeLeftRef.current / 60);
        const seconds = timeLeftRef.current % 60;
        timerDisplayRef.current.textContent = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
      }
    };

    const updateTimer = () => {
      if (timeLeftRef.current > 0) {
        timeLeftRef.current -= 1;
        updateTimerDisplay();
      } else {
        handleFinishTest();
        clearInterval(timerRef.current);
      }
    };

    if (testInProgress) {
      timerRef.current = setInterval(updateTimer, 1000);
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [testInProgress]);

  useEffect(() => {
    if (testInProgress) {
      saveProgress();
    }
  }, [answeredQuestions, results]);

  const handleAskAI = () => {
    Logger.log('Opening AI Dialog from handleAskAI');
    setAiDialogOpen(true);
  };

  useEffect(() => {
    Logger.log('aiDialogOpen useEffect: ', aiDialogOpen);
  }, [aiDialogOpen]);

  useEffect(() => {
    const handleKeyDown = event => {
      Logger.log('isAIDialogOpen handleKeyDown: ', aiDialogOpen);
      if (aiDialogOpen) {
        return;
      }

      if (!testInProgress) return;

      const currentSection = hpTest[currentSectionIndex];
      const currentQuestion = currentSection.questions[currentQuestionIndex];
      switch (event.key) {
        case '1':
        case '2':
        case '3':
        case '4':
        case '5':
          // Select option based on key press
          const optionIndex = parseInt(event.key) - 1;
          if (optionIndex >= 0 && optionIndex < currentQuestion.options.length) {
            handleOptionSelect(currentQuestion.options[optionIndex]);
          }
          break;
        case ' ':
        case 'Enter':
          // Move to next question with spacebar or enter
          event.preventDefault();
          if (selectedOption) {
            handleNextQuestion();
          }
          break;
        case 'ArrowUp':
        case 'ArrowDown':
          event.preventDefault();
          const currentOptions = currentQuestion.options;
          const currentIndex = selectedOption ? currentOptions.indexOf(selectedOption) : -1;
          let newIndex;
          if (event.key === 'ArrowUp') {
            newIndex = currentIndex > 0 ? currentIndex - 1 : currentOptions.length - 1;
          } else {
            newIndex = currentIndex < currentOptions.length - 1 ? currentIndex + 1 : 0;
          }
          handleOptionSelect(currentOptions[newIndex]);
          break;

        case 'ArrowLeft':
          handlePreviousQuestion();
          break;
        case 'ArrowRight':
          handleNextQuestion();

          break;
        case 'Ö':
        case 'ö':
          handleOpenOverview();
          break;

        case 'S':
        case 's':
          handleSaveQuestion();

          break;
        case 'F':
        case 'f':
          handleAskAI();
        default:
          break;
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [
    currentSectionIndex,
    currentQuestionIndex,
    hpTest,
    testInProgress,
    selectedOption,
    aiDialogOpen,
  ]);

  const startTest = testId => {
    setTestInProgress(true);
    setShowTestSelector(false);
    setTimeLeft(55 * 60);

    navigate(`/app/hp/${testId}`);
  };

  const handleOptionSelect = option => {
    nextSound.play();
    setSelectedOption(option);
  };

  const getPreviouslySelectedOption = (sectionIndex, questionIndex) => {
    const questionKey = `${sectionIndex}-${questionIndex}`;

    try {
      if (!hpTest[sectionIndex] || !hpTest[sectionIndex].questions[questionIndex]) {
        throw new Error(`Invalid section or question index: ${questionKey}`);
      }

      const currentQuestion = hpTest[sectionIndex].questions[questionIndex].question;

      if (currentQuestion === undefined) {
        console.warn(`Question is undefined for ${questionKey}`);
        return null;
      }

      const result = results.find(
        r => r.questionType === hpTest[sectionIndex].questionType && r.question === currentQuestion
      );

      return result ? result.selectedOption : null;
    } catch (error) {
      console.error(`Error in getPreviouslySelectedOption: ${error.message}`);
      return null;
    }
  };

  // DETTA FUNKAR JUST NU MEN KANSKE STÄLLER TILL PROBS SENARE. MOONSHINE.
  useEffect(() => {
    if (hpTest) {
      const previouslySelectedOption = getPreviouslySelectedOption(
        currentSectionIndex,
        currentQuestionIndex
      );
      setSelectedOption(previouslySelectedOption);
    }
  }, [currentSectionIndex, currentQuestionIndex]);

  const jumpToQuestion = (sectionIndex, questionIndex) => {
    selectSound.play();
    setCurrentSectionIndex(sectionIndex);
    setCurrentQuestionIndex(questionIndex);
    const previouslySelectedOption = getPreviouslySelectedOption(sectionIndex, questionIndex);
    setSelectedOption(previouslySelectedOption);
    handleCloseOverview();
  };

  const handlePreviousQuestion = () => {
    selectSound.play();

    if (currentSectionIndex === 0 && currentQuestionIndex === 0) {
      // Om vi är på första frågan i första sektionen, gör ingenting
      return;
    }

    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    } else if (currentSectionIndex > 0) {
      setCurrentSectionIndex(currentSectionIndex - 1);
      setCurrentQuestionIndex(hpTest[currentSectionIndex - 1].questions.length - 1);
    }

    const previousSelectedOption = getPreviouslySelectedOption(
      currentSectionIndex > 0 ? currentSectionIndex - 1 : currentSectionIndex,
      currentQuestionIndex > 0
        ? currentQuestionIndex - 1
        : hpTest[currentSectionIndex - 1].questions.length - 1
    );

    Logger.log('jumpToQuestion: previousSelectedOption: ', previousSelectedOption);
    Logger.log('jumpToQuestion: selectedOption: ', selectedOption);
    setSelectedOption(previousSelectedOption);
  };

  const handleDeleteProgress = async testId => {
    if (!user) return;

    try {
      // Delete from hpTestProgress
      await deleteDoc(doc(db, 'hpTestProgress', `${user.uid}_${testId}`));

      // Delete from hpTestResults
      const resultsQuery = query(
        collection(db, 'hpTestResults'),
        where('userId', '==', user.uid),
        where('testId', '==', testId)
      );
      const resultsSnapshot = await getDocs(resultsQuery);
      resultsSnapshot.forEach(async doc => {
        await deleteDoc(doc.ref);
      });

      // Update local state
      setUserProgress(prevProgress => prevProgress.filter(p => p.testId !== testId));

      // Reset other relevant state variables
      if (selectedTestId === testId) {
        setAnsweredQuestions({});
        setSavedQuestions({});
        setResults([]);
        setCurrentSectionIndex(0);
        setCurrentQuestionIndex(0);
        setSelectedOption(null);
        setTimeLeft(55 * 60);
        setTestInProgress(false);
        setShowSummary(false);
      }

      Logger.log(`Progress for test ${testId} has been fully deleted`);
    } catch (error) {
      console.error('Error deleting progress:', error);
    }
  };

  const handleNextQuestion = () => {
    Logger.log('Handling next question. Current state:', {
      currentSectionIndex,
      currentQuestionIndex,
      selectedOption,
    });
    selectSound.play();
    const currentSection = hpTest[currentSectionIndex];
    const currentQuestion = currentSection.questions[currentQuestionIndex];

    if (selectedOption) {
      setResults(prev => {
        const existingIndex = prev.findIndex(
          r => r.sectionIndex === currentSectionIndex && r.questionIndex === currentQuestionIndex
        );
        const newResult = {
          sectionIndex: currentSectionIndex,
          questionIndex: currentQuestionIndex,
          questionType: currentSection.questionType,
          question: currentQuestion.question,
          selectedOption,
          correctAnswer: currentQuestion.correctAnswer,
          isCorrect: selectedOption === currentQuestion.correctAnswer,
        };

        if (existingIndex !== -1) {
          const updated = [...prev];
          updated[existingIndex] = newResult;
          return updated;
        } else {
          return [...prev, newResult];
        }
      });

      saveProgress();

      if (
        currentSectionIndex === hpTest.length - 1 &&
        currentQuestionIndex === currentSection.questions.length - 1
      ) {
        Logger.log('Reached last question. Results:', results);
        setTimeout(() => handleFinishTest(), 0);
        return;
      }
    }

    // Move to next question
    if (currentQuestionIndex < currentSection.questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else if (currentSectionIndex < hpTest.length - 1) {
      setCurrentSectionIndex(currentSectionIndex + 1);
      setCurrentQuestionIndex(0);
    }

    const nextSelectedOption = getPreviouslySelectedOption(
      currentSectionIndex,
      currentQuestionIndex < currentSection.questions.length - 1 ? currentQuestionIndex + 1 : 0
    );
    setSelectedOption(nextSelectedOption);
  };

  const questionTypeDescriptions = {
    ORD: 'Ordförståelse',
    LÄS: 'Läsförståelse',
    MEK: 'Meningskomplettering',
    ELF: 'Engelsk läsförståelse',
  };

  const handleFinishTest = () => {
    const totalQuestions = calculateTotalQuestions(hpTest);
    const answeredCount = Object.values(answeredQuestions).filter(Boolean).length;

    Logger.log(`Total questions: ${totalQuestions}, Answered: ${answeredCount}`);

    // if (!allAnswered) {
    //   setIncompleteDialogOpen(true);
    //   return;
    // }

    const finalTimeLeft = timeLeftRef.current;
    Logger.log('Finishing test. Final timeLeft:', finalTimeLeft);

    // Ensure we have the latest results
    setResults(prevResults => {
      Logger.log('Final Results:', prevResults);
      setShowSummary(true);
      setTestInProgress(false);
      saveResultsToDatabase(prevResults);
      doneSound.play();
      return prevResults;
    });
  };

  const saveResultsToDatabase = async resultsToSave => {
    // try {
    //   await addDoc(collection(db, 'hpTestResults'), {
    //     userId: user.uid,
    //     results: resultsToSave,
    //     date: new Date(),
    //     totalTime: 55 * 60 - timeLeft
    //   });
    // } catch (error) {
    //   console.error('Error saving HP test results:', error);
    // }
  };

  const sanitizeLatex = equation => {
    return (
      equation
        // Replace common Unicode characters with their LaTeX equivalents
        .replace(/[¹²³⁴⁵⁶⁷⁸⁹⁰]/g, match => `^{${match}}`)
        .replace(/[ᵃᵇᶜᵈᵉᶠᵍʰⁱʲᵏˡᵐⁿᵒᵖ]/g, match => `^{${match.normalize('NFKD')}}`)
        .replace(/[₁₂₃₄₅₆₇₈₉₀]/g, match => `_{${match}}`)
        .replace(/[ₐₑᵢₒᵤ]/g, match => `_{${match.normalize('NFKD')}}`)
        .replace(/×/g, '\\times')
        .replace(/÷/g, '\\div')
        .replace(/±/g, '\\pm')
        .replace(/∓/g, '\\mp')
        .replace(/≤/g, '\\leq')
        .replace(/≥/g, '\\geq')
        .replace(/≠/g, '\\neq')
        .replace(/≈/g, '\\approx')
        .replace(/∞/g, '\\infty')
        .replace(/∫/g, '\\int')
        .replace(/∑/g, '\\sum')
        .replace(/∏/g, '\\prod')
        .replace(/√/g, '\\sqrt')
        .replace(/∛/g, '\\sqrt[3]')
        .replace(/π/g, '\\pi')
        .replace(/θ/g, '\\theta')
        .replace(/α/g, '\\alpha')
        .replace(/β/g, '\\beta')
        .replace(/γ/g, '\\gamma')
        .replace(/δ/g, '\\delta')
        .replace(/ε/g, '\\epsilon')
        .replace(/λ/g, '\\lambda')
        .replace(/μ/g, '\\mu')
        .replace(/σ/g, '\\sigma')
        .replace(/τ/g, '\\tau')
        .replace(/ω/g, '\\omega')
        .replace(/Δ/g, '\\Delta')
        .replace(/Σ/g, '\\Sigma')
        .replace(/Π/g, '\\Pi')
        .replace(/Ω/g, '\\Omega')
        // Handle potential encoding issues
        .replace(/�rac/g, '\\frac')
        .replace(/imes/g, '\\times')
        .replace(/�/g, '\\')
        // Ensure proper spacing around operators
        .replace(/([0-9a-zA-Z}])([\+\-\*\/])/g, '$1 $2 ')
        .replace(/([\+\-\*\/])([0-9a-zA-Z{])/g, '$1 $2')
        // Remove any remaining non-printable characters
        .replace(/[^\x20-\x7E]/g, '')
    );
  };

  const renderQuestion = () => {
    if (
      currentSectionIndex >= hpTest.length ||
      currentQuestionIndex >= hpTest[currentSectionIndex].questions.length
    ) {
      return null;
    }

    const currentSection = hpTest[currentSectionIndex];
    const currentQuestion = currentSection.questions[currentQuestionIndex];

    const answeredQuestion = results.find(
      r => r.sectionIndex === currentSectionIndex && r.questionIndex === currentQuestionIndex
    );

    const optionIcons = [LooksOneIcon, LooksTwoIcon, Looks3Icon, Looks4Icon, Looks5Icon];

    let width = '50%';
    if (currentSection.questionType === 'LÄS' || currentSection.questionType === 'ELF') {
      width = '100%';
    }

    const renderOptions = () => (
      <Box sx={{ display: 'flex', flexDirection: 'column', width: { xs: '100%', md: width } }}>
        {currentQuestion.options.map((option, index) => {
          const OptionIcon = optionIcons[index];
          return (
            <OptionButton
              key={index}
              variant={selectedOption === option ? 'contained' : 'outlined'}
              onClick={() => handleOptionSelect(option)}
              startIcon={<OptionIcon />}
            >
              {option}
            </OptionButton>
          );
        })}
      </Box>
    );

    if (currentSection.questionType === 'DTK') {
      // Render chart-based question
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
          <Box
            sx={{
              mb: 3,
              mt: 4,
              textAlign: 'center',
              border: theme.palette.mode === 'dark' ? '1px solid #4a5d6b' : '1px solid #eaeaea',
              fontWeight: 'bold',
              borderRadius: '20px',
              backgroundColor: theme.palette.mode === 'dark' ? '#3a454f' : 'white',
              width: { xs: '100%', md: '50%' },
              padding: '40px',
              color: theme.palette.mode === 'dark' ? 'white' : '#333',
            }}
          >
            <Typography variant='body1' sx={{ marginBottom: '25px' }}>
              {currentQuestion.question}
            </Typography>
            <DynamicMathChart
              chartType={currentQuestion.chartType}
              chartData={currentQuestion.chartData}
              chartConfig={currentQuestion.chartConfig}
            />
          </Box>

          {renderOptions()}
          <div className={`focus-toggle ${focusModeActive ? 'focus-active' : ''}`}>
            <QuestionNavigation
              handlePreviousQuestion={handlePreviousQuestion}
              handleNextQuestion={handleNextQuestion}
              handleSaveQuestion={handleSaveQuestion}
              handleAskAI={handleAskAI}
              isSaved={isSaved}
              currentQuestionIndex={currentQuestionIndex}
              currentSectionIndex={currentSectionIndex}
              theme={theme}
              styles={styles}
            />
            <Box sx={{ width: { xs: '100%', md: '80%', margin: '0 auto' } }}>
              <RecommendationComponent
                itemId={testId}
                userId={user.uid}
                collectionName='hpPublic'
                questionText='Skulle du rekommendera detta högskoleprov till andra användare?'
              />
            </Box>

            {/*
              <TestRecommendation testId={testId} userId={user.uid} />
              */}
          </div>
        </Box>
      );
    }

    if (currentSection.questionType === 'XYZ') {
      const sanitizedEquation = sanitizeLatex(currentQuestion.equation);
      Logger.log('Sanitized equation:', sanitizedEquation);

      // Kontrollera om ekvationen är lång (t.ex. över 50 tecken)
      const isLongEquation = sanitizedEquation.length > 50;

      // Funktion för att konvertera ekvationen till vertikalt format
      const convertToVertical = eq => {
        return `\\begin{align*}\n${eq.replace(/=/g, '&=')}\n\\end{align*}`;
      };

      const displayEquation = isLongEquation
        ? convertToVertical(sanitizedEquation)
        : sanitizedEquation;

      return (
        <MathJaxContext>
          <Box
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}
          >
            <Box
              sx={{
                mb: 3,
                mt: 4,
                textAlign: 'center',
                border: theme.palette.mode === 'dark' ? '1px solid #4a5d6b' : '1px solid #eaeaea',
                fontWeight: 'bold',
                borderRadius: '20px',
                backgroundColor: theme.palette.mode === 'dark' ? '#3a454f' : 'white',
                width: { xs: '100%', md: '50%' },
                padding: '40px',
                color: theme.palette.mode === 'dark' ? 'white' : '#333',
              }}
            >
              <Typography variant='body1' sx={{ marginBottom: '25px' }}>
                {currentQuestion.question}
              </Typography>
              <span style={{ fontSize: '30px' }}>
                <MathJax
                  key={displayEquation}
                  hideUntilTypeset='first'
                >{`\\[${displayEquation}\\]`}</MathJax>
              </span>
            </Box>
            {renderOptions()}
            <div className={`focus-toggle ${focusModeActive ? 'focus-active' : ''}`}>
              <QuestionNavigation
                handlePreviousQuestion={handlePreviousQuestion}
                handleNextQuestion={handleNextQuestion}
                handleSaveQuestion={handleSaveQuestion}
                handleAskAI={handleAskAI}
                isSaved={isSaved}
                currentQuestionIndex={currentQuestionIndex}
                currentSectionIndex={currentSectionIndex}
                theme={theme}
                styles={styles}
              />

              <Box
                sx={{ width: { xs: '100%', md: 'auto', margin: '0 auto', textAlign: 'center' } }}
              >
                <RecommendationComponent
                  itemId={testId}
                  userId={user.uid}
                  collectionName='hpPublic'
                  questionText='Skulle du rekommendera detta högskoleprov till andra användare?'
                />
              </Box>

              {/*        <TestRecommendation testId={testId} userId={user.uid} />  */}
            </div>
          </Box>
        </MathJaxContext>
      );
    } else if (currentSection.questionType === 'LÄS' || currentSection.questionType === 'ELF') {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            width: '100%',
          }}
        >
          <Box
            sx={{
              flex: { xs: '0 0 100%', sm: 1 },
              pr: { xs: 0, sm: 2 },
              mb: { xs: 2, sm: 0 },
            }}
          >
            <Typography
              variant='body1'
              sx={{
                whiteSpace: 'pre-wrap',
                lineHeight: '30px',
                marginTop: hpTest.official ? '0px' : '30px',
              }}
            >
              <span dangerouslySetInnerHTML={{ __html: currentSection.text }} />
            </Typography>
          </Box>
          <Box
            sx={{
              flex: { xs: '0 0 100%', sm: 1 },
              pl: { xs: 0, sm: 2 },
            }}
          >
            <Typography variant='h6' sx={{ mb: 3, mt: 3 }}>
              {currentQuestion.question}
            </Typography>
            {renderOptions()}

            <div className={`focus-toggle ${focusModeActive ? 'focus-active' : ''}`}>
              <QuestionNavigation
                handlePreviousQuestion={handlePreviousQuestion}
                handleNextQuestion={handleNextQuestion}
                handleSaveQuestion={handleSaveQuestion}
                handleAskAI={handleAskAI}
                isSaved={isSaved}
                currentQuestionIndex={currentQuestionIndex}
                currentSectionIndex={currentSectionIndex}
                theme={theme}
                styles={styles}
              />

              <Box
                sx={{ width: { xs: '100%', md: 'auto', margin: '0 auto', textAlign: 'center' } }}
              >
                <RecommendationComponent
                  itemId={testId}
                  userId={user.uid}
                  collectionName='hpPublic'
                  questionText='Skulle du rekommendera detta högskoleprov till andra användare?'
                />
              </Box>
            </div>
          </Box>
        </Box>
      );
    } else {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
          <Box
            sx={{
              mb: 3,
              mt: 4,
              textAlign: 'center',
              position: 'relative',
              border: theme.palette.mode === 'dark' ? '1px solid #4a5d6b' : '1px solid #eaeaea',
              fontWeight: 'bold',
              borderRadius: '20px',
              backgroundColor: theme.palette.mode === 'dark' ? '#3a454f' : 'white',
              width: { xs: '100%', md: '50%' },
              padding: '40px',
              color: theme.palette.mode === 'dark' ? 'white' : '#333',
            }}
          >
            <Box sx={{ position: 'absolute', top: 5, right: 5, zIndex: 1 }}>
              <Voice
                key={`${currentSectionIndex}-${currentQuestionIndex}`}
                content={currentQuestion.question}
                fontSize={20}
                userId={user.uid}
              />
            </Box>

            {currentQuestion.question}
          </Box>
          {renderOptions()}
          <div className={`focus-toggle ${focusModeActive ? 'focus-active' : ''}`}>
            <QuestionNavigation
              handlePreviousQuestion={handlePreviousQuestion}
              handleNextQuestion={handleNextQuestion}
              handleSaveQuestion={handleSaveQuestion}
              handleAskAI={handleAskAI}
              isSaved={isSaved}
              currentQuestionIndex={currentQuestionIndex}
              currentSectionIndex={currentSectionIndex}
              theme={theme}
              styles={styles}
            />

            <Box sx={{ width: { xs: '100%', md: 'auto', margin: '0 auto', textAlign: 'center' } }}>
              <RecommendationComponent
                itemId={testId}
                userId={user.uid}
                collectionName='hpPublic'
                questionText='Skulle du rekommendera detta högskoleprov till andra användare?'
              />
            </Box>

            {/*<TestRecommendation testId={testId} userId={user.uid} />*/}
          </div>
        </Box>
      );
    }
  };

  if (!hpTest || isTestLoading) {
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}
      >
        <LoadingSpinner />
      </Box>
    );
  }

  return (
    <>
      <Helmet>
        <title>{dynamicTitle}</title>
        <meta name='description' content={dynamicDescription} />
      </Helmet>

      <Box sx={styles.container}>
        <Box sx={styles.innerContainer}>
          {showOverlay && (
            <Box
              sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 9999,
              }}
            >
              <CircularProgress size={60} sx={{ color: 'white' }} />
              <Typography variant='h5' sx={{ color: 'white', mt: 2 }}>
                Förbereder ditt högskoleprov...
              </Typography>
            </Box>
          )}

          <Typography variant='h1' sx={styles.header}>
            Högskoleprov
          </Typography>

          {showManualTest && (
            <Box
              sx={{
                mt: 2,
                mb: 4,
                p: 3,
                backgroundColor: theme.palette.mode === 'dark' ? '#22303C' : '#fff',
                borderRadius: '8px',
                border: theme.palette.mode === 'dark' ? '1px solid #263442' : '1px solid #e0e0e091',
              }}
            >
              <Typography variant='h5' sx={{ mb: 2 }}>
                Add Manual Test
              </Typography>
              <form onSubmit={handleManualTestSubmit}>
                <TextField
                  fullWidth
                  label='Test Name'
                  value={manualTestName}
                  onChange={e => setManualTestName(e.target.value)}
                  sx={{ mb: 2 }}
                />
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label='Test JSON'
                  value={manualTestJson}
                  onChange={e => setManualTestJson(e.target.value)}
                  sx={{ mb: 2 }}
                />
                <Button type='submit' variant='contained' color='primary'>
                  Add Test
                </Button>
              </form>
              <Button type='submit' variant='contained' color='primary' onClick={copyTest}>
                kopiera
              </Button>
            </Box>
          )}

          <AIDialog
            userId={user.uid}
            open={aiDialogOpen}
            onClose={() => {
              Logger.log('Closing AI Dialog');
              setAiDialogOpen(false);
            }}
            initialQuestion={
              hpTest[currentSectionIndex].questions[currentQuestionIndex]
                ? `Question: ${hpTest[currentSectionIndex].questions[currentQuestionIndex].question}\nOptions: ${hpTest[currentSectionIndex].questions[currentQuestionIndex].options.join(', ')}\nPlease explain this question and help me understand the answer options. Skriv på svenska.`
                : 'No question available'
            }
            systemMessage='You are a helpful AI assistant designed to explain and assist with högskoleprovet (Swedish Scholastic Aptitude Test) questions. Provide clear, pedagogical explanations using analogies and examples when appropriate. Skriv på svenska'
            theme={theme}
          />

          <IncompleteTestDialog
            open={incompleteDialogOpen}
            onClose={() => {
              setIncompleteDialogOpen(false);
              setIsOverviewOpen(true);
            }}
          />

          <Box sx={{ ...styles.buttonsContainer, ...styles.additionalButtonsContainer }}>
            {isMobile ? (
              <Button
                variant='contained'
                color='primary'
                disableRipple
                onClick={e => {
                  e.preventDefault();
                  navigate('/app/hogskoleprov');
                }}
                startIcon={
                  <ExploreIcon sx={{ color: theme.palette.mode === 'dark' ? '#fff' : '#8b5cf6' }} />
                }
                sx={styles.shrinkButton}
              ></Button>
            ) : (
              <Button
                variant='contained'
                color='primary'
                disableRipple
                onClick={e => {
                  e.preventDefault();
                  navigate('/app/hogskoleprov');
                }}
                startIcon={
                  <ExploreIcon sx={{ color: theme.palette.mode === 'dark' ? '#fff' : '#8b5cf6' }} />
                }
              >
                Alla högskoleprov
              </Button>
            )}

            <Button
              variant='contained'
              color='primary'
              disableRipple
              onClick={handleBackToSelector}
              startIcon={<AddIcon />}
              sx={styles.shrinkButton}
            />

            <Button
              variant='contained'
              color='primary'
              disableRipple
              onClick={() => setDrawerOpen(true)}
              startIcon={<SegmentIcon />}
              sx={{
                padding: '8px',
                marginRight: '0px',
                paddingLeft: '14px',
                paddingRight: '3px',
                minWidth: 'auto',
                textTransform: 'none',
              }}
            />
          </Box>

          <HPDrawerComponent
            user={user}
            theme={theme}
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}
          />

          {showTestSelector && (
            <>
              <Typography variant='h3' sx={{ ...styles.h3, mt: 5 }}>
                Generera högskoleprov med AI
              </Typography>
              <Box
                sx={{
                  mt: 2,
                  p: { xs: '12px', md: 3 },

                  backgroundColor: theme.palette.mode === 'dark' ? '#22303C' : '#fff',
                  borderRadius: { xs: '0px', md: '8px' },
                  border:
                    theme.palette.mode === 'dark' ? '1px solid #263442' : '1px solid #e0e0e091',
                }}
              >
                <form onSubmit={handleGenerateQuestions}>
                  <Tooltip title='Du måste välja minst en frågetyp.' placement='top'>
                    <Typography variant='formLabel' sx={{ fontWeight: '500' }}>
                      Välj typ av frågor
                    </Typography>
                  </Tooltip>
                  <FormGroup row>
                    {['ORD', 'LÄS', 'MEK', 'ELF', 'XYZ', 'DTK'].map(type => (
                      <QuestionTypeSelector
                        key={type}
                        type={type}
                        selectedQuestionTypes={selectedQuestionTypes}
                        handleQuestionTypeChange={handleQuestionTypeChange}
                        selectedThemes={selectedThemes}
                        handleThemeChange={handleThemeChange}
                        questionCounts={questionCounts}
                        handleQuestionCountChange={handleQuestionCountChange}
                        theme={theme}
                        questionTypeDescriptions={questionTypeDescriptions}
                      />
                    ))}
                  </FormGroup>

                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: { xs: 'column', md: 'row' },
                      gap: 2,
                      width: '100%',
                    }}
                  >
                    <Box sx={{ flex: 1 }}>
                      <Tooltip
                        title='Högskoleprovet kommer variera tämligen kraftigt beroende på vilken AI-modell du väljer.'
                        placement='top'
                      >
                        <Typography variant='formLabel' sx={{ fontWeight: '500', mt: 1 }}>
                          AI-modell:
                        </Typography>
                      </Tooltip>
                      <AIModelSelect
                        selectedModel={selectedModel}
                        setSelectedModel={setSelectedModel}
                        disabledOptions={['Gemini Pro', 'Mistral Large']}
                      />
                    </Box>

                    <FormControl sx={{ flex: 1 }}>
                      <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500', mt: 1 }}>
                        Svårighetsgrad:
                      </Typography>

                      <Select
                        value={difficulty}
                        sx={{ mt: 1 }}
                        onChange={e => setDifficulty(e.target.value)}
                      >
                        <MenuItem value='easy'>Enkel</MenuItem>
                        <MenuItem value='medium'>Medelsvår</MenuItem>
                        <MenuItem value='hard'>Svår</MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl sx={{ flex: 1 }}>
                      <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500', mt: 1 }}>
                        Språk:
                      </Typography>

                      <Select
                        sx={{ mt: 1 }}
                        value={selectedLanguage}
                        onChange={e => setSelectedLanguage(e.target.value)}
                      >
                        <MenuItem value='Svenska'>Svenska</MenuItem>
                        <MenuItem value='Engelska'>Engelska</MenuItem>
                        <MenuItem value='Tyska'>Tyska</MenuItem>
                        <MenuItem value='Franska'>Franska</MenuItem>
                        <MenuItem value='Spanska'>Spanska</MenuItem>
                        <MenuItem disabled={true} value='Arabiska'>
                          Arabiska
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>

                  <Box sx={{ mt: 3 }} className='progress-bar-container'>
                    <Button
                      type='submit'
                      variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                      color='primary'
                      disabled={isGenerating || selectedQuestionTypes.length === 0}
                      startIcon={
                        isGenerating ? (
                          <CircularProgress size={18} />
                        ) : (
                          <GenerateIcon
                            style={{ color: theme.palette.mode === 'dark' ? 'white' : '#f9f9f9' }}
                          />
                        )
                      }
                    >
                      {isGenerating ? 'Genererar...' : 'Generera högskoleprov'}
                    </Button>
                  </Box>
                </form>

                <Box
                  sx={{
                    width: '100%',
                    mb: 2,
                    mt: 4,
                    backgroundColor: theme.palette.mode === 'dark' ? '#15202b' : '#f9f9f9',
                    padding: '20px',
                    borderRadius: '10px',
                    display: isGenerating || selectedQuestionTypes.length > 0 ? 'block' : 'none',
                  }}
                >
                  {isGenerating && (
                    <>
                      <Typography
                        variant='body2'
                        color='text.secondary'
                        sx={{ ...styles.h5, mb: 1 }}
                      >
                        Genererar frågor för <strong>{currentGeneratingType}</strong>
                      </Typography>
                      <Box
                        sx={{
                          width: '100%',
                          height: '20px',
                          backgroundColor: theme.palette.mode === 'dark' ? '#22303c' : 'white',
                          borderRadius: '8px',
                          overflow: 'hidden',
                        }}
                      >
                        <Box
                          ref={progressRef}
                          sx={{
                            width: '0%',
                            height: '100%',
                            backgroundColor: theme.palette.mode === 'dark' ? '#6F38EA' : '#6339c4',
                            transition: 'width 0.1s linear',
                          }}
                        />
                      </Box>
                      <Typography
                        ref={progressPercentRef}
                        variant='body2'
                        color='text.secondary'
                        align='right'
                      >
                        0%
                      </Typography>
                    </>
                  )}

                  {selectedQuestionTypes.map(type => (
                    <Chip
                      key={type}
                      label={
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          {type}:{' '}
                          {generationStatus[type] === 'klar' ? (
                            <CheckCircleOutlineIcon fontSize='small' sx={{ ml: 0.5 }} />
                          ) : (
                            generationStatus[type] || 'Redo'
                          )}
                        </Box>
                      }
                      color={generationStatus[type] === 'klar' ? 'success' : 'default'}
                      sx={{
                        mr: 1,

                        backgroundColor: '#2b822f',
                        color: 'white',
                        fontWeight: '600',
                        animation:
                          generationStatus[type] === 'genererar'
                            ? `${pulse} 1.5s ease-in-out infinite`
                            : 'none',
                      }}
                    />
                  ))}

                  {generatedTestId && (
                    <Box sx={{ mt: 2 }}>
                      <Alert severity='success'>
                        Nytt högskoleprov genererat med ID: {generatedTestId}
                      </Alert>
                    </Box>
                  )}
                  {generationStatus.error && (
                    <Box sx={{ mt: 2 }}>
                      <Typography color='error'>
                        Knag i fogarna, försök igen: {generationStatus.error}
                      </Typography>
                    </Box>
                  )}
                </Box>

                <Box>
                  <Alert severity='warning' sx={{ mt: 2, mb: 0, borderRadius: '30px' }}>
                    <strong>BETA: </strong> Vi jobbar på att få bättre resultat från vår AI-modell.
                    (Du kommer även kunna generera de kvantitativa delarna inom kort.)
                  </Alert>
                </Box>
              </Box>
              {/* 



 )}

  Status indicators */}

              <Typography variant='h3' sx={{ ...styles.h3, mt: 7 }}>
                Dina högskoleprov
              </Typography>
              <Typography
                variant='h5'
                sx={{ ...styles.h5, display: 'inline-flex', alignItems: 'center', mt: 1 }}
              >
                <InfoIcon sx={{ mr: 1, fontSize: '17px' }} />
                De kvantitativa delarna kommer snart.
              </Typography>

              {isLoadingTests ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '200px',
                  }}
                >
                  <LoadingSpinner />
                  {/* <Typography variant="body1" sx={{ ml: 2 }}>
                  Laddar sparade högskoleprov...
                </Typography>*/}
                </Box>
              ) : (
                <TestSelector
                  onSelectTest={handleSelectTest}
                  userProgress={userProgress}
                  key={refreshKey}
                  allTests={allTests}
                  onDeleteTest={handleDeleteGeneratedTest}
                />
              )}

              <Typography variant='h3' sx={{ ...styles.h3, mt: 10 }}>
                Viktigt att känna till om AI-genererade högskoleprov
              </Typography>
              <Typography
                variant='h5'
                sx={{ ...styles.h5, display: 'inline-flex', alignItems: 'center', mt: 1 }}
              >
                <InfoIcon sx={{ mr: 1, fontSize: '17px' }} />
                Stöd för KVA, NOG och DTK kommer snart.
              </Typography>

              <Box sx={{ mb: 6, width: '100%' }}>
                <Grid container spacing={4} sx={{ mb: 6 }}>
                  <Grid item xs={12} md={6}>
                    <Box
                      sx={{
                        ...styles.newBox,
                        p: 3,
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        borderRadius: '15px',
                      }}
                    >
                      <Spellcheck
                        sx={{
                          fontSize: 50,
                          color: theme.palette.secondary.main,
                          mb: 2,
                          alignSelf: 'center',
                        }}
                      />
                      <Typography
                        variant='h6'
                        sx={{ mb: 2, textAlign: 'center', fontWeight: 'bold' }}
                      >
                        ORD
                      </Typography>
                      <Typography variant='body1'>
                        ORD testar din svenska ordförståelse. Här får du chansen att utöka ditt
                        ordförråd rejält genom att möta en mängd olika ord och uttryck.
                        AI-genererade orduppgifter ger dig en närmast oändlig mängd nytt material
                        att öva på, vilket gör att du kan bli expert på att snabbt tolka och förstå
                        olika ords betydelser.
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box
                      sx={{
                        ...styles.newBox,
                        p: 3,
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        borderRadius: '15px',
                      }}
                    >
                      <AutoStories
                        sx={{
                          fontSize: 50,
                          color: theme.palette.secondary.main,
                          mb: 2,
                          alignSelf: 'center',
                        }}
                      />
                      <Typography
                        variant='h6'
                        sx={{ mb: 2, textAlign: 'center', fontWeight: 'bold' }}
                      >
                        LÄS
                      </Typography>
                      <Typography variant='body1'>
                        LÄS är läsförståelsedelen som utmanar din förmåga att analysera texter. Med
                        AI-genererade texter får du ständigt ny, fräsch läsning som håller dig på
                        tårna. Du utvecklar snabbt förmågan att plocka ut nyckelinformation och dra
                        slutsatser från olika typer av texter - en ovärderlig färdighet inför
                        högskoleprovet.
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box
                      sx={{
                        ...styles.newBox,
                        p: 3,
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        borderRadius: '15px',
                      }}
                    >
                      <TextFields
                        sx={{
                          fontSize: 50,
                          color: theme.palette.secondary.main,
                          mb: 2,
                          alignSelf: 'center',
                        }}
                      />
                      <Typography
                        variant='h6'
                        sx={{ mb: 2, textAlign: 'center', fontWeight: 'bold' }}
                      >
                        MEK
                      </Typography>
                      <Typography variant='body1'>
                        MEK, eller meningskomplettering, skärper din språkkänsla. Genom att fylla i
                        luckor i AI-genererade meningar tränar du upp en intuitiv förståelse för
                        språkets struktur och flöde. Du blir efter ett tag väldigt bra på att snabbt
                        identifiera det ord som passar bäst i sammanhanget.
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box
                      sx={{
                        ...styles.newBox,
                        p: 3,
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        borderRadius: '15px',
                      }}
                    >
                      <Language
                        sx={{
                          fontSize: 50,
                          color: theme.palette.secondary.main,
                          mb: 2,
                          alignSelf: 'center',
                        }}
                      />
                      <Typography
                        variant='h6'
                        sx={{ mb: 2, textAlign: 'center', fontWeight: 'bold' }}
                      >
                        ELF
                      </Typography>
                      <Typography variant='body1'>
                        ELF testar din engelska läsförståelse. AI-genererade engelska texter ger dig
                        en bred exponering för olika ämnen och stilar, vilket kraftigt förbättrar
                        ditt engelska ordförråd och din läshastighet. Du blir redo att ta dig an
                        vilken engelsk text som helst med självsäkerhet.
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box
                      sx={{
                        ...styles.newBox,
                        p: 3,
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        borderRadius: '15px',
                      }}
                    >
                      <Calculate
                        sx={{
                          fontSize: 50,
                          color: theme.palette.secondary.main,
                          mb: 2,
                          alignSelf: 'center',
                        }}
                      />
                      <Typography
                        variant='h6'
                        sx={{ mb: 2, textAlign: 'center', fontWeight: 'bold' }}
                      >
                        XYZ
                      </Typography>
                      <Typography variant='body1'>
                        XYZ är den kvantitativa delen som förfinar ditt matematiska tänkande. AI:n
                        skapar en uppsjö av varierande problem som tränar din hjärna att snabbt
                        identifiera mönster och hitta smarta lösningar. Du utvecklar en matematisk
                        intuition som gör att du kan tackla även de knepigaste uppgifterna.
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box
                      sx={{
                        ...styles.newBox,
                        p: 3,
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        borderRadius: '15px',
                      }}
                    >
                      <Extension
                        sx={{
                          fontSize: 50,
                          color: theme.palette.secondary.main,
                          mb: 2,
                          alignSelf: 'center',
                        }}
                      />
                      <Typography
                        variant='h6'
                        sx={{ mb: 2, textAlign: 'center', fontWeight: 'bold' }}
                      >
                        DTK
                      </Typography>
                      <Typography variant='body1'>
                        DTK utmanar din förmåga att tolka diagram, tabeller och kartor. AI-genererat
                        material ger dig chansen att analysera en mängd olika visualiseringar,
                        vilket gör att du lär dig extrahera viktig information från grafiska
                        presentationer. En ovärderlig färdighet i dagens datadrivna värld, men inte
                        minst inför högskoleprovet.
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Box
                      sx={{
                        ...styles.newBox,
                        p: 3,
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        borderRadius: '15px',
                      }}
                    >
                      <PieChart
                        sx={{
                          fontSize: 50,
                          color: theme.palette.secondary.main,
                          mb: 2,
                          alignSelf: 'center',
                        }}
                      />
                      <Typography
                        variant='h6'
                        sx={{ mb: 2, textAlign: 'center', fontWeight: 'bold' }}
                      >
                        NOG
                      </Typography>
                      <Typography variant='body1'>
                        NOG, eller kvantitativa resonemang, utmanar ditt logiska och matematiska
                        tänkande. AI-genererade problem ger dig möjlighet att öva på komplexa
                        resonemang och problemlösning. Du utvecklar förmågan att strukturera
                        information, identifiera relevanta data och dra korrekta slutsatser -
                        kritiska färdigheter för att lyckas i denna del av högskoleprovet.
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box
                      sx={{
                        ...styles.newBox,
                        p: 3,
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        borderRadius: '15px',
                      }}
                    >
                      <Compare
                        sx={{
                          fontSize: 50,
                          color: theme.palette.secondary.main,
                          mb: 2,
                          alignSelf: 'center',
                        }}
                      />
                      <Typography
                        variant='h6'
                        sx={{ mb: 2, textAlign: 'center', fontWeight: 'bold' }}
                      >
                        KVA
                      </Typography>
                      <Typography variant='body1'>
                        KVA, eller kvantitativa jämförelser, övar din förmåga att snabbt jämföra
                        matematiska uttryck. Genom att analysera AI-genererade jämförelser tränar du
                        upp din matematiska intuition och blir skicklig på att göra snabba
                        uppskattningar. Detta hjälper dig att effektivt lösa problem under tidspress
                        på högskoleprovet.
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </>
          )}

          {testInProgress && hpTest && hpTest.length > 0 && (
            <Fade in={true} timeout={1000}>
              <Box sx={{ width: '100%', mt: 4, paddingRight: { xs: '0px' } }}>
                <Typography
                  variant='h6'
                  sx={{ mb: 2 }}
                  className={`focus-toggle ${focusModeActive ? 'focus-active' : ''}`}
                >
                  {currentHpName || 'Högskoleprov'} - {hpTest[currentSectionIndex].questionType}
                </Typography>

                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Tooltip title='Fokusläge'>
                    <IconButton color='secondary' onClick={toggleFocusMode}>
                      {focusModeActive ? (
                        <VisibilityOffIcon
                          sx={{ color: theme.palette.mode === 'dark' ? '#157dbd' : '#a4a3a4' }}
                        />
                      ) : (
                        <VisibilityIcon
                          sx={{ color: theme.palette.mode === 'dark' ? '#157dbd' : '#a4a3a4' }}
                        />
                      )}
                    </IconButton>
                  </Tooltip>
                </Box>
                <Box
                  sx={{
                    marginTop: '0px !important',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2,
                    margin: { xs: '15px', md: 'inherit' },
                  }}
                >
                  <Tooltip title='Fokusläge'>
                    <Box
                      position='relative'
                      display='inline-flex'
                      className={`focus-toggle ${focusModeActive ? 'focus-active' : ''}`}
                      onClick={toggleFocusMode}
                      style={{ cursor: 'pointer' }}
                    >
                      <CircularProgress
                        variant='determinate'
                        value={(timeLeft / (55 * 60)) * 100}
                        size={60}
                      />
                      <Box
                        top={0}
                        left={0}
                        bottom={0}
                        right={0}
                        position='absolute'
                        display='flex'
                        alignItems='center'
                        justifyContent='center'
                      >
                        <Typography
                          variant='caption'
                          component='div'
                          color='text.secondary'
                          ref={timerDisplayRef}
                        >
                          {`${Math.floor(timeLeftRef.current / 60)}:${timeLeftRef.current % 60 < 10 ? '0' : ''}${timeLeftRef.current % 60}`}
                        </Typography>
                      </Box>
                    </Box>
                  </Tooltip>

                  <div className={`focus-toggle ${focusModeActive ? 'focus-active' : ''}`}>
                    <Typography
                      variant='h6'
                      sx={{
                        color: theme.palette.mode === 'dark' ? '#c4c4c4' : '#333',
                        textAlign: 'center',
                        fontSize: '16px',
                      }}
                    >
                      Fråga {currentQuestionIndex + 1} av{' '}
                      {hpTest[currentSectionIndex].questions.length} —{' '}
                      {hpTest[currentSectionIndex].questionType}
                    </Typography>
                  </div>

                  {isMobile ? (
                    <Button
                      variant='contained'
                      color='primary'
                      disableRipple
                      onClick={handleOpenOverview}
                      startIcon={<DatasetIcon />}
                      sx={styles.shrinkButton}
                    />
                  ) : (
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={handleOpenOverview}
                      startIcon={<DatasetIcon />}
                      sx={{ mt: 2, mb: 2 }}
                      className={`focus-toggle ${focusModeActive ? 'focus-active' : ''}`}
                    >
                      Översikt
                    </Button>
                  )}
                </Box>

                <LinearProgress
                  className={`focus-toggle ${focusModeActive ? 'focus-active' : ''}`}
                  variant='determinate'
                  value={
                    (currentQuestionIndex / hpTest[currentSectionIndex].questions.length) * 100
                  }
                  sx={{
                    height: 10,
                    borderRadius: 5,
                    marginBottom: 2,
                    marginTop: '10px',
                    '& .MuiLinearProgress-bar': {
                      borderRadius: 5,
                      backgroundColor: '#8b5cf6',
                      transition: 'transform 0.5s ease-in-out',
                    },
                  }}
                />
                {renderQuestion()}
              </Box>
            </Fade>
          )}

          {showSummary && (
            <Box sx={styles.summaryContainer}>
              {Logger.log('Current timeLeft:', timeLeft)}
              {Logger.log('Total test time in seconds:', 55 * 60)}
              {Logger.log('Calculated used time:', 55 * 60 - timeLeft)}

              <Typography variant='h3' sx={{ ...styles.h3, mb: 2, mt: 3 }}>
                Resultat
              </Typography>

              <Card
                sx={{
                  minWidth: 275,
                  mb: 4,
                  boxShadow: 'none',
                  borderRadius: '10px',

                  backgroundImage: 'none',

                  backgroundColor: theme.palette.mode === 'dark' ? '#25323f' : 'white',
                  border: theme.palette.mode === 'dark' ? '1px solid #35414d' : '1px solid #eaeaea',
                }}
              >
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-around',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      mt: 2,
                    }}
                  >
                    <Box sx={{ textAlign: 'center' }}>
                      <Typography variant='h4' sx={{ fontSize: { xs: '25px', md: '34px' } }}>
                        {results.filter(result => result.isCorrect).length} / {results.length}
                      </Typography>
                      <Typography color='text.secondary'>Korrekta svar</Typography>
                    </Box>
                    <Box sx={{ textAlign: 'center' }}>
                      <Typography variant='h4' sx={{ fontSize: { xs: '25px', md: '34px' } }}>
                        {(
                          (results.filter(result => result.isCorrect).length / results.length) *
                          100
                        ).toFixed(1)}
                        %
                      </Typography>
                      <Typography color='text.secondary'>Precision</Typography>
                    </Box>
                    <Box sx={{ textAlign: 'center' }}>
                      <Typography variant='h4' sx={{ fontSize: { xs: '25px', md: '34px' } }}>
                        {Math.floor((55 * 60 - timeLeftRef.current) / 60)}:
                        {((55 * 60 - timeLeftRef.current) % 60).toString().padStart(2, '0')}
                      </Typography>
                      <Typography color='text.secondary'>Tid använd</Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>

              <Typography variant='h3' sx={{ ...styles.h3, mb: 2 }}>
                Detaljerade resultat
              </Typography>
              <MathJaxContext>
                {results.map((result, index) => {
                  //Logger.log("Rendering result:", index, result);

                  // Find the correct section
                  const section = hpTest.find(s => s.questionType === result.questionType);

                  // Find the correct question, handling potential nested structure
                  let question;
                  if (section) {
                    if (Array.isArray(section.questions)) {
                      // För ORD-frågor
                      question = section.questions.find(q => q.question === result.question);
                    } else if (section.questions && Array.isArray(section.questions)) {
                      // För LÄS-frågor
                      question = section.questions[0];
                    }
                  }
                  Logger.log('Section:', section);
                  Logger.log('Question:', question);
                  if (!section || !question) {
                    console.error(
                      'Could not find matching section or question for result:',
                      result
                    );
                    return null; // or return a placeholder component
                  }

                  return (
                    <Card
                      key={index}
                      sx={{
                        mb: 2,
                        backgroundColor: '#4a5d6b',
                        borderRadius: '10px',
                        boxShadow: 'none',
                        position: 'relative',

                        border:
                          theme.palette.mode === 'dark' ? '1px solid #35414d' : '1px solid #eaeaea',

                        backgroundColor: theme.palette.mode === 'dark' ? '##162837' : 'white',
                      }}
                    >
                      <CardContent>
                        <Typography variant='h6'>{result.questionType}</Typography>
                        <Typography variant='body1' sx={{ mb: 1 }}>
                          {result.question}
                        </Typography>

                        {result.questionType === 'XYZ' ? (
                          <Box sx={{ mb: 3, mt: 2 }}>
                            <span style={{ fontSize: '20px' }}>
                              <MathJax>{` \\(${question.equation}\\)`}</MathJax>
                            </span>
                          </Box>
                        ) : result.questionType === 'DTK' ? (
                          <DynamicMathChart
                            chartType={question.chartType}
                            chartData={question.chartData}
                            chartConfig={question.chartConfig}
                          />
                        ) : (
                          result.question
                        )}

                        <Typography variant='body2'>Ditt svar: {result.selectedOption}</Typography>
                        <Typography variant='body2'>
                          Korrekt svar: {result.correctAnswer}
                        </Typography>
                        <Divider sx={{ my: 1 }} />
                        <Typography variant='body2'>Alla alternativ:</Typography>
                        {question.options.map((option, optionIndex) => (
                          <Chip
                            key={optionIndex}
                            label={option}
                            color={
                              option === result.correctAnswer
                                ? 'success'
                                : option === result.selectedOption
                                  ? 'error'
                                  : 'default'
                            }
                            variant={
                              option === result.selectedOption || option === result.correctAnswer
                                ? 'filled'
                                : 'outlined'
                            }
                            sx={{ m: 0.5 }}
                          />
                        ))}
                        <Chip
                          icon={result.isCorrect ? <CheckCircleIcon /> : <CancelIcon />}
                          label={result.isCorrect ? 'Rätt' : 'Fel'}
                          color={result.isCorrect ? 'success' : 'error'}
                          sx={{
                            mt: 1,
                            position: 'absolute',
                            right: '15px',
                            top: '10px',
                            borderRadius: '10px',
                          }}
                        />
                        <Divider sx={{ my: 1 }} />
                        <Box>
                          <Button
                            variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                            color='primary'
                            onClick={() => handleOpenAIDialog(index)}
                            startIcon={<GenerateIcon />}
                            sx={{
                              mt: 2,
                              borderRadius: '25px',
                              textTransform: 'none',
                              fontWeight: 'bold',
                            }}
                          >
                            Fråga AI
                          </Button>
                        </Box>
                        <AIDialog
                          userId={user.uid}
                          open={openAIDialogs[index] || false}
                          onClose={() => handleCloseAIDialog(index)}
                          initialQuestion={`Fråga: ${result.question}
${result.questionType === 'XYZ' && question.equation ? `Ekvation: ${question.equation}` : ''}
Alternativ: ${question.options.join(', ')}
Korrekt svar: ${result.correctAnswer}
Valt svar: ${result.selectedOption}
Förklara denna fråga och hjälp mig förstå svarsalternativen. Skriv på svenska.`}
                          systemMessage='Du är en hjälpsam AI-assistent designad för att förklara och hjälpa till med högskoleprovsfrågor. Ge en kort och enkel förklaring. Skriv på svenska.'
                          theme={theme}
                        />
                      </CardContent>
                    </Card>
                  );
                })}
              </MathJaxContext>

              {/*
    <Button
      variant="contained"
      color="primary"
      onClick={() => {
        setShowSummary(false);
        setCurrentSectionIndex(0);
        setCurrentQuestionIndex(0);
        setResults([]);
        setSelectedOption(null);
        handleBackToSelector();
      }}
      sx={{ mt: 3 }}
    >
      Nytt prov
    </Button>
    */}
            </Box>
          )}
        </Box>
        <Dialog
          open={isOverviewOpen}
          onClose={handleCloseOverview}
          maxWidth='md'
          fullWidth
          PaperProps={{
            style: {
              width: '85%',
              maxWidth: '850px',
              maxHeight: '80vh',
            },
          }}
        >
          <DialogTitle
            sx={{
              m: 0,
              p: 2,
              backgroundColor: theme.palette.mode === 'dark' ? '#192734' : 'white',
            }}
          >
            <IconButton
              aria-label='close'
              onClick={handleCloseOverview}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: theme.palette.mode === 'dark' ? 'white' : 'black',
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent
            sx={{ backgroundColor: theme.palette.mode === 'dark' ? '#192734' : 'white' }}
          >
            {overviewLoading ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Box sx={{ p: 3 }}>
                <Typography variant='h3' sx={{ ...styles.h3, mb: 3 }}>
                  Översikt
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    justifyContent: { sm: 'space-around' },
                    alignItems: { xs: 'flex-start', sm: 'center' },
                    gap: { xs: 2, sm: 0 },
                    mb: 6,
                    p: 2,
                    backgroundColor: theme.palette.mode === 'dark' ? '#3e4a55' : '#eee9ff',
                    borderRadius: '8px',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <CheckCircleIcon
                      color='success'
                      sx={{ mr: 1, width: '18px', height: '18px' }}
                    />
                    <Typography>Besvarad</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <WarningIcon color='warning' sx={{ mr: 1, width: '18px', height: '18px' }} />
                    <Typography>Ej besvarad</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <StarIcon color='info' sx={{ mr: 1, width: '18px', height: '18px' }} />
                    <Typography>Sparad fråga</Typography>
                  </Box>
                </Box>

                {hpTest.map((section, sectionIndex) => (
                  <Box key={section.questionType} sx={{ mb: 4 }}>
                    <Typography variant='h3' sx={{ ...styles.h3, fontWeight: 'bold', mb: 2 }}>
                      {section.questionType}
                    </Typography>
                    <Grid container spacing={2}>
                      {section.questions.map((_, questionIndex) => {
                        const answeredQuestion = results.find(
                          r => r.sectionIndex === sectionIndex && r.questionIndex === questionIndex
                        );

                        let status = 'Ej besvarad';
                        let color = 'warning.main';
                        let icon = <WarningIcon sx={{ width: '18px', height: '18px' }} />;

                        if (answeredQuestion) {
                          if (answeredQuestion.saved) {
                            status = 'Sparad fråga';
                            color = 'info.main';
                            icon = <StarIcon sx={{ width: '18px', height: '18px' }} />;
                          } else {
                            status = 'Besvarad';
                            color = 'success.main';
                            icon = <CheckCircleIcon sx={{ width: '18px', height: '18px' }} />;
                          }
                        }

                        return (
                          <Grid item key={`${sectionIndex}-${questionIndex}`}>
                            <Tooltip title={status}>
                              <Button
                                variant='outlined'
                                size='large'
                                onClick={() => jumpToQuestion(sectionIndex, questionIndex)}
                                sx={{
                                  minWidth: '60px',
                                  height: '30px',
                                  p: 0,
                                  color: color,
                                  borderColor: color,
                                  backgroundColor:
                                    theme.palette.mode === 'dark' ? '#22303C' : '#f8f8f8',
                                  border:
                                    theme.palette.mode === 'dark'
                                      ? '1px solid #3E5366'
                                      : '1px solid #f1f1f1',
                                  '&:hover': {
                                    backgroundColor: `${color}10`,
                                  },
                                }}
                                startIcon={icon}
                              >
                                {questionIndex + 1}
                              </Button>
                            </Tooltip>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Box>
                ))}
              </Box>
            )}
          </DialogContent>
        </Dialog>
      </Box>
    </>
  );
};

export default HP;
