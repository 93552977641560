import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, orderBy } from 'firebase/firestore';
import { db } from './firebase';
import { FormControl, Select, MenuItem } from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';

const PreviousLessonsLoader = ({ user, lessonType, onLoadLesson }) => {
  const [previousLessons, setPreviousLessons] = useState([]);
  const [selectedLesson, setSelectedLesson] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchPreviousLessons = async () => {
      if (!user?.uid) return;

      try {
        const lessonsRef = collection(db, 'lesson_usage');
        const q = query(
          lessonsRef,
          where('userId', '==', user.uid),
          where('lessonType', '==', lessonType),
          where('hasPrinted', '==', true),
          orderBy('timestamp', 'desc')
        );

        const querySnapshot = await getDocs(q);
        const lessons = querySnapshot.docs.map(doc => ({
          id: doc.id,
          data: doc.data().data,
          formData: doc.data().formData,
          timestamp: doc.data().timestamp?.toDate() || new Date(),
        }));

        setPreviousLessons(lessons);
      } catch (error) {
        console.error('Error fetching previous lessons:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPreviousLessons();
  }, [user?.uid, lessonType]);

  const handleLessonChange = event => {
    const lessonId = event.target.value;
    setSelectedLesson(lessonId);
    const selected = previousLessons.find(lesson => lesson.id === lessonId);
    if (selected) {
      onLoadLesson(selected.data, selected.formData);
    }
  };

  const formatDate = date => {
    return new Intl.DateTimeFormat('sv-SE', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    }).format(date);
  };

  return (
    <FormControl sx={{ minWidth: 250 }}>
      <Select
        value={selectedLesson}
        onChange={handleLessonChange}
        displayEmpty
        startAdornment={<HistoryIcon sx={{ mr: 1, opacity: 0.7 }} />}
        disabled={isLoading}
      >
        <MenuItem value='' disabled>
          {isLoading ? 'Laddar...' : 'Tidigare lektioner'}
        </MenuItem>
        {previousLessons.map(lesson => (
          <MenuItem key={lesson.id} value={lesson.id}>
            {formatDate(lesson.timestamp)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default PreviousLessonsLoader;
