import { useState, useEffect } from 'react';
import { auth, db } from './firebase';
import {
  doc,
  getDoc,
  updateDoc,
  setDoc,
  collection,
  query,
  where,
  getDocs,
  increment,
} from 'firebase/firestore';
import { signOut, updateProfile, GoogleAuthProvider, signInWithCredential } from 'firebase/auth';
import { v4 as uuidv4 } from 'uuid';
import { handleNewUserSignUp } from './userSetupUtils';
import {
  createUserWithEmailAndPassword as firebaseCreateUser,
  signInWithEmailAndPassword as firebaseSignIn,
} from 'firebase/auth';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Logger from './Logger';
import { serverTimestamp } from 'firebase/firestore';
const guestUser = {
  uid: 'guest-user',
  displayName: 'Gäst',
  tokens: 0,
  photoURL:
    'https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/misc%2Fgraduated.png?alt=media&token=9bcc4230-9671-4553-930e-e11e95d7fe74',
  preferredTheme: 'light',
  guest: true,
  isTemporary: true,
  partnerUser: false,
  payingSubscriber: false,
};

export function useAuth() {
  const [user, setUser] = useState(guestUser);
  const [loading, setLoading] = useState(true);
  const [justLoggedIn, setJustLoggedIn] = useState(false);

  // Handle URL params first

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async authUser => {
      try {
        if (
          window.location.pathname.includes('/app/skapa-konto') ||
          window.location.pathname.includes('/app/logga-in')
        ) {
          setLoading(false);
          return;
        }

        const params = new URLSearchParams(window.location.search);
        const partnerData = params.get('userData');

        if (partnerData && !sessionStorage.getItem('ssssssssss')) {
          sessionStorage.setItem('ssssssssss', 'true'); // MÅSTE vara här uppe för att hindra multipla körningar
          const userData = JSON.parse(atob(partnerData));

          // Kolla om användaren finns
          const usersRef = collection(db, 'users');
          const q = query(usersRef, where('partnerUserId', '==', userData.partnerUserId));
          const querySnapshot = await getDocs(q);

          if (querySnapshot.empty) {
            // Ny användare - skapa ETT konto
            await firebaseCreateUser(auth, userData.email, `${userData.partnerUserId}#2024!`);
            await setDoc(doc(db, 'users', auth.currentUser.uid), {
              email: userData.email,
              displayName: userData.name,
              partnerUserId: userData.partnerUserId,
              school: userData.school || '',
              municipality: userData.municipality || '',
              tokens: 0,
              preferredTheme: 'allakando',
              language: 'Svenska',
              createdAt: new Date(),
              partnerUser: true,
              accountType: 'student',
              guest: false,
            });
          } else {
            // Existerande användare - logga in
            await firebaseSignIn(auth, userData.email, `${userData.partnerUserId}#2024!`);
          }

          const url = new URL(window.location);
          url.search = '';
          window.history.replaceState({}, '', url);

          return;
        }

        if (authUser) {
          await loadUserData(authUser);
        } else {
          //Moonshine: Redirecta till /app/logga-in
          window.location.href = '/app/logga-in';
          //await loadGuestData();
        }
        setLoading(false);
      } catch (error) {
        console.error('Auth error:', error);
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const loadUserData = async authUser => {
    // Load Firestore data in background
    const userDoc = doc(db, 'users', authUser.uid);
    const docSnap = await getDoc(userDoc);

    if (docSnap.exists()) {
      const userData = docSnap.data();
      setUser({
        uid: authUser.uid, // Use the REAL uid
        guest: false, // DEFINITELY not a guest
        tokens: userData.tokens || 0,
        photoURL: authUser.photoURL || userData.photoURL,
        displayName: userData.displayName,
        latexActivated: userData.latexActivated,
        accountType: userData.accountType,
        municipality: userData.municipality,
        school: userData.school,
        admin: userData.admin,
        preferredTheme: userData.preferredTheme || 'light',
        language: userData.language || 'Svenska',
        payingSubscriber: userData.payingSubscriber || false,
      });
    }

    // Update stats in background
    updateDoc(userDoc, {
      lastLogin: serverTimestamp(),
      loginCount: increment(1),
    }).catch(console.error);
  };

  const loadGuestData = async () => {
    let guestId = localStorage.getItem('guestId');
    if (!guestId) {
      guestId = `guest-${uuidv4()}`;
      localStorage.setItem('guestId', guestId);
    }
    const userDoc = doc(db, 'users', guestId);
    const docSnap = await getDoc(userDoc);

    console.log('Guest data from Firestore:', docSnap.data()); // Add this

    Logger.log('local guestId: ', guestId);
    if (docSnap.exists()) {
      setUser({ uid: guestId, ...docSnap.data(), guest: true });
    } else {
      const newUser = {
        uid: guestId,
        displayName: 'Gäst',
        photoURL:
          'https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/misc%2Fgraduated.png?alt=media&token=9bcc4230-9671-4553-930e-e11e95d7fe74',
        tokens: 0,
        guest: true,
        preferredTheme: 'light',
        partnerUser: false,
        language: 'Svenska',
        createdAt: serverTimestamp(),
      };
      await setDoc(userDoc, newUser);
      setUser(newUser);
      Logger.log('setUser runs: ', newUser);

      Logger.log('handleNewUserSignUp runs');
      await handleNewUserSignUp(guestId);
    }
  };

  // useEffect(() => {
  //     if (user.guest) {
  //         window.google.accounts.id.initialize({
  //             client_id: "981080802387-cplabtnr7806g0dmduu9hkms8o2eus7j.apps.googleusercontent.com",
  //             callback: useGoogleLogin,
  //             auto_select: true,
  //         });
  //         window.google.accounts.id.prompt();
  //     }
  // }, [user.guest]);

  const updateUser = async newData => {
    if (auth.currentUser) {
      const userDoc = doc(db, 'users', auth.currentUser.uid);
      await updateDoc(userDoc, newData);

      if (newData.displayName || newData.photoURL) {
        await updateProfile(auth.currentUser, {
          displayName: newData.displayName,
          photoURL: newData.photoURL,
        });
      }
      await loadUserData(auth.currentUser);
      return auth.currentUser;
    } else if (newData.email) {
      // This is for converting a guest user to a registered user
      const guestId = localStorage.getItem('guestId');
      if (guestId) {
        const userDoc = doc(db, 'users', guestId);
        await updateDoc(userDoc, { ...newData, guest: false });
        localStorage.removeItem('guestId');
        await loadUserData(auth.currentUser);
      }
    } else {
      // Handle update for guest users
      const guestId = localStorage.getItem('guestId');
      if (guestId) {
        const userDoc = doc(db, 'users', guestId);
        await updateDoc(userDoc, newData);
        await loadGuestData();
      }
    }
  };

  const handleGoogleLogin = async response => {
    try {
      let credential;
      if (response.credential) {
        // Response from GoogleLogin component
        credential = GoogleAuthProvider.credential(response.credential);
      } else if (response.access_token) {
        // Response from useGoogleLogin
        credential = GoogleAuthProvider.credential(null, response.access_token);
      } else {
        throw new Error('No credential or access token found in response');
      }

      const result = await signInWithCredential(auth, credential);
      const user = result.user;
      const accessToken = await user.getIdToken(true);

      // Check if user document exists in Firestore
      const userDoc = doc(db, 'users', user.uid);
      const docSnap = await getDoc(userDoc);

      if (!docSnap.exists()) {
        // Create new user document if it doesn't exist
        await setDoc(userDoc, {
          displayName: user.displayName,
          email: user.email,
          photoURL: user.photoURL,
          tokens: 0,
          preferredTheme: 'light',
          language: 'Svenska',
          guest: false,
          accessToken: accessToken,
          accountType: 'undecided',
          createdAt: serverTimestamp(),
        });
      } else {
        // Update the access token for existing users
        await updateDoc(userDoc, { accessToken: accessToken });
      }

      // Load user data
      await loadUserData(user);
      await handleNewUserSignUp(user.uid);

      Logger.log('User logged in successfully:', user);
    } catch (error) {
      console.error('Error handling Google login:', error);
    }
  };

  const createUserWithEmailAndPassword = async (email, password) => {
    try {
      const userCredential = await firebaseCreateUser(auth, email, password);
      const user = userCredential.user;

      // Skapa användardokument i Firestore
      const userDoc = doc(db, 'users', user.uid);
      await setDoc(userDoc, {
        email: user.email,
        displayName: user.email.split('@')[0], // Sätt ett standard displayname
        tokens: 0,
        preferredTheme: 'light',
        language: 'Svenska',
        accountType: 'undecided',
        createdAt: serverTimestamp(),
      });

      await loadUserData(user);
      await handleNewUserSignUp(user.uid);
      Logger.log('User with email and pass successfully:', user);
      return user;
    } catch (error) {
      throw error;
    }
  };

  const signIn = async (email, password) => {
    try {
      const userCredential = await firebaseSignIn(auth, email, password);
      await loadUserData(userCredential.user);
      Logger.log('User with email and pass signed in con succé:', user);
      return userCredential.user;
    } catch (error) {
      throw error;
    }
  };

  //   const handleLogout = async () => {
  //     try {
  //       await signOut(auth);
  //       setUser(guestUser);

  // window.location.href = '/login';
  //     } catch (error) {
  //       console.error('Error logging out:', error);
  //     }
  //   };

  return {
    user,
    loading,
    updateUser,
    handleGoogleLogin,
    createUserWithEmailAndPassword,
    signIn,
    justLoggedIn,
    setJustLoggedIn,
    setUser,
  };
}
