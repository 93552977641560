import React from 'react';
import { Box, Button, TextField, Typography, Modal } from '@mui/material';
import BrushIcon from '@mui/icons-material/Brush';

const ImageGenerationModal = ({
  open,
  onClose,
  onGenerate,
  isGenerating,
  instructions,
  setInstructions,
  generationType,
  theme,
}) => {
  return (
    <Modal open={open} onClose={onClose} aria-labelledby='image-modal-title'>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          maxWidth: 600,
          bgcolor: theme.palette.mode === 'dark' ? '#1E2A3A' : '#ffffff',
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
        }}
      >
        <Box sx={{ textAlign: 'center', mb: 4 }}>
          <Typography id='image-modal-title' variant='h5' sx={{ fontWeight: 'bold', mb: 2 }}>
            {generationType === 'topic' ? 'Lägg till instruktioner' : 'Lägg till instruktioner'}
          </Typography>
          <Typography variant='h6' sx={{ color: 'text.secondary' }}>
            Instruera AI:n att generera bilderna så som du vill ha dem. Var tydlig och kortfattad.
          </Typography>
        </Box>

        <TextField
          fullWidth
          multiline
          rows={4}
          value={instructions}
          onChange={e => setInstructions(e.target.value)}
          placeholder='T.ex. "Visa inga vikingar som bär hjälm med horn eftersom det är en felaktig uppfattning"'
          sx={{ mb: 3 }}
        />

        <Typography
          variant='h6'
          sx={{
            color: 'text.secondary',
            textAlign: 'center',
            mb: 3,
            mt: 2,
            fontWeight: '600',
            color: theme.palette.mode === 'dark' ? 'white' : '#333',
          }}
        >
          Eller generera direkt:
        </Typography>

        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
          <Button variant='outlined' color='inherit' onClick={onClose} sx={{ minWidth: '120px' }}>
            Avbryt
          </Button>
          <Button
            variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
            color='primary'
            onClick={onGenerate}
            startIcon={<BrushIcon />}
            disabled={isGenerating}
            sx={{ minWidth: '120px' }}
          >
            Generera bild{generationType === 'facts' ? 'er' : ''}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ImageGenerationModal;
