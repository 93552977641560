import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Button } from '@mui/material';
import { Palette as PaletteIcon } from '@mui/icons-material';
import { Abc as AbcIcon } from '@mui/icons-material';
import { EditNote as EditNoteIcon } from '@mui/icons-material';
import GenerateIcon from '@mui/icons-material/AutoAwesome';
import ShortcutBox from './ShortcutBox';
import { shortcuts } from './DashboardToolsShortcuts';

const DashboardLessonsShortcuts = ({ role, resetCategory, theme, category }) => {
  const [selectedCategory, setSelectedCategory] = useState('Populära');

  const excludedShortcuts = ['Dashboard', 'Shortcut Title 2'];

  useEffect(() => {
    // If category prop is provided, use it instead of 'Lektioner'
    setSelectedCategory(category || 'Lektioner');
  }, [role, resetCategory, category]);

  const filteredShortcuts = shortcuts.filter(
    shortcut =>
      (shortcut.roles.includes(role) || shortcut.roles.includes('both')) &&
      !excludedShortcuts.includes(shortcut.title) &&
      (selectedCategory === 'Populära' || shortcut.category === selectedCategory)
  );

  // Only get categories if no category prop is provided
  const categories = !category
    ? [
        ...new Set(
          shortcuts
            .filter(
              shortcut =>
                (shortcut.roles.includes(role) || shortcut.roles.includes('both')) &&
                !excludedShortcuts.includes(shortcut.title)
            )
            .map(shortcut => shortcut.category)
        ),
      ].filter(Boolean)
    : [];

  const limitedShortcuts =
    selectedCategory === 'Populära' ? filteredShortcuts.slice(0, 12) : filteredShortcuts;

  useEffect(() => {
    console.log('category selected: ', selectedCategory);
  }, [selectedCategory]);

  return (
    <Box sx={{ mt: 4 }}>
      {/* Only render categories if no category prop is provided */}
      {!category && (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 6, display: 'none' }}>
          {categories.map((cat, index) => (
            <Button
              key={index}
              variant={selectedCategory === cat ? 'contained' : 'outlined'}
              onClick={() => setSelectedCategory(cat)}
              startIcon={cat === 'Lektioner' ? <GenerateIcon /> : null}
              sx={{
                borderRadius: '30px',
                textTransform: 'none',
                fontWeight: '400',
                borderBottom: theme.palette.mode === 'dark' ? 'none' : undefined,
                '&:hover': {
                  borderBottom: theme.palette.mode === 'dark' ? 'none' : undefined,
                },
              }}
            >
              {cat}
            </Button>
          ))}
        </Box>
      )}
      <Grid container spacing={2}>
        {limitedShortcuts.map((shortcut, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <ShortcutBox diffView={true} {...shortcut} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default DashboardLessonsShortcuts;
