import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Divider,
  TextField,
  Tooltip,
  CircularProgress,
  Grid,
  Avatar,
} from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';

import GenerateIcon from '@mui/icons-material/AutoAwesome';
import InfoBox from './InfoBox';
import { callApi } from './api';
import TrainIcon from '@mui/icons-material/Train';
import { useSnackbar } from './SnackbarHandler';
import LanguageSelector from './LanguageSelector';
import { RiLightbulbLine } from 'react-icons/ri';
import { ThemeSelectorFlow } from './ThemeSelectorFlow';
import HoverableImage from './HoverableImage';
import ProgressBar from './ProgressBar';
import ImageGenerationModal from './ImageGenerationModal';

import { handlePrintPaSparet } from './printFunctions';

import { FourSquare } from 'react-loading-indicators';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import { ThemeSelector } from './ThemeSelector';
import Logger from './Logger';

import ImageIcon from '@mui/icons-material/Image';
import { generateAndProcessImage, trackLesson } from './functions';
import PrintIcon from '@mui/icons-material/Print';
import LessonDrawer from './LessonDrawer';
import SaveLessonDialog from './SaveLessonDialog';

import HistoryIcon from '@mui/icons-material/History';

import DeleteIcon from '@mui/icons-material/Delete';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import {
  Science as ScienceIcon,
  Castle as CastleIcon,
  Psychology as PsychologyIcon,
  Pets as PetsIcon,
  Public as PublicIcon,
  Brush as BrushIcon,
  EmojiEvents as EmojiEventsIcon,
  Museum as MuseumIcon,
  Nature as NatureIcon,
  AccountBalance as AccountBalanceIcon,
  LocalHospital as LocalHospitalIcon,
  School as SchoolIcon,
  Architecture as ArchitectureIcon,
} from '@mui/icons-material';

const ClueGenerator = ({ user, theme, styles, grade }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);

  const [topic, setTopic] = useState('customTheme');
  const [subject, setSubject] = useState('');
  const [customTheme, setCustomTheme] = useState('');
  const [language, setLanguage] = useState('svenska');
  const [clueCount, setClueCount] = useState(6);
  const [isGenerating, setIsGenerating] = useState(false);

  const [clueData, setClueData] = useState(null);
  const [isGeneratingTopicImage, setIsGeneratingTopicImage] = useState(false);
  const [isGeneratingClueImages, setIsGeneratingClueImages] = useState(false);
  const [progress, setProgress] = useState(0);
  const [generatedClueImages, setGeneratedClueImages] = useState(new Set());
  const { showSnackbar } = useSnackbar();
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageInstructions, setImageInstructions] = useState('');
  const [imageGenerationType, setImageGenerationType] = useState(null);
  const [openAiInstructions, setOpenAiInstructions] = useState('');

  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [titleText, setTitleText] = useState('På spåret-lektion');

  const [editingClueId, setEditingClueId] = useState(null);
  const [selectedClueIndex, setSelectedClueIndex] = useState(null);
  const [loadingImageIndex, setLoadingImageIndex] = useState(null);
  const [editingDifficulty, setEditingDifficulty] = useState('');

  const [lessonTheme, setLessonTheme] = useState('');
  const [predefinedTheme, setPredefinedTheme] = useState(null);
  const lessonTitleRef = useRef(null); // Add this ref
  const transcriptRef = useRef('');
  const uploadedFileRef = useRef('');

  const wikipediaContentRef = useRef('');
  const randomizedContentRef = useRef('');
  const coursePlanContentRef = useRef('');

  const topicSectionRef = useRef(null);
  const factsSectionRef = useRef(null);

  const additionalThemes = [
    { type: 'subheader', label: 'Teman som lämpar sig extra bra för denna lektionstyp:' },
    {
      value: 'virginia-woolf',
      label: 'Virginia Woolf',
      content: 'Virginia Woolf',
      Icon: MenuBookIcon,
    },
    {
      value: 'eiffel-tower',
      label: 'Eiffeltornet',
      content: 'Eiffeltornet',
      Icon: CastleIcon,
    },
    {
      value: 'penicillin',
      label: 'Penicillin',
      content: 'Penicillin',
      Icon: ScienceIcon,
    },
    {
      value: 'allah',
      label: 'Allah',
      content: 'Allah',
      Icon: PublicIcon,
    },
    {
      value: 'jesus',
      label: 'Jesus',
      content: 'Jesus',
      Icon: PublicIcon,
    },
    {
      value: 'james-joyce',
      label: 'James Joyce',
      content: 'James Joyce',
      Icon: MenuBookIcon,
    },
    {
      value: 'jane-austen',
      label: 'Jane Austen',
      content: 'Jane Austen',
      Icon: MenuBookIcon,
    },
    {
      value: 'anna-karenina',
      label: 'Anna Karenina',
      content: 'Anna Karenina',
      Icon: MenuBookIcon,
    },
    {
      value: 'mogadishu',
      label: 'Mogadishu',
      content: 'Mogadishu',
      Icon: PublicIcon,
    },
  ];

  useEffect(() => {
    Logger.log('lessonTheme: ', lessonTheme);
  }, [lessonTheme]);

  useEffect(() => {
    Logger.log('predefinedTheme: ', predefinedTheme);
  }, [predefinedTheme]);

  useEffect(() => {
    Logger.log('customTheme: ', customTheme);
  }, [customTheme]);

  useEffect(() => {
    Logger.log('youtubeTranscript: ', transcriptRef.current);
  }, [transcriptRef.current]);

  useEffect(() => {
    Logger.log('wikipediaContentRef: ', wikipediaContentRef.current);
  }, [wikipediaContentRef.current]);
  useEffect(() => {
    Logger.log('uploadedFileRef: ', uploadedFileRef.current);
  }, [uploadedFileRef.current]);

  useEffect(() => {
    Logger.log('randomizedContentRef: ', randomizedContentRef.current);
  }, [randomizedContentRef.current]);

  useEffect(() => {
    Logger.log('coursePlanContentRef: ', coursePlanContentRef.current);
  }, [coursePlanContentRef.current]);

  const handleDeleteClue = clueKey => {
    setClueData(prevData => {
      const updatedClues = { ...prevData.clues };
      delete updatedClues[clueKey];

      return {
        ...prevData,
        clues: updatedClues,
      };
    });

    showSnackbar('Ledtråden har tagits bort', 'success');
  };
  const handleEditClue = (index, newText, newDifficulty) => {
    const clueKey = `Clue ${index + 1}`;
    setClueData(prevData => ({
      ...prevData,
      clues: {
        ...prevData.clues,
        [clueKey]: {
          ...prevData.clues[clueKey],
          text: newText,
          difficulty: newDifficulty,
        },
      },
    }));
    setEditingClueId(null);
    showSnackbar('Ledtråden har uppdaterats', 'success');
  };

  const handleSaveLessonClick = () => {
    setSaveDialogOpen(true);
  };

  const handleSaveWithComment = async (comment, name, coverImage, tags) => {
    Logger.log(JSON.stringify(clueData, null, 2));
    await trackLesson(
      user.uid,
      'ClueGeneratorLesson.js',
      clueData,
      null,
      true,
      comment,
      name,
      coverImage,
      tags
    );
    setDrawerOpen(true);
    showSnackbar('Lektionen sparad i din lektionsbank!', 'success');
  };

  const handleGenerateSpecificImage = async index => {
    setIsGeneratingClueImages(true);
    setLoadingImageIndex(index);
    handleCloseModal();

    try {
      const clueKey = `Clue ${index + 1}`;
      const clue = clueData.clues[clueKey];
      const prompt = `Create a clue image for the hint: "${clue.text}" about ${clueData.topic}. Make it subtle and not too obvious. ${imageInstructions}`;
      const imageUrl = await generateAndProcessImage(prompt, user.uid);

      setClueData(prevData => ({
        ...prevData,
        clues: {
          ...prevData.clues,
          [clueKey]: {
            ...prevData.clues[clueKey],
            image: imageUrl,
          },
        },
      }));

      showSnackbar('Bilden har genererats!', 'success');
    } catch (error) {
      console.error('Error generating image:', error);
      showSnackbar('Ett fel uppstod när bilden skulle genereras', 'error');
    } finally {
      setIsGeneratingClueImages(false);
      setLoadingImageIndex(null);
    }
  };

  const handleImageButtonClick = type => {
    setImageGenerationType(type);
    setSelectedClueIndex(null);
    setShowImageModal(true);
  };

  const handleGenerateWithInstructions = async () => {
    handleCloseModal();
    if (selectedClueIndex !== null) {
      await handleGenerateSpecificImage(selectedClueIndex);
      setSelectedClueIndex(null);
    } else if (imageGenerationType === 'topic') {
      await handleGenerateTopicImage();
    } else if (imageGenerationType === 'clues') {
      await handleGenerateClueImages();
    }
  };

  const handleCloseModal = () => {
    setShowImageModal(false);
    setImageInstructions('');
  };

  const simulateProgress = (startProgress, endProgress, duration, setProgressFn) => {
    const stepSize = 0.5;
    const steps = (endProgress - startProgress) / stepSize;
    const stepDuration = duration / steps;
    let currentProgress = startProgress;

    const intervalId = setInterval(() => {
      currentProgress += stepSize;
      if (currentProgress >= endProgress) {
        clearInterval(intervalId);
      } else {
        setProgressFn(currentProgress);
      }
    }, stepDuration);

    return () => clearInterval(intervalId);
  };

  const handleGenerateClues = async () => {
    setIsGenerating(true);
    setProgress(0);
    let stopProgressSimulation = null;
    try {
      stopProgressSimulation = simulateProgress(0, 90, 15000, setProgress);

      let themeToUse;

      if (lessonTheme === 'youtube') {
        themeToUse = transcriptRef.current;
      } else if (lessonTheme === 'customTheme') {
        themeToUse = customTheme;
      } else if (lessonTheme === 'predefined') {
        themeToUse = predefinedTheme;
      } else if (lessonTheme === 'fileUpload') {
        themeToUse = uploadedFileRef.current;
      } else if (lessonTheme === 'wikipedia') {
        themeToUse = wikipediaContentRef.current;
      } else if (lessonTheme === 'randomizer') {
        themeToUse = randomizedContentRef.current;
      } else {
        //
        themeToUse = coursePlanContentRef.current;
      }

      Logger.log('themeToUse: ', themeToUse);
      //const themeToUse = topic === 'customTheme' ? customTheme : topic;

      const response = await callApi(
        [
          {
            role: 'system',
            content: `You are an assistant that generates interesting clues for guessing games. The clues should start VERY VERY difficult and become progressively easier. ${openAiInstructions}`,
          },
          {
            role: 'user',
            content: `Generate a JSON object with exactly ${clueCount} clues about ${themeToUse} in "${language}". 


            The clues should be ordered from most difficult to easiest, with the last clue being very obvious.
            Each clue should contain at least 3 sentences and max 3 sentences.
            Include empty image placeholders (null) for "topicImage" and each clue's "image" field.
            Example response:
{
  "topic": "Miguel de Cervantes",

  "answer": "Miguel de Cervantes",
    "title": "Famous Authors in History",
  "topicImage": null,
  "clues": {
    "Clue 1": {
      "text": "This author spent five years as a captive in Algiers",
      "difficulty": "12 poäng",
      "image": null
    },
    "Clue 2": {
      "text": "They worked as a tax collector before achieving literary fame",
      "difficulty": "10 poäng",
      "image": null
    },
    "Clue 3": {
      "text": "They lost the use of their left hand in a famous naval battle",
      "difficulty": "8 poäng",
      "image": null
    },
    "Clue 4": {
      "text": "This Spanish author's masterpiece features a delusional knight",
      "difficulty": "6 poäng",
      "image": null
    },
    "Clue 5": {
      "text": "They wrote Don Quixote",
      "difficulty": "4 poäng",
      "image": null
    }

        "Clue 6": {
      "text": "They wrote Don Quixote",
      "difficulty": "2 poäng",
      "image": null
    }
  }
}`,
          },
        ],
        8000,
        true,
        user.uid
      );

      if (stopProgressSimulation) {
        stopProgressSimulation();
      }

      setProgress(95);

      let messageContent = response.data.choices[0].message.content.trim();
      if (messageContent.startsWith('```json')) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith('```')) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const data = JSON.parse(messageContent);
      setProgress(100);
      await new Promise(resolve => setTimeout(resolve, 800));
      Logger.log(JSON.stringify(data, null, 2));
      setClueData(data);
      setTitleText(data.answer);
      showSnackbar('På spåret-lektionen har genererats!', 'success');

      await trackLesson(user.uid, 'ClueGenerator.js', null, {
        language: language,
        clueCount: clueCount,
        themeToUse: themeToUse,
        lessonTheme: lessonTheme,
      });
    } catch (error) {
      console.error('Error generating clues:', error);
      showSnackbar('Något gick fel. Försök igen.', 'error');
    } finally {
      setIsGenerating(false);
    }
  };

  const handleGenerateTopicImage = async () => {
    setIsGeneratingTopicImage(true);
    try {
      const prompt = `Skapa en bild som representerar ${clueData.topic}, men gör det inte för uppenbart vad det är. Lägg inte till någon text i bilden. ${imageInstructions}`;
      const imageUrl = await generateAndProcessImage(prompt, user.uid);
      setClueData(prevData => ({
        ...prevData,
        topicImage: imageUrl,
      }));
      showSnackbar('Ämnesbild genererad framgångsrikt.', 'success');
    } catch (error) {
      showSnackbar('Misslyckades med att generera ämnesbild. Försök igen.', 'error');
    } finally {
      setIsGeneratingTopicImage(false);
    }
  };

  const handleGenerateClueImages = async () => {
    setIsGeneratingClueImages(true);
    setGeneratedClueImages(new Set());
    handleCloseModal();

    try {
      const entries = Object.entries(clueData.clues);
      await Promise.all(
        entries.map(async ([key, value]) => {
          try {
            const prompt = `Skapa en bildledtråd för ledtråden: "${value.text}" om ${clueData.topic}. Gör den subtil och inte för uppenbar. Bilden får absolut INTE avslöja svaret. Lägg inte till någon text. ${imageInstructions}`;
            const imageUrl = await generateAndProcessImage(prompt, user.uid);

            setClueData(prevData => ({
              ...prevData,
              clues: {
                ...prevData.clues,
                [key]: {
                  ...prevData.clues[key],
                  image: imageUrl,
                },
              },
            }));

            setGeneratedClueImages(prev => new Set([...prev, key]));
            showSnackbar(`Bild för ledtråd ${key.replace('Clue ', '')} har genererats!`, 'success');
          } catch (error) {
            console.error(`Error generating image for clue "${key}":`, error);
          }
        })
      );

      showSnackbar('Alla bilder har genererats!', 'success');
    } catch (error) {
      showSnackbar('Ett fel uppstod när bilderna skulle genereras', 'error');
    } finally {
      setIsGeneratingClueImages(false);
    }
  };

  const isGeneratingAnyImages = isGeneratingTopicImage || isGeneratingClueImages;

  return (
    <Box sx={styles.container}>
      <Box
        sx={{
          ...styles.innerContainer,
          width: { xs: '100%', md: '87%' },
          margin: '0 auto',
        }}
      >
        <Typography
          variant='h1'
          gutterBottom
          sx={{ fontWeight: 'bold', fontSize: '1.8em', mb: 3, mt: 5, textAlign: 'center' }}
        >
          På spåret-lektion
        </Typography>
        <Box sx={{ ...styles.buttonsContainer, ...styles.additionalButtonsContainer }}>
          <Tooltip title='Dina sparade lektioner'>
            <Button
              variant='contained'
              color='primary'
              disableRipple
              onClick={() => setDrawerOpen(true)}
              startIcon={<HistoryIcon />}
              sx={{
                padding: '8px',
                marginRight: '0px',
                paddingLeft: '14px',
                paddingRight: '3px',
                minWidth: 'auto',
                textTransform: 'none',
              }}
            />
          </Tooltip>
        </Box>

        <LessonDrawer
          userId={user.uid}
          lessonType='ClueGeneratorLesson.js'
          theme={theme}
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          lessonIcon={TrainIcon}
          onSelectLesson={lesson => {
            setClueData(lesson.data);
            setDrawerOpen(false);
            //setShowForm(false);
            showSnackbar(`Lektionen har laddats in.`, 'success');
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'start', // This ensures it scrolls to the top of the element
              });
            }, 100);
          }}
        />
        <InfoBox
          icon={TrainIcon}
          title='Instruktioner för Ledtråds Generator'
          instructions={[
            'Ange ett svar som elever ska gissa sig till (t.ex. Penicillin, Kalla kriget)',
            'Välj antal ledtrådar du vill att eleverna ska få',
            'Lägg till bilder för varje ledtråd och skriv ut lektionen',
          ]}
          theme={theme}
          styles={styles}
        />

        <ThemeSelectorFlow
          value={lessonTheme}
          onChange={e => setLessonTheme(e.target.value)}
          customTheme={customTheme}
          onCustomThemeChange={e => setCustomTheme(e.target.value)}
          onPredefinedThemeChange={e => setPredefinedTheme(e.target.value)}
          onYoutubeTranscriptChange={transcriptContent => {
            Logger.log('onYoutubeTranscriptChange körs med: ', transcriptContent);
            transcriptRef.current = transcriptContent;
          }}
          onFileUpload={content => {
            Logger.log('onFileUpload körs med: ', content);
            uploadedFileRef.current = content;
          }}
          onWikipediaContentChange={content => {
            Logger.log('Parent: Wikipedia content received', content.substring(0, 100));
            wikipediaContentRef.current = content;
          }}
          onRandomizedContentChange={content => {
            Logger.log('randomizedContentRef', content.substring(0, 100));
            randomizedContentRef.current = content;
          }}
          onCoursePlanContentChange={content => {
            Logger.log('onCoursePlanChange', content.substring(0, 100));
            coursePlanContentRef.current = content;
          }}
          lessonType='ClueGeneratorLesson.js'
          user={user}
          theme={theme}
          styles={styles}
          onLessonSelect={lesson => {
            Logger.log('onLessonSelect körs');
            setClueData(lesson.data);
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'start', // This ensures it scrolls to the top of the element
              });
            }, 100);
            showSnackbar('Lektionen har laddats in.', 'success');
          }}
          additionalThemes={additionalThemes}
        />
        <Box sx={styles.headlineLessonBox}>
          <Typography variant='h6' sx={styles.headlineLessonBoxTitle}>
            2. Generera lektionen
          </Typography>
        </Box>

        <Box sx={{ ...styles.freshBox, padding: '30px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant='subtitle1' sx={{ fontWeight: '500', mt: 1 }}>
                Antal ledtrådar:
              </Typography>
              <FormControl fullWidth margin='normal' sx={{ mb: 1, mt: 1 }}>
                <Select value={clueCount} onChange={e => setClueCount(e.target.value)}>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={6}>6</MenuItem>
                  <MenuItem value={7}>7</MenuItem>
                  <MenuItem value={8}>8</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant='subtitle1' sx={{ fontWeight: '500', mt: 1 }}>
                Språk:
              </Typography>
              <Box sx={{ mt: 1, mb: 1 }}>
                <LanguageSelector language={language} setLanguage={setLanguage} />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Typography variant='subtitle1' sx={{ fontWeight: '500', mt: 1 }}>
                Ytterligare instruktioner:
              </Typography>
              <FormControl fullWidth margin='normal' sx={{ mb: 3, mt: 1 }}>
                <TextField
                  value={openAiInstructions}
                  onChange={e => setOpenAiInstructions(e.target.value)}
                  placeholder='Ex. Fokusera på vetenskapliga prestationer'
                  variant='outlined'
                />
              </FormControl>
            </Grid>
          </Grid>

          <Button
            variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
            color='primary'
            onClick={handleGenerateClues}
            disabled={isGenerating || isGeneratingAnyImages}
            sx={{ mt: 2 }}
            startIcon={
              !isGenerating ? (
                <GenerateIcon style={{ color: '#fff' }} />
              ) : (
                <CircularProgress style={{ color: '#fff' }} size={16} />
              )
            }
          >
            {isGenerating ? 'Genererar ledtrådar...' : 'Generera lektionen'}
          </Button>

          {isGenerating && (
            <Box sx={{ mt: 7 }}>
              <ProgressBar progress={progress} label='Genererar på spåret-lektion...' />
            </Box>
          )}
        </Box>
        {clueData && (
          <>
            <Box ref={lessonTitleRef} sx={{ ...styles.headlineLessonBox, mt: 10 }}>
              <Typography variant='h6' sx={styles.headlineLessonBoxTitle}>
                3. Din lektion
              </Typography>
            </Box>
            <Box sx={{ ...styles.freshBox, padding: '30px' }}>
              <Box sx={styles.lessonControlPanel}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    px: 1,
                  }}
                >
                  <Box>
                    <Button
                      variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                      color='primary'
                      onClick={() => handleImageButtonClick('clues')}
                      disabled={isGeneratingAnyImages}
                      startIcon={
                        !isGeneratingClueImages ? (
                          <ImageIcon style={{ color: '#fff' }} />
                        ) : (
                          <CircularProgress style={{ color: '#fff' }} size={16} />
                        )
                      }
                    >
                      {isGeneratingClueImages ? 'Genererar...' : 'Generera alla bilder'}
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      variant='print'
                      color='primary'
                      onClick={() => handlePrintPaSparet(clueData, titleText, theme)}
                      startIcon={<PrintIcon />}
                      sx={{ ml: 2 }}
                    >
                      Skriv ut
                    </Button>
                    <Button
                      variant='print'
                      startIcon={<SaveAsIcon />}
                      sx={{ ml: 2 }}
                      onClick={handleSaveLessonClick}
                    >
                      Spara i lektionsbank
                    </Button>
                  </Box>
                </Box>
              </Box>
              <SaveLessonDialog
                open={saveDialogOpen}
                onClose={() => setSaveDialogOpen(false)}
                onSave={handleSaveWithComment}
                theme={theme}
                lessonName={clueData?.answer || ''}
                lessonObject={clueData}
                lessonType='ClueGeneratorLesson.js'
              />
              <ImageGenerationModal
                open={showImageModal}
                onClose={handleCloseModal}
                onGenerate={handleGenerateWithInstructions}
                isGenerating={
                  imageGenerationType === 'topic' ? isGeneratingTopicImage : isGeneratingClueImages
                }
                instructions={imageInstructions}
                setInstructions={setImageInstructions}
                generationType={imageGenerationType}
                theme={theme}
              />
              <Box sx={{ mt: 8 }}>
                <Typography
                  variant='h6'
                  sx={{ ...styles.grayText, fontWeight: '600', mt: 0, fontSize: '18px' }}
                >
                  (Korrekt svar: {clueData.answer})
                </Typography>

                {isEditingTitle ? (
                  <TextField
                    value={titleText}
                    onChange={e => setTitleText(e.target.value)}
                    onBlur={() => {
                      setIsEditingTitle(false);
                      showSnackbar('Titeln på lektionen har uppdaterats', 'success');
                    }}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        setIsEditingTitle(false);
                        showSnackbar('Titeln på lektionen har uppdaterats', 'success');
                      }
                    }}
                    autoFocus
                    fullWidth
                    variant='standard'
                    sx={{ backgroundColor: 'transparent' }}
                    InputProps={{
                      style: {
                        fontWeight: 'bold',
                        fontSize: '2.6em',
                        textAlign: 'center',
                        marginBottom: '1.2rem',
                        marginTop: '1.5rem',

                        backgroundColor: 'transparent',
                      },
                      disableUnderline: true,
                    }}
                  />
                ) : (
                  <Box
                    sx={{
                      position: 'relative',
                      display: 'inline-block',
                      margin: '0 auto',
                      mb: 3,
                      mt: 5,
                      ':hover .edit-icon': {
                        opacity: 1,
                      },
                    }}
                  >
                    <Typography
                      variant='h1'
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '2.6em',
                        cursor: 'pointer',
                      }}
                      onClick={() => setIsEditingTitle(true)}
                    >
                      {titleText}
                    </Typography>
                    <Tooltip title='Redigera titel'>
                      <EditIcon
                        className='edit-icon'
                        onClick={() => setIsEditingTitle(true)}
                        sx={{
                          position: 'absolute',
                          right: -35,
                          top: '50%',
                          transform: 'translateY(-50%)',
                          cursor: 'pointer',
                          opacity: 0,
                          transition: 'opacity 0.2s ease',
                        }}
                      />
                    </Tooltip>
                  </Box>
                )}

                <Grid container spacing={4}>
                  {Object.entries(clueData.clues).map(([key, clue], index) => (
                    <Grid item xs={12} key={index}>
                      <Box
                        sx={{
                          position: 'relative',
                          '&:hover .clue-controls': {
                            opacity: 1,
                          },
                        }}
                      >
                        <Box
                          className='clue-controls'
                          sx={{
                            position: 'absolute',
                            right: '-10px',
                            top: '20px',
                            opacity: 0,
                            transition: 'opacity 0.2s',
                            zIndex: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '4px',
                          }}
                        >
                          {editingClueId === index ? (
                            <Tooltip title='Spara' placement='top'>
                              <IconButton
                                onClick={() => {
                                  const textElement = document.querySelector(`#clue-text-${index}`);
                                  const difficultyElement = document.querySelector(
                                    `#difficulty-${index}`
                                  );
                                  handleEditClue(index, textElement.value, difficultyElement.value);
                                }}
                              >
                                <SaveAsIcon fontSize='small' />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip title='Redigera' placement='top'>
                              <IconButton onClick={() => setEditingClueId(index)}>
                                <EditIcon fontSize='small' />
                              </IconButton>
                            </Tooltip>
                          )}
                          <Tooltip title='Ta bort' placement='top'>
                            <IconButton onClick={() => handleDeleteClue(key)}>
                              <DeleteIcon fontSize='small' />
                            </IconButton>
                          </Tooltip>
                        </Box>

                        {editingClueId === index ? (
                          <TextField
                            id={`difficulty-${index}`}
                            defaultValue={clue.difficulty}
                            variant='outlined'
                            size='small'
                            sx={{
                              mb: 1,
                              mt: 5,

                              '& .MuiInputBase-input': {
                                fontSize: '40px',
                                color: theme => theme.palette.primary.main,
                                fontWeight: 'bold',
                              },
                            }}
                          />
                        ) : (
                          <Typography
                            variant='subtitle1'
                            sx={{
                              fontWeight: 'bold',
                              mb: 1,
                              mt: 5,
                              color: theme => theme.palette.primary.main,
                              fontSize: '40px',
                            }}
                          >
                            {clue.difficulty}
                          </Typography>
                        )}

                        <Box
                          sx={{
                            backgroundColor:
                              theme.palette.mode === 'dark' ? '#15202b' : '#e0e0e029',
                            borderRadius: '10px',
                            padding: '20px',
                          }}
                        >
                          <Grid container spacing={3} alignItems='center'>
                            <Grid item xs={12} md={4}>
                              <HoverableImage
                                imageUrl={clue.image}
                                isLoading={isGeneratingClueImages}
                                loadingIndex={loadingImageIndex}
                                currentIndex={index}
                                onGenerateClick={() => {
                                  setSelectedClueIndex(index);
                                  setShowImageModal(true);
                                }}
                                userId={user.uid}
                                onImageUpdate={newImageUrl => {
                                  setClueData(prevData => ({
                                    ...prevData,
                                    clues: {
                                      ...prevData.clues,
                                      [key]: {
                                        ...prevData.clues[key],
                                        image: newImageUrl,
                                      },
                                    },
                                  }));
                                }}
                                height='240px'
                                theme={theme}
                                showSnackbar={showSnackbar}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={8}
                              sx={{ display: 'flex', flexDirection: 'column' }}
                            >
                              {editingClueId === index ? (
                                <TextField
                                  id={`clue-text-${index}`}
                                  fullWidth
                                  multiline
                                  defaultValue={clue.text}
                                  autoFocus
                                  sx={{
                                    ...styles.editingTextField,
                                    '& .MuiInputBase-input': {
                                      lineHeight: '1.7rem',
                                    },
                                  }}
                                />
                              ) : (
                                <Typography
                                  variant='body1'
                                  sx={{
                                    lineHeight: '1.7rem',
                                    whiteSpace: 'pre-wrap',
                                  }}
                                >
                                  {clue.text}
                                </Typography>
                              )}
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default ClueGenerator;
