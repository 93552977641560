import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  LinearProgress,
  Avatar,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { Link } from 'react-router-dom';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { getStyles } from '../index';
import { formatUpvotes } from './functions';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { doc, getDoc, getDocs, collection } from 'firebase/firestore';
import SchoolIcon from '@mui/icons-material/School';
import { CourseListSkeleton } from './TeacherSkeleton';
import { db } from './firebase';
import Logger from './Logger';

const CourseList = ({ userCourses = [], courseIds, compact = false }) => {
  const theme = useTheme();
  const styles = getStyles(theme);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [creators, setCreators] = useState({});
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    Logger.log('userCourses: ', userCourses);

    const fetchCourses = async () => {
      Logger.log('render');
      setLoading(true);
      let coursesToDisplay = [];

      if (courseIds && courseIds.length > 0) {
        // Fetch specific courses based on provided IDs
        const coursePromises = courseIds.map(id => getDoc(doc(db, 'courses', id)));
        const courseSnapshots = await Promise.all(coursePromises);
        coursesToDisplay = courseSnapshots
          .filter(doc => doc.exists())
          .map(doc => ({ id: doc.id, ...doc.data() }));
      } else if (userCourses.length > 0) {
        // Use the userCourses prop if no specific IDs are provided
        coursesToDisplay = userCourses;
      } else {
        // Fallback to fetching all courses if neither courseIds nor userCourses are provided
        const coursesSnapshot = await getDocs(collection(db, 'courses'));
        coursesToDisplay = coursesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      }

      setCourses(coursesToDisplay);
      setLoading(false);
      // Fetch creators for the courses
      const creatorIds = [
        ...new Set(coursesToDisplay.map(course => course.userId).filter(Boolean)),
      ];
      const creatorPromises = creatorIds.map(async userId => {
        const creatorDoc = await getDoc(doc(db, 'users', userId));
        if (creatorDoc.exists()) {
          return { [userId]: creatorDoc.data() };
        }
        return null;
      });

      const creatorResults = await Promise.all(creatorPromises);
      const creatorData = Object.assign({}, ...creatorResults.filter(Boolean));
      setCreators(creatorData);
    };

    fetchCourses();
  }, [JSON.stringify(courseIds), JSON.stringify(userCourses)]);

  const formatEstimatedTime = minutes => {
    if (minutes >= 60) {
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
      if (remainingMinutes === 0) {
        return `${hours} ${hours === 1 ? 'tim' : 'timmar'}`;
      }
      return `${hours} ${hours === 1 ? 'tim' : 'tim'} och ${remainingMinutes} min`;
    }
    return `${minutes} min`;
  };

  const calculateCourseProgress = modules => {
    const totalChapters = modules.reduce((sum, module) => sum + module.chapters.length, 0);
    const completedChapters = modules.reduce(
      (sum, module) => sum + module.chapters.filter(chapter => chapter.chapter_completed).length,
      0
    );
    return (completedChapters / totalChapters) * 100;
  };

  const calculateEstimatedTime = modules => {
    const totalChapters = modules.reduce((sum, module) => sum + module.chapters.length, 0);
    const completedChapters = modules.reduce(
      (sum, module) => sum + module.chapters.filter(chapter => chapter.chapter_completed).length,
      0
    );
    const remainingChapters = totalChapters - completedChapters;
    return remainingChapters * 20; // 20 minutes per remaining chapter
  };

  const isCourseCompleted = courseProgress => courseProgress === 100;

  const getBackgroundColor = (theme, completed) => {
    const isAllakando = theme.palette.themeType === 'allakando';
    const isDark = theme.palette.mode === 'dark';

    if (!compact) {
      return completed
        ? isDark
          ? 'rgba(86, 166, 83, 0.1)'
          : 'rgba(86, 166, 83, 0.05)'
        : isDark
          ? 'rgba(255, 255, 255, 0.05)'
          : 'white';
    }

    if (isAllakando) {
      return theme.palette.background.card;
    }
    return isDark ? '#2c3740' : '#f9f9f9';
  };

  const getHoverBackgroundColor = (theme, completed) => {
    const isAllakando = theme.palette.themeType === 'allakando';
    const isDark = theme.palette.mode === 'dark';

    if (!compact) {
      return completed
        ? isDark
          ? 'rgba(86, 166, 83, 0.15)'
          : 'rgba(86, 166, 83, 0.1)'
        : isDark
          ? 'rgba(255, 255, 255, 0.1)'
          : 'inherit';
    }

    if (isAllakando) {
      return theme.palette.background.cardHover;
    }
    return isDark ? '#35414d' : '#f0f0f0';
  };

  const getIconColor = theme => {
    const isAllakando = theme.palette.themeType === 'allakando';
    if (isAllakando) {
      return theme.palette.primary.main;
    }
    return styles.grayText.color;
  };

  const getHoverIconColor = theme => {
    const isAllakando = theme.palette.themeType === 'allakando';
    if (isAllakando) {
      return theme.palette.primary.hover;
    }
    return styles.grayText.color;
  };

  const getTextColor = theme => {
    const isAllakando = theme.palette.themeType === 'allakando';
    const isDark = theme.palette.mode === 'dark';

    if (isAllakando) {
      return theme.palette.text.primary;
    }
    return isDark ? '#c0c3c6' : '#4a5568';
  };

  if (loading) {
    return <CourseListSkeleton theme={theme} numberOfCourses={compact ? 8 : 6} compact={compact} />;
  }

  return (
    <>
      <Grid container spacing={3}>
        {courses.map((course, index) => {
          const totalChapters = course.modules.reduce(
            (sum, module) => sum + module.chapters.length,
            0
          );
          const courseProgress = calculateCourseProgress(course.modules);
          const estimatedTime = calculateEstimatedTime(course.modules);
          const completed = isCourseCompleted(courseProgress);

          return (
            <Grid item xs={12} sm={6} md={compact ? 3 : 4} key={index}>
              <Card
                component={compact ? Link : 'div'}
                to={compact ? `/app/kurser/${course.slug}` : undefined}
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  boxShadow: 'none',
                  borderRadius: '12px',
                  backgroundImage: 'none',
                  textDecoration: 'none',
                  border: compact
                    ? 'none'
                    : completed
                      ? theme.palette.mode === 'dark'
                        ? '1px solid #366235'
                        : '1px solid #CBF2C9'
                      : theme.palette.mode === 'dark'
                        ? '1px solid #35414d'
                        : '1px solid #eee',
                  position: 'relative',
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    textDecoration: 'none',
                    '& .icon': {
                      color: getHoverIconColor(theme),
                    },
                    backgroundColor: getHoverBackgroundColor(theme, completed),
                  },
                  backgroundColor: getBackgroundColor(theme, completed),
                }}
              >
                {completed && (
                  <CheckCircleIcon
                    sx={{
                      position: 'absolute',
                      top: '10px',
                      right: '10px',
                      color: '#56A653',
                      fontSize: '24px',
                    }}
                  />
                )}
                <CardContent
                  sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    height: '100%',
                    padding: compact ? '20px' : '30px',
                    border: 'none',
                  }}
                >
                  <Box>
                    <SchoolIcon
                      className='icon'
                      sx={{
                        fontSize: compact ? '30px' : '40px',
                        color: getIconColor(theme),
                        transition: 'color 0.3s ease',
                      }}
                    />
                  </Box>

                  <Typography
                    variant='h6'
                    sx={{
                      fontWeight: compact ? '600' : '600',
                      fontSize:
                        theme.palette.themeType === 'allakando'
                          ? compact
                            ? '1.175rem'
                            : '1.45rem'
                          : compact
                            ? '1.0rem'
                            : '1.45rem',
                      color: getTextColor(theme),
                    }}
                  >
                    {course.topic}
                  </Typography>

                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px', mt: 0.5 }}>
                    <ThumbUpOffAltIcon
                      sx={{
                        fontSize: '15px',
                        ml: 0,
                        color: getIconColor(theme),
                      }}
                    />
                    <Typography
                      variant='body2'
                      sx={{
                        fontSize: '13px',
                        lineHeight: 1,
                        fontWeight: '500',
                        color: getIconColor(theme),
                      }}
                    >
                      {formatUpvotes(course.upvotes)}
                    </Typography>
                    <FolderOpenIcon
                      sx={{
                        fontSize: '15px',
                        ml: 1,
                        color: getIconColor(theme),
                      }}
                    />
                    <Typography
                      variant='body2'
                      sx={{
                        fontSize: '13px',
                        lineHeight: 1,
                        fontWeight: '500',
                        color: getIconColor(theme),
                      }}
                    >{`${course.modules.length} delkurser`}</Typography>
                  </Box>

                  {!compact && (
                    <>
                      <Typography
                        variant='body2'
                        sx={{ ...styles.grayText, mt: 2, lineHeight: '21px' }}
                      >
                        {course.description.length > 260
                          ? course.description.slice(0, 260) + '...'
                          : course.description}
                      </Typography>

                      <Box
                        sx={{
                          backgroundColor: theme.palette.mode === 'dark' ? '#2d3e4d' : '#f9f9f9',
                          borderRadius: '6px',
                          padding: '15px',
                          mt: 3,
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Typography
                            variant='body2'
                            sx={{
                              ...styles.grayText,
                              fontSize: '13px',
                              display: 'flex',
                              alignItems: 'center',
                              mr: 3,
                              mb: 1,
                            }}
                          >
                            <MenuBookIcon sx={{ mr: 1, fontSize: '16px' }} />
                            {totalChapters} kapitel
                          </Typography>
                          <Typography
                            variant='body2'
                            sx={{
                              ...styles.grayText,
                              fontSize: '13px',
                              display: 'flex',
                              alignItems: 'center',
                              mb: 1,
                            }}
                          >
                            <AccessTimeIcon sx={{ mr: 1, fontSize: '16px' }} />
                            {completed
                              ? 'Avslutad'
                              : (formattedTime =>
                                  formattedTime.length > 25
                                    ? `${formattedTime.substring(0, 25)}...`
                                    : formattedTime)(formatEstimatedTime(estimatedTime))}
                          </Typography>
                        </Box>
                        <LinearProgress
                          variant='determinate'
                          value={courseProgress}
                          sx={{
                            height: 6,
                            borderRadius: 3,
                            mb: 1,
                            '& .MuiLinearProgress-bar': {
                              backgroundColor: completed ? '#56A653' : 'primary.main',
                            },
                          }}
                        />
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Typography
                            variant='body2'
                            sx={{ ...styles.grayText, fontSize: '12px' }}
                          ></Typography>
                          <Typography variant='body2' sx={{ ...styles.grayText, fontSize: '12px' }}>
                            {completed ? 'Avslutad' : `${Math.round(courseProgress)}% avklarat`}
                          </Typography>
                        </Box>
                      </Box>

                      <Button
                        component={Link}
                        to={`/app/kurser/${course.slug}`}
                        variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                        sx={{
                          width: '100%',
                          mt: 2,
                          ...(completed && {
                            backgroundColor: '#56A653',
                            '&:hover': {
                              backgroundColor: '#4a8f47',
                            },
                          }),
                        }}
                      >
                        {completed ? 'Repetera kurs' : 'Gå till kurs'}
                      </Button>
                    </>
                  )}
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default CourseList;
