import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  IconButton,
  Card,
  CardContent,
  Paper,
  CircularProgress,
  Chip,
  TextField,
  Stack,
  Dialog,
  DialogContent,
} from '@mui/material';

import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PricesSection from './PricesSection';
import LanguageSelector from './app/LanguageSelector';
import TeacherStatsSection from './TeacherStatsSection';
import CheckIcon from '@mui/icons-material/Check';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import BackgroundAndVisionSection from './BackgroundAndVisionSection';
import { useSnackbar } from './app/SnackbarHandler';

import ContactForm from './ContactForm';
import StarIcon from '@mui/icons-material/Star';
import StartPageFAQSection from './StartPageFAQSection';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Link as MuiLink } from '@mui/material';

import { East as ArrowIcon } from '@mui/icons-material';

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'; // Changed import
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import WestIcon from '@mui/icons-material/West';

import { Helmet } from 'react-helmet-async';
// **Newly Added Icon Imports**
import { features } from './app/functions';

import { Link } from 'react-router-dom';
import { TypeAnimation } from 'react-type-animation';

import { motion } from 'framer-motion';
import { getAIModelImage } from './app/TestComponents2';

import UniversitySection from './UniversitySection';

import FAQSection from './FAQSection';
import Logger from './app/Logger';
import TestimonialsSection from './TestimonialsSection';
import { GradientBackground, StyledButton, FeatureSection } from './StyledComponents';

import Header from './Header';
import Footer from './Footer';

const API_URL = process.env.NODE_ENV === 'production' ? '/api' : 'http://localhost:3001/api';

const ModernStuderaAIWebsite = ({ toggleDarkMode, isDark }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileOpen, setMobileOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const { showSnackbar } = useSnackbar();
  const [language, setLanguage] = useState('svenska'); // Add language state
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openVideoDialog, setOpenVideoDialog] = useState(false);
  const [selectedVideoId, setSelectedVideoId] = useState(null);

  const contactFormRef = useRef(null); // Add ref for ContactForm

  const instructionItems = [
    'Generera färdiga lektioner med AI på nolltid',
    'Lektioner i enlighet med Skolverkets kursplan',
    'Trygg och säker hantering av personlig integritet och GDPR',
    'Används av lärare, elever, studenter, läxhjälpare och vikarier',
    'Stöd för över 50 språk',
  ];

  const InstructionItem = ({ text }) => (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        mb: 1.5,
      }}
    >
      <Box
        sx={{
          width: 24,
          height: 24,
          borderRadius: '50%',
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          mr: 2,
        }}
      >
        <CheckIcon sx={{ fontSize: 16, color: 'white' }} />
      </Box>
      <Typography
        sx={{
          color: 'rgba(255, 255, 255, 0.8)',
          fontSize: '1.1rem',
          lineHeight: 1.5,
          fontWeight: 500,
        }}
      >
        {text}
      </Typography>
    </Box>
  );

  const uspData = [
    {
      id: 1,
      subheading: 'AI-VERKTYG FÖR LÄRARE',
      heading: 'Generera lektioner med AI på nolltid',
      description:
        'Generera engagerande lektionsmaterial med fantasieggande bilder på bara några sekunder! Perfekt som komplement till befintligt läromedel.',
      image: '/img/bild-exempel-4.jpg',
      link: '/app',
      buttonText: 'Prova helt gratis',
    },

    {
      id: 2,
      subheading: 'GDPR & personlig integritet',
      heading: 'Tryggt och säkert lärande, även med AI-teknologi',
      description:
        'Många användare undrar hur AI hanterar personlig integritet och GDPR. På Studera.AI prioriterar vi datasäkerhet och säkerställer att ingen användarinformation delas med våra AI-modeller.',
      image: '/img/bild-exempel-5.jpg',
      link: '/app',
      buttonText: 'Prova helt gratis',
    },

    {
      id: 3,
      subheading: 'AI-VERKTYG FÖR LÄRARE',
      heading: 'Engagerande AI-genererade bilder till lektionerna',
      description:
        'Med ett enkelt knapptryck kan du generera enastående vackra och engagerande bilder till det pedagogiska materialet — med skräddarsydda instruktioner för att anpassa bilderna utifrån ditt behov.',
      image: '/img/bild-exempel-1.jpg',
      link: '/app',
      buttonText: 'Prova helt gratis',
    },

    {
      id: 4,
      subheading: 'AI-VERKTYG FÖR LÄRARE',
      heading: 'Lektioner i enlighet med Skolverkets kursplan',
      description:
        'Vi har koll på alla styrdokument från Skolverket, så du kan generera lektioner i enlighet med kursplan för respektive ämne och årskurs.',
      image: '/img/skolverket-dark-bigger.png',
      link: '/app',
      buttonText: 'Prova helt gratis',
    },

    {
      id: 5,
      subheading: 'AI-VERKTYG FÖR LÄRARE',
      heading: 'Stöd för över 50 språk',
      description:
        'Generera pedagogiskt material på över 50 språk, däribland svenska, arabiska, farsi, spanska, somali, punjabi och många fler. Vi vill att alla - oavsett kulturell bakgrund - ska kunna använda vår tjänst.',
      image: '/img/stodjer-50-sprak.png',
      link: '/app',
      buttonText: 'Prova helt gratis',
      customContent: (
        <Box sx={{ mt: 0, mb: 4 }}>
          <LanguageSelector language={language} setLanguage={setLanguage} />
        </Box>
      ),
    },

    // {
    //   id: 6,
    //   subheading: 'AI-VERKTYG FÖR LÄRARE',
    //   heading: 'Lektionsplanering',
    //   description:
    //     'Låt Studera.ai generera lektionsplanering inom valfritt ämne. Bestäm längd på lektionen och vilka sektioner som ska ingå.',
    //   image: '/img/bild-exempel-5.jpg',
    //   link: '/app',
    //   buttonText: 'Prova helt gratis',
    // },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollToContact = e => {
    e.preventDefault();
    contactFormRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <Helmet>
        <title>Studera.AI — AI-verktyg för lärare, pedagoger, elever & skolor</title>
        <meta
          name='description'
          content='AI-verktyg för elever, lärare, pedagoger & skolor. Upptäck framtidens lärande genom att använda AI-verktyg som stöd, inte som ersättning.'
        />

        {/* Open Graph tags */}
        <meta property='og:type' content='website' />
        <meta property='og:url' content='https://studera.ai' />
        <meta
          property='og:title'
          content='Studera.AI — AI-verktyg för lärare, pedagoger & elever'
        />
        <meta
          property='og:description'
          content='AI-verktyg för elever, lärare, pedagoger & skolor. Upptäck framtidens lärande genom att använda AI-verktyg som stöd, inte som ersättning.'
        />
        <meta property='og:image' content='https://studera.ai/img/AI.png' />
        <meta property='og:image:width' content='1200' />
        <meta property='og:image:height' content='630' />
        <meta property='og:site_name' content='Studera.AI' />

        {/* Twitter Card tags */}
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:url' content='https://studera.ai' />
        <meta
          name='twitter:title'
          content='Studera.AI — AI-verktyg för lärare, pedagoger, elever & skolor'
        />
        <meta
          name='twitter:description'
          content='AI-verktyg för elever, lärare, pedagoger & skolor. Upptäck framtidens lärande genom att använda AI-verktyg som stöd, inte som ersättning.'
        />
        <meta name='twitter:image' content='https://studera.ai/img/AI.png' />
      </Helmet>
      <Header contactFormRef={contactFormRef} />

      <Box sx={{ bgcolor: 'background.default', color: 'text.primary', minHeight: '100vh' }}>
        <GradientBackground className='gradient-background'>
          <Container
            maxWidth='xl'
            sx={{ pt: { xs: 10, md: 21 }, pb: { xs: 10, md: 3 }, position: 'relative' }}
          >
            <Grid container spacing={2} alignItems='center'>
              <Grid item xs={12} md={6}>
                <Box sx={{ pl: { xs: 0, md: 4 } }}>
                  <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                  >
                    <Box sx={{ display: 'flex', mb: 2 }}>
                      <Chip
                        label={
                          <span>
                            Studera.ai{' '}
                            <span style={{ opacity: 0.7 }}>&nbsp;•&nbsp; Framtidens lärande</span>
                          </span>
                        }
                        sx={{
                          backgroundColor: 'rgba(255, 255, 255, 0.1)',
                          color: 'white',
                          fontWeight: '600',
                          fontSize: '0.8rem',
                          padding: '2px 14px',

                          borderRadius: '16px',
                          '& .MuiChip-label': {
                            padding: '0 4px',
                          },
                        }}
                      />
                    </Box>

                    <Typography
                      variant='h2'
                      component='h1'
                      gutterBottom
                      sx={{
                        fontWeight: 900,

                        fontSize: { xs: '2.5rem', md: '3.32rem' },
                      }}
                    >
                      AI-plattform för lärare, elever & studenter
                    </Typography>

                    <Box sx={{ mt: 4, mb: 0 }}>
                      {instructionItems.map((item, index) => (
                        <InstructionItem key={index} text={item} />
                      ))}
                    </Box>

                    <Box sx={{ display: 'flex', gap: 2 }}>
                      <Link to='/app/skapa-konto?ref=startpage' style={{ textDecoration: 'none' }}>
                        <StyledButton
                          variant='blue'
                          color='primary'
                          size='large'
                          sx={{
                            borderRadius: '12px',
                            padding: '13px 50px',
                            fontSize: '20px',
                            mt: 4,
                          }}
                        >
                          Skapa konto gratis <ArrowIcon sx={{ ml: 1 }} />
                        </StyledButton>
                      </Link>

                      <Link to='/app/logga-in?ref=startpage' style={{ textDecoration: 'none' }}>
                        <StyledButton
                          variant='blue'
                          color='primary'
                          size='large'
                          sx={{
                            borderRadius: '12px',
                            padding: '13px 50px',
                            fontSize: '20px',
                            mt: 4,
                          }}
                        >
                          Logga in <ArrowIcon sx={{ ml: 1 }} />
                        </StyledButton>
                      </Link>
                    </Box>
                  </motion.div>
                </Box>
              </Grid>

              {/* Image on the right */}
              <Grid
                item
                xs={12}
                md={6}
                sx={{ position: 'relative', display: { xs: 'none', md: 'block' } }}
              >
                <motion.div
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.8, delay: 0.2 }}
                >
                  <Link to='/app/skapa-konto?ref=startpage' style={{ textDecoration: 'none' }}>
                    <Box
                      component='img'
                      src={theme.palette.mode === 'dark' ? '/img/d.png' : '/img/l.png'}
                      alt='Studera med AI'
                      sx={{
                        width: 'calc(100% + 100px)', // Stretch the image beyond its container
                        height: 'auto',
                        borderRadius: '7px',
                        position: 'relative',
                        right: '-50px', // Moves the image to touch the scrollbar
                      }}
                    />
                  </Link>
                </motion.div>
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 7 }}>
              <KeyboardDoubleArrowDownIcon
                sx={{
                  fontSize: '4rem',
                  color: '#ffffff',
                  animation: 'bounce 2s infinite',
                }}
              />
            </Box>
          </Container>
        </GradientBackground>

        <style>
          {`
      @keyframes bounce {
        0%, 20%, 50%, 80%, 100% {
          transform: translateY(0);
        }
        40% {
          transform: translateY(-30px);
        }
        60% {
          transform: translateY(-15px);
        }
      }
    `}
        </style>

        <UniversitySection bgcolor='background.paper' showHeadline={true} />
        <Box sx={{ bgcolor: 'background.default', py: 8, mt: 8 }}>
          <Container maxWidth='lg'>
            {uspData.map((usp, index) => (
              <Grid
                container
                spacing={10}
                alignItems='center'
                sx={{ mb: 20 }}
                key={usp.id}
                direction={index % 2 === 0 ? 'row' : 'row-reverse'}
              >
                <Grid item xs={12} md={6}>
                  <motion.div
                    initial={{ opacity: 0, x: index % 2 === 0 ? -50 : 50 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.8 }}
                  >
                    <Typography
                      variant='overline'
                      sx={{
                        color: 'primary.main',
                        fontWeight: 600,

                        mb: 0,
                        display: 'block',
                        fontSize: '1.2rem',
                      }}
                    >
                      {usp.subheading}
                    </Typography>
                    <Typography
                      variant='h3'
                      sx={{
                        fontWeight: 800,
                        mb: 2,
                        fontSize: { xs: '2rem', md: '3.3rem' },
                      }}
                    >
                      {usp.heading}
                    </Typography>
                    <Typography
                      variant='body1'
                      sx={{
                        mb: 4,
                        color: 'text.secondary',
                        fontSize: '1.2rem',
                        lineHeight: 1.7,
                        whiteSpace: 'pre-line',
                      }}
                    >
                      {usp.description}
                    </Typography>
                    {usp.customContent}

                    <Link to={usp.link} style={{ textDecoration: 'none', display: 'none' }}>
                      <StyledButton
                        variant='blue'
                        size='large'
                        sx={{
                          borderRadius: '12px',
                          padding: '12px 32px',
                          fontSize: '1.1rem',
                        }}
                        endIcon={<KeyboardDoubleArrowRightIcon />}
                      >
                        {usp.buttonText}
                      </StyledButton>
                    </Link>
                  </motion.div>
                </Grid>
                <Grid item xs={12} md={6} sx={{ display: { xs: 'none', md: 'block' } }}>
                  <motion.div
                    initial={{ opacity: 0, x: index % 2 === 0 ? 50 : -50 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.8 }}
                  >
                    <Box
                      component='img'
                      src={usp.image}
                      alt={usp.heading}
                      sx={{
                        width: '100%',
                        height: 'auto',
                        borderRadius: '12px',
                        boxShadow: '0 20px 40px rgba(0,0,0,0.1)',
                        transition: 'transform 0.3s ease-in-out',
                        '&:hover': {
                          transform: 'translateY(-10px)',
                        },
                      }}
                    />
                  </motion.div>
                </Grid>
              </Grid>
            ))}
          </Container>
        </Box>

        {/* Example Lessons Grid Section */}
        <Box sx={{ bgcolor: 'background.paper', py: 8, display: 'none' }}>
          <Container maxWidth='lg'>
            <Typography
              variant='h3'
              sx={{
                fontWeight: 800,
                mb: 7,
                textAlign: 'center',
                fontSize: { xs: '2rem', md: '2.5rem' },
              }}
            >
              Exempel på lektioner du kan generera
            </Typography>

            <Grid container spacing={4}>
              {[
                {
                  title: 'Svenska: Läsförståelse',
                  description:
                    'En interaktiv lektion för kreativt skrivande med övningar och exempel anpassade efter elevernas nivå.',
                  type: 'image',
                  media:
                    'https://www.ilteducation.com/wp-content/uploads/2024/05/aski-raski-dator-720x482.jpg',
                  pdfUrl: '/pdfs/kreativt-skrivande.pdf',
                },

                {
                  title: 'Historia: Andra världskriget',
                  description:
                    'Fördjupande lektion om andra världskriget med tidslinje, kartor och diskussionsfrågor.',
                  type: 'image',
                  media:
                    'https://www.ilteducation.com/wp-content/uploads/2024/05/aski-raski-dator-720x482.jpg',
                  pdfUrl: '/pdfs/andra-varldskriget.pdf',
                },

                {
                  title: 'Matematik: Algebra grundkurs',
                  description:
                    'Komplett lektion i grundläggande algebra med övningsuppgifter och steg-för-steg-lösningar.',
                  type: 'video',
                  media: 'EB0I7YNK2ro',
                  pdfUrl: '/pdfs/algebra-grundkurs.pdf',
                },
              ].map((lesson, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <Paper
                    elevation={0}
                    sx={{
                      p: 3,
                      height: '100%',
                      borderRadius: 4,
                      bgcolor: theme.palette.mode === 'dark' ? '#15202b' : 'white',
                      boxShadow: '0 10px 40px rgba(0,0,0,0.04)',
                      transition: 'transform 0.3s ease-in-out',
                      '&:hover': {
                        transform: 'translateY(-10px)',
                      },
                    }}
                  >
                    {lesson.type === 'video' ? (
                      <Box
                        sx={{
                          position: 'relative',
                          width: '100%',
                          height: 200,
                          borderRadius: 2,
                          mb: 2,
                          cursor: 'pointer',
                          background: 'linear-gradient(135deg, #6366f1, #754cbf)',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          transition: 'all 0.3s ease',
                          '&:hover': {
                            transform: 'scale(1.02)',
                          },
                        }}
                        onClick={() => {
                          setSelectedVideoId(lesson.media);
                          setOpenVideoDialog(true);
                        }}
                      >
                        <Box
                          sx={{
                            width: 60,
                            height: 60,
                            borderRadius: '50%',
                            backgroundColor: 'rgba(255, 255, 255, 0.9)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            mb: 2,
                          }}
                        >
                          <PlayArrowIcon sx={{ fontSize: 35, color: '#6366f1' }} />
                        </Box>
                        <Typography
                          variant='subtitle1'
                          sx={{
                            color: 'white',
                            fontWeight: 500,
                          }}
                        >
                          Klicka för att se videon
                        </Typography>
                        <Typography
                          variant='caption'
                          sx={{
                            color: 'rgba(255, 255, 255, 0.8)',
                            display: 'none',
                          }}
                        >
                          Klicka för att se videon
                        </Typography>
                      </Box>
                    ) : (
                      <Box
                        component='img'
                        src={lesson.media}
                        alt={lesson.title}
                        sx={{
                          width: '100%',
                          height: 200,
                          objectFit: 'cover',
                          borderRadius: 2,
                          mb: 2,
                        }}
                      />
                    )}
                    <Typography variant='h5' sx={{ fontWeight: 700, mb: 1 }}>
                      {lesson.title}
                    </Typography>
                    <Typography variant='body1' sx={{ color: 'text.secondary', mb: 2 }}>
                      {lesson.description}
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <IconButton
                        component='a'
                        href={lesson.pdfUrl}
                        target='_blank'
                        sx={{ color: 'primary.main' }}
                      >
                        <PictureAsPdfIcon />
                      </IconButton>
                    </Box>
                  </Paper>
                </Grid>
              ))}

              {/* Add this Dialog component outside the mapping, but inside your main component */}
              <Dialog
                open={openVideoDialog}
                onClose={() => {
                  setOpenVideoDialog(false);
                  setSelectedVideoId(null);
                }}
                maxWidth='md'
                fullWidth
              >
                <DialogContent sx={{ p: 0, bgcolor: 'black' }}>
                  <Box
                    component='iframe'
                    src={
                      selectedVideoId
                        ? `https://www.youtube.com/embed/${selectedVideoId}?autoplay=1`
                        : ''
                    }
                    sx={{
                      width: '100%',
                      aspectRatio: '16/9',
                      border: 0,
                    }}
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                    allowFullScreen
                  />
                </DialogContent>
              </Dialog>
            </Grid>

            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 6 }}>
              <Link to='/app/skapa-konto' style={{ textDecoration: 'none' }}>
                <StyledButton
                  variant='blue'
                  size='large'
                  sx={{
                    borderRadius: '12px',
                    padding: '12px 32px',
                    fontSize: '1.1rem',
                  }}
                  endIcon={<KeyboardDoubleArrowRightIcon />}
                >
                  Se alla lektioner du kan generera
                </StyledButton>
              </Link>
            </Box>
          </Container>
        </Box>

        <Container
          maxWidth='xl'
          sx={{
            paddingTop: 15,
            paddingBottom: 15,
            textAlign: 'center',

            backgroundImage:
              'url("/img/hepp.svg"), radial-gradient(circle, rgb(106, 80, 243), rgb(62, 46, 141) 82%)',
          }}
        >
          <Typography
            variant='h3'
            gutterBottom
            sx={{ fontWeight: 'bold', fontSize: '2.6em', color: 'white', display: 'block', mb: 3 }}
          >
            Prova helt gratis — ingen registrering krävs
          </Typography>
          <Typography variant='h6' sx={{ mb: 4, color: '#E7E7E8' }}>
            Anslut dig till 3000+ lärare och elever som använder Studera.ai
          </Typography>
          <Link to='/app/' style={{ textDecoration: 'none' }}>
            <StyledButton
              variant='blue'
              color='primary'
              size='large'
              endIcon={<KeyboardDoubleArrowRightIcon />}
              sx={{ borderRadius: '12px', padding: '13px 50px', fontSize: '20px' }}
            >
              Prova Studera.AI gratis nu
            </StyledButton>
          </Link>
        </Container>
        <div ref={contactFormRef}>
          <ContactForm />
        </div>

        <TestimonialsSection source='teacher' />
        <Box sx={{ bgcolor: 'background.default', py: 8, mt: 8 }}>
          <Container maxWidth='lg'>
            <Grid container spacing={10} alignItems='center' justifyContent='center'>
              <Grid
                item
                xs={12}
                md={10}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant='h3'
                  align='center'
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '2.3em',
                    mb: 2,
                  }}
                >
                  Vanliga frågor och svar
                </Typography>

                <Typography
                  variant='h6'
                  paragraph
                  color='text.secondary'
                  sx={{ textAlign: 'center', mb: 3 }}
                >
                  Om du har några andra frågor — vänligen{' '}
                  <MuiLink
                    onClick={scrollToContact}
                    sx={{
                      color: 'text.secondary',
                      textDecoration: 'none',
                      cursor: 'pointer',
                      '&:hover': {
                        textDecoration: 'underline',
                      },
                    }}
                  >
                    kontakta oss
                  </MuiLink>
                </Typography>

                <Box sx={{ width: '100%' }}>
                  <StartPageFAQSection />
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>

        <Box sx={{ py: 14, backgroundColor: theme.palette.background.paper }}>
          <TeacherStatsSection />
        </Box>

        <Box sx={{ display: 'none', py: 14, backgroundColor: theme.palette.background.default }}>
          <Container>
            <Typography
              variant='h3'
              align='center'
              sx={{
                fontWeight: 'bold',
                fontSize: '2.3em',
                mb: 3,
              }}
            >
              Hitta rätt prisplan för dig
            </Typography>

            <Typography
              variant='h6'
              paragraph
              color='text.secondary'
              sx={{ textAlign: 'center', display: 'block', mb: 6 }}
            >
              Hittar du ingen prisplan som passar dig — vänligen{' '}
              <MuiLink
                onClick={scrollToContact}
                sx={{
                  color: 'text.secondary',
                  textDecoration: 'none',
                  cursor: 'pointer',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                kontakta oss
              </MuiLink>
            </Typography>

            <PricesSection />
          </Container>
        </Box>
        <BackgroundAndVisionSection contactFormRef={contactFormRef} />
        <Box sx={{ display: 'none' }}>
          {features
            .filter(feature => feature.models && feature.models.length > 0)
            .map(feature => (
              <FeatureSection
                key={feature.title}
                id={feature.title.toLowerCase().replace(/ /g, '-')}
                sx={{
                  bgcolor: 'background.default',
                  padding: { xs: '30px 0px', md: '80px 0px' },
                }}
              >
                <Container>
                  <Grid container spacing={6} alignItems='center'>
                    <Grid item xs={12} md={12} sx={{ textAlign: 'center' }}>
                      <Typography
                        variant='h3'
                        gutterBottom
                        sx={{
                          fontWeight: 'bold',
                          fontSize: { xs: '1.6em', md: '2.3em' },
                          textAlign: 'center',
                        }}
                      >
                        {feature.title}
                      </Typography>
                      <Typography
                        variant='h6'
                        paragraph
                        color='text.secondary'
                        sx={{ textAlign: 'center' }}
                      >
                        {feature.description}
                      </Typography>

                      <Grid
                        container
                        spacing={2}
                        justifyContent='center'
                        sx={{ marginTop: '20px' }}
                      >
                        {feature.models.map((model, idx) => (
                          <Grid item xs={12} sm={6} md={4} key={idx}>
                            <Card
                              sx={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                padding: 2,
                                boxShadow: 'none',
                                transition: 'transform 0.3s, box-shadow 0.3s',
                                '&:hover': {
                                  transform: 'translateY(-10px)',
                                },
                              }}
                            >
                              <CardContent sx={{ textAlign: 'center' }}>
                                <Box
                                  component='img'
                                  src={getAIModelImage(model.name)}
                                  alt={model.name}
                                  sx={{ width: 60, height: 60, mb: 2, borderRadius: '9px' }}
                                />
                                <Typography variant='h6' gutterBottom>
                                  {model.name}
                                </Typography>
                                <Typography variant='body2' color='text.secondary'>
                                  {model.description}
                                </Typography>
                              </CardContent>
                            </Card>
                          </Grid>
                        ))}
                      </Grid>

                      <Box mt={4} display='flex' justifyContent='center'>
                        <Link
                          to={
                            feature.goDirectlyToApp
                              ? '/app/skapa-konto'
                              : `/funktioner/${feature.title.toLowerCase().replace(/ /g, '-').replace(/å/g, 'a').replace(/ä/g, 'a').replace(/ö/g, 'o')}`
                          }
                          style={{ textDecoration: 'none' }}
                        >
                          <StyledButton
                            variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                            color='primary'
                            endIcon={<WestIcon />}
                          >
                            Lär dig mer
                          </StyledButton>
                        </Link>
                      </Box>
                    </Grid>
                  </Grid>
                </Container>
              </FeatureSection>
            ))}
        </Box>

        {/*<MediaSection  bgcolor='background.default'  />*/}
        <Footer contactFormRef={contactFormRef} />
      </Box>
      {!isMobile && (
        <Box
          sx={{
            position: 'fixed',
            bottom: 16,
            right: 16,
            zIndex: 9999,
          }}
        >
          <IconButton onClick={toggleDarkMode}>
            {isDark ? <LightModeIcon /> : <DarkModeIcon />}
          </IconButton>
        </Box>
      )}
    </>
  );
};

export default ModernStuderaAIWebsite;
