import React from 'react';
import { Box, Container, Typography, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/system';

const ScrollContainer = styled(Box)({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  width: '100%',
});

const ScrollContent = styled(Box)({
  display: 'inline-block',
  whiteSpace: 'nowrap',
  animation: 'scroll 70s linear infinite',
  '@keyframes scroll': {
    '0%': { transform: 'translateX(0%)' },
    '100%': { transform: 'translateX(-50%)' },
  },
});

const UniversityLogoWrapper = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '200px', // Adjust as needed
  height: '120px',
  margin: '0 30px',
  padding: theme.spacing(2),
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  borderRadius: '11px',
  verticalAlign: 'middle',
}));

const UniversityLogo = styled('img')({
  maxWidth: '100%',
  maxHeight: '100%',
  objectFit: 'contain',
});

const UniversitySection = ({ bgcolor, showHeadline = true }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const universities = [
    // { name: 'Lunds universitet', logo: '/img/lund.png' },
    // { name: 'Uppsala universitet', logo: '/img/uppsala.png' },

    { name: 'Byängsskolan', logo: '/img/Byangsskolan.png' },
    { name: 'Holavedsgymnasiet', logo: '/img/Holavedsgymnasiet.png' },
    { name: 'Gustavslundsskolan', logo: '/img/Gustavslundsskolan.jpg' },
    { name: 'Vittra Röda Stan', logo: '/img/Vittra_Roda_Stan.png' },
    { name: 'Gottsundaskolan', logo: '/img/Gottsundaskolan.jpg' },
    //{ name: 'Göteborgs universitet', logo: '/img/goteborg.png' },
    // { name: 'Linköpings universitet', logo: '/img/linkoping.png' },
    { name: 'Ekebyskolan', logo: '/img/Ekebyskolan.png' },
    { name: 'Bladins grundskola', logo: '/img/Bladins_grundskola.png' },
    { name: 'Fridaskolan', logo: '/img/Fridaskolan.png' },
    { name: 'Sätraskolan', logo: '/img/Satraskolan.png' },
    { name: 'Vastbergaskolan', logo: '/img/vastbergaskolan.png' },
    // { name: 'Umeå universitet', logo: '/img/umea.png' },

    { name: 'Montessori Lindängen', logo: '/img/Montessori_Lindangen.jpg' },
    { name: 'Kristofferskolan', logo: '/img/kristofferskolan.png' },
    { name: 'Viksangsskolan', logo: '/img/Viksangsskolan.png' },
    { name: 'British Junior', logo: '/img/british_junio.png' },
    //{ name: 'Kungliga Tekniska högskolan', logo: '/img/kth.png' },
    { name: 'Sköldinge skola', logo: '/img/Skoldinge_skola.png' },
    { name: 'Malmabergsskolan', logo: '/img/Malmabergsskolan.png' },
    { name: 'Teknikum', logo: '/img/teknikum.jpg' },
    { name: 'Lugnetgymnasiet', logo: '/img/Lugnetgymnasiet.png' },
    { name: 'Fredriksbergsskolan', logo: '/img/Fredriksbergsskolan.png' },
  ];

  return (
    <Box
      sx={{
        bgcolor: bgcolor,
        py: showHeadline ? 8 : 8,
        paddingBottom: showHeadline ? undefined : '60px',
      }}
    >
      <Container maxWidth='xl'>
        <Typography
          variant='h3'
          align='center'
          sx={{
            fontWeight: 'bold',
            fontSize: '2.3em',
            mb: 2,
            display: showHeadline ? 'block' : 'none',
          }}
        >
          Används av 3000+ lärare och elever
        </Typography>

        <Typography
          variant='h6'
          paragraph
          color='text.secondary'
          sx={{ textAlign: 'center', mb: showHeadline ? 6 : 3 }}
        >
          Över 400 skolor använder Studera.ai just nu
        </Typography>

        <ScrollContainer>
          <ScrollContent>
            {[...universities, ...universities].map((university, index) => (
              <UniversityLogoWrapper key={`${university.name}-${index}`}>
                <UniversityLogo
                  src={university.logo}
                  alt={`${university.name} logotyp`}
                  title={university.name}
                />
              </UniversityLogoWrapper>
            ))}
          </ScrollContent>
        </ScrollContainer>
      </Container>
    </Box>
  );
};

export default UniversitySection;
