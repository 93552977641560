import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Container,
  Typography,
  Grid,
  Box,
  useTheme,
  useMediaQuery,
  IconButton,
  Chip,
} from '@mui/material';

import { StyledButton, GradientBackground } from './StyledComponents';
import { Link } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';

import { motion } from 'framer-motion';
import DashboardLessonsShortcuts from './app/DashboardLessonsShortcuts';

import LightModeIcon from '@mui/icons-material/LightMode';
import FAQSection from './FAQSection';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import TestimonialsSection from './TestimonialsSection';
import { getAIModelImage } from './app/TestComponents2';
import UniversitySection from './UniversitySection';
import MediaSection from './MediaSection';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { getStyles } from './index';
import TeacherStatsSection from './TeacherStatsSection';
import TeacherFAQSection from './TeacherFAQSection';

const LessonsPage = ({ toggleDarkMode, isDark }) => {
  const theme = useTheme();
  const styles = getStyles(theme);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>{`AI-verktyg för lärare & pedagoger | Studera.ai`}</title>
        <meta
          name='description'
          content='Hitta alla AI-verktyg för lärare, pedagoger och vikarier. Spara oceaner av tid genom att använda AI i skolan.'
        />
      </Helmet>

      <Header />

      <GradientBackground className='gradient-background'>
        <Container maxWidth='xl' sx={{ pt: { xs: 10, md: 20 }, pb: 15, position: 'relative' }}>
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={12} md={6}>
              <Box sx={{ pl: { xs: 0, md: 4 } }}>
                <motion.div
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8 }}
                >
                  <Box sx={{ display: 'flex', mb: 2 }}>
                    <Chip
                      label={
                        <span>
                          Studera.ai{' '}
                          <span style={{ opacity: 0.7 }}>
                            &nbsp;•&nbsp; Revolutionerar lärande med AI
                          </span>
                        </span>
                      }
                      sx={{
                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                        color: 'white',
                        fontWeight: '600',
                        fontSize: '0.8rem',
                        padding: '2px 14px',

                        borderRadius: '16px',
                        '& .MuiChip-label': {
                          padding: '0 4px',
                        },
                      }}
                    />
                  </Box>

                  <Typography
                    variant='h2'
                    component='h1'
                    gutterBottom
                    sx={{
                      fontWeight: 900,
                      letterSpacing: -1,
                      fontSize: { xs: '2.5rem', md: '3.35rem' },
                      mb: 5,
                    }}
                  >
                    AI-verktyg för lärare & pedagoger
                  </Typography>
                  <Typography
                    sx={{
                      mb: 4,
                      opacity: 0.8,
                      fontSize: '1.3rem',
                      fontWeight: '500',
                      lineHeight: '2rem',
                    }}
                  >
                    Spara värdefull tid genom att låta våra AI-verktyg för lärare hjälpa dig i
                    skapandet av pedagogiskt material. Oavsett om du behöver skapa lektioner,
                    lektionsplaner, övningar eller utveckla bedömningsunderlag kan Studera.ai hjälpa
                    dig att effektivisera ditt arbete med hjälp av AI.
                  </Typography>

                  <Link to='/app' style={{ textDecoration: 'none' }}>
                    <StyledButton
                      variant='blue'
                      color='primary'
                      size='large'
                      endIcon={<KeyboardDoubleArrowRightIcon />}
                      sx={{ borderRadius: '28px', padding: '13px 50px', fontSize: '20px' }}
                    >
                      Testa Studera.ai helt gratis
                    </StyledButton>
                  </Link>
                </motion.div>
              </Box>
            </Grid>

            {/* Image on the right */}
            <Grid
              item
              xs={12}
              md={6}
              sx={{ position: 'relative' }}
              sx={{ display: { xs: 'none', md: 'block' } }}
            >
              <motion.div
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.8, delay: 0.2 }}
              >
                <Link to='/app' style={{ textDecoration: 'none' }}>
                  <Box
                    component='img'
                    src={theme.palette.mode === 'dark' ? '/img/d.png' : '/img/l.png'}
                    alt='Studera med AI'
                    sx={{
                      width: 'calc(100% + 100px)', // Stretch the image beyond its container
                      height: 'auto',
                      borderRadius: '7px',
                      position: 'relative',
                      right: '-50px', // Moves the image to touch the scrollbar
                    }}
                  />
                </Link>
              </motion.div>
            </Grid>
          </Grid>
        </Container>
      </GradientBackground>

      {/*
      <GradientBackground className='gradient-background'>
        <Container
          sx={{ pt: { xs: 10, md: 20 }, pb: 15, position: 'relative', textAlign: 'center' }}
        >
          <Grid container spacing={6} alignItems='center'>
            <Grid item xs={12} md={12} sx={{ textAlign: 'center' }}>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                  <Chip
                    label={
                      <span>
                        Studera.ai{' '}
                        <span style={{ opacity: 0.7 }}>
                          &nbsp;•&nbsp; Revolutionerar lärande med AI
                        </span>
                      </span>
                    }
                    sx={{
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                      color: 'white',
                      fontWeight: '600',
                      fontSize: '0.8rem',
                      padding: '2px 14px',

                      borderRadius: '16px',
                      '& .MuiChip-label': {
                        padding: '0 4px',
                      },
                    }}
                  />
                </Box>

                <Typography
                  variant='h2'
                  component='h1'
                  gutterBottom
                  sx={{
                    fontWeight: 900,
                    letterSpacing: -1,
                    fontSize: { xs: '2.5rem', md: '3.35rem' },
                    width: { xs: '100%', md: '70%' },
                    textAlign: 'center',
                    display: 'block',
                    margin: '0 auto',
                  }}
                >
                  AI-verktyg för lärare & pedagoger
                </Typography>
                <Typography
                  variant='h5'
                  sx={{
                    mb: 4,
                    opacity: 0.8,
                    fontSize: '1.3rem',
                    width: { xs: '100%', md: '70%' },
                    margin: '0 auto',
                    fontWeight: '500',
                    lineHeight: '31px',
                    display: 'block',
                    mt: 4,
                  }}
                >
                  Spara värdefull tid genom att låta AI assistera dig i skapandet av pedagogiskt
                  material. Oavsett om du behöver planera lektioner, skapa övningar eller utveckla
                  bedömningsunderlag kan Studera.ai hjälpa dig att effektivisera ditt arbete - så
                  att du kan fokusera på det som betyder mest: dina elever.
                </Typography>

                <Box sx={{ textAlign: 'center', mt: 7 }}>
                  <Link to='/app' style={{ textDecoration: 'none' }}>
                    <StyledButton
                      variant='contained'
                      color='primary'
                      size='large'
                      endIcon={<ArrowForwardIcon />}
                    >
                      Testa Studera.ai nu
                    </StyledButton>
                  </Link>
                </Box>
              </motion.div>
            </Grid>
          </Grid>
        </Container>
      </GradientBackground>
*/}
      <Container id='om-studera-ai' sx={{ my: 15, textAlign: 'center' }}>
        <Typography
          variant='h2'
          gutterBottom
          sx={{ fontWeight: 'bold', fontSize: '2.3em', mb: 2, mt: 0 }}
        >
          Lektioner du kan skapa med AI
        </Typography>
        <Typography
          variant='body1'
          sx={{
            display: 'block',
            color: 'text.secondary',
            fontWeight: '400',
            fontSize: '1.15rem',
            lineHeight: '1.6',
            width: '80%',
            margin: '0 auto',
            mb: 7,
          }}
        >
          Utforska vilka typer av lektioner du som lärare, pedagog & vikarie kan generera för att
          skapa engagerande studiematerial på nolltid.
        </Typography>

        <Box>
          <Box sx={{ ...styles.newBox, padding: '20px', paddingTop: '0px', width: '100%' }}>
            <DashboardLessonsShortcuts theme={theme} role='teacher' />
          </Box>
        </Box>

        <Typography
          variant='h2'
          gutterBottom
          sx={{ fontWeight: 'bold', fontSize: '2.3em', mb: 2, mt: 20 }}
        >
          Läsförståelse
        </Typography>
        <Typography
          variant='body1'
          sx={{
            display: 'block',
            color: 'text.secondary',
            fontWeight: '400',
            fontSize: '1.15rem',
            lineHeight: '1.6',
            width: '80%',
            margin: '0 auto',
            mb: 7,
          }}
        >
          Här hittar du olika typer av lektioner för att förbättra elevers läsförståelse.
        </Typography>

        <Box>
          <Box sx={{ ...styles.newBox, padding: '20px', paddingTop: '0px', width: '100%' }}>
            <DashboardLessonsShortcuts category='Läsförståelse' theme={theme} role='teacher' />
          </Box>
        </Box>

        <Typography
          variant='h2'
          gutterBottom
          sx={{ fontWeight: 'bold', fontSize: '2.3em', mb: 2, mt: 20 }}
        >
          Hjälpsamma AI-verktyg
        </Typography>
        <Typography
          variant='body1'
          sx={{
            display: 'block',
            color: 'text.secondary',
            fontWeight: '400',
            fontSize: '1.15rem',
            lineHeight: '1.6',
            width: '80%',
            margin: '0 auto',
            mb: 7,
          }}
        >
          AI-verktyg för att t.ex. generera bilder och powerpoints.
        </Typography>

        <Box>
          <Box sx={{ ...styles.newBox, padding: '20px', paddingTop: '0px', width: '100%' }}>
            <DashboardLessonsShortcuts category='Hjälpmedel' theme={theme} role='teacher' />
          </Box>
        </Box>

        <Typography
          variant='h2'
          gutterBottom
          sx={{ fontWeight: 'bold', fontSize: '2.3em', mb: 2, mt: 20 }}
        >
          Planering
        </Typography>
        <Typography
          variant='body1'
          sx={{
            display: 'block',
            color: 'text.secondary',
            fontWeight: '400',
            fontSize: '1.15rem',
            lineHeight: '1.6',
            width: '80%',
            margin: '0 auto',
            mb: 7,
          }}
        >
          Skapa lektionsplaner baserat på tid och ämne.
        </Typography>

        <Box>
          <Box sx={{ ...styles.newBox, padding: '20px', paddingTop: '0px', width: '100%' }}>
            <DashboardLessonsShortcuts category='Planering' theme={theme} role='teacher' />
          </Box>
        </Box>

        <Typography
          variant='h2'
          gutterBottom
          sx={{ fontWeight: 'bold', fontSize: '2.3em', mb: 2, mt: 20 }}
        >
          Quiz
        </Typography>
        <Typography
          variant='body1'
          sx={{
            display: 'block',
            color: 'text.secondary',
            fontWeight: '400',
            fontSize: '1.15rem',
            lineHeight: '1.6',
            width: '80%',
            margin: '0 auto',
            mb: 7,
          }}
        >
          Skapa AI-genererade quiz baserat på lektionsmaterial, valfritt ämne eller föreläsning från
          YouTube.
        </Typography>

        <Box>
          <Box sx={{ ...styles.newBox, padding: '20px', paddingTop: '0px', width: '100%' }}>
            <DashboardLessonsShortcuts category='Quiz' theme={theme} role='teacher' />
          </Box>
        </Box>

        <Typography
          variant='h2'
          gutterBottom
          sx={{ fontWeight: 'bold', fontSize: '2.3em', mb: 2, mt: 20 }}
        >
          AI-verktyg för textanalys
        </Typography>
        <Typography
          variant='body1'
          sx={{
            display: 'block',
            color: 'text.secondary',
            fontWeight: '400',
            fontSize: '1.15rem',
            lineHeight: '1.6',
            width: '80%',
            margin: '0 auto',
            mb: 7,
          }}
        >
          För dig som behöver rätta eller faktakolla en text.
        </Typography>

        <Box>
          <Box sx={{ ...styles.newBox, padding: '20px', paddingTop: '0px', width: '100%' }}>
            <DashboardLessonsShortcuts category='Textanalys' theme={theme} role='teacher' />
          </Box>
        </Box>

        <Typography
          variant='h2'
          gutterBottom
          sx={{ fontWeight: 'bold', fontSize: '2.3em', mb: 2, mt: 20 }}
        >
          Bedömning
        </Typography>
        <Typography
          variant='body1'
          sx={{
            display: 'block',
            color: 'text.secondary',
            fontWeight: '400',
            fontSize: '1.15rem',
            lineHeight: '1.6',
            width: '80%',
            margin: '0 auto',
            mb: 7,
          }}
        >
          Skapa anpassningsbara bedömningsmatriser och bedöm texter utifrån fördefinierade eller
          skapade matriser.
        </Typography>

        <Box sx={{ mb: 10 }}>
          <Box sx={{ ...styles.newBox, padding: '20px', paddingTop: '0px', width: '100%' }}>
            <DashboardLessonsShortcuts category='Bedömning' theme={theme} role='teacher' />
          </Box>
        </Box>

        <Link to='/app' style={{ textDecoration: 'none' }}>
          <StyledButton
            variant='contained'
            color='primary'
            size='large'
            endIcon={<ArrowForwardIcon />}
          >
            Börja din resa nu
          </StyledButton>
        </Link>
      </Container>

      {/* Stats Section */}
      <Box sx={{ py: 10, backgroundColor: theme.palette.background.paper }}>
        <TeacherStatsSection />
      </Box>

      <TeacherFAQSection />

      <UniversitySection bgcolor='background.default' showHeadline={false} />

      <TestimonialsSection source='teacher' />

      <MediaSection bgcolor='background.default' />

      <Footer />

      {!isMobile && (
        <Box
          sx={{
            position: 'fixed',
            bottom: 16,
            right: 16,
            zIndex: 9999,
          }}
        >
          <IconButton onClick={toggleDarkMode}>
            {isDark ? <LightModeIcon /> : <DarkModeIcon />}
          </IconButton>
        </Box>
      )}
    </>
  );
};

export default LessonsPage;
