import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
  CircularProgress,
  Fade,
  Divider,
  Tooltip,
} from '@mui/material';
import { callApi } from './api'; // Adjust import path as needed
import ReactMarkdown from 'react-markdown';
import InfoBox from './InfoBox';
import { useTheme } from '@mui/material/styles';
import GenerateIcon from '@mui/icons-material/AutoAwesome';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import ImageIcon from '@mui/icons-material/Image';
import CharacterMindMap from './CharacterMindMap'; // Adjust the import path as needed
import { generateAndProcessImage, trackLesson } from './functions'; // New import from TermsGrid
import { useSnackbar } from './SnackbarHandler'; // New import from TermsGrid
import { OrbitProgress } from 'react-loading-indicators'; // New import from TermsGrid
import { handlePrintNarrativeLesson } from './printFunctions';
import { getStyles } from '../index';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PrintIcon from '@mui/icons-material/Print';
import { ThemeSelectorFlow } from './ThemeSelectorFlow';
import EditIcon from '@mui/icons-material/Edit';
import LanguageSelector from './LanguageSelector';

import { CustomFormControlLabel } from './functions';
import { ThemeSelector } from './ThemeSelector';
import ImageGenerationModal from './ImageGenerationModal';
import GradeSelector, { gradeInstructions } from './GradeSelector';
import Logger from './Logger';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';

import NarrativeLessonStoryGenerator from './NarrativeLessonStoryGenerator';

import LessonDrawer from './LessonDrawer';
import SaveLessonDialog from './SaveLessonDialog';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import HistoryIcon from '@mui/icons-material/History';
import HoverableImage from './HoverableImage';

import {
  Forest,
  Castle,
  Rocket,
  Pets,
  SailingOutlined,
  Water,
  Celebration,
  AutoFixHigh,
  Favorite,
  SupervisedUserCircle,
} from '@mui/icons-material';

const NarrativeLesson = ({ user }) => {
  const [goal, setGoal] = useState('');
  const [editingSections, setEditingSections] = useState({});
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [develop, setDevelop] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [lessonTheme, setLessonTheme] = useState('generateWithAi');
  const [customTheme, setCustomTheme] = useState('');
  const [isGeneratingInfo, setIsGeneratingInfo] = useState(false);
  const [isGeneratingPlan, setIsGeneratingPlan] = useState(false);
  const [isGeneratingImages, setIsGeneratingImages] = useState(false);
  const [isGeneratingStoryImages, setIsGeneratingStoryImages] = useState(false);
  const [lessonPlanKey, setLessonPlanKey] = useState(0);
  const [themeColor, setThemeColor] = useState('#000000');
  const [planData, setPlanData] = useState(null);
  const theme = useTheme();
  const styles = getStyles(theme);
  const { showSnackbar } = useSnackbar(); // New hook from TermsGrid
  const [generatedCharacterImages, setGeneratedCharacterImages] = useState(new Set());
  const [grade, setGrade] = useState('åk6');
  const [language, setLanguage] = useState('svenska');
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageInstructions, setImageInstructions] = useState('');
  const [imageGenerationType, setImageGenerationType] = useState(null);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);

  const [generatingCharacterIndex, setGeneratingCharacterIndex] = useState(null);
  const [generatingSceneIndex, setGeneratingSceneIndex] = useState(null);
  const [isGeneratingMainStory, setIsGeneratingMainStory] = useState(false);

  const [predefinedTheme, setPredefinedTheme] = useState(null);
  const lessonTitleRef = useRef(null); // Add this ref
  const transcriptRef = useRef('');
  const uploadedFileRef = useRef('');

  const wikipediaContentRef = useRef('');
  const randomizedContentRef = useRef('');
  const coursePlanContentRef = useRef('');

  const additionalThemes = [
    { type: 'subheader', label: 'Teman som lämpar sig extra bra för denna lektionstyp:' },
    {
      value: 'den-magiska-skogen',
      label: 'Den magiska skogen',
      content: 'Den magiska skogen',
      Icon: Forest,
    },
    {
      value: 'prinsessan-och-draken',
      label: 'Prinsessan och draken',
      content: 'Prinsessan och draken',
      Icon: Castle,
    },
    {
      value: 'rymdaventyret',
      label: 'Rymdäventyret',
      content: 'Rymdäventyret',
      Icon: Rocket,
    },
    {
      value: 'den-lilla-nyfikna-katten',
      label: 'Den lilla nyfikna katten',
      content: 'Den lilla nyfikna katten',
      Icon: Pets,
    },
    {
      value: 'skattjakten-pa-piraton',
      label: 'Skattjakten på piratön',
      content: 'Skattjakten på piratön',
      Icon: SailingOutlined,
    },
    {
      value: 'alvens-hemlighet',
      label: 'Älvens hemlighet',
      content: 'Älvens hemlighet',
      Icon: Water,
    },
    {
      value: 'djurens-stora-fest',
      label: 'Djurens stora fest',
      content: 'Djurens stora fest',
      Icon: Celebration,
    },
    {
      value: 'det-fortrollade-slottet',
      label: 'Det förtrollade slottet',
      content: 'Det förtrollade slottet',
      Icon: AutoFixHigh,
    },
    {
      value: 'vanskapens-kraft',
      label: 'Vänskapens kraft',
      content: 'Vänskapens kraft',
      Icon: Favorite,
    },
    {
      value: 'den-osynliga-hjalten',
      label: 'Den osynliga hjälten',
      content: 'Den osynliga hjälten',
      Icon: SupervisedUserCircle,
    },
  ];

  useEffect(() => {
    Logger.log('lessonTheme: ', lessonTheme);
  }, [lessonTheme]);

  useEffect(() => {
    Logger.log('predefinedTheme: ', predefinedTheme);
  }, [predefinedTheme]);

  useEffect(() => {
    Logger.log('customTheme: ', customTheme);
  }, [customTheme]);

  useEffect(() => {
    Logger.log('youtubeTranscript: ', transcriptRef.current);
  }, [transcriptRef.current]);

  useEffect(() => {
    Logger.log('wikipediaContentRef: ', wikipediaContentRef.current);
  }, [wikipediaContentRef.current]);
  useEffect(() => {
    Logger.log('uploadedFileRef: ', uploadedFileRef.current);
  }, [uploadedFileRef.current]);

  useEffect(() => {
    Logger.log('randomizedContentRef: ', randomizedContentRef.current);
  }, [randomizedContentRef.current]);

  useEffect(() => {
    Logger.log('coursePlanContentRef: ', coursePlanContentRef.current);
  }, [coursePlanContentRef.current]);

  const handleSectionDelete = section => {
    setPlanData(prev => {
      const newData = { ...prev };
      if (section === 'characters') {
        // Set to empty array instead of deleting
        newData.whoOrWhich = [];
        newData.headlines.characters = '';
      } else if (section === 'locations') {
        // Set to empty array instead of deleting
        newData.where = [];
        newData.headlines.locations = '';
      } else if (section === 'actions') {
        // Set to empty array instead of deleting
        newData.what = [];
        newData.headlines.actions = '';
      }
      return newData;
    });
    showSnackbar('Sektionen har tagits bort', 'success');
  };

  const handleSectionEdit = section => {
    setEditingSections(prev => ({
      ...prev,
      [section]: true,
    }));
  };

  const handleSectionSave = section => {
    setEditingSections(prev => ({
      ...prev,
      [section]: false,
    }));
    showSnackbar('Sektionen har uppdaterats', 'success');
  };

  const handleContentUpdate = (section, newContent) => {
    setPlanData(prev => {
      if (section === 'characters') {
        return {
          ...prev,
          whoOrWhich: newContent.map(item => ({ name: item })),
          headlines: {
            ...prev.headlines,
            characters: prev.headlines?.characters || 'Karaktärer (Vem eller vilka)',
          },
        };
      } else if (section === 'locations') {
        return {
          ...prev,
          where: newContent,
          headlines: {
            ...prev.headlines,
            locations: prev.headlines?.locations || 'Miljöer (Var)',
          },
        };
      } else if (section === 'actions') {
        return {
          ...prev,
          what: newContent,
          headlines: {
            ...prev.headlines,
            actions: prev.headlines?.actions || 'Handlingsidéer (Vad)',
          },
        };
      }
      return prev;
    });
  };

  const handleHeadlineUpdate = (section, newHeadline) => {
    setPlanData(prev => ({
      ...prev,
      headlines: {
        ...prev.headlines,
        [section]: newHeadline,
      },
    }));
  };

  const renderEditableSection = (section, title, content, headlineKey) => {
    const isEditing = editingSections[section];
    const contentArray = section === 'characters' ? content.map(item => item.name) : content;

    return (
      <Box
        sx={{
          position: 'relative',
          marginTop: 7,
          '&:hover': {
            '& .section-controls': {
              opacity: 1,
              visibility: 'visible',
            },
          },
        }}
      >
        <Box
          className='section-controls'
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            opacity: 0,
            visibility: 'hidden',
            transition: 'all 0.2s ease',
            display: 'flex',
            gap: 1,
            zIndex: 1,
          }}
        >
          <Tooltip title={isEditing ? 'Spara' : 'Redigera'} placement='top'>
            <IconButton
              size='small'
              onClick={() => (isEditing ? handleSectionSave(section) : handleSectionEdit(section))}
            >
              {isEditing ? <SaveAsIcon fontSize='small' /> : <EditIcon fontSize='small' />}
            </IconButton>
          </Tooltip>
          {section !== 'characters' && (
            <Tooltip title='Ta bort sektion' placement='top'>
              <IconButton size='small' onClick={() => handleSectionDelete(section)}>
                <DeleteOutlineIcon fontSize='small' />
              </IconButton>
            </Tooltip>
          )}
        </Box>
        {isEditing ? (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              value={planData.headlines?.[headlineKey] || title}
              onChange={e => handleHeadlineUpdate(headlineKey, e.target.value)}
              sx={{ ...styles.editingTextField, width: '80%' }}
              InputProps={{
                sx: {
                  fontSize: '1.4rem',
                  fontWeight: '600',
                },
              }}
            />
            <Box
              sx={{
                backgroundColor: theme => (theme.palette.mode === 'dark' ? '#15202b' : '#e0e0e029'),
                borderRadius: '10px',
                padding: '20px',
              }}
            >
              {contentArray.map((item, i) => (
                <TextField
                  key={i}
                  fullWidth
                  multiline
                  value={item}
                  onChange={e => {
                    const newItems = [...contentArray];
                    newItems[i] = e.target.value;
                    handleContentUpdate(section, newItems);
                  }}
                  sx={{
                    mb: 2,
                    '& .MuiInputBase-root': {
                      fontSize: '1rem',
                      lineHeight: '1.5',
                    },
                    ...styles.editingTextField,
                  }}
                />
              ))}
              <Button
                variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                color='primary'
                startIcon={<SaveAsIcon />}
                onClick={() => handleSectionSave(section)}
                sx={{ mt: 2, textTransform: 'none' }}
              >
                Spara ändringar
              </Button>
            </Box>
          </Box>
        ) : (
          <>
            <Typography
              variant='h5'
              sx={{
                color: themeColor,
                mb: 2,
                fontWeight: '600',
                fontSize: '1.4rem',
              }}
            >
              {planData.headlines?.[headlineKey] || title}
            </Typography>
            <Box
              sx={{
                backgroundColor: theme => (theme.palette.mode === 'dark' ? '#15202b' : '#e0e0e029'),
                borderRadius: '10px',
                padding: '20px',
              }}
            >
              <ul style={{ marginBottom: '2rem' }}>
                {contentArray.map((item, i) => (
                  <li key={i} style={{ marginBottom: '0.5rem' }}>
                    <Typography variant='body1'>{item}</Typography>
                  </li>
                ))}
              </ul>
            </Box>
          </>
        )}
      </Box>
    );
  };

  const handleGenerateCharacterImage = async (characterIndex, prompt) => {
    setGeneratingCharacterIndex(characterIndex); // Only affects character images
    try {
      const imageUrl = await generateAndProcessImage(prompt, user.uid, {
        size: '1024x1024',
        model: 'dall-e-3',
        quality: 'standard',
      });

      const updatedPlanData = { ...planData };
      updatedPlanData.whoOrWhich[characterIndex] = {
        ...updatedPlanData.whoOrWhich[characterIndex],
        imageUrl: imageUrl,
      };

      setPlanData(updatedPlanData);
      setGeneratedCharacterImages(prev => new Set([...prev, characterIndex]));
      setLessonPlanKey(prevKey => prevKey + 1);
      showSnackbar(
        `Genererat karaktärsbild ${characterIndex + 1} av ${planData.whoOrWhich.length}`,
        'success'
      );
    } catch (error) {
      console.error(`Error generating image for character ${characterIndex}:`, error);
      showSnackbar('Ett fel uppstod vid bildgenereringen', 'error');
    } finally {
      setGeneratingCharacterIndex(null);
    }
  };

  const handleGenerateStoryImage = async prompt => {
    setIsGeneratingMainStory(true); // Only affects main story image
    try {
      const imageUrl = await generateAndProcessImage(prompt, user.uid, {
        size: '1024x1024',
        model: 'dall-e-3',
        quality: 'standard',
      });

      setPlanData(prevData => ({
        ...prevData,
        storyImage: imageUrl,
      }));
      setLessonPlanKey(prevKey => prevKey + 1);
      showSnackbar('Huvudbild har genererats', 'success');
    } catch (error) {
      console.error('Error generating story image:', error);
      showSnackbar('Kunde inte generera huvudbild', 'error');
    } finally {
      setIsGeneratingMainStory(false);
    }
  };

  const handleGenerateIndividualStoryImage = async (index, prompt) => {
    setGeneratingSceneIndex(index); // Only affects individual scene images
    try {
      const imageUrl = await generateAndProcessImage(prompt, user.uid, {
        size: '1024x1024',
        model: 'dall-e-3',
        quality: 'standard',
      });

      setPlanData(prevData => ({
        ...prevData,
        additionalStoryImages: prevData.additionalStoryImages.map((img, i) =>
          i === index ? { imageUrl: imageUrl } : img
        ),
      }));
      setLessonPlanKey(prevKey => prevKey + 1);
      showSnackbar(`Scenbild ${index + 1} har genererats`, 'success');
    } catch (error) {
      console.error(`Error generating scene image ${index + 1}:`, error);
      showSnackbar(`Kunde inte generera scenbild ${index + 1}`, 'error');
    } finally {
      setGeneratingSceneIndex(null);
    }
  };

  const handleImageUpdate = (type, index, newImageUrl) => {
    if (type === 'character-names') {
      setPlanData(prevData => ({
        ...prevData,
        whoOrWhich: newImageUrl,
      }));
    } else if (type === 'character') {
      const updatedPlanData = { ...planData };
      updatedPlanData.whoOrWhich[index] = {
        ...updatedPlanData.whoOrWhich[index],
        imageUrl: newImageUrl,
      };
      setPlanData(updatedPlanData);
    } else if (type === 'main-story') {
      // CHANGED from 'story' when index is undefined
      setPlanData(prevData => ({
        ...prevData,
        storyImage: newImageUrl,
      }));
    } else if (type === 'scene') {
      // CHANGED from 'story' when index is defined
      const updatedPlanData = { ...planData };
      updatedPlanData.additionalStoryImages[index] = {
        ...updatedPlanData.additionalStoryImages[index],
        imageUrl: newImageUrl,
      };
      setPlanData(updatedPlanData);
    }
    setLessonPlanKey(prevKey => prevKey + 1);
  };
  const handleSaveLessonClick = () => {
    setSaveDialogOpen(true);
  };

  const handleSaveWithComment = async (comment, name, coverImage, tags) => {
    Logger.log(JSON.stringify(planData, null, 2));
    await trackLesson(
      user.uid,
      'NarrativeLesson.js',
      planData,
      null,
      true,
      comment,
      name,
      coverImage,
      tags
    );
    setDrawerOpen(true);
    showSnackbar('Lektionen sparad i din lektionsbank!', 'success');
  };

  const handleImageButtonClick = type => {
    setImageGenerationType(type);
    setShowImageModal(true);
  };

  const handleCloseModal = () => {
    setShowImageModal(false);
    setImageInstructions('');
  };

  const handleHoverableImageClick = (type, index) => {
    setImageGenerationType(type);
    // Store the index for later use in handleGenerateWithInstructions
    if (type === 'character') {
      setGeneratingCharacterIndex(index);
    } else if (type === 'scene') {
      setGeneratingSceneIndex(index);
    }
    setShowImageModal(true);
  };

  const handleGenerateWithInstructions = async () => {
    handleCloseModal();

    if (imageGenerationType === 'character') {
      const index = generatingCharacterIndex; // Get the stored index
      setGeneratingCharacterIndex(index);
      const character = planData.whoOrWhich[index];
      const prompt = `Create a portrait image of a character described as "${character.name}". The character should be expressive and appealing. Do not include any text in the image. ${imageInstructions}`;
      await handleGenerateCharacterImage(index, prompt);
    } else if (imageGenerationType === 'main-story') {
      setIsGeneratingMainStory(true);
      const prompt = `Create an image representing the story theme "${planData.storyTitle}". Do not include any text in the image. ${imageInstructions}`;
      await handleGenerateStoryImage(prompt);
    } else if (imageGenerationType === 'scene') {
      const index = generatingSceneIndex; // Get the stored index
      setGeneratingSceneIndex(index);
      const scenePrompts = [
        `Create an establishing shot showing the environment "${planData.where[0]}" for the story "${planData.storyTitle}". Style: cinematic, engaging, no text. ${imageInstructions}`,
        `Create a dramatic scene showing "${planData.what[0]}" from the story "${planData.storyTitle}". Style: action-oriented, dynamic, no text. ${imageInstructions}`,
        `Create a climactic scene combining elements of "${planData.where[0]}" and "${planData.what[1]}" from the story. Style: dramatic, atmospheric, no text. ${imageInstructions}`,
      ];
      await handleGenerateIndividualStoryImage(index, scenePrompts[index]);
    }
  };

  const handleGeneratePlan = async () => {
    setIsGeneratingPlan(true);
    setPlanData(null);
    setGeneratedCharacterImages(new Set());
    const gradeInstructionsText = gradeInstructions[grade];
    try {
      let themeToUse;

      if (lessonTheme === 'youtube') {
        themeToUse = transcriptRef.current;
      } else if (lessonTheme === 'customTheme') {
        themeToUse = customTheme;
      } else if (lessonTheme === 'predefined') {
        themeToUse = predefinedTheme;
      } else if (lessonTheme === 'fileUpload') {
        themeToUse = uploadedFileRef.current;
      } else if (lessonTheme === 'wikipedia') {
        themeToUse = wikipediaContentRef.current;
      } else if (lessonTheme === 'randomizer') {
        themeToUse = randomizedContentRef.current;
      } else {
        //
        themeToUse = coursePlanContentRef.current;
      }

      Logger.log('themeToUse: ', themeToUse);

      const response = await callApi(
        [
          {
            role: 'system',
            content: `You are an assistant that generates lesson plans in  ${language}. Prioritize novelty and uniqueness in each generation. Avoid repeating themes, characters, or plot elements from previous responses.`,
          },
          {
            role: 'user',
            content: `Generate a lesson plan for a narrative text lesson in  ${language}. ${gradeInstructionsText}. The theme should be ${themeToUse}. It should contain the following details:
          Goal: ${goal}
          Develop: ${develop}
          Additional Info: ${additionalInfo}
          Include storyTitle, whoOrWhich (5 suggestions), where (5 suggestions), and what (5 suggestions based on whoOrWhich).
          Example of expected JSON response:
          {
            "storyTitle": "Den mystiska ön",
            "whoOrWhich": [
              "En spion",
              "En häxa",
              "Ett monster",
              "En skolklass",
              "En flicka som bor på barnhem"
            ],
            "where": [
              "På en övergiven ö mitt i havet",
              "I ett hemligt underjordiskt laboratorium",
              "I en gammal herrgård full av gömda rum",
              "På en flotte som driver på ett stormigt hav",
              "I en mystisk djungel med okända varelser"
            ],
            "what": [
              "Upptäcker en portal till en annan dimension",
              "Avslöjar en konspiration som hotar hela världen",
              "Löser en uråldrig gåta som kan förändra historien",
              "Räddar en utrotningshotad art från tjuvjägare",
              "Återförenar en familj som varit separerad i årtionden"
            ]
          }`,
          },
        ],
        8000,
        true,
        user.uid
      );

      let messageContent = response.data.choices[0].message.content.trim();

      if (messageContent.startsWith('```json')) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith('```')) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const data = JSON.parse(messageContent);
      const whoOrWhichObjects = data.whoOrWhich.map(character => ({
        name: character,
        imageUrl: null,
      }));

      setPlanData({
        ...data,
        whoOrWhich: whoOrWhichObjects,
        additionalStoryImages: [{ imageUrl: null }, { imageUrl: null }, { imageUrl: null }],
        headlines: {
          characters: 'Karaktärer (Vem eller vilka)',
          locations: 'Miljöer (Var utspelar sig berättelsen)',
          actions: 'Handlingsidéer (Vad händer i berättelsen)',
        },
      });

      setLessonPlanKey(prevKey => prevKey + 1);
      showSnackbar('Lektionen har genererats!', 'success');
      await trackLesson(user.uid, 'NarrativeLesson.js', null, {
        lessonTheme: lessonTheme,
        language: language,
      });
    } catch (error) {
      console.error('Error generating lesson plan:', error);
    } finally {
      setIsGeneratingPlan(false);
    }
  };

  return (
    <>
      <Box sx={styles.container}>
        <Box
          sx={{
            ...styles.innerContainer,
            width: { xs: '100%', md: '87%' },
            margin: '0 auto',
          }}
        >
          <Typography
            variant='h1'
            gutterBottom
            sx={{ fontWeight: 'bold', fontSize: '1.8em', mb: 3, mt: 5, textAlign: 'center' }}
          >
            Berättande text
          </Typography>

          <Box sx={{ ...styles.buttonsContainer, ...styles.additionalButtonsContainer }}>
            <Tooltip title='Dina sparade lektioner'>
              <Button
                variant='contained'
                color='primary'
                disableRipple
                onClick={() => setDrawerOpen(true)}
                startIcon={<HistoryIcon />}
                sx={{
                  padding: '8px',
                  marginRight: '0px',
                  paddingLeft: '14px',
                  paddingRight: '3px',
                  minWidth: 'auto',
                  textTransform: 'none',
                }}
              />
            </Tooltip>
          </Box>

          <InfoBox
            icon={HistoryEduIcon}
            title='Powerpoint Instructions'
            instructions={[
              'Eleven skriver en berättelse baserad på genererade karaktärer och miljöer',
              'Lägg till fantasieggande AI-genererade bilder för karaktärer och miljöer',
              'Skriv ut lektionen',
            ]}
            theme={theme}
            styles={styles}
          />

          <LessonDrawer
            userId={user.uid}
            lessonType='NarrativeLesson.js'
            theme={theme}
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}
            lessonIcon={HistoryEduIcon}
            onSelectLesson={lesson => {
              //Logger.log(JSON.stringify(lesson.data, null, 2));
              Logger.log('BAJS');

              setPlanData(lesson.data);

              setDrawerOpen(false);

              showSnackbar(`Lektionen har laddats in.`, 'success');
              setTimeout(() => {
                lessonTitleRef.current?.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start', // This ensures it scrolls to the top of the element
                });
              }, 100);
            }}
          />

          <ThemeSelectorFlow
            value={lessonTheme}
            onChange={e => setLessonTheme(e.target.value)}
            customTheme={customTheme}
            onCustomThemeChange={e => setCustomTheme(e.target.value)}
            onPredefinedThemeChange={e => setPredefinedTheme(e.target.value)}
            onYoutubeTranscriptChange={transcriptContent => {
              Logger.log('onYoutubeTranscriptChange körs med: ', transcriptContent);
              transcriptRef.current = transcriptContent;
            }}
            onFileUpload={content => {
              Logger.log('onFileUpload körs med: ', content);
              uploadedFileRef.current = content;
            }}
            onWikipediaContentChange={content => {
              Logger.log('Parent: Wikipedia content received', content.substring(0, 100));
              wikipediaContentRef.current = content;
            }}
            onRandomizedContentChange={content => {
              Logger.log('randomizedContentRef', content.substring(0, 100));
              randomizedContentRef.current = content;
            }}
            onCoursePlanContentChange={content => {
              Logger.log('onCoursePlanChange', content.substring(0, 100));
              coursePlanContentRef.current = content;
            }}
            lessonType='NarrativeLesson.js'
            user={user}
            theme={theme}
            styles={styles}
            onLessonSelect={lesson => {
              Logger.log('onLessonSelect körs');
              setPlanData(lesson.data);
              setTimeout(() => {
                lessonTitleRef.current?.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start', // This ensures it scrolls to the top of the element
                });
              }, 100);
              showSnackbar('Lektionen har laddats in.', 'success');
            }}
            additionalThemes={additionalThemes}
          />
          <Box sx={styles.headlineLessonBox}>
            <Typography variant='h6' sx={styles.headlineLessonBoxTitle}>
              2. Generera lektionen
            </Typography>
          </Box>
          <Box sx={{ ...styles.freshBox, padding: '30px' }}>
            <Typography
              variant='formLabelSinUnderline'
              sx={{ fontWeight: '500', mb: 0, display: 'block' }}
            >
              Årskurs:
            </Typography>
            <Box sx={{ mt: 1, mb: 4 }}>
              <GradeSelector grade={grade} setGrade={setGrade} />
            </Box>

            <Typography
              variant='formLabelSinUnderline'
              sx={{ fontWeight: '500', mb: 0, display: 'block' }}
            >
              Språk:
            </Typography>
            <Box sx={{ mt: 1, mb: 3 }}>
              <LanguageSelector language={language} setLanguage={setLanguage} />
            </Box>

            <Box>
              <Button
                variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                onClick={handleGeneratePlan}
                disabled={isGeneratingPlan}
                sx={{ mt: 4, mb: 2 }}
                startIcon={
                  !isGeneratingPlan ? (
                    <GenerateIcon style={{ color: '#fff' }} />
                  ) : (
                    <CircularProgress size={16} />
                  )
                }
              >
                {isGeneratingPlan ? 'Genererar...' : 'Generera lektion'}
              </Button>
            </Box>
          </Box>
          {planData && (
            <>
              <Box ref={lessonTitleRef} sx={{ ...styles.headlineLessonBox, mt: 10 }}>
                <Typography variant='h6' sx={styles.headlineLessonBoxTitle}>
                  3. Din lektion
                </Typography>
              </Box>
              <Box sx={{ ...styles.freshBox, padding: '30px' }}>
                <Box sx={styles.lessonControlPanel}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      px: 1,
                    }}
                  >
                    <Box>
                      <Button
                        variant='print'
                        color='primary'
                        onClick={() =>
                          handlePrintNarrativeLesson(
                            planData,
                            goal,
                            develop,
                            additionalInfo,
                            themeColor
                          )
                        }
                        startIcon={<PrintIcon />}
                      >
                        Skriv ut
                      </Button>

                      <Button
                        variant='print'
                        startIcon={<SaveAsIcon />}
                        sx={{ ml: 2 }}
                        onClick={handleSaveLessonClick}
                      >
                        Spara i lektionsbank
                      </Button>
                    </Box>
                  </Box>
                </Box>
                <SaveLessonDialog
                  open={saveDialogOpen}
                  onClose={() => setSaveDialogOpen(false)}
                  onSave={handleSaveWithComment}
                  theme={theme}
                  lessonObject={planData}
                  lessonName={planData.storyTitle}
                />

                <ImageGenerationModal
                  open={showImageModal}
                  onClose={handleCloseModal}
                  onGenerate={handleGenerateWithInstructions}
                  isGenerating={
                    imageGenerationType === 'topic' ? isGeneratingImages : isGeneratingStoryImages
                  }
                  instructions={imageInstructions}
                  setInstructions={setImageInstructions}
                  generationType={imageGenerationType}
                  theme={theme}
                />

                {planData && (
                  <>
                    <Box sx={{ mt: 8 }}>
                      {isEditingTitle ? (
                        <TextField
                          value={planData.storyTitle}
                          onChange={e => {
                            setPlanData({
                              ...planData,
                              storyTitle: e.target.value,
                            });
                          }}
                          onBlur={() => {
                            setIsEditingTitle(false);
                            showSnackbar('Titeln har uppdaterats', 'success');
                          }}
                          onKeyDown={e => {
                            if (e.key === 'Enter') {
                              setIsEditingTitle(false);
                              showSnackbar('Titeln har uppdaterats', 'success');
                            }
                          }}
                          autoFocus
                          fullWidth
                          variant='standard'
                          sx={{
                            backgroundColor: 'transparent',
                            '& .MuiInputBase-input': {
                              textAlign: 'center',
                              fontWeight: 'bold',
                              fontSize: '2.6em',
                              marginBottom: '1.2rem',
                              marginTop: '1.5rem',
                            },
                          }}
                          InputProps={{
                            disableUnderline: true,
                          }}
                        />
                      ) : (
                        <Box
                          sx={{
                            position: 'relative',
                            display: 'inline-block',
                            margin: '0 auto',
                            width: '100%',
                            mb: 3,
                            mt: 5,
                            ':hover .edit-icon': {
                              opacity: 1,
                            },
                          }}
                        >
                          <Typography
                            variant='h1'
                            sx={{
                              fontWeight: 'bold',
                              fontSize: '2.6em',

                              textAlign: 'center',
                              width: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              gap: 2,
                            }}
                          >
                            {planData.storyTitle || ' '}
                            <Box
                              component='span'
                              sx={{
                                opacity: 0,
                                transition: 'opacity 0.2s',
                                display: 'inline-flex',
                                gap: 0.5,
                              }}
                              className='edit-icon'
                            >
                              <Tooltip title='Redigera titel' placement='top'>
                                <IconButton size='small' onClick={() => setIsEditingTitle(true)}>
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </Typography>
                        </Box>
                      )}

                      <Box sx={{ mt: 10 }}>
                        <Typography
                          variant='h5'
                          sx={{
                            color: themeColor,
                            mb: 8,
                            textAlign: 'center',
                            fontWeight: '500',
                            fontSize: '2.4rem',
                            display: 'none',
                          }}
                        >
                          Karaktärer:
                        </Typography>
                        <CharacterMindMap
                          key={JSON.stringify(planData?.whoOrWhich)}
                          characters={planData.whoOrWhich}
                          storyImage={planData.storyImage}
                          themeColor={themeColor}
                          generatingCharacterIndex={generatingCharacterIndex} // Changed from generatingImageIndex
                          isGeneratingMainStory={isGeneratingMainStory} // Changed from isGeneratingStoryImages
                          generatedCharacterImages={generatedCharacterImages}
                          onGenerateCharacterImage={handleGenerateCharacterImage}
                          onGenerateStoryImage={handleGenerateStoryImage}
                          onImageUpdate={handleImageUpdate}
                          userId={user.uid}
                          showSnackbar={showSnackbar}
                          theme={theme}
                          onImageClick={handleHoverableImageClick}
                        />
                      </Box>
                    </Box>

                    {planData.whoOrWhich?.length > 0 &&
                      renderEditableSection(
                        'characters',
                        'Karaktärer (Vem eller vilka)',
                        planData.whoOrWhich,
                        'characters'
                      )}

                    {planData.where?.length > 0 &&
                      renderEditableSection(
                        'locations',
                        'Miljöer (Var)',
                        planData.where,
                        'locations'
                      )}

                    {planData.additionalStoryImages && (
                      <Box
                        sx={{
                          display: 'grid',
                          gridTemplateColumns: {
                            xs: '1fr', // 1 column on mobile
                            sm: 'repeat(2, 1fr)', // 2 columns on small screens
                            md: 'repeat(3, 1fr)', // 3 columns on medium and larger screens
                          },
                          gap: 2,
                          my: 4,
                        }}
                      >
                        {planData.additionalStoryImages.map((image, index) => (
                          <HoverableImage
                            key={index}
                            imageUrl={image.imageUrl}
                            isLoading={generatingSceneIndex === index}
                            onGenerateClick={() => handleHoverableImageClick('scene', index)} // CHANGED from 'story'
                            userId={user.uid}
                            onImageUpdate={newUrl => handleImageUpdate('scene', index, newUrl)} // CHANGED from 'story'
                            height='300px'
                            theme={theme}
                            showSnackbar={showSnackbar}
                          />
                        ))}
                      </Box>
                    )}

                    {planData.what?.length > 0 &&
                      renderEditableSection(
                        'actions',
                        'Handlingsidéer (Vad)',
                        planData.what,
                        'actions'
                      )}
                  </>
                )}
              </Box>
            </>
          )}
        </Box>

        <NarrativeLessonStoryGenerator
          planData={planData}
          themeColor={themeColor}
          theme={theme}
          styles={styles}
          user={user}
        />
      </Box>
    </>
  );
};

export default NarrativeLesson;
