import React, { useState } from 'react';
import { Box, Typography, IconButton, TextField, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import HoverableImage from './HoverableImage';

const CharacterMindMap = ({
  characters,
  storyImage,
  themeColor,
  generatingCharacterIndex, // Changed from generatingImageIndex
  isGeneratingMainStory, // Changed from isGeneratingStoryImage
  generatedCharacterImages,
  onGenerateCharacterImage,
  onGenerateStoryImage,
  onImageUpdate,
  userId,
  showSnackbar,
  theme,
  onImageClick,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedCharacters, setEditedCharacters] = useState(characters);
  const centerSize = 130;
  const itemSize = 120;
  const radius = 200;
  const [isHovering, setIsHovering] = useState(false);

  const handleNameChange = (index, newName) => {
    const updatedCharacters = editedCharacters.map((char, i) =>
      i === index ? { ...char, name: newName } : char
    );
    setEditedCharacters(updatedCharacters);
  };

  const handleCharacterImageClick = index => {
    onImageClick('character', index);
  };

  const handleStoryImageClick = () => {
    onImageClick('main-story', null); // CHANGED from 'story'
  };

  const toggleEdit = () => {
    if (isEditing) {
      // Save changes
      onImageUpdate('character-names', null, editedCharacters);
      showSnackbar('Karaktärsnamn uppdaterat!', 'success');
    } else {
      // Start editing
      setEditedCharacters([...characters]);
    }
    setIsEditing(!isEditing);
  };

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      toggleEdit();
    }
  };

  return (
    <Box
      sx={{
        position: 'relative',
        width: radius * 2 + itemSize,
        height: radius * 2 + itemSize,
        margin: '0 auto',
      }}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {/* Edit Button */}
      <Tooltip title={isEditing ? 'Spara ändringar' : 'Redigera karaktärsnamn'} open={isHovering}>
        <IconButton
          onClick={toggleEdit}
          sx={{
            position: 'absolute',
            top: -48,
            right: 0,
            zIndex: 4,
            opacity: isHovering ? 1 : 0,
            transition: 'opacity 0.2s',
          }}
        >
          {isEditing ? <SaveIcon /> : <EditIcon />}
        </IconButton>
      </Tooltip>
      {/* Center Story Image */}
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 2,
        }}
      >
        <HoverableImage
          imageUrl={storyImage}
          isLoading={isGeneratingMainStory}
          onGenerateClick={handleStoryImageClick}
          userId={userId}
          onImageUpdate={newUrl => onImageUpdate('main-story', null, newUrl)} // CHANGED from 'story'
          showSnackbar={showSnackbar}
          theme={theme}
          tinyMode={true}
          size={centerSize}
        />
      </Box>

      {/* Character Avatars */}
      {editedCharacters.map((character, index) => {
        const angle = (index / editedCharacters.length) * 2 * Math.PI - Math.PI / 2;
        const x = Math.cos(angle) * radius + radius + itemSize / 2;
        const y = Math.sin(angle) * radius + radius + itemSize / 2;

        return (
          <Box
            key={index}
            sx={{
              position: 'absolute',
              top: y,
              left: x,
              transform: 'translate(-50%, -50%)',
              width: itemSize * 1.5,
              height: itemSize * 1.5,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 3,
            }}
          >
            <Box sx={{ position: 'relative' }}>
              <HoverableImage
                imageUrl={character.imageUrl}
                isLoading={generatingCharacterIndex === index}
                loadingIndex={index}
                currentIndex={index}
                onGenerateClick={() => handleCharacterImageClick(index)}
                userId={userId}
                onImageUpdate={newUrl => onImageUpdate('character', index, newUrl)}
                showSnackbar={showSnackbar}
                theme={theme}
                tinyMode={true}
                size={itemSize * 0.8}
              />
            </Box>
            {isEditing ? (
              <TextField
                value={character.name}
                onChange={e => handleNameChange(index, e.target.value)}
                onKeyPress={handleKeyPress}
                variant='standard'
                sx={{
                  width: itemSize * 1.5,
                  mt: 1,
                  '& input': {
                    textAlign: 'center',
                    color: themeColor,
                    fontWeight: 'bold',
                    fontSize: '1rem',
                  },
                }}
              />
            ) : (
              <Typography
                variant='subtitle2'
                align='center'
                sx={{
                  width: itemSize * 2,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  lineHeight: 1.2,
                  height: '2.4em',
                  fontWeight: '500',
                  fontSize: '1rem',
                  color: themeColor,
                  mt: 1,
                }}
              >
                {character.name}
              </Typography>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default CharacterMindMap;
