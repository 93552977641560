import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Avatar } from '@mui/material';
import { collection, query, where, getDocs, orderBy, doc, getDoc } from 'firebase/firestore';
import { db } from './firebase';
import MessageBubble from './MessageBubble';
import { Link } from 'react-router-dom';
import { sanitizeInput } from './functions';
import { Helmet } from 'react-helmet-async';
import { useTheme } from '@mui/material/styles';
import { getStyles } from '../index';
import LoadingSpinner from './LoadingSpinner';
import { formatDate } from './functions';

const SharedConversation = ({}) => {
  const { chatId } = useParams();
  const [messages, setMessages] = useState([]);
  const [teacher, setTeacher] = useState(null);
  const [creator, setCreator] = useState(null);
  const [user, setUser] = useState(null);
  const [chatCreatedAt, setChatCreatedAt] = useState(null);
  const [loading, setLoading] = useState(true);
  const [dynamicTitle, setDynamicTitle] = useState('T');
  const [dynamicDescription, setDynamicDescription] = useState('T');
  const theme = useTheme();
  const styles = getStyles(theme);

  const isDark = theme.palette.mode === 'dark';

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Fetch messages
        const messagesQuery = query(
          collection(db, 'conversations'),
          where('chatId', '==', chatId),
          orderBy('createdAt')
        );
        const messagesSnapshot = await getDocs(messagesQuery);
        const fetchedMessages = messagesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setMessages(fetchedMessages);

        // Fetch chat document to get teacherId
        const chatDoc = await getDoc(doc(db, 'chats', chatId));
        if (chatDoc.exists()) {
          const chatData = chatDoc.data();
          setChatCreatedAt(chatData.createdAt);
          // Fetch teacher
          const teacherDoc = await getDoc(doc(db, 'teachers', chatData.conversationType));
          if (teacherDoc.exists()) {
            setTeacher({ id: teacherDoc.id, ...teacherDoc.data() });

            // Fetch creator
            const creatorId = teacherDoc.data().originalCreatorId || teacherDoc.data().userId;
            const creatorDoc = await getDoc(doc(db, 'users', creatorId));
            if (creatorDoc.exists()) {
              setCreator(creatorDoc.data());
            }

            const userDoc = await getDoc(doc(db, 'users', teacherDoc.data().userId));
            if (userDoc.exists()) {
              setUser(userDoc.data());
            }
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      setLoading(false);
    };

    fetchData();
  }, [chatId]);

  useEffect(() => {
    if (teacher && creator) {
      setDynamicTitle(`Chatt mellan ${teacher.characterName} och ${user.displayName}`);
      setDynamicDescription(
        `En konversation mellan ${teacher.characterName} och ${user.displayName}`
      );
    }
  }, [teacher, user]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Helmet>
        <title>{dynamicTitle}</title>
        <meta name='description' content={dynamicDescription} />
      </Helmet>

      <Box sx={{ ...styles.container, backgroundColor: isDark === true ? '#15202B' : '' }}>
        <Box sx={styles.innerContainer}>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <Avatar
              src={teacher.imageUrl}
              alt={teacher.characterName}
              sx={{
                width: 45,
                height: 45,
                marginRight: 2,
                borderRadius: '100px',
                border: '2px solid white',
                boxShadow: theme.palette.mode === 'dark' ? '0 2px 10px rgba(0,0,0,0.2)' : 'none',
              }}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                variant='h1'
                sx={{
                  ...styles.header,
                  borderBottom: '0px',
                  fontWeight: '500',
                  fontSize: '18px',
                  marginTop: '0px',
                  marginBottom: '2px',
                }}
              >
                <Link
                  to={`/app/chat/${teacher.id}`}
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  {sanitizeInput(teacher.characterName)}
                </Link>
              </Typography>
              <Box
                sx={{
                  ...styles.grayText,
                  mt: 0,
                  fontSize: '13px',
                  fontWeight: '500',
                }}
              >
                {creator ? (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ marginRight: '4px' }}>Av</span>@
                    {creator.displayName || 'Anonym användare'}
                  </div>
                ) : (
                  '@anonym'
                )}
              </Box>
            </Box>

            <Box sx={{ ...styles.buttonsContainer, ...styles.additionalButtonsContainer }}>
              <Typography
                sx={{
                  fontSize: '14px',
                  marginRight: '30px',
                  color: isDark ? '#b9b9b9' : '#6c6c6c',
                  display: { xs: 'none', md: 'inherit' },
                }}
              >
                Konversation mellan&nbsp;
                {teacher && (
                  <Link
                    to={`/app/chat/${teacher.id}`}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    {teacher.characterName}
                  </Link>
                )}
                &nbsp;och&nbsp;
                {user.displayName}
                &nbsp;({chatCreatedAt && `${formatDate(chatCreatedAt)}`})
              </Typography>
            </Box>
          </Box>
          <style jsx='true' global='true'>{`
            html,
            body {
              overflow: hidden; /* Prevent body scroll */
              height: 100%; /* Ensure body takes full height */
              margin: 0; /* Remove default margin */
            }
          `}</style>
          <Box
            sx={{
              height: 'calc(100vh)',
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'auto',
              padding: { xs: '0px', md: '16px' },
              '&::-webkit-scrollbar': { display: 'block', width: '8px' },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,0,.2)',
                borderRadius: '4px',
              },
              scrollbarWidth: 'thin',
              scrollbarColor: 'rgba(0,0,0,.2) transparent',
            }}
          >
            <Box sx={{ flexGrow: 1, width: '100%', margin: '0 auto' }}>
              {teacher && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    pt: 8,
                    pb: 4,
                    maxWidth: '300px',
                    margin: '0 auto',
                  }}
                >
                  <Avatar
                    src={teacher.imageUrl}
                    alt={teacher.characterName}
                    sx={{
                      width: 65,
                      height: 65,
                      marginBottom: '2px',
                      borderRadius: '100px',
                      border: '2px solid white',
                      boxShadow:
                        theme.palette.mode === 'dark' ? '0 2px 10px rgba(0,0,0,0.2)' : 'none',
                    }}
                  />
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography
                      variant='h1'
                      sx={{
                        ...styles.header,
                        borderBottom: '0px',
                        fontWeight: '500',
                        fontSize: '18px',
                        marginTop: '0px',
                        marginBottom: '6px',
                        textAlign: 'center',
                      }}
                    >
                      <Link
                        to={`/app/chat/${teacher.id}`}
                        style={{ textDecoration: 'none', color: 'inherit' }}
                      >
                        {sanitizeInput(teacher.characterName)}
                      </Link>
                    </Typography>
                    {teacher.tagline && (
                      <Box
                        sx={{
                          ...styles.grayText,
                          mt: 0,
                          fontSize: '13px',
                          fontWeight: '500',
                          textAlign: 'center',
                        }}
                      >
                        {teacher.tagline}
                      </Box>
                    )}
                    <Box
                      sx={{
                        ...styles.grayText,
                        mt: 0,
                        fontSize: '13px',
                        fontWeight: '500',
                        textAlign: 'center',
                      }}
                    >
                      {creator ? (
                        <Link
                          to={`/app/profile/${teacher.userId}`}
                          style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                          Av @{creator.displayName || 'Anonym användare'}
                        </Link>
                      ) : (
                        '@anonym'
                      )}
                    </Box>
                  </Box>
                </Box>
              )}

              <Box sx={{ width: { xs: '100%', sm: '70%' }, margin: '0 auto' }}>
                {messages.map(msg => (
                  <MessageBubble
                    key={msg.id}
                    role={msg.role}
                    content={msg.message}
                    isShared={true}
                    avatar={
                      msg.role === 'user'
                        ? '/path-to-default-user-avatar.png'
                        : teacher?.imageUrl || '/path-to-default-ai-avatar.png'
                    }
                  />
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SharedConversation;
