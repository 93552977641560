import React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { EnergySavingsLeaf as TokenIcon } from '@mui/icons-material';

export const translateFirebaseError = error => {
    const errorCode = error.code;
    const errorMessage = error.message;

    const errorTranslations = {
        'auth/weak-password': 'Lösenordet bör vara minst 6 tecken långt.',
        'auth/email-already-in-use': 'E-postadressen används redan av ett annat konto.',
        'auth/invalid-email': 'E-postadressen är ogiltig.',
        'auth/user-disabled': 'Detta användarkonto har inaktiverats.',
        'auth/user-not-found': 'Ingen användare hittades med denna e-postadress.',
        'auth/wrong-password': 'Felaktigt lösenord.',
        'auth/missing-password': 'Skriv in lösenord.',
        'auth/operation-not-allowed': 'Denna inloggningsmetod är inte aktiverad.',
        'auth/invalid-credential': 'Ogiltig autentiseringsbegäran.',
        'auth/operation-not-supported-in-this-environment': 'Operationen stöds inte i denna miljö.',
        'auth/timeout': 'Tidsgräns för begäran har överskridits.',
    };

    return errorTranslations[errorCode] || errorMessage;
};

export const TokenLimitMessage = ({ user }) => {
    return (
        <Paper
            elevation={0}
            sx={{
                mb: 2,
                mt: 0,
                width: { xs: '100%', md: '73%' },
                maxWidth: '800px',
                borderRadius: '16px',
                background:
                    'linear-gradient(135deg, rgba(99, 102, 241, 0.08) 0%, rgba(139, 92, 246, 0.08) 100%)',
                border: '1px solid',
                borderColor: 'rgba(99, 102, 241, 0.2)',
                overflow: 'hidden',
                position: 'relative',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    p: 3,
                    gap: 2,
                }}
            >
                <TokenIcon
                    sx={{
                        fontSize: 28,
                        color: '#6366f1',
                        mt: 0.5,
                    }}
                />
                <Box>
                    <Typography
                        variant='h6'
                        sx={{
                            fontWeight: 600,
                            mb: 1,
                            background: 'linear-gradient(135deg, #6366f1 0%, #8b5cf6 100%)',
                            backgroundClip: 'text',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                        }}
                    >
                        {user?.guest ? 'Token-gräns uppnådd' : 'Token-gräns överskriden'}
                    </Typography>
                    <Typography
                        variant='body1'
                        sx={{
                            color: theme => theme.palette.text.secondary,
                            lineHeight: 1.6,
                        }}
                    >
                        Du är inloggad som Gäst och har nu använt alla dina tokens. Skapa ett konto
                        för att forsätta använda Studera.AI helt gratis.
                    </Typography>
                </Box>
            </Box>
        </Paper>
    );
};

export const MarketingSide = () => {
    return (
        <Box
            sx={{
                flex: '1 1 50%',
                height: '100vh',
                display: { xs: 'none', md: 'flex' },
                flexDirection: 'column',
                alignItems: 'center',
                p: 6,
                background: 'linear-gradient(135deg, #6366f1 0%, #8b5cf6 100%)',
                color: 'white',
                backgroundImage:
                    'url("/img/hepp.svg"),radial-gradient(circle, rgb(106, 80, 243), rgb(62, 46, 141) 82%)',
            }}
        >
            <Box sx={{ flex: 1 }} /> {/* Pushes content to middle */}
            <Box
                sx={{
                    textAlign: 'center',
                    mb: 2,
                }}
            >
                <Box
                    component='img'
                    src='/img/AI.png'
                    alt='Logo'
                    sx={{
                        width: '60px',
                        height: '60px',
                    }}
                />
                <Typography
                    variant='h4'
                    sx={{
                        fontWeight: 700,
                        fontSize: '2rem',
                        textShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                    }}
                >
                    Studera.AI
                </Typography>
            </Box>
            <Typography
                variant='h6'
                sx={{
                    mb: 4,
                    textAlign: 'center',
                    maxWidth: '600px',
                    opacity: 0.9,
                    lineHeight: 1.6,
                    textShadow: '0 1px 2px rgba(0, 0, 0, 0.15)',
                }}
            >
                AI för lärare, pedagoger & elever
            </Typography>
            <Box sx={{ flex: 1 }} /> {/* Pushes content to middle */}
            <Typography
                variant='caption'
                sx={{
                    opacity: 0.7,
                    textAlign: 'center',
                    width: '100%',
                }}
            >
                © 2024 Studera.AI - Din partner för modern utbildning
            </Typography>
        </Box>
    );
};
