const commonStyles = `
  @media print {
    @page {
      size: A4;
      margin: 2cm 0cm 2cm 0cm;
      marks: none;
    }
    
    html {
      padding: 0cm 2cm 2cm 2cm !important;
      margin: 0 !important;
      margin-top: 2cm !important;
    }

    body { 
      margin: 0 !important;
      -webkit-print-color-adjust: exact !important;
      print-color-adjust: exact !important;
    }


    
    .logo-container {
      position: fixed;
      top: 0.5cm;
      right: 0.5cm;
      padding: 5px;
      z-index: 1000;
    }
    
    .logo {
      width: 100px;
      height: auto;
      display: block;
      border-radius: 20px;
    }


  }
`;

const logoHtml = `
  <div class="logo-container">
    <img src="/printlogo.png" alt="Logo" class="logo">
  </div>
`;

export const handlePrintArgumentation = (lessonData, theme) => {
  const printWindow = window.open('', '_blank');
  const themeColor = '#000000';

  // Helper function to map educational content types to Swedish titles
  const getEducationalSectionTitle = key => {
    const titles = {
      celebrities: 'Kända personers åsikter',
      exercises: 'Övningar för elever',
      sentenceStarters: 'Meningsstartare',
      historicalContext: 'Historisk kontext',
      statistics: 'Statistik och fakta',
      realExamples: 'Verkliga exempel',
      ethicalDilemmas: 'Etiska dilemman',
      rolePlay: 'Rollspelsscenarier',
      vocabulary: 'Ämnesspecifikt ordförråd',
      mediaAnalysis: 'Medieanalys',
      reflectionQuestions: 'Reflektionsfrågor efter debatten',
      whatIf: 'Tänk om-scenarier',
      futureImpact: 'Framtidsperspektiv',
      factOpinion: 'Fakta vs åsikter',
    };
    return titles[key] || key;
  };

  const styles = `
    <style>
      ${commonStyles}
      @media print {
        body { 
          font-family: Arial, sans-serif;
        }
        .container {
          max-width: 800px;
          margin: 0 auto;
        }
        .title {
          color: ${themeColor};
          font-size: 2.6em;
          text-align: center;
          font-weight: bold;
          margin-bottom: 2.2rem;
          margin-top: 1.5rem;
        }
        .introduction {
          line-height: 1.8;
          margin-bottom: 3rem;
        }
        .arguments-grid {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 24px;
          margin-bottom: 3rem;
          page-break-inside: avoid;
        }
        .arguments-section {
          background-color: ${theme?.palette?.mode === 'dark' ? '#1e1e1e' : '#f5f5f5'};
          border-radius: 10px;
          padding: 24px;
        }
        .arguments-title {
          font-size: 1.5em;
          font-weight: bold;
          margin-bottom: 1.5rem;
        }
        .for-arguments .arguments-title {
          color: #197b19;
        }
        .against-arguments .arguments-title {
          color: #9b1e1e;
        }
        .argument-list {
          list-style-type: disc;
          padding-left: 20px;
          margin: 0;
        }
        .argument-item {
          margin-bottom: 1rem;
          line-height: 1.8;
        }


        .images-grid {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 24px;
          margin-bottom: 3rem;

          break-before: page;
        }
        .lesson-image {
          width: 100%;
          height: 300px;
          object-fit: cover;
          border-radius: 10px;
          background-color: #f2f2f2;
        }
        .educational-section {
          margin-top: 3rem;
          page-break-inside: avoid;
        }
        .section-title {
          font-size: 1.5em;
          font-weight: bold;
          color: ${themeColor};
          margin-bottom: 1.5rem;
        }
        .section-content {

          border-radius: 10px;
          padding: 24px;
          margin-bottom: 2rem;
        }
        .section-list {
          list-style-type: disc;
          padding-left: 20px;
          margin: 0;
        }
        .section-item {
          margin-bottom: 1rem;
          line-height: 1.8;
        }
      }
    </style>
  `;

  const content = `
    <!DOCTYPE html>
    <html>
      <head>
        <title>${lessonData.title}</title>
        ${styles}
      </head>
      <body>
        ${logoHtml}
        <div class="container">
          <h1 class="title">${lessonData.title}</h1>
          
          ${
            lessonData.introduction
              ? `<div class="introduction">${lessonData.introduction}</div>`
              : ''
          }
          
          <div class="arguments-grid">
            <div class="arguments-section for-arguments">
              <div class="arguments-title">Argument för</div>
              <ul class="argument-list">
                ${lessonData.forArguments
                  .map(arg => `<li class="argument-item">${arg}</li>`)
                  .join('')}
              </ul>
            </div>
            
            <div class="arguments-section against-arguments">
              <div class="arguments-title">Argument emot</div>
              <ul class="argument-list">
                ${lessonData.againstArguments
                  .map(arg => `<li class="argument-item">${arg}</li>`)
                  .join('')}
              </ul>
            </div>
          </div>
          
          ${
            lessonData.images?.length > 0
              ? `
            <div class="images-grid">
              ${lessonData.images
                .map(
                  image => `
                <img 
                  src="${image.imageUrl || '/api/placeholder/400/300'}" 
                  alt="Illustration"
                  class="lesson-image"
                >
              `
                )
                .join('')}
            </div>
          `
              : ''
          }
          
          ${
            lessonData.educationalContent
              ? `
            <div class="educational-section">
              ${Object.entries(lessonData.educationalContent)
                .map(([key, content]) => {
                  if (key === 'sentenceStarters') {
                    return `
                      <div class="section">
                        <h2 class="section-title">${getEducationalSectionTitle(key)}</h2>
                        ${Object.entries(content)
                          .map(
                            ([type, starters]) => `
                          <div class="section-content">
                            <h3 class="subsection-title">
                              ${
                                type === 'general'
                                  ? 'Generella'
                                  : type === 'for'
                                    ? 'För argument'
                                    : 'Mot argument'
                              }
                            </h3>
                            <ul class="section-list">
                              ${starters
                                .map(starter => `<li class="section-item">${starter}</li>`)
                                .join('')}
                            </ul>
                          </div>
                        `
                          )
                          .join('')}
                      </div>
                    `;
                  }

                  return Array.isArray(content)
                    ? `
                    <div class="section">
                      <h2 class="section-title">${getEducationalSectionTitle(key)}</h2>
                      <div class="section-content">
                        <ul class="section-list">
                          ${content.map(item => `<li class="section-item">${item}</li>`).join('')}
                        </ul>
                      </div>
                    </div>
                  `
                    : `
                    <div class="section">
                      <h2 class="section-title">${getEducationalSectionTitle(key)}</h2>
                      <div class="section-content">
                        ${content}
                      </div>
                    </div>
                  `;
                })
                .join('')}
            </div>
          `
              : ''
          }
        </div>
      </body>
    </html>
  `;

  printWindow.document.write(content);
  printWindow.document.close();

  printWindow.onload = function () {
    setTimeout(() => {
      printWindow.print();
      const checkPrintState = setInterval(() => {
        if (printWindow.document.readyState === 'complete') {
          clearInterval(checkPrintState);
          setTimeout(() => {
            if (!printWindow.closed) {
              printWindow.close();
            }
          }, 100);
        }
      }, 100);
    }, 100);
  };
};

export const handlePrintComparativeLesson = (lessonData, theme) => {
  const printWindow = window.open('', '_blank');
  const themeColor = '#000000';

  const styles = `
    <style>
      ${commonStyles}
      body { 
        font-family: Arial, sans-serif;
      }
      .container {
        max-width: 800px;
        margin: 0 auto;
      }
      .title {
        display:block;
        color: ${themeColor};
        font-size: 2.6em;
        text-align: center;
        font-weight: bold;
        margin-bottom: 30px !important;

      }
      .main-content {
        margin-bottom: 30px;
        line-height: 1.8;
      }
      .text-section {
       /* background-color: #f9f9f9; */
        border-radius: 10px;
        padding: 20px;
        margin-bottom: 30px;
      }
      .text-header {
        font-size: 1.5em;
        font-weight: bold;
        margin-bottom: 16px;
        color: ${themeColor};
      }
      .text-content {
        white-space: pre-wrap;
        line-height: 1.8;
      }
      .images-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 16px;
        margin: 30px 0;
        page-break-inside: avoid;
      }
      .content-image {
        width: 100%;
        height: 300px;
        border-radius: 8px;
        display: block;
        object-fit: cover;
        background-color: #f2f2f2;
      }
      .section {
        margin-top: 40px;
        page-break-inside: avoid;
      }
      .section-title {
        font-size: 1.5em;
        font-weight: bold;
        margin-bottom: 16px;
        color: ${themeColor};
      }
      .section-content {
       /*  background-color: #f9f9f9; */
        border-radius: 10px;
        padding: 20px;
      }
      .section-list {
        list-style-type: disc !important;
        padding-left: 24px !important;
        margin: 0;
      }
      .section-item {
        margin-bottom: 12px;
        line-height: 25px;
        display: list-item !important;
        padding-left: 8px;
      }
      .section-list li::marker {
        color: ${themeColor};
        font-size: 1.2em;
      }
      @media print {
        .section {
          break-inside: avoid;
        }
        .images-grid {
          break-inside: avoid;
        }
        .text-section {
         /* background-color: #f9f9f9 !important; */
        }
        .section-content {
          /* background-color: #f9f9f9 !important; */
        }
      }
    </style>
  `;

  const content = `
    <!DOCTYPE html>
    <html>
      <head>
        <title>${lessonData.title}</title>
        ${styles}
      </head>
      <body>
        ${logoHtml}
        <div class="container">

          <h1 class="title"> ${lessonData.title}</h1>
 <div style="height: 30px;"></div>
          ${lessonData.content
            .map(
              content => `
            <h2 class="text-header">${content.header}</h2>
            <div class="text-section">
              
              <div class="text-content">${content.text}</div>
            </div>
          `
            )
            .join('')}
          
          ${
            lessonData.images && lessonData.images.length > 0
              ? `
            <div class="images-grid">
              ${lessonData.images
                .map(
                  image => `
                <img 
                  src="${image.imageUrl || '/api/placeholder/500/300'}" 
                  alt="Bild hamnar här"
                  class="content-image"
                >
              `
                )
                .join('')}
            </div>
            `
              : ''
          }

          ${lessonData.exercises
            .map(
              exercise => `
            <div class="section">
              <h2 class="section-title">${exercise.title}</h2>
              <div class="section-content">
                <ul class="section-list">
                  ${exercise.content
                    .map(
                      item => `
                    <li class="section-item">${item}</li>
                  `
                    )
                    .join('')}
                </ul>
              </div>
            </div>
          `
            )
            .join('')}
        </div>
      </body>
    </html>
  `;

  printWindow.document.write(content);
  printWindow.document.close();

  printWindow.onload = function () {
    setTimeout(() => {
      printWindow.print();
      const checkPrintState = setInterval(() => {
        if (printWindow.document.readyState === 'complete') {
          clearInterval(checkPrintState);
          setTimeout(() => {
            if (!printWindow.closed) {
              printWindow.close();
            }
          }, 100);
        }
      }, 100);
    }, 100);
  };
};

export const handlePrintDetectiveLesson = (lessonData, theme) => {
  const printWindow = window.open('', '_blank');
  const themeColor = '#000000';

  const styles = `
    <style>
      ${commonStyles}
      body { 
        font-family: Arial, sans-serif;
      }
      .container {
        max-width: 800px;
        margin: 0 auto;
      }
      .title {
        color: ${themeColor};
        font-size: 2.6em;
        text-align: center;
        font-weight: bold;
        margin-bottom: 1.2rem;
        margin-top: 1.5rem;
      }
      .main-content {
        border-radius: 10px;
        padding: 20px;
        margin-bottom: 30px;
        line-height: 1.8;
        white-space: pre-wrap;
      }
      .images-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;  /* Changed to two columns */
        gap: 16px;
        margin: 30px 0;
        page-break-inside: avoid;
      }
      .content-image {
        width: 100%;         /* Changed to 100% to fit container */
        height: 300px;       /* Adjusted height for side-by-side layout */
        border-radius: 8px;
        display: block;
        object-fit: cover;
        background-color: #f2f2f2;
        page-break-inside: avoid;
      }
      .section {
        margin-top: 40px;
        page-break-inside: avoid;
      }
      .section-title {
        font-size: 1.5em;
        font-weight: bold;
        margin-bottom: 16px;
        color: ${themeColor};
      }
      .section-content {
        border-radius: 10px;
        padding: 20px;

      }
      .section-list {
        list-style-type: disc !important;
        padding-left: 24px !important;
        margin: 0;
      }
      .section-item {
        margin-bottom: 12px;
        line-height: 25px;
        display: list-item !important;
        padding-left: 8px;
      }
      .section-list li::marker {
        color: ${themeColor};
        font-size: 1.2em;
      }
      .clues-points {
        font-weight: bold;
        margin-bottom: 8px;
        color: ${themeColor};
      }
      @media print {
        .section {
          break-inside: avoid;
        }
        .images-grid {
          break-inside: avoid;
        }
      }
    </style>
  `;

  const renderExerciseContent = exercise => {
    if (exercise.type === 'scoring-clues') {
      return `
        <div>
          ${Object.entries(exercise.content)
            .map(
              ([points, clues]) => `
                <div class="clues-section">
                  <div class="clues-points">${points} poäng:</div>
                  <ul class="section-list">
                    ${clues
                      .map(
                        clue => `
                      <li class="section-item">${clue}</li>
                    `
                      )
                      .join('')}
                  </ul>
                </div>
              `
            )
            .join('')}
        </div>
      `;
    }

    return `
      <ul class="section-list">
        ${exercise.content
          .map(
            item => `
          <li class="section-item">${item}</li>
        `
          )
          .join('')}
      </ul>
    `;
  };

  const content = `
    <!DOCTYPE html>
    <html>
      <head>
        <title>${lessonData.title}</title>
        ${styles}
      </head>
      <body>
        ${logoHtml}
        <div class="paddingWrapper">
          <div class="container">
            <h1 class="title">${lessonData.title}</h1>
            
            <div class="main-content">
              ${lessonData.content}
            </div>

            ${
              lessonData.images && lessonData.images.length > 0
                ? `
            <div class="images-grid">
              ${lessonData.images
                .map(
                  image => `
                <img 
                  src="${image.imageUrl || '/api/placeholder/500/300'}" 
                  alt="Detective story illustration"
                  class="content-image"
                >
              `
                )
                .join('')}
            </div>
            `
                : ''
            }

            ${lessonData.exercises
              .map(
                exercise => `
              <div class="section">
                <h2 class="section-title">${exercise.title}</h2>
                <div class="section-content">
                  ${renderExerciseContent(exercise)}
                </div>
              </div>
            `
              )
              .join('')}


          </div>
        </div>
      </body>
    </html>
  `;

  printWindow.document.write(content);
  printWindow.document.close();

  printWindow.onload = function () {
    setTimeout(() => {
      printWindow.print();
      const checkPrintState = setInterval(() => {
        if (printWindow.document.readyState === 'complete') {
          clearInterval(checkPrintState);
          setTimeout(() => {
            if (!printWindow.closed) {
              printWindow.close();
            }
          }, 100);
        }
      }, 100);
    }, 100);
  };
};

export const handlePrintReadingComp = (lessonData, theme) => {
  const printWindow = window.open('', '_blank');
  const themeColor = '#000000';

  const styles = `
    <style>
      ${commonStyles}
      body { 
        font-family: Arial, sans-serif;
      }
      .container {
        max-width: 800px;
        margin: 0 auto;
      }
      .title {
        color: ${themeColor};
        font-size: 2.6em;
        text-align: center;
        font-weight: bold;
        margin-bottom: 1.2rem;
        margin-top: 1.5rem;
      }
      .main-content {
        border-radius: 10px;
        padding: 20px;
        margin-bottom: 30px;
        white-space: pre-wrap; 
        line-height: 1.8;
      }
      .images-grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 16px;
        margin: 30px 0;
        page-break-inside: avoid;
      }
      .content-image {
        width: 500px;
        height: 400px;
        border-radius: 8px;
        margin: 0 auto;
        display: block;
        object-fit: cover;
        background-color: #f2f2f2;
         page-break-inside: avoid;
      }
      .exercise-section {
        margin-top: 40px;
        page-break-inside: avoid;
      }
      .exercise-title {
        font-size: 1.5em;
        font-weight: bold;
        margin-bottom: 16px;
        color: ${themeColor};
      }
      .exercise-content {
        border-radius: 10px;
        padding: 20px;
        /*background-color: #f9f9f94f;*/
      }
      .exercise-list {
        list-style-type: disc !important;
        padding-left: 24px !important;
        margin: 0;
      }
      .exercise-item {
        margin-bottom: 12px;
        line-height: 25px;
        display: list-item !important;
        padding-left: 8px;
      }
      .exercise-list li::marker {
        color: ${themeColor};
        font-size: 1.2em;
      }
      .multiple-choice-question {
        margin-bottom: 20px;
      }
      .question-text {
        font-weight: 500;
        margin-bottom: 8px;
         margin-top: 20px;
      }
      .options-list {
        list-style-type: none !important;
        padding-left: 20px !important;
        margin: 0;
      }
      .option-item {
        margin-bottom: 4px !important;
        line-height: 1.5;
      }
      .option-item::marker {
        content: none !important;
      }
      @media print {
        .exercise-section {
          break-inside: avoid;
        }
        .images-grid {
          break-inside: avoid;
        }
      }
    </style>
  `;

  const renderExerciseContent = exercise => {
    if (exercise.type === 'multiple-choice') {
      return `
        <div>
          ${exercise.content
            .map(
              (item, index) => `
            <div class="multiple-choice-question">
              <div class="question-text">${item.question}</div>
              <ul class="options-list">
                ${item.options
                  .map(
                    (option, optIndex) => `
                  <li class="option-item">${String.fromCharCode(65 + optIndex)}) ${option}</li>
                `
                  )
                  .join('')}
              </ul>
            </div>
          `
            )
            .join('')}
        </div>
      `;
    }

    // Regular exercise content
    return `
      <ul class="exercise-list">
        ${exercise.content
          .map(
            item => `
          <li class="exercise-item">${item}</li>
        `
          )
          .join('')}
      </ul>
    `;
  };

  const content = `
    <!DOCTYPE html>
    <html>
      <head>
        <title>${lessonData.title}</title>
        ${styles}
      </head>
      <body>
        ${logoHtml}
        <div class="paddingWrapper">
          <div class="container">
            <h1 class="title">${lessonData.title}</h1>
            
            <div class="main-content">
              ${lessonData.content}
            </div>

${
  lessonData.images && lessonData.images.length > 0
    ? `
<table style="width: 100%; break-inside: avoid !important; page-break-inside: avoid !important;">
  <tr>
    <td>
      <div class="images-grid">
        ${lessonData.images
          .map(
            image => `
          <img 
            src="${image.imageUrl || '/api/placeholder/300/300'}" 
            alt="Illustration till texten"
            class="content-image"
          >
        `
          )
          .join('')}
      </div>
    </td>
  </tr>
</table>
`
    : ''
}

            ${lessonData.exercises
              .map(
                exercise => `
              <div class="exercise-section">
                <h2 class="exercise-title">${exercise.title}</h2>
                <div class="exercise-content">
                  ${renderExerciseContent(exercise)}
                </div>
              </div>
            `
              )
              .join('')}
          </div>
        </div>
      </body>
    </html>
  `;

  printWindow.document.write(content);
  printWindow.document.close();

  printWindow.onload = function () {
    setTimeout(() => {
      printWindow.print();
      const checkPrintState = setInterval(() => {
        if (printWindow.document.readyState === 'complete') {
          clearInterval(checkPrintState);
          setTimeout(() => {
            if (!printWindow.closed) {
              printWindow.close();
            }
          }, 100);
        }
      }, 100);
    }, 100);
  };
};

export const handlePrintSentenceFinisher = (lessonData, theme) => {
  const printWindow = window.open('', '_blank');
  const themeColor = theme?.palette?.primary?.main || '#8b5cf6';

  const styles = `
    <style>
      ${commonStyles}
      @media print {
        body { 
          font-family: Arial, sans-serif;
        }
        .container {
          max-width: 800px;
          margin: 0 auto;
        }
        .title {
          color: ${themeColor};
          font-size: 2.6em;
          text-align: center;
          font-weight: bold;
          margin-bottom: 1.2rem;
          margin-top: 1.5rem;
        }
        .story-content {
         /* background-color: #f9f9f94f;*/
          border-radius: 10px;
          padding: 20px;
          margin-bottom: 30px;
          line-height: 1.8;
          white-space: pre-line;
        }
        .images-grid {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 16px;
          margin: 30px 0;
          page-break-inside: avoid;
        }
        .story-image {
          width: 100%;
          height: 240px;
          border-radius: 8px;
          object-fit: cover;
          background-color: #f2f2f2;
        }
        .section {
          margin-top: 40px;
          page-break-inside: avoid;
        }
        .section-title {
          font-size: 1.5em;
          font-weight: bold;
          margin-bottom: 16px;
          color: ${themeColor};
        }
        .section-content {
        /*  background-color: #f9f9f94f;*/
          border-radius: 10px;
          padding: 20px;
        }
        .section-list {
          list-style-type: disc !important;
          padding-left: 24px !important;
          margin: 0;
        }
        .section-item {
          margin-bottom: 12px;
          line-height: 25px;
          display: list-item !important;
          padding-left: 8px;
        }
        /* Ensure list markers are visible */
        .section-list li::marker {
          color: ${themeColor};
          font-size: 1.2em;
        }
      }
    </style>
  `;

  const content = `
    <!DOCTYPE html>
    <html>
      <head>
        <title>${lessonData.storyTitle}</title>
        ${styles}
      </head>
      <body>
        ${logoHtml}
        <div class="paddingWrapper">
        <div class="container">
          <h1 class="title">${lessonData.storyTitle}</h1>
          
          <div class="story-content">
            ${lessonData.story}
          </div>

          <div class="images-grid">
            ${lessonData.images
              .map(
                image => `
              <img 
                src="${image.imageUrl || '/api/placeholder/240/240'}" 
               alt="Bild hamnar här"
                class="story-image"
              >
            `
              )
              .join('')}
          </div>

     ${
       lessonData.continuationTitle && lessonData.continuations
         ? `
    <div class="section">
      <h2 class="section-title">${lessonData.continuationTitle}</h2>
      <div class="section-content">
        <ul class="section-list">
          ${lessonData.continuations
            .map(
              continuation => `
            <li class="section-item">${continuation}</li>
          `
            )
            .join('')}
        </ul>
      </div>
    </div>
  `
         : ''
     }

${
  lessonData.plotTwistTitle && lessonData.plotTwists
    ? `
    <div class="section">
      <h2 class="section-title">${lessonData.plotTwistTitle}</h2>
      <div class="section-content">
        <ul class="section-list">
          ${lessonData.plotTwists
            .map(
              twist => `
            <li class="section-item">${twist}</li>
          `
            )
            .join('')}
        </ul>
      </div>
    </div>
  `
    : ''
}

${
  lessonData.environmentTitle && lessonData.environments
    ? `
    <div class="section">
      <h2 class="section-title">${lessonData.environmentTitle}</h2>
      <div class="section-content">
        <ul class="section-list">
          ${lessonData.environments
            .map(
              environment => `
            <li class="section-item">${environment}</li>
          `
            )
            .join('')}
        </ul>
      </div>
    </div>
  `
    : ''
}

        </div>
        </div>
      </body>
    </html>
  `;

  printWindow.document.write(content);
  printWindow.document.close();

  printWindow.onload = function () {
    setTimeout(() => {
      printWindow.print();
      const checkPrintState = setInterval(() => {
        if (printWindow.document.readyState === 'complete') {
          clearInterval(checkPrintState);
          setTimeout(() => {
            if (!printWindow.closed) {
              printWindow.close();
            }
          }, 100);
        }
      }, 100);
    }, 100);
  };
};

export const handlePrintReadingLesson = editorRef => {
  if (!editorRef.current) return;
  const printWindow = window.open('', '_blank');
  const printContent = `
    <!DOCTYPE html>
    <html>
      <head>
        <title>Skriv ut lektion</title>
        <style>
          ${commonStyles}
          @media print {
            body { 
              font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 
                         Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
              line-height: 28px !important; 

            }
            p { 
              line-height: 34px !important; 
            } 
            h3 { 
              margin-top: 60px !important; 
            } 
            li { 
              margin-bottom: 12px !important; 
              line-height: 28px !important; 
            } 
            ul, ol { 
              margin: 20px !important 
            } 
            mark { 
              font-weight: bold !important;
              padding: 5px !important;
              background: #dcccff !important;
              -webkit-print-color-adjust: exact !important;
              print-color-adjust: exact !important;
            }
            table {
              border-collapse: collapse !important;
              width: 100% !important;
              margin-bottom: 20px !important;
            }
            table, th, td {
              border: 2px solid black !important;
            }
            th, td {
              padding: 10px !important;
              text-align: left !important;
            }
            .exercise {
              margin-top: 30px;
              page-break-inside: avoid;
            }
            .teacher-notes {
              page-break-before: always;
            }
            .no-print {
              display: none;
            }
            img {
              max-width: 100%;
              page-break-inside: avoid;
            }
            h2, h3, h4 {
              page-break-after: avoid;
            }
            .container {
              max-width: 800px;
              margin: 0 auto;
            }
          }
        </style>
      </head>
      <body>
        ${logoHtml}
        <div class="container">
          ${editorRef.current.getContent()}
        </div>
      </body>
    </html>
  `;

  printWindow.document.write(printContent);
  printWindow.document.close();

  printWindow.onload = function () {
    setTimeout(() => {
      printWindow.print();
      const checkPrintState = setInterval(() => {
        if (printWindow.document.readyState === 'complete') {
          clearInterval(checkPrintState);
          setTimeout(() => {
            if (!printWindow.closed) {
              printWindow.close();
            }
          }, 100);
        }
      }, 100);
    }, 100);
  };
};

export const handlePrintEditorContent = (content, themeColor) => {
  const printWindow = window.open('', '_blank');
  const styles = `
    <style>
      ${commonStyles}
      @media print {
        body { 
          font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 
                       Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
          line-height: 1.6;
          padding: 20px;
        }
        .container {
          max-width: 800px;
          margin: 0 auto;
        }
        h1, h2, h3, h4, h5, h6 {
          color: ${themeColor};
          break-after: avoid;
        }
        img {
          max-width: 100%;
          height: auto;
          margin: 0;
          page-break-inside: avoid;
        }
        /* Ensure images stay in a row */
        #image-row {
          display: flex !important;
          gap: 12px !important;
          width: 100% !important;
          page-break-inside: avoid;
        }
        #image-row > div {
          flex: 1 !important;
          min-width: 0 !important;
        }
        ul, ol {
          padding-left: 20px;
          margin: 1rem 0;
        }
        li {
          margin-bottom: 0.5rem;
        }
        p {
          margin: 1rem 0;
        }
        table {
          width: 100%;
          border-collapse: collapse;
          margin: 1rem 0;
          page-break-inside: avoid;
        }
        th, td {
          border: 1px solid #ddd;
          padding: 8px;
          text-align: left;
        }
      }
    </style>
  `;
  const printContent = `
    <!DOCTYPE html>
    <html>
      <head>
        <title>Skriv ut lektion — Studera.ai</title>
        ${styles}
      </head>
      <body>
        ${logoHtml}
        <div class="container">
          ${content}
        </div>
      </body>
    </html>
  `;
  printWindow.document.write(printContent);
  printWindow.document.close();
  printWindow.onload = function () {
    setTimeout(() => {
      printWindow.print();
      const checkPrintState = setInterval(() => {
        if (printWindow.document.readyState === 'complete') {
          clearInterval(checkPrintState);
          setTimeout(() => {
            if (!printWindow.closed) {
              printWindow.close();
            }
          }, 100);
        }
      }, 100);
    }, 100);
  };
};

export const handlePrintTwisters = (
  lessonData,
  theme,
  titleText = 'Tungvrickare',
  subtitleText = ''
) => {
  const printWindow = window.open('', '_blank');
  const themeColor = '#000000';

  const styles = `
  <style>
    ${commonStyles}
    @media print {
        body { 
          font-family: Arial, sans-serif;
        }
        .container {
          max-width: 800px;
          margin: 0 auto;
        }
        .title {
          color: ${themeColor};
          font-size: 2.6em;
          text-align: center;
          font-weight: bold;
          margin-bottom: 1.2rem;

        }
        .subtitle {
          text-align: center;
          font-size: 1.2em;
          margin-bottom: 2rem;
          color: #666;
        }
        .twister-box {
          display: flex;
          align-items: flex-start;
          margin-bottom: 2rem;
          background-color: ${theme?.palette?.mode === 'dark' ? '#1e1e1e' : '#f5f5f5'};
          padding: 20px;
          border-radius: 8px;
          page-break-inside: avoid;
        }
        .image-container {
          flex: 0 0 200px;
          margin-right: 1.5rem;
        }
        .twister-image {
          width: 200px;
          height: 200px;
          border-radius: 8px;
          object-fit: cover;
          background-color: #f2f2f2;
        }
        .twister-content {
          flex: 1;
        }
        .twister-text {
          font-size: 24px;
          font-weight: 600;
          margin-bottom: 0.5rem;
        }
        .pronunciation {
          font-family: monospace;
          margin-bottom: 0.5rem;
          color: ${theme?.palette?.mode === 'dark' ? '#aaa' : '#666'};
        }
        .syllables {
          font-family: monospace;
          margin-bottom: 1rem;
          color: ${theme?.palette?.mode === 'dark' ? '#aaa' : '#666'};
        }
        .difficulty {
          font-style: italic;
          color: ${themeColor};
          margin-top: 0.5rem;
        }
        .story-container {
padding-top: 4rem;
          margin-bottom: 2rem;
          padding: 30px;
          background-color: ${theme?.palette?.mode === 'dark' ? '#1e1e1e' : '#f5f5f5'};
          border-radius: 8px;
          page-break-before: always;
        }
        .story-title {
          font-size: 1.5em;
          margin-bottom: 1.5rem;
          font-weight: 600;
        }
        .story-content {
          line-height: 1.8;
        }
    }
  </style>
`;

  const content = `
    <!DOCTYPE html>
    <html>
      <head>
        <title>${titleText}</title>
        ${styles}
      </head>
      <body>
      ${logoHtml}
        <div class="container">
          <h1 class="title">${titleText}</h1>
          ${subtitleText ? `<div class="subtitle">${subtitleText}</div>` : ''}

          ${lessonData.twisters
            .map(
              twister => `
            <div class="twister-box">
              <div class="image-container">
                <img 
                  src="${twister.imageUrl || '/api/placeholder/200/200'}" 
                  alt="${twister.twister}"
                  class="twister-image"
                >
              </div>
              <div class="twister-content">
                <div class="twister-text">${twister.twister}</div>
                ${
                  twister.ipa_pronunciation
                    ? `<div class="pronunciation">${twister.ipa_pronunciation}</div>`
                    : ''
                }
                ${
                  twister.syllable_pronunciation
                    ? `<div class="syllables">${twister.syllable_pronunciation}</div>`
                    : ''
                }
              </div>
            </div>
          `
            )
            .join('')}

          <div class="story-container">
            <div class="story-title">Berättelse med tungvrickare</div>
            <div class="story-content">${lessonData.story}</div>
          </div>
        </div>
      </body>
    </html>
  `;

  printWindow.document.write(content);
  printWindow.document.close();

  printWindow.onload = function () {
    setTimeout(() => {
      printWindow.print();
      const checkPrintState = setInterval(() => {
        if (printWindow.document.readyState === 'complete') {
          clearInterval(checkPrintState);
          setTimeout(() => {
            if (!printWindow.closed) {
              printWindow.close();
            }
          }, 100);
        }
      }, 100);
    }, 100);
  };
};

export const handlePrintGrammarLesson = (lessonData, titleText, showTranslation, theme) => {
  const printWindow = window.open('', '_blank');
  const themeColor = theme?.palette?.primary?.main || '#8b5cf6';

  const styles = `
    <style>



${commonStyles}
      @media print {
         
        body { 
          margin: 0; 
          padding: 20px; 
          font-family: Arial, sans-serif;
        }
        .container {
          max-width: 800px;
          margin: 0 auto;
        }
        .title {
          color: ${themeColor};
          font-size: 2.6em;
          text-align: center;
          font-weight: bold;
          margin-bottom: 2.2rem;
          margin-top: 1.5rem;
        }
        .sentence-grid {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 20px;
          margin-bottom: 30px;
        }
        .sentence-card {
          border: 1px solid #e0e0e0;
          border-radius: 8px;
          padding: 20px;
          background-color: ${theme?.palette?.mode === 'dark' ? '#1e1e1e' : '#ffffff'};
          position: relative;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }
        .sentence-card::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          height: 4px;
          background: linear-gradient(90deg, ${themeColor}aa, ${themeColor});
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }
        .sentence {
          font-size: 1.1em;
          margin-bottom: ${showTranslation ? '10px' : '0'};
          line-height: 1.4;
        }
        .translation {
          font-style: italic;
          color: ${theme?.palette?.mode === 'dark' ? '#aaa' : '#666'};
          margin-bottom: 10px;
        }
        .story-section {
          margin-top: 40px;
          margin-bottom: 20px;
          padding: 30px;
          background-color: ${theme?.palette?.mode === 'dark' ? '#1e1e1e' : '#f5f5f5'};
          border-radius: 8px;
          page-break-before: always;
        }
        .story-title {
          font-size: 1.5em;
          margin-bottom: 1.5rem;
          font-weight: 600;
          color: ${themeColor};
        }
        .story-content {
          line-height: 1.8;
          margin-bottom: ${showTranslation ? '20px' : '0'};
        }
        .story-translation {
          font-style: italic;
          color: ${theme?.palette?.mode === 'dark' ? '#aaa' : '#666'};
          line-height: 1.8;
        }
        .analysis-section {
          margin-top: 40px;
          padding: 30px;
          background-color: ${theme?.palette?.mode === 'dark' ? '#1e1e1e' : '#f5f5f5'};
          border-radius: 8px;
          page-break-before: always;
        }
        .analysis-title {
          font-size: 1.5em;
          margin-bottom: 1.5rem;
          font-weight: 600;
          color: ${themeColor};
        }
        .analysis-item {
          margin-bottom: 20px;
        }
        .analysis-sentence {
          font-weight: bold;
          margin-bottom: 5px;
        }
        .analysis-translation {
          font-style: italic;
          color: ${theme?.palette?.mode === 'dark' ? '#aaa' : '#666'};
          margin-bottom: 5px;
        }
        .analysis-text {
          color: ${theme?.palette?.mode === 'dark' ? '#ddd' : '#444'};
        }
    
      }
    </style>
  `;

  const content = `
    <!DOCTYPE html>
    <html>
      <head>
        <title>${titleText}</title>
        ${styles}
      </head>
      <body>
${logoHtml}
        <div class="container">

          <h1 class="title">${titleText}</h1>

          <div class="sentence-grid">
            ${lessonData.sentences
              .map(
                sentence => `
              <div class="sentence-card">
                <div class="sentence">${sentence.sentence}</div>
                ${showTranslation ? `<div class="translation">${sentence.translation}</div>` : ''}
              </div>
            `
              )
              .join('')}
          </div>

          <div class="story-section">
            <div class="story-title">Berättelse</div>
            <div class="story-content">${lessonData.story}</div>
            ${
              showTranslation
                ? `<div class="story-translation">${lessonData.storyTranslation}</div>`
                : ''
            }
          </div>

          <div class="analysis-section">
            <div class="analysis-title">Grammatisk analys</div>
            ${lessonData.sentences
              .map(
                (sentence, index) => `
              <div class="analysis-item">
                <div class="analysis-sentence">${index + 1}. ${sentence.sentence}</div>
                ${
                  showTranslation
                    ? `<div class="analysis-translation">${sentence.translation}</div>`
                    : ''
                }
                <div class="analysis-text">${sentence.analysis}</div>
              </div>
            `
              )
              .join('')}
          </div>
        </div>
      </body>
    </html>
  `;

  printWindow.document.write(content);
  printWindow.document.close();

  printWindow.onload = function () {
    setTimeout(() => {
      printWindow.print();
      const checkPrintState = setInterval(() => {
        if (printWindow.document.readyState === 'complete') {
          clearInterval(checkPrintState);
          setTimeout(() => {
            if (!printWindow.closed) {
              printWindow.close();
            }
          }, 100);
        }
      }, 100);
    }, 100);
  };
};

export const handlePrintIdioms = (
  lessonData,
  theme,
  titleText = 'Idiomatiska uttryck',
  subtitleText = ''
) => {
  const printWindow = window.open('', '_blank');
  const themeColor = theme?.palette?.primary?.main || '#8b5cf6';

  const styles = `
    <style>${commonStyles}
      @media print {
        body { 


          font-family: Arial, sans-serif;
        }
        .container {
          max-width: 800px;
          margin: 0 auto;
        }
        .title {
          color: ${themeColor};
          font-size: 2.6em;
          text-align: center;
          font-weight: bold;
          margin-bottom: 2.2rem;
          margin-top: 1.5rem;
        }
        .subtitle {
          text-align: center;
          font-size: 1.2em;
          margin-bottom: 2rem;
          color: #666;
        }
        .idiom-box {
          display: flex;
          align-items: flex-start;
          margin-bottom: 2rem;
          background-color: ${theme?.palette?.mode === 'dark' ? '#1e1e1e' : '#f5f5f5'};
          padding: 20px;
          border-radius: 8px;
          page-break-inside: avoid;
        }
        .image-container {
          flex: 0 0 200px;
          margin-right: 1.5rem;
        }
        .idiom-image {
          width: 200px;
          height: 200px;
          border-radius: 8px;
          object-fit: cover;
          background-color: #f2f2f2;
        }
        .idiom-content {
          flex: 1;
        }
        .idiom-text {
          font-size: 24px;
          font-weight: 600;
          margin-bottom: 0.5rem;
        }
        .meaning {
          font-style: italic;
          margin-bottom: 0.5rem;
          color: ${theme?.palette?.mode === 'dark' ? '#aaa' : '#666'};
        }
        .example-sentence {
          line-height: 1.5;
        }
        .story-section {
  page-break-before: always;
}
        .story-container {

          margin-top: 4rem;
          margin-bottom: 2rem;
          padding: 30px;
          background-color: ${theme?.palette?.mode === 'dark' ? '#1e1e1e' : '#f5f5f5'};
          border-radius: 8px;
        }
        .story-title {
          font-size: 1.5em;
          margin-bottom: 1.5rem;
          font-weight: 600;
        }
        .story-content {
          line-height: 1.8;
        }
     
      }
    </style>
  `;

  const content = `
    <!DOCTYPE html>
    <html>
      <head>
        <title>${titleText}</title>
        ${styles}
      </head>
      <body>
      ${logoHtml}
        <div class="container">
          <h1 class="title">${titleText}</h1>


          ${lessonData.idioms
            .map(
              idiom => `
            <div class="idiom-box">
              <div class="image-container">
                <img 
                  src="${idiom.imageUrl || '/api/placeholder/200/200'}" 
                  alt="${idiom.idiom}"
                  class="idiom-image"
                >
              </div>
              <div class="idiom-content">
                <div class="idiom-text">${idiom.idiom}</div>
                <div class="meaning">${idiom.meaning}</div>
                <div class="example-sentence">${idiom.exampleSentence}</div>
              </div>
            </div>
          `
            )
            .join('')}
      
<div class="story-section">
          <div class="story-container">
            <div class="story-title">Berättelse med idiomatiska uttryck</div>
            <div class="story-content">${lessonData.story}</div>
          </div>
          </div>
        </div>
      </body>
    </html>
  `;

  printWindow.document.write(content);
  printWindow.document.close();

  printWindow.onload = function () {
    setTimeout(() => {
      printWindow.print();
      const checkPrintState = setInterval(() => {
        if (printWindow.document.readyState === 'complete') {
          clearInterval(checkPrintState);
          setTimeout(() => {
            if (!printWindow.closed) {
              printWindow.close();
            }
          }, 100);
        }
      }, 100);
    }, 100);
  };
};

export const handlePrintWeeklyWords = (
  lessonData,
  theme,
  titleText = 'Veckans ord',
  subtitleText = ''
) => {
  const printWindow = window.open('', '_blank');
  const themeColor = theme?.palette?.primary?.main || '#8b5cf6';

  const styles = `
    <style>
${commonStyles}
      @media print {
        body { 


          font-family: Arial, sans-serif;
        }
        .container {
          max-width: 800px;
          margin: 0 auto;
        }
        .title {
          color: ${themeColor};
          font-size: 2.6em;
          text-align: center;
          font-weight: bold;
          margin-bottom: 1.2rem;
          margin-top: 1.5rem;
        }
        .subtitle {
          text-align: center;
          font-size: 1.2em;
          margin-bottom: 2rem;
          color: #666;
        }
        .word-box {
          display: flex;
          align-items: flex-start;
          margin-bottom: 2rem;
          background-color: ${theme?.palette?.mode === 'dark' ? '#1e1e1e' : '#f5f5f5'};
          padding: 20px;
          border-radius: 8px;
          page-break-inside: avoid;
        }
        .image-container {
          flex: 0 0 200px;
          margin-right: 1.5rem;
        }
        .word-image {
          width: 200px;
          height: 200px;
          border-radius: 8px;
          object-fit: cover;
          background-color: #f2f2f2;
        }
        .word-content {
          flex: 1;
        }
        .word-text {
          font-size: 24px;
          font-weight: 600;
          margin-bottom: 0.5rem;
        }
        .pattern {
          font-style: italic;
          margin-bottom: 0.5rem;
          color: ${theme?.palette?.mode === 'dark' ? '#aaa' : '#666'};
        }
        .example-sentence {
          line-height: 1.5;
        }

.story-section {
  page-break-before: always;
}

        .story-container {
          margin-top: 4rem;
          margin-bottom: 2rem;
          padding: 30px;
          background-color: ${theme?.palette?.mode === 'dark' ? '#1e1e1e' : '#f5f5f5'};
          border-radius: 8px;
        }
        .story-title {
          font-size: 1.5em;
          margin-bottom: 1.5rem;
          font-weight: 600;
        }
        .story-content {
          line-height: 1.8;
        }

      }
    </style>
  `;

  const content = `
    <!DOCTYPE html>
    <html>
      <head>
        <title>${titleText}</title>
        ${styles}
      </head>
      <body>
      ${logoHtml}
        <div class="container">
          <h1 class="title">${titleText}</h1>
          <div class="subtitle">${subtitleText}</div>

          ${lessonData.words
            .map(
              word => `
            <div class="word-box">
              <div class="image-container">
                <img 
                  src="${word.imageUrl || '/api/placeholder/200/200'}" 
                  alt="${word.word}"
                  class="word-image"
                >
              </div>
              <div class="word-content">
                <div class="word-text">${word.word}</div>

                <div class="example-sentence">${word.exampleSentence}</div>
              </div>
            </div>
          `
            )
            .join('')}
<div class="story-section">
          <div class="story-container">
            <div class="story-title">Berättelse med veckans ord</div>
            <div class="story-content">${lessonData.story}</div>
          </div>
        </div>
        </div>
      </body>
    </html>
  `;

  printWindow.document.write(content);
  printWindow.document.close();

  printWindow.onload = function () {
    setTimeout(() => {
      printWindow.print();
      const checkPrintState = setInterval(() => {
        if (printWindow.document.readyState === 'complete') {
          clearInterval(checkPrintState);
          setTimeout(() => {
            if (!printWindow.closed) {
              printWindow.close();
            }
          }, 100);
        }
      }, 100);
    }, 100);
  };
};

export const handlePrintConversationLesson = (lessonData, titleText, theme) => {
  const printWindow = window.open('', '_blank');
  const themeColor = theme?.palette?.primary?.main || '#8b5cf6';

  const styles = `
    <style>

${commonStyles}

      @media print {
        body { 

          padding: 20px; 
          font-family: Arial, sans-serif;
        }
        .container {
          max-width: 800px;
          margin: 0 auto;
        }
        .title {
          color: ${themeColor};
          font-size: 2.6em;
          text-align: center;
          font-weight: bold;
          margin-bottom: 3rem;

        }
        .topic-image {
          width: 350px;
          height: 350px;
          border-radius: 10px;
          margin: 0 auto;
          display: block;
          margin-bottom: 3rem;
          object-fit: cover;
          background-color: #f2f2f2;
        }
        .participants-container {
          display: flex;
          justify-content: center;
          gap: 3rem;
          margin-bottom: 2rem;
          flex-wrap: wrap;
        }
        .participant {
          text-align: center;
        }
        .participant-image {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          margin-bottom: 0.5rem;
          object-fit: cover;
          background-color: #f2f2f2;
        }
        .participant-name {
          font-weight: 600;
          font-size: 1rem;
          margin-bottom: 0.25rem;
        }
        .participant-role {
          font-weight: 400;
          font-size: 0.85rem;
          color: #666;
        }
        .conversation {
          margin-top: 3.5rem;
        }
        .message {
          display: flex;
          align-items: flex-start;
          gap: 1rem;
          margin-bottom: 2rem;


        }
        .message-avatar {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          object-fit: cover;
          background-color: #f2f2f2;
        }
        .message-content {
          flex: 1;

        }
        .message-speaker {
          font-weight: 600;
          font-size: 1rem;
          margin-bottom: 0.5rem;
        }
        .message-text {
          background-color: #e0e0e029;
          -webkit-print-color-adjust: exact !important;
          print-color-adjust: exact !important;
          border-radius: 10px;
          padding: 15px;
          margin-top: 0.5rem;
        }
        .message-text.empty {
          min-height: 60px;
        }
        .message-translation-container {
          margin-top: 0.5rem;
          padding-top: 0.5rem;
          border-top: 1px solid rgba(128, 128, 128, 0.2);
        }
        .message-translation {
          font-style: italic;
          min-height: ${theme?.palette?.mode === 'dark' ? '40px' : '30px'};
        }
        .message-translation.empty {
          min-height: 60px;
          border: 1px dashed rgba(128, 128, 128, 0.3);
          border-radius: 5px;
          margin: 5px 0;
        }
     
      }
    </style>
  `;

  const content = `
    <!DOCTYPE html>
    <html>
      <head>
        <title>${titleText}</title>
        ${styles}
      </head>
      <body>
      ${logoHtml}
        <div class="container">
          <h1 class="title">${titleText}</h1>

          ${
            lessonData.topicImage
              ? `<img src="${lessonData.topicImage}" alt="Topic" class="topic-image">`
              : ''
          }

          <div class="participants-container">
            ${Object.values(lessonData.participants)
              .map(
                participant => `
              <div class="participant">
                <img 
                  src="${participant.image || '/api/placeholder/150/150'}" 
                  alt="${participant.name}"
                  class="participant-image"
                >
                <div class="participant-name">${participant.name}</div>
                <div class="participant-role">${participant.role}</div>
              </div>
            `
              )
              .join('')}
          </div>

          <div class="conversation">
            ${lessonData.conversation
              .map(message => {
                const participant = Object.values(lessonData.participants).find(
                  p => p.name === message.speaker
                );
                const messageIsEmpty = !message.message || message.message.trim() === '';
                const translationIsEmpty =
                  !message.translation || message.translation.trim() === '';

                return `
                <div class="message">
                  <img 
                    src="${participant?.image || '/api/placeholder/50/50'}" 
                    alt="${message.speaker}"
                    class="message-avatar"
                  >
                  <div class="message-content">
                    <div class="message-speaker">${message.speaker}</div>
                    <div class="message-text ${messageIsEmpty ? 'empty' : ''}">
                      ${message.message || ''}
                      ${
                        'translation' in message
                          ? `
                          <div class="message-translation-container">
                            <div class="message-translation ${translationIsEmpty ? 'empty' : ''}">
                              ${message.translation || ''}
                            </div>
                          </div>
                        `
                          : ''
                      }
                    </div>
                  </div>
                </div>
              `;
              })
              .join('')}
          </div>
        </div>
      </body>
    </html>
  `;

  printWindow.document.write(content);
  printWindow.document.close();

  printWindow.onload = function () {
    setTimeout(() => {
      printWindow.print();
      const checkPrintState = setInterval(() => {
        if (printWindow.document.readyState === 'complete') {
          clearInterval(checkPrintState);
          setTimeout(() => {
            if (!printWindow.closed) {
              printWindow.close();
            }
          }, 100);
        }
      }, 100);
    }, 100);
  };
};
export const handlePrintStory = (storyData, themeColor) => {
  const printWindow = window.open('', '_blank');
  const styles = `
    <style>
${commonStyles}
      @media print {
        body { 

          font-family: Arial, sans-serif;
        }
        .container {
          max-width: 800px;
          margin: 0 auto;
        }
        h1 {
          color: ${themeColor};
          font-size: 24px;
          text-align: center;
          margin-bottom: 30px;
        }
        .characters-section {
          margin: 20px 0;
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
        }
        .character-card {
          display: flex;
          align-items: center;
          gap: 10px;
        }
        .character-image-container {
          width: 200px;
          height: 200px;
          background-color: #f0f0f0;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
        }
        .character-image {
          max-width: 100%;
          max-height: 100%;
        }
        .character-info {
          flex: 1;
        }
        .character-name {
          font-size: 28px;
          font-weight: bold;
          margin-bottom: 8px;
        }
        .main-story-image {
          margin: 20px 0;
          width: 100%;
          max-height: 400px;
          object-fit: contain;
        }
        .story-content {
          line-height: 1.6;
          margin: 20px 0;
        }
        .environment-images {
          margin: 20px 0;
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
        }
        .environment-card {
          width: 300px;
        }
        .environment-image-container {
          width: 100%;
          height: 200px;
          background-color: #f0f0f0;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          margin-bottom: 10px;
        }
        .environment-image {
          max-width: 100%;
          max-height: 100%;
        }
        .environment-description {
          font-style: italic;
          margin-top: 8px;
        }
     
      }
    </style>
  `;

  const content = `
    <!DOCTYPE html>
    <html>
      <head>
        <title>${storyData.title}</title>
        ${styles}
      </head>
      <body>
      ${logoHtml}
        <div class="container">
          <h1>${storyData.title}</h1>
          
          <div class="characters-section">
            ${storyData.characters
              .map(
                char => `
              <div class="character-card">
                <div class="character-image-container">
                  ${
                    char.imageUrl
                      ? `<img src="${char.imageUrl}" alt="${char.name}" class="character-image">`
                      : 'Bild hamnar här'
                  }
                </div>
                <div class="character-info">
                  <div class="character-name">${char.name}</div>
                  ${char.description}
                </div>
              </div>
            `
              )
              .join('')}
          </div>

          ${
            storyData.mainStoryImage
              ? `
            <div>
              <img src="${storyData.mainStoryImage}" alt="Huvudbild" class="main-story-image">
            </div>
          `
              : ''
          }

          <div class="story-content">
            ${storyData.storyContent
              .map(
                section => `
              <p>${section.content}</p>
            `
              )
              .join('')}
          </div>

          <div class="environment-images">
            ${storyData.environmentImages
              .map(
                env => `
              <div class="environment-card">
                <div class="environment-image-container">
                  ${
                    env.imageUrl
                      ? `<img src="${env.imageUrl}" alt="${env.description}" class="environment-image">`
                      : 'Miljöbild hamnar här'
                  }
                </div>
                <p class="environment-description">${env.description}</p>
              </div>
            `
              )
              .join('')}
          </div>
        </div>
      </body>
    </html>
  `;

  printWindow.document.write(content);
  printWindow.document.close();

  printWindow.onload = function () {
    setTimeout(() => {
      printWindow.print();
      const checkPrintState = setInterval(() => {
        if (printWindow.document.readyState === 'complete') {
          clearInterval(checkPrintState);
          setTimeout(() => {
            if (!printWindow.closed) {
              printWindow.close();
            }
          }, 100);
        }
      }, 100);
    }, 100);
  };
};

export const handlePrintQuizResults = (topic, questions) => {
  const printWindow = window.open('', '_blank');

  const styles = `
    <style>
    ${commonStyles}
      @media print {
        body { 
          margin: 0; 
          padding: 20px; 
          font-family: Arial, sans-serif;
        }
        .container {
          max-width: 800px;
          margin: 0 auto;
        }
        h1 {
          color: #6424c1;
          font-size: 24px;
          text-align: center;
          margin-bottom: 30px;
        }
        .question {
          border: 1px solid #e0e0e0;
          border-radius: 8px;
          padding: 20px;
          margin-bottom: 20px;
          page-break-inside: avoid;
        }
        .question-text {
          font-weight: bold;
          margin-bottom: 15px;
        }
        .options {
          margin-left: 20px;
        }
        .option {
          margin-bottom: 8px;
        }
        .answer-key {
          page-break-before: always;
        }
        .answer-key h1 {
          margin-bottom: 40px;
        }
        .answer-item {
          margin-bottom: 15px;
          page-break-inside: avoid;
        }
        .student-info {
          margin-top: 30px;
          margin-bottom: 40px;
          border-top: 1px solid #e0e0e0;
          border-bottom: 1px solid #e0e0e0;
          padding: 20px 0;
        }
        .info-line {
          display: flex;
          margin-bottom: 10px;
        }
        .info-label {
          width: 100px;
        }
        .info-value {
          flex: 1;
          border-bottom: 1px solid #999;
        }
      
      }
    </style>
  `;

  const content = `
    <!DOCTYPE html>
    <html>
      <head>
        <title>Quiz: ${topic}</title>
        ${styles}
      </head>
      <body>
      ${logoHtml}
        <div class="container">
          <h1>Quiz: ${topic}</h1>

          <div class="student-info">
            <div class="info-line">
              <div class="info-label">Namn:</div>
              <div class="info-value">&nbsp;</div>
            </div>
            <div class="info-line">
              <div class="info-label">Klass:</div>
              <div class="info-value">&nbsp;</div>
            </div>
            <div class="info-line">
              <div class="info-label">Datum:</div>
              <div class="info-value">&nbsp;</div>
            </div>
          </div>
          
          ${questions
            .map(
              (question, index) => `
            <div class="question">
              <div class="question-text">${index + 1}. ${question.question}</div>
              <div class="options">
                ${question.options
                  .map(
                    option => `
                  <div class="option">${option}</div>
                `
                  )
                  .join('')}
              </div>
            </div>
          `
            )
            .join('')}

          <!-- Answer key on a new page -->
          <div class="answer-key">
            <h1>Facit: ${topic}</h1>
            ${questions
              .map(
                (question, index) => `
              <div class="answer-item">
                <strong>Fråga ${index + 1}:</strong> ${question.correctAnswer}
              </div>
            `
              )
              .join('')}
          </div>
        </div>
      </body>
    </html>
  `;

  printWindow.document.write(content);
  printWindow.document.close();

  printWindow.onload = function () {
    setTimeout(() => {
      printWindow.print();
      const checkPrintState = setInterval(() => {
        if (printWindow.document.readyState === 'complete') {
          clearInterval(checkPrintState);
          setTimeout(() => {
            if (!printWindow.closed) {
              printWindow.close();
            }
          }, 100);
        }
      }, 100);
    }, 100);
  };
};

export const handlePrintPaSparet = (clueData, titleText, theme) => {
  const printWindow = window.open('', '_blank');
  const themeColor = theme?.palette?.primary?.main || '#8b5cf6';

  const styles = `
    <style>
      ${commonStyles}
      @media print {
        
        body { 

          font-family: Arial, sans-serif;
        }
        .container {
          max-width: 800px;
          margin: 0 auto;
        }
        .title {
          color: ${themeColor};
          font-size: 2.6em;
          text-align: center;
          font-weight: bold;
          margin-bottom: 3.0rem;
          display:none;
        }
        .answer {
          text-align: center;
          color: #666;
          font-size: 1.1em;
          margin-bottom: 3rem;
        }
        .clue-container {
          margin-bottom: 2.5rem;
          page-break-inside: avoid;
        }
        .difficulty {
          font-size: 2.0em;
          color: ${themeColor};
          font-weight: bold;
          margin-bottom: 1rem;
        }
        .clue-box {
          display: grid;
          grid-template-columns: 1fr 2fr;
          gap: 20px;
          background-color: #f5f5f5;
          padding: 20px;
          border-radius: 10px;
          min-height: 240px;
        }
        .clue-image {
          width: 100%;
          height: 240px;
          border-radius: 10px;
          object-fit: cover;
          background-color: #e0e0e0;
        }
        .clue-text {
          font-size: 1.1em;
          line-height: 1.7;
          display: flex;
          align-items: center;
 white-space: pre-line;
        }
    
      }
    </style>
  `;

  const content = `
    <!DOCTYPE html>
    <html>
      <head>
        <title>${titleText}</title>
        ${styles}
      </head>
      <body>
      ${logoHtml}
        <div class="container">
          <h1 class="title">${titleText}</h1>

          
          ${Object.entries(clueData.clues)
            .map(
              ([_, clue]) => `
                <div class="clue-container">
                  <div class="difficulty">${clue.difficulty}</div>
                  <div class="clue-box">
                    <img 
                      class="clue-image" 
                      src="${clue.image || '/api/placeholder/600/300'}" 
                      alt="Ledtråd bild"
                    />
                    <div class="clue-text">${clue.text}</div>
                  </div>
                </div>
              `
            )
            .join('')}
        </div>
      </body>
    </html>
  `;

  printWindow.document.write(content);
  printWindow.document.close();

  printWindow.onload = function () {
    setTimeout(() => {
      printWindow.print();
      const checkPrintState = setInterval(() => {
        if (printWindow.document.readyState === 'complete') {
          clearInterval(checkPrintState);
          setTimeout(() => {
            if (!printWindow.closed) {
              printWindow.close();
            }
          }, 100);
        }
      }, 100);
    }, 100);
  };
};

export const handlePrintQuiz = (quiz, quizName, questionType, themeColor) => {
  const printWindow = window.open('', '_blank');

  const getLetterForIndex = idx => String.fromCharCode(65 + idx);

  const styles = `
    <style>

    ${commonStyles}
      @media print {
        body { 

          font-family: Arial, sans-serif;

        }
        .container {
          max-width: 800px;
          margin: 0 auto;
        }
        .header {
          margin-bottom: 40px;
          padding: 20px;
        }
        .quiz-title {
          font-size: 2.5rem;
          font-weight: 600;
          margin-bottom: 8px;
          color: #333;
        }
        .quiz-info {
          color: #666;
          font-size: 1rem;
        }
        .question-card {
          background-color: #f8f9fa;
          border: 1px solid #e0e0e0;
          border-radius: 15px;
          padding: 20px;
          margin-bottom: 24px;
          page-break-inside: avoid;
        }
        .question-number {
          font-weight: 500;
          color: #666;
          font-size: 1rem;
          margin-bottom: 8px;
        }
        .question-text {
          font-size: 1.3rem;
          font-weight: 600;
          color: #333;
          margin-bottom: 32px;
          line-height: 1.4;
        }
        .options-container {
          display: flex;
          flex-direction: column;
          gap: 16px;
        }
        .option-row {
          display: flex;
          align-items: center;
          gap: 16px;
        }
        .letter-box {
          width: 30px;
          height: 30px;
          border: 2px solid #8b5cf6;
          color: #8b5cf6;
          background-color:#ffffff;
          border-radius: 7px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          font-size: 0.9rem;
        }
        .option-text {
          font-size: 1rem;
        }
        .answer-key {
          background-color: #f8f9fa;
          border: 1px solid #e0e0e0;
          border-radius: 15px;
          padding: 20px;
          margin-top: 32px;
          page-break-before: always;
        }
        .answer-key-title {
          font-size: 1.25rem;
          font-weight: 600;
          margin-bottom: 16px;
        }
        .answer-item {
          margin-bottom: 16px;
          font-size: 1rem;
        }
        .fill-blank-question {
          display: inline;
        }
        .blank-line {
          display: inline-block;
          width: 120px;
          border-bottom: 1px solid #999;
          margin: 0 8px;
        }
      }
    </style>
  `;

  const renderQuestionContent = (question, type, index) => {
    switch (type) {
      case 'fill-in-the-blank':
        const parts = question.question.split('_');
        const questionHtml = parts
          .map(
            (part, i, arr) => `
          ${part}${i < arr.length - 1 ? '<span class="blank-line"></span>' : ''}
        `
          )
          .join('');

        return `
          <div class="question-text fill-blank-question">${questionHtml}</div>
          <div class="options-container">
            ${question.options
              .map(
                (option, idx) => `
              <div class="option-row">
                <div class="letter-box">${getLetterForIndex(idx)}</div>
                <div class="option-text">${option}</div>
              </div>
            `
              )
              .join('')}
          </div>
        `;

      case 'true-or-false':
        return `
          <div class="options-container">
            ${['Sant', 'Falskt']
              .map(
                (option, idx) => `
              <div class="option-row">
                <div class="letter-box">${getLetterForIndex(idx)}</div>
                <div class="option-text">${option}</div>
              </div>
            `
              )
              .join('')}
          </div>
        `;

      default:
        return `
          <div class="options-container">
            ${question.options
              .map(
                (option, idx) => `
              <div class="option-row">
                <div class="letter-box">${getLetterForIndex(idx)}</div>
                <div class="option-text">${option}</div>
              </div>
            `
              )
              .join('')}
          </div>
        `;
    }
  };

  const content = `
    <!DOCTYPE html>
    <html>
      <head>
        <title>${quizName}</title>
        ${styles}
      </head>
      <body>
      ${logoHtml}
        <div class="container">
          <div class="header">
            <div class="quiz-title">${quizName}</div>
           
          </div>

          ${quiz
            .map(
              (question, index) => `
            <div class="question-card">
              <div class="question-number">Fråga ${index + 1}</div>
              ${
                questionType !== 'fill-in-the-blank'
                  ? `<div class="question-text">${question.question}</div>`
                  : ''
              }
              ${renderQuestionContent(question, questionType, index)}
            </div>
          `
            )
            .join('')}

          <div class="answer-key">
            <div class="answer-key-title">Facit</div>
            ${quiz
              .map(
                (question, index) => `
              <div class="answer-item">
                <strong>Fråga ${index + 1}:</strong> ${question.correct}
              </div>
            `
              )
              .join('')}
          </div>
        </div>
      </body>
    </html>
  `;

  printWindow.document.write(content);
  printWindow.document.close();

  printWindow.onload = function () {
    setTimeout(() => {
      printWindow.print();
      const checkPrintState = setInterval(() => {
        if (printWindow.document.readyState === 'complete') {
          clearInterval(checkPrintState);
          setTimeout(() => {
            if (!printWindow.closed) {
              printWindow.close();
            }
          }, 100);
        }
      }, 100);
    }, 100);
  };
};
export const handleMysticImageWritingLesson = (
  words,
  lineCount,
  lineHeight,
  themeColor,
  scenarioText,
  titleText
) => {
  const PRINT_SCALE_FACTOR = 0.4;
  const printLineHeight = Math.round(lineHeight * PRINT_SCALE_FACTOR);

  const printWindow = window.open('', '_blank');
  const styles = `
    <style>
${commonStyles}
      @media print {
        body { 

          font-family: Arial, sans-serif;
        }
        .container {
          max-width: 800px;
          margin: 0 auto;
        }


         .title {
          color: black;
          font-size: 2.6em;
          text-align: center;
          font-weight: bold;


        }
        .scenario-box {

          border-radius: 13px;
          padding: 24px;


        }
        .scenario-text {
          font-size: 18px;
          line-height: 1.6;

          color: #444;
        }
        .writing-box {

          border-radius: 13px;
          padding: 24px;
          margin-bottom: 0px;
          page-break-inside: avoid;

          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .image-container {
          width: 500px;
          height:300px;
          margin-bottom: 24px;
        }
        .word-image {
          width: 100%;
          height: 100%;
          border-radius: 13px;
          object-fit: cover;
          background-color: #f2f2f2;
        }
        .lines-container {
          width: 100%;
          padding-top: ${printLineHeight}px;
        }
        .writing-line {
          position: relative;
          border-bottom: 2px solid black;
          height: ${printLineHeight}px;
          margin-bottom: ${printLineHeight}px;
        }
    
        
      }
    </style>
  `;

  const content = `
    <!DOCTYPE html>
    <html>
      <head>
        <title>Skriv om den mystiska bilden</title>
        ${styles}
      </head>
      <body>
      ${logoHtml}
        <div class="container">
        <div class="title">${titleText}</div>

          
          ${
            scenarioText
              ? `
            <div class="scenario-box">
              <div class="scenario-text">${scenarioText}</div>
            </div>
          `
              : ''
          }
          
          ${words
            .map(
              word => `
            <div class="writing-box">
              <div class="image-container">
                <img src="${word.image}" alt="${word.word}" width="100%" class="word-image">
              </div>
              
              <div class="lines-container">
                ${Array(lineCount)
                  .fill(0)
                  .map(() => '<div class="writing-line"></div>')
                  .join('')}
              </div>
            </div>
          `
            )
            .join('')}
        </div>
      </body>
    </html>
  `;

  printWindow.document.write(content);
  printWindow.document.close();

  printWindow.onload = function () {
    setTimeout(() => {
      printWindow.print();
      const checkPrintState = setInterval(() => {
        if (printWindow.document.readyState === 'complete') {
          clearInterval(checkPrintState);
          setTimeout(() => {
            if (!printWindow.closed) {
              printWindow.close();
            }
          }, 100);
        }
      }, 100);
    }, 100);
  };
};

export const handlePrintFindWordsLesson = (titleText, themeImage, wordGrid, words, themeColor) => {
  const printWindow = window.open('', '_blank');
  const gridSize = wordGrid.length;
  const styles = `
    <style>
${commonStyles}
      @media print {
        body {

          font-family: 'Arial', sans-serif;
        }
        .container {
          max-width: 800px;
          margin: 0 auto;
          text-align: center;
        }
        h1 {
          color: ${themeColor};
          font-size: 1.8em;
          margin-bottom: 30px;
          font-weight: bold;
        }
        .theme-image {
          width: 200px;
          height: 200px;
          object-fit: cover;
          border-radius: 16px;
          margin-bottom: 30px;
        }
        .word-grid {
          display: inline-grid;
          grid-template-columns: repeat(${gridSize}, 60px);
          gap: 8px;
          padding: 16px;
          background: #ffffff;
          border-radius: 12px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          margin-bottom: 40px;
        }
        .grid-cell {
          width: 60px;
          height: 60px;
          border: 2px solid #2c2c2c;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          font-size: 24px;
          font-weight: 600;
          background-color: white;
          color: #333;
          text-transform: uppercase;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
          transition: all 0.2s ease;
        }
        .word-list {
          margin-top: 40px;
          page-break-inside: avoid;
        }
        .word-items {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
          gap: 16px;
          padding: 16px;
        }
        .word-item {
          display: flex;
          align-items: center;
          gap: 16px;
          border: 1px solid #e0e0e0;
          border-radius: 12px;
          padding: 12px;
          background-color: white;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
        }
        .word-item img {
          width: 80px;
          height: 80px;
          object-fit: cover;
          border-radius: 8px;
          background-color: #f7f7f7;
        }
        .word-item span {
          font-size: 1.2em;
          text-transform: capitalize;
          color: #333;
        }
        h2 {
          color: ${themeColor};
          font-size: 1.5em;
          margin: 20px 0;
          font-weight: bold;
        }
        @page {
          size: A4 portrait;
          margin: 2cm;
        }
      }
    </style>
  `;
  const puzzleContent = `
    <div class="container">
      <img src="${themeImage}" alt="Theme Image" class="theme-image">
      <h1>${titleText}</h1>
      <div class="word-grid">
        ${wordGrid
          .map(row => row.map(letter => `<div class="grid-cell">${letter}</div>`).join(''))
          .join('')}
      </div>
      <div class="word-list">
        <h2>Ordlista</h2>
        <div class="word-items">
          ${words
            .map(
              word => `
            <div class="word-item">
              <img src="${word.image}" >
              <span>${word.word}</span>
            </div>
          `
            )
            .join('')}
        </div>
      </div>
    </div>
  `;
  const content = `
    <!DOCTYPE html>
    <html>
      <head>
        <title>${titleText}</title>
        ${styles}
      </head>
      <body>
      ${logoHtml}
        ${puzzleContent}
      }
      </body>
    </html>
  `;
  printWindow.document.write(content);
  printWindow.document.close();
  printWindow.onload = function () {
    setTimeout(() => {
      printWindow.print();
      const checkPrintState = setInterval(() => {
        if (printWindow.document.readyState === 'complete') {
          clearInterval(checkPrintState);
          setTimeout(() => {
            if (!printWindow.closed) {
              printWindow.close();
            }
          }, 100);
        }
      }, 100);
    }, 100);
  };
};

export const handlePrintWritingLesson = (words, lineCount, lineHeight, themeColor, titleText) => {
  // Convert screen pixels to print-appropriate measurements
  const PRINT_SCALE_FACTOR = 0.4;
  const printLineHeight = Math.round(lineHeight * PRINT_SCALE_FACTOR);

  const printWindow = window.open('', '_blank');
  const styles = `
    <style>
${commonStyles}
      @media print {
        body { 

          font-family: Arial, sans-serif;
        }
        .container {
          max-width: 800px;
          margin: 0 auto;
        }
        h1 {
          color: black;
          text-align: center;
          font-size: 24px;
          margin-bottom: 30px;
          font-weight: bold;
        }
        .word-box {
          display: flex;
          align-items: flex-start;
          gap: 24px;
          border: 1px solid #e0e0e091;
          border-radius: 13px;
          padding: 16px;
          margin-bottom: 16px;
          page-break-inside: avoid;
          background-color: #f5f5f5;
          min-height: 200px;
        }
        .image-container {
          width: 200px;
          height: 200px;
          flex-shrink: 0;
        }
        .word-image {
          width: 100%;
          height: 100%;
          border-radius: 13px;
          object-fit: cover;
          background-color: #f2f2f2;
        }
        .lines-container {
          flex: 1;
          padding-top: ${printLineHeight}px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          min-height: 200px;
          box-sizing: border-box;
        }
        .writing-line {
          position: relative;
          border-bottom: 2px solid black;
          height: ${printLineHeight}px;
          margin-bottom: ${printLineHeight}px;
        }

      
      }
    </style>
  `;
  const content = `
    <!DOCTYPE html>
    <html>
      <head>
        <title>Skrivövningar</title>
        ${styles}
      </head>
      <body>
      ${logoHtml}
        <div class="container">
          <h1>${titleText}</h1>
          
          ${words
            .map(
              word => `
              <div class="word-box">
                <div class="image-container">
                  <img src="${word.image}" class="word-image">
                </div>
                
                <div class="lines-container">
                  ${Array(lineCount)
                    .fill(0)
                    .map(() => '<div class="writing-line"></div>')
                    .join('')}
                </div>
              </div>
            `
            )
            .join('')}
        </div>
      </body>
    </html>
  `;
  printWindow.document.write(content);
  printWindow.document.close();
  printWindow.onload = function () {
    setTimeout(() => {
      printWindow.print();
      const checkPrintState = setInterval(() => {
        if (printWindow.document.readyState === 'complete') {
          clearInterval(checkPrintState);
          setTimeout(() => {
            if (!printWindow.closed) {
              printWindow.close();
            }
          }, 100);
        }
      }, 100);
    }, 100);
  };
};
export const handlePrintLetterBox = (words, selectedLetters, themeColor, titleText) => {
  const printWindow = window.open('', '_blank');
  const styles = `
    <style>

${commonStyles}
      @media print {
        body { 

          font-family: Arial, sans-serif;
        }
        .container {
          max-width: 800px;
          margin: 0 auto;
        }
        .word-box {
          display: flex;
          align-items: center;
          gap: 24px;
          border: 1px solid #e0e0e091;
          border-radius: 13px;
          padding: 16px;
          margin-bottom: 24px;
          page-break-inside: avoid;
          background-color: #f5f5f5;
        }
        .image-container {
          width: 200px;
          height: 200px;
          flex-shrink: 0;
        }
        .word-image {
          width: 100%;
          height: 100%;
          border-radius: 13px;
          object-fit: cover;
          background-color: #f2f2f2;
        }
        .word-content {
          flex: 1;
        }
        .letters-grid {
          display: flex;
          flex-wrap: wrap;
          gap: 16px;
          margin-bottom: 16px;
        }
        .letter-circle {
          width: 44px;
          height: 44px;
          border-radius: 50%;
          border: 1px solid gray;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 600;
          color: black;
          background-color: white;
        }
        .letter-circle.selected {
          background-color: #8b5cf6;
          color: white;
        }
        .selected-letters {
          display: flex;
          align-items: center;
          gap: 8px;
          min-height: 45px;
        }
        .selected-letter {
          width: 45px;
          height: 45px;
          border-radius: 50%;
          background-color: #8b5cf6;
          color: white;
          font-size: 17px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        h1 {
          color: black;
          text-align: center;
          font-size: 24px;
          margin-bottom: 30px;
        }
        .word-list {
          text-align: center;
          font-weight: 500;
          font-size: 23px;
          margin-bottom: 32px;
        }
     
      }
    </style>
  `;

  const content = `
    <!DOCTYPE html>
    <html>
      <head>
        <title>Bokstavslådor</title>
        ${styles}
      </head>
      <body>
      ${logoHtml}
        <div class="container">
          <h1>${titleText}</h1>
          


          ${words
            .map((word, wordIndex) => {
              const selected = selectedLetters[wordIndex] || [];
              return `
                <div class="word-box">
                  <div class="image-container">
                    <img src="${word.image}" alt="${word.word}" class="word-image">
                  </div>
                  
                  <div class="word-content">
                    <div class="letters-grid">
                      ${word.letters
                        .map((letter, letterIndex) => {
                          const isSelected = selected.find(item => item.index === letterIndex);
                          return `
                            <div class="letter-circle ${isSelected ? 'selected' : ''}">
                              ${letter}
                            </div>
                          `;
                        })
                        .join('')}
                    </div>
                    
                    <div class="selected-letters">
                      ${selected
                        .map(
                          item => `
                            <div class="selected-letter">
                              ${item.letter}
                            </div>
                          `
                        )
                        .join('')}
                    </div>
                  </div>
                </div>
              `;
            })
            .join('')}
        </div>
      </body>
    </html>
  `;

  printWindow.document.write(content);
  printWindow.document.close();

  printWindow.onload = function () {
    setTimeout(() => {
      printWindow.print();
      const checkPrintState = setInterval(() => {
        if (printWindow.document.readyState === 'complete') {
          clearInterval(checkPrintState);
          setTimeout(() => {
            if (!printWindow.closed) {
              printWindow.close();
            }
          }, 100);
        }
      }, 100);
    }, 100);
  };
};

export const handlePrintNarrativeLesson = (planData, goal, develop, additionalInfo, themeColor) => {
  const printWindow = window.open('', '_blank');
  const styles = `
    <style>
${commonStyles}
      @media print {
        body { 

          font-family: Arial, sans-serif;
        }
        .container {
          max-width: 800px;
          margin: 0 auto;
        }
        h1 {
          color: ${themeColor};
          font-size: 32px;
          margin-bottom: 20px;
          text-align:center;
        }
        h2 {
          color: ${themeColor};
          font-size: 20px;
          margin-top: 30px;
          margin-bottom: 10px;
        }
        .section {
          margin-bottom: 30px;
          page-break-inside: avoid;
        }
        .mindmap-container {
          position: relative;
          width: 520px;
          height: 520px;
          margin: 40px auto;
          page-break-inside: avoid;
        }
        .center-image-container {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 2;
          width: 130px;
          height: 130px;
        }
        .center-image {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background-color: #f2f2f2;
          object-fit: cover;
        }
        .character-circle {
          position: absolute;
          width: 180px;
          height: 180px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          z-index: 3;
          transform: translate(-50%, -50%);
        }
        .character-image-container {
          position: relative;
          width: 96px;
          height: 96px;
          margin-bottom: 8px;
        }
        .character-image {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background-color: #f2f2f2;
          object-fit: cover;
        }
        .character-name {
          width: 240px;
          text-align: center;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          line-height: 1.2;
          height: 2.4em;
          font-weight: bold;
          font-size: 16px;
          color: ${themeColor};
          margin-top: 8px;
        }
        .story-images {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 20px;
          margin: 20px 0;
        }
        .story-image {
          width: 100%;
          height: 300px;
          object-fit: cover;
          border-radius: 8px;
        
        }
        .list {
          padding-left: 20px;
        }
        .list-item {
          margin-bottom: 10px;
          color: #333;
        }
    
      }
    </style>
  `;

  // Calculate positions for mind map
  const generateCharacterPositions = characters => {
    const radius = 200;
    return characters.map((char, index) => {
      const angle = (index / characters.length) * 2 * Math.PI - Math.PI / 2;
      const x = Math.cos(angle) * radius + 260; // Container width/2
      const y = Math.sin(angle) * radius + 260; // Container height/2
      return { character: char, x, y };
    });
  };

  const characterPositions = generateCharacterPositions(planData.whoOrWhich);

  const content = `
    <!DOCTYPE html>
    <html>
      <head>
        <title>${planData.storyTitle}</title>
        ${styles}
      </head>
      <body>
      ${logoHtml}
        <div class="container">
          <h1>${planData.storyTitle}</h1>
          
     
          
          <div class="section">
<h2 style="text-align:center;">${planData.headlines.characters}</h2>
<div style="height:30px"></div>
            <div class="mindmap-container">
              <div class="center-image-container">
                <img src="${planData.storyImage}" class="center-image" alt="Story theme">
              </div>
              ${characterPositions
                .map(
                  ({ character, x, y }) => `
                <div class="character-circle" style="left: ${x}px; top: ${y}px;">
                  <div class="character-image-container">
                    <img src="${character.imageUrl}" class="character-image" alt="${character.name}">
                  </div>
                  <div class="character-name">${character.name}</div>
                </div>
              `
                )
                .join('')}
            </div>
          </div>


          
          <div class="section">
            <h2>${planData.headlines.locations}</h2>
            <ul class="list">
              ${planData.where
                .map(
                  place => `
                <li class="list-item">${place}</li>
              `
                )
                .join('')}
            </ul>

          ${
            planData.additionalStoryImages && planData.additionalStoryImages.length > 0
              ? `
            <div class="section">
             
              <div class="story-images">
                ${planData.additionalStoryImages
                  .map(
                    imageUrl => `
                  <img src="${imageUrl}" class="story-image" alt="Story scene">
                `
                  )
                  .join('')}
              </div>
            </div>
          `
              : ''
          }

          </div>
          
          <div class="section">
<h2>${planData.headlines.actions}</h2>

            <ul class="list">
              ${planData.what
                .map(
                  action => `
                <li class="list-item">${action}</li>
              `
                )
                .join('')}
            </ul>
          </div>
        </div>
      </body>
    </html>
  `;

  printWindow.document.write(content);
  printWindow.document.close();

  printWindow.onload = function () {
    setTimeout(() => {
      printWindow.print();
      const checkPrintState = setInterval(() => {
        if (printWindow.document.readyState === 'complete') {
          clearInterval(checkPrintState);
          setTimeout(() => {
            if (!printWindow.closed) {
              printWindow.close();
            }
          }, 100);
        }
      }, 100);
    }, 100);
  };
};

export const handlePrint = (
  terms,
  storyTheme,
  themeImage,
  themeTranslation,
  themeColor,
  titleText
) => {
  const printWindow = window.open('', '_blank');
  const styles = `
    <style>
      ${commonStyles}
      @media print {
        body { 
          font-family: Arial, sans-serif;
        }
        .print-grid { 
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          gap: 16px;
          page-break-inside: avoid;
          margin-bottom: 16px;
        }
        .term-box {
          border: 2px solid ${themeColor};
          border-radius: 8px;
          padding: 10px;
          text-align: center;
          height: 180px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
        }
        .middle-row {
          display: grid;
          grid-template-columns: 1fr 2fr 1fr;
          gap: 16px;
          margin: 16px 0;
          page-break-inside: avoid;
        }
        .theme-box {
          border: 2px solid ${themeColor};
          border-radius: 8px;
          padding: 20px;
          text-align: center;
          background-color: #f0f0f0;
          height: auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
        }
        .word { 
          font-family: Arial, sans-serif;
          font-weight: bold; 
          font-size: 18px; 
          margin: 5px 0; 
        }
        .translation { 
          font-family: Arial, sans-serif;
          font-weight: bold; 
          font-size: 18px; 
          margin: 5px 0; 
        }
        .theme-title { 
          font-family: Arial, sans-serif;
          font-weight: bold; 
          font-size: 24px; 
          color: ${themeColor}; 
          margin: 0;
        }
        .theme-translation { 
          font-family: Arial, sans-serif;
          font-weight: bold; 
          font-size: 20px; 
          color: ${themeColor}; 
          margin: 0;
        }
        img { 
          width: 80px; 
          height: 80px; 
          border-radius: 50%; 
          object-fit: cover; 
        }
        .theme-img { 
          width: 60px; 
          height: 60px; 
        }
        h1 {
          font-family: Arial, sans-serif;
          text-align: center;
          margin-bottom: 30px;
          color: ${themeColor};
        }

.page-break { page-break-before: always }
.sentences-grid { display: grid; grid-template-columns: repeat(2, 1fr); gap: 24px; padding: 20px }
.sentence-box { border: 2px solid ${themeColor}; border-radius: 8px; padding: 25px }

     
        .example-sentences {
          margin-top: 40px;
        }
        .example-sentence-box {
          border: 1px solid ${themeColor};
          border-radius: 8px;
          padding: 20px;
          margin-bottom: 16px;
          background-color: #ffffff;
        }
        .example-word {
          color: ${themeColor};
          font-weight: bold;
          font-size: 18px;
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          gap: 10px;
        }
        .example-word img {
          width: 40px;
          height: 40px;
        }
        .example-text {
          font-style: italic;
          margin: 5px 0;
        }
      }
    </style>
  `;

  const generateTermBoxes = termsArray => {
    return termsArray
      .map(
        term => `
        <div class="term-box">
          <div class="word">${term.word}</div>
          <img src="${term.image}" alt="${term.word}">
          <div class="translation">${term.translation}</div>
        </div>
      `
      )
      .join('');
  };

  const generateExampleSentences = terms => {
    return terms
      .map(
        term => `
        <div class="example-sentence-box">
          <div class="example-word">
            <img src="${term.image}" alt="${term.word}">
            ${term.word} / ${term.translation}
          </div>
          <div class="example-text">${term.exampleSentence}</div>
          <div class="example-text">${term.translatedExampleSentence}</div>
        </div>
      `
      )
      .join('');
  };

  const content = `
    <!DOCTYPE html>
    <html>
      <head>
        <title>Glosor i lådor</title>
        ${styles}
      </head>
      <body>
        ${logoHtml}
        <h1>${titleText}</h1>
        
        <div class="print-grid">
          ${generateTermBoxes(terms.slice(0, 4))}
        </div>
        
        <div class="middle-row">
          ${
            terms[4]
              ? `
            <div class="term-box">
              <div class="word">${terms[4].word}</div>
              <img src="${terms[4].image}" alt="${terms[4].word}">
              <div class="translation">${terms[4].translation}</div>
            </div>
          `
              : '<div></div>'
          }
          
          <div class="theme-box">
            <div class="theme-title">${storyTheme}</div>
            <img class="theme-img" src="${themeImage}" alt="${storyTheme}">
            <div class="theme-translation">${themeTranslation}</div>
          </div>
          
          ${
            terms[5]
              ? `
            <div class="term-box">
              <div class="word">${terms[5].word}</div>
              <img src="${terms[5].image}" alt="${terms[5].word}">
              <div class="translation">${terms[5].translation}</div>
            </div>
          `
              : '<div></div>'
          }
        </div>
        
        <div class="print-grid">
          ${generateTermBoxes(terms.slice(6))}
        </div>


        
        


<div class="page-break"></div>
<h1 style="font-size: 25px">Exempelmeningar</h1>
<div class="sentences-grid">
  ${terms
    .map(
      term => `
    <div class="sentence-box" style="background-color: #f9f9f9}">
      <div style="display: flex; align-items: center; margin-bottom: 16px">
        <img src="${term.image}"  
          style="width: 40px; height: 40px; margin-right: 16px; background-color: #f1f1f1; border-radius: 50%">
        <div style="font-weight: bold; font-size: 18px; color: ${themeColor}">
          ${term.word} / ${term.translation}
        </div>
      </div>
      <div style="position: relative">
        <div style="font-style: italic; margin-bottom: 8px">${term.exampleSentence}</div>
        <div style="font-style: italic">${term.translatedExampleSentence}</div>
      </div>
    </div>
  `
    )
    .join('')}
</div>



      </body>
    </html>
  `;

  printWindow.document.write(content);
  printWindow.document.close();

  printWindow.onload = function () {
    setTimeout(() => {
      printWindow.print();
      const checkPrintState = setInterval(() => {
        if (printWindow.document.readyState === 'complete') {
          clearInterval(checkPrintState);
          // Close window after 500ms if printing dialog was closed
          setTimeout(() => {
            if (!printWindow.closed) {
              printWindow.close();
            }
          }, 100);
        }
      }, 100);
    }, 100);
  };
};
