import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Tooltip, Divider } from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbDownIcon from '@mui/icons-material/ThumbDownOutlined';
import { db } from './firebase';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { useTheme } from '@mui/material/styles';
import { getStyles } from '../index';
import Logger from './Logger';
const RecommendationComponent = ({
  itemId,
  userId,
  collectionName,
  questionText,
  largerStyle = false,
}) => {
  const [recommendation, setRecommendation] = useState(null);
  const [hasVoted, setHasVoted] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const [fadeOutBox, setFadeOutBox] = useState(false);
  const [upvotes, setUpvotes] = useState(0);
  const theme = useTheme();
  const styles = getStyles(theme);

  useEffect(() => {
    Logger.log('recommend id: ', itemId);
    const fetchRecommendation = async () => {
      if (itemId) {
        const docRef = doc(db, collectionName, itemId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setRecommendation(data.userVotes?.[userId] || null);
          setHasVoted(data.userVotes?.hasOwnProperty(userId) || false);
          //setFadeOutBox(data.userVotes?.hasOwnProperty(userId) || false);
          setUpvotes(data.upvotes || 0);
        }
      }
    };
    fetchRecommendation();
  }, [itemId, userId, collectionName]);

  const handleRecommend = async isRecommended => {
    Logger.log('handleRecommend userId: ', userId);
    if (itemId) {
      const docRef = doc(db, collectionName, itemId);
      const docSnap = await getDoc(docRef);
      const currentData = docSnap.data();
      const updatedData = {
        [`userVotes.${userId}`]: isRecommended,
        upvotes: (currentData.upvotes || 0) + (isRecommended ? 1 : 0),
        downvotes: (currentData.downvotes || 0) + (isRecommended ? 0 : 1),
      };
      if (hasVoted) {
        updatedData.upvotes -= currentData.userVotes[userId] ? 1 : 0;
        updatedData.downvotes -= currentData.userVotes[userId] ? 0 : 1;
      }
      await updateDoc(docRef, updatedData);
      setRecommendation(isRecommended);
      setHasVoted(true);
      setShowThankYou(true);
      setUpvotes(updatedData.upvotes);
      //setTimeout(() => setFadeOutBox(true), 2000);
      Logger.log('Röstade just på HP-ID: ', itemId);
    }
  };
  const formatUpvotes = upvotes => {
    if (upvotes == null) return '0'; // Handle undefined or null

    const num = parseInt(upvotes, 10);
    if (isNaN(num)) return '0'; // Handle non-numeric strings

    if (num >= 1000000) {
      return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    }
    if (num >= 1000) {
      return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'k';
    }
    return num.toString();
  };

  //if (fadeOutBox) return null;

  return (
    <Box
      sx={{
        ...styles.smallBox,
        padding: largerStyle ? '11px' : '0px',
        marginTop: '0px',
        display: 'inline-flex',
        width: 'auto',
        alignItems: 'center',
        position: 'relative',
        marginBottom: '0px',
      }}
    >
      <IconButton
        onClick={() => handleRecommend(true)}
        disabled={hasVoted}
        color={recommendation === true ? 'primary' : 'default'}
        sx={{ paddingRight: '6px' }}
      >
        <ThumbUpIcon sx={{ fontSize: '17px' }} />
      </IconButton>
      <Typography
        sx={{
          mx: 0,
          fontWeight: '600',
          fontSize: '13px',
          ml: 0,

          color: theme.palette.mode === 'dark' ? 'white' : '#32324d',
        }}
      >
        {formatUpvotes(upvotes)}
      </Typography>
      <Divider orientation='vertical' flexItem sx={{ ml: 1 }} />
      <IconButton
        onClick={() => handleRecommend(false)}
        disabled={hasVoted}
        color={recommendation === false ? 'primary' : 'default'}
      >
        <ThumbDownIcon sx={{ fontSize: '17px' }} />
      </IconButton>
    </Box>
  );
};

export default RecommendationComponent;
