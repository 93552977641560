import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  IconButton,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import DescriptionIcon from '@mui/icons-material/Description';
import SchoolIcon from '@mui/icons-material/School';
import GenerateIcon from '@mui/icons-material/AutoAwesome';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import DeleteIcon from '@mui/icons-material/Delete';
import { getStyles } from '../index';
import { collection, query, where, getDocs, deleteDoc, doc, orderBy } from 'firebase/firestore';
import { db } from './firebase';

const Essays = ({ user }) => {
  const theme = useTheme();
  const styles = getStyles(theme);
  const [essays, setEssays] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchEssays = async () => {
      if (!user) return;

      setLoading(true);
      const essaysRef = collection(db, 'essays');
      const q = query(essaysRef, where('userId', '==', user.uid), orderBy('createdAt', 'desc'));

      try {
        const querySnapshot = await getDocs(q);
        const essayList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEssays(essayList);
      } catch (error) {
        console.error('Error fetching essays: ', error);
      } finally {
        setLoading(false);
      }
    };

    fetchEssays();
  }, [user]);

  const handleDelete = async (e, essayId) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      await deleteDoc(doc(db, 'essays', essayId));
      setEssays(essays.filter(essay => essay.id !== essayId));
      showSnackbar('Uppsats raderad.', 'success');
    } catch (error) {
      console.error('Error deleting essay: ', error);
    }
  };

  if (loading) {
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Box sx={styles.container}>
        <Box sx={styles.innerContainer}>
          <Typography variant='h1' sx={{ ...styles.header, borderBottom: '0px' }}>
            Dina uppsatser
          </Typography>
          <Typography variant='subtitle1' gutterBottom sx={{ ...styles.softerText, mb: 4 }}>
            Här samlas de uppsatser du laddat upp.
          </Typography>
          <Box sx={{ ...styles.buttonsContainer, ...styles.additionalButtonsContainer }}>
            <Button
              variant='contained'
              color='primary'
              startIcon={<GenerateIcon />}
              component={Link}
              to={`/app/uppsats/ny`}
            >
              Lägg till uppsats
            </Button>
          </Box>
          {essays.length === 0 ? (
            <Typography variant='body1' sx={{ textAlign: 'center' }}>
              Du har inga uppsatser än. Börja med att skapa en ny uppsats!
            </Typography>
          ) : (
            <Grid container spacing={3}>
              {essays.map(essay => (
                <Grid item xs={12} sm={6} md={4} key={essay.id}>
                  <Card
                    component={Link}
                    to={`/app/uppsats/${essay.id}`}
                    sx={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      boxShadow: 'none',
                      borderRadius: '7px',
                      backgroundImage: 'none',
                      textDecoration: 'none',
                      border:
                        theme.palette.mode === 'dark' ? '1px solid #35414d' : '1px solid #eee',
                      position: 'relative',
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        transform: 'translateY(-2px)',
                        textDecoration: 'none',
                      },
                    }}
                  >
                    <IconButton
                      onClick={e => handleDelete(e, essay.id)}
                      sx={{
                        position: 'absolute',
                        top: '8px',
                        right: '8px',
                        zIndex: 1,
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>

                    <CardContent
                      sx={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        height: '100%',
                        padding: '30px',
                        border: 'none',
                        backgroundColor:
                          theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'white',
                        '&:hover': {
                          backgroundColor:
                            theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'inherit',
                        },
                      }}
                    >
                      <Box>
                        {essay.reviewed ? (
                          <Tooltip title='Granskad'>
                            <CheckCircleIcon
                              sx={{
                                color: theme.palette.mode === 'dark' ? '#63e363' : 'green',
                                fontSize: '40px',
                              }}
                            />
                          </Tooltip>
                        ) : (
                          <DescriptionIcon sx={{ ...styles.grayText, fontSize: '40px' }} />
                        )}
                      </Box>

                      <Typography
                        variant='h6'
                        sx={{
                          fontWeight: '600',
                          fontSize: '1.45rem',
                          color: theme.palette.mode === 'dark' ? '#e0e0e0' : 'inherit',
                          wordBreak: 'break-word',
                        }}
                      >
                        {essay.fileName}
                      </Typography>

                      <Box sx={{ mt: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                          <SchoolIcon sx={{ ...styles.grayText, fontSize: '16px', mr: 1 }} />
                          <Typography variant='body2' sx={{ ...styles.grayText }}>
                            {essay.educationLevel}
                          </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                          <HistoryEduIcon sx={{ ...styles.grayText, fontSize: '16px', mr: 1 }} />
                          <Typography variant='body2' sx={{ ...styles.grayText }}>
                            {essay.rubricName}
                          </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <CalendarTodayIcon sx={{ ...styles.grayText, fontSize: '16px', mr: 1 }} />
                          <Typography variant='body2' sx={{ ...styles.grayText }}>
                            {essay.createdAt.toDate().toLocaleDateString()}
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          mt: 3,
                        }}
                      >
                        <Button
                          variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                          fullWidth
                        >
                          Visa uppsats
                        </Button>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Essays;
