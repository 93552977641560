import React, { useState } from 'react';
import { Button, Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import {
  Document,
  Packer,
  Paragraph,
  TextRun,
  ImageRun,
  AlignmentType,
  Table,
  TableRow,
  TableCell,
  WidthType,
  BorderStyle,
} from 'docx';
import SaveIcon from '@mui/icons-material/Save';
import DescriptionIcon from '@mui/icons-material/Description';
import GoogleIcon from '@mui/icons-material/Google';
import CircularProgress from '@mui/material/CircularProgress';

const SaveDocumentButton = ({ editorContent, showSnackbar, theme, storyData }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const createWordDocument = async () => {
    const doc = new Document({
      sections: [
        {
          properties: {},
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: storyData.title,
                  bold: true,
                  size: 32,
                }),
              ],
            }),
            new Paragraph({ spacing: { after: 400 } }),

            ...(await Promise.all(
              storyData.characters.map(async char => {
                const row = new TableRow({
                  children: [
                    new TableCell({
                      width: {
                        size: 30,
                        type: WidthType.PERCENTAGE,
                      },
                      borders: {
                        top: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
                        bottom: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
                        left: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
                        right: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
                      },
                      margins: {
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                      },
                      children: char.imageUrl
                        ? [
                            new Paragraph({
                              children: [
                                new ImageRun({
                                  data: await fetch(char.imageUrl).then(r => r.arrayBuffer()),
                                  transformation: {
                                    width: 200,
                                    height: 200,
                                  },
                                }),
                              ],
                            }),
                          ]
                        : [],
                    }),
                    new TableCell({
                      width: {
                        size: 70,
                        type: WidthType.PERCENTAGE,
                      },
                      borders: {
                        top: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
                        bottom: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
                        left: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
                        right: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
                      },
                      margins: {
                        top: 150,
                        bottom: 150,
                        left: 150,
                        right: 150,
                      },
                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: char.name,
                              bold: true,
                              size: 28,
                            }),
                          ],
                        }),
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: char.description,
                              size: 24,
                            }),
                          ],
                        }),
                      ],
                    }),
                  ],
                });

                return new Table({
                  width: {
                    size: 100,
                    type: WidthType.PERCENTAGE,
                  },
                  rows: [row],
                });
              })
            )),

            new Paragraph({ spacing: { after: 400 } }),

            ...storyData.storyContent.map(
              section =>
                new Paragraph({
                  children: [
                    new TextRun({
                      text: section.content,
                      size: 24,
                    }),
                  ],
                  spacing: { after: 300 },
                })
            ),

            ...(storyData.mainStoryImage
              ? [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new ImageRun({
                        data: await fetch(storyData.mainStoryImage).then(r => r.arrayBuffer()),
                        transformation: {
                          width: 400,
                          height: 400,
                        },
                      }),
                    ],
                  }),
                ]
              : []),

            new Paragraph({ spacing: { after: 400 } }),

            new Table({
              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              },
              rows: [
                new TableRow({
                  children: await Promise.all(
                    storyData.environmentImages.map(
                      async env =>
                        new TableCell({
                          width: {
                            size: Math.floor(100 / storyData.environmentImages.length),
                            type: WidthType.PERCENTAGE,
                          },
                          children: env.imageUrl
                            ? [
                                new Paragraph({
                                  alignment: AlignmentType.CENTER,
                                  children: [
                                    new ImageRun({
                                      data: await fetch(env.imageUrl).then(r => r.arrayBuffer()),
                                      transformation: {
                                        width: 300,
                                        height: 200,
                                      },
                                    }),
                                  ],
                                }),
                              ]
                            : [],
                        })
                    )
                  ),
                }),
              ],
            }),
          ],
        },
      ],
    });

    return doc;
  };

  const handleSave = async saveType => {
    setIsSaving(true);
    try {
      if (saveType === 'word') {
        const doc = await createWordDocument();
        const blob = await Packer.toBlob(doc);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${storyData.title || 'story'}.docx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        showSnackbar('Dokumentet har sparats som Word-fil', 'success');
      } else if (saveType === 'googledocs') {
        showSnackbar('Inte fixat ännu', 'error');
      }
    } catch (error) {
      console.error('Error saving document:', error);
      showSnackbar(error.message || 'Ett fel uppstod när dokumentet skulle sparas', 'error');
    } finally {
      setIsSaving(false);
      handleClose();
    }
  };

  return (
    <>
      <Button
        variant='contained'
        onClick={handleClick}
        disabled={isSaving}
        sx={{ ml: 2, mb: 6 }}
        startIcon={isSaving ? <CircularProgress size={16} /> : <SaveIcon />}
      >
        {isSaving ? 'Sparar...' : 'Spara dokument'}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          sx: {
            mt: 1,
            '& .MuiMenuItem-root': {
              px: 2,
              py: 1,
            },
          },
        }}
      >
        <MenuItem onClick={() => handleSave('word')} disabled={isSaving}>
          <ListItemIcon>
            <DescriptionIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Spara som Word-dokument</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleSave('googledocs')} disabled>
          <ListItemIcon>
            <GoogleIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Spara till Google Docs</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default SaveDocumentButton;
