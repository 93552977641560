import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  IconButton,
  Chip,
  Button,
  CircularProgress,
  alpha,
  darken,
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';
import SchoolIcon from '@mui/icons-material/School';
import NumbersIcon from '@mui/icons-material/Numbers';

import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';

const MyFlashcards = ({ user, theme, styles, onStartTraining }) => {
  const [collections, setCollections] = useState([]);
  const [loadingCollections, setLoadingCollections] = useState(false);
  const [isManaging, setIsManaging] = useState(false);

  useEffect(() => {
    fetchUserCollections();
  }, [user.uid]);

  const fetchUserCollections = async () => {
    setLoadingCollections(true);
    try {
      const querySnapshot = await getDocs(collection(db, 'flashcardCollections'));
      const userCollections = querySnapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .filter(doc => doc.userId === user.uid)
        .sort((a, b) => (b.lastTrainingSession || 0) - (a.lastTrainingSession || 0))
        .slice(0, 30);
      setCollections(userCollections);
    } catch (error) {
      console.error('Error fetching user collections:', error);
    } finally {
      setLoadingCollections(false);
    }
  };

  const handleDeleteCollection = async (collectionId, event) => {
    if (event) {
      event.stopPropagation();
    }
    setCollections(prevCollections =>
      prevCollections.filter(collection => collection.id !== collectionId)
    );
    try {
      await deleteDoc(doc(db, 'flashcardCollections', collectionId));
    } catch (error) {
      console.error('Error deleting collection:', error);
      fetchUserCollections();
    }
  };

  const getTimeSinceLastTraining = lastTrainingSession => {
    if (!lastTrainingSession) return 'Aldrig tränat';
    const now = Date.now();
    const diff = now - lastTrainingSession;
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);

    if (seconds < 60) return `${seconds} sekund${seconds !== 1 ? 'er' : ''} sedan`;
    if (minutes < 60) return `${minutes} minut${minutes !== 1 ? 'er' : ''} sedan`;
    if (hours < 24) return `${hours} timm${hours !== 1 ? 'ar' : 'e'} sedan`;

    const days = Math.floor(hours / 24);
    if (days === 1) return 'Igår';
    if (days < 7) return `${days} dagar sedan`;
    const weeks = Math.floor(days / 7);
    if (weeks === 1) return '1 vecka sedan';
    if (weeks < 4) return `${weeks} veckor sedan`;
    const months = Math.floor(days / 30);
    if (months === 1) return '1 månad sedan';
    if (months < 12) return `${months} månader sedan`;
    const years = Math.floor(days / 365);
    return `${years} år sedan`;
  };

  const getFlashcardResults = flashcards => {
    const results = { easy: 0, good: 0, hard: 0, fail: 0 };
    flashcards.forEach(card => {
      if (card.correct === 'easy') results.easy++;
      else if (card.correct === 'good') results.good++;
      else if (card.correct === 'hard') results.hard++;
      else if (card.correct === 'fail') results.fail++;
    });
    return results;
  };

  const resultChips = [
    { name: 'Väldigt svåra', color: '#FF4D4D', key: 'fail', icon: CancelOutlinedIcon },
    { name: 'Svåra', color: '#C47510', key: 'hard', icon: CircleIcon },
    { name: 'Ganska lätta', color: '#27792B', key: 'good', icon: CheckCircleOutlineIcon },
    { name: 'Lätta', color: '#2196F3', key: 'easy', icon: CheckCircleOutlineIcon },
  ];

  if (loadingCollections) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      {collections.length === 0 ? (
        <Box
          sx={{
            p: 4,
            textAlign: 'center',
            color: 'text.secondary',
            bgcolor: alpha(theme.palette.background.paper, 0.6),
            borderRadius: 2,
          }}
        >
          De flashcards du övar på hamnar här.
        </Box>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {collections.map(collection => (
            <Card
              key={collection.id}
              sx={{
                width: '100%',
                borderRadius: 5,
                padding: '13px',
                boxShadow: 'none',
                border: theme => `1px solid ${theme.palette.divider}`,
                cursor: 'pointer',
                '&:hover': {
                  bgcolor: theme => theme.palette.background.paperHover,
                  border: theme => `1px solid ${darken(theme.palette.divider, 0.2)}`,
                },
              }}
              onClick={() => !isManaging && onStartTraining(collection)}
            >
              <CardContent
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  py: 2,
                  '&:last-child': { pb: 2 },
                }}
              >
                <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', gap: 3 }}>
                  <ViewAgendaIcon
                    sx={{
                      fontSize: 24,
                      color: theme => darken(theme.palette.text.main, 0.2),
                    }}
                  />
                  <Typography
                    variant='subtitle1'
                    sx={{
                      fontWeight: 500,
                      color: theme.palette.text.primary,
                      flex: '0 0 250px',
                    }}
                  >
                    {collection.collectionName}
                    {collection.isCopied && (
                      <Box
                        component='span'
                        sx={{
                          display: 'inline-flex',
                          alignItems: 'center',
                          ml: 2,
                          color: alpha(theme.palette.text.secondary, 0.7),
                        }}
                      >
                        <CircleIcon sx={{ fontSize: 8, color: '#85a5f0', mr: 0.5 }} />
                        <Typography variant='caption'>Kopierad</Typography>
                      </Box>
                    )}
                  </Typography>

                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      color: theme.palette.text.secondary,
                      flex: '0 0 150px',
                    }}
                  >
                    <NumbersIcon sx={{ fontSize: 16, mr: 1 }} />
                    <Typography variant='body2'>
                      {collection.flashcards.length} flashcards
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(2, 1fr)',
                      gap: 1,
                      flex: '0 0 200px',
                      maxWidth: '200px',
                    }}
                  >
                    {(() => {
                      const results = getFlashcardResults(collection.flashcards);
                      return resultChips.map(
                        chip =>
                          results[chip.key] > 0 && (
                            <Chip
                              key={chip.name}
                              label={`${chip.name}: ${results[chip.key]}`}
                              size='small'
                              icon={<chip.icon style={{ color: 'inherit' }} />}
                              sx={{
                                height: '24px',
                                background: alpha(chip.color, 0.1),
                                color: chip.color,
                                textTransform: 'uppercase',
                                fontSize: '11px',
                                fontWeight: 500,
                                '& .MuiChip-icon': {
                                  fontSize: 14,
                                  color: 'inherit',
                                },
                              }}
                            />
                          )
                      );
                    })()}
                  </Box>
                </Box>

                {isManaging ? (
                  <IconButton
                    onClick={e => handleDeleteCollection(collection.id, e)}
                    sx={{
                      color: theme.palette.error.main,
                      '&:hover': {
                        color: darken(theme.palette.error.main, 0.1),
                      },
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                ) : (
                  <Box
                    sx={{
                      width: 24,
                      height: 24,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: alpha(theme.palette.text.primary, 0.3),
                    }}
                  >
                    →
                  </Box>
                )}
              </CardContent>
            </Card>
          ))}

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4, alignItems: 'center' }}>
            {collections.length > 0 && (
              <Button
                variant='print'
                onClick={() => setIsManaging(!isManaging)}
                startIcon={<DeleteIcon />}
              >
                {isManaging ? 'Klar?' : 'Hantera'}
              </Button>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default MyFlashcards;
