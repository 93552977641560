import axios from 'axios';
import { Anthropic } from '@anthropic-ai/sdk';
import { updateUserTokens } from './functions';
import Logger from './Logger';
// const apiKey = 'sk-proj-C69fw7mjS0f4DK64gRHhT3BlbkFJLhrh35xtgTCTy4UOD9yP';

// const openAiKey = process.env.OPENAI_API_KEY;

const API_URL = process.env.NODE_ENV === 'production' ? '/api' : 'http://localhost:3001/api';

const generateImage = async (prompt, userId, size, model, numImages, quality, responseFormat) => {
  try {
    const response = await axios.post(`${API_URL}/generate-image`, {
      prompt: prompt,
      n: numImages,
      size: size,
      model: model,
      quality: quality,
      responseFormat: responseFormat,
    });

    // Log the response data to understand its structure
    console.log('Response data:', response.data);
    console.log('Full API response:', JSON.stringify(response.data, null, 2));
    const estimatedTokens = estimateTokensForDallE(prompt);
    updateUserTokens(userId, estimatedTokens);
    // Access the image URL from the response data
    const imageUrl = response.data.data[0].url;

    // Return the image URL
    return response.data;
  } catch (error) {
    console.error('Error generating image:', error);
    throw error; // Re-throw the error to handle it in the calling function
  }
};

const callOpenAISTT = async (audioBlob, userId) => {
  try {
    const formData = new FormData();
    formData.append('file', audioBlob, 'audio.webm');
    formData.append('model', 'whisper-1');

    const response = await axios.post(`${API_URL}/stt`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    // Estimate tokens for the transcribed text
    const estimatedTokens = estimateTokens([{ content: response.data.text, role: '' }]);
    Logger.log(`Estimated tokens for STT text: ${estimatedTokens}`);
    updateUserTokens(userId, estimatedTokens);

    return response.data.text;
  } catch (error) {
    console.error('Error calling STT API:', error);
    throw error;
  }
};

const callOpenAITTS = async (text, voice = 'alloy', signal, userId) => {
  const estimatedTokens = estimateTokens([{ content: text, role: '' }]);
  Logger.log(`Estimated tokens for TTS text: ${estimatedTokens} for userId `, userId);
  updateUserTokens(userId, estimatedTokens);
  try {
    const response = await axios.post(
      `${API_URL}/tts`,
      {
        text,
        voice,
      },
      {
        responseType: 'arraybuffer',
        signal: signal,
      }
    );

    return new Blob([response.data], { type: 'audio/mpeg' });
  } catch (error) {
    if (axios.isCancel(error)) {
      Logger.log('TTS request was cancelled');
    } else {
      console.error('Error calling TTS API:', error);
    }
    throw error;
  }
};

const callClaude = async (messages, maxTokens, clean = false) => {
  try {
    const response = await axios.post(`${API_URL}/claude`, {
      messages,
      maxTokens,
    });

    Logger.log('callClaude: ', response);
    return response;
    //return response.data.content[0].text;
  } catch (error) {
    console.error('Error calling Claude:', error);
    throw error;
  }
};
const callApi = async (
  messages,
  maxTokens = 6000,
  clean = false,
  userId = null,
  temperature = 0.7
) => {
  const startTime = performance.now();
  try {
    Logger.log('Sending messages to API:', messages);
    const response = await axios.post(`${API_URL}/openai`, {
      messages,
      maxTokens,
      temperature,
    });

    Logger.log('API response:', response.data);
    const totalTokens = response.data.usage.total_tokens;
    if (userId) {
      updateUserTokens(userId, totalTokens);
      Logger.log(
        'updateUserTokens ran from callApi on userId:',
        userId,
        ' with increased tokens of ',
        totalTokens
      );
    }

    Logger.log('data.Usage: ', response.data.usage);
    Logger.log('maxTokens defined:', maxTokens);
    Logger.log('totalTokens used:', totalTokens);

    if (!clean) return response.data.choices[0].message.content;
    else return response;
  } catch (error) {
    console.error('Error calling API:', error);
    return null;
  } finally {
    const endTime = performance.now();
    const elapsedTime = (endTime - startTime) / 1000;
    Logger.log(`API call took ${elapsedTime} seconds`);
  }
};

const estimateTokens = messages => {
  // Uppskattning: 1 token ≈ 4 tecken för engelska texter
  const CHARS_PER_TOKEN = 4;

  let totalChars = 0;

  for (const message of messages) {
    totalChars += message.content.length;
    // Lägg till extra tecken för rollnamnet (t.ex. "user" eller "assistant")
    totalChars += message.role.length;
  }

  // Avrunda uppåt för att ge en konservativ uppskattning
  return Math.ceil(totalChars / CHARS_PER_TOKEN);
};

const estimateTokensForDallE = prompt => {
  // Uppskattning: 1 token ≈ 4 tecken för engelska texter
  const CHARS_PER_TOKEN = 4;

  // Total character count for the prompt
  let totalChars = prompt.length;

  // Avrunda uppåt för att ge en konservativ uppskattning
  return Math.ceil(totalChars / CHARS_PER_TOKEN);
};

export const callApiWithStreaming = async (messages, onChunk, userId = null, signal = null) => {
  const initialTokens = estimateTokens(messages);
  let totalTokens = initialTokens;

  try {
    const fetchOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        messages: messages,
        stream: true,
        stream_options: {
          include_usage: true,
        },
      }),
    };

    if (signal) {
      fetchOptions.signal = signal;
    }

    const response = await fetch(`${API_URL}/openai-stream`, fetchOptions);
    const reader = response.body.getReader();
    const decoder = new TextDecoder('utf-8');
    let buffer = '';

    while (true) {
      const { done, value } = await reader.read();
      if (done) break;
      buffer += decoder.decode(value, { stream: true });
      const lines = buffer.split('\n');
      buffer = lines.pop();

      for (const line of lines) {
        if (line.startsWith('data: ')) {
          const data = line.slice(6);
          if (data === '[DONE]') continue;
          try {
            const parsed = JSON.parse(data);
            if (parsed.choices && parsed.choices[0].delta && parsed.choices[0].delta.content) {
              onChunk(parsed.choices[0].delta.content);
            }

            if (parsed.usage) {
              totalTokens = parsed.usage.total_tokens;
            }
          } catch (error) {
            console.error('Error parsing streaming message:', error);
          }
        }
      }
    }

    if (userId) {
      updateUserTokens(userId, totalTokens);
      // Logger.log('updateUserTokens ran from callApiWithStreaming() on userId:', userId, " with total tokens of ", totalTokens);
    }

    return buffer;
  } catch (error) {
    if (error.name === 'AbortError') {
      Logger.log('Streaming request was aborted');
    } else {
      console.error('Error in streaming API call:', error);
    }
    throw error;
  }
};

// export const callApiWithStreaming2 = async (messages, onChunk, abortSignal) => {
//   const response = await fetch('https://api.openai.com/v1/chat/completions', {
//     method: 'POST',
//     headers: {
//       'Authorization': `Bearer ${apiKey}`,
//       'Content-Type': 'application/json'
//     },
//     body: JSON.stringify({
//       model: 'gpt-4o-mini',
//       messages: messages,
//       stream: true
//     }),
//     signal: abortSignal
//   });

//   const reader = response.body.getReader();
//   const decoder = new TextDecoder('utf-8');
//   let buffer = '';

//   while (true) {
//     const { done, value } = await reader.read();
//     if (done) break;
//     buffer += decoder.decode(value, { stream: true });
//     const lines = buffer.split('\n');
//     buffer = lines.pop();
//     for (const line of lines) {
//       if (line.startsWith('data: ')) {
//         const data = line.slice(6);
//         if (data === '[DONE]') continue;
//         try {
//           const parsed = JSON.parse(data);
//           const content = parsed.choices[0].delta.content;
//           if (content) {
//             onChunk(content);
//           }
//         } catch (error) {
//           console.error('Error parsing streaming message:', error);
//         }
//       }
//     }
//   }
//   return buffer;
// };

export { callApi, callClaude, callOpenAITTS, callOpenAISTT, generateImage };
