import React, { useState, useEffect } from 'react';
import { collection, query, getDocs, where, Timestamp, orderBy } from 'firebase/firestore';
import { db } from './firebase';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
  Box,
  Card,
  Tooltip,
} from '@mui/material';
import { format, subDays, formatDistanceToNow } from 'date-fns';
import { sv } from 'date-fns/locale';

const AdminUserDashboard = () => {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);

  const adminUsers = [
    'Z1dF5PGfjvdBGS9egzACvcSRZiN2',
    'x2rjH7JxWxc6GnFgPSTxGxN1bm63',
    'Z6BLSMsm38Un5mgW0O6Z3TToHq12',
  ];

  const isValidDate = date => {
    return date && Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const fourDaysAgo = subDays(new Date(), 4);

        const usersQuery = query(
          collection(db, 'users'),
          where('lastLogin', '>=', Timestamp.fromDate(fourDaysAgo)),
          orderBy('lastLogin', 'desc')
        );

        const usersSnapshot = await getDocs(usersQuery);
        const usersData = usersSnapshot.docs
          .map(doc => {
            const data = doc.data();
            // Convert createdAt to Date if it's a timestamp
            let createdAt = data.createdAt;
            if (createdAt && typeof createdAt === 'object' && 'seconds' in createdAt) {
              createdAt = createdAt.toDate();
            } else if (createdAt && typeof createdAt.toDate === 'function') {
              createdAt = createdAt.toDate();
            } else if (typeof createdAt === 'string') {
              createdAt = new Date(createdAt);
            }

            return {
              id: doc.id,
              ...data,
              lastLogin: data.lastLogin?.toDate(),
              createdAt: createdAt,
            };
          })
          .filter(user => !adminUsers.includes(user.id));

        console.log(
          'Processed users data:',
          usersData.map(user => ({
            email: user.email,
            createdAt: user.createdAt,
            createdAtType: user.createdAt ? typeof user.createdAt : 'undefined',
          }))
        );

        setUsers(usersData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching users:', error);
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const formatDate = date => {
    if (!isValidDate(date)) return '-';
    try {
      return format(date, 'yyyy-MM-dd HH:mm', { locale: sv });
    } catch (error) {
      console.error('Error formatting date:', date, error);
      return '-';
    }
  };

  const formatAccountAge = date => {
    if (!isValidDate(date)) return '-';
    try {
      return formatDistanceToNow(date, { locale: sv, addSuffix: true });
    } catch (error) {
      console.error('Error formatting account age:', date, error);
      return '-';
    }
  };

  if (loading) {
    return (
      <Box display='flex' justifyContent='center' alignItems='center' minHeight='100vh'>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3, maxWidth: '1400px', margin: '0 auto' }}>
      <Typography variant='h4' component='h1' gutterBottom sx={{ mb: 4 }}>
        Användaraktivitet - Senaste 4 dagarna
      </Typography>

      <Card>
        <Box sx={{ p: 2, display: 'flex', gap: 2 }}>
          <Typography variant='h6' component='div'>
            Totalt antal användare: {users.length}
          </Typography>
        </Box>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>Registrerad</TableCell>
                <TableCell>Senaste inloggning</TableCell>
                <TableCell>Antal inloggningar</TableCell>
                <TableCell>Skola</TableCell>
                <TableCell>Kommun</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map(user => (
                <TableRow key={user.id} hover>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>
                    {isValidDate(user.createdAt) ? (
                      <Tooltip title={formatDate(user.createdAt)}>
                        <span>{formatAccountAge(user.createdAt)}</span>
                      </Tooltip>
                    ) : (
                      '-'
                    )}
                  </TableCell>
                  <TableCell>{user.lastLogin ? formatDate(user.lastLogin) : '-'}</TableCell>
                  <TableCell>{user.loginCount || 0}</TableCell>
                  <TableCell>{user.school || '-'}</TableCell>
                  <TableCell>{user.municipality || '-'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </Box>
  );
};

export default AdminUserDashboard;
