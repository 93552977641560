import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  Breadcrumbs,
  Grid,
  Card,
  CardContent,
  Chip,
  useTheme,
} from '@mui/material';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import PublicIcon from '@mui/icons-material/Public';
import BiotechIcon from '@mui/icons-material/Biotech';
import EventNoteIcon from '@mui/icons-material/EventNote';
import ScienceIcon from '@mui/icons-material/Science';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import CalculateIcon from '@mui/icons-material/Calculate';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import GradingIcon from '@mui/icons-material/Grading';
import TableChartIcon from '@mui/icons-material/TableChart';
import ShortcutBox from './ShortcutBox'; // Adjust the path as necessary
import Person3Icon from '@mui/icons-material/Person3';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import CreateIcon from '@mui/icons-material/Create';
import TranslateIcon from '@mui/icons-material/Translate';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import { useNavigate, Link } from 'react-router-dom';
import { Palette as PaletteIcon } from '@mui/icons-material';
import { Abc as AbcIcon } from '@mui/icons-material';
import GridOnIcon from '@mui/icons-material/GridOn';

import SubjectIcon from '@mui/icons-material/Subject';
import { subjects } from './ThemeSelector';

import ForestIcon from '@mui/icons-material/Forest';
import CoronavirusIcon from '@mui/icons-material/Coronavirus';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import NatureIcon from '@mui/icons-material/Nature';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import HistoryIcon from '@mui/icons-material/History';
import FilterVintageIcon from '@mui/icons-material/FilterVintage';

import WindowIcon from '@mui/icons-material/Window';
import ThemeFlowBox from './ThemeFlowBox';
import { getStyles } from '../index';
import { shortcuts } from './DashboardToolsShortcuts';
const TeacherShortcuts = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const theme = useTheme();
  const styles = getStyles(theme);
  const navigate = useNavigate();

  const getShortcutsBySubject = subject => {
    return shortcuts.filter(
      shortcut =>
        shortcut.belongsTo &&
        shortcut.belongsTo
          .split(',')
          .map(s => s.trim())
          .includes(subject)
    );
  };

  const shortcuts2 = {
    Biologi: {
      clickable: true,
      icon: BiotechIcon,
      content: {
        Biologi: [...getShortcutsBySubject('Biologi')],
      },
    },

    Svenska: {
      clickable: true,
      icon: MenuBookIcon,
      content: {
        Svenska: [...getShortcutsBySubject('Svenska')],
      },
    },
    Samhällskunskap: {
      clickable: false,
      icon: PublicIcon,
      content: {
        Samhällskunskap: [
          {
            icon: <GradingIcon />,
            title: 'Grej of the day',
            subtitle: 'Kort förklarande text om vad denna modul gör',
            path: '/app/uppsats/ny',
            label: 'POPULÄR',
          },
        ],
      },
    },

    Kemi: {
      clickable: false,
      icon: ScienceIcon,
      content: {
        Text: [
          {
            icon: <AssignmentTurnedInIcon />,
            title: 'Skapa individuell studieplan',
            subtitle: 'Generera en anpassad studieplan för en elev',
            path: '/app/fraga-ai?prompt=individuell-studieplan',
          },
        ],
      },
    },
    'Idrott och hälsa': {
      clickable: false,
      icon: FitnessCenterIcon,
      content: {
        Text: [
          {
            icon: <AssignmentTurnedInIcon />,
            title: 'Skapa individuell studieplan',
            subtitle: 'Generera en anpassad studieplan för en elev',
            path: '/app/fraga-ai?prompt=individuell-studieplan',
          },
        ],
      },
    },
    Fysik: {
      clickable: false,
      icon: CalculateIcon,
      content: {
        Text: [
          {
            icon: <AssignmentTurnedInIcon />,
            title: 'Skapa individuell studieplan',
            subtitle: 'Generera en anpassad studieplan för en elev',
            path: '/app/fraga-ai?prompt=individuell-studieplan',
          },
        ],
      },
    },
  };

  const MainCategoryView = () => (
    <Grid container spacing={3}>
      {Object.entries(shortcuts2).map(([category, categoryData]) => (
        <Grid item xs={12} md={4} key={category}>
          <ThemeFlowBox
            icon={categoryData.icon}
            title={category}
            subtitle={`Lektioner som passar ${category}`}
            onClick={() => categoryData.clickable && setSelectedCategory(category)}
            diffView={true}
            clickable={categoryData.clickable}
          />
        </Grid>
      ))}
    </Grid>
  );

  const SubCategoryView = () => (
    <Box>
      {Object.entries(shortcuts2[selectedCategory].content).map(([subcategory, items]) => (
        <Box key={subcategory} sx={{ mb: 4 }}>
          <Typography variant='h6' sx={{ mb: 2, mt: 7, fontWeight: '600', fontSize: '23px' }}>
            {subcategory}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Grid container spacing={2}>
              {items.map((item, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <ShortcutBox {...item} diffView={true} />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      ))}
    </Box>
  );

  return (
    <Box>
      {selectedCategory && (
        <Box
          onClick={() => setSelectedCategory(null)}
          sx={{
            ...styles.backButton,
          }}
        >
          <ArrowBackIcon />
          <Typography sx={{ fontSize: '0.875rem', fontWeight: '500' }}>Tillbaka</Typography>
        </Box>
      )}

      {selectedCategory ? <SubCategoryView /> : <MainCategoryView />}
    </Box>
  );
};

export default TeacherShortcuts;
