import React, { useState } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

const CustomThemeInput = ({ onSubmit, theme }) => {
  const [value, setValue] = useState('');
  const isDark = theme.palette.mode === 'dark';

  const handleSubmit = () => {
    if (value.trim()) {
      // Create a new theme object in the same format as additionalThemes
      const newTheme = {
        value: `custom-${Date.now()}`,
        label: value,
        Icon: EditIcon,
        content: value,
        type: 'custom',
      };

      onSubmit(value);
      setValue('');
    }
  };

  return (
    <Box
      sx={{
        mt: 4,
        pt: 4,
        borderTop: isDark ? '1px solid rgba(255, 255, 255, 0.1)' : '1px solid rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
      }}
    >
      <Typography
        variant='h6'
        sx={{
          fontWeight: '500',
          mb: 3,
          fontSize: '1rem',
          color: isDark ? 'rgba(255, 255, 255, 0.7)' : '#2c2f33',
        }}
      >
        Hittar du inte det du söker?
      </Typography>
      <Box sx={{ display: 'flex', gap: 2, maxWidth: '600px', margin: '0 auto' }}>
        <TextField
          fullWidth
          value={value}
          onChange={e => setValue(e.target.value)}
          placeholder='Skriv in eget tema...'
          variant='outlined'
          size='medium'
          sx={{
            '& .MuiOutlinedInput-root': {
              backgroundColor: isDark ? 'rgba(255, 255, 255, 0.05)' : 'rgba(223, 222, 225, 0.05)',
            },
          }}
        />
        <Button
          variant='purp'
          onClick={handleSubmit}
          sx={{
            backgroundColor: '#8b5cf6',
            '&:hover': {
              backgroundColor: '#6b46c1',
            },
            whiteSpace: 'nowrap',
          }}
        >
          Använd
        </Button>
      </Box>
    </Box>
  );
};

export default CustomThemeInput;
