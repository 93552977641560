import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { getStyles } from '../index';
import CourseList from './CourseList';
import GenerateIcon from '@mui/icons-material/AutoAwesome';
import { Link } from 'react-router-dom';

const CoursesPublic = () => {
  const theme = useTheme();
  const styles = getStyles(theme);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.innerContainer}>
        <Typography variant='h1' sx={{ ...styles.header, borderBottom: '0px' }}>
          Utvalda kurser
        </Typography>
        <Typography variant='subtitle1' gutterBottom sx={{ ...styles.softerText, mb: 4 }}>
          Ta en titt på ett urval av kurser framtagna av andra användare.
        </Typography>
        <Box sx={{ ...styles.buttonsContainer, ...styles.additionalButtonsContainer }}>
          <Button
            variant='contained'
            color='primary'
            startIcon={<GenerateIcon />}
            component={Link}
            to={`/app/kurser`}
          >
            Skapa kurs
          </Button>
        </Box>

        <CourseList
          courseIds={[
            'd9RQjnU8wVGcaiDrO2dd',
            'hGrCbLXixh2kXy131Yf8',
            'DAmlDfx15ciBiOVAfXOj',
            'zRl9weyypH0guq6roPvt',
            'fgpOLak0BBqJP85zVj3z',
            'CdLx3L3F8LqrXI2vCX6G',
            'KYyUj9Q8Ye5nmpT9aUKF',
            'r7NnC08Grk6ylkKILsFa',
          ]}
        />
      </Box>
    </Box>
  );
};

export default CoursesPublic;
