import React, { useState, useEffect } from 'react';
import {
  Drawer,
  Box,
  Typography,
  TextField,
  Button,
  Switch,
  FormControlLabel,
  Avatar,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Tooltip,
  CircularProgress, // 1. Importera CircularProgress
} from '@mui/material';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { useTheme } from '@mui/material/styles';
import { db, storage } from './firebase';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { collection, addDoc, doc, getDoc, updateDoc, setDoc, getDocs } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useSnackbar } from './SnackbarHandler';

const TeacherEditDrawer = ({
  open,
  onClose,
  teacherId,
  user,
  onTeacherUpdate,
  isDark,
  isCreating,
}) => {
  const theme = useTheme();
  const { showSnackbar } = useSnackbar();
  const [teacherData, setTeacherData] = useState({
    characterName: '',
    tagline: '',
    description: '',
    greeting: '',
    voice: '',
    teachingStyle: '',
    personality: '',
    pedagogicalPhilosophy: '',
    communicationStyle: '',
    background: '',
    approachToMistakes: '',
    dyslexiaAdapted: false,
    adhdAdapted: false,
    imageUrl: '',
    category: '',
    visibility: 'public',
  });
  const [image, setImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [categories, setCategories] = useState([]);

  // 2. Lägg till isLoading state
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchTeacherData = async () => {
      if (!isCreating && teacherId) {
        const docRef = doc(db, 'teachers', teacherId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setTeacherData(docSnap.data());
          setPreviewUrl(docSnap.data().imageUrl);
        }
      } else {
        // Reset state when adding a new teacher
        setTeacherData({
          characterName: '',
          tagline: '',
          description: '',
          greeting: '',
          voice: '',
          teachingStyle: '',
          personality: '',
          pedagogicalPhilosophy: '',
          communicationStyle: '',
          background: '',
          approachToMistakes: '',
          dyslexiaAdapted: false,
          adhdAdapted: false,
          imageUrl: '',
          category: '',
          visibility: 'public',
        });
        setPreviewUrl(null);
        setImage(null);
      }
    };

    const fetchCategories = async () => {
      const categoriesSnapshot = await getDocs(collection(db, 'categories'));
      const categoriesList = categoriesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setCategories(categoriesList);
    };

    fetchTeacherData();
    fetchCategories();
  }, [teacherId, open, isCreating]);

  const handleChange = e => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    setTeacherData(prev => ({ ...prev, [name]: newValue }));
  };

  const handleImageChange = e => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
      setPreviewUrl(URL.createObjectURL(e.target.files[0]));
    }
  };

  // 3. Uppdatera handleSubmit för att hantera isLoading
  const handleSubmit = async () => {
    setIsLoading(true); // Sätt loading till true när submission startar
    try {
      console.log('Submitting with teacherId:', teacherId); // Debugging line

      if (!teacherData.characterName.trim() || !teacherData.greeting.trim()) {
        showSnackbar('Namn och hälsningsfras är obligatoriska fält.', 'error');
        return;
      }

      let imageUrl = teacherData.imageUrl;
      if (image) {
        const imageRef = ref(storage, `teacherImages/${user.uid}/${image.name}`);
        await uploadBytes(imageRef, image);
        imageUrl = await getDownloadURL(imageRef);
      }

      const teacherDataToSave = {
        ...teacherData,
        imageUrl,
        userId: user.uid,
        updatedAt: new Date(),
      };

      let newTeacherId;
      if (!isCreating) {
        await updateDoc(doc(db, 'teachers', teacherId), teacherDataToSave);
        newTeacherId = teacherId;
        showSnackbar(`${teacherData.characterName} har uppdaterats.`, 'success');
      } else {
        const docRef = await addDoc(collection(db, 'teachers'), {
          ...teacherDataToSave,
          createdAt: new Date(),
        });
        newTeacherId = docRef.id;
        showSnackbar(`${teacherData.characterName} har skapats.`, 'success');
      }
      if (isCreating) {
        if (teacherData.visibility === 'public') {
          await setDoc(doc(db, 'publicTeachers', newTeacherId), {
            ...teacherDataToSave,
            originalCreatorId: user.uid,
          });
        }
      }

      onTeacherUpdate(teacherDataToSave, newTeacherId);
      onClose();
    } catch (error) {
      console.error('Error saving teacher:', error);
      showSnackbar('Ett fel uppstod. Försök igen.', 'error');
    } finally {
      setIsLoading(false); // Sätt loading till false när submission är klar
    }
  };

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: { xs: '75%', sm: '50%', md: '25%' },
          boxShadow: 'none',
          borderLeft: isDark ? '1px solid #34414c' : '1px solid #e0e0e0',
          backgroundImage: 'none',
        },
      }}
    >
      <Box sx={{ p: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <IconButton onClick={onClose} color='inherit'>
            <ChevronRight />
          </IconButton>
          <Typography variant='h6' sx={{ fontSize: '17px', ml: 2 }}>
            {isCreating ? 'Skapa ny karaktär' : 'Redigera karaktär'}
          </Typography>
        </Box>

        <Box sx={{ mb: 2 }}>
          <input
            accept='image/*'
            type='file'
            onChange={handleImageChange}
            style={{ display: 'none' }}
            id='teacher-image-upload'
          />
          <label htmlFor='teacher-image-upload'>
            <IconButton component='span'>
              <Avatar
                src={previewUrl}
                sx={{
                  width: 50,
                  height: 50,
                  cursor: 'pointer',
                }}
              >
                {!previewUrl && <AddAPhotoIcon />}
              </Avatar>
            </IconButton>
          </label>
        </Box>

        <TextField
          fullWidth
          margin='normal'
          name='characterName'
          label='Karaktärens namn'
          value={teacherData.characterName}
          onChange={handleChange}
          required
          sx={{ padding: '0px' }}
          InputProps={{
            sx: {
              fontSize: '13px',
            },
          }}
        />

        <TextField
          fullWidth
          margin='normal'
          name='greeting'
          label='Hälsningsfras'
          value={teacherData.greeting}
          onChange={handleChange}
          required
          sx={{ padding: '0px' }}
          InputProps={{
            sx: {
              fontSize: '13px',
            },
          }}
        />

        <TextField
          fullWidth
          margin='normal'
          name='tagline'
          label='Tagline'
          value={teacherData.tagline}
          onChange={handleChange}
          sx={{ padding: '0px' }}
          InputProps={{
            sx: {
              fontSize: '13px',
            },
          }}
        />

        <TextField
          fullWidth
          margin='normal'
          name='description'
          label='Beskrivning'
          multiline
          rows={3}
          value={teacherData.description}
          onChange={handleChange}
          sx={{ padding: '0px' }}
          InputProps={{
            sx: {
              fontSize: '13px',
            },
          }}
        />

        <TextField
          fullWidth
          margin='normal'
          name='background'
          label='Bakgrund/Definition'
          multiline
          rows={5}
          value={teacherData.background}
          onChange={handleChange}
          sx={{ padding: '0px' }}
          InputProps={{
            sx: {
              fontSize: '13px',
            },
          }}
        />

        {/* 4. Uppdatera knappen med CircularProgress och disable */}
        <Button
          variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
          color='primary'
          onClick={handleSubmit}
          disabled={isLoading} // Inaktivera knappen när laddar
          sx={{ mt: 2, display: 'flex', alignItems: 'center' }}
        >
          {isCreating ? 'Skapa karaktär' : 'Uppdatera karaktär'}
          {isLoading && (
            <CircularProgress
              size={16}
              sx={{ color: isDark ? 'white' : '#333', ml: 2 }} // Justera färg och margin efter behov
            />
          )}
        </Button>
      </Box>
    </Drawer>
  );
};

export default TeacherEditDrawer;
