import React, { useEffect } from 'react';
import {
  Container,
  Typography,
  Grid,
  Box,
  useMediaQuery,
  IconButton,
  Chip,
  useTheme,
} from '@mui/material';
import { StyledButton, GradientBackground } from './StyledComponents';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import Header from './Header';
import Footer from './Footer';
import TestimonialsSection from './TestimonialsSection';
import { motion } from 'framer-motion';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import UniversitySection from './UniversitySection';
import MediaSection from './MediaSection';
import PricesSection from './PricesSection';

const PricesPage = ({ toggleDarkMode, isDark }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Priser | Studera.ai</title>
        <meta
          name='description'
          content='Lär dig mer kostnaderna för lärare, pedagoger och elever för att använda Studera.ai.'
        />
      </Helmet>

      <Header />

      <GradientBackground className='gradient-background'>
        <Container
          sx={{ pt: { xs: 10, md: 20 }, pb: 15, position: 'relative', textAlign: 'center' }}
        >
          <Grid container spacing={6} alignItems='center'>
            <Grid item xs={12} md={12} sx={{ textAlign: 'center' }}>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                  <Chip
                    label={
                      <span>
                        Studera.ai{' '}
                        <span style={{ opacity: 0.7 }}>
                          &nbsp;•&nbsp; Revolutionerar lärande med AI
                        </span>
                      </span>
                    }
                    sx={{
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                      color: 'white',
                      fontWeight: '600',
                      fontSize: '0.8rem',
                      padding: '2px 14px',
                      borderRadius: '16px',
                      '& .MuiChip-label': {
                        padding: '0 4px',
                      },
                    }}
                  />
                </Box>

                <Typography
                  variant='h2'
                  component='h1'
                  gutterBottom
                  sx={{
                    fontWeight: 900,
                    letterSpacing: -1,
                    fontSize: { xs: '2.5rem', md: '3.35rem' },
                  }}
                >
                  Priser för Studera.ai
                </Typography>
                <Typography
                  variant='h5'
                  sx={{
                    mb: 4,
                    opacity: 0.8,
                    fontSize: '1.3rem',
                    width: { xs: '100%', md: '64%' },
                    margin: '0 auto',
                    fontWeight: '500',
                    lineHeight: '31px',
                    display: 'block',
                    mt: 4,
                  }}
                >
                  Med våra olika prisplaner kan varje lärare upptäcka kraften med AI i klassrummet.
                  Ta en titt på våra priser för lärare och skolor och bestäm vilket alternativ som
                  passar dig bäst.
                </Typography>
              </motion.div>
            </Grid>
          </Grid>
        </Container>
      </GradientBackground>
      <Container sx={{ my: 15 }}>
        <Typography
          variant='h3'
          align='center'
          sx={{
            fontWeight: 'bold',
            fontSize: '2.3em',
            mb: 3,
          }}
        >
          Hitta rätt prisplan för dig
        </Typography>

        <Typography
          variant='h6'
          paragraph
          color='text.secondary'
          sx={{ textAlign: 'center', display: 'block', mb: 6 }}
        >
          Välj bland våra olika nivåer för att hitta den som passar dina behov och din budget
        </Typography>

        <PricesSection />
      </Container>
      <TestimonialsSection source='teacher' />
      <UniversitySection bgcolor='background.paper' showHeadline={true} />
      <MediaSection bgcolor='background.default' />
      <Footer />

      {!isMobile && (
        <Box sx={{ position: 'fixed', bottom: 16, right: 16, zIndex: 9999 }}>
          <IconButton onClick={toggleDarkMode}>
            {isDark ? <LightModeIcon /> : <DarkModeIcon />}
          </IconButton>
        </Box>
      )}
    </>
  );
};

export default PricesPage;
