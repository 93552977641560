import React from 'react';
import {
  Box,
  Typography,
  LinearProgress,
  Button,
  IconButton,
  Avatar,
  Divider,
  Tooltip,
} from '@mui/material';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import Looks4Icon from '@mui/icons-material/Looks4';
import SpaceBarIcon from '@mui/icons-material/SpaceBar';
import BookmarkIcon from '@mui/icons-material/BookmarkBorder';
import CheckCircleIcon from '@mui/icons-material/Check';
import GenerateIcon from '@mui/icons-material/AutoAwesome';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import LooksThreeIcon from '@mui/icons-material/Looks3';
import LooksFourIcon from '@mui/icons-material/Looks4';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUp';
import { formatDate } from './functions';
import Hint from './Hint';
import RecommendationComponent from './RecommendationComponent';
import AIDialog from './AIDialog';

import { lighten, darken } from '@mui/material/styles';

const OptionButton = ({ children, ...props }) => (
  <Button
    {...props}
    sx={{
      margin: theme => theme.spacing(1),
      width: { xs: '100%', md: '45%' },
      textTransform: 'none',
      backgroundColor: theme => theme.palette.background.paper,
      color: theme => theme.palette.text.reverse,
      fontSize: '1rem',
      borderRadius: '10px',
      borderColor: theme => theme.palette.divider,
      borderBottom: theme => `4px solid ${theme.palette.divider}`,
      display: 'flex',
      justifyContent: 'left',
      textAlign: 'left',
      padding: theme => theme.spacing(1.5),

      '&:hover': {
        borderColor: theme => theme.palette.divider,
        borderBottom: theme => `4px solid ${theme.palette.divider}`,
        backgroundColor: theme => theme.palette.background.paperHover,
      },

      '& .MuiSvgIcon-root': {
        color: theme => theme.palette.text.main,
      },

      '&.MuiButton-contained': {
        outline: 'none',
        backgroundColor: theme => theme.palette.text.marked,
        fontWeight: 'bold',
        color: theme => theme.palette.text.white,
        borderColor: theme => darken(theme.palette.text.marked, 0.2),
        '& .MuiSvgIcon-root': {
          color: theme => theme.palette.text.white,
        },
      },
    }}
  >
    {children}
  </Button>
);

const QuizInProgress = ({
  quiz,
  currentQuestionIndex,
  quizName,
  currentQuestion,
  currentQuestionNumber,
  selectedOption,
  handleOptionSelect,
  handleNextQuestion,
  handleAskAI,
  bookmarks,
  toggleBookmark,
  creatorInfo,
  quizCreatedAt,
  originalQuizId,
  user,
  theme,
  styles,
  aiDialogOpen,
  setAiDialogOpen,
}) => {
  return (
    <>
      <Box
        sx={{
          ...styles.darkBorder,
          width: '100%',
          position: 'relative',
          padding: { xs: '20px', md: '40px' },
          marginTop: '50px',
          backgroundColor: theme.palette.mode === 'dark' ? '#212c36' : 'white',
          border: theme.palette.mode === 'dark' ? '1px solid #263442' : '1px solid #e0e0e091',
          borderRadius: { xs: '0px', md: '8px' },
        }}
      >
        <Box sx={{ position: 'relative', mb: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
          <LinearProgress
            variant='determinate'
            value={(currentQuestionIndex / quiz.length) * 100}
            sx={{
              height: 10,
              borderRadius: 5,
              flex: 1,
              '& .MuiLinearProgress-bar': {
                borderRadius: 5,
                backgroundColor: '#8b5cf6',
                transition: 'transform 0.5s ease-in-out',
              },
            }}
          />
          <Box
            sx={{
              backgroundColor: theme => theme.palette.background.paper,
              borderRadius: '16px',
              padding: '4px 12px',
              border: theme => `1px solid ${theme.palette.divider}`,
              fontSize: '0.85rem',
              flexShrink: 0,
              color: theme => theme.palette.text.secondary,
            }}
          >
            {currentQuestionNumber}/{quiz.length}
          </Box>
        </Box>

        <Typography style={styles.h2}>{quizName}</Typography>
        <Typography style={styles.h3}>{currentQuestion.question}</Typography>

        <Box
          sx={{
            display: 'flex',
            mt: 6,
            mb: 8,
            flexDirection: { xs: 'column', sm: 'row' },
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          {currentQuestion.options.map((option, index) => (
            <OptionButton
              key={index}
              variant={selectedOption === option ? 'contained' : 'outlined'}
              onClick={() => handleOptionSelect(option)}
            >
              {index === 0 && <LooksOneIcon sx={{ marginRight: '8px', color: '#8b5cf6' }} />}
              {index === 1 && <LooksTwoIcon sx={{ marginRight: '8px', color: '#8b5cf6' }} />}
              {index === 2 && <Looks3Icon sx={{ marginRight: '8px', color: '#8b5cf6' }} />}
              {index === 3 && <Looks4Icon sx={{ marginRight: '8px', color: '#8b5cf6' }} />}
              {option}
            </OptionButton>
          ))}
        </Box>

        <Box sx={{ position: 'absolute', bottom: 15, left: 15, zIndex: 1 }}>
          <Hint
            question={currentQuestion.question}
            answer={currentQuestion.options.join(', ')}
            user={user}
            theme={theme}
          />
        </Box>

        <Box sx={{ marginTop: '45px', display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              variant='purp'
              onClick={handleNextQuestion}
              disabled={!selectedOption}
              endIcon={<SpaceBarIcon />}
              sx={{ width: '270px' }}
            >
              Nästa
            </Button>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              variant='print'
              onClick={handleAskAI}
              startIcon={<GenerateIcon />}
              sx={{ width: '270px' }}
            >
              Fråga AI
            </Button>
          </Box>
        </Box>

        <Box sx={{ position: 'absolute', bottom: 10, right: 10, zIndex: 1 }}>
          <Tooltip title={bookmarks[currentQuestionIndex] ? 'Sparat' : 'Spara'}>
            <IconButton
              onClick={toggleBookmark}
              sx={{
                color: bookmarks[currentQuestionIndex] ? 'green' : theme.palette.text.primary,
              }}
            >
              {bookmarks[currentQuestionIndex] ? <CheckCircleIcon /> : <BookmarkIcon />}
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: { xs: 'stretch', md: 'flex-start' },
          justifyContent: 'space-between',
          gap: 2,
          mt: 3,
        }}
      >
        {creatorInfo && (
          <Box
            sx={{
              ...styles.smallBox,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: theme.palette.mode === 'dark' ? '#2a3b4c57' : '#fff',
              borderRadius: '10px',
              padding: '10px',
              marginTop: { xs: '30px', md: 0 },
              width: { xs: '100%', md: 'fit-content' },
            }}
          >
            <Avatar src={creatorInfo.avatarUrl} sx={{ width: 40, height: 40, mr: 2 }}>
              {creatorInfo.displayName[0].toUpperCase()}
            </Avatar>
            <Box>
              <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                {creatorInfo.displayName}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 0 }}>
                <ThumbUpOutlinedIcon
                  sx={{ fontSize: 16, mr: 0.5, color: theme.palette.text.secondary }}
                />
                <Typography
                  variant='body2'
                  sx={{ color: theme.palette.text.secondary, fontWeight: 'bold' }}
                >
                  {creatorInfo.upvotes}
                </Typography>
                <Typography
                  variant='body2'
                  sx={{ color: theme.palette.text.secondary, fontSize: '0.8rem', ml: 1 }}
                >
                  · &nbsp; {formatDate(quizCreatedAt)}
                </Typography>
              </Box>
            </Box>
          </Box>
        )}

        <Box
          sx={{
            ...styles.smallBox,
            backgroundColor: theme.palette.mode === 'dark' ? '#2a3b4c57' : '#fff',
            borderRadius: '10px',
            width: { xs: '100%', md: '40%' },
            padding: '0px',
            margin: { xs: '0 auto', md: 0 },
            marginTop: { xs: '80px', md: 0 },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: theme.palette.text.secondary,
            }}
          >
            <Typography
              variant='h4'
              sx={{
                fontWeight: 'bold',
                fontSize: '13px',
                flexShrink: 0,
                padding: '20px',
                paddingRight: '0px',
              }}
            >
              GENVÄGAR
            </Typography>
            <Divider orientation='vertical' flexItem sx={{ mx: 2 }} />
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '2px',
                ml: 0,
              }}
            >
              <LooksOneIcon />
              <LooksTwoIcon />
              <LooksThreeIcon />
              <LooksFourIcon />
              <SpaceBarIcon />
              <KeyboardReturnIcon />
              <Typography variant='body2' sx={{ ml: 1 }}>
                (F = Fråga AI)
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ width: { xs: '100%', md: 'auto' } }}>
          <RecommendationComponent
            itemId={originalQuizId}
            userId={user.uid}
            collectionName='quiz'
            questionText='Skulle du rekommendera detta quiz till andra användare?'
            largerStyle={true}
          />
        </Box>
      </Box>

      <AIDialog
        userId={user.uid}
        open={aiDialogOpen}
        onClose={() => setAiDialogOpen(false)}
        initialQuestion={
          currentQuestion
            ? `Question: ${currentQuestion.question}\nOptions: ${currentQuestion.options.join(', ')}\nPlease explain this question and help me understand the answer options, the name of the quiz is ${quizName}. Skriv på svenska.`
            : 'No question available'
        }
        systemMessage='You are a helpful AI assistant designed to explain and assist with the quiz question. Provide a short and simple explanation. Skriv på svenska'
        theme={theme}
      />
    </>
  );
};

export default QuizInProgress;
