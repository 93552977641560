import React, { useState, useRef } from 'react';
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Grid,
  FormControl,
  Select,
  MenuItem,
  Divider,
  Tooltip,
  IconButton,
  TextField,
  Avatar,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import InfoBox from './InfoBox';
import ImageIcon from '@mui/icons-material/Image';
import GenerateIcon from '@mui/icons-material/AutoAwesome';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { handlePrintTwisters } from './printFunctions';
import { callApi } from './api';
import {
  generateAndProcessImage,
  trackLesson,
  CustomFormControlLabel,
  extractJsonFromString,
} from './functions';
import { useSnackbar } from './SnackbarHandler';
import ImageGenerationModal from './ImageGenerationModal';
import PreviousLessonsLoader from './PreviousLessonsLoader';
import PrintIcon from '@mui/icons-material/Print';
import ProgressBar from './ProgressBar';
import Voice from './Voice';
import LanguageSelector from './LanguageSelector';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { FourSquare } from 'react-loading-indicators';
import Logger from './Logger';
import { Checkbox, FormControlLabel } from '@mui/material';
import StormIcon from '@mui/icons-material/Storm';

import HoverableImage from './HoverableImage';
import LessonDrawer from './LessonDrawer';
import SaveLessonDialog from './SaveLessonDialog';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import HistoryIcon from '@mui/icons-material/History';

const TongueTwistersLesson = ({ theme, styles, user }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);

  const [twisterCount, setTwisterCount] = useState(4);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isGeneratingImages, setIsGeneratingImages] = useState(false);
  const [lessonData, setLessonData] = useState(null);
  const [progress, setProgress] = useState(0);
  const { showSnackbar } = useSnackbar();
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageInstructions, setImageInstructions] = useState('');
  const [selectedTwisterIndex, setSelectedTwisterIndex] = useState(null);
  const [loadingImageIndex, setLoadingImageIndex] = useState(null);
  const [language, setLanguage] = useState('svenska');

  const [includeIPA, setIncludeIPA] = useState(true);
  const [includeSyllables, setIncludeSyllables] = useState(false);

  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isEditingSubtitle, setIsEditingSubtitle] = useState(false);
  const [titleText, setTitleText] = useState('Tungvrickare');
  const [editingExampleId, setEditingExampleId] = useState(null);
  const [editingTwisterId, setEditingTwisterId] = useState(null);
  const [editingMeaningId, setEditingMeaningId] = useState(null);

  const [editingIPAId, setEditingIPAId] = useState(null);
  const [editingSyllableId, setEditingSyllableId] = useState(null);

  const handleSaveLessonClick = () => {
    setSaveDialogOpen(true);
  };

  const handleSaveWithComment = async (comment, name, coverImage, tags) => {
    Logger.log(JSON.stringify(lessonData, null, 2));
    await trackLesson(
      user.uid,
      'TongueTwistersLesson.js',
      lessonData,
      null,
      true,
      comment,
      name,
      coverImage,
      tags
    );
    setDrawerOpen(true);
    showSnackbar('Lektionen sparad i din lektionsbank!', 'success');
  };

  const handlePrintClick = async () => {
    if (lessonData && lessonData.twisters.every(twister => twister.imageUrl)) {
      Logger.log('Saving lesson since all images have been generated');
      await trackLesson(
        user.uid,
        'TongueTwistersLesson.js',
        lessonData,
        null,
        true,
        null,
        titleText
      );
    }

    handlePrintTwisters(lessonData, theme, titleText, subtitleText);
  };

  const handleLoadPreviousLesson = (lessonData, formData) => {
    setLessonData(lessonData);
    Logger.log('lessonData: ', lessonData);
  };
  const getCurrentWeek = () => {
    const now = new Date();
    const onejan = new Date(now.getFullYear(), 0, 1);
    const week = Math.ceil(
      ((now.getTime() - onejan.getTime()) / 86400000 + onejan.getDay() + 1) / 7
    );
    return week;
  };

  const [subtitleText, setSubtitleText] = useState(`Vecka ${getCurrentWeek()}`);

  const [isEditingStory, setIsEditingStory] = useState(false);

  const handleEditStory = newText => {
    setLessonData(prevData => ({
      ...prevData,
      story: newText,
    }));
    setIsEditingStory(false);
    showSnackbar('Berättelsen har uppdaterats', 'success');
  };

  const handleEditTwister = (index, newText, field) => {
    setLessonData(prevData => ({
      ...prevData,
      twisters: prevData.twisters.map((twister, i) =>
        i === index ? { ...twister, [field]: newText } : twister
      ),
    }));

    // Reset the appropriate editing state
    if (field === 'twister') setEditingTwisterId(null);
    else if (field === 'meaning') setEditingMeaningId(null);
    else if (field === 'ipa_pronunciation') setEditingIPAId(null);
    else if (field === 'syllable_pronunciation') setEditingSyllableId(null);

    showSnackbar(
      `${field === 'twister' ? 'Tungvrickaren' : field === 'meaning' ? 'Betydelsen' : 'Uttalet'} har uppdaterats`,
      'success'
    );
  };

  const simulateProgress = (startProgress, endProgress, duration, setProgressFn) => {
    const stepSize = 0.5;
    const steps = (endProgress - startProgress) / stepSize;
    const stepDuration = duration / steps;
    let currentProgress = startProgress;

    const intervalId = setInterval(() => {
      currentProgress += stepSize;
      if (currentProgress >= endProgress) {
        clearInterval(intervalId);
      } else {
        setProgressFn(currentProgress);
      }
    }, stepDuration);

    return () => clearInterval(intervalId);
  };

  const handleGenerateTwisters = async () => {
    setIsGenerating(true);
    setProgress(0);
    let stopProgressSimulation = null;

    try {
      stopProgressSimulation = simulateProgress(0, 90, 15000, setProgress);

      const response = await callApi(
        [
          {
            role: 'system',
            content: `Du är en språklärare som specialiserar sig på tungvrickare (så kallade tongue twisters) och uttal. VIKTIGT: Svara ENDAST med ett JSON-objekt, utan förklarande text före eller efter.`,
          },
          {
            role: 'user',
            content: `Skapa en lektion med ${twisterCount} tungvrickare på ${language}. 
Returnera ENDAST ett JSON-objekt med följande struktur, utan någon text före eller efter:
{
  "title": "Tungvrickare",
  "instructions": "En kort instruktion om hur man ska träna på tungvrickarna",
  "twisters": [
    {
      "twister": "tungvrickaren",
      "meaning": "översättning och/eller förklaring av betydelsen på ${language}",
      ${includeIPA ? '"ipa_pronunciation": "IPA-notation"' : ''}${includeIPA && includeSyllables ? ',' : ''}
      ${includeSyllables ? '"syllable_pronunciation": "ord-för-ord-med-bindestreck"' : ''}${includeIPA || includeSyllables ? ',' : ''}
      "imagePrompt": "beskrivning för bildgenerering som illustrerar tungvrickaren",
      "imageUrl": null,
      "difficulty": "lätt/medel/svår"
    }
  ],
  "story": "En kort berättelse (cirka 12 meningar) som använder tungvrickarna på ett underhållande sätt"
}`,
          },
        ],
        8000,
        true,
        user.uid
      );

      if (stopProgressSimulation) {
        stopProgressSimulation();
      }

      setProgress(95);

      const messageContent = response.data.choices[0].message.content.trim();
      const data = extractJsonFromString(messageContent);

      setProgress(100);
      await new Promise(resolve => setTimeout(resolve, 800));

      setLessonData(data);
      Logger.log(JSON.stringify(data, null, 2));
      await trackLesson(user.uid, 'TongueTwistersLesson.js', null, {
        twisterCount,
        language,
      });

      showSnackbar('Tungvrickare har genererats!', 'success');
    } catch (error) {
      console.error('Error generating tongue twisters:', error);
      showSnackbar('Ett fel uppstod när tungvrickarna skulle genereras', 'error');
    } finally {
      setIsGenerating(false);
    }
  };

  const handleImageButtonClick = () => {
    setShowImageModal(true);
  };

  const handleCloseModal = () => {
    setShowImageModal(false);
    setImageInstructions('');
    setSelectedTwisterIndex(null);
  };

  const handleImageUpdate = (index, newImageUrl) => {
    const updatedTwisters = [...lessonData.twisters];
    updatedTwisters[index] = { ...updatedTwisters[index], imageUrl: newImageUrl };
    setLessonData({
      ...lessonData,
      twisters: updatedTwisters,
    });
  };

  const handleGenerateSpecificImage = async index => {
    setIsGeneratingImages(true);
    setLoadingImageIndex(index);
    handleCloseModal();

    try {
      const twister = lessonData.twisters[index];
      const imageUrl = await generateAndProcessImage(
        `${twister.imagePrompt}. ${imageInstructions}`,
        user.uid
      );

      const updatedTwisters = [...lessonData.twisters];
      updatedTwisters[index] = { ...twister, imageUrl };

      setLessonData({
        ...lessonData,
        twisters: updatedTwisters,
      });

      showSnackbar('Bilden har genererats!', 'success');
    } catch (error) {
      console.error('Error generating image:', error);
      showSnackbar('Ett fel uppstod när bilden skulle genereras', 'error');
    } finally {
      setIsGeneratingImages(false);
      setLoadingImageIndex(null);
    }
  };

  const handleGenerateImages = async () => {
    if (!lessonData) return;

    setIsGeneratingImages(true);
    handleCloseModal();

    let successCount = 0;
    const totalImages = lessonData.twisters.length;

    try {
      const updatedTwisters = await Promise.all(
        lessonData.twisters.map(async twister => {
          try {
            const imageUrl = await generateAndProcessImage(
              `${twister.imagePrompt}. ${imageInstructions}`,
              user.uid
            );
            successCount++;
            return { ...twister, imageUrl };
          } catch (error) {
            console.error(`Error generating image for ${twister.twister}:`, error);
            return twister;
          }
        })
      );

      setLessonData({
        ...lessonData,
        twisters: updatedTwisters,
      });

      if (successCount === totalImages) {
        showSnackbar('Alla bilder genererades framgångsrikt!', 'success');
      } else {
        showSnackbar(`${successCount}/${totalImages} bilder genererades framgångsrikt.`, 'warning');
      }
    } finally {
      setIsGeneratingImages(false);
    }
  };

  return (
    <Box sx={styles.container}>
      <Box
        sx={{
          ...styles.innerContainer,
          width: { xs: '100%', md: '87%' },
          margin: '0 auto',
        }}
      >
        <Typography
          variant='h1'
          gutterBottom
          sx={{ fontWeight: 'bold', fontSize: '1.8em', mb: 3, mt: 5, textAlign: 'center' }}
        >
          Tungvrickare
        </Typography>
        <Box sx={{ ...styles.buttonsContainer, ...styles.additionalButtonsContainer }}>
          <Tooltip title='Dina sparade lektioner'>
            <Button
              variant='contained'
              color='primary'
              disableRipple
              onClick={() => setDrawerOpen(true)}
              startIcon={<HistoryIcon />}
              sx={{
                padding: '8px',
                marginRight: '0px',
                paddingLeft: '14px',
                paddingRight: '3px',
                minWidth: 'auto',
                textTransform: 'none',
              }}
            />
          </Tooltip>
        </Box>

        <InfoBox
          icon={StormIcon}
          title='Instruktioner för Tungvrickare'
          instructions={[
            'Välj antalet tungvrickare att generera',
            'Välj språk för tungvrickarna',
            'Välj fonetisk representation',
            'Generera illustrativa bilder',
          ]}
          theme={theme}
          styles={styles}
        />

        <Box sx={{ ...styles.freshBox, padding: '30px' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              {/*
              <PreviousLessonsLoader
                user={user}
                lessonType='TongueTwistersLesson.js'
                onLoadLesson={handleLoadPreviousLesson}
              />
*/}
              <Typography variant='subtitle1' sx={{ fontWeight: '500', mb: 1 }}>
                Antal tungvrickare:
              </Typography>
              <FormControl fullWidth>
                <Select value={twisterCount} onChange={e => setTwisterCount(e.target.value)}>
                  {[2, 4, 6, 8, 10].map(num => (
                    <MenuItem key={num} value={num}>
                      {num} tungvrickare
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant='subtitle1' sx={{ fontWeight: '500', mb: 1 }}>
                Språk:
              </Typography>
              <LanguageSelector language={language} setLanguage={setLanguage} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant='subtitle1' sx={{ fontWeight: '500', mb: 1 }}>
                Uttalsnotering:
              </Typography>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <CustomFormControlLabel
                  theme={theme}
                  control={<Checkbox />}
                  label={
                    <Box>
                      <Typography sx={{ fontSize: '0.975rem' }}>IPA-notation</Typography>
                    </Box>
                  }
                  checked={includeIPA}
                  onChange={e => setIncludeIPA(e.target.checked)}
                />
                <CustomFormControlLabel
                  theme={theme}
                  control={<Checkbox />}
                  label={
                    <Box>
                      <Typography sx={{ fontSize: '0.975rem' }}>Ord-för-ord</Typography>
                    </Box>
                  }
                  checked={includeSyllables}
                  onChange={e => setIncludeSyllables(e.target.checked)}
                />
              </Box>
            </Grid>
          </Grid>

          <Button
            variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
            onClick={handleGenerateTwisters}
            disabled={isGenerating}
            sx={{ mt: 6 }}
            startIcon={isGenerating ? <CircularProgress size={16} /> : <GenerateIcon />}
          >
            {isGenerating ? 'Genererar...' : 'Generera tungvrickare'}
          </Button>

          {isGenerating && (
            <Box sx={{ mt: 7 }}>
              <ProgressBar progress={progress} label='Genererar tungvrickare...' />
            </Box>
          )}

          {lessonData && (
            <Box sx={{ mt: 4 }}>
              <Divider sx={{ mt: 3, mb: 4 }} />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Box>
                  <Button
                    variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                    onClick={handleImageButtonClick}
                    disabled={isGeneratingImages}
                    sx={{ mr: 2 }}
                    startIcon={isGeneratingImages ? <CircularProgress size={16} /> : <ImageIcon />}
                  >
                    {isGeneratingImages ? 'Genererar bilder...' : 'Generera bilder'}
                  </Button>
                </Box>
                <Box>
                  <Button variant='print' onClick={handlePrintClick} startIcon={<PrintIcon />}>
                    Skriv ut
                  </Button>

                  <Button
                    variant='print'
                    startIcon={<SaveAsIcon />}
                    sx={{ ml: 2 }}
                    onClick={handleSaveLessonClick}
                  >
                    Spara i lektionsbank
                  </Button>
                </Box>
              </Box>
              <SaveLessonDialog
                open={saveDialogOpen}
                onClose={() => setSaveDialogOpen(false)}
                onSave={handleSaveWithComment}
                theme={theme}
                lessonObject={lessonData}
                lessonName={titleText}
              />

              <LessonDrawer
                userId={user.uid}
                lessonType='TongueTwistersLesson.js'
                theme={theme}
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                lessonIcon={StormIcon}
                onSelectLesson={lesson => {
                  setLessonData(lesson.data);
                  setTitleText(lesson.lessonName);
                  setDrawerOpen(false);

                  showSnackbar(`Lektionen har laddats in.`, 'success');
                  document.querySelector('hr')?.scrollIntoView({ behavior: 'smooth' });
                }}
              />

              <ImageGenerationModal
                open={showImageModal}
                onClose={handleCloseModal}
                onGenerate={
                  selectedTwisterIndex !== null
                    ? () => handleGenerateSpecificImage(selectedTwisterIndex)
                    : handleGenerateImages
                }
                isGenerating={isGeneratingImages}
                instructions={imageInstructions}
                setInstructions={setImageInstructions}
                generationType='twisters'
                theme={theme}
              />

              <Box sx={{ mt: 7 }}>
                {/* Title */}
                {isEditingTitle ? (
                  <TextField
                    value={titleText}
                    onChange={e => setTitleText(e.target.value)}
                    onBlur={() => setIsEditingTitle(false)}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        setIsEditingTitle(false);
                      }
                    }}
                    autoFocus
                    fullWidth
                    variant='standard'
                    sx={{
                      backgroundColor: 'transparent',
                      '& .MuiInputBase-input': {
                        textAlign: 'center',
                        fontWeight: 'bold',
                        fontSize: '2.6em',
                        marginBottom: '1.2rem',
                        marginTop: '1.5rem',
                      },
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                ) : (
                  <Box
                    sx={{
                      position: 'relative',
                      display: 'inline-block',
                      margin: '0 auto',
                      width: '100%',
                      mb: 7,
                      mt: 5,
                      ':hover .edit-icon': {
                        opacity: 1,
                      },
                    }}
                  >
                    <Typography
                      variant='h1'
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '2.6em',
                        cursor: 'pointer',
                        textAlign: 'center',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 2,
                      }}
                    >
                      {titleText || ' '}
                      <Box
                        component='span'
                        sx={{
                          opacity: 0,
                          transition: 'opacity 0.2s',
                          display: 'inline-flex',
                          gap: 0.5,
                        }}
                        className='edit-icon'
                      >
                        <Tooltip title='Redigera' placement='top'>
                          <IconButton size='small' onClick={() => setIsEditingTitle(true)}>
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Typography>
                  </Box>
                )}

                {lessonData &&
                  lessonData.twisters.map((twister, index) => (
                    <Box
                      key={index}
                      sx={{
                        position: 'relative',
                        display: 'flex',
                        alignItems: 'center',
                        mb: 5,
                        backgroundColor: theme.palette.mode === 'dark' ? '#1e1e1e' : '#f5f5f5',
                        padding: '20px',
                        borderRadius: '8px',
                        '&:hover': {
                          '& .delete-button': {
                            opacity: 1,
                            visibility: 'visible',
                          },
                        },
                      }}
                    >
                      <IconButton
                        className='delete-button'
                        size='small'
                        onClick={() => {
                          const updatedTwisters = lessonData.twisters.filter((_, i) => i !== index);
                          setLessonData({ ...lessonData, twisters: updatedTwisters });
                          showSnackbar('Tungvrickaren har tagits bort', 'success');
                        }}
                        sx={{
                          position: 'absolute',
                          top: 8,
                          right: 8,
                          opacity: 0,
                          visibility: 'hidden',
                          transition: 'all 0.2s ease',
                          bgcolor:
                            theme.palette.mode === 'dark' ? 'rgba(0,0,0,0.2)' : 'transparent',
                          '&:hover': {
                            bgcolor:
                              theme.palette.mode === 'dark'
                                ? 'rgba(0,0,0,0.3)'
                                : 'rgba(255,255,255,0.9)',
                          },
                        }}
                      >
                        <Tooltip title='Ta bort tungvrickare' placement='top'>
                          <DeleteOutlineIcon fontSize='small' />
                        </Tooltip>
                      </IconButton>

                      <Box sx={{ flex: '0 0 270px', mr: 3, position: 'relative' }}>
                        <HoverableImage
                          imageUrl={twister.imageUrl}
                          isLoading={isGeneratingImages}
                          loadingIndex={loadingImageIndex}
                          currentIndex={index}
                          onGenerateClick={() => {
                            setSelectedTwisterIndex(index);
                            setShowImageModal(true);
                          }}
                          userId={user.uid}
                          onImageUpdate={newImageUrl => handleImageUpdate(index, newImageUrl)}
                          height='270px'
                          theme={theme}
                          showSnackbar={showSnackbar}
                          tinyMode={false}
                        />
                      </Box>

                      <Box sx={{ flex: 1 }}>
                        {/* Tongue Twister */}
                        <Box
                          sx={{
                            '&:hover .controls': {
                              opacity: 1,
                            },
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            mb: 1,
                          }}
                        >
                          {editingTwisterId === index ? (
                            <TextField
                              fullWidth
                              value={twister.twister}
                              onChange={e => {
                                const updatedTwisters = [...lessonData.twisters];
                                updatedTwisters[index] = { ...twister, twister: e.target.value };
                                setLessonData({ ...lessonData, twisters: updatedTwisters });
                              }}
                              onBlur={() => handleEditTwister(index, twister.twister, 'twister')}
                              onKeyDown={e => {
                                if (e.key === 'Enter' && !e.shiftKey) {
                                  e.preventDefault();
                                  handleEditTwister(index, twister.twister, 'twister');
                                }
                              }}
                              autoFocus
                              sx={{
                                '& .MuiInputBase-input': {
                                  fontSize: '24px',
                                  fontWeight: '600',
                                },
                              }}
                            />
                          ) : (
                            <Typography
                              variant='h2'
                              sx={{
                                fontSize: '24px',
                                fontWeight: '600',
                                flex: 1,
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                              }}
                            >
                              {twister.twister}
                              <Box
                                component='span'
                                className='controls'
                                sx={{
                                  opacity: 0,
                                  transition: 'opacity 0.2s',
                                  display: 'inline-flex',
                                  gap: 0.5,
                                  ml: 1,
                                }}
                              >
                                <Voice
                                  content={twister.twister}
                                  fontSize={20}
                                  userId={user.uid}
                                  language={language}
                                  tooltipPlacement='top'
                                />
                                <Tooltip title='Redigera' placement='top'>
                                  <IconButton
                                    size='small'
                                    onClick={() => setEditingTwisterId(index)}
                                  >
                                    <EditIcon fontSize='small' />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Typography>
                          )}
                        </Box>

                        {/* Pronunciation */}

                        {/* IPA Pronunciation */}
                        {includeIPA && twister.ipa_pronunciation && (
                          <Box
                            sx={{
                              '&:hover .controls': {
                                opacity: 1,
                              },
                              display: 'flex',
                              alignItems: 'center',
                              gap: 1,
                              mb: includeSyllables ? 1 : 0,
                            }}
                          >
                            {editingIPAId === index ? (
                              <TextField
                                fullWidth
                                multiline
                                value={twister.ipa_pronunciation}
                                onChange={e => {
                                  const updatedTwisters = [...lessonData.twisters];
                                  updatedTwisters[index] = {
                                    ...twister,
                                    ipa_pronunciation: e.target.value,
                                  };
                                  setLessonData({ ...lessonData, twisters: updatedTwisters });
                                }}
                                onBlur={() =>
                                  handleEditTwister(
                                    index,
                                    twister.ipa_pronunciation,
                                    'ipa_pronunciation'
                                  )
                                }
                                onKeyDown={e => {
                                  if (e.key === 'Enter' && !e.shiftKey) {
                                    e.preventDefault();
                                    handleEditTwister(
                                      index,
                                      twister.ipa_pronunciation,
                                      'ipa_pronunciation'
                                    );
                                  }
                                }}
                                autoFocus
                              />
                            ) : (
                              <Typography
                                variant='body1'
                                sx={{
                                  flex: 1,
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 1,
                                  fontFamily: 'monospace',
                                }}
                              >
                                {twister.ipa_pronunciation}
                                <Box
                                  component='span'
                                  className='controls'
                                  sx={{
                                    opacity: 0,
                                    transition: 'opacity 0.2s',
                                    display: 'inline-flex',
                                    gap: 0.5,
                                    ml: 1,
                                  }}
                                >
                                  <Tooltip title='Redigera' placement='top'>
                                    <IconButton size='small' onClick={() => setEditingIPAId(index)}>
                                      <EditIcon fontSize='small' />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              </Typography>
                            )}
                          </Box>
                        )}

                        {includeSyllables && twister.syllable_pronunciation && (
                          <Box
                            sx={{
                              '&:hover .controls': {
                                opacity: 1,
                              },
                              display: 'flex',
                              alignItems: 'center',
                              gap: 1,
                            }}
                          >
                            {editingSyllableId === index ? (
                              <TextField
                                fullWidth
                                multiline
                                value={twister.syllable_pronunciation}
                                onChange={e => {
                                  const updatedTwisters = [...lessonData.twisters];
                                  updatedTwisters[index] = {
                                    ...twister,
                                    syllable_pronunciation: e.target.value,
                                  };
                                  setLessonData({ ...lessonData, twisters: updatedTwisters });
                                }}
                                onBlur={() =>
                                  handleEditTwister(
                                    index,
                                    twister.syllable_pronunciation,
                                    'syllable_pronunciation'
                                  )
                                }
                                onKeyDown={e => {
                                  if (e.key === 'Enter' && !e.shiftKey) {
                                    e.preventDefault();
                                    handleEditTwister(
                                      index,
                                      twister.syllable_pronunciation,
                                      'syllable_pronunciation'
                                    );
                                  }
                                }}
                                autoFocus
                              />
                            ) : (
                              <Typography
                                variant='body1'
                                sx={{
                                  flex: 1,
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 1,
                                  fontFamily: 'monospace',
                                }}
                              >
                                {twister.syllable_pronunciation}
                                <Box
                                  component='span'
                                  className='controls'
                                  sx={{
                                    opacity: 0,
                                    transition: 'opacity 0.2s',
                                    display: 'inline-flex',
                                    gap: 0.5,
                                    ml: 1,
                                  }}
                                >
                                  <Tooltip title='Redigera' placement='top'>
                                    <IconButton
                                      size='small'
                                      onClick={() => setEditingSyllableId(index)}
                                    >
                                      <EditIcon fontSize='small' />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              </Typography>
                            )}
                          </Box>
                        )}
                      </Box>
                    </Box>
                  ))}

                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, mb: 4 }}>
                  <Button
                    variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                    startIcon={<AddIcon />}
                    onClick={() => {
                      const newTwister = {
                        twister: 'Ny tungvrickare',
                        meaning: 'Skriv betydelsen här',
                        ipa_pronunciation: 'Skriv IPA-uttalet här',
                        syllable_pronunciation: 'Ord-för-ord-med-bindestreck här',
                        imagePrompt: '',
                        imageUrl: null,
                        difficulty: 'medel',
                      };
                      setLessonData({
                        ...lessonData,
                        twisters: [...lessonData.twisters, newTwister],
                      });
                      showSnackbar('Ny tungvrickare har lagts till', 'success');
                    }}
                    sx={{
                      mt: 2,
                      mb: 4,
                    }}
                  >
                    Lägg till tungvrickare
                  </Button>
                </Box>

                <Box
                  sx={{
                    mt: 8,
                    mb: 4,
                    padding: '30px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#1e1e1e' : '#f5f5f5',
                    borderRadius: '8px',
                  }}
                >
                  <Typography variant='h2' sx={{ fontSize: '1.5em', mb: 3, fontWeight: '600' }}>
                    Berättelse med tungvrickare
                  </Typography>
                  <Box
                    sx={{
                      '&:hover .controls': {
                        opacity: 1,
                      },
                      display: 'flex',
                      alignItems: 'flex-start',
                      gap: 1,
                    }}
                  >
                    {isEditingStory ? (
                      <TextField
                        fullWidth
                        multiline
                        rows={10}
                        defaultValue={lessonData.story}
                        onBlur={e => handleEditStory(e.target.value)}
                        onKeyDown={e => {
                          if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault();
                            handleEditStory(e.target.value);
                          }
                        }}
                        autoFocus
                        sx={{
                          '& .MuiInputBase-input': {
                            lineHeight: 1.8,
                          },
                        }}
                      />
                    ) : (
                      <Typography
                        variant='body1'
                        sx={{
                          lineHeight: 1.8,
                          flex: 1,
                          display: 'flex',
                          alignItems: 'flex-start',
                          gap: 1,
                        }}
                      >
                        {lessonData.story || ' '}
                        <Box
                          component='span'
                          className='controls'
                          sx={{
                            opacity: 0,
                            transition: 'opacity 0.2s',
                            display: 'inline-flex',
                            gap: 0.5,
                            ml: 1,
                            mt: 0.5,
                          }}
                        >
                          <Voice
                            content={lessonData.story}
                            fontSize={20}
                            userId={user.uid}
                            language={language}
                            tooltipPlacement='top'
                          />
                          <Tooltip title='Redigera' placement='top'>
                            <IconButton size='small' onClick={() => setIsEditingStory(true)}>
                              <EditIcon fontSize='small' />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default TongueTwistersLesson;
