import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, ListSubheader } from '@mui/material';

const gradeInstructions = {
  åk1: 'För årskurs 1 (7 år) ska lektionen vara mycket enkel med lättförståeliga koncept och begrepp som passar nybörjare.',
  åk2: 'För årskurs 2 (8 år) ska lektionen vara enkel och fokusera på att bygga grundläggande kunskap med tydliga exempel.',
  åk3: 'För årskurs 3 (9 år) ska lektionen vara enkel men innehålla lite mer detaljerad information och börja introducera nya begrepp.',
  åk4: 'För årskurs 4 (10 år) ska lektionen börja introducera koncept på medelnivå och uppmuntra till mer självständigt tänkande.',
  åk5: 'För årskurs 5 (11 år) ska lektionen presentera en medelnivå av svårighet och inkludera mer komplexa idéer och samband.',
  åk6: 'För årskurs 6 (12 år) ska lektionen innehålla medium svårighetsgrad med djupare förklaringar och mer avancerade koncept.',
  åk7: 'För årskurs 7 (13 år) ska lektionen vara utmanande med fokus på kritiskt tänkande och mer avancerade ämnen som kräver analys.',
  åk8: 'För årskurs 8 (14 år) ska lektionen vara ganska utmanande med avancerade koncept som kräver god förståelse för ämnet.',
  åk9: 'För årskurs 9 (15 år) ska lektionen vara mycket utmanande med fokus på djup analys, problemlösning och förberedelse inför gymnasiet.',
  firstRing:
    'För första ring på gymnasiet (16 år) ska lektionen vara på en hög svårighetsnivå och förbereda eleverna för komplexa ämnen.',
  secondRing:
    'För andra ring på gymnasiet (17 år) ska lektionen utmana eleverna med djupgående och detaljerade ämnen som kräver omfattande förståelse.',
  thirdRing:
    'För tredje ring på gymnasiet (18 år) ska lektionen vara mycket avancerad och spegla en nästan högskolemässig svårighetsgrad.',
};

export { gradeInstructions };

const GradeSelector = ({ grade, setGrade }) => {
  return (
    <FormControl fullWidth margin='normal' sx={{ mt: 0, mb: 0 }}>
      <Select defaultValue='åk6' value={grade} onChange={e => setGrade(e.target.value)}>
        <ListSubheader>Grundskolan</ListSubheader>
        <MenuItem value='åk1'>Åk 1</MenuItem>
        <MenuItem value='åk2'>Åk 2</MenuItem>
        <MenuItem value='åk3'>Åk 3</MenuItem>
        <MenuItem value='åk4'>Åk 4</MenuItem>
        <MenuItem value='åk5'>Åk 5</MenuItem>
        <MenuItem value='åk6'>Åk 6</MenuItem>
        <MenuItem value='åk7'>Åk 7</MenuItem>
        <MenuItem value='åk8'>Åk 8</MenuItem>
        <MenuItem value='åk9'>Åk 9</MenuItem>
        <ListSubheader>Gymnasiet</ListSubheader>
        <MenuItem value='firstRing'>1:an</MenuItem>
        <MenuItem value='secondRing'>2:an</MenuItem>
        <MenuItem value='thirdRing'>3:an</MenuItem>
      </Select>
    </FormControl>
  );
};

export default GradeSelector;
