import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Tabs, Tab } from '@mui/material';
import { collection, query, where, orderBy, limit, getDocs } from 'firebase/firestore';
import { db } from './firebase';
import { useNavigate } from 'react-router-dom';
import LessonCard from './LessonCard';
import { lessonMapping, getLessonTypeParam, lessonUrlMapping } from './functions';
import { LessonsSkeleton } from './TeacherSkeleton';

const TabPanel = ({ children, value, index, ...other }) => (
  <div
    role='tabpanel'
    hidden={value !== index}
    id={`lesson-tabpanel-${index}`}
    aria-labelledby={`lesson-tab-${index}`}
    {...other}
  >
    {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
  </div>
);

const MyLessons = ({ theme, styles, user }) => {
  const [myLessons, setMyLessons] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.uid) {
      loadMyLessons();
    }
  }, [user]);

  const loadMyLessons = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const q = query(
        collection(db, 'lesson_usage'),
        where('userId', '==', user.uid),
        where('hasPrinted', '==', true),
        orderBy('timestamp', 'desc'),
        limit(200)
      );
      const querySnapshot = await getDocs(q);
      const lessonData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        timestamp: doc.data().timestamp?.toDate(),
      }));
      setMyLessons(lessonData);
    } catch (error) {
      console.error('Error loading my lessons:', error);
      setError('Failed to load lessons. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleLessonClick = lesson => {
    const shortId = lesson.id.substring(0, 10);
    const typeParam = getLessonTypeParam(lesson.lessonType);
    const urlPath = lessonUrlMapping(lesson.lessonType);
    navigate(`/app/lektioner/${urlPath}/${shortId}?type=${typeParam}`);
  };

  // Group lessons by their mapped type
  const groupedLessons = myLessons.reduce((acc, lesson) => {
    const mappedType = lessonMapping(lesson.lessonType);
    if (!acc[mappedType]) {
      acc[mappedType] = [];
    }
    acc[mappedType].push(lesson);
    return acc;
  }, {});

  const tabTypes = Object.keys(groupedLessons);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const renderContent = () => {
    if (isLoading) {
      return <LessonsSkeleton tabCount={7} theme={theme} />;
    }

    if (error) {
      return (
        <Typography variant='body1' color='error' sx={{ textAlign: 'center', py: 4 }}>
          {error}
        </Typography>
      );
    }

    if (tabTypes.length === 0) {
      return (
        <Typography variant='body1' sx={{ textAlign: 'center', py: 4 }}>
          Du har inte sparat några lektioner ännu. Testa generera en lektion och spara i din
          lektionsbank! :)
        </Typography>
      );
    }

    return (
      <>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            background: theme.palette.mode === 'dark' ? '#22303c' : '#f9f9fa',
            borderTopLeftRadius: '9px',
            borderTopRightRadius: '9px',
          }}
        >
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            variant='scrollable'
            scrollButtons='auto'
            aria-label='lesson type tabs'
          >
            {tabTypes.map((type, index) => (
              <Tab
                key={type}
                label={type}
                id={`lesson-tab-${index}`}
                aria-controls={`lesson-tabpanel-${index}`}
                sx={{
                  padding: '20px',
                  textTransform: 'none',
                  fontSize: '1.05rem',
                }}
              />
            ))}
          </Tabs>
        </Box>

        {tabTypes.map((type, index) => (
          <TabPanel key={type} value={selectedTab} index={index}>
            <Box sx={{ padding: '0px', paddingTop: '20px' }}>
              <Grid container spacing={3}>
                {groupedLessons[type].map(lesson => (
                  <Grid item xs={12} sm={6} md={4} key={lesson.id}>
                    <LessonCard
                      name={lesson.lessonName}
                      comment={lesson.comment}
                      timestamp={lesson.timestamp}
                      lessonType={lesson.lessonType}
                      imageUrl={lesson.coverImage}
                      onClick={() => handleLessonClick(lesson)}
                      size='small'
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </TabPanel>
        ))}
      </>
    );
  };

  return (
    <Box sx={styles.container}>
      <Box sx={{ ...styles.innerContainer, width: { xs: '100%', md: '100%' }, margin: '0 auto' }}>
        <Typography
          variant='h1'
          gutterBottom
          sx={{ fontWeight: 'bold', fontSize: '1.8em', mb: 3, mt: 5, textAlign: 'left' }}
        >
          Mina sparade lektioner
        </Typography>
        <Box sx={{ ...styles.newBox, padding: '0px', width: '100%' }}>{renderContent()}</Box>
      </Box>
    </Box>
  );
};

export default MyLessons;
