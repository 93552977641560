import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  IconButton,
  Chip,
  Button,
  CircularProgress,
  alpha,
  darken,
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, query, where, deleteDoc, doc, limit } from 'firebase/firestore';
import { db } from '../firebase';

import GamesIcon from '@mui/icons-material/Games';

import QuizIcon from '@mui/icons-material/Quiz';
import Logger from '../Logger';

const MyQuizzes = ({ user, theme, styles }) => {
  const [quizzes, setQuizzes] = useState([]);
  const [loadingMyQuizzes, setLoadingMyQuizzes] = useState(false);
  const [isManaging, setIsManaging] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    loadQuizzes();
  }, [user.uid]);

  const loadQuizzes = async () => {
    try {
      setLoadingMyQuizzes(true);
      const q = query(collection(db, 'quiz'), where('userId', '==', user.uid), limit(30));

      Logger.log('loadQuizzes userid: ', user.uid);

      const querySnapshot = await getDocs(q);
      const quizzesData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));

      const sortedQuizzes = quizzesData.sort(
        (a, b) => (b.lastTrainingSession || 0) - (a.lastTrainingSession || 0)
      );

      setQuizzes(sortedQuizzes);
    } catch (error) {
      console.error('Error fetching quizzes:', error);
    } finally {
      setLoadingMyQuizzes(false);
    }
  };

  const handleDeleteQuiz = async quizId => {
    setQuizzes(prevQuizzes => prevQuizzes.filter(quiz => quiz.id !== quizId));
    try {
      await deleteDoc(doc(db, 'quiz', quizId));
    } catch (error) {
      console.error('Error deleting quiz:', error);
      const quizToRestore = quizzes.find(quiz => quiz.id === quizId);
      if (quizToRestore) {
        setQuizzes(prevQuizzes => [...prevQuizzes, quizToRestore]);
      }
    }
  };

  const getTimeSinceLastTraining = lastTrainingSession => {
    if (!lastTrainingSession) return 'Aldrig tränat';
    const now = Date.now();
    const diff = now - lastTrainingSession;
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);

    if (seconds < 60) return `${seconds} sekund${seconds !== 1 ? 'er' : ''} sedan`;
    if (minutes < 60) return `${minutes} minut${minutes !== 1 ? 'er' : ''} sedan`;
    if (hours < 24) return `${hours} timm${hours !== 1 ? 'ar' : 'e'} sedan`;

    const days = Math.floor(hours / 24);
    if (days === 1) return 'Igår';
    if (days < 7) return `${days} dagar sedan`;
    const weeks = Math.floor(days / 7);
    if (weeks === 1) return '1 vecka sedan';
    if (weeks < 4) return `${weeks} veckor sedan`;
    const months = Math.floor(days / 30);
    if (months === 1) return '1 månad sedan';
    if (months < 12) return `${months} månader sedan`;
    const years = Math.floor(days / 365);
    return `${years} år sedan`;
  };

  const calculateResults = results => {
    if (!results || !results.some(result => result.selectedOption)) return { correct: 0, wrong: 0 };
    const totalQuestions = results.length;
    const correctAnswers = results.filter(
      result => result.selectedOption === result.correct
    ).length;
    return { correct: correctAnswers, wrong: totalQuestions - correctAnswers };
  };

  const formatUrlPart = str => {
    return str
      .replace(/\s+/g, '-')
      .replace(/[åä]/g, 'a')
      .replace(/[ö]/g, 'o')
      .replace(/[^a-z0-9-]/gi, '')
      .toLowerCase();
  };

  const handleQuizClick = quiz => {
    const formattedQuizName = formatUrlPart(quiz.quizName);
    navigate(`/app/quiz/${quiz.id}/${formattedQuizName}`);
  };

  if (loadingMyQuizzes) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      {quizzes.length === 0 ? (
        <Box
          sx={{
            p: 4,
            textAlign: 'center',
            color: 'text.secondary',
            bgcolor: alpha(theme.palette.background.paper, 0.6),
            borderRadius: 2,
          }}
        >
          De quiz du övar på hamnar här.
        </Box>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {quizzes.map(quiz => (
            <Card
              key={quiz.id}
              sx={{
                width: '100%',
                borderRadius: 5,
                padding: '13px',
                boxShadow: 'none',
                border: theme => `1px solid ${theme.palette.divider}`,
                cursor: 'pointer',
                '&:hover': {
                  bgcolor: theme => theme.palette.background.paperHover,
                  border: theme => `1px solid ${darken(theme.palette.divider, 0.2)}`,
                },
              }}
              onClick={() => !isManaging && handleQuizClick(quiz)}
            >
              <CardContent
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  py: 2,
                  '&:last-child': { pb: 2 },
                }}
              >
                <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', gap: 3 }}>
                  <GamesIcon
                    sx={{
                      fontSize: 24,

                      color: theme => darken(theme.palette.text.main, 0.2),
                    }}
                  />
                  <Typography
                    variant='subtitle1'
                    sx={{
                      fontWeight: 500,
                      color: theme.palette.text.primary,
                      flex: '0 0 300px',
                    }}
                  >
                    {quiz.quizName}
                    {quiz.byAdmin === 'Yes' && (
                      <Box
                        component='span'
                        sx={{
                          display: 'inline-flex',
                          alignItems: 'center',
                          ml: 2,
                          color: alpha(theme.palette.text.secondary, 0.7),
                        }}
                      >
                        <CircleIcon sx={{ fontSize: 8, color: '#85a5f0', mr: 0.5 }} />
                        <Typography variant='caption'>Kopierad</Typography>
                      </Box>
                    )}
                  </Typography>

                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      color: theme.palette.text.secondary,
                      flex: '0 0 200px',
                    }}
                  >
                    <AccessTimeIcon sx={{ fontSize: 16, mr: 1 }} />
                    <Typography variant='body2'>
                      {getTimeSinceLastTraining(quiz.lastTrainingSession)}
                    </Typography>
                  </Box>

                  {quiz.results?.some(result => result.selectedOption) && (
                    <Box sx={{ display: 'flex', gap: 1, flex: '0 0 200px' }}>
                      {(() => {
                        const { correct, wrong } = calculateResults(quiz.results);
                        return (
                          <>
                            <Chip
                              icon={<CheckCircleOutlineIcon />}
                              label={`${correct} rätt`}
                              size='small'
                              sx={{
                                height: '24px',
                                background: alpha('#27792B', 0.1),
                                color: '#27792B',
                                textTransform: 'uppercase',
                                fontSize: '11px',
                                fontWeight: 500,
                                '& .MuiChip-icon': {
                                  fontSize: 14,
                                  color: '#27792B',
                                },
                              }}
                            />
                            <Chip
                              icon={<CancelOutlinedIcon />}
                              label={`${wrong} fel`}
                              size='small'
                              sx={{
                                height: '24px',
                                background: alpha('#FF4D4D', 0.1),
                                color: '#FF4D4D',
                                textTransform: 'uppercase',
                                fontSize: '11px',
                                fontWeight: 500,
                                '& .MuiChip-icon': {
                                  fontSize: 14,
                                  color: '#FF4D4D',
                                },
                              }}
                            />
                          </>
                        );
                      })()}
                    </Box>
                  )}
                </Box>

                {isManaging ? (
                  <IconButton
                    onClick={e => {
                      e.stopPropagation();
                      handleDeleteQuiz(quiz.id);
                    }}
                    sx={{
                      color: theme.palette.error.main,
                      '&:hover': {
                        color: darken(theme.palette.error.main, 0.1),
                      },
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                ) : (
                  <Box
                    sx={{
                      width: 24,
                      height: 24,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: alpha(theme.palette.text.primary, 0.3),
                    }}
                  >
                    →
                  </Box>
                )}
              </CardContent>
            </Card>
          ))}

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4, alignItems: 'center' }}>
            {quizzes.length > 0 && (
              <Button
                variant='print'
                onClick={() => setIsManaging(!isManaging)}
                startIcon={<DeleteIcon />}
              >
                {isManaging ? 'Klar?' : 'Hantera'}
              </Button>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default MyQuizzes;
