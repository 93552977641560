import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Stack,
  Typography,
  Box,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { East as ArrowIcon } from '@mui/icons-material';
const CompareDialog = ({ open, onClose, onSubmit, theme }) => {
  const [concept1, setConcept1] = useState('');
  const [concept2, setConcept2] = useState('');
  const [compareType, setCompareType] = useState('similarities');
  const [detail, setDetail] = useState('basic');
  const [format, setFormat] = useState('text');

  const handleSubmit = () => {
    if (!concept1.trim() || !concept2.trim()) return;

    const compareTypeMap = {
      similarities: 'likheter och skillnader',
      pros: 'fördelar och nackdelar',
      historical: 'historisk utveckling',
      practical: 'praktisk användning',
    };

    const detailMap = {
      basic: 'grundläggande',
      detailed: 'detaljerad',
      deep: 'djupgående',
    };

    const formatMap = {
      text: 'text',
      bullets: 'punktlista',
      table: 'tabell',
    };

    const prompt = `Jämför och förklara skillnaderna mellan ${concept1} och ${concept2}. Analystyp: ${compareTypeMap[compareType]}. Detaljnivå: ${detailMap[detail]}. Format: ${formatMap[format]}.`;

    onSubmit(prompt);
    onClose();
    setConcept1('');
    setConcept2('');
    setCompareType('similarities');
    setDetail('basic');
    setFormat('text');
  };

  const compareTypes = [
    { value: 'similarities', label: 'Likheter & skillnader' },
    { value: 'pros', label: 'Fördelar & nackdelar' },
    { value: 'historical', label: 'Historisk utveckling' },
    { value: 'practical', label: 'Praktisk användning' },
  ];

  const detailLevels = [
    { value: 'basic', label: 'Grundläggande' },
    { value: 'detailed', label: 'Detaljerad' },
    { value: 'deep', label: 'Djupgående' },
  ];

  const formatTypes = [
    { value: 'text', label: 'Text' },
    { value: 'bullets', label: 'Punktlista' },
    { value: 'table', label: 'Tabell' },
  ];

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: 680,
          backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'white',
          border: theme.palette.mode === 'dark' ? '1px solid #323d48' : '1px solid #e2e8f0',
          p: 3,
        },
      }}
    >
      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 16,
          top: 16,
          color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'inherit',
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 1,
          color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'inherit',
          pt: 2,
        }}
      >
        <Typography variant='h5' fontWeight={700}>
          Vad vill du jämföra?
        </Typography>
      </DialogTitle>

      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: 4,
          pb: 4,
        }}
      >
        <TextField
          autoFocus
          fullWidth
          value={concept1}
          onChange={e => setConcept1(e.target.value)}
          placeholder='T.ex. marknadsekonomi'
          sx={{ mb: 3 }}
        />
        <TextField
          fullWidth
          value={concept2}
          onChange={e => setConcept2(e.target.value)}
          placeholder='T.ex. planekonomi'
          sx={{ mb: 4 }}
        />

        <Stack spacing={3} width='100%' alignItems='center'>
          <Typography variant='subtitle1' fontWeight={600}>
            Välj analystyp:
          </Typography>
          <Box
            sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2, width: '100%' }}
          >
            {compareTypes.map(({ value, label }) => (
              <Button
                key={value}
                variant={compareType === value ? 'save' : 'print'}
                onClick={() => setCompareType(value)}
                sx={{ height: 45 }}
                color={compareType === value ? 'primary' : 'inherit'}
                startIcon={compareType === value ? <CheckCircleIcon /> : null}
              >
                {label}
              </Button>
            ))}
          </Box>
        </Stack>

        <Stack spacing={3} width='100%' alignItems='center' sx={{ mt: 4 }}>
          <Typography variant='subtitle1' fontWeight={600}>
            Välj detaljnivå:
          </Typography>
          <Box
            sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 2, width: '100%' }}
          >
            {detailLevels.map(({ value, label }) => (
              <Button
                key={value}
                variant={detail === value ? 'save' : 'print'}
                onClick={() => setDetail(value)}
                sx={{ height: 45 }}
                color={detail === value ? 'primary' : 'inherit'}
                startIcon={detail === value ? <CheckCircleIcon /> : null}
              >
                {label}
              </Button>
            ))}
          </Box>
        </Stack>

        <Stack spacing={3} width='100%' alignItems='center' sx={{ mt: 4 }}>
          <Typography variant='subtitle1' fontWeight={600}>
            Välj format:
          </Typography>
          <Box
            sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 2, width: '100%' }}
          >
            {formatTypes.map(({ value, label }) => (
              <Button
                key={value}
                variant={format === value ? 'save' : 'print'}
                onClick={() => setFormat(value)}
                sx={{ height: 45 }}
                color={format === value ? 'primary' : 'inherit'}
                startIcon={format === value ? <CheckCircleIcon /> : null}
              >
                {label}
              </Button>
            ))}
          </Box>
        </Stack>
      </DialogContent>

      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button
          onClick={handleSubmit}
          variant='purp'
          disabled={!concept1.trim() || !concept2.trim()}
          sx={{ width: 200, height: 45 }}
        >
          Jämför <ArrowIcon sx={{ ml: 1 }} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CompareDialog;
