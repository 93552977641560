import React from 'react';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const GuideSection = ({ theme, styles }) => {
  const guideSteps = [
    {
      title: '1. Definiera personlighet',
      content: [
        'Attityd: Positiv och uppmuntrande, neutral och saklig, eller strikt och direkt?',
        'Humor: Torr, sarkastisk, ordvitsande eller situationskomisk?',
        'Empati: Hur emotionellt lyhörd ska boten vara?',
        'Problemlösning: Analytisk, kreativ, intuitiv eller en kombination?',
      ],
    },
    {
      title: '2. Informationshantering',
      content: [
        'Kunskapsområde: Specialist inom ett fält eller bred allmänbildning?',
        'Osäkerhet: Fråga efter mer information eller ge kvalificerade gissningar?',
        'Källhantering: Citera källor eller sammanfatta information?',
      ],
    },
    {
      title: '3. Ge din bot unika särdrag',
      content: [
        'Uttryckssätt: Utveckla specifika fraser eller ordval som är unika för boten',
        'Egenheter: Ge boten små egenheter, som att alltid använda statistik eller metaforik',
        'Bakgrundshistoria: Skapa en fiktiv bakgrund som påverkar botens perspektiv',
        'Specialintressen: Låt boten ha oväntade kunskapsområden den gärna refererar till',
      ],
    },
    {
      title: '4. Språklig ton och kommunikationsstil',
      content: [
        'Målgruppsanpassning: Justera språket baserat på användarens ålder eller expertis',
        'Kulturell medvetenhet: Anpassa referenser och uttryck till olika kulturer',
        'Språkliga variationer: Inkludera dialektala uttryck, slang eller facktermer vid behov',
      ],
    },
    {
      title: '5. Anpassningsförmåga',
      content: [
        'Kunskapsnivåer: Fördjupa för experter, förenkla för nybörjare',
        'Kontextbyte: Smidigt växla mellan olika samtalsämnen',
        'Stämningsläsning: Anpassa tonen baserat på användarens sinnestillstånd',
      ],
    },
    {
      title: '6. Hantera känsliga ämnen',
      content: [
        'Etisk kompass: Definiera botens moraliska riktlinjer',
        'Diplomatisk strategi: Utveckla tekniker för att hantera kontroversiella frågor',
        'Omdirigering: Skapa metoder för att styra samtalet till säkrare ämnen',
        'Transparens: Var öppen med botens begränsningar i känsliga diskussioner',
      ],
    },
    {
      title: '7. Självreflektion och begränsningar',
      content: [
        'Erkänna okunskap: Utveckla varierade sätt att säga "Jag vet inte"',
        'Metakognition: Låt boten reflektera över sitt eget "tänkande"',
        'Felsökning: Skapa strategier för att hantera missförstånd',
        'Uppdateringsbehov: Låt boten indikera när den behöver ny information',
      ],
    },
    {
      title: '8. Hantera feedback',
      content: [
        'Lärandeloop: Implementera system för att integrera användarfeedback',
        'Anpassning: Justera beteende baserat på direkt och indirekt kritik',
        'Förbättringsförslag: Låt boten be om specifik feedback för att förbättras',
      ],
    },
    {
      title: '9. Kunskapsgränser',
      content: [
        'Transparens: Var öppen med begränsningar i kunskap och förmågor',
        'Kreativ problemlösning: Hitta alternativa sätt att hjälpa när kunskap saknas',
        'Hänvisningar: Utveckla system för att guida användare till andra resurser',
        'Uppdateringsönskemål: Låt boten föreslå områden där den borde uppdateras',
      ],
    },
  ];

  return (
    <div>
      <Typography
        variant='h6'
        gutterBottom
        sx={{ marginTop: { xs: '30px', md: '227px', fontWeight: '600' } }}
      >
        Guide: Så instruerar du en AI-bot
      </Typography>

      {/*
      <Typography
        variant='body'
        sx={{ ...styles.softerText, marginBottom: '10px', display: 'block', fontSize: '0.9rem' }}
      >
        Vill du skapa en avancerad AI-bot kan du ta nedanstående faktorer i beaktande. Kom ihåg att
        du alltid kan redigera definitionen vid senare tillfälle.
      </Typography>
      */}
      {guideSteps.map((step, index) => (
        <Accordion
          key={index}
          sx={{
            border: theme.palette.mode === 'dark' ? '1px solid #444' : '1px solid #e0e0e0',
            border: 'none',
            boxShadow: 'none',
            '&::before': {
              backgroundColor:
                theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.03)',
            },
            // '& .MuiAccordionSummary-root': {
            //   backgroundColor:
            //     theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.03)',
            // },
            // '& .MuiAccordionDetails-root': {
            //   backgroundColor:
            //     theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.01)' : 'rgba(0, 0, 0, 0.01)',
            // },
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant='subtitle1'>{step.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List
              sx={{
                backgroundColor: theme.palette.mode === 'dark' ? 'transparent' : 'inherit',
                py: 0,
              }}
            >
              {step.content.map((item, itemIndex) => {
                const [beforeColon, afterColon] = item.split(':');
                return (
                  <ListItem
                    key={itemIndex}
                    variant='compactList'
                    className='rounded'
                    sx={{
                      py: 0,
                      '&:not(:last-child)': {
                        mb: 0,
                      },
                    }}
                  >
                    <ListItemIcon>
                      <ChevronRightIcon color='primary' />
                    </ListItemIcon>
                    <ListItemText
                      sx={{ lineHeight: '1.6' }}
                      primary={
                        <>
                          <Typography component='span' sx={{ fontWeight: '500' }}>
                            {beforeColon}:
                          </Typography>
                          {afterColon && ` ${afterColon}`}
                        </>
                      }
                      primaryTypographyProps={{
                        variant: 'body2',
                        sx: { lineHeight: 1.3 },
                      }}
                    />
                  </ListItem>
                );
              })}
            </List>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default GuideSection;
