// SharedAppBar.js
import React from 'react';
import { Box, Typography, styled } from '@mui/material';
import { AppBar, Toolbar } from '@mui/material';
import { Category, Bookmarks, Assignment, Home } from '@mui/icons-material';

const StyledAppBar = styled(AppBar)({
  backgroundColor: '#6b46c1',
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
});

const NavButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  padding: theme.spacing(1, 2),
  cursor: 'pointer',
  color: 'white',
  transition: 'background-color 0.2s',
  borderRadius: theme.shape.borderRadius,
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

const SharedAppBar = ({ onHomeClick }) => {
  return (
    <StyledAppBar position='sticky'>
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <Typography variant='h6' sx={{ fontWeight: 600 }}>
          Bokläsare
        </Typography>

        <Box sx={{ display: 'flex', gap: 2, mx: 'auto' }}>
          <NavButton onClick={onHomeClick}>
            <Home />
            <Typography>Hem</Typography>
          </NavButton>
          <NavButton>
            <Category />
            <Typography>Kategorier</Typography>
          </NavButton>
          <NavButton>
            <Bookmarks />
            <Typography>Bokhyllor</Typography>
          </NavButton>
          <NavButton>
            <Assignment />
            <Typography>Uppgifter</Typography>
          </NavButton>
        </Box>

        <Box sx={{ width: 100 }} />
      </Toolbar>
    </StyledAppBar>
  );
};

export default SharedAppBar;
