import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Divider,
  TextField,
  CircularProgress,
  FormControlLabel,
  Switch,
  Grid,
  Tooltip,
  Avatar,
  Chip,
  IconButton,
} from '@mui/material';
import GenerateIcon from '@mui/icons-material/AutoAwesome';
import { handlePrintConversationLesson } from './printFunctions';
import InfoBox from './InfoBox';
import { callApi } from './api';
import ChatIcon from '@mui/icons-material/Chat';
import { useSnackbar } from './SnackbarHandler';
import LessonDrawer from './LessonDrawer';
import SaveLessonDialog from './SaveLessonDialog';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import LanguageSelector from './LanguageSelector';
import GradeSelector, { gradeInstructions } from './GradeSelector';
import ProgressBar from './ProgressBar';
import ImageGenerationModal from './ImageGenerationModal';
import Voice from './Voice';
import { ThemeSelectorFlow } from './ThemeSelectorFlow';

import Logger from './Logger';

import HistoryIcon from '@mui/icons-material/History';
import { FourSquare } from 'react-loading-indicators';
import { ThemeSelector } from './ThemeSelector';

import ImageIcon from '@mui/icons-material/Image';
import PrintIcon from '@mui/icons-material/Print';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import { generateAndProcessImage, trackLesson } from './functions';

import RestaurantIcon from '@mui/icons-material/Restaurant';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PhoneIcon from '@mui/icons-material/Phone';
import HotelIcon from '@mui/icons-material/Hotel';
import LocalCafeIcon from '@mui/icons-material/LocalCafe';
import LibraryIcon from '@mui/icons-material/LocalLibrary';
import MovieIcon from '@mui/icons-material/Movie';
import FlightIcon from '@mui/icons-material/Flight';
import SportsIcon from '@mui/icons-material/Sports';
import SchoolIcon from '@mui/icons-material/School';
import StoreIcon from '@mui/icons-material/Store';
import HoverableImage from './HoverableImage';
import MicExternalOnIcon from '@mui/icons-material/MicExternalOn';
import { useSearchParams } from 'react-router-dom';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import ScienceIcon from '@mui/icons-material/Science';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import PaletteIcon from '@mui/icons-material/Palette';
import NatureIcon from '@mui/icons-material/Nature';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import MusicNoteIcon from '@mui/icons-material/MusicNote';

const ConversationLesson = ({ user, theme, styles }) => {
  const [topic, setTopic] = useState('airport');
  const [customTheme, setCustomTheme] = useState('');
  const [language, setLanguage] = useState('svenska');
  const [participantCount, setParticipantCount] = useState(2);
  const [conversationTone, setConversationTone] = useState('neutral');
  const [isGenerating, setIsGenerating] = useState(false);
  const [messageCount, setMessageCount] = useState(6);

  const [lessonData, setLessonData] = useState(null);
  const [isGeneratingTopicImage, setIsGeneratingTopicImage] = useState(false);
  const [isGeneratingParticipantImages, setIsGeneratingParticipantImages] = useState(false);
  const [progress, setProgress] = useState(0);
  const [generatedImages, setGeneratedImages] = useState(new Set());
  const { showSnackbar } = useSnackbar();
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageInstructions, setImageInstructions] = useState('');
  const [imageGenerationType, setImageGenerationType] = useState(null);
  const [openAiInstructions, setOpenAiInstructions] = useState('');

  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [titleText, setTitleText] = useState('Konversationslektion');
  const [grade, setGrade] = useState('åk6');
  const [showTranslation, setShowTranslation] = useState(false);
  const [translationLanguage, setTranslationLanguage] = useState('spanska');

  const [editingMessageId, setEditingMessageId] = useState(null);
  const [editingTranslationId, setEditingTranslationId] = useState(null);

  const [selectedParticipantId, setSelectedParticipantId] = useState(null);
  const [loadingImageIndex, setLoadingImageIndex] = useState(null);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);

  const [lessonTheme, setLessonTheme] = useState('generateWithAi');
  const [predefinedTheme, setPredefinedTheme] = useState(null);
  const lessonTitleRef = useRef(null); // Add this ref
  const transcriptRef = useRef('');
  const uploadedFileRef = useRef('');

  const wikipediaContentRef = useRef('');
  const randomizedContentRef = useRef('');
  const coursePlanContentRef = useRef('');

  const topicSectionRef = useRef(null);
  const factsSectionRef = useRef(null);

  const getAdditionalThemes = lessonType => {
    switch (lessonType) {
      case 'ConversationLessonVariantInterview.js':
        return [
          { type: 'subheader', label: 'Teman som lämpar sig extra bra för denna lektionstyp:' },
          {
            value: 'football',
            label: 'Intervju med fotbollsproffs',
            content: 'Simulera en realistisk intervju med fotbollsproffs.',
            Icon: SportsSoccerIcon,
          },
          {
            value: 'polis',
            label: 'Intervju med polis',
            content: 'Simulera en realistisk intervju med polis.',
            Icon: LocalPoliceIcon,
          },
          {
            value: 'mariecurie',
            label: 'Intervju med Marie Curie',
            content: 'Simulera en realistisk intervju med Marie Curie.',
            Icon: ScienceIcon,
          },
          {
            value: 'astronaut',
            label: 'Intervju med astronaut',
            content:
              'Simulera en realistisk intervju med en astronaut som berättar om livet i rymden.',
            Icon: RocketLaunchIcon,
          },
          {
            value: 'artist',
            label: 'Intervju med konstnär',
            content:
              'Simulera en realistisk intervju med en framgångsrik konstnär om kreativitet och inspiration.',
            Icon: PaletteIcon,
          },
          {
            value: 'chef',
            label: 'Intervju med kock',
            content:
              'Simulera en realistisk intervju med en prisbelönt kock om matlagning och restaurangbranschen.',
            Icon: RestaurantIcon,
          },
          {
            value: 'environmentalist',
            label: 'Intervju med miljöaktivist',
            content:
              'Simulera en realistisk intervju med en miljöaktivist om klimatfrågor och hållbarhet.',
            Icon: NatureIcon,
          },
          {
            value: 'teacher',
            label: 'Intervju med lärare',
            content:
              'Simulera en realistisk intervju med en erfaren lärare om utbildning och pedagogik.',
            Icon: SchoolIcon,
          },
          {
            value: 'author',
            label: 'Intervju med författare',
            content:
              'Simulera en realistisk intervju med en känd författare om skrivande och litteratur.',
            Icon: MenuBookIcon,
          },
          {
            value: 'doctor',
            label: 'Intervju med läkare',
            content:
              'Simulera en realistisk intervju med en läkare om hälsovård och medicinska framsteg.',
            Icon: LocalHospitalIcon,
          },
          {
            value: 'archaeologist',
            label: 'Intervju med arkeolog',
            content: 'Simulera en realistisk intervju med en arkeolog om historiska upptäckter.',
            Icon: ArchitectureIcon,
          },
          {
            value: 'musician',
            label: 'Intervju med musiker',
            content:
              'Simulera en realistisk intervju med en framgångsrik musiker om musik och kreativt skapande.',
            Icon: MusicNoteIcon,
          },
          {
            value: 'pilot',
            label: 'Intervju med pilot',
            content:
              'Simulera en realistisk intervju med en erfaren pilot om flygning och luftfart.',
            Icon: FlightIcon,
          },
        ];

      default:
        return [
          { type: 'subheader', label: 'Teman som lämpar sig extra bra för denna lektionstyp:' },
          {
            value: 'restaurant',
            label: 'Restaurangbesök',
            content:
              'Simulera en realistisk konversation på en restaurang. Den kan inkludera att beställa mat, fråga om menyn, specialkost, eller betala notan. Använd naturliga fraser som används i restaurangmiljö',
            Icon: RestaurantIcon,
          },
          {
            value: 'hairdresser',
            label: 'Frisör',
            content:
              'Simulera en konversation hos frisören. Den kan inkludera att boka tid, diskutera frisyrer, hårbehandlingar eller betalning',
            Icon: ContentCutIcon,
          },
          {
            value: 'grocery',
            label: 'I mataffären',
            content:
              'Simulera en konversation i mataffären. Den kan handla om att hitta varor, fråga om priser, erbjudanden, eller färskvaror. Gör dialogen praktisk och vardagsnära',
            Icon: LocalGroceryStoreIcon,
          },
          {
            value: 'publicTransport',
            label: 'Kollektivtrafik',
            content:
              'Simulera en konversation om kollektivtrafik. Det kan vara vid biljettköp, på bussen/tåget eller vid en hållplats. Inkludera vanliga frågor om avgångar, priser eller rutter',
            Icon: DirectionsBusIcon,
          },
          {
            value: 'doctor',
            label: 'Läkarbesök',
            content:
              'Simulera ett läkarbesök med fokus på vanliga symptom och enkel vård. Håll samtalet enkelt och undvik komplexa medicinska termer',
            Icon: LocalHospitalIcon,
          },
          {
            value: 'shopping',
            label: 'Klädbutik',
            content:
              'Simulera ett besök i en klädbutik. Inkludera konversationer om storlekar, priser, material eller att prova kläder. Håll dialogen praktisk och vardaglig',
            Icon: ShoppingCartIcon,
          },
          {
            value: 'phoneCall',
            label: 'Telefonsamtal',
            content:
              'Simulera ett telefonsamtal i vardaglig situation. Det kan vara att boka tid, beställa något eller prata med en vän. Fokusera på tydlig kommunikation',
            Icon: PhoneIcon,
          },
          {
            value: 'hotel',
            label: 'Hotellbokning',
            content:
              'Simulera en konversation vid hotellbokning eller incheckning. Inkludera frågor om rum, faciliteter, priser eller service',
            Icon: HotelIcon,
          },
          {
            value: 'cafe',
            label: 'På caféet',
            content:
              'Simulera ett cafébesök med beställning av dryck och fika. Inkludera vanliga fraser vid beställning, specialönskemål eller betalning',
            Icon: LocalCafeIcon,
          },
          {
            value: 'library',
            label: 'Biblioteket',
            content:
              'Simulera en konversation på ett bibliotek. Det kan handla om att låna/lämna böcker, hitta material eller få hjälp av bibliotekarien',
            Icon: LibraryIcon,
          },
          {
            value: 'cinema',
            label: 'Biograf',
            content:
              'Simulera en konversation på bio. Inkludera biljettköp, val av platser, snacks eller diskussion om filmer',
            Icon: MovieIcon,
          },
          {
            value: 'airport',
            label: 'På flygplatsen',
            content:
              'Simulera en slumpmässig konversation på en flygplats. Den kan utspela sig vid incheckningen, säkerhetskontrollen, gate-området eller bagagehämtningen. Fokusera på realistiska interaktioner som kan uppstå i dessa situationer, som att checka in bagage, visa boardingkort, fråga om förseningar eller hitta rätt gate',
            Icon: FlightIcon,
          },
          {
            value: 'gym',
            label: 'På gymmet',
            content:
              'Simulera en konversation på ett gym. Det kan handla om medlemskap, träningsråd eller användning av utrustning',
            Icon: SportsIcon,
          },
          {
            value: 'school',
            label: 'I klassrummet',
            content:
              'Simulera en konversation i skolmiljö. Fokusera på vardagliga situationer mellan elever eller mellan elev och lärare',
            Icon: SchoolIcon,
          },
          {
            value: 'store',
            label: 'I butiken',
            content:
              'Simulera en konversation i en butik. Inkludera kundbetjäning, produktfrågor eller köp/retur av varor',
            Icon: StoreIcon,
          },
        ];
    }
  };

  const LESSON_VARIANTS = {
    default: {
      title: 'Konversation',
      lessonType: 'ConversationLesson.js',
      icon: ChatIcon,
    },
    interview: {
      title: 'Intervju',
      lessonType: 'ConversationLessonVariantInterview.js',
      icon: MicExternalOnIcon,
    },
  };

  const [searchParams] = useSearchParams();
  const variant = searchParams.get('type') || 'default';
  const lessonConfig = LESSON_VARIANTS[variant] || LESSON_VARIANTS.default;
  const additionalThemes = getAdditionalThemes(lessonConfig.lessonType);

  useEffect(() => {
    Logger.log('lessonTheme: ', lessonTheme);
  }, [lessonTheme]);

  useEffect(() => {
    Logger.log('predefinedTheme: ', predefinedTheme);
  }, [predefinedTheme]);

  useEffect(() => {
    Logger.log('customTheme: ', customTheme);
  }, [customTheme]);

  useEffect(() => {
    Logger.log('youtubeTranscript: ', transcriptRef.current);
  }, [transcriptRef.current]);

  useEffect(() => {
    Logger.log('wikipediaContentRef: ', wikipediaContentRef.current);
  }, [wikipediaContentRef.current]);
  useEffect(() => {
    Logger.log('uploadedFileRef: ', uploadedFileRef.current);
  }, [uploadedFileRef.current]);

  useEffect(() => {
    Logger.log('randomizedContentRef: ', randomizedContentRef.current);
  }, [randomizedContentRef.current]);

  useEffect(() => {
    Logger.log('coursePlanContentRef: ', coursePlanContentRef.current);
  }, [coursePlanContentRef.current]);

  const handleSaveLessonClick = () => {
    setSaveDialogOpen(true);
  };

  const handleSaveWithComment = async (comment, name, coverImage, tags) => {
    Logger.log('handleSaveWithComment: ', JSON.stringify(lessonData, null, 2));
    await trackLesson(
      user.uid,
      lessonConfig.lessonType,
      lessonData,
      null,
      true,
      comment,
      name,
      coverImage,
      tags
    );
    setDrawerOpen(true);
    showSnackbar('Lektionen sparad i din lektionsbank!', 'success');
  };

  const handleGenerateSpecificImage = async () => {
    handleCloseModal();
    if (imageGenerationType === 'topic') {
      setIsGeneratingTopicImage(true);
      try {
        const prompt = `Skapa en bild som representerar ämnet ${lessonData.topic}. ${imageInstructions}`;
        const imageUrl = await generateAndProcessImage(prompt, user.uid);
        setLessonData(prevData => ({
          ...prevData,
          topicImage: imageUrl,
        }));
        showSnackbar('Ämnesbild genererad framgångsrikt.', 'success');
      } catch (error) {
        showSnackbar('Misslyckades med att generera ämnesbild. Försök igen.', 'error');
      } finally {
        setIsGeneratingTopicImage(false);
      }
    } else if (imageGenerationType === 'participants' && selectedParticipantId) {
      const participant = lessonData.participants[selectedParticipantId];
      setIsGeneratingParticipantImages(true);
      setLoadingImageIndex(selectedParticipantId);
      try {
        const prompt = `Skapa en porträttbild som representerar ${participant.name}, ${participant.role}. Bilden ska reflektera deras karakteristik: ${participant.characteristics}. ${imageInstructions}`;
        const imageUrl = await generateAndProcessImage(prompt, user.uid);

        setLessonData(prevData => ({
          ...prevData,
          participants: {
            ...prevData.participants,
            [selectedParticipantId]: {
              ...prevData.participants[selectedParticipantId],
              image: imageUrl,
            },
          },
        }));
        showSnackbar('Deltagarbild har genererats!', 'success');
      } catch (error) {
        showSnackbar('Fel vid generering av deltagarbild.', 'error');
      } finally {
        setIsGeneratingParticipantImages(false);
        setLoadingImageIndex(null);
        setSelectedParticipantId(null);
      }
    }
  };

  const handleClearText = (index, type = 'message') => {
    setLessonData(prevData => ({
      ...prevData,
      conversation: prevData.conversation.map((msg, i) =>
        i === index ? { ...msg, [type]: '' } : msg
      ),
    }));
    showSnackbar(`${type === 'message' ? 'Meddelandet' : 'Översättningen'} har tömts`, 'success');
  };

  const handleEditMessage = (index, newText, type = 'message') => {
    setLessonData(prevData => ({
      ...prevData,
      conversation: prevData.conversation.map((msg, i) =>
        i === index ? { ...msg, [type]: newText } : msg
      ),
    }));
    type === 'message' ? setEditingMessageId(null) : setEditingTranslationId(null);
    showSnackbar(
      `${type === 'message' ? 'Meddelandet' : 'Översättningen'} har uppdaterats`,
      'success'
    );
  };

  const toneOptions = [
    { value: 'lekfull', label: 'Lekfull' },
    { value: 'akademisk', label: 'Akademisk' },
    { value: 'informell', label: 'Informell' },
    { value: 'formell', label: 'Formell' },
    { value: 'debatt', label: 'Debatt' },
    { value: 'neutral', label: 'Neutral' },
  ];

  const handleImageButtonClick = type => {
    setImageGenerationType(type);
    setShowImageModal(true);
  };

  const handleCloseModal = () => {
    setShowImageModal(false);
    setImageInstructions('');
  };

  const handleGenerateWithInstructions = async () => {
    handleCloseModal();
    if (imageGenerationType === 'topic') {
      await handleGenerateTopicImage();
    } else if (imageGenerationType === 'participants') {
      await handleGenerateParticipantImages();
    }
  };

  const simulateProgress = (startProgress, endProgress, duration, setProgressFn) => {
    const stepSize = 0.5;
    const steps = (endProgress - startProgress) / stepSize;
    const stepDuration = duration / steps;
    let currentProgress = startProgress;

    const intervalId = setInterval(() => {
      currentProgress += stepSize;
      if (currentProgress >= endProgress) {
        clearInterval(intervalId);
      } else {
        setProgressFn(currentProgress);
      }
    }, stepDuration);

    return () => clearInterval(intervalId);
  };

  const handleGenerateConversation = async () => {
    setIsGenerating(true);
    setProgress(0);
    let stopProgressSimulation = null;
    try {
      stopProgressSimulation = simulateProgress(0, 90, 15000, setProgress);

      let themeToUse;

      if (lessonTheme === 'youtube') {
        themeToUse = transcriptRef.current;
      } else if (lessonTheme === 'customTheme') {
        themeToUse = customTheme;
      } else if (lessonTheme === 'predefined') {
        themeToUse = predefinedTheme;
      } else if (lessonTheme === 'fileUpload') {
        themeToUse = uploadedFileRef.current;
      } else if (lessonTheme === 'wikipedia') {
        themeToUse = wikipediaContentRef.current;
      } else if (lessonTheme === 'randomizer') {
        themeToUse = randomizedContentRef.current;
      } else {
        //
        themeToUse = coursePlanContentRef.current;
      }

      Logger.log('themeToUse: ', themeToUse);

      const gradeInstructionsText = gradeInstructions[grade];

      const response = await callApi(
        [
          {
            role: 'system',
            content: `You are an assistant that generates engaging educational conversations between multiple participants. The conversation should be natural and flow well while maintaining the specified tone. ${openAiInstructions}. ${gradeInstructionsText}.`,
          },
          {
            role: 'user',
            content: `Generate a JSON object with a conversation between ${participantCount} participants about ${themeToUse} in "${language}"${showTranslation ? ` with translations in "${translationLanguage}"` : ''}. The conversation should have ${messageCount} messages and be in a ${conversationTone} tone.

      Include characteristics for each participant that influences their perspective in the conversation.
      
      Example response:
{
  "topic": "Klimatförändringar",
  "topicImage": null,
  "title": "Dialog om klimatförändringarnas påverkan",
  "participants": {
    "participant1": {
      "name": "Maria",
      "role": "Klimatforskare",
      "characteristics": "Faktadriven och analytisk",
      "image": null
    },
    "participant2": {
      "name": "Erik",
      "role": "Miljöaktivist",
      "characteristics": "Passionerad och handlingsinriktad",
      "image": null
    }
  },
 "conversation": [
    {
      "speaker": "Maria",
      "message": "Enligt de senaste forskningsrapporterna ser vi en alarmerande ökning av den globala medeltemperaturen"
      ${showTranslation ? `,"translation": "Översättning på ${translationLanguage}"` : ''}
    },
    {
      "speaker": "Erik",
      "message": "Det är precis vad vi har varnat för! Vi måste agera nu innan det är för sent"
      ${showTranslation ? `,"translation": "Översättning på ${translationLanguage}"` : ''}
    }
  ]
}`,
          },
        ],
        8000,
        true,
        user.uid
      );

      if (stopProgressSimulation) {
        stopProgressSimulation();
      }

      setProgress(95);

      let messageContent = response.data.choices[0].message.content.trim();
      if (messageContent.startsWith('```json')) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith('```')) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const data = JSON.parse(messageContent);
      setProgress(100);
      await new Promise(resolve => setTimeout(resolve, 800));

      setLessonData(data);
      setTitleText(data.title);
      showSnackbar('Konversationslektionen har genererats!', 'success');

      await trackLesson(user.uid, lessonConfig.lessonType, null, {
        participantCount,
        language,
        showTranslation,
        translationLanguage,
        themeToUse,
      });
    } catch (error) {
      console.error('Error generating conversation:', error);
      showSnackbar('Något gick fel. Försök igen.', 'error');
    } finally {
      setIsGenerating(false);
    }
  };

  const handleGenerateTopicImage = async () => {
    setIsGeneratingTopicImage(true);
    try {
      const prompt = `Skapa en bild som representerar ämnet ${lessonData.topic}. ${imageInstructions}`;
      const imageUrl = await generateAndProcessImage(prompt, user.uid);
      setLessonData(prevData => ({
        ...prevData,
        topicImage: imageUrl,
      }));
      showSnackbar('Ämnesbild genererad framgångsrikt.', 'success');
    } catch (error) {
      showSnackbar('Misslyckades med att generera ämnesbild. Försök igen.', 'error');
    } finally {
      setIsGeneratingTopicImage(false);
    }
  };

  const handleGenerateParticipantImages = async () => {
    setIsGeneratingParticipantImages(true);
    setGeneratedImages(new Set());
    try {
      for (const [id, participant] of Object.entries(lessonData.participants)) {
        try {
          const prompt = `Skapa en porträttbild som representerar ${participant.name}, ${participant.role}. Bilden ska reflektera deras karakteristik: ${participant.characteristics}. ${imageInstructions}`;
          const imageUrl = await generateAndProcessImage(prompt, user.uid);

          setLessonData(prevData => ({
            ...prevData,
            participants: {
              ...prevData.participants,
              [id]: {
                ...prevData.participants[id],
                image: imageUrl,
              },
            },
          }));

          setGeneratedImages(prev => new Set([...prev, id]));
        } catch (error) {
          console.error(`Error generating image for participant "${id}":`, error);
          continue;
        }
      }
      showSnackbar('Alla deltagarbilder har genererats!', 'success');
    } catch (error) {
      showSnackbar('Fel vid generering av deltagarbilder.', 'error');
    } finally {
      setIsGeneratingParticipantImages(false);
    }
  };

  const isGeneratingAnyImages = isGeneratingTopicImage || isGeneratingParticipantImages;

  return (
    <Box sx={styles.container}>
      <Box
        sx={{
          ...styles.innerContainer,
          width: { xs: '100%', md: '87%' },
          margin: '0 auto',
        }}
      >
        <Typography
          variant='h1'
          gutterBottom
          sx={{ fontWeight: 'bold', fontSize: '1.8em', mb: 3, mt: 5, textAlign: 'center' }}
        >
          {lessonConfig.title}
        </Typography>

        <LessonDrawer
          userId={user.uid}
          lessonType={lessonConfig.lessonType}
          theme={theme}
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          lessonIcon={ChatIcon}
          onSelectLesson={lesson => {
            setLessonData(lesson.data);

            setDrawerOpen(false);

            showSnackbar(`Lektionen har laddats in.`, 'success');
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              });
            }, 100);
          }}
        />
        <Box sx={{ ...styles.buttonsContainer, ...styles.additionalButtonsContainer }}>
          <Tooltip title='Dina sparade lektioner'>
            <Button
              variant='contained'
              color='primary'
              disableRipple
              onClick={() => setDrawerOpen(true)}
              startIcon={<HistoryIcon />}
              sx={{
                padding: '8px',
                marginRight: '0px',
                paddingLeft: '14px',
                paddingRight: '3px',
                minWidth: 'auto',
                textTransform: 'none',
              }}
            />
          </Tooltip>
        </Box>

        <InfoBox
          icon={lessonConfig.icon}
          title='Instruktioner för Konversations Generator'
          instructions={[
            'Välj antal deltagare i konversationen',
            'Ange samtalets tema och ton och simulera en konversation',
            'Generera bilder för tema och deltagare',
          ]}
          theme={theme}
          styles={styles}
        />
        <ThemeSelectorFlow
          key={variant}
          value={lessonTheme}
          onChange={e => setLessonTheme(e.target.value)}
          customTheme={customTheme}
          onCustomThemeChange={e => setCustomTheme(e.target.value)}
          onPredefinedThemeChange={e => setPredefinedTheme(e.target.value)}
          onYoutubeTranscriptChange={transcriptContent => {
            Logger.log('onYoutubeTranscriptChange körs med: ', transcriptContent);
            transcriptRef.current = transcriptContent;
          }}
          onFileUpload={content => {
            Logger.log('onFileUpload körs med: ', content);
            uploadedFileRef.current = content;
          }}
          onWikipediaContentChange={content => {
            Logger.log('Parent: Wikipedia content received', content.substring(0, 100));
            wikipediaContentRef.current = content;
          }}
          onRandomizedContentChange={content => {
            Logger.log('randomizedContentRef', content.substring(0, 100));
            randomizedContentRef.current = content;
          }}
          onCoursePlanContentChange={content => {
            Logger.log('onCoursePlanChange', content.substring(0, 100));
            coursePlanContentRef.current = content;
          }}
          lessonType={lessonConfig.lessonType}
          user={user}
          theme={theme}
          styles={styles}
          onLessonSelect={lesson => {
            Logger.log('onLessonSelect körs');
            setLessonData(lesson.data);
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'start', // This ensures it scrolls to the top of the element
              });
            }, 100);
            showSnackbar('Lektionen har laddats in.', 'success');
          }}
          additionalThemes={additionalThemes}
        />
        <Box sx={styles.headlineLessonBox}>
          <Typography variant='h6' sx={styles.headlineLessonBoxTitle}>
            2. Generera lektionen
          </Typography>
        </Box>
        <Box sx={{ ...styles.freshBox, padding: '30px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography variant='subtitle1' sx={{ fontWeight: '500', mt: 0 }}>
                Årskurs:
              </Typography>
              <Box sx={{ mt: 1, mb: 1 }}>
                <GradeSelector grade={grade} setGrade={setGrade} />
              </Box>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography variant='subtitle1' sx={{ fontWeight: '500', mt: 1 }}>
                Antal deltagare:
              </Typography>
              <FormControl fullWidth margin='normal' sx={{ mb: 1, mt: 1 }}>
                <Select
                  value={participantCount}
                  onChange={e => setParticipantCount(e.target.value)}
                >
                  {[2, 3, 4, 5].map(num => (
                    <MenuItem key={num} value={num}>
                      {num}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography variant='subtitle1' sx={{ fontWeight: '500', mt: 1 }}>
                Antal meddelanden:
              </Typography>
              <FormControl fullWidth margin='normal' sx={{ mb: 1, mt: 1 }}>
                <Select value={messageCount} onChange={e => setMessageCount(e.target.value)}>
                  {[4, 6, 8, 10, 12].map(num => (
                    <MenuItem key={num} value={num}>
                      {num}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography variant='subtitle1' sx={{ fontWeight: '500', mt: 1 }}>
                Samtalston:
              </Typography>
              <FormControl fullWidth margin='normal' sx={{ mb: 1, mt: 1 }}>
                <Select
                  value={conversationTone}
                  onChange={e => setConversationTone(e.target.value)}
                >
                  {toneOptions.map(tone => (
                    <MenuItem key={tone.value} value={tone.value}>
                      {tone.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant='subtitle1' sx={{ fontWeight: '500', mt: 1 }}>
                Språk:
              </Typography>
              <Box sx={{ mt: 1, mb: 1 }}>
                <LanguageSelector language={language} setLanguage={setLanguage} />
              </Box>
            </Grid>

            <Grid item xs={12} md={12}>
              <FormControl component='fieldset' sx={{ mt: 1 }}>
                <Typography variant='subtitle1' sx={{ fontWeight: '500', mb: 1 }}>
                  Visa översättning för varje meddelande?
                </Typography>
                <FormControlLabel
                  control={
                    <Switch
                      checked={showTranslation}
                      onChange={e => setShowTranslation(e.target.checked)}
                      color='primary'
                    />
                  }
                  label={showTranslation ? 'Ja' : 'Nej'}
                />
              </FormControl>
            </Grid>

            {showTranslation && (
              <Grid item xs={12} md={12}>
                <Typography variant='subtitle1' sx={{ fontWeight: '500', mt: 2, mb: 1 }}>
                  Välj språk för översättning:
                </Typography>
                <LanguageSelector
                  language={translationLanguage}
                  setLanguage={setTranslationLanguage}
                />
              </Grid>
            )}

            {/*
            <Grid item xs={12}>
              <Typography variant='subtitle1' sx={{ fontWeight: '500', mt: 1 }}>
                Ytterligare instruktioner till AI:
              </Typography>
              <FormControl fullWidth margin='normal' sx={{ mb: 3, mt: 1 }}>
                <TextField
                  value={openAiInstructions}
                  onChange={e => setOpenAiInstructions(e.target.value)}
                  placeholder='Ex. Fokusera på vetenskapliga aspekter'
                  variant='outlined'
                />
              </FormControl>
            </Grid>
            */}
          </Grid>

          <Button
            variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
            color='primary'
            onClick={handleGenerateConversation}
            disabled={isGenerating || isGeneratingAnyImages}
            sx={{ mt: 5 }}
            startIcon={
              !isGenerating ? (
                <GenerateIcon style={{ color: '#fff' }} />
              ) : (
                <CircularProgress style={{ color: '#fff' }} size={16} />
              )
            }
          >
            {isGenerating ? 'Genererar konversation...' : 'Generera lektion'}
          </Button>

          {isGenerating && (
            <Box sx={{ mt: 7 }}>
              <ProgressBar progress={progress} label='Genererar konversationslektion...' />
            </Box>
          )}
        </Box>
        {lessonData && (
          <>
            <Box ref={lessonTitleRef} sx={{ ...styles.headlineLessonBox, mt: 10 }}>
              <Typography variant='h6' sx={styles.headlineLessonBoxTitle}>
                3. Din lektion
              </Typography>
            </Box>
            <Box sx={{ ...styles.freshBox, padding: '30px' }}>
              <Box sx={styles.lessonControlPanel}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    px: 1,
                  }}
                >
                  <Box>
                    <Button
                      variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                      color='primary'
                      onClick={() => handleImageButtonClick('topic')}
                      disabled={isGeneratingAnyImages}
                      startIcon={
                        !isGeneratingTopicImage ? (
                          <ImageIcon style={{ color: '#fff' }} />
                        ) : (
                          <CircularProgress style={{ color: '#fff' }} size={16} />
                        )
                      }
                    >
                      {isGeneratingTopicImage ? 'Genererar...' : 'Lägg till ämnesbild'}
                    </Button>

                    <Button
                      variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                      color='primary'
                      onClick={() => handleImageButtonClick('participants')}
                      disabled={isGeneratingAnyImages}
                      sx={{ ml: 2 }}
                      startIcon={
                        !isGeneratingParticipantImages ? (
                          <ImageIcon style={{ color: '#fff' }} />
                        ) : (
                          <CircularProgress style={{ color: '#fff' }} size={16} />
                        )
                      }
                    >
                      {isGeneratingParticipantImages ? 'Genererar...' : 'Lägg till deltagarbilder'}
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      variant='print'
                      color='primary'
                      onClick={() => handlePrintConversationLesson(lessonData, titleText, theme)}
                      startIcon={<PrintIcon />}
                      sx={{ ml: 2 }}
                    >
                      Skriv ut
                    </Button>

                    <Button
                      variant='print'
                      startIcon={<SaveAsIcon />}
                      sx={{ ml: 2 }}
                      onClick={handleSaveLessonClick}
                    >
                      Spara i lektionsbank
                    </Button>
                  </Box>
                </Box>
              </Box>
              <SaveLessonDialog
                open={saveDialogOpen}
                onClose={() => setSaveDialogOpen(false)}
                onSave={handleSaveWithComment}
                theme={theme}
                lessonName={'Namn på din lektion'}
                lessonObject={lessonData}
                lessonType={lessonConfig.lessonType}
              />
              <ImageGenerationModal
                open={showImageModal}
                onClose={handleCloseModal}
                onGenerate={
                  selectedParticipantId || imageGenerationType === 'topic'
                    ? handleGenerateSpecificImage
                    : handleGenerateWithInstructions
                }
                isGenerating={isGeneratingTopicImage || isGeneratingParticipantImages}
                instructions={imageInstructions}
                setInstructions={setImageInstructions}
                generationType={imageGenerationType}
                theme={theme}
              />

              <Box sx={{ mt: 8 }}>
                {/* Title */}
                {isEditingTitle ? (
                  <TextField
                    value={titleText}
                    onChange={e => setTitleText(e.target.value)}
                    onBlur={() => {
                      setIsEditingTitle(false);
                      showSnackbar('Titeln på lektionen har uppdaterats', 'success');
                    }}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        setIsEditingTitle(false);
                        showSnackbar('Titeln på lektionen har uppdaterats', 'success');
                      }
                    }}
                    autoFocus
                    fullWidth
                    variant='standard'
                    sx={{
                      backgroundColor: 'transparent',
                      '& .MuiInputBase-input': {
                        textAlign: 'center',
                        fontWeight: 'bold',
                        fontSize: '2.6em',
                        marginBottom: '1.2rem',
                        marginTop: '1.5rem',
                      },
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                ) : (
                  <Box
                    sx={{
                      position: 'relative',
                      display: 'inline-block',
                      margin: '0 auto',
                      width: '100%',
                      mb: 7,
                      mt: 5,
                      ':hover .edit-icon': {
                        opacity: 1,
                      },
                    }}
                  >
                    <Typography
                      variant='h1'
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '2.6em',
                        cursor: 'pointer',
                        textAlign: 'center',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 2,
                      }}
                    >
                      {titleText || ' '}
                      <Box
                        component='span'
                        sx={{
                          opacity: 0,
                          transition: 'opacity 0.2s',
                          display: 'inline-flex',
                          gap: 0.5,
                        }}
                        className='edit-icon'
                      >
                        <Tooltip title='Redigera' placement='top'>
                          <IconButton size='small' onClick={() => setIsEditingTitle(true)}>
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Typography>
                  </Box>
                )}

                {/* Topic Image */}

                <Box
                  sx={{
                    mb: 6,
                    mt: 2,

                    position: 'relative',
                    width: '350px',
                    margin: '0 auto',
                  }}
                >
                  <HoverableImage
                    imageUrl={lessonData.topicImage}
                    isLoading={isGeneratingTopicImage}
                    onGenerateClick={() => {
                      setImageGenerationType('topic');
                      setShowImageModal(true);
                    }}
                    userId={user.uid}
                    onImageUpdate={newImageUrl => {
                      setLessonData(prevData => ({
                        ...prevData,
                        topicImage: newImageUrl,
                      }));
                    }}
                    height='350px'
                    showSnackbar={showSnackbar}
                    theme={theme}
                  />
                </Box>

                {/* Participant Avatars Row */}
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: 3,
                    mb: 4,
                    mt: 3,
                    flexWrap: 'wrap',
                  }}
                >
                  {Object.entries(lessonData.participants).map(([id, participant]) => (
                    <Box
                      key={id}
                      sx={{ textAlign: 'center', position: 'relative', width: '100px' }}
                    >
                      <HoverableImage
                        imageUrl={participant.image}
                        isLoading={isGeneratingParticipantImages && loadingImageIndex === id}
                        loadingIndex={loadingImageIndex}
                        currentIndex={id}
                        onGenerateClick={() => {
                          setSelectedParticipantId(id);
                          setImageGenerationType('participants');
                          setShowImageModal(true);
                        }}
                        userId={user.uid} // This comes from props.user.uid
                        onImageUpdate={newImageUrl => {
                          setLessonData(prevData => ({
                            ...prevData,
                            participants: {
                              ...prevData.participants,
                              [id]: {
                                ...prevData.participants[id],
                                image: newImageUrl,
                              },
                            },
                          }));
                        }}
                        showSnackbar={showSnackbar}
                        tinyMode={true}
                        size={100}
                        theme={theme}
                      />
                      <Typography variant='subtitle2' sx={{ fontWeight: '600', fontSize: '1rem' }}>
                        {participant.name}
                      </Typography>
                      <Typography
                        variant='caption'
                        sx={{
                          display: 'block',
                          fontWeight: '400',
                          fontSize: '0.85rem',
                          wordWrap: 'break-word', // Allows long words to be broken
                          overflowWrap: 'break-word', // Modern version of word-wrap
                          maxWidth: '100%', // Ensures text stays within container
                          whiteSpace: 'normal', // Allows text to wrap
                        }}
                      >
                        {participant.role}
                      </Typography>
                    </Box>
                  ))}
                </Box>

                {/* Conversation Messages */}
                <Box sx={{ mt: 7 }}>
                  {lessonData.conversation.map((message, index) => {
                    const participant = Object.values(lessonData.participants).find(
                      p => p.name === message.speaker
                    );

                    return (
                      <Box
                        key={index}
                        sx={{
                          display: 'flex',
                          alignItems: 'flex-start',
                          mb: 4,
                          gap: 2,
                        }}
                      >
                        <Avatar
                          src={
                            participant?.image || 'https://placehold.co/50?text=Bild&font=Raleway'
                          }
                          alt={message.speaker}
                          sx={{ width: 40, height: 40 }}
                        />
                        <Box sx={{ flex: 1 }}>
                          <Typography
                            variant='subtitle2'
                            sx={{
                              fontWeight: '600',

                              fontSize: '1rem',
                            }}
                          >
                            {message.speaker}
                          </Typography>
                          <Box
                            sx={{
                              backgroundColor: theme =>
                                theme.palette.mode === 'dark' ? '#15202b' : '#e0e0e029',
                              borderRadius: '10px',
                              padding: '15px',
                              mt: 1,
                            }}
                          >
                            {/* Message */}
                            {editingMessageId === index ? (
                              <TextField
                                fullWidth
                                multiline
                                defaultValue={message.message} // Changed from value to defaultValue
                                onBlur={e => {
                                  handleEditMessage(index, e.target.value, 'message');
                                  setEditingMessageId(null);
                                }}
                                onKeyDown={e => {
                                  if (e.key === 'Enter' && !e.shiftKey) {
                                    e.preventDefault();
                                    handleEditMessage(index, e.target.value, 'message');
                                    setEditingMessageId(null);
                                  }
                                }}
                                autoFocus
                              />
                            ) : (
                              <Typography
                                variant='body1'
                                sx={{
                                  '&:hover .controls': {
                                    opacity: 1,
                                  },
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 1,
                                }}
                              >
                                {message.message || ' '}
                                <Box
                                  component='span'
                                  className='controls'
                                  sx={{
                                    opacity: 0,
                                    transition: 'opacity 0.2s',
                                    display: 'inline-flex',
                                    gap: 0.5,
                                  }}
                                >
                                  <Voice
                                    content={message.message}
                                    fontSize={20}
                                    userId={user.uid}
                                    language={language}
                                    tooltipPlacement='top'
                                  />

                                  <Tooltip title='Redigera' placement='top'>
                                    <IconButton
                                      size='small'
                                      onClick={() => setEditingMessageId(index)}
                                    >
                                      <EditIcon fontSize='small' />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title='Töm' placement='top'>
                                    <IconButton
                                      size='small'
                                      onClick={() => handleClearText(index, 'message')}
                                    >
                                      <DeleteOutlineIcon fontSize='small' />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              </Typography>
                            )}

                            {/* Translation */}
                            {message.translation && (
                              <Box
                                sx={{
                                  mt: 1,
                                  pt: 1,
                                  borderTop: '1px solid rgba(128, 128, 128, 0.2)',
                                  fontStyle: 'italic',
                                }}
                              >
                                {editingTranslationId === index ? (
                                  <TextField
                                    fullWidth
                                    multiline
                                    defaultValue={message.translation} // Changed from value to defaultValue
                                    onBlur={e => {
                                      handleEditMessage(index, e.target.value, 'translation');
                                      setEditingTranslationId(null);
                                    }}
                                    onKeyDown={e => {
                                      if (e.key === 'Enter' && !e.shiftKey) {
                                        e.preventDefault();
                                        handleEditMessage(index, e.target.value, 'translation');
                                        setEditingTranslationId(null);
                                      }
                                    }}
                                    autoFocus
                                  />
                                ) : (
                                  <Typography
                                    variant='body1'
                                    sx={{
                                      '&:hover .controls': {
                                        opacity: 1,
                                      },
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: 1,
                                    }}
                                  >
                                    {message.translation || '...'}
                                    <Box
                                      component='span'
                                      className='controls'
                                      sx={{
                                        opacity: 0,
                                        transition: 'opacity 0.2s',
                                        display: 'inline-flex',
                                        gap: 0.5,
                                      }}
                                    >
                                      <Voice
                                        content={message.translation}
                                        fontSize={20}
                                        userId={user.uid}
                                        language={translationLanguage}
                                        tooltipPlacement='top'
                                      />

                                      <Tooltip title='Redigera' placement='top'>
                                        <IconButton
                                          size='small'
                                          onClick={() => setEditingTranslationId(index)}
                                        >
                                          <EditIcon fontSize='small' />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip title='Töm' placement='top'>
                                        <IconButton
                                          size='small'
                                          onClick={() => handleClearText(index, 'translation')}
                                        >
                                          <DeleteOutlineIcon fontSize='small' />
                                        </IconButton>
                                      </Tooltip>
                                    </Box>
                                  </Typography>
                                )}
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default ConversationLesson;
