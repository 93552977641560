import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import {
  collection,
  addDoc,
  getDocs,
  query,
  where,
  deleteDoc,
  doc,
  getDoc,
  writeBatch,
} from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { Drawer, Box, Typography, IconButton, Chip, Button, CircularProgress } from '@mui/material';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import DeleteIcon from '@mui/icons-material/Delete';
import CircleIcon from '@mui/icons-material/Circle';
import { getStyles } from '../../index'; // Adjust the import path as needed
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Logger from '../Logger';

const DrawerComponent = ({ user, theme, open, onClose }) => {
  const [quizzes, setQuizzes] = useState([]);
  const [loadingMyQuizzes, setLoadingMyQuizzes] = useState(false);
  const [isManaging, setIsManaging] = useState(false);

  const navigate = useNavigate();
  const styles = getStyles(theme);

  useEffect(() => {
    const logNonOfficialRecords = async () => {
      try {
        // const hpPublicRef = collection(db, 'hpPublic');
        // const q = query(hpPublicRef, where('official', '!=', true));
        // const querySnapshot = await getDocs(q);
        // Logger.log('Documents that would be deleted:');
        // querySnapshot.forEach((doc) => {
        //   const data = doc.data();
        //   Logger.log(`Document ID: ${doc.id}, Name: ${data.name || 'No name field'}`);
        // });
        // Logger.log(`Total documents that would be deleted: ${querySnapshot.size}`);
        // Commented out deletion code
        // const batch = writeBatch(db);
        // querySnapshot.forEach((doc) => {
        //   batch.delete(doc.ref);
        // });
        // await batch.commit();
        // Logger.log('Deleted non-official records from hpPublic');
      } catch (error) {
        console.error('Error querying non-official records:', error);
      }
    };

    logNonOfficialRecords();
  }, []); // This effect runs once when the component mounts

  useEffect(() => {
    if (open) {
      loadQuizzes();
    }
  }, [open, user.uid]);

  const loadQuizzes = async () => {
    try {
      setLoadingMyQuizzes(true);
      const q = query(collection(db, 'quiz'), where('userId', '==', user.uid));
      const querySnapshot = await getDocs(q);
      const quizzesData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));

      Logger.log('quizzesData: ', quizzesData);

      // Sort the quizzes based on lastTrainingSession
      const sortedQuizzes = quizzesData.sort(
        (a, b) => (b.lastTrainingSession || 0) - (a.lastTrainingSession || 0)
      );

      setQuizzes(sortedQuizzes);
    } catch (error) {
      console.error('Error fetching quizzes:', error);
    } finally {
      setLoadingMyQuizzes(false);
    }
  };

  const getTimeSinceLastTraining = lastTrainingSession => {
    if (!lastTrainingSession) return 'Aldrig tränat';
    const now = Date.now();
    const diff = now - lastTrainingSession;
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);

    if (seconds < 60) return `${seconds} sekund${seconds !== 1 ? 'er' : ''} sedan`;
    if (minutes < 60) return `${minutes} minut${minutes !== 1 ? 'er' : ''} sedan`;
    if (hours < 24) return `${hours} timm${hours !== 1 ? 'ar' : 'e'} sedan`;

    const days = Math.floor(hours / 24);
    if (days === 1) return 'Igår';
    if (days < 7) return `${days} dagar sedan`;
    const weeks = Math.floor(days / 7);
    if (weeks === 1) return '1 vecka sedan';
    if (weeks < 4) return `${weeks} veckor sedan`;
    const months = Math.floor(days / 30);
    if (months === 1) return '1 månad sedan';
    if (months < 12) return `${months} månader sedan`;
    const years = Math.floor(days / 365);
    return `${years} år sedan`;
  };

  const calculateResults = results => {
    if (!results || !results.some(result => result.selectedOption)) return { correct: 0, wrong: 0 };
    const totalQuestions = results.length;
    const correctAnswers = results.filter(
      result => result.selectedOption === result.correct
    ).length;
    return { correct: correctAnswers, wrong: totalQuestions - correctAnswers };
  };

  const handleDeleteQuiz = async quizId => {
    setQuizzes(prevQuizzes => prevQuizzes.filter(quiz => quiz.id !== quizId));
    try {
      await deleteDoc(doc(db, 'quiz', quizId));
    } catch (error) {
      console.error('Error deleting quiz:', error);
      const quizToRestore = quizzes.find(quiz => quiz.id === quizId);
      if (quizToRestore) {
        setQuizzes(prevQuizzes => [...prevQuizzes, quizToRestore]);
      }
    }
  };

  const renderResults = results => {
    const { correct, wrong } = calculateResults(results);
    return (
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Chip
          icon={<CheckCircleOutlineIcon />}
          label={`${correct} rätt`}
          size='small'
          variant='outlined'
          sx={{
            background: '#27792B',
            borderColor: '#27792B',
            color: 'white',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            fontSize: '12px',
            '& .MuiChip-icon': { color: 'white' },
          }}
        />
        <Chip
          icon={<CancelOutlinedIcon sx={{ color: 'white' }} />}
          label={`${wrong} fel`}
          size='small'
          variant='outlined'
          sx={{
            background: '#FF4D4D',
            borderColor: '#FF4D4D',
            color: 'white',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            fontSize: '12px',
            '& .MuiChip-icon': { color: 'white' },
          }}
        />
      </Box>
    );
  };

  const toggleManaging = () => {
    setIsManaging(!isManaging);
  };

  const formatUrlPart = str => {
    return str
      .replace(/\s+/g, '-')
      .replace(/[åä]/g, 'a')
      .replace(/[ö]/g, 'o')
      .replace(/[^a-z0-9-]/gi, '')
      .toLowerCase();
  };

  const handleQuizLoad = quiz => {
    onClose();
    Logger.log('Loading quiz: ', quiz);
    const formattedQuizName = formatUrlPart(quiz.quizName);
    navigate(`/app/quiz/${quiz.id}/${formattedQuizName}`);
  };

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={onClose}
      sx={{ width: { xs: '75%', sm: '50%', md: '30%' }, paddingTop: '0px' }}
      PaperProps={{
        sx: { width: { xs: '75%', sm: '50%', md: '30%' }, padding: '20px', paddingTop: '0px' },
      }}
    >
      <Box sx={styles.flexContainerSidebar}>
        <Typography variant='h6' sx={{ ...styles.header, ...styles.headerAboveBox }}>
          Dina quiz
        </Typography>
        <IconButton onClick={onClose} color='inherit'>
          <MenuOpenIcon />
        </IconButton>
      </Box>

      <Box sx={styles.buttonsContainer}>
        {loadingMyQuizzes ? (
          <CircularProgress size={16} sx={{ margin: 'auto' }} />
        ) : quizzes.length === 0 ? (
          <Typography sx={{ textAlign: 'center', color: 'text.secondary' }}>
            De quiz du övar på hamnar här.
          </Typography>
        ) : (
          <>
            {quizzes
              .filter(quiz => quiz.byAdmin !== 'Yes')
              .map(quiz => (
                <Box
                  key={quiz.id}
                  sx={{
                    ...styles.beautyBtnBox,
                    width: '100%',
                    position: 'relative',
                    p: 2,
                    margin: '0px',
                    cursor: 'pointer',
                    borderRadius: { xs: '5px', md: '8px' },
                  }}
                  onClick={() => (isManaging ? handleDeleteQuiz(quiz.id) : handleQuizLoad(quiz))}
                >
                  <Box
                    sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                  >
                    <Box sx={{ flex: 1 }}>
                      <Typography
                        variant='subtitle1'
                        sx={{
                          fontWeight: '500',
                          fontSize: '15px',
                          lineHeight: '24px',
                          marginBottom: '3px',
                          marginTop: '3px',
                        }}
                      >
                        {quiz.quizName}
                      </Typography>

                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <AccessTimeIcon sx={{ fontSize: '16px', mr: 1, color: 'text.secondary' }} />
                        <Typography variant='body2' color='text.secondary'>
                          {getTimeSinceLastTraining(quiz.lastTrainingSession)}
                        </Typography>
                      </Box>

                      {quiz.results && quiz.results.some(result => result.selectedOption) && (
                        <Box sx={{ mt: 1, mb: 1 }}>
                          <Typography variant='body2' color='text.secondary' sx={{ mb: 0.5 }}>
                            Senaste resultat:
                          </Typography>
                          {renderResults(quiz.results)}
                        </Box>
                      )}

                      {quiz.isCopied && (
                        <Typography
                          variant='body2'
                          color='text.secondary'
                          sx={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            fontStyle: 'italic',
                          }}
                        >
                          Kopierat
                        </Typography>
                      )}
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', height: '100%', ml: 2 }}>
                      {isManaging ? (
                        <DeleteIcon
                          style={{ color: 'red', cursor: 'pointer' }}
                          onClick={() => handleDeleteQuiz(quiz.id)}
                          className={'wiggle'}
                        />
                      ) : (
                        <ChevronRightIcon onClick={() => handleQuizLoad(quiz)} />
                      )}
                    </Box>
                  </Box>
                </Box>
              ))}
            {quizzes
              .filter(quiz => quiz.byAdmin === 'Yes')
              .map(quiz => (
                <Button
                  key={quiz.id}
                  variant='contained'
                  color='primary'
                  className={isManaging ? 'wiggle' : ''}
                  onClick={() => (isManaging ? handleDeleteQuiz(quiz.id) : handleQuizLoad(quiz))}
                  startIcon={
                    isManaging ? (
                      <DeleteIcon style={{ color: 'red' }} className={'wiggle'} />
                    ) : (
                      <CircleIcon style={{ fontSize: 10, color: '#85a5f0' }} />
                    )
                  }
                  sx={styles.beautyBtn}
                >
                  {quiz.quizName.length > 25
                    ? quiz.quizName.substring(0, 25) + '...'
                    : quiz.quizName}
                </Button>
              ))}
          </>
        )}
      </Box>

      {quizzes.length > 0 && (
        <>
          <Box>
            <Button
              variant='contained'
              color='secondary'
              onClick={toggleManaging}
              startIcon={<DeleteIcon />}
              sx={{ marginTop: '12px' }}
            >
              {isManaging ? 'Klar?' : 'Hantera'}
            </Button>
          </Box>
          <Box sx={{ fontSize: '12px', marginTop: '14px' }}>
            <CircleIcon style={{ fontSize: 10, color: '#85a5f0' }} /> Kopierad från biblioteket
          </Box>
        </>
      )}
    </Drawer>
  );
};

export default DrawerComponent;
