import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { db } from './firebase';
import {
  collection,
  addDoc,
  getDocs,
  query,
  where,
  deleteDoc,
  doc,
  updateDoc,
} from 'firebase/firestore';
import { callApi } from './api';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Logger from './Logger';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/system';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import BoltIcon from '@mui/icons-material/Bolt';
import '../App.css';
import { styles } from '../index';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import TopicIcon from '@mui/icons-material/Subject';
import { Link as LinkIcon } from '@mui/icons-material';
import {
  Card,
  CardContent,
  Drawer,
  Divider,
  Chip,
  RadioGroup,
  FormControlLabel,
  Input,
  FormControl,
  ToggleButton,
  ToggleButtonGroup,
  Radio,
  IconButton,
  InputAdornment,
} from '@mui/material';
import WikipediaIcon from './WikipediaIcon';
import SkeletonLoader from './SkeletonLoader';
import SpaceBarIcon from '@mui/icons-material/SpaceBar';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import Looks4Icon from '@mui/icons-material/Looks4';
import CircleIcon from '@mui/icons-material/Circle';
import GenerateIcon from '@mui/icons-material/AutoAwesome';
import mammoth from 'mammoth';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import AddIcon from '@mui/icons-material/Add';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FunctionsIcon from '@mui/icons-material/Functions';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import LinearProgress from '@mui/material/LinearProgress';
import { motion, AnimatePresence } from 'framer-motion';
import Select from '@mui/material/Select';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

import { Howl } from 'howler';
import selectSoundFile from './sounds/select.mp3';
import select2 from './sounds/select2.mp3';
import done from './sounds/done.mp3';

import InputLabel from '@mui/material/InputLabel';

import Tooltip from '@mui/material/Tooltip';

import { useTheme } from '@mui/material/styles';
import { getStyles } from '../index';
const apiKey = 'sk-proj-C69fw7mjS0f4DK64gRHhT3BlbkFJLhrh35xtgTCTy4UOD9yP';
const cheerio = require('cheerio');
const AnimatedPie = motion(Pie);
const WordView = ({ user }) => {
  const [wordTest, setWordTest] = useState([]);
  const [wordTests, setWordTests] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [showSummary, setShowSummary] = useState(false);
  const [numQuestions, setNumQuestions] = useState(5);
  const [difficulty, setDifficulty] = useState('medium');
  const [numOptions, setNumOptions] = useState(4);
  const [subject, setSubject] = useState('general');
  const [timePerQuestion, setTimePerQuestion] = useState(30);
  const [showCorrectAnswerImmediately, setShowCorrectAnswerImmediately] = useState(false);
  const [testName, setTestName] = useState('');
  const [isManaging, setIsManaging] = useState(false);
  const [testInProgress, setTestInProgress] = useState(false);
  const [currentTestId, setCurrentTestId] = useState(null);
  const [loadingTest, setLoadingTest] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const [timeLeft, setTimeLeft] = useState(timePerQuestion);
  const [isActive, setIsActive] = useState(false);

  const selectSound = new Howl({ src: [selectSoundFile], volume: 0.1 });
  const nextSound = new Howl({ src: [select2], volume: 0.6 });
  const doneSound = new Howl({ src: [done], volume: 0.2 });
  const AnimatedPieChart = ({ results, isVisible }) => {
    return (
      <AnimatePresence>
        {isVisible && (
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.5 }}
            transition={{ duration: 0.5 }}
          >
            <ResponsiveContainer width='100%' height={300}>
              <PieChart margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
                <Pie
                  data={[
                    { name: 'Correct', value: results.filter(result => result.isCorrect).length },
                    {
                      name: 'Incorrect',
                      value: results.filter(result => !result.isCorrect).length,
                    },
                  ]}
                  cx='50%'
                  cy='50%'
                  labelLine={false}
                  outerRadius={80}
                  fill='#8884d8'
                  dataKey='value'
                  stroke='none'
                >
                  <Cell fill='#4caf50' />
                  <Cell fill='#5e1b18' />
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </motion.div>
        )}
      </AnimatePresence>
    );
  };

  useEffect(() => {
    Logger.log('WordView rendered');
  }, []);

  const startTimer = useCallback(() => {
    setIsActive(true);
    setTimeLeft(timePerQuestion);
  }, [timePerQuestion]);

  const resetTimer = useCallback(() => {
    setIsActive(false);
    setTimeLeft(timePerQuestion);
  }, [timePerQuestion]);

  useEffect(() => {
    let interval = null;
    if (isActive && timeLeft > 0) {
      interval = setInterval(() => {
        setTimeLeft(time => time - 1);
      }, 1000);
    } else if (isActive && timeLeft === 0) {
      setIsActive(false);
      // Här kan du lägga till logik för vad som ska hända när tiden är ute
      // Till exempel, gå automatiskt till nästa fråga
      handleNextQuestion();
    }
    return () => clearInterval(interval);
  }, [isActive, timeLeft]);

  useEffect(() => {
    if (testInProgress) {
      startTimer();
    }
  }, [testInProgress, currentQuestionIndex, startTimer]);

  useEffect(() => {
    Logger.log('results: ', results);
  }, [results]);

  const theme = useTheme();
  const styles = getStyles(theme);

  useEffect(() => {
    const handleKeyDown = event => {
      if (!testInProgress) return;
      switch (event.key) {
        case '1':
        case '2':
        case '3':
        case '4':
          // Select option based on key press
          const optionIndex = parseInt(event.key) - 1;
          if (optionIndex >= 0 && optionIndex < wordTest[currentQuestionIndex].options.length) {
            handleOptionSelect(wordTest[currentQuestionIndex].options[optionIndex]);
          }
          break;
        case ' ':
        case 'Enter':
          // Move to next question with spacebar or enter
          event.preventDefault();
          if (selectedOption) {
            handleNextQuestion();
          }
          break;
        case 'ArrowUp':
        case 'ArrowDown':
          event.preventDefault();
          const currentOptions = wordTest[currentQuestionIndex].options;
          const currentIndex = selectedOption ? currentOptions.indexOf(selectedOption) : -1;
          let newIndex;
          if (event.key === 'ArrowUp') {
            newIndex = currentIndex > 0 ? currentIndex - 1 : currentOptions.length - 1;
          } else {
            newIndex = currentIndex < currentOptions.length - 1 ? currentIndex + 1 : 0;
          }
          handleOptionSelect(currentOptions[newIndex]);
          break;
        default:
          break;
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [currentQuestionIndex, wordTest, testInProgress, selectedOption]);

  const handleNewTest = () => {
    setShowForm(true);
    setTestInProgress(false);
    setShowSummary(false);
    setWordTest([]);
    setCurrentQuestionIndex(0);
    setSelectedOption(null);
    setResults([]);
    setCurrentTestId(null); // Lägg till denna rad
  };

  const toggleDrawer = open => () => {
    setDrawerOpen(open);
  };

  const loadWordTests = async () => {
    try {
      const q = query(collection(db, 'wordTests'), where('userId', '==', user.uid));
      const querySnapshot = await getDocs(q);
      const testsData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setWordTests(testsData);
    } catch (error) {
      console.error('Error fetching word tests:', error);
    }
  };

  useEffect(() => {
    loadWordTests();
  }, [user]);

  const handleInputChange = e => {
    const { name, value } = e.target;
    switch (name) {
      case 'numQuestions':
        setNumQuestions(parseInt(value));
        break;
      case 'difficulty':
        setDifficulty(value);
        break;
      case 'numOptions':
        setNumOptions(parseInt(value));
        break;
      case 'subject':
        setSubject(value);
        break;
      case 'timePerQuestion':
        setTimePerQuestion(parseInt(value));
        break;
      case 'showCorrectAnswerImmediately':
        setShowCorrectAnswerImmediately(value === 'true');
        break;
      case 'testName':
        setTestName(value);
        break;
      default:
        break;
    }
  };
  const generateWordTest = async () => {
    setLoadingTest(true);
    setCurrentTestId(null); // Lägg till denna rad
    let instructions = `Generera ett ordtest med följande specifikationer:
    - Antal frågor: ${numQuestions}
    - Svårighetsgrad: ${difficulty}
    - Antal svarsalternativ per fråga: ${numOptions}
    - Ämnesområde: ${subject}

    Varje fråga ska vara ett JSON-objekt med fälten "word" (ordet som ska testas), "options" (array med svarsalternativ), och "correct" (det korrekta svaret). 
    Alla frågor ska returneras i en enda JSON-array. 

    Format exempel: 
    [
      {
        "word": "Efemär",
        "options": ["Tillfällig", "Evig", "Solid", "Genomskinlig", "Tung"],
        "correct": "Tillfällig"
      }
    ]

    Anpassa ordförrådet och svårighetsgraden baserat på den valda svårighetsgraden och ämnesområdet.`;

    const messages = [
      { role: 'system', content: instructions },
      { role: 'user', content: 'Generera ordtest' },
    ];

    Logger.log('Meddelande till API: ', messages);

    try {
      const response = await callApi(messages, 1000);

      Logger.log('response: ', response);

      // Extract the content from the response
      let messageContent =
        typeof response === 'string' ? response : response.choices?.[0]?.message?.content;

      if (!messageContent) {
        throw new Error('Unexpected API response format');
      }

      messageContent = messageContent.trim();
      Logger.log('API-svar innehåll: ', messageContent);

      // Extract only the JSON part
      //const jsonMatch = messageContent.match(/```json\s*([\s\S]*?)\s*```/);
      if (!jsonMatch) {
        throw new Error('No JSON found in the response');
      }

      const jsonString = jsonMatch[1].trim();
      Logger.log('Extracted JSON string: ', jsonString);

      let parsedResponse;
      try {
        parsedResponse = JSON.parse(jsonString);
      } catch (parseError) {
        console.error('Fel vid parsning av JSON-svar:', parseError);
        throw new Error('Invalid JSON in API response');
      }

      Logger.log('Parsad respons: ', parsedResponse);
      setWordTest(parsedResponse);
      setTestInProgress(true);
    } catch (error) {
      console.error('Error generating word test:', error);
      // Handle the error appropriately, maybe set an error state
    } finally {
      setLoadingTest(false);
    }
  };

  const handleOptionSelect = option => {
    nextSound.play();
    setSelectedOption(option);
    const currentQuestion = wordTest[currentQuestionIndex];
    const isCorrect = option === currentQuestion.correct;
    const updatedResults = [...results];
    updatedResults[currentQuestionIndex] = {
      ...currentQuestion,
      selectedOption: option,
      isCorrect,
    };
    setResults(updatedResults);

    if (showCorrectAnswerImmediately) {
      // Show correct answer logic here
    }
  };

  const handleNextQuestion = () => {
    selectSound.play();

    if (currentQuestionIndex < wordTest.length - 1) {
      setCurrentQuestionIndex(prevIndex => prevIndex + 1);
      setSelectedOption(null);
      resetTimer();
      startTimer();
    } else {
      setShowSummary(true);
      setTestInProgress(false);
      saveResultsToDatabase(results);
    }
  };

  const saveResultsToDatabase = async resultsToSave => {
    try {
      if (currentTestId) {
        // Om det finns ett currentTestId, uppdatera det befintliga dokumentet
        await updateDoc(doc(db, 'wordTests', currentTestId), {
          results: resultsToSave,
          updatedAt: new Date(),
        });
      } else {
        // Om det inte finns ett currentTestId, skapa ett nytt dokument
        const docRef = await addDoc(collection(db, 'wordTests'), {
          userId: user.uid,
          results: resultsToSave,
          testName: testName,
          createdAt: new Date(),
          updatedAt: new Date(),
        });
        setCurrentTestId(docRef.id);
      }
      loadWordTests();
    } catch (error) {
      console.error('Error saving word test results:', error);
    }
  };

  const handleTestLoad = test => {
    setDrawerOpen(false);
    setWordTest(test.results);
    setTestName(test.testName);
    setCurrentTestId(test.id);
    setCurrentQuestionIndex(0);
    setSelectedOption(null);
    setResults([]);
    setShowSummary(false);
    setTestInProgress(true);
    doneSound.play();
  };

  const handleDeleteTest = async testId => {
    try {
      await deleteDoc(doc(db, 'wordTests', testId));
      loadWordTests();
    } catch (error) {
      console.error('Error deleting word test:', error);
    }
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.innerContainer}>
        <Typography variant='h1' sx={styles.header}>
          Word Test
        </Typography>

        <Box
          sx={{
            ...styles.buttonsContainer,
            marginTop: '15px',
            marginBottom: '0px',
            position: 'absolute',
            right: '88px',
            top: '5px',
            borderBottom: '0px',
          }}
        >
          <Button
            variant='contained'
            color='primary'
            onClick={handleNewTest}
            startIcon={<AddIcon />}
          >
            Nytt test
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={toggleDrawer(true)}
            startIcon={<LibraryBooksIcon />}
          >
            Historik
          </Button>
        </Box>
        <Drawer
          anchor='right'
          open={drawerOpen}
          onClose={toggleDrawer(false)}
          PaperProps={{ sx: { width: { xs: '75%', sm: '50%', md: '30%' }, padding: '20px' } }}
        >
          <Box sx={styles.flexContainerSidebar}>
            <Typography variant='h6' sx={styles.header}>
              Ditt arkiv
            </Typography>
            <IconButton onClick={toggleDrawer(false)} color='inherit'>
              <MenuOpenIcon />
            </IconButton>
          </Box>
          <Box sx={{ marginTop: '20px' }}>
            <Box sx={styles.buttonsContainer}>
              {wordTests.map(test => (
                <Button
                  key={test.id}
                  variant='contained'
                  color='primary'
                  className={isManaging ? 'wiggle' : ''}
                  onClick={() => (isManaging ? handleDeleteTest(test.id) : handleTestLoad(test))}
                  startIcon={
                    isManaging ? (
                      <DeleteIcon style={{ color: 'red', fontSize: '16px' }} />
                    ) : (
                      <CircleIcon
                        style={{
                          fontSize: collection.isCopied ? 10 : 0,
                          color: collection.isCopied ? '#85a5f0' : '',
                        }}
                      />
                    )
                  }
                  sx={styles.beautyBtn}
                >
                  {test.testName}
                </Button>
              ))}
            </Box>
          </Box>
          {wordTests.length > 0 && (
            <Button
              variant='contained'
              color='secondary'
              onClick={() => setIsManaging(!isManaging)}
              startIcon={<DeleteIcon />}
              sx={{ marginTop: '12px' }}
            >
              {isManaging ? 'Klar?' : 'Hantera'}
            </Button>
          )}
        </Drawer>

        {!testInProgress && !showSummary && (
          <Box sx={styles.formContainer}>
            <Typography variant='h4' sx={styles.header}>
              Create Word Test
            </Typography>

            <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <InputLabel id='num-questions-label'>Number of Questions</InputLabel>
              <Select
                labelId='num-questions-label'
                id='num-questions-select'
                value={numQuestions}
                label='Number of Questions'
                onChange={handleInputChange}
                name='numQuestions'
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={15}>15</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <InputLabel id='difficulty-label'>Difficulty</InputLabel>
              <Select
                labelId='difficulty-label'
                id='difficulty-select'
                value={difficulty}
                label='Difficulty'
                onChange={handleInputChange}
                name='difficulty'
              >
                <MenuItem value='easy'>Easy</MenuItem>
                <MenuItem value='medium'>Medium</MenuItem>
                <MenuItem value='hard'>Hard</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <InputLabel id='num-options-label'>Number of Options</InputLabel>
              <Select
                labelId='num-options-label'
                id='num-options-select'
                value={numOptions}
                label='Number of Options'
                onChange={handleInputChange}
                name='numOptions'
              >
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <InputLabel id='subject-label'>Subject Area</InputLabel>
              <Select
                labelId='subject-label'
                id='subject-select'
                value={subject}
                label='Subject Area'
                onChange={handleInputChange}
                name='subject'
              >
                <MenuItem value='general'>General</MenuItem>
                <MenuItem value='academic'>Academic</MenuItem>
                <MenuItem value='business'>Business</MenuItem>
              </Select>
            </FormControl>

            <TextField
              label='Time per Question (seconds)'
              name='timePerQuestion'
              type='number'
              value={timePerQuestion}
              onChange={handleInputChange}
              variant='outlined'
              fullWidth
              sx={{ marginBottom: 2 }}
            />

            <FormControl component='fieldset' sx={{ marginBottom: 2 }}>
              <Typography variant='body1'>Show Correct Answer Immediately:</Typography>
              <RadioGroup
                row
                name='showCorrectAnswerImmediately'
                value={showCorrectAnswerImmediately.toString()}
                onChange={handleInputChange}
              >
                <FormControlLabel value='false' control={<Radio />} label='No' />
                <FormControlLabel value='true' control={<Radio />} label='Yes' />
              </RadioGroup>
            </FormControl>

            <TextField
              label='Test Name'
              name='testName'
              value={testName}
              onChange={handleInputChange}
              variant='outlined'
              fullWidth
              sx={{ marginBottom: 2 }}
            />

            <Button
              variant='contained'
              color='primary'
              onClick={generateWordTest}
              disabled={loadingTest}
              startIcon={loadingTest ? <CircularProgress size={18} /> : <GenerateIcon />}
            >
              {loadingTest ? 'Generating Word Test...' : 'Generate Word Test'}
            </Button>
          </Box>
        )}

        {wordTest.length > 0 && testInProgress && (
          <Box
            sx={{
              ...styles.testContainer,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            <LinearProgress
              variant='determinate'
              value={(currentQuestionIndex / wordTest.length) * 100}
              sx={{
                height: 10,
                borderRadius: 5,
                marginBottom: 2,
                width: '100%',
                '& .MuiLinearProgress-bar': {
                  borderRadius: 5,
                  backgroundColor: '#8b5cf6',
                  transition: 'transform 0.5s ease-in-out',
                },
              }}
            />

            <Typography variant='h2' style={styles.h2}>
              {testName}
            </Typography>
            <Typography variant='h3' style={styles.h3}>
              {wordTest[currentQuestionIndex].word}
            </Typography>
            <Typography variant='h4' style={styles.h4}>
              Question {currentQuestionIndex + 1} of {wordTest.length}
            </Typography>

            <Box sx={{ position: 'relative', display: 'inline-flex', marginBottom: 2 }}>
              <CircularProgress
                variant='determinate'
                value={(timeLeft / timePerQuestion) * 100}
                size={80}
              />
              <Box
                sx={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography variant='caption' component='div' color='text.secondary'>
                  {timeLeft}s
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}
            >
              {wordTest[currentQuestionIndex].options.map((option, index) => (
                <OptionButton
                  key={index}
                  variant={selectedOption === option ? 'contained' : 'outlined'}
                  onClick={() => handleOptionSelect(option)}
                  fullWidth
                  sx={{ marginBottom: 1 }}
                >
                  {[<LooksOneIcon />, <LooksTwoIcon />, <Looks3Icon />, <Looks4Icon />][index]}
                  {option}
                </OptionButton>
              ))}
            </Box>

            <CenteredButton
              variant='contained'
              color='secondary'
              onClick={handleNextQuestion}
              disabled={!selectedOption}
              endIcon={<SpaceBarIcon />}
            >
              Next
            </CenteredButton>
          </Box>
        )}

        {showSummary && (
          <Box sx={styles.summaryContainer}>
            <Typography variant='h6'>Resultat</Typography>

            <Card sx={{ minWidth: 275, marginBottom: 2 }}>
              <CardContent>
                <Typography variant='h6' component='div'>
                  Översikt
                </Typography>
                <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                  Test: {testName}
                </Typography>
                <Divider sx={{ my: 2 }} />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                  }}
                >
                  <Box sx={{ textAlign: 'center' }}>
                    <Typography variant='h6' sx={styles.h6}>
                      {results.filter(result => result.isCorrect).length} / {results.length}
                    </Typography>
                    <Typography color='text.secondary'>Korrekta svar</Typography>
                  </Box>
                  <Box sx={{ textAlign: 'center' }}>
                    <Typography variant='h6'>
                      {(
                        (results.filter(result => result.isCorrect).length / results.length) *
                        100
                      ).toFixed(1)}
                      %
                    </Typography>
                    <Typography color='text.secondary'>Precision</Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>

            <AnimatedPieChart results={results} isVisible={true} />

            <Typography variant='h6' sx={styles.h6}>
              Detaljerade resultat
            </Typography>
            {results.map((result, index) => (
              <Card
                key={index}
                sx={{
                  mb: 2,
                  backgroundColor: result.isCorrect ? '#21374d' : '#21374d',
                  borderRadius: '10px',
                  position: 'relative',
                }}
              >
                <CardContent>
                  <Typography variant='h6'>{result.word}</Typography>
                  <Typography variant='body2'>Ditt svar: {result.selectedOption}</Typography>
                  <Typography variant='body2'>Korrekt svar: {result.correct}</Typography>
                  <Divider sx={{ my: 1 }} />
                  <Typography variant='body2'>Alla alternativ:</Typography>
                  {wordTest[index].options.map((option, optionIndex) => (
                    <Chip
                      key={optionIndex}
                      label={option}
                      color={
                        option === result.correct
                          ? 'success'
                          : option === result.selectedOption
                            ? 'error'
                            : 'default'
                      }
                      variant={
                        option === result.selectedOption || option === result.correct
                          ? 'filled'
                          : 'outlined'
                      }
                      sx={{ m: 0.5 }}
                    />
                  ))}
                  <Chip
                    icon={result.isCorrect ? <CheckCircleIcon /> : <CancelIcon />}
                    label={result.isCorrect ? 'Rätt' : 'Fel'}
                    color={result.isCorrect ? 'success' : 'error'}
                    sx={{
                      mt: 1,
                      position: 'absolute',
                      right: '15px',
                      top: '10px',
                      borderRadius: '10px',
                    }}
                  />
                </CardContent>
              </Card>
            ))}

            <Button
              variant='contained'
              color='primary'
              onClick={handleNewTest}
              sx={styles.newTestButton}
            >
              New Test
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const CenteredButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  margin: '0 auto',
  textAlign: 'center',
  marginTop: '20px',
  backgroundColor: theme.palette.mode === 'dark' ? '#3a4b65' : '#000', // Använd en mörkare blågrå nyans för mörkt tema
  fontWeight: 'bold',
  color: theme.palette.mode === 'dark' ? '#ffffff' : '#ffffff', // Vit text för båda teman
  borderColor: theme.palette.mode === 'dark' ? '#5a6a8a' : '#28146c', // Använd en något ljusare blå för mörkt tema
  width: '15%',
  padding: '10px',
  '&:hover': {
    borderColor: theme.palette.mode === 'dark' ? '#2d3a4d' : '#000', // Mörkare blågrå vid hover
    color: '#ffffff', // Vit text vid hover
    backgroundColor: theme.palette.mode === 'dark' ? '#5a6a8a' : '#28146c', // Ljusa blågrå för hover i mörkt tema
  },
}));

const OptionButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
  width: '45%',
  textTransform: 'none',
  backgroundColor: theme.palette.mode === 'dark' ? '#2a3b4c' : '#ffffff', // Använd en mörk blågrå nyans för mörkt tema
  color: theme.palette.mode === 'dark' ? '#e0e0e0' : '#000000', // Ljusgrå text för mörkt tema och svart för ljust tema
  fontSize: '1rem',
  borderRadius: '10px',
  borderColor: theme.palette.mode === 'dark' ? '#4a5d6b' : '#eaeaea', // Mörkare blågrå för mörkt tema
  display: 'flex',
  justifyContent: 'left',
  textAlign: 'left',
  padding: theme.spacing(1.5),
  '& .MuiSvgIcon-root': {
    color: theme.palette.mode === 'dark' ? '#1A91DA' : '#8b5cf6',
  },

  '&:active': {
    color: theme.palette.mode === 'dark' ? '#1A91DA' : '#8b5cf6', // Lila text vid fokus
    backgroundColor: theme.palette.mode === 'dark' ? '#3a4b65' : '#eee9ff', // Mörkare blågrå vid fokus i mörkt tema
  },

  '&:focus': {
    outline: 'none',
    color: theme.palette.mode === 'dark' ? '#1A91DA' : '#8b5cf6', // Lila text vid fokus
    backgroundColor: theme.palette.mode === 'dark' ? '#3a4b65' : '#eee9ff', // Mörkare blågrå vid fokus i mörkt tema
  },
  '&.MuiButton-contained': {
    outline: 'none',
    backgroundColor: theme.palette.mode === 'dark' ? '#1097da' : '#28146c', // Mörkare blågrå för mörkt tema och lila för ljust tema
    fontWeight: 'bold',
    color: theme.palette.mode === 'dark' ? '#ffffff' : '#ffffff', // Vit text för båda teman
    borderColor: theme.palette.mode === 'dark' ? '#1675a3' : '#1675a3', // Mörkare blågrå border för mörkt tema
    '& .MuiSvgIcon-root': {
      color: theme.palette.mode === 'dark' ? '#ffffff' : '#ffffff', // Vit ikonfärg för båda teman
    },
  },
}));

export default WordView;
