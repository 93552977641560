import React, { useState, useEffect } from 'react';
import {
  Drawer,
  Box,
  Typography,
  Divider,
  IconButton,
  Tooltip,
  CircularProgress,
  Grid,
  Paper,
} from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import {
  deleteDoc,
  doc,
  query,
  collection,
  where,
  orderBy,
  updateDoc,
  getDocs,
  limit,
} from 'firebase/firestore';
import { db } from './firebase';
import { getStyles } from '../index';
import { lessonMapping } from './functions';
import LessonCard from './LessonCard';
import { Star, StarBorder } from '@mui/icons-material';
import { useSnackbar } from './SnackbarHandler';
import { Star as GoldStar, StarBorder as GoldStarBorder } from '@mui/icons-material';

const LessonDrawer = ({
  userId,
  lessonType,
  theme,
  open,
  onClose,
  onSelectLesson,
  lessonIcon: LessonIcon,
}) => {
  const [lessons, setLessons] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const styles = getStyles(theme);
  const isAdmin = userId === 'Z6BLSMsm38Un5mgW0O6Z3TToHq12';
  const { showSnackbar } = useSnackbar();
  useEffect(() => {
    if (open && userId && lessonType) {
      loadLessons();
    }
  }, [open, userId, lessonType]);

  const handleToggleFeatured = async (e, lessonId, currentFeatured) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      await updateDoc(doc(db, 'lesson_usage', lessonId), {
        featured: !currentFeatured,
      });
      setLessons(prevLessons =>
        prevLessons.map(lesson =>
          lesson.id === lessonId ? { ...lesson, featured: !lesson.featured } : lesson
        )
      );

      showSnackbar(
        currentFeatured
          ? 'Lektion borttagen från underlagsväljaren!'
          : 'Lektion tillagd i underlagsväljaren!',
        'success'
      );
    } catch (error) {
      console.error('Error updating featured status:', error);
    }
  };

  const handleToggleLessonBankFeatured = async (e, lessonId, currentFeatured) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      await updateDoc(doc(db, 'lesson_usage', lessonId), {
        featuredInLessonBank: !currentFeatured,
      });
      setLessons(prevLessons =>
        prevLessons.map(lesson =>
          lesson.id === lessonId
            ? { ...lesson, featuredInLessonBank: !lesson.featuredInLessonBank }
            : lesson
        )
      );
      showSnackbar(
        currentFeatured
          ? 'Lektion borttagen från lektionsbanken!'
          : 'Lektion tillagd i lektionsbanken!',
        'success'
      );
    } catch (error) {
      console.error('Error updating lesson bank featured status:', error);
    }
  };

  const loadLessons = async () => {
    setIsLoading(true);
    try {
      if (isAdmin) {
        const q = query(
          collection(db, 'lesson_usage'),
          where('lessonType', '==', lessonType),
          where('hasPrinted', '==', true),
          where('coverImage', '!=', null),
          orderBy('timestamp', 'desc'),
          limit(50)
        );

        const querySnapshot = await getDocs(q);
        const lessonData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          timestamp: doc.data().timestamp?.toDate(),
          isAdminLesson: doc.data().userId === userId,
        }));
        setLessons(lessonData);
      } else {
        const q = query(
          collection(db, 'lesson_usage'),
          where('userId', '==', userId),
          where('lessonType', '==', lessonType),
          where('hasPrinted', '==', true),
          orderBy('timestamp', 'desc')
        );

        const querySnapshot = await getDocs(q);
        const lessonData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          timestamp: doc.data().timestamp?.toDate(),
        }));
        setLessons(lessonData);
      }
    } catch (error) {
      console.error('Error loading lessons:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (e, lessonId) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      await deleteDoc(doc(db, 'lesson_usage', lessonId));
      setLessons(prevLessons => prevLessons.filter(lesson => lesson.id !== lessonId));
    } catch (error) {
      console.error('Error deleting lesson:', error);
    }
  };

  return (
    <Drawer
      onClose={onClose}
      anchor='right'
      open={open}
      sx={{ width: { xs: '75%', sm: '50%', md: '33%' }, paddingTop: '0px' }}
      PaperProps={{
        sx: {
          width: { xs: '75%', sm: '50%', md: '28%' },
          boxShadow: 'none',
          borderLeft: theme.palette.mode === 'dark' ? '1px solid #34414c' : '1px solid #e0e0e0',
          backgroundImage: 'none',
        },
      }}
    >
      <Box sx={{ p: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            onClick={onClose}
            color='inherit'
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <ChevronRight />
          </IconButton>
          <Typography
            variant='h6'
            sx={{
              fontSize: '17px',
              ml: 2,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            Sparade lektioner
          </Typography>
        </Box>

        <Paper
          elevation={0}
          sx={{
            mt: 3,
            mb: 2,
            py: 2.5,
            px: 3,
            backgroundColor:
              theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.03)' : '#f8f9fb',
            borderRadius: '12px',
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            border:
              theme.palette.mode === 'dark'
                ? '1px solid rgba(255, 255, 255, 0.05)'
                : '1px solid rgba(0, 0, 0, 0.03)',
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              backgroundColor:
                theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.04)' : '#f3f4f6',
            },
          }}
        >
          {LessonIcon && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor:
                  theme.palette.mode === 'dark'
                    ? 'rgba(255, 255, 255, 0.05)'
                    : theme.palette.primary.main + '15',
                borderRadius: '8px',
                p: 1,
              }}
            >
              <LessonIcon
                sx={{
                  fontSize: 24,
                  color: theme.palette.primary.main,
                }}
              />
            </Box>
          )}
          <Typography
            variant='body2'
            sx={{
              color:
                theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)',
              lineHeight: 1.5,
              fontWeight: 500,
            }}
          >
            Här samlas alla dina sparade lektioner för lektionstypen{' '}
            <i>{lessonMapping(lessonType)}</i>.
          </Typography>
        </Paper>

        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <CircularProgress />
          </Box>
        ) : lessons.length === 0 ? (
          <Box sx={{ mt: 4, textAlign: 'center' }}>
            <Typography variant='body1' color='text.secondary'>
              Inga sparade lektioner
            </Typography>
          </Box>
        ) : (
          <>
            {isAdmin ? (
              <>
                {/* Admin view with headers */}
                {lessons.filter(lesson => lesson.isAdminLesson).length > 0 && (
                  <>
                    <Typography variant='subtitle1' sx={{ mt: 3, mb: 2 }}>
                      Dina lektioner
                    </Typography>
                    <Grid container spacing={2}>
                      {lessons
                        .filter(lesson => lesson.isAdminLesson)
                        .map(lesson => (
                          <Grid item xs={12} key={lesson.id}>
                            <Box sx={{ position: 'relative' }}>
                              <LessonCard
                                name={lesson.lessonName}
                                comment={lesson.comment}
                                timestamp={lesson.timestamp}
                                lessonType={lessonType}
                                imageUrl={lesson.coverImage}
                                onClick={() => onSelectLesson(lesson)}
                                size='small'
                                theme={theme}
                              />

                              {isAdmin && (
                                <>
                                  <Tooltip
                                    title={
                                      lesson.featuredInLessonBank
                                        ? 'Ta bort från lektionsbanken'
                                        : 'Lägg till i lektionsbanken'
                                    }
                                    placement='top'
                                  >
                                    <IconButton
                                      onClick={e =>
                                        handleToggleLessonBankFeatured(
                                          e,
                                          lesson.id,
                                          lesson.featuredInLessonBank
                                        )
                                      }
                                      sx={{
                                        position: 'absolute',
                                        top: 8,
                                        right: 88, // positioned to the left of the other star button
                                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                        '&:hover': {
                                          backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                        },
                                      }}
                                    >
                                      {lesson.featuredInLessonBank ? (
                                        <GoldStar sx={{ color: '#FFD700', fontSize: 'medium' }} />
                                      ) : (
                                        <GoldStarBorder
                                          sx={{ color: '#FFD700', fontSize: 'medium' }}
                                        />
                                      )}
                                    </IconButton>
                                  </Tooltip>

                                  <Tooltip
                                    title={
                                      lesson.featured
                                        ? 'Ta bort från underlagsväljaren'
                                        : 'Lägg till i underlagsväljaren'
                                    }
                                    placement='top'
                                  >
                                    <IconButton
                                      onClick={e =>
                                        handleToggleFeatured(e, lesson.id, lesson.featured)
                                      }
                                      sx={{
                                        position: 'absolute',
                                        top: 8,
                                        right: 48, // positioned to the left of delete button
                                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                        '&:hover': {
                                          backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                        },
                                      }}
                                    >
                                      {lesson.featured ? (
                                        <Star sx={{ color: 'white', fontSize: 'medium' }} />
                                      ) : (
                                        <StarBorder sx={{ color: 'white', fontSize: 'medium' }} />
                                      )}
                                    </IconButton>
                                  </Tooltip>
                                </>
                              )}

                              <Tooltip title='Radera lektion' placement='top'>
                                <IconButton
                                  onClick={e => handleDelete(e, lesson.id)}
                                  sx={{
                                    position: 'absolute',
                                    top: 8,
                                    right: 8,
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    '&:hover': {
                                      backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                    },
                                  }}
                                >
                                  <DeleteOutline sx={{ color: 'white', fontSize: 'medium' }} />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </Grid>
                        ))}
                    </Grid>
                  </>
                )}
                {lessons.filter(lesson => !lesson.isAdminLesson).length > 0 && (
                  <>
                    <Box sx={{ mb: 2 }}>
                      <Divider />
                    </Box>
                    <Typography variant='subtitle1' sx={{ mt: 3, mb: 2 }}>
                      Andra användares lektioner
                    </Typography>
                    <Grid container spacing={2}>
                      {lessons
                        .filter(lesson => !lesson.isAdminLesson)
                        .map(lesson => (
                          <Grid item xs={12} key={lesson.id}>
                            <Box sx={{ position: 'relative' }}>
                              <LessonCard
                                name={lesson.lessonName}
                                comment={lesson.comment}
                                timestamp={lesson.timestamp}
                                lessonType={lessonType}
                                imageUrl={lesson.coverImage}
                                onClick={() => onSelectLesson(lesson)}
                                size='small'
                                theme={theme}
                              />

                              {isAdmin && (
                                <Tooltip
                                  title={lesson.featured ? 'Ta bort stjärnmärkning' : 'Stjärnmärk'}
                                  placement='top'
                                >
                                  <IconButton
                                    onClick={e =>
                                      handleToggleFeatured(e, lesson.id, lesson.featured)
                                    }
                                    sx={{
                                      position: 'absolute',
                                      top: 8,
                                      right: 48, // positioned to the left of delete button
                                      backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                      '&:hover': {
                                        backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                      },
                                    }}
                                  >
                                    {lesson.featured ? (
                                      <Star sx={{ color: 'white', fontSize: 'medium' }} />
                                    ) : (
                                      <StarBorder sx={{ color: 'white', fontSize: 'medium' }} />
                                    )}
                                  </IconButton>
                                </Tooltip>
                              )}

                              <Tooltip title='Radera lektion' placement='top'>
                                <IconButton
                                  onClick={e => handleDelete(e, lesson.id)}
                                  sx={{
                                    position: 'absolute',
                                    top: 8,
                                    right: 8,
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    '&:hover': {
                                      backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                    },
                                  }}
                                >
                                  <DeleteOutline sx={{ color: 'white', fontSize: 'medium' }} />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </Grid>
                        ))}
                    </Grid>
                  </>
                )}
              </>
            ) : (
              /* Regular user view - no headers */
              <Grid container spacing={2}>
                {lessons.map(lesson => (
                  <Grid item xs={12} key={lesson.id}>
                    <Box sx={{ position: 'relative' }}>
                      <LessonCard
                        name={lesson.lessonName}
                        comment={lesson.comment}
                        timestamp={lesson.timestamp}
                        lessonType={lessonType}
                        imageUrl={lesson.coverImage}
                        onClick={() => onSelectLesson(lesson)}
                        size='small'
                        theme={theme}
                      />
                      <Tooltip title='Radera lektion' placement='top'>
                        <IconButton
                          onClick={e => handleDelete(e, lesson.id)}
                          sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            '&:hover': {
                              backgroundColor: 'rgba(0, 0, 0, 0.7)',
                            },
                          }}
                        >
                          <DeleteOutline sx={{ color: 'white', fontSize: 'medium' }} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            )}
          </>
        )}
      </Box>
    </Drawer>
  );
};

export default LessonDrawer;
