import React, { useState, useEffect } from 'react';
import { Typography, Box, Avatar, Tooltip, Button } from '@mui/material';
import { doc, getDoc, setDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from './firebase';
import { useParams, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { getStyles } from '../index';
import { useSnackbar } from './SnackbarHandler';
import { Helmet } from 'react-helmet-async';

const TeacherDetails = ({ user }) => {
  const { teacherId, name } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const styles = getStyles(theme);
  const { showSnackbar } = useSnackbar();

  const [teacherData, setTeacherData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isInUserCollection, setIsInUserCollection] = useState(false);

  const dynamicTitle = `${teacherData?.characterName || 'Lärare'} — Lärare — AI-Chat `;
  const dynamicDescription = teacherData?.description
    ? teacherData.description.slice(0, 150) + (teacherData.description.length > 150 ? '...' : '')
    : 'Detaljer om AI-lärare';

  useEffect(() => {
    const fetchTeacherData = async () => {
      if (!teacherId) {
        setError('No teacher ID provided');
        setLoading(false);
        return;
      }

      try {
        const docRef = doc(db, 'publicTeachers', teacherId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setTeacherData({ id: docSnap.id, ...docSnap.data() });
        } else {
          setError('No teacher found with this ID');
          showSnackbar('Teacher not found', 'error');
        }

        // Check if the teacher is already in user's collection
        if (user) {
          const userTeachersRef = collection(db, 'teachers');
          const q = query(
            userTeachersRef,
            where('userId', '==', user.uid),
            where('id', '==', teacherId)
          );
          const userTeacherSnap = await getDocs(q);
          setIsInUserCollection(!userTeacherSnap.empty);
        }
      } catch (err) {
        console.error('Error fetching teacher data:', err);
        setError('Failed to fetch teacher data');
        showSnackbar('Error loading teacher data', 'error');
      } finally {
        setLoading(false);
      }
    };

    fetchTeacherData();
  }, [teacherId, showSnackbar, user]);

  const handleUseTeacher = async () => {
    if (!user) {
      showSnackbar('Du måste vara inloggad för att använda en lärare.', 'error');
      return;
    }

    if (isInUserCollection) {
      showSnackbar('Du har redan denna lärare i din personal', 'info');
      return;
    }

    try {
      const teacherCopy = {
        ...teacherData,
        userId: user.uid,
        createdAt: new Date(),
        updatedAt: new Date(),
        visibility: 'private',
        isPublicCopy: true,
      };

      await setDoc(doc(db, 'teachers', teacherData.id), teacherCopy);

      showSnackbar(`${teacherData.characterName} har kopierats till dina lärare.`, 'success');
      navigate(`/app/chat/${teacherData.id}`);
    } catch (error) {
      console.error('Fel vid kopiering av lärare: ', error);
      showSnackbar('Det gick inte att kopiera läraren. Försök igen.', 'error');
    }
  };
  if (!teacherData) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <>
      <Helmet>
        <title>{dynamicTitle}</title>
        <meta name='description' content={dynamicDescription} />
      </Helmet>

      <Box sx={{ ...styles.container, padding: '12px' }}>
        <Box sx={{ ...styles.innerContainer, width: { xs: '100%', md: '60%' }, margin: '0 auto' }}>
          <Typography variant='h1' sx={styles.header}>
            {teacherData.characterName}
          </Typography>

          <Box>
            <Avatar
              sx={{
                width: 100,
                height: 100,
                bgcolor: 'orange',
              }}
              src={teacherData.imageUrl}
            >
              {!teacherData.imageUrl && teacherData.characterName[0].toUpperCase()}
            </Avatar>
          </Box>

          <Typography variant='subtitle1'>{teacherData.tagline}</Typography>

          <Typography variant='body1'>{teacherData.description}</Typography>

          <Typography variant='h6'>Hälsningsfras</Typography>
          <Typography variant='body1'>{teacherData.greeting}</Typography>

          <Typography variant='h6'>Röst</Typography>
          <Typography variant='body1'>{teacherData.voice}</Typography>

          <Typography variant='h6'>Bakgrund</Typography>
          <Typography variant='body1'>{teacherData.background}</Typography>

          <Typography variant='h6'>Undervisningsstil</Typography>
          <Typography variant='body1'>{teacherData.teachingStyle}</Typography>

          <Typography variant='h6'>Personlighet</Typography>
          <Typography variant='body1'>{teacherData.personality}</Typography>

          <Typography variant='h6'>Pedagogisk filosofi</Typography>
          <Typography variant='body1'>{teacherData.pedagogicalPhilosophy}</Typography>

          <Typography variant='h6'>Kommunikationsstil</Typography>
          <Typography variant='body1'>{teacherData.communicationStyle}</Typography>

          <Typography variant='h6'>Förhållningssätt till misstag och frågor</Typography>
          <Typography variant='body1'>{teacherData.approachToMistakes}</Typography>

          <Box sx={{ mt: 2 }}>
            <Typography variant='body2'>
              Dyslexi-anpassad: {teacherData.dyslexiaAdapted ? 'Ja' : 'Nej'}
            </Typography>
            <Typography variant='body2'>
              ADHD-anpassad: {teacherData.adhdAdapted ? 'Ja' : 'Nej'}
            </Typography>
          </Box>

          <Box sx={{ mt: 2 }}>
            <Typography variant='body2'>
              Synlighet: {teacherData.visibility === 'public' ? 'Publik' : 'Privat'}
            </Typography>
          </Box>

          <Button
            onClick={handleUseTeacher}
            variant='contained'
            color='primary'
            style={{ marginTop: 16 }}
          >
            {isInUserCollection
              ? 'Du har redan denna lärare'
              : `Chatta med ${teacherData.characterName}`}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default TeacherDetails;
