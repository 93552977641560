import React, { useState, useEffect, useRef } from 'react';
import {
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  Switch,
  FormControlLabel,
  Tooltip,
  Box,
  Avatar,
  IconButton,
  RadioGroup,
  CircularProgress,
  Radio,
  Grid,
  InputAdornment,
  InputLabel,
} from '@mui/material';
import { ref, uploadBytes, getDownloadURL } from './firebase';
import { db, storage } from './firebase';
import { collection, addDoc, doc, getDoc, updateDoc, setDoc, getDocs } from 'firebase/firestore';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import PublicIcon from '@mui/icons-material/Public';
import LockIcon from '@mui/icons-material/Lock';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { getStyles } from '../index';
import IdeaGenerator from './IdeaGenerator';
import LoadingSpinner from './LoadingSpinner';
import GuideSection from './GuideSection'; // Adjust the import path as needed
import { useSnackbar } from './SnackbarHandler';
import Suggestions from './Suggestions';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExploreIcon from '@mui/icons-material/Explore';
import Logger from './Logger';
import { sanitizeInput } from './functions';
import GenerateCharacter from './GenerateCharacter';

const TeacherHandler = ({ teacherId, user }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const styles = getStyles(theme);

  const [teacherData, setTeacherData] = useState({
    characterName: '',
    tagline: '',
    description: '',
    greeting: '',
    voice: '',
    teachingStyle: '',
    personality: '',
    pedagogicalPhilosophy: '',
    communicationStyle: '',
    background: '',
    approachToMistakes: '',
    dyslexiaAdapted: false,
    adhdAdapted: false,
    imageUrl: '',
    category: '',
  });

  const [image, setImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [visibility, setVisibility] = useState('public');
  const [viewIsLoading, setViewIsLoading] = useState(true);
  const { showSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState({ name: '', description: '', imageUrl: '' });
  const [showCategoryForm, setShowCategoryForm] = useState(false);
  const [categoryImage, setCategoryImage] = useState(null);
  const [categoryPreviewUrl, setCategoryPreviewUrl] = useState(null);

  const handleIdeaGenerated = idea => {
    setTeacherData(prevData => ({
      ...prevData,
      background: idea,
    }));
  };

  const maxLengths = {
    characterName: 50,
    tagline: 100,
    description: 500,
    greeting: 200,
    teachingStyle: 100,
    personality: 100,
    pedagogicalPhilosophy: 300,
    communicationStyle: 100,
    background: 1000,
    approachToMistakes: 300,
  };

  useEffect(() => {
    const fetchTeacherData = async () => {
      if (teacherId) {
        Logger.log('teacherId:', teacherId);
        const docRef = doc(db, 'teachers', teacherId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setTeacherData(docSnap.data());
          setPreviewUrl(docSnap.data().imageUrl);
          setVisibility(docSnap.data().visibility || 'public');
        }
      } else {
        // Reset state when not editing (i.e., adding new teacher)
        setTeacherData({
          characterName: '',
          tagline: '',
          description: '',
          greeting: '',
          voice: '',
          teachingStyle: '',
          personality: '',
          pedagogicalPhilosophy: '',
          communicationStyle: '',
          background: '',
          approachToMistakes: '',
          dyslexiaAdapted: false,
          adhdAdapted: false,
          imageUrl: '',
          category: '',
        });
        setPreviewUrl(null);
        setVisibility('public');
        setShowAdditionalFields(false);
        setImage(null);
      }
    };

    fetchTeacherData();
  }, [teacherId, location.pathname]);

  useEffect(() => {
    setViewIsLoading(false);
  }, []);

  useEffect(() => {
    const fetchCategories = async () => {
      const categoriesCollection = collection(db, 'categories');
      const categoriesSnapshot = await getDocs(categoriesCollection);
      const categoriesList = categoriesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setCategories(categoriesList);
    };

    fetchCategories();
  }, []);

  if (viewIsLoading) {
    return <LoadingSpinner />;
  }

  const handleCharacterGenerated = characterData => {
    setTeacherData({
      ...teacherData,
      characterName: characterData.characterName,
      description: characterData.description,
      greeting: characterData.greeting,
      background: characterData.background,
      tagline: characterData.tagline,
      visibility: characterData.visibility,
    });
  };

  const handleChange = e => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    setTeacherData(prev => ({ ...prev, [name]: newValue }));
  };

  const handleImageChange = e => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
      setPreviewUrl(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleVisibilityChange = event => {
    setVisibility(event.target.value);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      if (!user) {
        console.error('Ingen användare inloggad');
        return;
      }

      // Check if character name or greeting is empty
      if (!teacherData.characterName.trim() || !teacherData.greeting.trim()) {
        showSnackbar('Namn och hälsningsfras är obligatoriska fält.', 'info');
        return;
      }

      let imageUrl = teacherData.imageUrl;
      if (image) {
        const imageRef = ref(storage, `teacherImages/${user.uid}/${image.name}`);
        await uploadBytes(imageRef, image);
        imageUrl = await getDownloadURL(imageRef);
      }

      // If no image is selected or uploaded, set imageUrl to a string that will trigger MUI's default avatar
      if (!imageUrl) {
        imageUrl = teacherData.characterName[0].toUpperCase();
      }

      // Sanitize all text fields
      const sanitizedTeacherData = Object.fromEntries(
        Object.entries(teacherData).map(([key, value]) => {
          if (typeof value === 'string') {
            return [key, sanitizeInput(value)];
          }
          return [key, value];
        })
      );

      const teacherDataToSave = {
        ...sanitizedTeacherData,
        imageUrl,
        userId: user.uid,
        updatedAt: new Date(),
        visibility: visibility,
        isPublicCopy: false,
        category: teacherData.category,
      };

      let newTeacherId;
      if (teacherId) {
        await updateDoc(doc(db, 'teachers', teacherId), teacherDataToSave);
        newTeacherId = teacherId;
        console.log('Document successfully updated: ', teacherDataToSave);
      } else {
        const docRef = await addDoc(collection(db, 'teachers'), {
          ...teacherDataToSave,
          createdAt: new Date(),
        });
        newTeacherId = docRef.id;
      }

      if (!teacherId) {
        if (visibility === 'public') {
          await setDoc(doc(db, 'publicTeachers', newTeacherId), {
            ...teacherDataToSave,
            originalCreatorId: user.uid,
            byAdmin: true,
          });
        }
      }

      Logger.log('teacherDataToSave: ', teacherDataToSave);

      if (teacherId) {
        showSnackbar(`${sanitizedTeacherData.characterName} är uppdaterad.`, 'info');
      } else {
        showSnackbar(
          `${sanitizedTeacherData.characterName} har stigit in i klassrummet.`,
          'success'
        );
      }
      setIsLoading(false);
      navigate(`/app/chat/${newTeacherId}`);
    } catch (error) {
      setIsLoading(false);
      console.error('Fel vid hantering av karaktär: ', error);
      showSnackbar('Ett fel uppstod. Försök igen.', 'error');
    }
  };

  const handleSelect = (selectedItem, listType) => {
    Logger.log(selectedItem);
    setTeacherData({
      characterName: selectedItem.characterName || '',
      tagline: selectedItem.tagline || '',
      description: selectedItem.description || '',
      greeting: selectedItem.greeting || '',
      teachingStyle: selectedItem.teachingStyle || '',
      personality: selectedItem.personality || '',
      pedagogicalPhilosophy: selectedItem.pedagogicalPhilosophy || '',
      communicationStyle: selectedItem.communicationStyle || '',
      background: selectedItem.background || '',
      approachToMistakes: selectedItem.approachToMistakes || '',
      dyslexiaAdapted: selectedItem.dyslexiaAdapted || false,
      adhdAdapted: selectedItem.adhdAdapted || false,
      imageUrl: selectedItem.imageUrl || '',
      category: selectedItem.category || '',
    });

    setVisibility(selectedItem.visibility || 'public');

    if (selectedItem.imageUrl) {
      setPreviewUrl(selectedItem.imageUrl);
    }

    Logger.log('Valt objekt:', selectedItem);
  };

  const handleCategoryChange = e => {
    const { name, value } = e.target;
    setNewCategory(prev => ({ ...prev, [name]: value }));
  };

  const handleCategoryImageChange = e => {
    if (e.target.files[0]) {
      setCategoryImage(e.target.files[0]);
      setCategoryPreviewUrl(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleAddCategory = async () => {
    try {
      const sanitizedCategory = {
        name: sanitizeInput(newCategory.name),
        description: sanitizeInput(newCategory.description),
      };

      let imageUrl = '';
      if (categoryImage) {
        const imageRef = ref(storage, `categoryImages/${categoryImage.name}`);
        await uploadBytes(imageRef, categoryImage);
        imageUrl = await getDownloadURL(imageRef);
      }

      const categoryToSave = {
        ...sanitizedCategory,
        imageUrl,
      };

      const docRef = await addDoc(collection(db, 'categories'), categoryToSave);
      setCategories(prev => [...prev, { id: docRef.id, ...categoryToSave }]);
      setNewCategory({ name: '', description: '', imageUrl: '' });
      setCategoryImage(null);
      setCategoryPreviewUrl(null);
      setShowCategoryForm(false);
      showSnackbar('Kategori har lagts till', 'success');
    } catch (error) {
      console.error('Fel vid tillägg av kategori: ', error);
      showSnackbar('Ett fel uppstod. Försök igen.', 'error');
    }
  };

  return (
    <Box sx={{ ...styles.container }}>
      <Box sx={{ ...styles.innerContainer, width: { xs: '100%', md: '100%' }, margin: '0 auto' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            {/* Formulärsektionen */}
            <Box>
              <Typography variant='h1' sx={styles.header}>
                {teacherId ? 'Redigera karaktär' : 'Skapa AI-karaktär'}
              </Typography>
              {/*
        <GenerateCharacter
          user={user}
          onCharacterGenerated={handleCharacterGenerated}
          position='top'
        />
*/}
              {/* Category Form */}
              {user && user.admin && (
                <Button
                  onClick={() => setShowCategoryForm(!showCategoryForm)}
                  variant='outlined'
                  sx={{ ...styles.beautyBtnTres, mt: 2, mb: 2 }}
                >
                  {showCategoryForm ? 'Dölj kategoriformat' : 'Lägg till ny kategori'}
                </Button>
              )}

              {showCategoryForm && (
                <Box sx={{ mb: 4, p: 2, border: '1px solid #ccc', borderRadius: '4px' }}>
                  <Typography variant='h6' sx={{ mb: 2 }}>
                    Lägg till ny kategori
                  </Typography>

                  <TextField
                    fullWidth
                    margin='normal'
                    name='name'
                    label='Kategorinamn'
                    value={newCategory.name}
                    onChange={handleCategoryChange}
                    sx={{ ...styles.whiteBg, mb: 2 }}
                  />

                  <TextField
                    fullWidth
                    margin='normal'
                    name='description'
                    label='Beskrivning'
                    multiline
                    rows={2}
                    value={newCategory.description}
                    onChange={handleCategoryChange}
                    sx={{ ...styles.whiteBg, mb: 2 }}
                  />

                  <Box sx={{ mb: 2 }}>
                    <input
                      accept='image/*'
                      type='file'
                      onChange={handleCategoryImageChange}
                      style={{ display: 'none' }}
                      id='category-image-upload'
                    />
                    <label htmlFor='category-image-upload'>
                      <IconButton component='span'>
                        <Avatar
                          sx={{
                            width: 100,
                            height: 100,
                            bgcolor: '#8b5cf6',
                            color: 'white',
                            cursor: 'pointer',
                          }}
                        >
                          {categoryPreviewUrl ? (
                            <img
                              src={categoryPreviewUrl}
                              alt='Category Preview'
                              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                            />
                          ) : (
                            <AddAPhotoIcon sx={{ fontSize: 40 }} />
                          )}
                        </Avatar>
                      </IconButton>
                    </label>
                  </Box>

                  <Button
                    onClick={handleAddCategory}
                    variant='contained'
                    color='primary'
                    sx={{ mt: 2 }}
                  >
                    Lägg till kategori
                  </Button>
                </Box>
              )}

              <Box sx={{ ...styles.buttonsContainer, ...styles.additionalButtonsContainer }}>
                <Button
                  variant='contained'
                  color='primary'
                  disableRipple
                  onClick={e => {
                    e.preventDefault();
                    navigate('/app/personal');
                  }}
                  startIcon={
                    <ExploreIcon
                      sx={{ color: theme.palette.mode === 'dark' ? '#fff' : '#8b5cf6' }}
                    />
                  }
                  sx={styles.shrinkButton}
                ></Button>
              </Box>

              <Box>
                <input
                  accept='image/*'
                  type='file'
                  onChange={handleImageChange}
                  style={{ display: 'none' }}
                  id='image-upload'
                />
                <label htmlFor='image-upload'>
                  <IconButton component='span'>
                    <Avatar
                      sx={{
                        width: 100,
                        height: 100,
                        bgcolor: '#8b5cf6',
                        color: 'white',
                        cursor: 'pointer',
                      }}
                    >
                      {previewUrl ? (
                        <img
                          src={previewUrl}
                          alt='Preview'
                          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                        />
                      ) : (
                        <AddAPhotoIcon sx={{ fontSize: 40 }} />
                      )}
                    </Avatar>
                  </IconButton>
                </label>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
                <Suggestions
                  listType='teachers2'
                  maxSuggestions={6}
                  onSelect={handleSelect}
                  alignRight={true}
                />
              </Box>

              <Typography variant='formLabelSinUnderline'>Karaktärens namn*</Typography>
              <TextField
                fullWidth
                margin='normal'
                name='characterName'
                value={teacherData.characterName}
                onChange={handleChange}
                inputProps={{ maxLength: maxLengths.characterName }}
                sx={{ ...styles.whiteBg, mt: 1 }}
                placeholder='T.ex. Herman Lindqvist'
              />
              <Box display='flex' justifyContent='flex-end'>
                <Typography variant='caption'>
                  {teacherData.characterName.length}/{maxLengths.characterName}
                </Typography>
              </Box>

              <Tooltip title='Karaktärens inledande hälsningsfras i varje chatt'>
                <Typography variant='formLabel'>Hälsningsfras*</Typography>
              </Tooltip>
              <TextField
                fullWidth
                margin='normal'
                name='greeting'
                multiline
                rows={2}
                value={teacherData.greeting}
                onChange={handleChange}
                inputProps={{ maxLength: maxLengths.greeting }}
                sx={{ ...styles.whiteBg, mt: 1 }}
                placeholder='T.ex. Hej, jag heter Herman. Fråga mig om populärhistoria.'
              />
              <Box display='flex' justifyContent='flex-end'>
                <Typography variant='caption'>
                  {teacherData.greeting.length}/{maxLengths.greeting}
                </Typography>
              </Box>

              <Tooltip title='Beskriv hur karaktären ska bete sig, vilket temperament etc.'>
                <Typography variant='formLabel' sx={{ mt: 1 }}>
                  Definition
                </Typography>
              </Tooltip>
              <TextField
                fullWidth
                margin='normal'
                name='background'
                multiline
                rows={5}
                value={teacherData.background}
                onChange={handleChange}
                inputProps={{ maxLength: maxLengths.background }}
                sx={{ ...styles.whiteBg, mt: 1 }}
                placeholder='Hur vill du att karaktären ska tala och agera, hur bör den strukturera sina svar, och vilket temperament och ton ska den ha?'
                // InputProps={{
                //   endAdornment: (
                //     <InputAdornment position='end'>
                //       <IdeaGenerator
                //         user={user}
                //         onIdeaGenerated={handleIdeaGenerated}
                //         systemMessage="Ge mig en karaktärsdefinition på 3-4 meningar på en chatbot, t.ex. 'Du är en spansklärare som är uppmuntrande, pedagogisk och förstående. Driv samtalet framåt och skapa en personlig relation med eleven med ett vänskapligt språkbruk och samtalston.'  Följ denna struktur varje gång: [{ 'idea': 'the  idea'}]"
                //         position='top'
                //         size='small'
                //       />
                //     </InputAdornment>
                //   ),
                // }}
              />
              <Box display='flex' justifyContent='flex-end'>
                <Typography variant='caption'>
                  {teacherData.background.length}/{maxLengths.background}
                </Typography>
              </Box>

              <Tooltip title='Ju bättre beskrivning desto fler användare som kommer använda den'>
                <Typography variant='formLabel'>Beskrivning</Typography>
              </Tooltip>
              <TextField
                fullWidth
                margin='normal'
                name='description'
                multiline
                rows={2}
                value={teacherData.description}
                onChange={handleChange}
                inputProps={{ maxLength: maxLengths.description }}
                sx={{ ...styles.whiteBg, mt: 1 }}
                placeholder='Beskriv karaktären för andra användare'
              />
              <Box display='flex' justifyContent='flex-end'>
                <Typography variant='caption'>
                  {teacherData.description.length}/{maxLengths.description}
                </Typography>
              </Box>

              <Tooltip title='Kort och kärnfull beskrivning av karaktären'>
                <Typography variant='formLabel'>Tagline</Typography>
              </Tooltip>
              <TextField
                fullWidth
                margin='normal'
                name='tagline'
                value={teacherData.tagline}
                onChange={handleChange}
                inputProps={{ maxLength: maxLengths.tagline }}
                sx={{ ...styles.whiteBg, mt: 1 }}
                placeholder='Lägg till en kort tagline'
              />
              <Box display='flex' justifyContent='flex-end'>
                <Typography variant='caption'>
                  {teacherData.tagline.length}/{maxLengths.tagline}
                </Typography>
              </Box>

              <Tooltip title='Specifika röster kommer snart'>
                <FormControl fullWidth margin='normal' disabled={true} sx={{ mt: 0 }}>
                  <Typography variant='formLabelSinUnderline'>Röst</Typography>

                  <Select
                    id='voice-select'
                    name='voice'
                    value={teacherData.voice || ''}
                    onChange={handleChange}
                    displayEmpty
                    sx={{ ...styles.whiteBg, mt: 1 }}
                  >
                    <MenuItem value='' disabled>
                      Välj röst
                    </MenuItem>
                    <MenuItem value='rost1'>Röst 1</MenuItem>
                    <MenuItem value='röst2'>Röst 2</MenuItem>
                  </Select>
                </FormControl>
              </Tooltip>

              <Box>
                {user && user.admin && (
                  <FormControl fullWidth margin='normal' sx={{ mt: 0 }}>
                    <Typography variant='formLabelSinUnderline'>Kategori</Typography>

                    <Select
                      id='category'
                      name='category'
                      value={teacherData.category}
                      onChange={handleChange}
                      displayEmpty
                      sx={{ ...styles.whiteBg, mt: 1 }}
                    >
                      <MenuItem value='' disabled>
                        Välj kategori
                      </MenuItem>
                      {categories.map(category => (
                        <MenuItem key={category.id} value={category.id}>
                          {category.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Box>

              <Button
                onClick={() => setShowAdditionalFields(!showAdditionalFields)}
                variant='outlined'
                endIcon={showAdditionalFields ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                sx={{ ...styles.beautyBtnTres, mt: 2, mb: 4 }}
                aria-expanded={showAdditionalFields}
                aria-controls='additional-fields'
              >
                {showAdditionalFields ? 'Dölj fler alternativ' : 'Visa fler alternativ'}
              </Button>

              {showAdditionalFields && (
                <>
                  <Box>
                    <Tooltip title='Beskriv lärarens pedagogiska metoder'>
                      <Typography variant='formLabel'>Undervisningsstil</Typography>
                    </Tooltip>
                    <TextField
                      fullWidth
                      margin='normal'
                      name='teachingStyle'
                      value={teacherData.teachingStyle}
                      onChange={handleChange}
                      inputProps={{ maxLength: maxLengths.teachingStyle }}
                      sx={{ ...styles.whiteBg, mt: 1 }}
                      placeholder='Tuff men rättvis'
                    />
                    <Box display='flex' justifyContent='flex-end'>
                      <Typography variant='caption'>
                        {teacherData.teachingStyle.length}/{maxLengths.teachingStyle}
                      </Typography>
                    </Box>

                    <Tooltip title='Beskriv lärarens personlighetsdrag'>
                      <Typography variant='formLabel'>Personlighet</Typography>
                    </Tooltip>
                    <TextField
                      fullWidth
                      margin='normal'
                      name='personality'
                      value={teacherData.personality}
                      onChange={handleChange}
                      inputProps={{ maxLength: maxLengths.personality }}
                      sx={{ ...styles.whiteBg, mt: 1 }}
                      placeholder='Känslomässigt instabil men med ett gott hjärta'
                    />
                    <Box display='flex' justifyContent='flex-end'>
                      <Typography variant='caption'>
                        {teacherData.personality.length}/{maxLengths.personality}
                      </Typography>
                    </Box>

                    <Tooltip title='Beskriv lärarens grundläggande pedagogiska övertygelser'>
                      <Typography variant='formLabel'>Pedagogisk filosofi</Typography>
                    </Tooltip>
                    <TextField
                      fullWidth
                      margin='normal'
                      name='pedagogicalPhilosophy'
                      multiline
                      rows={2}
                      value={teacherData.pedagogicalPhilosophy}
                      onChange={handleChange}
                      inputProps={{ maxLength: maxLengths.pedagogicalPhilosophy }}
                      sx={{ ...styles.whiteBg, mt: 1 }}
                      placeholder='Montessori-, waldorf- eller freinetpedagogik'
                    />
                    <Box display='flex' justifyContent='flex-end'>
                      <Typography variant='caption'>
                        {teacherData.pedagogicalPhilosophy.length}/
                        {maxLengths.pedagogicalPhilosophy}
                      </Typography>
                    </Box>

                    <Tooltip title='Beskriv hur läraren kommunicerar med studenter'>
                      <Typography variant='formLabel'>Kommunikationsstil</Typography>
                    </Tooltip>
                    <TextField
                      fullWidth
                      margin='normal'
                      name='communicationStyle'
                      value={teacherData.communicationStyle}
                      onChange={handleChange}
                      inputProps={{ maxLength: maxLengths.communicationStyle }}
                      sx={{ ...styles.whiteBg, mt: 1 }}
                      placeholder='T.ex. empatisk, lyhörd och inspirerande'
                    />
                    <Box display='flex' justifyContent='flex-end'>
                      <Typography variant='caption'>
                        {teacherData.communicationStyle.length}/{maxLengths.communicationStyle}
                      </Typography>
                    </Box>

                    <Tooltip title='Beskriv hur läraren hanterar misstag och frågor från studenter'>
                      <Typography variant='formLabel'>
                        Förhållningssätt till misstag och frågor
                      </Typography>
                    </Tooltip>
                    <TextField
                      fullWidth
                      margin='normal'
                      name='approachToMistakes'
                      multiline
                      rows={2}
                      value={teacherData.approachToMistakes}
                      onChange={handleChange}
                      inputProps={{ maxLength: maxLengths.approachToMistakes }}
                      placeholder='T.ex. lösningsfokuserad, stöttande och positiv'
                      sx={{ ...styles.whiteBg, mt: 1 }}
                    />
                    <Box display='flex' justifyContent='flex-end'>
                      <Typography variant='caption'>
                        {teacherData.approachToMistakes.length}/{maxLengths.approachToMistakes}
                      </Typography>
                    </Box>

                    <Box
                      display='flex'
                      justifyContent='space-between'
                      alignItems='center'
                      sx={{
                        background: theme.palette.mode === 'dark' ? '#2a3742' : '#fff',
                        marginBottom: '5px',
                        borderRadius: '10px',
                        padding: '12px',
                        paddingTop: '5px',
                        paddingBottom: '5px',
                        mt: 2,
                      }}
                    >
                      <Tooltip title='Extra tydlig, långsamt samtalstempo, bekräftar förståelse, upprepar information och ger god tid för reflektion.'>
                        <Typography variant='formLabel'>Dyslexi-anpassad</Typography>
                      </Tooltip>

                      <Switch
                        checked={teacherData.dyslexiaAdapted}
                        onChange={handleChange}
                        name='dyslexiaAdapted'
                      />
                    </Box>
                    <Box
                      display='flex'
                      justifyContent='space-between'
                      alignItems='center'
                      sx={{
                        background: theme.palette.mode === 'dark' ? '#2a3742' : '#fff',
                        marginBottom: '5px',
                        borderRadius: '10px',
                        padding: '12px',
                        paddingTop: '5px',
                        paddingBottom: '5px',
                        mt: 2,
                      }}
                    >
                      <Tooltip title='Korta instruktioner, bryter ner uppgifter i mindre delar, förutsägbar miljö, positiv feedback & förståelse.'>
                        <Typography variant='formLabel'>ADHD-anpassad</Typography>
                      </Tooltip>
                      <Switch
                        checked={teacherData.adhdAdapted}
                        onChange={handleChange}
                        name='adhdAdapted'
                      />
                    </Box>
                  </Box>
                </>
              )}

              <Box
                display='flex'
                justifyContent='space-between'
                alignItems='center'
                sx={{
                  background: theme.palette.mode === 'dark' ? '#2a3742' : '#fff',
                  marginBottom: '5px',
                  borderRadius: '10px',
                  padding: '12px',
                  paddingTop: '5px',
                  paddingBottom: '5px',
                  mt: 2,
                }}
              >
                <Box display='flex' alignItems='center'>
                  <Tooltip
                    title='Ska karaktären vara tillgänglig för andra att använda?'
                    placement='top'
                  >
                    <Typography variant='formLabel'>Synlighet</Typography>
                  </Tooltip>
                  {visibility === 'public' ? (
                    <PublicIcon sx={{ marginLeft: 1, fontSize: '20px' }} />
                  ) : (
                    <LockIcon sx={{ marginLeft: 1, fontSize: '20px' }} />
                  )}
                </Box>
                <Box display='flex' alignItems='center'>
                  <Typography variant='caption' sx={{ mr: 1 }}>
                    {visibility === 'public' ? 'Publik' : 'Privat'}
                  </Typography>
                  <Switch
                    checked={visibility === 'public'}
                    onChange={e => setVisibility(e.target.checked ? 'public' : 'private')}
                    name='visibility'
                  />
                </Box>
              </Box>

              <Button
                onClick={handleSubmit}
                variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                color='primary'
                style={{ marginTop: '23px' }}
                disabled={isLoading}
                endIcon={isLoading ? <CircularProgress size={16} color='inherit' /> : null}
              >
                {teacherId ? 'Uppdatera karaktär' : 'Lägg till karaktär'}
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* Guiden-sektionen */}
            <Box sx={{ padding: { xs: '0px', md: '20px' } }}>
              <GuideSection theme={theme} styles={styles} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default TeacherHandler;
