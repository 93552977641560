import React, { useState } from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  IconButton,
  Box,
  Tooltip,
  Modal,
  Button,
  Stack,
} from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { East as ArrowIcon } from '@mui/icons-material';

const channelIcons = {
  CNN: {
    icon: '/img/cnn.png',
    tooltip: 'CNN',
  },
  URPLAY: {
    icon: '/img/urplaysmall.png',
    tooltip: 'UR Play',
  },
  SCHOOLOFLIFE: {
    icon: '/img/schooloflife.jpg',
    tooltip: 'The School of Life',
  },
  CRASHCOURSE: {
    icon: '/img/crashcourse.png',
    tooltip: 'Crash Course',
  },
  NATIONALGEOGRAPHIC: {
    icon: '/img/natgeo.png',
    tooltip: 'National Geographic',
  },
  TEDX: {
    icon: '/img/tedx.png',
    tooltip: 'TEDx Talks',
  },
  BBC: {
    icon: '/img/bbc.png',
    tooltip: 'BBC - British Broadcasting Corporation',
  },
  CAPTIVATINGHISTORY: {
    icon: '/img/captivatinghistory.jpg',
    tooltip: 'Captivating History',
  },
};

const VideoCard = ({ video, onSelect, isDark, showPreview, setShowPreview }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [localShowPreview, setLocalShowPreview] = useState(false);

  const actualShowPreview = showPreview !== undefined ? showPreview : localShowPreview;
  const actualSetShowPreview = setShowPreview || setLocalShowPreview;

  const extractVideoId = url => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? match[2] : null;
  };

  const videoId = extractVideoId(video.url);

  // Get the highest quality thumbnail available
  const getHighestQualityThumbnail = videoId => {
    return `https://i.ytimg.com/vi/${videoId}/maxresdefault.jpg`;
  };

  return (
    <>
      <Card
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        sx={{
          cursor: 'pointer',
          backgroundColor: isDark ? 'rgba(255, 255, 255, 0.05)' : 'rgba(223, 222, 225, 0.19)',
          borderRadius: '12px',

          transition: 'all 0.3s ease',
          '&:hover': {
            backgroundColor: isDark ? 'rgba(255, 255, 255, 0.1)' : 'rgba(139, 92, 246, 0.1)',
            transform: 'scale(1.02)',
          },
          boxShadow: 'none',
          border: 'none',
          position: 'relative',
          overflow: 'hidden',
        }}
        onClick={() => actualSetShowPreview(true)}
      >
        <Box sx={{ position: 'relative' }}>
          <CardMedia
            component='img'
            image={getHighestQualityThumbnail(videoId)}
            alt={video.title}
            onError={e => {
              // Fallback to hqdefault if maxresdefault is not available
              e.target.src = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
            }}
            sx={{
              transition: 'transform 0.3s ease',
              ...(isHovered && { transform: 'scale(1.05)' }),
              objectFit: 'contain', // Add this line
              backgroundColor: 'black', // Optional: adds a background color to empty space
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.3)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              opacity: isHovered ? 1 : 0,
              transition: 'opacity 0.3s ease',
            }}
          >
            <Stack direction='row' spacing={2}>
              <IconButton
                onClick={() => actualSetShowPreview(true)}
                sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.15)',
                  backdropFilter: 'blur(8px)',

                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.25)',
                  },
                  width: 56,
                  height: 56,
                }}
              >
                <PlayArrowIcon
                  sx={{
                    color: '#fff',
                    fontSize: '2.5rem',

                    '&:hover': {
                      color: '#f03',
                    },
                  }}
                />
              </IconButton>

              <Tooltip title='Hämta innehåll från video' placement='top'>
                <IconButton
                  onClick={e => {
                    e.stopPropagation();
                    onSelect(video);
                  }}
                  sx={{
                    backgroundColor: 'rgba(255, 255, 255, 0.15)',
                    backdropFilter: 'blur(8px)',
                    display: 'none',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.25)',
                    },
                    width: 56,
                    height: 56,
                  }}
                >
                  <CheckCircleIcon
                    sx={{
                      color: '#fff',
                      fontSize: '2.2rem',

                      '&:hover': {
                        color: '#f03',
                      },
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Stack>
          </Box>
        </Box>
        <CardContent
          sx={{
            textAlign: 'center',
            position: 'relative',

            paddingBottom: '38px !important',
            paddingTop: '22px',
          }}
        >
          <Typography
            variant='subtitle1'
            sx={{
              color: isDark ? 'rgba(255, 255, 255, 0.7)' : '#2c2f33',
              fontWeight: 600,
            }}
          >
            {video.title}
          </Typography>

          {video.channel && channelIcons[video.channel] && (
            <Box
              sx={{
                position: 'absolute',
                bottom: 8,
                right: 8,
                zIndex: 1,
              }}
            >
              <Tooltip title={channelIcons[video.channel].tooltip} placement='top'>
                <Box
                  component='img'
                  src={channelIcons[video.channel].icon}
                  alt={video.channel}
                  sx={{
                    height: 18,
                    width: 'auto',
                    opacity: 0.8,
                    transition: 'opacity 0.2s',
                    '&:hover': {
                      opacity: 1,
                      transform: 'scale(1.1)',
                    },
                  }}
                />
              </Tooltip>
            </Box>
          )}
        </CardContent>
      </Card>

      <Modal
        open={actualShowPreview}
        onClose={() => actualSetShowPreview(false)}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            width: '90vw',
            maxWidth: '960px',
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            p: 1,
          }}
        >
          <IconButton
            onClick={() => actualSetShowPreview(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.7)' },
              zIndex: 1,
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box
            sx={{
              position: 'relative',
              paddingTop: '56.25%', // 16:9 aspect ratio
              width: '100%',
            }}
          >
            <iframe
              src={`https://www.youtube.com/embed/${videoId}?autoplay=0&rel=0&modestbranding=1`}
              title={video.title}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                border: 0,
              }}
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen
            />
          </Box>
          <Box sx={{ mt: 2, p: 2, display: 'flex', justifyContent: 'center' }}>
            <Button
              variant='purp'
              color='primary'
              onClick={() => {
                actualSetShowPreview(false);
                onSelect(video);
              }}
            >
              Välj video som källa till din lektion <ArrowIcon sx={{ ml: 1 }} />
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default VideoCard;
