import React, { useRef, useEffect, useState } from 'react';
import {
  Typography,
  Grid,
  Box,
  TextField,
  Collapse,
  Paper,
  Stack,
  CircularProgress,
  Container,
} from '@mui/material';

import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import EditIcon from '@mui/icons-material/Edit';
import SchoolIcon from '@mui/icons-material/School';
import HistoryIcon from '@mui/icons-material/History';
import ScienceIcon from '@mui/icons-material/Science';
import StyleIcon from '@mui/icons-material/Style';
import PublicIcon from '@mui/icons-material/Public';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CalculateIcon from '@mui/icons-material/Calculate';

import UploadFileIcon from '@mui/icons-material/UploadFile';
import FileUploader from './FileUploader';
import WikiFetcher from './WikiFetcher';
import Randomizer from './Randomizer';
import Suggestions from './Suggestions';
import CustomThemeInput from './CustomThemeInput';

import WikipediaIcon from './WikipediaIcon';
import TranslateIcon from '@mui/icons-material/Translate';
import LanguageIcon from '@mui/icons-material/Language';
import NatureIcon from '@mui/icons-material/Nature';

import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';

import WavesIcon from '@mui/icons-material/Waves';
import PeopleIcon from '@mui/icons-material/People';

import { getTrackedLessons } from './functions';
import LessonCard from './LessonCard';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import MosqueIcon from '@mui/icons-material/Mosque';
import YouTubeIcon from '@mui/icons-material/YouTube';
import BiotechIcon from '@mui/icons-material/Biotech';
import ThemeFlowBox from './ThemeFlowBox';
import { CoursePlanSelector } from './CoursePlanSelector';
import { YouTubeSelector } from './YouTubeSelector';

import ShuffleIcon from '@mui/icons-material/Shuffle';

const ThemeSelectorFlow = ({
  value,
  onChange,
  customTheme,
  onCustomThemeChange,
  onPredefinedThemeChange,
  additionalThemes = [],

  onYoutubeTranscriptChange,
  onFileUpload,
  onWikipediaContentChange,
  onRandomizedContentChange,
  onCoursePlanContentChange,

  lessonType,
  onLessonSelect, // New callback prop for handling lesson selection
  user, // Need to pass the user prop for getTrackedLessons
  theme,
  styles,
  forQuiz = false,
  forFlashcards = false,
}) => {
  const inputRef = useRef(null);
  const [step, setStep] = React.useState(1);
  const [selectedSource, setSelectedSource] = React.useState(null);
  const textFieldRef = useRef(null);
  const [lessons, setLessons] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [allThemes, setAllThemes] = useState(additionalThemes);
  const [selectedTheme, setSelectedTheme] = useState(null);

  const schoolSubjects = [
    { value: 'historia', label: 'Historia', Icon: HistoryIcon },
    { value: 'fysik', label: 'Fysik', Icon: WavesIcon },
    { value: 'biologi', label: 'Biologi', Icon: NatureIcon },
    { value: 'geografi', label: 'Geografi', Icon: PublicIcon },
    { value: 'engelska', label: 'Engelska', Icon: LanguageIcon },
    { value: 'kemi', label: 'Kemi', Icon: ScienceIcon },
    { value: 'modersmål', label: 'Modersmål', Icon: PublicIcon },
    { value: 'religionskunskap', label: 'Religionskunskap', Icon: MosqueIcon },
    { value: 'samhällskunskap', label: 'Samhällskunskap', Icon: PeopleIcon },
    { value: 'svenska', label: 'Svenska', Icon: TranslateIcon },
    { value: 'matematik', label: 'Matematik', Icon: CalculateIcon },
    { value: 'teknik', label: 'Teknik', Icon: BiotechIcon },
  ];

  const validSchoolSubjects = schoolSubjects.map(subject => subject.value);
  const hasCustomThemes = additionalThemes.length > 0;

  useEffect(() => {
    if (selectedSource === 'lessonBank') {
      loadLessons();
    }
  }, [selectedSource]);

  const loadLessons = async () => {
    setIsLoading(true);
    try {
      console.log('lessonType in loadLessons', lessonType);
      const trackedLessons = await getTrackedLessons(user.uid, lessonType);
      setLessons(trackedLessons);
    } catch (error) {
      console.error('Error loading lessons:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLessonSelect = lesson => {
    if (onLessonSelect) {
      onLessonSelect(lesson);
      onChange({ target: { value: 'lessonBank' } });
    }
  };

  useEffect(() => {
    if (customTheme?.startsWith('Ämne:') && inputRef.current) {
      const timeoutId = setTimeout(() => {
        inputRef.current.focus();
        inputRef.current.setSelectionRange(customTheme.length, customTheme.length);
      }, 0);
      return () => clearTimeout(timeoutId);
    }
  }, [customTheme]);

  useEffect(() => {
    // Om value är ett skolämne, sätt selectedSource till 'subject'
    if (validSchoolSubjects.includes(value)) {
      setSelectedSource('subject');
      setStep(2);
    }
  }, [value]);

  const handleSourceSelect = source => {
    setSelectedSource(source);
    if (source === 'customTheme') {
      onChange({ target: { value: 'customTheme' } });
    } else if (source === 'youtube') {
      onChange({ target: { value: 'youtube' } });
    } else if (source === 'urplay') {
      onChange({ target: { value: 'urplay' } });
    } else if (source === 'lessonBank') {
      onChange({ target: { value: 'lessonBank' } });
    } else if (source === 'predefined') {
      onChange({ target: { value: 'predefined' } });
    } else if (source === 'fileUpload') {
      onChange({ target: { value: 'fileUpload' } });
    } else if (source === 'wikipedia') {
      onChange({ target: { value: 'wikipedia' } });
    } else if (source === 'randomizer') {
      onChange({ target: { value: 'randomizer' } });
    } else if (source === 'subject') {
      onChange({ target: { value: 'subject' } });
    }
    setStep(2);
  };

  const handleSubjectSelect = subject => {
    onChange({ target: { value: subject } });
  };

  const handleSelect = selected => {
    if (selected.text && inputRef.current) {
      inputRef.current.value = selected.value;

      onCustomThemeChange({ target: { value: selected.value } });
    }
  };

  const handlePredefinedThemeSelect = topic => {
    const selectedTheme = allThemes.find(theme => theme.value === topic);
    onPredefinedThemeChange({
      target: {
        value: selectedTheme ? `${selectedTheme.label}: ${selectedTheme.content}` : topic,
      },
    });
  };

  const ContentWrapper = ({ children, showBackButton, title }) => {
    const [isHovered, setIsHovered] = useState(false);
    const isStartPage = !showBackButton;
    return (
      <Box sx={{ py: 2, mt: 6 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 2,
            gap: 1,
            height: '40px',
            position: 'relative',
          }}
        >
          {showBackButton && (
            <Box
              onClick={() => {
                setStep(1);
                setSelectedSource(null);
                onChange({ target: { value: '' } });
                onPredefinedThemeChange({ target: { value: '' } });
                onYoutubeTranscriptChange('');
                onWikipediaContentChange('');
                onRandomizedContentChange('');
                onFileUpload('');
                if (inputRef.current) {
                  inputRef.current.value = '';
                }
              }}
              sx={{
                ...styles.backButton,
              }}
            >
              <ArrowBackIcon />
              <Typography sx={{ fontSize: '0.875rem', fontWeight: '500' }}>Tillbaka</Typography>
            </Box>
          )}
          <Typography
            variant='h6'
            sx={{
              fontWeight: '600',
              fontSize: '1.45rem',
              width: '100%',
              textAlign: 'center',
              position: 'absolute',
              left: 0,
              right: 0,
            }}
          >
            {title}
          </Typography>
        </Box>

        <Box
          sx={{
            ...styles.freshBox,
            padding: '30px',
            mb: 7,
            position: 'relative',
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <Box
            sx={{
              position: 'relative',
              '&::after': !isHovered
                ? {
                    content: '""',
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: '100px',
                    background: isStartPage
                      ? `linear-gradient(to bottom, transparent, ${theme.palette.background.paper})`
                      : 'transparent',
                    pointerEvents: 'none',
                    zIndex: 1,
                  }
                : {},
            }}
          >
            {isStartPage ? (
              <Collapse in={isHovered} collapsedSize={390} timeout='auto'>
                {children}
              </Collapse>
            ) : (
              children
            )}
          </Box>
          {!isHovered && (
            <Box
              sx={{
                position: 'absolute',
                bottom: 20,
                left: '50%',
                transform: 'translateX(-50%)',
                color: 'text.secondary',
                fontSize: '0.875rem',
                opacity: 0.7,
                transition: 'opacity 0.2s',
                zIndex: 2,
                display: 'none',
              }}
            >
              Visa mer
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  if (step === 1) {
    return (
      <ContentWrapper
        title={
          forQuiz
            ? '1. Välj underlag för ditt quiz'
            : forFlashcards
              ? '1. Välj underlag för dina flashcards'
              : '1. Välj underlag för din lektion'
        }
      >
        <Grid container spacing={3}>
          {/* Custom instructions - First in quiz/flashcards mode */}
          <Grid
            item
            xs={12}
            md={hasCustomThemes ? 4 : 4}
            sx={{ order: forQuiz || forFlashcards ? 1 : 6 }}
          >
            <ThemeFlowBox
              icon={EditIcon}
              title='Egna instruktioner'
              subtitle='Skriv vad du vill, t.ex. "Jämställdhetens historia".'
              onClick={() => handleSourceSelect('customTheme')}
              diffView={true}
            />
          </Grid>

          {/* File upload - Second in quiz/flashcards mode */}
          <Grid
            item
            xs={12}
            md={hasCustomThemes ? 4 : 4}
            sx={{ order: forQuiz || forFlashcards ? 4 : 7 }}
          >
            <ThemeFlowBox
              icon={AttachFileIcon}
              title='Ladda upp fil'
              subtitle='Ladda upp en Word- eller textfil.'
              onClick={() => handleSourceSelect('fileUpload')}
              diffView={true}
            />
          </Grid>

          {/* Subject - Third in quiz/flashcards mode */}
          <Grid
            item
            xs={12}
            md={hasCustomThemes ? 4 : 4}
            sx={{ order: forQuiz || forFlashcards ? 2 : 1 }}
          >
            <ThemeFlowBox
              icon={SchoolIcon}
              title='Ämne'
              subtitle='Ämnen från Skolverkets kursplan.'
              onClick={() => handleSourceSelect('subject')}
              diffView={true}
            />
          </Grid>

          {/* Lesson bank - Hide in quiz/flashcards mode */}
          {!forQuiz && !forFlashcards && (
            <Grid item xs={12} md={hasCustomThemes ? 4 : 4} sx={{ order: 2 }}>
              <ThemeFlowBox
                icon={StyleIcon}
                title='Lektionsbank'
                subtitle='Utvalda lektioner från vår lektionsbank.'
                onClick={() => handleSourceSelect('lessonBank')}
                diffView={true}
              />
            </Grid>
          )}

          {/* Predefined themes - Show only if hasCustomThemes */}
          {hasCustomThemes && (
            <Grid item xs={12} md={4} sx={{ order: 3 }}>
              <ThemeFlowBox
                icon={AutoAwesomeIcon}
                title='Utvalda teman'
                subtitle={
                  forQuiz
                    ? 'Teman som passar bra för quiz.'
                    : forFlashcards
                      ? 'Teman som passar bra för flashcards.'
                      : 'Teman som passar denna lektion.'
                }
                onClick={() => handleSourceSelect('predefined')}
                diffView={true}
              />
            </Grid>
          )}

          {/* Rest of the items with appropriate order */}
          <Grid
            item
            xs={12}
            md={hasCustomThemes ? 4 : 4}
            sx={{ order: forQuiz || forFlashcards ? 4 : 8 }}
          >
            <ThemeFlowBox
              icon={WikipediaIcon}
              title='Wikipedia-artikel'
              subtitle='Hämta innehåll från en stjärnmärkt Wikipedia-artikel.'
              onClick={() => handleSourceSelect('wikipedia')}
              diffView={true}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={hasCustomThemes ? 4 : 4}
            sx={{ order: forQuiz || forFlashcards ? 6 : 4 }}
          >
            <ThemeFlowBox
              icon={YouTubeIcon}
              iconImage='/img/urplaysmall.png'
              title='UR Play-filmer'
              subtitle='Välj en video producerad av UR Play.'
              onClick={() => handleSourceSelect('urplay')}
              diffView={true}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={hasCustomThemes ? 4 : 4}
            sx={{ order: forQuiz || forFlashcards ? 5 : 5 }}
          >
            <ThemeFlowBox
              icon={YouTubeIcon}
              title='YouTube-videos'
              subtitle='Välj en YouTube-video eller skriv in egen URL.'
              onClick={() => handleSourceSelect('youtube')}
              diffView={true}
            />
          </Grid>

          <Grid item xs={12} md={hasCustomThemes ? 4 : 4} sx={{ order: 9 }}>
            <ThemeFlowBox
              icon={ShuffleIcon}
              title='Slumpa ämne'
              subtitle='Låt oss slumpa fram ett spännande ämne åt dig.'
              onClick={() => handleSourceSelect('randomizer')}
              diffView={true}
            />
          </Grid>
        </Grid>
      </ContentWrapper>
    );
  }

  if (selectedSource === 'lessonBank') {
    return (
      <ContentWrapper showBackButton={step > 1} title='Utvalda lektioner från lektionsbanken'>
        {isLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mt: 4,
              minHeight: '276px',
            }}
          >
            <CircularProgress />
          </Box>
        ) : lessons.length < 1 ? (
          <Box sx={{ ...styles.successBox }}>
            <SentimentVerySatisfiedIcon sx={{ fontSize: 60, color: 'success.main', mb: 3 }} />
            <Typography
              variant='h6'
              sx={{
                color: 'success.main',
                fontWeight: 500,
                textAlign: 'center',
                width: '80%',
                margin: '0 auto',
                mb: 3,
              }}
            >
              Om ett par dagar kommer det finnas färdiga lektioner att välja mellan här. Så titta in
              snart igen! :)
            </Typography>
          </Box>
        ) : (
          <>
            <Typography
              variant='h6'
              sx={{ fontWeight: '500', mb: 4, fontSize: '1rem', textAlign: 'center' }}
            >
              Välj bland de mest poulära lektionerna som våra användare skapat för denna
              lektionstyp.
            </Typography>
            <Grid container spacing={2}>
              {lessons.map(lesson => (
                <Grid item xs={12} sm={6} md={4} key={lesson.id}>
                  <Box sx={{ position: 'relative' }}>
                    <LessonCard
                      name={lesson.lessonName}
                      comment={lesson.comment}
                      timestamp={lesson.timestamp}
                      lessonType={lesson.lessonType}
                      imageUrl={lesson.coverImage}
                      onClick={() => handleLessonSelect(lesson)}
                      size='large'
                      centered={true}
                      width='285px'
                      height='260px'
                    />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </ContentWrapper>
    );
  }

  if (selectedSource === 'predefined') {
    return (
      <ContentWrapper
        showBackButton={step > 1}
        title={
          forQuiz
            ? 'Utvalda teman för quiz'
            : forFlashcards
              ? 'Utvalda teman för flashcards'
              : 'Utvalda teman för denna lektionstyp'
        }
      >
        <Box sx={{ py: 2 }}>
          {allThemes.length > 0 && (
            <>
              <Grid container spacing={2}>
                {allThemes.map((theme, index) => {
                  if (theme.type === 'subheader') {
                    return (
                      <Grid item xs={12} key={`subheader-${index}`}>
                        <Typography
                          variant='h6'
                          sx={{ fontWeight: '500', mb: 2, fontSize: '1rem', textAlign: 'center' }}
                        >
                          {theme.label}
                        </Typography>
                      </Grid>
                    );
                  }

                  return (
                    <Grid item xs={12} sm={6} md={4} key={theme.value}>
                      <ThemeFlowBox
                        icon={theme.Icon}
                        title={theme.label}
                        isSelected={selectedTheme === theme.value}
                        onClick={() => {
                          setSelectedTheme(theme.value);
                          handlePredefinedThemeSelect(theme.value);
                        }}
                      />
                    </Grid>
                  );
                })}
              </Grid>

              <CustomThemeInput
                onSubmit={customValue => {
                  const newTheme = {
                    value: `custom-${Date.now()}`,
                    label: customValue,
                    Icon: EditIcon,
                    content: customValue,
                    type: 'custom',
                  };
                  setAllThemes([...additionalThemes, newTheme]);
                  setSelectedTheme(newTheme.value);
                  handlePredefinedThemeSelect(customValue);
                }}
                theme={theme}
              />
            </>
          )}
        </Box>
      </ContentWrapper>
    );
  }

  if (selectedSource === 'subject' || validSchoolSubjects.includes(value)) {
    return (
      <ContentWrapper showBackButton={step > 1} title='Välj ämne:'>
        {!validSchoolSubjects.includes(value) && (
          <Grid container spacing={2}>
            {schoolSubjects.map(subject => (
              <Grid item xs={12} sm={6} md={4} key={subject.value}>
                <ThemeFlowBox
                  icon={subject.Icon}
                  title={subject.label}
                  onClick={() => handleSubjectSelect(subject.value)}
                />
              </Grid>
            ))}
          </Grid>
        )}
        {validSchoolSubjects.includes(value) && (
          <CoursePlanSelector
            subject={value}
            theme={theme}
            styles={styles}
            onTopicSelect={onCoursePlanContentChange}
            forQuiz={forQuiz}
            forFlashcards={forFlashcards}
          />
        )}
      </ContentWrapper>
    );
  }

  if (selectedSource === 'customTheme') {
    return (
      <ContentWrapper showBackButton={step > 1} title='Skriv in eget tema: '>
        <TextField
          fullWidth
          defaultValue={customTheme}
          onBlur={e => onCustomThemeChange({ target: { value: e.target.value } })}
          placeholder='T.ex. Jämställdhetens historia, Nationaldagen i Albanien, Andra världskriget, Selma Lagerlöf...'
          inputRef={inputRef}
          variant='outlined'
          multiline={true} // Set to true or false, but keep it fixed
          rows={8} // Set to a fixed number
        />

        {forQuiz && (
          <Box sx={{ mt: 2 }}>
            <Suggestions listType='quizIdeas' maxSuggestions={6} onSelect={handleSelect} />
          </Box>
        )}

        {forFlashcards && (
          <Box sx={{ mt: 2 }}>
            <Suggestions listType='flashcardIdeas' maxSuggestions={6} onSelect={handleSelect} />
          </Box>
        )}
      </ContentWrapper>
    );
  }

  if (selectedSource === 'youtube') {
    return (
      <ContentWrapper showBackButton={step > 1} title='Välj video:'>
        <YouTubeSelector
          subject={value}
          onTranscriptChange={onYoutubeTranscriptChange}
          forQuiz={forQuiz}
          forFlashcards={forFlashcards}
        />
      </ContentWrapper>
    );
  }

  if (selectedSource === 'urplay') {
    return (
      <ContentWrapper showBackButton={step > 1} title='Välj video:'>
        <YouTubeSelector
          subject={value}
          onTranscriptChange={onYoutubeTranscriptChange}
          urplay={true}
          forQuiz={forQuiz}
          forFlashcards={forFlashcards}
        />
      </ContentWrapper>
    );
  }

  if (selectedSource === 'wikipedia') {
    return (
      <ContentWrapper showBackButton={step > 1} title='Hämta Wikipedia-artikel'>
        <WikiFetcher
          forQuiz={forQuiz}
          forFlashcards={forFlashcards}
          styles={styles}
          theme={theme}
          onWikipediaChange={onWikipediaContentChange}
        />
      </ContentWrapper>
    );
  }

  if (selectedSource === 'fileUpload') {
    return (
      <ContentWrapper showBackButton={step > 1} title='Ladda upp fil (.docx, .doc eller .txt)'>
        <FileUploader
          theme={theme}
          styles={styles}
          forQuiz={forQuiz}
          forFlashcards={forFlashcards}
          onFileContent={content => {
            onChange({ target: { value: 'fileUpload' } });
            if (onFileUpload) {
              onFileUpload(content);
            }
          }}
        />
      </ContentWrapper>
    );
  }

  if (selectedSource === 'randomizer') {
    return (
      <ContentWrapper showBackButton={step > 1} title='Slumpa fram ett ämne'>
        <Randomizer
          styles={styles}
          theme={theme}
          forQuiz={forQuiz}
          forFlashcards={forFlashcards}
          onSelect={content => {
            if (onRandomizedContentChange) {
              onRandomizedContentChange(content);
            }
          }}
        />
      </ContentWrapper>
    );
  }

  return null;
};

export { ThemeSelectorFlow };
