import React from 'react';
import { Popover, Box, Avatar, Typography, Button } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import LogoutIcon from '@mui/icons-material/Logout';

const SettingsPopover = ({
  user,
  isDark,
  openPopover,
  anchorEl,
  handlePopoverClose,
  handleLogout,
  openSettings,
}) => {
  const popoverId = openPopover ? 'user-popover' : undefined;

  return (
    <Popover
      id={popoverId}
      open={openPopover}
      anchorEl={anchorEl}
      onClose={handlePopoverClose}
      PaperProps={{
        sx: {
          borderRadius: '10px',
        },
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      {user && (
        <Box sx={{ p: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Avatar src={user.photoURL} alt={user.displayName} />
            <Box sx={{ ml: 2 }}>
              <Typography variant='subtitle1'>
                <strong>{user.displayName}</strong>
              </Typography>
              <Typography variant='body2'>{user.email}</Typography>
              <Typography variant='body2' color='textSecondary'>
                ID: {user.uid}
              </Typography>
            </Box>
          </Box>

          <Typography variant='subtitle1' sx={{ fontWeight: 'bold', marginBottom: '6px' }}>
            Användning
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '25px',
              alignItems: 'center',
              backgroundColor: isDark ? '#22303c' : '#eee9ff',
              borderRadius: '15px',
              padding: '15px',
            }}
          >
            <Typography
              variant='body2'
              sx={{
                fontSize: '15px',
                fontWeight: '500',
                color: isDark ? '#f7f7f7' : '#8b5cf6',
              }}
            >
              Tokens:
            </Typography>
            <Typography
              variant='body2'
              sx={{
                fontSize: '15px',
                fontWeight: '500',
                color: isDark ? '#f7f7f7' : '#8b5cf6',
              }}
            >
              {user.tokens}/
              {user.guest
                ? process.env.REACT_APP_GUEST_TOKEN_LIMIT
                : process.env.REACT_APP_REGISTERED_TOKEN_LIMIT}
            </Typography>
          </Box>

          <Typography variant='subtitle1' sx={{ fontWeight: 'bold', marginBottom: '6px' }}>
            Inställningar
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '25px',
              alignItems: 'center',
              backgroundColor: isDark ? '#22303c' : '#eee9ff',
              borderRadius: '15px',
              padding: '15px',
              cursor: 'pointer',
            }}
            onClick={() => {
              openSettings();
              handlePopoverClose();
            }}
          >
            <Typography
              variant='body2'
              sx={{
                fontSize: '15px',
                fontWeight: '500',
                color: isDark ? '#f7f7f7' : '#8b5cf6',
              }}
            >
              Inställningar
            </Typography>
            <NavigateNextIcon sx={{ color: isDark ? '#f7f7f7' : '#8b5cf6' }} />
          </Box>

          <Button
            startIcon={<LogoutIcon />}
            variant='contained'
            color='primary'
            onClick={() => {
              handleLogout();
              handlePopoverClose();
            }}
          >
            Logga ut
          </Button>
        </Box>
      )}
    </Popover>
  );
};

export default SettingsPopover;
