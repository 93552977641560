import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Tabs, Tab, Skeleton } from '@mui/material';
import { collection, query, where, orderBy, limit, getDocs } from 'firebase/firestore';
import { db } from './firebase';
import { useNavigate } from 'react-router-dom';
import LessonCard from './LessonCard';
import StarIcon from '@mui/icons-material/Star';
import { lessonMapping, getLessonTypeParam, lessonUrlMapping } from './functions';
import { LessonsSkeleton } from './TeacherSkeleton';
const TabPanel = ({ children, value, index, ...other }) => (
  <div
    role='tabpanel'
    hidden={value !== index}
    id={`lesson-tabpanel-${index}`}
    aria-labelledby={`lesson-tab-${index}`}
    {...other}
  >
    {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
  </div>
);

const FeaturedLessons = ({ theme, styles }) => {
  const [featuredLessons, setFeaturedLessons] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    loadFeaturedLessons();
  }, []);

  const loadFeaturedLessons = async () => {
    try {
      const q = query(
        collection(db, 'lesson_usage'),
        where('featured', '==', true),
        where('hasPrinted', '==', true),
        orderBy('timestamp', 'desc'),
        limit(100)
      );
      const querySnapshot = await getDocs(q);
      const lessonData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        timestamp: doc.data().timestamp?.toDate(),
      }));
      setFeaturedLessons(lessonData);
    } catch (error) {
      console.error('Error loading featured lessons:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLessonClick = lesson => {
    const shortId = lesson.id.substring(0, 10);
    const typeParam = getLessonTypeParam(lesson.lessonType);
    const urlPath = lessonUrlMapping(lesson.lessonType);
    navigate(`/app/lektioner/${urlPath}/${shortId}?type=${typeParam}`);
  };

  const groupedLessons = featuredLessons.reduce((acc, lesson) => {
    const mappedType = lessonMapping(lesson.lessonType);
    if (!acc[mappedType]) {
      acc[mappedType] = [];
    }
    acc[mappedType].push(lesson);
    return acc;
  }, {});

  const tabTypes = Object.keys(groupedLessons);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
        <StarIcon
          sx={{
            color: theme.palette.mode === 'dark' ? 'gold' : '#FFD700',
            fontSize: '2rem',
            display: 'none',
          }}
        />
        <Typography variant='h5' sx={{ fontWeight: 'bold' }}>
          Utvalda lektioner
        </Typography>
      </Box>
      <Box sx={{ ...styles.newBox, padding: '0px', width: '100%' }}>
        {isLoading ? (
          <LessonsSkeleton theme={theme} tabCount={7} />
        ) : tabTypes.length > 0 ? (
          <>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                background: theme.palette.mode === 'dark' ? '#22303c' : '#f9f9fa',
                borderTopLeftRadius: '9px',
                borderTopRightRadius: '9px',
              }}
            >
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                variant='scrollable'
                scrollButtons='auto'
                aria-label='lesson type tabs'
              >
                {tabTypes.map((type, index) => (
                  <Tab
                    key={type}
                    label={type}
                    id={`lesson-tab-${index}`}
                    aria-controls={`lesson-tabpanel-${index}`}
                    sx={{
                      padding: '20px',
                      textTransform: 'none',
                      fontSize: '1rem',
                    }}
                  />
                ))}
              </Tabs>
            </Box>

            {tabTypes.map((type, index) => (
              <TabPanel key={type} value={selectedTab} index={index}>
                <Box sx={{ padding: '0px', paddingTop: '20px' }}>
                  <Grid container spacing={3}>
                    {groupedLessons[type].map(lesson => (
                      <Grid item xs={12} sm={6} md={4} key={lesson.id}>
                        <LessonCard
                          name={lesson.lessonName}
                          comment={lesson.comment}
                          timestamp={lesson.timestamp}
                          lessonType={lesson.lessonType}
                          imageUrl={lesson.coverImage}
                          onClick={() => handleLessonClick(lesson)}
                          size='small'
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </TabPanel>
            ))}
          </>
        ) : (
          <Box sx={{ textAlign: 'center', mt: 4 }}>
            <Typography variant='body1' color='text.secondary'>
              Inga utvalda lektioner just nu
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default FeaturedLessons;
