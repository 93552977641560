import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  CircularProgress,
  Alert,
  Container,
} from '@mui/material';

const PodcastGenerator = () => {
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [audioUrl, setAudioUrl] = useState(null);

  const pollResult = async eventId => {
    for (let i = 0; i < 60; i++) {
      try {
        const response = await fetch(`http://localhost:3001/api/podcast/result/${eventId}`);
        const data = await response.json();

        if (data.url) {
          return data.url;
        }
        await new Promise(resolve => setTimeout(resolve, 5000));
      } catch (error) {
        console.error('Polling error:', error);
      }
    }
    throw new Error('Generation timeout - please try again');
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (!input.trim()) {
      setError('Please enter some text');
      return;
    }

    setLoading(true);
    setError(null);
    setAudioUrl(null);

    try {
      const response = await fetch('http://localhost:3001/api/podcast/generate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          textInput: input,
        }),
      });

      if (!response.ok) {
        throw new Error('Generation failed - please try again');
      }

      const { eventId } = await response.json();
      if (!eventId) {
        throw new Error('Invalid response from server');
      }

      const url = await pollResult(eventId);
      setAudioUrl(url);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth='md'>
      <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
        <Typography variant='h4' gutterBottom>
          Generate AI Podcast
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            multiline
            rows={4}
            value={input}
            onChange={e => setInput(e.target.value)}
            placeholder='Enter your text here...'
            sx={{ mb: 2 }}
          />
          <Button
            fullWidth
            type='submit'
            variant='contained'
            disabled={loading || !input.trim()}
            sx={{ height: 56 }}
          >
            {loading ? <CircularProgress size={24} color='inherit' /> : 'Generate Podcast'}
          </Button>
        </form>

        {error && (
          <Alert severity='error' sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}

        {audioUrl && (
          <Box mt={2}>
            <audio controls style={{ width: '100%' }} autoPlay={false}>
              <source src={audioUrl} type='audio/mpeg' />
              Your browser does not support the audio element.
            </audio>
          </Box>
        )}
      </Paper>
    </Container>
  );
};

export default PodcastGenerator;
