import React from 'react';
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  Switch,
  Typography,
  Box,
  Tooltip,
  Grid,
} from '@mui/material';
import PublicIcon from '@mui/icons-material/Public';
import LockIcon from '@mui/icons-material/Lock';
import LanguageSelector from './LanguageSelector';
import { AIModelSelect } from './TestComponents2';

const AdditionalFlashFields = ({
  aiInstructions,
  setAiInstructions,
  numFlashcards,
  setNumFlashcards,
  category,
  setCategory,
  language,
  setLanguage,
  difficulty,
  setDifficulty,
  visibility,
  handleVisibilityChange,
  theme,
  selectedModel,
  setSelectedModel,
}) => {
  return (
    <Box>
      <Grid container spacing={5} sx={{ mt: 1 }}>
        {/* Number of Flashcards */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <Typography variant='formLabelSinUnderline' sx={{ display: 'block' }}>
              Antal flashcards
            </Typography>
            <Select
              labelId='num-flashcards-label'
              id='num-flashcards-select'
              value={numFlashcards}
              sx={{ mt: 1 }}
              onChange={e => setNumFlashcards(e.target.value)}
            >
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={15}>15</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <Tooltip title='Endast tillgängligt för premium-användare'>
                <span>
                  <MenuItem value={25} disabled>
                    25
                  </MenuItem>
                  <MenuItem value={30} disabled>
                    30
                  </MenuItem>
                  <MenuItem value={40} disabled>
                    40
                  </MenuItem>
                  <MenuItem value={50} disabled>
                    50
                  </MenuItem>
                </span>
              </Tooltip>
            </Select>
          </FormControl>
        </Grid>

        {/* Category */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <Typography variant='formLabelSinUnderline' sx={{ display: 'block' }}>
              Kategori
            </Typography>
            <Select sx={{ mt: 1 }} value={category} onChange={e => setCategory(e.target.value)}>
              <MenuItem value='Välj kategori' disabled>
                Välj kategori
              </MenuItem>
              <MenuItem value='Historia'>Historia</MenuItem>
              <MenuItem value='Matematik'>Matematik</MenuItem>
              <MenuItem value='Vetenskap'>Vetenskap</MenuItem>
              <MenuItem value='Fysik'>Fysik</MenuItem>
              <MenuItem value='Språk'>Språk</MenuItem>
              <MenuItem value='Litteratur'>Litteratur</MenuItem>
              <MenuItem value='Film'>Film</MenuItem>
              <MenuItem value='Konst'>Konst</MenuItem>
              <MenuItem value='Musik'>Musik</MenuItem>
              <MenuItem value='Idrott'>Idrott</MenuItem>
              <MenuItem value='Teknik'>Teknik</MenuItem>
              <MenuItem value='Filosofi'>Filosofi</MenuItem>
              <MenuItem value='Religion'>Religion</MenuItem>
              <MenuItem value='Biologi'>Biologi</MenuItem>
              <MenuItem value='Psykologi'>Psykologi</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* Language */}
        <Grid item xs={12} md={6}>
          <Typography variant='formLabelSinUnderline' sx={{ display: 'block' }}>
            AI-modell:
          </Typography>

          <AIModelSelect
            disabledOptions={['Gemini Pro', 'Mistral Large']}
            selectedModel={selectedModel}
            setSelectedModel={setSelectedModel}
          />
        </Grid>

        {/* Difficulty */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <Typography variant='formLabelSinUnderline' sx={{ display: 'block' }}>
              Svårighetsgrad
            </Typography>
            <Select value={difficulty} sx={{ mt: 1 }} onChange={e => setDifficulty(e.target.value)}>
              <MenuItem value='Lätt'>Lätt</MenuItem>
              <MenuItem value='Medel'>Medel</MenuItem>
              <MenuItem value='Svårt'>Svårt</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* AI Instructions - Full width */}
        <Grid item xs={12}>
          <Tooltip title='Håll det kort, koncist och tydligt för bättre resultat.' placement='top'>
            <Typography variant='formLabel'>Kompletterande instruktioner</Typography>
          </Tooltip>
          <TextField
            fullWidth
            sx={{ mt: 1 }}
            margin='normal'
            placeholder="T.ex. 'Ta inte med information mellan 1963-1968', eller 'undvik oregelbundna verb'."
            value={aiInstructions}
            onChange={e => setAiInstructions(e.target.value)}
            inputProps={{ maxLength: 100 }}
          />
        </Grid>

        {/* Visibility - Full width */}
        <Grid item xs={12}>
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            sx={{
              backgroundColor: theme.palette.mode === 'dark' ? '#394653' : '#f5f5f5',
              borderRadius: '10px',
              padding: '12px',
              paddingTop: '5px',
              paddingBottom: '5px',

              mb: 4, // Added more bottom margin
            }}
          >
            <Box display='flex' alignItems='center'>
              <Tooltip title='Ska samlingen vara tillgänglig för andra att använda?'>
                <Typography variant='formLabel'>Synlighet</Typography>
              </Tooltip>
              {visibility === 'public' ? (
                <PublicIcon sx={{ marginLeft: 1, fontSize: '20px' }} />
              ) : (
                <LockIcon sx={{ marginLeft: 1, fontSize: '20px' }} />
              )}
            </Box>
            <Box display='flex' alignItems='center'>
              <Typography variant='caption' sx={{ mr: 1 }}>
                {visibility === 'public' ? 'Publik' : 'Privat'}
              </Typography>
              <Switch
                checked={visibility === 'public'}
                onChange={handleVisibilityChange}
                name='visibility'
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AdditionalFlashFields;
