import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  ListItem,
  ListItemText,
  IconButton,
  Card,
  CardContent,
  Paper,
  Chip,
  ListItemIcon, // Ensure ListItemIcon is imported if used
} from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import StarIcon from '@mui/icons-material/Star';

import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { Helmet } from 'react-helmet-async';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
// **Newly Added Icon Imports**
import { features } from './app/functions';

import { Link } from 'react-router-dom';
import { TypeAnimation } from 'react-type-animation';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { motion } from 'framer-motion';
import { getAIModelImage } from './app/TestComponents2';

import UniversitySection from './UniversitySection';
import MediaSection from './MediaSection';
import FAQSection from './FAQSection';
import Logger from './app/Logger';
import TestimonialsSection from './TestimonialsSection';
import {
  GradientBackground,
  StyledButton,
  FeatureSection,
  FeatureList,
  ResponsiveVideo,
} from './StyledComponents';

import { getStyles } from './index';

import Header from './Header';
import Footer from './Footer';

const StudentsPage = ({ toggleDarkMode, isDark }) => {
  const theme = useTheme();
  const styles = getStyles(theme);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>{`AI-verktyg för elever | Studera.ai`}</title>
        <meta
          name='description'
          content='Hitta alla AI-verktyg för elever. Spara oceaner av tid genom att använda AI i skolan.'
        />
      </Helmet>

      <Header />

      <GradientBackground className='gradient-background'>
        <Container maxWidth='xl' sx={{ pt: { xs: 10, md: 20 }, pb: 15, position: 'relative' }}>
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={12} md={6}>
              <Box sx={{ pl: { xs: 0, md: 4 } }}>
                <motion.div
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8 }}
                >
                  <Box sx={{ display: 'flex', mb: 2 }}>
                    <Chip
                      label={
                        <span>
                          Studera.ai{' '}
                          <span style={{ opacity: 0.7 }}>
                            &nbsp;•&nbsp; Revolutionerar lärande med AI
                          </span>
                        </span>
                      }
                      sx={{
                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                        color: 'white',
                        fontWeight: '600',
                        fontSize: '0.8rem',
                        padding: '2px 14px',

                        borderRadius: '16px',
                        '& .MuiChip-label': {
                          padding: '0 4px',
                        },
                      }}
                    />
                  </Box>

                  <Typography
                    variant='h2'
                    component='h1'
                    gutterBottom
                    sx={{
                      fontWeight: 900,
                      letterSpacing: -1,
                      fontSize: { xs: '2.5rem', md: '3.35rem' },
                      mb: 5,
                    }}
                  >
                    AI-verktyg för elever
                  </Typography>
                  <Typography
                    sx={{
                      mb: 4,
                      opacity: 0.8,
                      fontSize: '1.3rem',
                      fontWeight: '500',
                      lineHeight: '2rem',
                    }}
                  >
                    Få tillgång till AI-verktyg som är anpassade för att hjälpa elever att maximera
                    sina studieresultat. Vårt mål är att erbjuda elever hjälpsamma AI-verktyg som
                    stöd genom hela sin utbildning - från grundskolan till högre utbildning.
                  </Typography>

                  <Link to='/app' style={{ textDecoration: 'none' }}>
                    <StyledButton
                      variant='blue'
                      color='primary'
                      size='large'
                      endIcon={<KeyboardDoubleArrowRightIcon />}
                      sx={{ borderRadius: '28px', padding: '13px 50px', fontSize: '20px' }}
                    >
                      Testa Studera.ai helt gratis
                    </StyledButton>
                  </Link>
                </motion.div>
              </Box>
            </Grid>

            {/* Image on the right */}
            <Grid
              item
              xs={12}
              md={6}
              sx={{ position: 'relative' }}
              sx={{ display: { xs: 'none', md: 'block' } }}
            >
              <motion.div
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.8, delay: 0.2 }}
              >
                <Link to='/app' style={{ textDecoration: 'none' }}>
                  <Box
                    component='img'
                    src={theme.palette.mode === 'dark' ? '/img/d.png' : '/img/l.png'}
                    alt='Studera med AI'
                    sx={{
                      width: 'calc(100% + 100px)', // Stretch the image beyond its container
                      height: 'auto',
                      borderRadius: '7px',
                      position: 'relative',
                      right: '-50px', // Moves the image to touch the scrollbar
                    }}
                  />
                </Link>
              </motion.div>
            </Grid>
          </Grid>
        </Container>
      </GradientBackground>

      {/*
      <GradientBackground className='gradient-background'>
        <Container
          sx={{ pt: { xs: 10, md: 20 }, pb: 15, position: 'relative', textAlign: 'center' }}
        >
          <Grid container spacing={6} alignItems='center'>
            <Grid item xs={12} md={12} sx={{ textAlign: 'center' }}>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                  <Chip
                    label={
                      <span>
                        Studera.ai{' '}
                        <span style={{ opacity: 0.7 }}>
                          &nbsp;•&nbsp; Revolutionerar lärande med AI
                        </span>
                      </span>
                    }
                    sx={{
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                      color: 'white',
                      fontWeight: '600',
                      fontSize: '0.8rem',
                      padding: '2px 14px',

                      borderRadius: '16px',
                      '& .MuiChip-label': {
                        padding: '0 4px',
                      },
                    }}
                  />
                </Box>

                <Typography
                  variant='h2'
                  component='h1'
                  gutterBottom
                  sx={{
                    fontWeight: 900,
                    letterSpacing: -1,
                    fontSize: { xs: '2.5rem', md: '3.35rem' },
                    width: { xs: '100%', md: '70%' },
                    textAlign: 'center',
                    display: 'block',
                    margin: '0 auto',
                  }}
                >
                  AI-verktyg för elever
                </Typography>
                <Typography
                  variant='h5'
                  sx={{
                    mb: 4,
                    opacity: 0.8,
                    fontSize: '1.3rem',
                    width: { xs: '100%', md: '70%' },
                    margin: '0 auto',
                    fontWeight: '500',
                    lineHeight: '31px',
                    display: 'block',
                    mt: 4,
                  }}
                >
                  Med Studera.ai får du tillgång till en helt ny värld av AI-verktyg som är
                  anpassade för att hjälpa elever att maximera sina studieresultat. Vårt mål är att
                  ge dig stöd genom hela din utbildning, från grundläggande frågor till komplex
                  problemlösning. <br />
                  <br />
                  Vi vet att utbildning handlar om mer än att bara minnas fakta – det handlar om att
                  förstå och kunna tillämpa kunskap. Därför erbjuder vi AI-lösningar som hjälper dig
                  att analysera, förklara och bygga upp förståelse på ett engagerande och
                  strukturerat sätt.
                </Typography>

                <Box sx={{ textAlign: 'center', mt: 7 }}>
                  <Link to='/app' style={{ textDecoration: 'none' }}>
                    <StyledButton
                      variant='contained'
                      color='primary'
                      size='large'
                      endIcon={<KeyboardDoubleArrowRightIcon />}
                      sx={{ borderRadius: '28px', padding: '13px 50px', fontSize: '20px' }}
                    >
                      Testa Studera.ai nu
                    </StyledButton>
                  </Link>
                </Box>
              </motion.div>
            </Grid>
          </Grid>
        </Container>
      </GradientBackground>
*/}
      <Box sx={{ bgcolor: 'background.paper', py: 8, paddingTop: '100px', position: 'relative' }}>
        <Container maxWidth='lg'>
          <Typography
            variant='h2'
            align='center'
            sx={{ fontWeight: 'bold', fontSize: '2.3em', mb: 2 }}
          >
            Upptäck möjligheterna med Studera.ai
          </Typography>
          <Typography variant='subtitle1' align='center' color='text.secondary' sx={{ mb: 4 }}>
            Utforska några av de populäraste funktionerna
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <KeyboardDoubleArrowDownIcon
              sx={{
                fontSize: '4rem',
                color: '#6424c1',
                animation: 'bounce 2s infinite',
              }}
            />
          </Box>
        </Container>

        <style>
          {`
      @keyframes bounce {
        0%, 20%, 50%, 80%, 100% {
          transform: translateY(0);
        }
        40% {
          transform: translateY(-30px);
        }
        60% {
          transform: translateY(-15px);
        }
      }
    `}
        </style>
      </Box>

      {features
        .filter(feature => !feature.hideOnStartpage)
        .map((feature, index) => {
          const hasModels = Boolean(feature.models && feature.models.length > 0);
          const isImageRight = index % 2 === 0;

          return (
            <FeatureSection
              key={index}
              id={feature.title.toLowerCase().replace(/ /g, '-')}
              sx={{
                bgcolor: index % 2 === 0 ? 'background.default' : 'background.paper',
                padding: { xs: '30px 0px', md: '80px 0px' },
              }}
            >
              <Container>
                <Grid
                  container
                  spacing={6}
                  alignItems='center'
                  direction={isImageRight ? 'row' : 'row-reverse'}
                >
                  {/* Text Content */}
                  <Grid
                    item
                    xs={12}
                    md={hasModels ? 12 : 6}
                    sx={{
                      textAlign: hasModels ? 'center' : 'left',
                    }}
                  >
                    <Typography
                      variant='h3'
                      gutterBottom
                      sx={{
                        fontWeight: 'bold',
                        fontSize: { xs: '1.6em', md: '2.3em' },
                        textAlign: hasModels ? 'center' : 'left',
                      }}
                    >
                      {feature.title}
                    </Typography>
                    <Typography
                      variant='h6'
                      paragraph
                      color='text.secondary'
                      sx={{
                        textAlign: hasModels ? 'center' : 'left',
                      }}
                    >
                      {feature.description}
                    </Typography>

                    {hasModels ? (
                      // If feature has models, render them
                      <Grid
                        container
                        spacing={2}
                        justifyContent='center'
                        sx={{ marginTop: hasModels ? '20px' : 'inherit' }}
                      >
                        {feature.models.map((model, idx) => (
                          <Grid item xs={12} sm={6} md={4} key={idx}>
                            <Card
                              sx={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                padding: 2,
                                boxShadow: 'none',
                                transition: 'transform 0.3s, box-shadow 0.3s',
                                '&:hover': {
                                  transform: 'translateY(-10px)',
                                },
                              }}
                            >
                              <CardContent sx={{ textAlign: 'center' }}>
                                <Box
                                  component='img'
                                  src={getAIModelImage(model.name)}
                                  alt={model.name}
                                  sx={{ width: 60, height: 60, mb: 2, borderRadius: '9px' }}
                                />
                                <Typography variant='h6' gutterBottom>
                                  {model.name}
                                </Typography>
                                <Typography variant='body2' color='text.secondary'>
                                  {model.description}
                                </Typography>
                              </CardContent>
                            </Card>
                          </Grid>
                        ))}
                      </Grid>
                    ) : (
                      // If feature has details, render them
                      <FeatureList
                        sx={{
                          backgroundColor:
                            theme.palette.mode === 'dark' ? 'transparent' : 'inherit',
                        }}
                      >
                        {feature.details
                          .filter(detail => detail.showInFeaturePage !== true)
                          .map((detail, idx) => (
                            <ListItem
                              key={idx}
                              alignItems='flex-start'
                              sx={{ marginLeft: '0px', paddingLeft: '0px' }}
                            >
                              <ListItemIcon
                                sx={{
                                  '& > svg': {
                                    fontSize: '34px',
                                    color: theme.palette.mode === 'dark' ? '#9575F7' : '#6424c1',
                                  },
                                  minWidth: '40px',
                                  marginRight: '15px',
                                  marginLeft: '0px',
                                }}
                              >
                                {detail.icon}
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  <Typography sx={{ fontWeight: '600', fontSize: '17px' }}>
                                    {detail.text.split(':')[0]}
                                  </Typography>
                                }
                                secondary={
                                  <Typography
                                    sx={{
                                      fontSize: '15px',
                                      color: theme.palette.mode === 'dark' ? '#8899a6' : 'gray',
                                    }}
                                  >
                                    {detail.text.split(':')[1]}
                                  </Typography>
                                }
                              />
                            </ListItem>
                          ))}
                      </FeatureList>
                    )}

                    <Box mt={4} display='flex' justifyContent={hasModels ? 'center' : 'flex-start'}>
                      <Link
                        to={
                          feature.goDirectlyToApp
                            ? '/app'
                            : feature.customLink
                              ? `/funktioner/${feature.customLink}`
                              : `/funktioner/${feature.title.toLowerCase().replace(/ /g, '-').replace(/å/g, 'a').replace(/ä/g, 'a').replace(/ö/g, 'o')}`
                        }
                        style={{ textDecoration: 'none' }}
                      >
                        <StyledButton
                          variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                          color='primary'
                          endIcon={<ArrowForwardIcon />}
                        >
                          Lär dig mer
                        </StyledButton>
                      </Link>
                    </Box>
                  </Grid>

                  {/* Image or Video Content */}
                  {!hasModels && (
                    <Grid
                      item
                      xs={12}
                      md={6}
                      sx={{
                        position: 'relative',
                        display: { xs: 'none', md: 'block' },
                      }}
                    >
                      {feature.video ? (
                        // If feature has a video, render the video
                        <Paper
                          elevation={3}
                          sx={{
                            borderRadius: 2,
                            overflow: 'hidden',
                            background: 'none',
                            backgroundImage: 'none',
                            boxShadow: 'none',
                          }}
                        >
                          <ResponsiveVideo
                            light={feature.video.light}
                            dark={feature.video.dark}
                            theme={theme}
                            alt={`${feature.title} demonstration`}
                          />
                        </Paper>
                      ) : feature.img ? (
                        // If feature has an image, render the image
                        <motion.div
                          initial={{ opacity: 0, scale: 0.8 }}
                          animate={{ opacity: 1, scale: 1 }}
                          transition={{ duration: 0.8, delay: 0.2 }}
                        >
                          <Link to='/app' style={{ textDecoration: 'none' }}>
                            <Box
                              component='img'
                              src={
                                theme.palette.mode === 'dark' ? feature.img.dark : feature.img.light
                              }
                              alt={`${feature.title} demonstration`}
                              sx={{
                                width: 'calc(100% + 100px)', // Stretch the image beyond its container
                                height: 'auto',
                                borderRadius: '7px',
                                position: 'relative',
                                ...(isImageRight
                                  ? { right: '-50px' } // Moves the image to touch the scrollbar on the right
                                  : { left: '-50px' }), // Moves the image to start at the left edge
                              }}
                            />
                          </Link>
                        </motion.div>
                      ) : null}
                    </Grid>
                  )}
                </Grid>
              </Container>
            </FeatureSection>
          );
        })}

      <FAQSection />

      <Container sx={{ my: 15, textAlign: 'center' }}>
        <Typography variant='h3' gutterBottom sx={{ fontWeight: 'bold', fontSize: '2.3em' }}>
          Redo att revolutionera ditt lärande?
        </Typography>
        <Typography variant='h6' sx={{ mb: 4, color: 'text.secondary' }}>
          Anslut dig till 3000+ studenter som redan förbättrat sina studieresultat med Studera.AI
        </Typography>
        <Link to='/app' style={{ textDecoration: 'none' }}>
          <StyledButton
            variant='contained'
            color='primary'
            size='large'
            endIcon={<ArrowForwardIcon />}
          >
            Prova Studera.AI gratis nu
          </StyledButton>
        </Link>
      </Container>

      <TestimonialsSection />

      <UniversitySection bgcolor='background.default' showHeadline={false} />

      <MediaSection bgcolor='background.default' />

      <Footer />

      {!isMobile && (
        <Box
          sx={{
            position: 'fixed',
            bottom: 16,
            right: 16,
            zIndex: 9999,
          }}
        >
          <IconButton onClick={toggleDarkMode}>
            {isDark ? <LightModeIcon /> : <DarkModeIcon />}
          </IconButton>
        </Box>
      )}
    </>
  );
};

export default StudentsPage;
