import React, { useState, useEffect } from 'react';
import { Box, Typography, Checkbox, Paper, Stack } from '@mui/material';
import BallotIcon from '@mui/icons-material/Ballot';
import ShortTextIcon from '@mui/icons-material/ShortText';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

const QuestionTypeSelector = ({ setQuestionType, disableTrueFalse = false, theme }) => {
  const [selectedType, setSelectedType] = useState('multipleChoice');

  useEffect(() => {
    setQuestionType(selectedType);
  }, [selectedType, setQuestionType]);

  const handleChange = value => {
    if (!(disableTrueFalse && value === 'trueFalse')) {
      setSelectedType(value);
    }
  };

  const options = [
    {
      value: 'multipleChoice',
      label: 'Flervalsfrågor',
      description: 'Flervalsfrågor med ett korrekt svar',
      icon: <BallotIcon />,
    },
    {
      value: 'fill-in-the-blank',
      label: 'Lucktext',
      description: 'Frågor där man fyller i utelämnat ord',
      icon: <ShortTextIcon />,
    },
    {
      value: 'true-or-false',
      label: 'Sant/Falskt',
      description: 'Påståenden som är sanna eller falska',
      icon: <FactCheckIcon />,
    },
  ];

  return (
    <Stack
      direction='row'
      spacing={2}
      sx={{
        width: '100%',
        mb: 4,
        flexWrap: { xs: 'wrap', md: 'nowrap' },
      }}
    >
      {options.map(option => (
        <Paper
          key={option.value}
          elevation={0}
          sx={{
            flex: 1,
            minWidth: { xs: '100%', md: '200px' },
            cursor: disableTrueFalse && option.value === 'trueFalse' ? 'not-allowed' : 'pointer',
            position: 'relative',
            borderRadius: 2,
            border: theme =>
              `1px solid ${
                selectedType === option.value ? theme.palette.text.main : theme.palette.divider
              }`,
            bgcolor: theme =>
              selectedType === option.value ? theme.palette.text.main + '10' : 'background.paper',
            opacity: disableTrueFalse && option.value === 'trueFalse' ? 0.5 : 1,

            '&:hover': {
              border: theme => `1px solid ${theme.palette.text.main}`,

              bgcolor: theme =>
                selectedType === option.value
                  ? theme.palette.text.main + '20'
                  : theme.palette.action.hover,
            },
          }}
          onClick={() => handleChange(option.value)}
        >
          <Box sx={{ p: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Checkbox
                checked={selectedType === option.value}
                checkedIcon={<CheckCircleIcon />}
                icon={<RadioButtonUncheckedIcon />}
                sx={{
                  p: 0,
                  mr: 1,
                  color: 'text.main',
                  '&.Mui-checked': {
                    color: 'text.main',
                  },
                }}
              />
              <Typography variant='subtitle1' sx={{ fontWeight: 600 }}>
                {option.label}
              </Typography>
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <Box
                sx={{
                  color: 'text.main',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {option.icon}
              </Box>
              <Typography variant='body2' color='text.secondary'>
                {option.description}
              </Typography>
            </Box>
          </Box>
        </Paper>
      ))}
    </Stack>
  );
};

export default QuestionTypeSelector;
