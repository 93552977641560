import React from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Container,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const faqData = [
  {
    question: 'Vad kan jag som elev använda Studera.ai till?',
    answer:
      'Studera.ai är en mångsidig plattform för AI-driven inlärning. Du kan använda den för att chatta med AI-lärare, lära dig språk, generera och lösa quiz, skapa flashcards, få hjälp med komplexa ämnen, träna på högskoleprovet, förbättra dina texter, analysera AI-genererat innehåll, simulera debatter för att slipa på din argumentationsteknik, och mycket mer. Det är en komplett tjänst som erbjuder mängder av AI-verktyg för att förbättra din inlärning och akademiska prestationer - allt från grundskolan hela vägen till universitetet.',
  },
  {
    question: 'Hur kan jag använda AI-chatbotar för att lära mig språk?',
    answer:
      'Våra AI-chatbotar är utmärkta för språkinlärning. Du kan välja en språklärare och engagera dig i konversationer på det språk du vill lära dig. Boten kan anpassa svårighetsgraden, rätta dina misstag, förklara grammatik, och till och med simulera olika rollspel som restaurangbesök eller jobbintervjuer. Detta ger dig möjlighet att öva på verklighetstrogna konversationer i en trygg miljö, där du när som helst kan ställa frågor utan att känna stress och press.',
  },
  {
    question: 'Kan jag skapa mina egna AI-lärare?',
    answer:
      'Ja, du kan skapa dina egna AI-lärare. Du kan anpassa lärarens personlighet, undervisningsstil, och expertisområde. Du kan till exempel skapa en historielärare som specialiserar sig på medeltiden, eller en matematiklärare som är särskilt bra på att förklara komplexa koncept med enkla analogier. Du kan även anpassa läraren för specifika behov, som dyslexianpassning eller ADHD-anpassad kommunikation. Vårt syfte är att elever och studenter med olika förutsättningar ska kunna nyttja vår plattform för att öka sin kunskap och i förlängningen sitt självförtroende.',
  },
  {
    question: 'Hur fungerar quiz-generatorn?',
    answer:
      'Quiz-generatorn använder AI för att skapa anpassade frågor baserade på ditt studiematerial. Du kan ladda upp dokument (Word eller PDF), klistra in text, eller till och med länka till en YouTube-föreläsning. AI:n analyserar innehållet och skapar relevanta frågor. Du kan välja frågetyp (flervalsfrågor, sant/falskt, fyll i luckan etc.), svårighetsgrad, och antal frågor. Det är ett utmärkt verktyg för att testa din förståelse och förbereda dig för prov. De flesta av våra användare använder vår quiz-generator som läxförhör, så de laddar upp t.ex. ett bokkapitel och får frågor på innehållet för att förbereda sig inför ett prov.',
  },
  {
    question: 'Hur kan Studera.au hjälpa mig med högskoleprovet?',
    answer:
      'Vi erbjuder omfattande stöd för förberedelser inför högskoleprovet. Du kan träna på gamla högskoleprovprov och även generera frågor med AI för alla delar av provet (ORD, LÄS, MEK, XYZ, ELF). AI:n kan förklara svåra koncept, ge tips om tidshantering, och hjälpa dig identifiera dina styrkor och svagheter. Du kan också skapa anpassade övningar för de områden du behöver förbättra mest.',
  },
  {
    question: "Vad är 'Textlabbet' och hur kan jag använda det?",
    answer:
      'Textlabbet är en kraftfull funktion som hjälper dig att förbättra dina skriftliga arbeten. Du kan mata in din text (uppsats, dikt, artikel, etc.) och välja olika åtgärder som sammanfattning, analys, omstrukturering, eller korrekturläsning. AI:n ger dig sedan förslag på förbättringar, alternativa formuleringar, eller en omarbetad version av din text. Det är särskilt användbart för att förbättra ditt akademiska skrivande eller för att få nya perspektiv på ditt arbete, eller helt enkelt brainstorma för att komma vidare i ditt arbete.',
  },
  {
    question: 'Hur fungerar flashcard-funktionen?',
    answer:
      'Flashcard-funktionen använder AI för att generera kort för att lättare komma ihåg vad du behöver lära dig. Du kan generera flashcards från ditt studiematerial, inklusive dokument, anteckningar, eller till och med YouTube-föreläsningar. AI:n identifierar nyckelkoncept och skapar flera fråga-svar-par. Du kan anpassa svårighetsgraden och antalet kort. Systemet använder också spaced repetition (SRS) för att optimera din inlärning över tid, genom att presentera korten vid optimala intervall för långsiktig minnesbehållning.',
  },

  {
    question: 'Hur kan jag använda AI-debatt för att förbättra mitt kritiska tänkande?',
    answer:
      'Debatt-funktionen låter dig engagera dig i simulerade debatter med historiska figurer eller experter inom olika områden. Du kan välja ett ämne och en motståndare, och sedan argumentera för din ståndpunkt. AI:n kommer att svara med motargument baserade på den valda personens perspektiv och kunskap. Detta hjälper dig att utveckla dina argumentationsfärdigheter, se olika perspektiv på komplexa frågor, och fördjupa din förståelse för olika ämnen. Du kan också be AI:n analysera argumenten och ge feedback på din debatteknik.',
  },
  {
    question: 'Hur säkert är det att använda Studera.ai för mina studier?',
    answer:
      'Vi tar datasäkerhet på största allvar. Alla data krypteras. Vi följer GDPR och andra relevanta dataskyddslagar. Dina studiematerial och interaktioner med plattformen är privata och används endast för att förbättra din inlärningsupplevelse. Vi delar aldrig personlig information med tredje part. Dessutom har du full kontroll över dina data och kan begära radering när som helst.',
  },
  {
    question: 'Vilka AI-modeller använder vi?',
    answer:
      'Vi stödjer flera ledande AI-modeller för att ge dig bästa möjliga inlärningsupplevelse. Vi använder ChatGPT 3.5 Turbo, 4o-mini, 4o, o1-mini och o1-preview från OpenAI, Claude Sonnet och Sonnet 3.5 från Anthropic, samt Google Gemini, Gemini Pro och Mistral Large. Därtill finjusterar vi ständigt vår egen AI-modell för att bli bättre på de uppgifter våra användare efterfrågar. Olika modeller används för olika uppgifter beroende på deras styrkor, vilket säkerställer optimal prestanda för varje funktion i plattformen.',
  },
  {
    question: 'Kan Studera.ai hjälpa mig med specifika ämnen som matematik eller historia?',
    answer:
      'Absolut! Vår app är anpassningsbar för alla ämnesområden. För matematik kan du få steg-för-steg-lösningar på problem, visualiseringar av koncept, och anpassade övningar. För historia kan du engagera dig i simulerade samtal med historiska figurer, få hjälp med att analysera primärkällor, och skapa tidlinjer. Oavsett ämne kan AI:n generera quiz, flashcards, och förklaringar anpassade till din nivå och inlärningsstil.',
  },
  {
    question: 'Hur kan jag använda AI för att förbättra mitt akademiska skrivande?',
    answer:
      'Vår tjänst erbjuder flera verktyg för att förbättra ditt akademiska skrivande. Du kan använda Textlabbet för att analysera och förbättra strukturen i dina uppsatser. AI:n kan hjälpa dig med källhänvisningar, förbättra din argumentation, och säkerställa att din text följer akademiska standarder. Du kan också få hjälp med att generera idéer, skapa dispositioner, och få feedback på din texts stilistiska kvalitet.',
  },
  {
    question: 'Kan inlärningen anpassas för elever med särskilda behov?',
    answer:
      'Ja, vår tjänst är designad för att vara inkluderande och kan anpassas för olika inlärningsbehov. AI-lärare kan programmeras att kommunicera på sätt som bäst passar elevens behov, t.ex. att repetera olika koncept eller förklara med vardaliga liknelser och annat som gör inlärningsprocessen enklare. Vi strävar kontinuerligt efter att förbättra tillgängligheten för alla användare med olika behov och förutsättningar.',
  },

  {
    question: 'Hur kan jag spara och organisera innehållet som genereras?',
    answer:
      'Vi erbjuder en digital anteckningsbok där du kan spara all viktig information från det innehåll som genereras. Du kan enkelt spara frågor från högskoleprov, svar från chatbotar, quiz-frågor eller flashcards. T.ex. kan du spara olika förklaringar som AI-lärare ger dig så du enkelt kan exportera den viktigaste informationen du tillskanskar dig. Allt innehåll kategoriseras automatiskt baserat på ämne och typ (quiz, flashcards, chatbot-konversationer, etc.). Du kan också exportera dina anteckningar i olika format som CSV, PDF eller Word för användning utanför plattformen. Exempelvis kan du spara svåra meningar eller ord du använt i en konversation med en chatbot, för att sedan generera flashcards eller ett quiz och träna på just dessa ord.',
  },
  {
    question: 'Kan tjänsten hjälpa mig med tidsplanering och studieplanering?',
    answer:
      'Absolut! Vi har inbyggda verktyg för tidshantering och studieplanering. Baserat på dina mål, deadlines och studievanor kan AI:n skapa en personlig studieplan. Den kan rekommendera optimala studietider, påminna dig om viktiga datum, och hjälpa dig att balansera olika ämnen. AI:n kan också analysera dina studiemönster över tid och ge rekommendationer för att förbättra din effektivitet. Detta hjälper dig att maximera din studietid och nå dina akademiska mål mer effektivt.',
  },

  {
    question:
      'Kan tjänsten användas för SFI (Svenska för invandrare) och språkinlärning för nyanlända?',
    answer:
      'Absolut! Vår plattform är ett utmärkt verktyg för SFI och språkinlärning för nyanlända. Tjänsten underlättar kraftigt för personer som kommit till Sverige och vill lära sig svenska. Våra AI-chatbotar kan kommunicera på en mängd olika språk, inklusive arabiska, farsi, somali, och många fler. Detta gör det möjligt för användare att få förklaringar och instruktioner på sitt modersmål medan de lär sig svenska. Vi erbjuder anpassade övningar, interaktiva dialoger, och kulturell kontext som hjälper nyanlända att inte bara lära sig språket, utan också förstå svenska seder och bruk. Dessutom kan användare öva på vardagliga situationer, som att handla mat eller söka jobb, i en trygg digital miljö i form av rollspel. Vi är stolta över att kunna stödja mångfald och integration genom vår plattform, och vi välkomnar varmt alla som vill lära sig svenska, eller vilket språk som helst!',
  },
];

const FAQSection = () => {
  return (
    <Box sx={{ bgcolor: 'background.paper', py: 12, boxShadow: 'none', border: 'none' }}>
      <Container maxWidth='lg' sx={{ boxShadow: 'none', border: 'none' }}>
        <Typography
          variant='h3'
          align='center'
          gutterBottom
          sx={{ fontWeight: 'bold', mb: 9, fontSize: '2.3em' }}
        >
          Vanliga frågor och svar
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            {faqData.slice(0, 9).map((faq, index) => (
              <Accordion
                key={index}
                sx={{
                  mb: 2,
                  boxShadow: 'none',
                  border: 'none',
                  '&:before': { display: 'none' },
                  bgcolor: 'transparent', // This line removes the background color
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{
                    boxShadow: 'none',
                    border: 'none',
                    '&:before': { display: 'none' },
                  }}
                >
                  <Typography variant='h6' sx={{ fontWeight: '600', fontSize: '1.2rem' }}>
                    {faq.question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography sx={{ lineHeight: '1.7rem' }}>{faq.answer}</Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Grid>
          <Grid item xs={12} md={6}>
            {faqData.slice(9).map((faq, index) => (
              <Accordion
                key={index}
                id={`faq-${index}`}
                sx={{
                  mb: 2,
                  boxShadow: 'none',
                  border: 'none',
                  '&:before': { display: 'none' },
                  bgcolor: 'transparent', // This line removes the background color
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{
                    boxShadow: 'none',
                    border: 'none',
                    '&:before': { display: 'none' },
                  }}
                >
                  <Typography variant='h6' sx={{ fontWeight: '600', fontSize: '1.2rem' }}>
                    {faq.question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography sx={{ lineHeight: '1.7rem' }}>{faq.answer}</Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default FAQSection;
