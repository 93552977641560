import React, { useState, useRef, useEffect, memo, useCallback } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Popover,
  Paper,
  Divider,
  CircularProgress,
  IconButton,
  Tooltip,
  Card,
  CardContent,
  CardActions,
  Grid,
  FormControl,
  ToggleButtonGroup,
  ToggleButton,
  Switch,
  FormControlLabel,
  LinearProgress,
  InputAdornment,
  InputLabel,
  Select,
  MenuItem,
  Collapse,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import InfoIcon from '@mui/icons-material/Info';
import ReplyIcon from '@mui/icons-material/Reply';
import SimplifyIcon from '@mui/icons-material/AutoFixHigh';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ExpandIcon from '@mui/icons-material/OpenInFull';
import Logger from './Logger';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SendIcon from '@mui/icons-material/Send'; // Import the SendIcon
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import AnalysisIcon from '@mui/icons-material/Analytics';
import { db } from './firebase';
import {
  collection,
  addDoc,
  getDocs,
  query,
  where,
  deleteDoc,
  doc,
  updateDoc,
  getDoc,
} from 'firebase/firestore';
import { callApi, callClaude, callApiWithStreaming } from './api';
import axios from 'axios';
import StopIcon from '@mui/icons-material/Stop';
import Suggestions from './Suggestions';
import { useTheme } from '@mui/material/styles';
import { getStyles } from '../index';
import RefreshIcon from '@mui/icons-material/Refresh';
import PulsatingDot from './PulsatingDot';
import { keyframes } from '@emotion/react';
import Anthropic from '@anthropic-ai/sdk';

const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.background.default,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
}));

const ConceptButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(0.5),
  fontSize: '0.8rem',
}));

const ActionButton = styled(Button)(({ theme }) => ({}));

const DebateCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  margin: theme.spacing(1),
  cursor: 'pointer',
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[2],
  transition: 'all 0.3s ease',
  background: theme.palette.background.paper,
  '&:hover': {
    boxShadow: theme.shadows[6],
    transform: 'translateY(-3px)',
  },
}));

const RefreshButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
}));
const predefinedDebates = [];

let audioSource = null;

const progressAnimation = keyframes`
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
`;

const AnimatedLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  marginBottom: theme.spacing(1),

  '& .MuiLinearProgress-bar': {
    backgroundColor: theme.palette.mode === 'dark' ? '#66bb6a' : '#52c164',
    animation: `${progressAnimation} 1s ease-out`,
    transformOrigin: 'left',
  },
}));

const DebateView = ({ user }) => {
  const [debateTopic, setDebateTopic] = useState('');
  const [debate, setDebate] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingArgument, setLoadingArgument] = useState(false);
  const [moderatorQuestion, setModeratorQuestion] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverContent, setPopoverContent] = useState('');
  const [popoverConcepts, setPopoverConcepts] = useState([]);
  const [loadingConcepts, setLoadingConcepts] = useState({});
  const [loadingActions, setLoadingActions] = useState({});
  const [analysisAvailable, setAnalysisAvailable] = useState(false);
  const [tone, setTone] = useState('Neutral');
  const [isPlaying, setIsPlaying] = useState(false);
  const [selectedVoice, setSelectedVoice] = useState('onyx');
  const [speechMode, setSpeechMode] = useState(false);
  const [isGeneratingSpeech, setIsGeneratingSpeech] = useState(false);

  const inputRef = useRef(null);
  const [loadingModeratorQuestion, setLoadingModeratorQuestion] = useState(false);

  const [showForm, setShowForm] = useState(true);

  useEffect(() => {
    if (debate.length > 0) {
      setShowForm(false);
    }
  }, [debate]);

  const [streamingContent, setStreamingContent] = useState('');

  const theme = useTheme();
  const styles = getStyles(theme);

  const [selectedDebates, setSelectedDebates] = useState([]);
  const [hoveredCardIndex, setHoveredCardIndex] = useState(null);

  const [loadingPersonDebate, setLoadingPersonDebate] = useState(false);
  const [personalDebate, setPersonalDebate] = useState(false);

  const [debatePerson, setDebatePerson] = useState();
  const debatePersonRef = useRef(null);

  useEffect(() => {
    Logger.log('debatePerson right now: ', debatePerson);
  }, [debatePerson]);

  const handleDebatePersonChange = e => {
    setDebatePerson(e.target.value);
    debatePersonRef.current = e.target.value;
  };

  useEffect(() => {
    if (debatePersonRef.current) {
      // Här kan du utföra åtgärder med debatePersonRef.current
      Logger.log('debatePersonRef är nu tillgänglig:', debatePersonRef.current);
    }
  }, []);

  const ImageBox = styled(Box)(({ theme }) => ({
    width: '100%',
    height: 200,
    borderRadius: 15,
    overflow: 'hidden',
    position: 'relative',
    cursor: 'pointer',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    '&:hover': {
      '& .MuiTypography-root': {
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
      },
    },
  }));

  const ImageOverlay = styled(Typography)(({ theme }) => ({
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    fontSize: '15px',
    color: 'white',
    padding: theme.spacing(2),
    transition: 'background-color 0.3s',
  }));

  const singleDebates = [
    {
      image:
        'https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/misc%2Fdebatter%2Feinstein.jpeg?alt=media&token=447086a8-39cb-4024-bf15-4296936bd561',
      title: 'Albert Einstein',
    },
    {
      image:
        'https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/misc%2Fdebatter%2Fshakespear.jpeg?alt=media&token=9b723355-96a9-488f-b225-a36bd11d381c',
      title: 'William Shakespeare',
    },
    {
      image:
        'https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/misc%2Fdebatter%2Fplato.jpeg?alt=media&token=cf287af0-97cd-4234-ae00-d82206aa633c',
      title: 'Platon',
    },
    {
      image:
        'https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/misc%2Fdebatter%2Ftesla.jpeg?alt=media&token=d693fd14-dda7-4f36-a6c6-097df54bbe72',
      title: 'Nikola Tesla',
    },
    {
      image:
        'https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/misc%2Fdebatter%2Fada%20lovelace.jpeg?alt=media&token=1d605332-bec4-46d8-a5b0-79bde5654a6c',
      title: 'Ada Lovelace',
    },
    {
      image:
        'https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/misc%2Fdebatter%2Fdarwin.jpeg?alt=media&token=cbd4789b-24a9-4fe0-a79d-00deede0779e',
      title: 'Charles Darwin',
    },
  ];

  const debateTopics = [
    {
      image:
        'https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/misc%2Fdebatter%2Feinstein%20newton.jpeg?alt=media&token=9999f603-8ed2-4f42-88f7-a68ac10d3363',
      title: 'Einstein och Newton diskuterar gravitationens natur',
    },
    {
      image:
        'https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/misc%2Fdebatter%2Fbarack%20plato.jpeg?alt=media&token=79ac4fdb-1d9d-4eeb-a538-24c221210558',
      title: 'Barack Obama och Platon diskuterar statsledning',
    },
    {
      image:
        'https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/misc%2Fdebatter%2Fbeethoven%20bach.jpeg?alt=media&token=4c7b9ec1-1d33-43bd-a7ec-5d8afe9a567e',
      title: 'Ludwig van Beethoven och Johann Sebastian Bach debatterar musik och komposition',
    },
    {
      image:
        'https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/misc%2Fdebatter%2Fda%20vinci%20tesla.jpeg?alt=media&token=b09dda09-4c73-4d5a-97c6-d503c01abae3',
      title: 'Leonardo da Vinci och Nikola Tesla diskuterar uppfinningar och innovation',
    },
    {
      image:
        'https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/misc%2Fdebatter%2Fdosto%20dickens.webp?alt=media&token=7eadefdd-34b3-47c8-9c87-e213e9243c9f',
      title: 'Charles Dickens och Fjodor Dostojevskij diskuterar realism i litteraturen',
    },
    {
      image:
        'https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/misc%2Fdebatter%2Ffreud%20jung.jpeg?alt=media&token=7ce2e26c-5653-48bf-a4cb-1a055bac86ce',
      title: 'Freud och Jung utforskar det mänsliga psyket',
    },
    {
      image:
        'https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/misc%2Fdebatter%2Fgoodall%20darwin.jpeg?alt=media&token=9285679e-2de0-4c85-9917-b8b65f4eda0f',
      title: 'Jane Goodall och Charles Darwin utforskar evolution och naturvetenskap',
    },
    {
      image:
        'https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/misc%2Fdebatter%2Forwell%20golding.jpeg?alt=media&token=77006f7f-1726-4426-ba1f-697338392c76',
      title: 'William Golding och George Orwell diskuterar människans natur',
    },
    {
      image:
        'https://firebasestorage.googleapis.com/v0/b/studeraai.appspot.com/o/misc%2Fdebatter%2Fsophocles%20euripides.jpeg?alt=media&token=d351386f-ee19-436a-9bd5-53d3eb6400f0',
      title: 'Sofokles och Euripides debatterar grekisk tragedi',
    },
  ];

  const handleSelect = selected => {
    if (selected.text) {
      Logger.log('selected.text: ', selected.text);
      setDebatePerson('');
      debatePersonRef.current = '';
      startDebate(selected.text);
    }
  };

  const handleSelectSingleDebate = selected => {
    if (selected.text) {
      Logger.log('selected.text: ', selected.text);
      debatePersonRef.current = '';
      setDebatePerson(selected.text);
      debatePersonRef.current = selected.text;
      startDebateWithPerson();
    }
  };

  const selectRandomDebates = () => {
    const shuffled = [...predefinedDebates].sort(() => 0.5 - Math.random());
    setSelectedDebates(shuffled.slice(0, 8));
  };

  useEffect(() => {
    selectRandomDebates();
  }, []);

  useEffect(() => {
    setAnalysisAvailable(debate.length >= 20);
  }, [debate]);

  useEffect(() => {
    const loadUserPreferences = async () => {
      try {
        const userDocRef = doc(db, 'users', user.uid);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          setTone(userData.tone || 'Neutral');
        }
      } catch (error) {
        console.error('Error loading user preferences:', error);
      }
    };

    loadUserPreferences();
  }, [user]);

  const updateUserPreferences = async preferences => {
    if (!user || !user.uid) {
      console.error('No user logged in');
      return;
    }
    try {
      const userDocRef = doc(db, 'users', user.uid);
      await updateDoc(userDocRef, preferences);
    } catch (error) {
      console.error('Error updating user preferences:', error);
    }
  };

  const handleDebateTopicChange = e => {
    setDebateTopic(e.target.value);
  };

  const handleModeratorQuestionChange = useCallback(e => {
    setModeratorQuestion(e.target.value);
  }, []);

  const handleToneChange = (event, newTone) => {
    if (newTone !== null) {
      setTone(newTone);
    }
  };

  const startDebateWithPerson = async (topic = debateTopic) => {
    Logger.log('startDebateWithPerson() is running');
    setLoadingPersonDebate(true);
    setPersonalDebate(true);
    setDebate([]);
    try {
      const messages = [
        {
          role: 'system',
          content: `Du är ${debatePersonRef.current} och deltar i en debatt med användaren. Presentera dig själv och fråga vilket ämne användaren vill debattera. Avsluta alltid med en fråga och referera till mig som "du". Svara i första person singular. Skriv på svenska. Returnera svaret i följande JSON-format:
          {
            "firstArgument": "<första argumentet>",
            "speaker": "${debatePersonRef.current}"
          }`,
        },
        {
          role: 'user',
          content: `Presentera dig själv (i jag-form, nämn inte ditt namn) så som ${debatePersonRef.current} skulle presentera sig själv i verkliga livet, och fråga vilket ämne användaren vill debattera.`,
        },
      ];

      const response = await callApi(messages, undefined, true, user.uid);
      const data = JSON.parse(response.data.choices[0].message.content);

      Logger.log('response: ', response.data);

      setDebate([{ type: 'argument', content: data.firstArgument, speaker: data.speaker }]);
    } catch (error) {
      console.error('Error starting person debate:', error);
    } finally {
      setLoadingPersonDebate(false);
    }
  };

  const getNextArgumentButtonText = () => {
    if (loading) return 'Hämtar argument...';
    if (isGeneratingSpeech) return 'Inväntar ljud...';
    return 'Nästa argument';
  };

  const startDebate = async (topic = debateTopic) => {
    setPersonalDebate(false);
    Logger.log('startDebate() is running');
    setLoading(true);
    setDebate([]);
    try {
      const messages = [
        {
          role: 'system',
          content: `Simulate a debate between: "${topic}". Introduce the debate and provide the first argument and speaker name. Skriv på svenska. The arguments should be in first person singular. The debaters should not refer to themselves nor mention their own names, it should simulate i REAL LIFE DEBATE. Return the response in the following JSON format:
          {
            "introduction": "<debate introduction>",
            "firstArgument": "<first argument>",
            "speaker": "<name of the first speaker>"
          }`,
        },
        { role: 'user', content: topic },
      ];

      const response = await callApi(messages, undefined, true, user.uid);
      const data = JSON.parse(response.data.choices[0].message.content);

      Logger.log('response: ', response.data);

      setDebate([
        { type: 'introduction', content: data.introduction },
        { type: 'argument', content: data.firstArgument, speaker: data.speaker },
      ]);
    } catch (error) {
      console.error('Error starting debate:', error);
    } finally {
      setLoading(false);
    }
  };

  const createPlaceholderArgument = () => ({
    id: 'placeholder-' + Date.now(),
    type: 'argument',
    content: 'Genererar argument...',
    speaker: 'AI',
    isPlaceholder: true,
  });

  //////////
  //////////

  const [streamingIds, setStreamingIds] = useState(new Set());

  const getNextArgument = async () => {
    setLoadingArgument(true);
    const streamingId = 'streaming-' + Date.now();
    setStreamingIds(prev => new Set(prev).add(streamingId));

    // Optimistic update
    const placeholderArgument = {
      id: streamingId,
      type: 'argument',
      content: 'Genererar argument...',
      speaker: 'AI',
      isPlaceholder: true,
    };
    setDebate(prevDebate => [...prevDebate, placeholderArgument]);

    try {
      const messages = [
        {
          role: 'system',
          content: `Continue the debate on "${debateTopic}". Provide the next argument based on the previous ones. Skriv på svenska. The arguments should be in first person singular. The debaters should not refer to themselves or mention their own names. Use the tone: ${tone}. 
Return the response in the following format:
<the argument text>
META: {"speaker": "<name of the speaker>"}`,
        },
        { role: 'user', content: JSON.stringify(debate) },
      ];
      let streamingContent = '';
      let streamingSpeaker = '';
      let metaFound = false;
      let accumulatedChunk = '';

      const onChunk = chunk => {
        Logger.log('Received chunk:', chunk);
        accumulatedChunk += chunk;

        const metaIndex = accumulatedChunk.indexOf('META:');
        if (metaIndex !== -1) {
          const newContent = accumulatedChunk.substring(0, metaIndex).trim();
          let metaJson = accumulatedChunk.substring(metaIndex + 5).trim();
          Logger.log('Found META data:', metaJson);
          try {
            metaJson = metaJson.replace(/^[^{]*|[^}]*$/g, '');
            Logger.log('Cleaned META data:', metaJson);
            const metaData = JSON.parse(metaJson);
            Logger.log('META data parsed:', metaData);
            if (metaData.speaker) {
              Logger.log('Speaker found:', metaData.speaker);
              streamingSpeaker = metaData.speaker;
            }
          } catch (e) {
            console.warn('Failed to parse META data:', e);
            console.warn('Raw META data:', metaJson);
          }
          streamingContent = newContent;
          metaFound = true;
        } else {
          streamingContent = accumulatedChunk;
        }

        setDebate(prevDebate => {
          return prevDebate.map(item =>
            item.id === streamingId
              ? {
                  ...item,
                  content: streamingContent,
                  speaker: streamingSpeaker || item.speaker,
                  isPlaceholder: false,
                }
              : item
          );
        });
      };

      await callApiWithStreaming(messages, onChunk, user.uid);
      Logger.log('Final accumulated chunk:', accumulatedChunk);
      Logger.log('Streamed debate content:', streamingContent);
      Logger.log('Final speaker:', streamingSpeaker);

      // Remove the placeholder if it wasn't updated (unlikely, but just in case)
      setDebate(prevDebate => prevDebate.filter(item => !item.isPlaceholder));
    } catch (error) {
      console.error('Error getting next argument:', error);
      // Remove the placeholder in case of error
      setDebate(prevDebate => prevDebate.filter(item => item.id !== streamingId));
    } finally {
      setLoadingArgument(false);
      setStreamingIds(prev => {
        const newSet = new Set(prev);
        newSet.delete(streamingId);
        return newSet;
      });
    }
  };

  /////////////
  /////////////

  const askModeratorQuestion = async question => {
    setLoadingModeratorQuestion(true);
    const streamingId = 'streaming-' + Date.now();
    setStreamingIds(prev => new Set(prev).add(streamingId));

    try {
      const messages = [
        {
          role: 'system',
          content: `Respond to the moderator's question: "${question}" in the context of the debate on "${debateTopic}". The speaker name should be whoever feels to reply. Skriv på svenska. The arguments should be in first person singular. The debaters should not refer to themselves. End with a question to the user. Use the tone: ${tone}.
Return the response in the following format:
<the argument text>
META: {"speaker": "<name of the speaker>"}`,
        },
        { role: 'user', content: JSON.stringify({ debate, question: question }) },
      ];

      let streamingContent = '';
      let streamingSpeaker = '';
      let metaFound = false;
      let accumulatedChunk = '';

      const onChunk = chunk => {
        //Logger.log('Received chunk:', chunk);
        accumulatedChunk += chunk;

        const metaIndex = accumulatedChunk.indexOf('META:');
        if (metaIndex !== -1) {
          const newContent = accumulatedChunk.substring(0, metaIndex).trim();
          let metaJson = accumulatedChunk.substring(metaIndex + 5).trim();
          //Logger.log('Found META data:', metaJson);
          try {
            metaJson = metaJson.replace(/^[^{]*|[^}]*$/g, '');
            //Logger.log('Cleaned META data:', metaJson);
            const metaData = JSON.parse(metaJson);
            Logger.log('META data parsed:', metaData);
            if (metaData.speaker) {
              Logger.log('Speaker found:', metaData.speaker);
              Logger.log('Debate Person Ref  in onChunk:', debatePersonRef.current);
              streamingSpeaker =
                debatePersonRef.current.length > 0 ? debatePersonRef.current : metaData.speaker;
            }
          } catch (e) {
            console.warn('Failed to parse META data:', e);
            console.warn('Raw META data:', metaJson);
          }
          streamingContent = newContent;
          metaFound = true;
        } else {
          streamingContent = accumulatedChunk;
        }

        setDebate(prevDebate => {
          const existingStreamingIndex = prevDebate.findIndex(item => item.id === streamingId);
          if (existingStreamingIndex !== -1) {
            const newDebate = [...prevDebate];
            newDebate[existingStreamingIndex] = {
              ...newDebate[existingStreamingIndex],
              content: streamingContent,
              speaker: streamingSpeaker,
            };
            return newDebate;
          } else {
            return [
              ...prevDebate,
              { type: 'moderator', content: question },
              {
                id: streamingId,
                type: 'response',
                content: streamingContent,
                speaker: streamingSpeaker,
                isStreaming: true,
              },
            ];
          }
        });
      };

      await callApiWithStreaming(messages, onChunk, user.uid);
      Logger.log('Final accumulated chunk:', accumulatedChunk);
      Logger.log('Streamed debate content:', streamingContent);
      Logger.log('Final speaker:', streamingSpeaker);

      setModeratorQuestion('');
    } catch (error) {
      console.error('Error asking moderator question:', error);
    } finally {
      setLoadingModeratorQuestion(false);
      setStreamingIds(prev => {
        const newSet = new Set(prev);
        newSet.delete(streamingId);
        return newSet;
      });
    }
  };
  const handleConceptClick = async (concept, event) => {
    setAnchorEl(event.currentTarget);
    setLoadingConcepts(prev => ({ ...prev, [concept]: true }));
    try {
      const messages = [
        {
          role: 'system',
          content: `Explain the concept: "${concept}" in the context of the debate on "${debateTopic}". Skriv på svenska. Return the response in the following JSON format:
            {
              "explanation": "<explanation of the concept>",
              "relatedConcepts": ["<concept 1>", "<concept 2>", "<concept 3>"],
              "relatedPersons": ["<person 1>", "<person 2>", "<person 3>"]
            }`,
        },
        { role: 'user', content: concept },
      ];

      const response = await callApi(messages, undefined, true, user.uid);
      const data = JSON.parse(response.data.choices[0].message.content);

      setPopoverContent(data.explanation);
      setPopoverConcepts(data.relatedConcepts);
    } catch (error) {
      console.error('Error explaining concept:', error);
    } finally {
      setLoadingConcepts(prev => ({ ...prev, [concept]: false }));
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setPopoverContent('');
    setPopoverConcepts([]);
  };

  const handleArgumentAction = async (action, argumentIndex) => {
    setLoadingActions(prev => ({ ...prev, [`${action}-${argumentIndex}`]: true }));
    const streamingId = 'streaming-' + Date.now();
    setStreamingIds(prev => new Set(prev).add(streamingId));

    try {
      const argument = debate[argumentIndex];
      const messages = [
        {
          role: 'system',
          content:
            action === 'Faktakoll'
              ? `Analysera sanningshalten i följande argument i kontexten av debatten om "${debateTopic}". Skriv på svenska. Avsluta ditt svar med META-data i följande format:

META: {"speaker": "<namn på talaren>", "santEllerFalskt": "<sant eller falskt>", "sanningshalt": <1-10>}

Se till att META-datan är på en ny rad och följer exakt det angivna JSON-formatet.`
              : `${action} the following argument in the context of the debate on "${debateTopic}". Skriv på svenska. Return the response in the following format:
<the result of the action>
META: {"speaker": "<name of the speaker>"}`,
        },
        { role: 'user', content: argument.content },
      ];

      let streamingContent = '';
      let streamingSpeaker = 'System';
      let metaFound = false;
      let accumulatedChunk = '';
      let santEllerFalskt = '';
      let sanningshalt = 0;

      const onChunk = chunk => {
        accumulatedChunk += chunk;
        const metaIndex = accumulatedChunk.indexOf('META:');
        if (metaIndex !== -1) {
          const newContent = accumulatedChunk.substring(0, metaIndex).trim();
          let metaJson = accumulatedChunk.substring(metaIndex + 5).trim();
          Logger.log('Raw META data:', metaJson);

          if (metaJson.endsWith('}')) {
            try {
              metaJson = metaJson.replace(/^[^{]*|[^}]*$/g, '');
              Logger.log('Cleaned META data:', metaJson);
              const metaData = JSON.parse(metaJson);
              Logger.log('Parsed META data:', metaData);

              if (metaData.speaker) {
                streamingSpeaker = metaData.speaker;
              }
              if (action === 'Faktakoll') {
                santEllerFalskt = metaData.santEllerFalskt || '';
                sanningshalt = parseInt(metaData.sanningshalt) || 0;
              }
              streamingContent = newContent;
              metaFound = true;
            } catch (e) {
              console.warn('Failed to parse META data:', e);
              console.warn('Problematic META data:', metaJson);
            }
          } else {
            Logger.log('Incomplete META data, waiting for more...');
            return;
          }
        } else {
          streamingContent = accumulatedChunk;
        }

        if (action === 'Förenkla' || action === 'Expandera') {
          setDebate(prevDebate => {
            const newDebate = [...prevDebate];
            newDebate[argumentIndex] = {
              ...newDebate[argumentIndex],
              content: streamingContent,
              speaker: streamingSpeaker,
              isStreaming: true,
            };
            return newDebate;
          });
        } else {
          setDebate(prevDebate => {
            const existingStreamingIndex = prevDebate.findIndex(item => item.id === streamingId);
            const newItem = {
              id: streamingId,
              type: 'analysis',
              content: streamingContent,
              speaker: streamingSpeaker,
              analysisType: action,
              isStreaming: true,
              ...(action === 'Faktakoll' && {
                santEllerFalskt,
                sanningshalt,
              }),
            };

            if (existingStreamingIndex !== -1) {
              const newDebate = [...prevDebate];
              newDebate[existingStreamingIndex] = newItem;
              return newDebate;
            } else {
              return [...prevDebate, newItem];
            }
          });
        }
      };

      await callApiWithStreaming(messages, onChunk, user.uid);
    } catch (error) {
      console.error(`Error performing action ${action}:`, error);
    } finally {
      setLoadingActions(prev => ({ ...prev, [`${action}-${argumentIndex}`]: false }));
      setStreamingIds(prev => {
        const newSet = new Set(prev);
        newSet.delete(streamingId);
        return newSet;
      });
    }
  };

  const copyArgument = argumentIndex => {
    const argument = debate[argumentIndex];
    navigator.clipboard.writeText(argument.content);
  };

  const analyzeDebate = async () => {
    setLoading(true);
    try {
      const messages = [
        {
          role: 'system',
          content: `Analyze the following debate on "${debateTopic}". Provide an overall analysis, comment on the quality of arguments, the moderator's role, and the progression of the debate. Skriv på svenska. Return the response in the following JSON format:
            {
              "analysis": "<overall analysis>",
              "argumentQuality": "<comment on argument quality>",
              "moderatorRole": "<analysis of moderator's role>",
              "debateProgression": "<analysis of how the debate progressed>",
              "relatedConcepts": ["<concept 1>", "<concept 2>", "<concept 3>"],
              "relatedPersons": ["<person 1>", "<person 2>", "<person 3>"]
            }`,
        },
        { role: 'user', content: JSON.stringify(debate) },
      ];

      const response = await callApi(messages, undefined, true, user.uid);
      const data = JSON.parse(response.data.choices[0].message.content);

      setDebate([
        ...debate,
        {
          type: 'analysis',
          content: data,
          relatedConcepts: data.relatedConcepts,
          relatedPersons: data.relatedPersons,
        },
      ]);
    } catch (error) {
      console.error('Error analyzing debate:', error);
    } finally {
      setLoading(false);
    }
  };

  const open = Boolean(anchorEl);

  const cardStyles = {
    introduction: {
      background: theme.palette.mode === 'dark' ? '#39234f' : '#8b5cf6',
      color: '#ffffff',
      iconColor: '#ffffff',
    },
    argument: {
      background: theme.palette.mode === 'dark' ? '#373c44' : '#64748b',
      color: '#ffffff',
      iconColor: '#ffffff',
    },
    moderator: {
      background: theme.palette.mode === 'dark' ? '#482a18' : '#884618',
      color: '#ffffff',
      iconColor: '#ffffff',
    },
    analysis: {
      background: theme.palette.mode === 'dark' ? '#183148' : '#336B88',
      color: '#ffffff',
      iconColor: '#ffffff',
    },
    response: {
      background: theme.palette.mode === 'dark' ? '#373c44' : '#338868',
      color: '#ffffff',
      iconColor: '#ffffff',
    },
  };

  const getCardStyle = (item, index) => {
    if (item.type === 'argument') {
      const alternateStyle = {
        background: theme.palette.mode === 'dark' ? '#212c36' : '#f9f9f9',
        color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000',
        iconColor: theme.palette.mode === 'dark' ? '#ffffff' : '#000000',
      };
      return index % 2 === 0 ? cardStyles.argument : alternateStyle;
    }
    return (
      cardStyles[item.type] || { background: 'inherit', color: 'inherit', iconColor: 'inherit' }
    );
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      askModeratorQuestion();
    }
  };

  //  useEffect(() => {
  //   const handleKeyDown = (event) => {
  //     if (event.shiftKey && event.key === ' ') {
  //       getNextArgument();
  //     }
  //   };

  //   document.addEventListener('keydown', handleKeyDown);

  //   return () => {
  //     document.removeEventListener('keydown', handleKeyDown);
  //   };
  // }, []);

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (debate.length > 0) {
      scrollToBottom();
    }
  }, [debate]);

  const scrollToSingleDebate = () => {
    const element = document.querySelector('.singleDebate');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToDebate = () => {
    const element = document.querySelector('.debate');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    Logger.log('setPersonalDebate: ', personalDebate);
  }, [personalDebate]);
  return (
    <Box sx={styles.container}>
      <Box sx={{ ...styles.innerContainer, paddingBottom: '192px' }}>
        <Typography variant='h1' sx={styles.header}>
          AI-driven debatt
        </Typography>

        {!showForm && (
          <Button
            variant='outlined'
            onClick={() => setShowForm(true)}
            startIcon={<ExpandMoreIcon />}
            sx={{ ...styles.beautyBtnTres, mt: 2, mb: 4 }}
          >
            Visa formulär
          </Button>
        )}

        <Collapse in={showForm}>
          <Typography
            variant='h4'
            sx={{ ...styles.header, ...styles.headerAboveBox, marginBottom: 2, marginTop: 3 }}
          >
            Moderera en debatt
          </Typography>

          <Box
            sx={{
              ...styles.darkBorder,
              width: '100%',
              padding: '20px',
              paddingBottom: '40px',
              backgroundColor: theme.palette.mode === 'dark' ? '#212c36' : 'white',
              border: theme.palette.mode === 'dark' ? '1px solid #263442' : '1px solid #e0e0e091',
            }}
          >
            <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500' }}>
              Skriv namnen på deltagarna och ämnet:
            </Typography>

            <TextField
              value={debateTopic}
              onChange={handleDebateTopicChange}
              placeholder='T.ex. Platon och Nietzsche diskuterar konstens betydelse'
              fullWidth
              margin='normal'
            />
            <Box sx={{ marginBottom: '20px' }}>
              <Suggestions listType='debates' maxSuggestions={5} onSelect={handleSelect} />
            </Box>
            <StyledButton
              className='debate'
              variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
              onClick={() => startDebate()}
              disabled={loading || !debateTopic}
              startIcon={loading ? <CircularProgress size={20} /> : <PlayArrowIcon />}
            >
              Starta debatten
            </StyledButton>
            <Grid container spacing={2}>
              {selectedDebates.map((topic, index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                  <DebateCard onClick={() => startDebate(topic)}>
                    <Typography variant='body1'>{topic}</Typography>
                  </DebateCard>
                </Grid>
              ))}
            </Grid>

            <Box sx={{ mt: 6 }}>
              <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500' }}>
                Eller välj en debatt:
              </Typography>
            </Box>
            <Grid container spacing={2} sx={{ mt: 0 }}>
              {debateTopics.map((topic, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <ImageBox
                    onClick={() => {
                      handleSelect({ text: topic.title });
                      scrollToDebate();
                    }}
                    sx={{ backgroundImage: `url(${topic.image})` }}
                  >
                    <ImageOverlay variant='h6'>{topic.title}</ImageOverlay>
                  </ImageBox>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Typography
            variant='h4'
            sx={{ ...styles.header, ...styles.headerAboveBox, marginBottom: 2, marginTop: 4 }}
          >
            ... eller debattera med valfri person
          </Typography>

          <Box
            sx={{
              ...styles.darkBorder,
              width: '100%',
              padding: '20px',
              paddingBottom: '40px',
              backgroundColor: theme.palette.mode === 'dark' ? '#212c36' : 'white',
              border: theme.palette.mode === 'dark' ? '1px solid #263442' : '1px solid #e0e0e091',
            }}
          >
            <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500' }}>
              Skriv namnet på personen du vill debattera:
            </Typography>

            <TextField
              value={debatePerson}
              onChange={handleDebatePersonChange}
              placeholder='T.ex. Albert Einstein, Friedrich Nietzsche, Robert Oppenheimer, Virginia Woolf'
              fullWidth
              margin='normal'
            />
            <Box sx={{ marginBottom: '20px' }}>
              <Suggestions
                listType='singleDebates'
                maxSuggestions={5}
                onSelect={handleSelectSingleDebate}
              />
            </Box>
            <StyledButton
              className='singleDebate'
              variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
              onClick={() => startDebateWithPerson()}
              disabled={loadingPersonDebate || !debatePersonRef.current}
              startIcon={loadingPersonDebate ? <CircularProgress size={20} /> : <PlayArrowIcon />}
            >
              Starta debatten
            </StyledButton>

            <Box sx={{ mt: 4 }}>
              <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500' }}>
                Eller välj en populär debattör:
              </Typography>
            </Box>
            <Grid container spacing={2} sx={{ mt: 0 }}>
              {singleDebates.map((topic, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <ImageBox
                    onClick={() => {
                      handleSelectSingleDebate({ text: topic.title });
                      scrollToSingleDebate();
                    }}
                    sx={{ backgroundImage: `url(${topic.image})` }}
                  >
                    <ImageOverlay variant='h6'>{topic.title}</ImageOverlay>
                  </ImageBox>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Collapse>

        {/*

{debate.length > 0 && (

<style jsx="true" global="true">{`
  html, body {
    overflow: hidden;
    height: 100%; 
    margin: 0; 
  }
`}</style>

)}

*/}
        <Box
          sx={{
            height: 'calc(100vh - 360px)', // Adjust this value based on your layout
            overflowY: 'auto',
            marginBottom: 0,
          }}
        >
          {debate.map((item, index) => (
            <StyledCard
              key={index}
              sx={{
                borderRadius: '15px',
                border: theme.palette.mode === 'dark' ? 'inherit' : '1px solid #e0e0e0',
                boxShadow: 'none',
              }}
            >
              <CardContent
                sx={{
                  backgroundColor: getCardStyle(item, index).background,
                  color: getCardStyle(item, index).color,

                  position: 'relative',
                  padding: '25px',
                }}
              >
                <Typography variant='h3' sx={{ ...styles.h3, mb: 2, fontSize: '18px' }}>
                  {item.isPlaceholder
                    ? 'AI'
                    : item.type === 'introduction'
                      ? 'Introduktion'
                      : item.type === 'moderator'
                        ? 'Moderator'
                        : item.type === 'analysis'
                          ? `${item.analysisType || 'Overall'}`
                          : `${debatePerson || item.speaker}`}
                </Typography>

                {item.type === 'analysis' && item.content.analysis ? (
                  <>
                    <Typography variant='body1' paragraph>
                      {item.content.analysis}
                    </Typography>
                    <Typography variant='body1' paragraph>
                      <strong>Argument Quality:</strong> {item.content.argumentQuality}
                    </Typography>
                    <Typography variant='body1' paragraph>
                      <strong>Moderator's Role:</strong> {item.content.moderatorRole}
                    </Typography>
                    <Typography variant='body1' paragraph>
                      <strong>Debate Progression:</strong> {item.content.debateProgression}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography variant='body1' paragraph>
                      {item.content.split('META:')[0].trim()}
                      {streamingIds.has(item.id) && <PulsatingDot />}
                    </Typography>

                    {!streamingIds.has(item.id) && (
                      <>
                        {item.sanningshalt !== undefined && (
                          <Box sx={{ width: '100%', maxWidth: 300 }}>
                            <Typography variant='body2' gutterBottom>
                              Sanningshalt: {item.sanningshalt}/10
                            </Typography>
                            <AnimatedLinearProgress
                              variant='determinate'
                              value={item.sanningshalt * 10}
                              color={item.sanningshalt > 5 ? 'success' : 'error'}
                            />
                          </Box>
                        )}
                      </>
                    )}
                  </>
                )}

                <Box display='flex' flexWrap='wrap'>
                  {/* 
    {item.relatedConcepts && item.relatedConcepts.map((concept, idx) => (
      <ConceptButton 
        key={idx} 
        variant="outlined" 
        size="small"
        onClick={(e) => handleConceptClick(concept, e)}
        disabled={loadingConcepts[concept]}
        sx={{...styles.beautyBtn, ...styles.beautyBtnDos}}
      >
        {loadingConcepts[concept] ? <CircularProgress size={16} /> : concept}
      </ConceptButton>
    ))}
    {item.relatedPersons && item.relatedPersons.map((person, idx) => (
      <ConceptButton 
        key={idx} 
        variant="outlined" 
        size="small"
        onClick={(e) => handleConceptClick(person, e)}
        disabled={loadingConcepts[person]}
        sx={{...styles.beautyBtn, ...styles.beautyBtnDos}}
      >
        {loadingConcepts[person] ? <CircularProgress size={16} /> : person}
      </ConceptButton>
    ))}

*/}
                  {(item.type === 'argument' || item.type === 'response') && (
                    <CardActions>
                      <Tooltip title='Generera ett motargument' arrow>
                        <IconButton
                          size='small'
                          onClick={() => handleArgumentAction('Motargument', index)}
                          disabled={loadingActions[`Motargument-${index}`]}
                          sx={{ color: getCardStyle(item, index).iconColor }}
                        >
                          {loadingActions[`Motargument-${index}`] ? (
                            <CircularProgress size={16} />
                          ) : (
                            <ReplyIcon sx={{ fontSize: '20px' }} />
                          )}
                        </IconButton>
                      </Tooltip>
                      <Tooltip title='Förenkla' arrow>
                        <IconButton
                          size='small'
                          onClick={() => handleArgumentAction('Förenkla', index)}
                          disabled={loadingActions[`Förenkla-${index}`]}
                          sx={{ color: getCardStyle(item, index).iconColor }}
                        >
                          {loadingActions[`Förenkla-${index}`] ? (
                            <CircularProgress size={16} />
                          ) : (
                            <SimplifyIcon sx={{ fontSize: '20px' }} />
                          )}
                        </IconButton>
                      </Tooltip>
                      <Tooltip title='Kopiera' arrow>
                        <IconButton
                          size='small'
                          onClick={() => copyArgument(index)}
                          sx={{ color: getCardStyle(item, index).iconColor }}
                        >
                          <ContentCopyIcon sx={{ fontSize: '20px' }} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title='Faktakoll' arrow>
                        <IconButton
                          size='small'
                          onClick={() => handleArgumentAction('Faktakoll', index)}
                          disabled={loadingActions[`Faktakoll-${index}`]}
                          sx={{ color: getCardStyle(item, index).iconColor }}
                        >
                          {loadingActions[`Faktakoll-${index}`] ? (
                            <CircularProgress size={16} />
                          ) : (
                            <FactCheckIcon sx={{ fontSize: '20px' }} />
                          )}
                        </IconButton>
                      </Tooltip>
                      <Tooltip title='Analogi' arrow>
                        <IconButton
                          size='small'
                          onClick={() => handleArgumentAction('Analogi', index)}
                          disabled={loadingActions[`Analogi-${index}`]}
                          sx={{ color: getCardStyle(item, index).iconColor }}
                        >
                          {loadingActions[`Analogi-${index}`] ? (
                            <CircularProgress size={16} />
                          ) : (
                            <EmojiObjectsIcon sx={{ fontSize: '20px' }} />
                          )}
                        </IconButton>
                      </Tooltip>
                    </CardActions>
                  )}
                </Box>
              </CardContent>
            </StyledCard>
          ))}
          <div ref={messagesEndRef} />
        </Box>
        {debate.length > 0 && (
          <Box
            sx={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              right: 0,
              backgroundColor: 'background.paper',
              boxShadow: 3,
              borderTopLeftRadius: '15px',
              borderTopRightRadius: '15px',
              zIndex: 1000,
              left: { xs: '0px', md: '270px' },
            }}
          >
            <Paper elevation={3} sx={{ padding: 2, borderRadius: '0px' }}>
              <Grid container spacing={2} alignItems='center'>
                <Grid item xs={12} md={2}>
                  <FormControl fullWidth>
                    <InputLabel id='tone-select-label'>Ton</InputLabel>
                    <Select
                      labelId='tone-select-label'
                      id='tone-select'
                      value={tone}
                      label='Ton'
                      onChange={event => handleToneChange(event, event.target.value)}
                      sx={{
                        width: '100%',
                        borderRadius: '6px',
                        '& .MuiSelect-select': {
                          padding: '10px', // Padding för den valda texten
                        },
                      }}
                    >
                      <MenuItem value='Neutral'>Neutral</MenuItem>
                      <MenuItem value='Akademisk'>Akademisk</MenuItem>
                      <MenuItem value='Humoristisk'>Humoristisk</MenuItem>
                      <MenuItem value='Sarkastisk'>Sarkastisk</MenuItem>
                      <MenuItem value='Poetisk'>Poetisk</MenuItem>
                      <MenuItem value='Mystisk'>Mystisk</MenuItem>
                      <MenuItem value='Dramatisk'>Dramatisk</MenuItem>
                      <MenuItem value='Melankolisk'>Melankolisk</MenuItem>
                      <MenuItem value='Barock'>Barock</MenuItem>
                      <MenuItem value='Kafkaesk'>Kafkaesk</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={5}>
                  <Box display='flex' justifyContent='space-between'>
                    <StyledButton
                      variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                      onClick={getNextArgument}
                      disabled={loading || isGeneratingSpeech}
                      startIcon={
                        loading || isGeneratingSpeech ? (
                          <CircularProgress size={20} />
                        ) : (
                          <QuestionAnswerIcon />
                        )
                      }
                      sx={{ display: personalDebate ? 'none' : undefined }}
                    >
                      {getNextArgumentButtonText()}
                    </StyledButton>

                    <Tooltip
                      title={
                        !analysisAvailable
                          ? 'Analysen är redo att köras först efter 20 inlägg i debatten'
                          : ''
                      }
                      arrow
                      placement='top'
                    >
                      <span>
                        <StyledButton
                          variant='contained'
                          onClick={analyzeDebate}
                          disabled={!analysisAvailable || loading}
                          startIcon={loading ? <CircularProgress size={20} /> : <AnalysisIcon />}
                          sx={{ display: { xs: 'none', md: undefined } }}
                        >
                          Analysera debatten
                        </StyledButton>
                      </span>
                    </Tooltip>
                  </Box>
                </Grid>
                <Grid item xs={12} sx={{ paddingTop: '10px !important' }}>
                  <Divider />
                </Grid>
                <Grid item xs={12} sx={{ paddingTop: '0px !important' }}>
                  <Box display='flex' alignItems='center'>
                    <TextField
                      inputRef={inputRef}
                      label='Ställ en fråga eller debattera själv'
                      defaultValue=''
                      fullWidth
                      margin='normal'
                      sx={{ flexGrow: 1, marginRight: 2 }}
                      onKeyPress={event => {
                        if (event.key === 'Enter' && !event.shiftKey) {
                          event.preventDefault();
                          askModeratorQuestion(inputRef.current.value);
                          inputRef.current.value = '';
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            {loadingModeratorQuestion ? (
                              <CircularProgress size={18} />
                            ) : (
                              <IconButton
                                onClick={() => {
                                  askModeratorQuestion(inputRef.current.value);
                                  inputRef.current.value = '';
                                }}
                                disabled={loadingModeratorQuestion}
                                sx={{
                                  color: theme.palette.primary.main,
                                  '&:hover': {
                                    color: theme.palette.primary.dark,
                                  },
                                }}
                              >
                                <SendIcon />
                              </IconButton>
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        )}

        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Box sx={{ padding: 2, maxWidth: 300 }}>
            {popoverContent ? (
              <>
                <Typography variant='body1'>{popoverContent}</Typography>
                <Box mt={2}>
                  <Typography variant='subtitle2'>Related Concepts:</Typography>
                  {popoverConcepts.map((concept, index) => (
                    <ConceptButton
                      key={index}
                      variant='outlined'
                      size='small'
                      onClick={e => handleConceptClick(concept, e)}
                      disabled={loadingConcepts[concept]}
                    >
                      {loadingConcepts[concept] ? <CircularProgress size={16} /> : concept}
                    </ConceptButton>
                  ))}
                </Box>
              </>
            ) : (
              <CircularProgress />
            )}
          </Box>
        </Popover>
      </Box>
    </Box>
  );
};

export default DebateView;
