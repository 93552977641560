import React, { useState, useEffect, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  Button,
  TextField,
  Grid,
  CircularProgress,
  Switch,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { collection, query, where, getDocs, addDoc } from 'firebase/firestore';
import { db } from './firebase';
import { useSnackbar } from './SnackbarHandler';
import { getStyles } from '../index';
import { useTheme } from '@mui/material/styles';
import mammoth from 'mammoth';
import GenerateIcon from '@mui/icons-material/AutoAwesome';
import LanguageSelector from './LanguageSelector';
import { callApi } from './api';
import SegmentIcon from '@mui/icons-material/Segment';
import Logger from './Logger';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate, Link } from 'react-router-dom';

import ProgressBar from './ProgressBar'; // Import the ProgressBar component

const CustomInputSwitch = ({ isManualEntry, setIsManualEntry, theme }) => {
  const handleClick = () => {
    setIsManualEntry(!isManualEntry);
  };

  return (
    <Box
      sx={{
        borderRadius: '38px',
        backgroundColor: theme.palette.mode === 'dark' ? '#3a454f' : '#f0f0f0',
        padding: '4px',
      }}
    >
      <Box
        onClick={handleClick}
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '240px',
          height: '36px',
          borderRadius: '18px',
          backgroundColor: theme.palette.mode === 'dark' ? '#3a454f' : '#f0f0f0',
          cursor: 'pointer',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            width: '50%',
            height: '100%',
            borderRadius: '18px',
            backgroundColor: '#8b5cf6',
            transition: 'transform 0.3s ease',
            transform: isManualEntry ? 'translateX(100%)' : 'translateX(0)',
          }}
        />
        <Typography
          variant='body2'
          sx={{
            flex: 1,
            textAlign: 'center',
            zIndex: 1,
            color: !isManualEntry ? '#fff' : theme.palette.mode === 'dark' ? '#fff' : '#000',
            transition: 'color 0.3s ease',
            fontWeight: '600',
          }}
        >
          Ladda upp fil
        </Typography>
        <Typography
          variant='body2'
          sx={{
            flex: 1,
            textAlign: 'center',
            zIndex: 1,
            color: isManualEntry ? '#fff' : theme.palette.mode === 'dark' ? '#fff' : '#000',
            transition: 'color 0.3s ease',
            fontWeight: '600',
          }}
        >
          Skriv in text
        </Typography>
      </Box>
    </Box>
  );
};

const EssayUpload = ({ user }) => {
  const [educationLevel, setEducationLevel] = useState('Gymnasium');
  const [selectedRubric, setSelectedRubric] = useState('');
  const [language, setLanguage] = useState('svenska');
  const [essayText, setEssayText] = useState('');
  const [file, setFile] = useState(null);
  const [rubrics, setRubrics] = useState([]);
  const [isManualEntry, setIsManualEntry] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [gradingResults, setGradingResults] = useState(null);
  const { showSnackbar } = useSnackbar();
  const [essayName, setEssayName] = useState('');
  const navigate = useNavigate();
  const theme = useTheme();
  const styles = getStyles(theme);
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    const fetchRubrics = async () => {
      const rubricsCollection = collection(db, 'rubrics');
      const q = query(rubricsCollection, where('custom', '==', true));
      const querySnapshot = await getDocs(q);
      const rubricList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setRubrics(rubricList);
      if (rubricList.length > 0) {
        setSelectedRubric(rubricList[0].id);
      }
    };

    fetchRubrics();
  }, []);

  const onDrop = useCallback(acceptedFiles => {
    setFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    },
    multiple: false,
  });

  const simulateProgress = (startProgress, endProgress, duration) => {
    const stepSize = 0.5;
    const steps = (endProgress - startProgress) / stepSize;
    const stepDuration = duration / steps;
    let currentProgress = startProgress;

    const intervalId = setInterval(() => {
      currentProgress += stepSize;
      if (currentProgress >= endProgress) {
        clearInterval(intervalId);
      } else {
        setProgress(currentProgress);
      }
    }, stepDuration);

    return () => clearInterval(intervalId);
  };

  const extractTextFromWordDocument = async file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async event => {
        try {
          const arrayBuffer = event.target.result;
          const result = await mammoth.extractRawText({ arrayBuffer });
          resolve(result.value);
        } catch (error) {
          reject(error);
        }
      };
      reader.onerror = error => reject(error);
      reader.readAsArrayBuffer(file);
    });
  };

  const handleSubmit = async event => {
    event.preventDefault();
    setIsLoading(true);
    setProgress(0);
    let content = '';
    let fileName = '';

    try {
      if (isManualEntry) {
        if (!essayText) {
          throw new Error('Vänligen ange uppsatstext');
        }
        if (!essayName) {
          throw new Error('Vänligen ange namn på uppsatsen');
        }
        content = essayText;
        fileName = essayName;
      } else {
        if (!file) {
          throw new Error('Vänligen ladda upp en fil');
        }
        content = await extractTextFromWordDocument(file);
        fileName = file.name;
      }

      const selectedRubricObject = rubrics.find(rubric => rubric.id === selectedRubric);

      if (!selectedRubricObject) {
        throw new Error('Vänligen välj en bedömningsmatris');
      }

      const prompt = `Bedöm följande uppsats baserat på den angivna bedömningsmatrisen. Uppsatsen är på ${language === 'swedish' ? 'svenska' : language} och är skriven på ${educationLevel}-nivå. Ge en analys för varje kriterium, förslag på förbättringar och ett betyg. Formatera svaret som markdown med följande struktur:


## Kriterieanalys

### [Kriteriumets namn]
- **Analys:** [Analys av detta kriterium på 3-4 meningar]
- **Förbättringsförslag:** [Förslag på förbättring på 3-4 meningar]
- **Betyg:** [Betyg för detta kriterium]
[Upprepa för varje kriterium]


### Övergripande betyg
[Ange övergripande betyg här]


---


## Bedömningsresultat



### Övergripande analys
[Ange övergripande analys av uppsatsen här]

### Positiv feedback
[Ange positiv feedback om uppsatsens styrkor här]

### Förbättringsplan
[Ange en strukturerad plan för att förbättra uppsatsen här]


---


## Felrapport



### Grammatikfel
[Lista med grammatikfel och förslag på korrigeringar]

### Meningsbyggnadsfel
[Beskrivning av problem med meningsbyggnad]

### Sammanhangsproblem
[Identifierade problem med textens sammanhang]

### Tydlighetsproblem
[Områden där texten kan förbättras för ökad tydlighet]

### Logiska inkonsekvenser
[Identifierade logiska problem eller motsägelser i texten]

### Stavfel
[Lista med stavfel och förslag på korrigeringar]

Bedömningsmatris:
${JSON.stringify(selectedRubricObject)}

Uppsats:
${content}`;

      const stopSimulation = simulateProgress(0, 90, 15000);

      let response = await callApi(
        [
          { role: 'system', content: 'Du är en hjälpsam AI-assistent som bedömer uppsatser.' },
          { role: 'user', content: prompt },
        ],
        undefined,
        undefined,
        user.uid
      );

      stopSimulation();
      setProgress(95);

      Logger.log(response);

      // Save the results to the database
      const docRef = await addDoc(collection(db, 'essays'), {
        userId: user.uid,
        content: content,
        results: response,
        fileName: fileName,
        educationLevel: educationLevel,
        language: language,
        rubricId: selectedRubric,
        rubricName: selectedRubricObject.name, // Add this line to save the rubric name
        createdAt: new Date(),
      });
      setProgress(100);
      showSnackbar('Uppsatsen har bedömts och sparats', 'success');

      // Navigate to the results page
      navigate(`/app/uppsats/${docRef.id}`);
    } catch (error) {
      console.error('Error grading essay:', error);
      showSnackbar(error.message || 'Ett oväntat fel uppstod. Vänligen försök igen.', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ ...styles.container }}>
      <Box sx={styles.innerContainer}>
        <Typography variant='h1' sx={{ ...styles.header, borderBottom: '0px' }}>
          Uppsatser
        </Typography>
        <Typography variant='h2' sx={{ ...styles.header, borderBottom: '0px', fontSize: '1.3rem' }}>
          Ladda upp uppsats
        </Typography>

        <Box sx={{ ...styles.buttonsContainer, ...styles.additionalButtonsContainer }}>
          <Button
            variant={'contained'}
            color='primary'
            component={Link}
            startIcon={<AddIcon />}
            to='/app/matris/ny'
          >
            Ny matris
          </Button>

          <Button
            variant='contained'
            color='primary'
            component={Link}
            startIcon={<SegmentIcon />}
            to='/app/uppsatser'
          >
            Dina uppsatser
          </Button>
        </Box>

        <Box sx={{ ...styles.freshBox, padding: '20px' }}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500', mb: 1 }}>
                  Utbildningsnivå:
                </Typography>
                <RadioGroup
                  value={educationLevel}
                  onChange={e => setEducationLevel(e.target.value)}
                  sx={{ mb: 4, mt: 1 }}
                >
                  {[
                    'Mellanstadiet',
                    'Högstadiet',
                    'Gymnasium',
                    'Komvux',
                    'Högskola',
                    'Universitet',
                  ].map(level => (
                    <FormControlLabel
                      key={level}
                      value={level}
                      control={<Radio />}
                      label={level.charAt(0).toUpperCase() + level.slice(1)}
                      sx={{
                        margin: 0,
                        '& .MuiTypography-root': { lineHeight: 1 },
                        '& .MuiRadio-root': { padding: '4px' },
                      }}
                    />
                  ))}
                </RadioGroup>
                <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500', mb: 1 }}>
                  Bedömningsmatris:
                </Typography>
                <RadioGroup
                  value={selectedRubric}
                  onChange={e => setSelectedRubric(e.target.value)}
                  sx={{ mt: 1 }}
                >
                  {rubrics.map(rubric => (
                    <FormControlLabel
                      key={rubric.id}
                      value={rubric.id}
                      control={<Radio />}
                      label={rubric.name}
                      sx={{
                        margin: 0,
                        '& .MuiTypography-root': { lineHeight: 1 },
                        '& .MuiRadio-root': { padding: '4px' },
                      }}
                    />
                  ))}
                </RadioGroup>

                <Button
                  variant='contained'
                  color='primary'
                  component={Link}
                  startIcon={<AddIcon />}
                  to='/app/matris/ny'
                  sx={{ mt: 2 }}
                >
                  Skapa bedömningsmatris
                </Button>
              </Grid>

              <Grid item xs={12} sm={8}>
                <Box>
                  <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500', mb: 0 }}>
                    Språk:
                  </Typography>
                </Box>
                <Box>
                  <Typography variant='subtitle' sx={{ ...styles.grayText, fontSize: '13px' }}>
                    Ordförrådet kommer att bedömas baserat på det språk som väljs.
                  </Typography>
                </Box>
                <Box sx={{ mt: 1, mb: 2 }}>
                  <LanguageSelector language={language} setLanguage={setLanguage} />
                </Box>

                <Box sx={{ mt: 2 }}>
                  <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500', mb: 0 }}>
                    Ladda upp fil eller skriv text:
                  </Typography>
                </Box>
                <Box>
                  <Typography variant='subtitle' sx={{ ...styles.grayText, fontSize: '13px' }}>
                    Klistra in text eller ladda upp ditt dokument (*.doc, *.docx)
                  </Typography>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, mt: 2 }}>
                  <CustomInputSwitch
                    isManualEntry={isManualEntry}
                    setIsManualEntry={setIsManualEntry}
                    theme={theme}
                  />
                </Box>

                {isManualEntry ? (
                  <>
                    <TextField
                      multiline
                      rows={10}
                      fullWidth
                      value={essayText}
                      onChange={e => setEssayText(e.target.value)}
                      placeholder='Skriv eller klistra in uppsatstexten här'
                      sx={{ mb: 2 }}
                    />
                    <Box>
                      <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500', mb: 1 }}>
                        Namn:
                      </Typography>
                    </Box>
                    <TextField
                      fullWidth
                      value={essayName}
                      onChange={e => setEssayName(e.target.value)}
                      placeholder='Namn på uppsatsen'
                      required
                      sx={{ mb: 2, mt: 1 }}
                    />
                  </>
                ) : (
                  <Box
                    {...getRootProps()}
                    sx={{
                      border: '2px dashed #cccccc',
                      backgroundColor: theme.palette.mode === 'dark' ? '#1c262f' : '#fcfcfc',
                      borderRadius: '4px',
                      padding: '20px',
                      textAlign: 'center',
                      cursor: 'pointer',
                      mb: 2,
                    }}
                  >
                    <input {...getInputProps()} />
                    <CloudUploadIcon sx={{ fontSize: 48, color: 'primary.main' }} />
                    {isDragActive ? (
                      <Typography>Släpp filen här ...</Typography>
                    ) : (
                      <Typography>
                        Dra och släpp en .doc eller .docx fil här, eller klicka för att välja en fil
                      </Typography>
                    )}
                    {file && <Typography>Vald fil: {file.name}</Typography>}
                  </Box>
                )}

                <Button
                  type='submit'
                  variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                  color='primary'
                  fullWidth
                  sx={{ mt: 3 }}
                  disabled={isLoading}
                  startIcon={
                    !isLoading ? (
                      <GenerateIcon style={{ color: '#fff' }} />
                    ) : (
                      <CircularProgress size={16} sx={{ color: '#fff' }} />
                    )
                  }
                >
                  {isLoading ? 'Betygsätter uppsats...' : 'Betygsätt uppsats'}
                </Button>

                {isLoading && (
                  <Grid item xs={12}>
                    <ProgressBar progress={progress} label='Betygsätter uppsats...' />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default EssayUpload;
