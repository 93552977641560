import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  IconButton,
  InputAdornment,
  Avatar,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { callApiWithStreaming } from './api'; // Adjust import path as needed
import BlurryBackdrop from './BlurryBackdrop';
import SendIcon from '@mui/icons-material/Send';
import { useTheme } from '@mui/material/styles';
import { getStyles } from '../index';
import DOMPurify from 'dompurify';
import { marked } from 'marked';
import Logger from './Logger';

const AIDialog = ({ open, onClose, initialQuestion, systemMessage, theme, userId }) => {
  const [aiResponse, setAiResponse] = useState('');
  const [followUpQuestion, setFollowUpQuestion] = useState('');
  const [isAiThinking, setIsAiThinking] = useState(false);
  const [isMessageSending, setIsMessageSending] = useState(false);
  const [streamingId, setStreamingId] = useState(null);
  const abortControllerRef = useRef(null);
  const messagesEndRef = useRef(null);
  const [isTextFieldFocused, setIsTextFieldFocused] = useState(false);

  const themeCurrent = useTheme();
  const styles = getStyles(theme);

  const handleKeyPress = event => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const handleSendMessage = async () => {
    if (!followUpQuestion.trim()) return;
    await handleFollowUpQuestion();
  };

  const parseMarkdownWithCursor = (content, isStreaming) => {
    const cursorPlaceholder = '∎';
    let contentWithPlaceholder = content;

    if (isStreaming) {
      contentWithPlaceholder += cursorPlaceholder;
    }

    const parsedContent = marked(contentWithPlaceholder);
    const sanitizedContent = DOMPurify.sanitize(parsedContent);
    return sanitizedContent.replace(cursorPlaceholder, '<span class="pulsating-dot"></span>');
  };

  const processMessages = (aiResponse, isStreaming) => {
    return aiResponse.split('\n\n').reduce((messages, message, index, array) => {
      if (message.startsWith('Följdfråga:')) {
        messages.push({ content: message.replace('Följdfråga: ', ''), isUser: true });
      } else if (message.startsWith('AI:')) {
        const content = message.replace('AI: ', '');
        messages.push({
          content: parseMarkdownWithCursor(content, isStreaming && index === array.length - 1),
          isUser: false,
        });
      } else if (messages.length > 0 && !messages[messages.length - 1].isUser) {
        const lastMessage = messages[messages.length - 1];
        lastMessage.content = parseMarkdownWithCursor(
          lastMessage.content + '\n\n' + message,
          isStreaming && index === array.length - 1
        );
      } else {
        messages.push({
          content: parseMarkdownWithCursor(message, isStreaming && index === array.length - 1),
          isUser: false,
        });
      }
      return messages;
    }, []);
  };

  const handleAskAI = async () => {
    setIsAiThinking(true);
    setAiResponse('');

    const newStreamingId = 'streaming-' + Date.now();
    setStreamingId(newStreamingId);

    try {
      let streamingContent = '';
      const onChunk = chunk => {
        streamingContent += chunk;
        setAiResponse(streamingContent);
      };

      await callApiWithStreaming(
        [
          { role: 'system', content: systemMessage },
          { role: 'user', content: initialQuestion },
        ],
        onChunk,
        userId,
        abortControllerRef.current.signal
      );
    } catch (error) {
      if (error.name === 'AbortError') {
        Logger.log('Stream was aborted');
      } else {
        console.error('Error calling AI API:', error);
        setAiResponse('Sorry, there was an error communicating with the AI. Please try again.');
      }
    } finally {
      setIsAiThinking(false);
      setStreamingId(null);
    }
  };

  const handleFollowUpQuestion = async () => {
    if (!followUpQuestion.trim()) return;
    setIsMessageSending(true);

    const newStreamingId = 'streaming-' + Date.now();
    setStreamingId(newStreamingId);

    try {
      let streamingContent = '';
      const onChunk = chunk => {
        streamingContent += chunk;
        setAiResponse(prevResponse => prevResponse + chunk);
      };

      setAiResponse(
        prevResponse => prevResponse + '\n\nFöljdfråga: ' + followUpQuestion + '\n\nAI: '
      );
      await callApiWithStreaming(
        [
          { role: 'system', content: systemMessage },
          { role: 'assistant', content: aiResponse },
          { role: 'user', content: followUpQuestion },
        ],
        onChunk,
        userId,
        abortControllerRef.current.signal
      );

      setFollowUpQuestion('');
    } catch (error) {
      if (error.name === 'AbortError') {
        Logger.log('Stream was aborted');
      } else {
        console.error('Error calling AI API:', error);
        setAiResponse(
          prevResponse =>
            prevResponse +
            '\n\nSorry, there was an error communicating with the AI. Please try again.'
        );
      }
    } finally {
      setIsMessageSending(false);
      setStreamingId(null);
    }
  };

  useEffect(() => {
    if (open) {
      abortControllerRef.current = new AbortController();
      handleAskAI();

      Logger.log('initialQuestion: ', initialQuestion);
    }
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [open]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [aiResponse]);

  const handleClose = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth='md'
      fullWidth
      BackdropComponent={BlurryBackdrop}
      PaperProps={{
        sx: {
          backgroundColor: theme.palette.mode === 'dark' ? '#22303C' : 'white',
          backgroundImage: theme.palette.mode === 'dark' ? 'none' : 'white',
          padding: '20px',
        },
      }}
    >
      <DialogTitle>
        <Typography sx={styles.h3} variant='title'>
          Fråga AI om hjälp
        </Typography>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.mode === 'dark' ? 'white' : 'black',
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '400px',
            overflowY: 'auto',
            position: 'relative',
          }}
        >
          {processMessages(aiResponse, !!streamingId).map((message, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                justifyContent: message.isUser ? 'flex-end' : 'flex-start',
                marginBottom: '8px',
              }}
            >
              {!message.isUser && (
                <Avatar
                  src='/AI.png'
                  alt='AI Avatar'
                  sx={{
                    width: '20px',
                    height: '20px',
                    marginRight: '8px',
                    borderRadius: '100px',
                  }}
                />
              )}
              <Box
                sx={{
                  backgroundColor: message.isUser
                    ? theme.palette.mode === 'dark'
                      ? '#2a3b4c'
                      : '#f9f9f9'
                    : theme.palette.mode === 'dark'
                      ? '#1C262F'
                      : '#f7f7f7',
                  padding: '12px',
                  alignSelf: message.isUser ? 'flex-end' : 'flex-start',
                  maxWidth: { xs: '100%', md: '80%' },
                  borderRadius: '8px',
                  padding: '20px',
                  paddingTop: message.isUser ? '15px' : '0px',
                  paddingBottom: message.isUser ? '15px' : '0px',
                }}
              >
                <Typography variant='body1' sx={{}}>
                  {message.isUser ? (
                    message.content
                  ) : (
                    <span dangerouslySetInnerHTML={{ __html: message.content }} />
                  )}
                </Typography>
              </Box>
            </Box>
          ))}
          <div ref={messagesEndRef} />
        </Box>
        <TextField
          fullWidth
          variant='outlined'
          label='Ställ en följdfråga'
          value={followUpQuestion}
          onChange={e => setFollowUpQuestion(e.target.value)}
          sx={{ mt: 2 }}
          onKeyPress={handleKeyPress}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                {isMessageSending ? (
                  <CircularProgress size={20} />
                ) : (
                  <IconButton
                    onClick={handleSendMessage}
                    disabled={isMessageSending}
                    sx={{
                      color: theme.palette.primary.main,
                      '&:hover': {
                        color: theme.palette.primary.dark,
                      },
                    }}
                  >
                    <SendIcon />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
          onFocus={() => setIsTextFieldFocused(true)}
          onBlur={() => setIsTextFieldFocused(false)}
        />
      </DialogContent>
    </Dialog>
  );
};

export default AIDialog;
