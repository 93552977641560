import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  Typography,
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  CircularProgress,
  ListItemIcon,
  Box,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSnackbar } from './SnackbarHandler';
import { useTheme } from '@mui/material/styles';
import { getStyles } from '../index';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import BlurryBackdrop from './BlurryBackdrop';
import Suggestions from './Suggestions';
import Help from './Help';

const PromptDialog = ({ open, onClose, prompts, onAddPrompt, onDeletePrompt, isDark }) => {
  const [newPrompt, setNewPrompt] = useState('');
  const [newDescription, setNewDescription] = useState('');
  const theme = useTheme();
  const styles = getStyles(theme);
  const [isAdding, setIsAdding] = useState(false);
  const { showSnackbar } = useSnackbar();

  const handleSelect = selected => {
    setNewPrompt(selected.text);
    setNewDescription(selected.value);
  };

  const handleCloseOverview = () => {
    onClose();
  };
  const handleAddPrompt = async () => {
    if (newPrompt.trim()) {
      setIsAdding(true);
      try {
        await onAddPrompt(newPrompt, newDescription);
        setNewPrompt('');
        setNewDescription('');
      } finally {
        setIsAdding(false);
      }
    } else {
      showSnackbar('Skriv in en prompt.', 'info');
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='md'
      fullWidth
      BackdropComponent={BlurryBackdrop}
      PaperProps={{
        sx: {
          borderRadius: '20px',
          backgroundColor: theme.palette.mode === 'dark' ? '#22303C' : 'white',
          backgroundImage: theme.palette.mode === 'dark' ? 'none' : 'white',
          padding: { xs: '0px', md: '20px' },
        },
      }}
    >
      <DialogTitle>
        Hantera prompts
        <IconButton
          aria-label='close'
          onClick={handleCloseOverview}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.mode === 'dark' ? 'white' : 'black',
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: '50px' }}>
        <Box>
          <Suggestions
            listType='promptIdeas'
            maxSuggestions={6}
            onSelect={handleSelect}
            alignRight={true}
          />
        </Box>

        {/*
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Help articleId='how-to-use-chat' tooltipTitle='Hur man använder chatten' />
          <Help articleId='how-to' tooltipTitle='Hur man använder chatten' />
        </Box>

*/}

        <Tooltip
          title='En välformulerad prompt hjälper AI:n att ge mer relevanta och användbara svar.'
          placement='top'
        >
          <Typography variant='formLabel' sx={{ mt: 4 }}>
            Prompt*
          </Typography>
        </Tooltip>
        <TextField
          autoFocus
          margin='dense'
          fullWidth
          placeholder='T.ex. Punktlista'
          value={newPrompt}
          onChange={e => setNewPrompt(e.target.value)}
        />
        <Tooltip title='För dig som vill ha en längre prompt.' placement='top'>
          <Typography variant='formLabel' sx={{ mt: 2 }}>
            Beskrivning
          </Typography>
        </Tooltip>
        <TextField
          margin='dense'
          fullWidth
          multiline
          rows={3}
          placeholder='T.ex. Ge mig en kort och koncis punktlista som sammanfattar ditt senaste svar och gör de viktigaste aspekterna fetstilta.'
          value={newDescription}
          onChange={e => setNewDescription(e.target.value)}
        />

        {prompts.length > 0 && (
          <Typography variant='h2' sx={{ fontSize: '1rem', fontWeight: '600', mt: 5 }}>
            Dina prompts:
          </Typography>
        )}

        <List sx={{ borderRadius: '10px', mt: 3, backgroundColor: 'transparent' }}>
          {prompts.map(prompt => (
            <ListItem
              key={prompt.id}
              variant='compactList'
              className='rounded'
              sx={{
                overflow: 'hidden',
                backgroundColor: isDark ? '#2b3b48' : '#f9f9f9',
                mb: 1,
              }}
            >
              <ListItemIcon>
                <ChevronRightIcon color='grayClean' />
              </ListItemIcon>
              <ListItemText
                primary={`${prompt.text}`}
                secondary={
                  prompt.description && prompt.description.length > 80
                    ? `${prompt.description.substring(0, 80)}...`
                    : prompt.description
                }
                secondaryTypographyProps={{ style: { fontSize: '0.8rem' } }}
                sx={{ borderRadius: '10px' }}
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge='end'
                  aria-label='delete'
                  onClick={() => onDeletePrompt(prompt.id)}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ margin: '20px' }}
          onClick={handleAddPrompt}
          startIcon={isAdding ? <CircularProgress size={16} color='inherit' /> : <AddIcon />}
          variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
          color='primary'
          disabled={isAdding}
        >
          {isAdding ? 'Lägger till...' : 'Lägg till'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PromptDialog;
