import React, { useState, useEffect, useRef, Suspense, lazy } from 'react';
import {
  Grid,
  Card,
  Typography,
  Button,
  TextField,
  Box,
  InputAdornment,
  CardContent,
  CircularProgress,
  Fade,
  Collapse,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  Chip,
  Avatar,
  Divider,
  IconButton,
} from '@mui/material';
import {
  collection,
  getDocs,
  addDoc,
  query,
  where,
  getDoc,
  doc,
  orderBy,
  limit,
} from 'firebase/firestore';
import { db } from './firebase';
import { useNavigate, Link } from 'react-router-dom';
import { useSnackbar } from './SnackbarHandler';
import { CourseListSkeleton } from './TeacherSkeleton';
import { useTheme } from '@mui/material/styles';
import { getStyles } from '../index';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import { formatDate } from './functions';
import LoadingSpinner from './LoadingSpinner';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { formatDistanceToNow } from 'date-fns';
import PersonIcon from '@mui/icons-material/Person';
import { sv } from 'date-fns/locale';
import { useMediaQuery } from '@mui/material';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUp';
import FilterListIcon from '@mui/icons-material/FilterList';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { getAIModelImage } from './TestComponents2';
import DrawerComponent from './quiz/DrawerComponent';
import BallotIcon from '@mui/icons-material/Ballot';
import ShortTextIcon from '@mui/icons-material/ShortText';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import SegmentIcon from '@mui/icons-material/Segment';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import QuizCarousel from './QuizCarousel';
const FilterComponent = lazy(() => import('./quiz/FilterComponent'));
import QuizIcon from '@mui/icons-material/Quiz';
import Logger from './Logger';

const PublicQuiz = ({ user }) => {
  const [publicQuizzes, setPublicQuizzes] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoadingQuizzes, setIsLoadingQuizzes] = useState(true);
  const [loading, setLoading] = useState(false);
  const [userEmails, setUserEmails] = useState({});
  const [questionCountFilter, setQuestionCountFilter] = useState('');
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const theme = useTheme();
  const styles = getStyles(theme);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [featuredQuizzes, setFeaturedQuizzes] = useState([]);
  const [categorizedQuizzes, setCategorizedQuizzes] = useState({});

  const [visibleQuizzes, setVisibleQuizzes] = useState({});

  const [filterOpen, setFilterOpen] = useState(false);
  const [difficultyFilter, setDifficultyFilter] = useState('');
  const [languageFilter, setLanguageFilter] = useState('');
  const [modelFilter, setModelFilter] = useState('');

  const [difficulties, setDifficulties] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [models, setModels] = useState([]);

  const featuredQuizIds = ['quiz1', 'quiz2', 'quiz3']; // Replace with actual featured quiz IDs

  const featuredRef = useRef(null);
  const recentlyGeneratedRef = useRef(null);
  const categoryRefs = useRef({});
  const [highestRatedQuizzes, setHighestRatedQuizzes] = useState([]);
  const highestRatedRef = useRef(null);
  const [recentlyGeneratedQuizzes, setRecentlyGeneratedQuizzes] = useState([]);
  const [userInfo, setUserInfo] = useState({});

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [activeSection, setActiveSection] = useState('highestRated'); // Add this new state

  const [questionTypeFilter, setQuestionTypeFilter] = useState('');
  const [questionTypes, setQuestionTypes] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState(null);

  const [loadedCategories, setLoadedCategories] = useState({});

  useEffect(() => {
    // Initialize refs for each category
    Object.keys(categorizedQuizzes).forEach(category => {
      categoryRefs.current[category] = React.createRef();
    });
  }, [categorizedQuizzes]);

  useEffect(() => {
    // Fetch initial categories
    const fetchCategories = async () => {
      try {
        const quizzesRef = collection(db, 'quiz');
        const q = query(quizzesRef, where('isPublic', '==', true));
        const snapshot = await getDocs(q);

        const categories = {};
        snapshot.docs.forEach(doc => {
          const quiz = doc.data();
          if (
            quiz.category &&
            quiz.category !== 'Course' &&
            quiz.category !== 'Välj kategori' &&
            quiz.category !== 'Från kurser'
          ) {
            if (!categories[quiz.category]) {
              categories[quiz.category] = [];
            }
          }
        });

        setCategorizedQuizzes(categories);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    // Fetch highest rated quizzes on initial load
    if (activeSection === 'highestRated') {
      fetchQuizzesForCategory('highestRated');
    }
  }, []); // Only run once on mount

  // Add this new function that loads quizzes for a specific category
  const fetchQuizzesForCategory = async category => {
    setIsLoadingQuizzes(true);
    try {
      const quizzesRef = collection(db, 'quiz');
      let q;

      if (category === 'highestRated') {
        q = query(quizzesRef, where('isPublic', '==', true), orderBy('upvotes', 'desc'), limit(10));
      } else if (category === 'recentlyGenerated') {
        q = query(
          quizzesRef,
          where('isPublic', '==', true),
          orderBy('createdAt', 'desc'),
          limit(10)
        );
      } else {
        q = query(quizzesRef, where('isPublic', '==', true), where('category', '==', category));
      }

      const quizzesSnapshot = await getDocs(q);
      const quizzesList = quizzesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Fetch user info for these quizzes
      const userIds = [...new Set(quizzesList.map(quiz => quiz.userId).filter(Boolean))];
      const userInfoObj = {};
      for (const userId of userIds) {
        const userDoc = await getDoc(doc(db, 'users', userId));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          userInfoObj[userId] = {
            displayName: userData.displayName || userData.email || 'Okänd',
            avatarUrl: userData.photoURL || null,
          };
        }
      }
      setUserInfo(prevState => ({ ...prevState, ...userInfoObj }));

      setLoadedCategories(prev => ({
        ...prev,
        [category]: quizzesList,
      }));
    } catch (error) {
      console.error('Error fetching quizzes:', error);
      showSnackbar('Error fetching quizzes. Please try again.', 'error');
    } finally {
      setIsLoadingQuizzes(false);
    }
  };

  const getCategoryDisplayName = category => {
    return category === 'Course' ? 'Från kurser' : category;
  };

  const filterQuizzes = quizzes => {
    return quizzes.filter(
      quiz =>
        quiz.quizName.toLowerCase().includes(searchTerm.toLowerCase()) &&
        (difficultyFilter === '' || quiz.difficulty === difficultyFilter) &&
        (questionTypeFilter === '' || quiz.questionType === questionTypeFilter) &&
        (modelFilter === '' || quiz.aiModel === modelFilter) &&
        (questionCountFilter === '' ||
          getQuestionCountRange(quiz.results ? quiz.results.length : 0) === questionCountFilter)
    );
  };

  const getQuestionCountRange = count => {
    if (count >= 1 && count <= 5) return '1-5';
    if (count >= 6 && count <= 10) return '6-10';
    if (count >= 11 && count <= 20) return '11-20';
    return '20+';
  };

  const handleCreateNewQuiz = () => {
    navigate(`/app/quiz`);
  };

  // Replace your existing category selection handlers with these
  const handleCategorySelect = category => {
    setSelectedCategory(category);
    setActiveSection(category);

    if (!loadedCategories[category]) {
      fetchQuizzesForCategory(category);
    }
  };

  const showHighestRated = () => {
    handleCategorySelect('highestRated');
  };

  const showRecentlyGenerated = () => {
    handleCategorySelect('recentlyGenerated');
  };

  const getDisplayQuizzes = () => {
    if (!activeSection) return [];
    return loadedCategories[activeSection] || [];
  };

  const toggleDrawer = open => () => {
    setDrawerOpen(open);
  };

  // const formatDate = (timestamp) => {
  //   const date = new Date(timestamp);
  //   const now = new Date();
  //   const diffInDays = Math.floor((now - date) / (1000 * 60 * 60 * 24));

  //   if (diffInDays === 0) {
  //     const distance = formatDistanceToNow(date, { addSuffix: true, locale: sv });
  //     // Custom shortening logic
  //     return distance
  //       .replace('ungefär ', '')
  //       .replace(' sekunder', ' sek')
  //       .replace(' minuter', ' min')
  //       .replace(' timmar', ' timmar')
  //       .replace(' timme', ' timme')
  //       .replace('mindre än en minut', '< 1 min');
  //   } else if (diffInDays === 1) {
  //     return 'Igår';
  //   } else if (diffInDays < 7) {
  //     return `${diffInDays} dagar sedan`;
  //   } else {
  //     return format(date, 'd MMM yyyy', { locale: sv });
  //   }
  // };

  const createUrlSlug = name => {
    return name
      .toLowerCase()
      .replace(/å/g, 'a')
      .replace(/ä/g, 'a')
      .replace(/ö/g, 'o')
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/^-+|-+$/g, '');
  };

  const copyPublicQuiz = async (publicQuiz, event) => {
    event.preventDefault(); // Prevent the default link behavior
    try {
      if (publicQuiz.userId === user.uid) {
        showSnackbar('Du kan inte kopiera ditt eget quiz.', 'info');
        return;
      }

      const q = query(
        collection(db, 'quiz'),
        where('userId', '==', user.uid),
        where('originalQuizId', '==', publicQuiz.id)
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // User has already copied this quiz
        const copiedQuiz = querySnapshot.docs[0].data();
        const urlSlug = createUrlSlug(copiedQuiz.quizName);
        showSnackbar('Du har redan kopierat detta quiz. Navigerar till din kopia.', 'info');
        navigate(`/app/quiz/${querySnapshot.docs[0].id}/${urlSlug}`);
        return;
      }

      const { id, ...quizData } = publicQuiz;
      const newQuizRef = await addDoc(collection(db, 'quiz'), {
        ...quizData,
        userId: user.uid,
        createdAt: new Date(),
        isPublic: false,
        isCopied: true,
        originalQuizId: id,
        originalUserId: quizData.userId,
      });

      Logger.log('Quiz copied with ID: ', newQuizRef.id);
      showSnackbar('Quiz kopierat framgångsrikt!', 'success');

      const urlSlug = createUrlSlug(publicQuiz.quizName);
      navigate(`/app/quiz/${newQuizRef.id}/${urlSlug}`); // Navigate to the new quiz with SEO-friendly URL
    } catch (error) {
      console.error('Error copying public quiz:', error);
      showSnackbar('Ett fel uppstod vid kopiering av quizet.', 'error');
    }
  };

  const handleLoadMore = category => {
    setLoading(true);
    setTimeout(() => {
      setVisibleQuizzes(prev => ({
        ...prev,
        [category]: prev[category] + 4,
      }));
      setLoading(false);
    }, 500);
  };

  const scrollToSection = ref => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const getDifficultyLabel = difficulty => {
    switch (difficulty) {
      case 'Enkel':
        return 'Enkel';
      case 'Medel':
        return 'Medel';
      case 'Svårt':
        return 'Svårt';
      default:
        return difficulty || 'Okänd';
    }
  };

  const getDifficultyColor = (difficulty, theme) => {
    switch (difficulty) {
      case 'Enkel':
        return {
          bg: theme.palette.mode === 'dark' ? '#1a5928' : '#e6f4e8',
          text: theme.palette.mode === 'dark' ? '#ffffff' : '#1a5928',
        };
      case 'Medel':
        return {
          bg: theme.palette.mode === 'dark' ? '#7d5f0a' : '#fff4d9',
          text: theme.palette.mode === 'dark' ? '#ffffff' : '#7d5f0a',
        };
      case 'Svårt':
        return {
          bg: theme.palette.mode === 'dark' ? '#8b2626' : '#ffe8e8',
          text: theme.palette.mode === 'dark' ? '#ffffff' : '#8b2626',
        };
      default:
        return {
          bg: 'transparent',
          text: theme.palette.text.primary,
        };
    }
  };

  const getSectionDescription = section => {
    switch (section) {
      case 'Utvalda Quiz':
        return 'Utvalda quiz som vi rekommenderar för sin kvalitet och variation.';
      case 'Högst betyg':
        return 'De mest uppskattade quizen baserat på användarnas omdömen.';
      case 'Senast genererade':
        return 'De senaste quizen som har skapats av vårt community.';
      case 'Alla Quiz':
        return 'Utforska hela vår samling av quiz med olika svårighetsgrader och teman.';
      case 'Okategoriserade':
        return 'Quiz som ännu inte har tilldelats en specifik kategori.';
      case 'Historia':
        return 'Res genom tiden med fängslande frågor om historiska händelser och personer.';
      case 'Matematik':
        return 'Utmana din logik och problemlösningsförmåga med matematiska quiz.';
      case 'Vetenskap':
        return 'Utforska naturens mysterier och vetenskapliga upptäckter.';
      case 'Språk':
        return 'Testa dina språkkunskaper och lär dig nya fascinerande ord och uttryck.';
      case 'Litteratur':
        return 'Dyk in i böckernas värld med frågor om författare, verk och litterära epoker.';
      case 'Film':
        return 'Pröva dina kunskaper om filmer, regissörer och ikoniska filmcitat i dessa film-quiz.';
      case 'Konst':
        return 'Upptäck konstens värld genom frågor om konstnärer, stilar och berömda verk.';
      case 'Musik':
        return 'Testa ditt gehör och musikkunskaper med frågor från olika genrer och epoker.';
      case 'Idrott':
        return 'Utmana dig själv med frågor om sporter, atleter och idrottshistoria.';
      case 'Teknik':
        return 'Dyk in i teknikens värld med frågor om innovationer och digitala trender.';
      case 'Filosofi':
        return 'Utforska tankeväckande filosofiska koncept och tänkare.';
      case 'Religion':
        return 'Lär dig mer om världens religioner, traditioner och andliga läror.';
      case 'Psykologi':
        return 'Utforska människans sinne och beteende genom psykologiska frågeställningar.';
      default:
        return 'Upptäck spännande quiz anpassade för olika behov och preferenser.';
    }
  };

  const clearFilters = () => {
    setDifficultyFilter('');
    setQuestionTypeFilter('');
    setModelFilter('');
    setQuestionCountFilter('');
  };

  const getQuestionTypeLabel = questionType => {
    const options = [
      { value: 'multipleChoice', label: 'Flervalsfrågor', icon: <BallotIcon /> },
      { value: 'fill-in-the-blank', label: 'Lucktext', icon: <ShortTextIcon /> },
      { value: 'true-or-false', label: 'Sant/Falskt', icon: <FactCheckIcon /> },
    ];
    const option = options.find(opt => opt.value === questionType);
    return option ? option.label : questionType;
  };

  const renderQuizCard = (quiz, isCarousel = false) => {
    const urlSlug = createUrlSlug(quiz.quizName);
    const user = userInfo[quiz.userId] || { displayName: 'Okänd', avatarUrl: null };
    const courseProgress = 0; // Since quizzes don't have progress
    const completed = false;

    const getBackgroundColor = (theme, completed) => {
      const isAllakando = theme.palette.themeType === 'allakando';
      const isDark = theme.palette.mode === 'dark';

      if (!isCarousel) {
        return completed
          ? isDark
            ? 'rgba(86, 166, 83, 0.1)'
            : 'rgba(86, 166, 83, 0.05)'
          : isDark
            ? 'rgba(255, 255, 255, 0.05)'
            : 'white';
      }

      if (isAllakando) {
        return theme.palette.background.card;
      }
      return isDark ? '#2c3740' : '#f9f9f9';
    };

    const getHoverBackgroundColor = (theme, completed) => {
      const isAllakando = theme.palette.themeType === 'allakando';
      const isDark = theme.palette.mode === 'dark';

      if (!isCarousel) {
        return completed
          ? isDark
            ? 'rgba(86, 166, 83, 0.15)'
            : 'rgba(86, 166, 83, 0.1)'
          : isDark
            ? 'rgba(255, 255, 255, 0.1)'
            : 'inherit';
      }

      if (isAllakando) {
        return theme.palette.background.cardHover;
      }
      return isDark ? '#35414d' : '#f0f0f0';
    };

    const getIconColor = theme => {
      const isAllakando = theme.palette.themeType === 'allakando';
      if (isAllakando) {
        return theme.palette.primary.main;
      }
      return styles.grayText.color;
    };

    return (
      <Grid item xs={12} sm={6} md={4} key={quiz.id}>
        <Card
          component={Link}
          to={`/app/quiz/${quiz.id}/${urlSlug}`}
          onClick={e => copyPublicQuiz(quiz, e)}
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            boxShadow: 'none',
            borderRadius: '12px',
            backgroundImage: 'none',
            textDecoration: 'none',
            border: theme.palette.mode === 'dark' ? '1px solid #35414d' : '1px solid #eee',
            position: 'relative',
            transition: 'all 0.3s ease',
            '&:hover': {
              textDecoration: 'none',
              '& .icon': {
                color: getIconColor(theme),
              },
              border: theme => `1px solid ${theme.palette.text.main}`,
              //backgroundColor: getHoverBackgroundColor(theme, completed),
              bgcolor: theme => theme.palette.text.main + '10',
            },
            backgroundColor: getBackgroundColor(theme, completed),
          }}
        >
          <CardContent
            sx={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
              padding: '30px',
              border: 'none',
            }}
          >
            <Typography
              variant='h6'
              sx={{
                fontWeight: '600',
                fontSize: '1.45rem',
                color:
                  theme.palette.themeType === 'allakando'
                    ? theme.palette.text.primary
                    : theme.palette.mode === 'dark'
                      ? '#c0c3c6'
                      : '#4a5568',
              }}
            >
              {quiz.quizName}
            </Typography>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px', mt: 2 }}>
              <ThumbUpOutlinedIcon
                sx={{
                  fontSize: '15px',
                  ml: 0,
                  color: getIconColor(theme),
                }}
              />
              <Typography
                variant='body2'
                sx={{
                  fontSize: '13px',
                  lineHeight: 1,
                  fontWeight: '500',
                  color: getIconColor(theme),
                }}
              >
                {quiz.upvotes || 0}
              </Typography>
              <BallotIcon
                sx={{
                  fontSize: '15px',
                  ml: 1,
                  color: getIconColor(theme),
                }}
              />
              <Typography
                variant='body2'
                sx={{
                  fontSize: '13px',
                  lineHeight: 1,
                  fontWeight: '500',
                  color: getIconColor(theme),
                }}
              >
                {`${quiz.results ? quiz.results.length : 0} frågor`}
              </Typography>
            </Box>

            <Typography variant='body2' sx={{ ...styles.grayText, mt: 2, lineHeight: '21px' }}>
              {quiz.description && quiz.description.length > 260
                ? quiz.description.slice(0, 260) + '...'
                : quiz.description || 'Ingen beskrivning tillgänglig'}
            </Typography>

            <Box
              sx={{
                backgroundColor: theme.palette.mode === 'dark' ? '#2d3e4d' : '#f9f9f9',
                borderRadius: '6px',
                padding: '15px',
                mt: 3,
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography
                  variant='body2'
                  sx={{
                    ...styles.grayText,
                    fontSize: '13px',
                    display: 'flex',
                    alignItems: 'center',
                    mr: 3,
                    mb: 1,
                  }}
                >
                  <PersonIcon sx={{ mr: 1, fontSize: '16px' }} />
                  {user.displayName}
                </Typography>
                <Typography
                  variant='body2'
                  sx={{
                    ...styles.grayText,
                    fontSize: '13px',
                    display: 'flex',
                    alignItems: 'center',
                    mb: 1,
                  }}
                >
                  <AccessTimeIcon sx={{ mr: 1, fontSize: '16px' }} />
                  {formatDate(quiz.createdAt)}
                </Typography>
              </Box>
              <Box sx={{ mt: 1 }}>
                <Chip
                  label={getDifficultyLabel(quiz.difficulty)}
                  size='small'
                  sx={{
                    mr: 1,
                    backgroundColor: getDifficultyColor(quiz.difficulty, theme).bg,
                    color: getDifficultyColor(quiz.difficulty, theme).text,
                  }}
                />
                <Chip
                  label={getQuestionTypeLabel(quiz.questionType)}
                  size='small'
                  sx={{
                    backgroundColor:
                      theme.palette.mode === 'dark'
                        ? 'rgba(255, 255, 255, 0.1)'
                        : 'rgba(0, 0, 0, 0.08)',
                  }}
                />
              </Box>
            </Box>

            <Button
              variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
              sx={{
                width: '100%',
                mt: 2,
              }}
            >
              Starta Quiz
            </Button>
          </CardContent>
        </Card>
      </Grid>
    );
  };

  return (
    <>
      <DrawerComponent user={user} theme={theme} open={drawerOpen} onClose={toggleDrawer(false)} />

      <Box sx={styles.container}>
        <Box sx={{ ...styles.innerContainer, width: { xs: '100%', md: '100%' }, margin: '0 auto' }}>
          <Typography variant='h4' sx={styles.header}>
            Quiz
          </Typography>

          <Box sx={{ mt: 2 }}>
            <Box sx={{ ...styles.buttonsContainer, ...styles.additionalButtonsContainer }}>
              {isMobile ? (
                <Button
                  variant='contained'
                  color='primary'
                  disableRipple
                  onClick={handleCreateNewQuiz}
                  startIcon={<AddIcon />}
                  sx={styles.shrinkButton}
                />
              ) : (
                <Button
                  variant='contained'
                  color='primary'
                  onClick={handleCreateNewQuiz}
                  startIcon={<AddIcon />}
                >
                  Nytt quiz
                </Button>
              )}

              <Button
                variant='contained'
                color='primary'
                disableRipple
                onClick={toggleDrawer(true)}
                startIcon={<SegmentIcon />}
                sx={{
                  padding: '8px',
                  marginRight: '0px',
                  paddingLeft: '14px',
                  paddingRight: '3px',
                  minWidth: 'auto',
                  textTransform: 'none',
                }}
              />
            </Box>

            {/*

              <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 2 }}>
                <TextField
                  placeholder='Sök quiz'
                  value={searchTerm}
                  fullWidth
                  onChange={e => setSearchTerm(e.target.value)}
                  sx={{ ...styles.whiteBg }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SearchIcon sx={{ fontSize: '20px' }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
*/}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'left',
                gap: 1,
                mb: 3,
                mt: 5,
                flexWrap: 'wrap',
              }}
            >
              {/*  <Button sx={{...styles.beautyBtn, ...styles.catBtn, borderRadius:'30px', fontSize:'14px'}} variant="outlined" onClick={() => scrollToSection(featuredRef)}>Utvalda</Button>*/}

              <Button
                sx={{
                  ...styles.beautyBtn,
                  ...styles.catBtn,
                  borderRadius: '30px',
                  fontSize: '14px',
                  display: 'none',
                }}
                variant='outlined'
                onClick={showHighestRated}
              >
                Högst betyg
              </Button>
              <Button
                sx={{
                  ...styles.beautyBtn,
                  ...styles.catBtn,
                  borderRadius: '30px',
                  fontSize: '14px',
                  display: 'none',
                }}
                variant='outlined'
                onClick={showRecentlyGenerated}
              >
                Senast genererade
              </Button>
              {Object.keys(categorizedQuizzes)
                .filter(category => category !== 'Course' && category !== 'Från kurser')
                .map((category, index) => (
                  <Button
                    key={index}
                    sx={{
                      ...styles.beautyBtn,
                      ...styles.catBtn,
                      borderRadius: '30px',
                      fontSize: '14px',
                    }}
                    variant='outlined'
                    onClick={() => handleCategorySelect(category)}
                  >
                    {category}
                  </Button>
                ))}
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mb: 5,
                textAlign: 'center',
              }}
            >
              <Button
                variant='outlined'
                sx={{ ...styles.beautyBtnTres }}
                startIcon={<FilterListIcon />}
                onClick={() => setFilterOpen(!filterOpen)}
              >
                Filtrera
              </Button>
            </Box>

            <Suspense
              fallback={
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <CircularProgress size={16} />
                </div>
              }
            >
              {filterOpen && (
                <FilterComponent
                  difficultyFilter={difficultyFilter}
                  setQuestionTypeFilter={setQuestionTypeFilter}
                  setDifficultyFilter={setDifficultyFilter}
                  languageFilter={languageFilter}
                  setLanguageFilter={setLanguageFilter}
                  modelFilter={modelFilter}
                  setModelFilter={setModelFilter}
                  questionCountFilter={questionCountFilter}
                  setQuestionCountFilter={setQuestionCountFilter}
                  difficulties={difficulties}
                  questionTypes={questionTypes}
                  models={models}
                  clearFilters={clearFilters}
                  styles={styles}
                />
              )}
            </Suspense>
          </Box>

          {/* Content Section */}
          {isLoadingQuizzes ? (
            <CourseListSkeleton theme={theme} numberOfCourses={8} />
          ) : (
            <Grid container spacing={3}>
              {getDisplayQuizzes().map(quiz => renderQuizCard(quiz))}
            </Grid>
          )}

          <Box sx={{ ...styles.bottomCta }}>
            <QuizIcon
              sx={{
                fontSize: { xs: 40, md: 60 },
                color: theme.palette.primary.main,
                marginBottom: 2,
              }}
            />
            <Typography variant='h4' sx={{ ...styles.bottomCtaTitle }}>
              Skapa ditt eget quiz med AI!
            </Typography>
            <Typography variant='body1' sx={{ ...styles.bottomCtaBody }}>
              Generera skräddarsydda quiz med hjälp av AI. Anpassa svårighetsgrad, ämnesområden och
              frågetyper för en unik och utmanande quizupplevelse.
            </Typography>
            <Button
              component={Link}
              to='/app/quiz'
              variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
              color='primary'
              size='large'
              startIcon={<AddIcon />}
              sx={{
                textTransform: 'none',
                fontWeight: 'bold',
                padding: '10px 20px',
                fontSize: '1.1rem',
                marginTop: '20px',
              }}
            >
              Skapa quiz med AI
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PublicQuiz;
