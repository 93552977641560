import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Typography, CircularProgress, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CourseList from './CourseList';

import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  setDoc,
} from 'firebase/firestore';
import { db } from './firebase';
import { getStyles } from '../index';
import { Helmet } from 'react-helmet-async';
import { CourseListSkeleton } from './TeacherSkeleton';

import CourseForm from './CourseForm';
import CourseModules from './CourseModules';
import CourseChapters from './CourseChapters';
import CourseProgress from './CourseProgress';
import CourseChapterContent from './CourseChapterContent';

import Logger from './Logger';

const Courses = ({ user }) => {
  const theme = useTheme();
  const styles = getStyles(theme);
  const navigate = useNavigate();
  const { courseId, courseName, moduleName, chapterName } = useParams();

  const [dynamicTitle, setDynamicTitle] = useState('Skapa en kurs med AI | Studera.AI');
  const [dynamicDescription, setDynamicDescription] = useState(
    'Skapa din egen kurs med AI om vilket ämne som helst. Generera quiz och flashcards för att lära dig effektivt.'
  );

  const [topic, setTopic] = useState('');
  const [courseModules, setCourseModules] = useState([]);
  const [selectedModule, setSelectedModule] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [userCourses, setUserCourses] = useState([]);
  const [courseProgress, setCourseProgress] = useState(0);
  const [totalChapters, setTotalChapters] = useState(0);
  const [estimatedTime, setEstimatedTime] = useState(0);
  const [totalModules, setTotalModules] = useState(0);

  useEffect(() => {
    Logger.log('courseId: ', courseId);

    // const simulateVotes = async () => {
    //   try {
    //     // Get all documents from the publicTeachers collection
    //     const querySnapshot = await getDocs(collection(db, 'courses'));

    //     // Update each document with a random number of upVotes
    //     const updatePromises = querySnapshot.docs.map(async document => {
    //       const randomVotes = Math.floor(Math.random() * (3500 - 173 + 1) + 173);
    //       await updateDoc(doc(db, 'courses', document.id), {
    //         upvotes: randomVotes,
    //       });
    //     });

    //     // Wait for all updates to complete
    //     await Promise.all(updatePromises);

    //     console.log('Vote simulation completed successfully');
    //   } catch (error) {
    //     console.error('Error simulating votes:', error);
    //   }
    // };

    // simulateVotes();

    if (!courseName) {
      setSelectedModule(null);
      setCourseModules([]);
      setTopic('');
    }
  }, [courseName]);

  useEffect(() => {
    if (courseModules.length > 0 && moduleName) {
      const module = courseModules.find(m => formatUrlPart(m.module_title) === moduleName);
      setSelectedModule(module);
    }
  }, [courseModules, moduleName]);

  useEffect(() => {
    if (user) {
      fetchUserCourses();
    }
  }, [user]);

  useEffect(() => {
    if (courseName) {
      fetchCourseBySlug(courseName);
    }
  }, [courseName]);

  useEffect(() => {
    updateDynamicTitleAndDescription();
  }, [courseName, moduleName, chapterName, topic, selectedModule]);

  const updateDynamicTitleAndDescription = () => {
    if (!courseName) {
      setDynamicTitle('Skapa en kurs med AI | Studera.ai');
      setDynamicDescription('Skapa en personlig kurs med AI så du kan studera i ditt eget tempo.');
    } else if (courseName && !moduleName) {
      setDynamicTitle(`${topic || 'Kurs'}: Delkurser | Studera.ai`);
      setDynamicDescription(
        `Upptäck de olika delarna i kursen om "${topic || 'Kurs'}" och testa vad du lärt dig med quiz och flashcards. `
      );
    } else if (moduleName && !chapterName) {
      const moduleTitle = selectedModule ? selectedModule.module_title : 'Modul';
      setDynamicTitle(`${topic}: ${moduleTitle} | Studera.ai`);
      setDynamicDescription(
        `Utforska kapitlen i delkursen om "${moduleTitle}" och fördjupa dina kunskaper inom ${topic}.`
      );
    } else if (chapterName) {
      const chapterTitle =
        selectedModule?.chapters.find(ch => formatUrlPart(ch.chapter_title) === chapterName)
          ?.chapter_title || 'Kapitel';
      setDynamicTitle(`${topic}: ${chapterTitle} | Studera.ai`);
      setDynamicDescription(`Lär dig om "${chapterTitle}" i kursen ${topic}.`);
    }
  };

  const fetchUserCourses = async () => {
    const q = query(collection(db, 'courses'), where('userId', '==', user.uid));
    const querySnapshot = await getDocs(q);
    const courses = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setUserCourses(courses);
  };

  const fetchCourseBySlug = async slug => {
    setIsLoading(true);
    try {
      const q = query(
        collection(db, 'courses'),
        where('slug', '==', slug),
        where('userId', '==', user.uid)
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const docSnap = querySnapshot.docs[0];
        const courseData = docSnap.data();
        setCourseModules(courseData.modules);
        setTopic(courseData.topic);
        updateCourseProgress(courseData.modules);

        // Calculate and set total modules
        const modulesCount = courseData.modules.length;
        setTotalModules(modulesCount);

        // Calculate total chapters
        const chaptersCount = courseData.modules.reduce(
          (sum, module) => sum + module.chapters.length,
          0
        );

        const completedChapters = courseData.modules.reduce(
          (sum, module) =>
            sum + module.chapters.filter(chapter => chapter.chapter_completed).length,
          0
        );

        // Calculate remaining chapters and set estimated time
        const remainingChapters = chaptersCount - completedChapters;
        setEstimatedTime(remainingChapters * 20); // 20 minutes per remaining chapter

        setTotalChapters(chaptersCount);

        // Update course progress
        const progress = (completedChapters / chaptersCount) * 100;
        setCourseProgress(progress);
      } else {
        // If course does not exist, create a copy for the user
        // Moonshine: Kanske bheöver använda generateUniqueSlug() för att generera ett nytt slug?
        const qPublic = query(collection(db, 'courses'), where('slug', '==', slug));
        const publicQuerySnapshot = await getDocs(qPublic);
        if (!publicQuerySnapshot.empty) {
          const publicDocSnap = publicQuerySnapshot.docs[0];
          const publicCourseData = publicDocSnap.data();
          const newCourseRef = doc(collection(db, 'courses'));
          await setDoc(newCourseRef, {
            ...publicCourseData,
            userId: user.uid,
            createdAt: new Date(),
            modules: publicCourseData.modules.map(module => ({
              ...module,
              chapters: module.chapters.map(chapter => ({
                ...chapter,
                chapter_completed: false,
                chapter_content: '',
              })),
            })),
          });
          fetchCourseBySlug(slug); // Re-fetch the course now that it has been created for the user
        } else {
          console.log('No such document!');
        }
      }
    } catch (error) {
      console.error('Error fetching course:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateCourseProgress = modules => {
    const totalChapters = modules.reduce((sum, module) => sum + module.chapters.length, 0);
    const completedChapters = modules.reduce(
      (sum, module) => sum + module.chapters.filter(chapter => chapter.chapter_completed).length,
      0
    );
    const progress = (completedChapters / totalChapters) * 100;
    setCourseProgress(progress);
  };

  const formatUrlPart = str => {
    return str
      .replace(/\s+/g, '-')
      .replace(/[åä]/g, 'a')
      .replace(/[ö]/g, 'o')
      .replace(/[^a-z0-9-]/gi, '')
      .toLowerCase();
  };

  const handleModuleClick = module => {
    setSelectedModule(module);
    const formattedModuleName = formatUrlPart(module.module_title);
    navigate(`${formattedModuleName}`);
  };

  const handleChapterClick = (chapter, chapterIndex) => {
    const formattedChapterName = formatUrlPart(chapter.chapter_title);
    navigate(
      `/app/kurser/${courseName}/${formatUrlPart(selectedModule.module_title)}/${formattedChapterName}`
    );
  };

  const handleChapterCompletion = async chapterIndex => {
    if (!selectedModule) return;

    const updatedModule = { ...selectedModule };
    updatedModule.chapters[chapterIndex].chapter_completed =
      !updatedModule.chapters[chapterIndex].chapter_completed;

    const updatedModules = courseModules.map(module =>
      module.module_title === updatedModule.module_title ? updatedModule : module
    );

    setCourseModules(updatedModules);
    setSelectedModule(updatedModule);
    updateCourseProgress(updatedModules);

    // Fetch the course by courseName (slug) to get courseId
    try {
      const q = query(
        collection(db, 'courses'),
        where('slug', '==', courseName),
        where('userId', '==', user.uid)
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const docRef = querySnapshot.docs[0].ref; // Get the document reference directly
        await updateDoc(docRef, { modules: updatedModules });
      } else {
        console.error('No course found with the given name!');
      }
    } catch (error) {
      console.error('Error updating chapter completion:', error);
    }
  };

  const handleBackClick = () => {
    if (chapterName) {
      navigate(`/app/kurser/${courseName}/${formatUrlPart(selectedModule.module_title)}`);
    } else if (moduleName) {
      setSelectedModule(null);
      navigate(`/app/kurser/${courseName}`);
    } else {
      navigate('/app/kurser');
    }
  };

  return (
    <>
      <Helmet>
        <title>{dynamicTitle}</title>
        <meta name='description' content={dynamicDescription} />
      </Helmet>
      <Box sx={styles.container}>
        <Box sx={styles.innerContainer}>
          {courseName && (
            <>
              <Typography variant='h1' sx={{ ...styles.header }}>
                {`${topic} — Din kurs`}
              </Typography>
              <CourseProgress
                totalModules={totalModules}
                totalChapters={totalChapters}
                estimatedTime={estimatedTime}
                courseProgress={courseProgress}
              />
            </>
          )}

          {(courseName || moduleName || chapterName) && (
            <IconButton onClick={handleBackClick} sx={{ mb: 2 }}>
              <ArrowBackIcon />
            </IconButton>
          )}

          {isLoading && <CourseListSkeleton theme={theme} numberOfCourses={6} />}

          {!courseName && (
            <>
              <CourseForm user={user} fetchUserCourses={fetchUserCourses} />
              {userCourses.length > 0 ? (
                <>
                  <Typography
                    variant='h2'
                    sx={{
                      ...styles.header,
                      marginTop: '100px',
                      marginBottom: 1,
                      textAlign: 'center',
                      marginLeft: { xs: 'none', md: 'inherit' },
                    }}
                  >
                    Dina kurser
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    gutterBottom
                    sx={{ ...styles.softerText, mb: 5, textAlign: 'center' }}
                  >
                    Översikt över aktiva och slutförda kurser
                  </Typography>
                  <CourseList userCourses={userCourses} />
                </>
              ) : (
                <>
                  <Typography
                    variant='h2'
                    sx={{
                      ...styles.header,
                      marginTop: '100px',
                      marginBottom: 1,
                      textAlign: 'center',
                      marginLeft: { xs: 'none', md: 'inherit' },
                    }}
                  >
                    Exempel på AI-genererade kurser
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    gutterBottom
                    sx={{ ...styles.softerText, mb: 5, textAlign: 'center' }}
                  >
                    Ta en titt på ett urval av kurser framtagna av andra användare
                  </Typography>
                  <CourseList
                    courseIds={[
                      'd9RQjnU8wVGcaiDrO2dd',
                      'hGrCbLXixh2kXy131Yf8',
                      'DAmlDfx15ciBiOVAfXOj',
                      'zRl9weyypH0guq6roPvt',
                      'fgpOLak0BBqJP85zVj3z',
                      'CdLx3L3F8LqrXI2vCX6G',
                      'KYyUj9Q8Ye5nmpT9aUKF',
                      'r7NnC08Grk6ylkKILsFa',
                    ]}
                  />
                </>
              )}
            </>
          )}

          {courseName && !selectedModule && !chapterName && (
            <CourseModules courseModules={courseModules} handleModuleClick={handleModuleClick} />
          )}

          {selectedModule && !chapterName && (
            <CourseChapters
              user={user}
              selectedModule={selectedModule}
              setSelectedModule={setSelectedModule}
              handleChapterClick={handleChapterClick}
              handleChapterCompletion={handleChapterCompletion}
              selectedModuleIndex={courseModules.findIndex(
                module => module.module_title === selectedModule.module_title
              )}
              courseName={courseName}
            />
          )}

          {chapterName && selectedModule && (
            <CourseChapterContent
              user={user}
              handleChapterCompletion={handleChapterCompletion}
              selectedModule={selectedModule}
              selectedModuleIndex={courseModules.findIndex(
                module => module.module_title === selectedModule.module_title
              )}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

export default Courses;
