import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    Box,
    IconButton,
    Divider,
    Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TimerIcon from '@mui/icons-material/Timer';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Link as MuiLink } from '@mui/material';
import PricesSection from '../PricesSection';
import ContactForm from '../ContactForm';
import BoltIcon from '@mui/icons-material/Bolt';

const ImageLimitDialog = ({ open, onClose, resetTime, theme }) => {
    const [countdown, setCountdown] = useState('');
    const [showContact, setShowContact] = useState(false);

    useEffect(() => {
        if (!resetTime) return;
        const updateCountdown = () => {
            const now = new Date().getTime();
            const distance = resetTime - now;
            const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);
            setCountdown(`${hours}h ${minutes}m ${seconds}s`);
            if (distance < 0) {
                onClose();
            }
        };
        updateCountdown();
        const interval = setInterval(updateCountdown, 1000);
        return () => clearInterval(interval);
    }, [resetTime, onClose]);

    const handleContactClick = e => {
        e.preventDefault();
        setShowContact(true);
    };

    const handleBackClick = () => {
        setShowContact(false);
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    minWidth: '1050px',
                    borderRadius: '12px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#22303C' : '#f1f5f9',
                    backgroundImage: theme.palette.mode === 'dark' ? 'none' : '#f1f5f9',
                    padding: { xs: '0px', md: '20px' },
                },
            }}
        >
            <DialogTitle
                sx={{
                    p: 3,
                    pb: 2,
                    position: 'relative',
                }}
            >
                <Box
                    sx={{
                        textAlign: 'center',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '8px',
                    }}
                >
                    <BoltIcon sx={{ color: '#8B5CF6', fontSize: '38px' }} />
                    <Typography variant='h5' sx={{ fontWeight: 800, fontSize: '2rem' }}>
                        {showContact ? 'Kontakta oss' : 'Daglig gräns för bildgenerering uppnådd'}
                    </Typography>
                </Box>
                {showContact && (
                    <IconButton
                        onClick={handleBackClick}
                        size='large'
                        sx={{
                            position: 'absolute',
                            left: 0,
                            top: 0,
                        }}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                )}
                <IconButton
                    onClick={onClose}
                    size='large'
                    sx={{
                        position: 'absolute',
                        right: 0,
                        top: 0,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent sx={{ p: 0 }}>
                {showContact ? (
                    <ContactForm bgcolor='transparent' />
                ) : (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            textAlign: 'center',
                            mb: 4,
                            mt: 2,
                        }}
                    >
                        <Typography variant='h6' sx={{ mb: 0, maxWidth: '600px', display: 'none' }}>
                            Du har nu nått den dagliga begränsningen för gratiskontot.
                        </Typography>

                        <Typography
                            variant='h6'
                            paragraph
                            color='text.secondary'
                            sx={{ textAlign: 'center', mb: 3, maxWidth: '600px' }}
                        >
                            Du kan fortsätta generera lektioner och använda alla andra verktyg, men
                            bilder kan du återigen börja generera om:
                        </Typography>

                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 2,
                                mb: 4,
                            }}
                        >
                            <TimerIcon sx={{ fontSize: 32, color: '#8B5CF6' }} />
                            <Box>
                                <Typography
                                    variant='h4'
                                    sx={{
                                        fontWeight: 700,
                                        color: '#8B5CF6',
                                        letterSpacing: '-0.5px',
                                    }}
                                >
                                    {countdown}
                                </Typography>
                            </Box>
                        </Box>

                        <Divider sx={{ width: '100%', mb: 4 }} />

                        <Typography
                            variant='h5'
                            sx={{
                                fontWeight: 800,
                                textAlign: 'center',
                                mb: 1,
                                fontSize: '1.8rem',
                                ...(theme.palette.mode === 'dark'
                                    ? {
                                          backgroundClip: 'text',
                                          textFillColor: 'transparent',
                                          background:
                                              'linear-gradient(135deg, #43CBFF 10%, #9708CC 100%)',
                                          WebkitBackgroundClip: 'text',
                                          WebkitTextFillColor: 'transparent',
                                      }
                                    : {
                                          color: 'inherit',
                                      }),
                            }}
                        >
                            Vill du fortsätta använda Studera.AI?
                        </Typography>

                        <Typography
                            variant='h6'
                            paragraph
                            color='text.secondary'
                            sx={{ textAlign: 'center', mb: 4 }}
                        >
                            {' '}
                            <MuiLink
                                onClick={handleContactClick}
                                sx={{
                                    color: 'text.secondary',
                                    textDecoration: 'none',
                                    paddingBottom: '0px',
                                    borderBottom: '2px solid',
                                    borderColor: 'text.secondary',
                                    cursor: 'pointer',
                                    '&:hover': {
                                        textDecoration: 'none',
                                        borderColor: 'text.primary',
                                    },
                                }}
                            >
                                Kontakta oss
                            </MuiLink>
                        </Typography>

                        <Box sx={{ width: '800px', display: 'none' }}>
                            <PricesSection hideBasic={true} />
                        </Box>
                    </Box>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default ImageLimitDialog;
