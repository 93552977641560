import React, { useState, useRef } from 'react';
import {
  Box,
  Button,
  Typography,
  Divider,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
  Grid,
  TextField,
} from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import GenerateIcon from '@mui/icons-material/AutoAwesome';
import ImageIcon from '@mui/icons-material/Image';
import PrintIcon from '@mui/icons-material/Print';
import { GiBookshelf } from 'react-icons/gi';
import { callApi } from './api';
import LoadingBar from 'react-top-loading-bar';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import OverlayLesson from './OverlayLesson';
import LanguageSelector from './LanguageSelector';
import InfoBox from './InfoBox';
import { handlePrintReadingLesson } from './printFunctions';
import { generateAndProcessImage } from './functions';
import { useSnackbar } from './SnackbarHandler';
import ProgressBar from './ProgressBar';
import { ThemeSelector } from './ThemeSelector';
import ImageGenerationModal from './ImageGenerationModal';
import GradeSelector, { gradeInstructions } from './GradeSelector';
import { useTheme } from '@mui/material/styles';
import { getStyles } from '../index';
import { trackLesson } from './functions';

const WordCompLesson = ({ user }) => {
  const [lessonTheme, setLessonTheme] = useState('generateWithAi');
  const [language, setLanguage] = useState('svenska');
  const [titleText, setTitleText] = useState('');
  const [grade, setGrade] = useState('åk6');
  const [progress, setProgress] = useState(0);
  const [isGeneratingLesson, setIsGeneratingLesson] = useState(false);
  const [isGeneratingImages, setIsGeneratingImages] = useState(false);
  const [editorContent, setEditorContent] = useState('');
  const [customTheme, setCustomTheme] = useState('');
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageInstructions, setImageInstructions] = useState('');
  const [openAiInstructions, setOpenAiInstructions] = useState('');
  const [wordCount, setWordCount] = useState(5);
  const [wordType, setWordType] = useState('random');

  const editorRef = useRef(null);
  const { showSnackbar } = useSnackbar();
  const theme = useTheme();
  const styles = getStyles(theme);

  const simulateProgress = (startProgress, endProgress, duration) => {
    const stepSize = 0.5;
    const steps = (endProgress - startProgress) / stepSize;
    const stepDuration = duration / steps;
    let currentProgress = startProgress;

    const intervalId = setInterval(() => {
      currentProgress += stepSize;
      if (currentProgress >= endProgress) {
        clearInterval(intervalId);
      } else {
        setProgress(currentProgress);
      }
    }, stepDuration);

    return () => clearInterval(intervalId);
  };

  const handleGenerateLesson = async () => {
    setProgress(0);
    let stopProgressSimulation = null;
    setIsGeneratingLesson(true);
    const gradeInstructionsText = gradeInstructions[grade];

    try {
      const generateTheme = lessonTheme === 'generateWithAi';
      const finalTheme = generateTheme
        ? '[RANDOM]'
        : lessonTheme === 'customTheme'
          ? customTheme
          : lessonTheme;
      stopProgressSimulation = simulateProgress(0, 90, 15000);

      let response = await callApi(
        [
          {
            role: 'system',
            content: `You are an expert teacher creating engaging reading comprehension lessons in ${language}. Follow the exact JSON structure provided in the prompt.`,
          },
          {
            role: 'user',
            content: `Skapa en text mellan 50-60 meningar som är UPPDELAD I TYDLIGA STYCKEN med fokus på ordförståelse. 
          Använd '\\n\\n' för att separera styckena.
          Texten ska vara skriven på ${language}.
          
          FÖLJ DENNA STRUKTUR EXAKT:
          1. Välj först EXAKT ${wordCount} ord ${
            wordType === 'verbs'
              ? 'som alla ska vara verb (handlingsord, t.ex. springa, läsa, skriva)'
              : wordType === 'adjectives'
                ? 'som alla ska vara adjektiv (beskrivande ord, t.ex. glad, stor, vacker)'
                : wordType === 'nouns'
                  ? 'som alla ska vara substantiv (namn på saker/personer/platser, t.ex. bord, lärare, park)'
                  : wordType === 'pronouns'
                    ? 'som alla ska vara pronomen (ersättningsord, t.ex. han, hon, den, det, denna)'
                    : wordType === 'adverbs'
                      ? 'som alla ska vara adverb (beskriver hur/när/var något sker, t.ex. snabbt, ofta, här)'
                      : wordType === 'prepositions'
                        ? 'som alla ska vara prepositioner (lägesord, t.ex. på, under, bredvid, genom)'
                        : wordType === 'conjunctions'
                          ? 'som alla ska vara konjunktioner (bindeord, t.ex. och, men, eller, eftersom)'
                          : wordType === 'interjections'
                            ? 'som alla ska vara interjektioner (utrop, t.ex. oj, hej, aj)'
                            : wordType === 'numerals'
                              ? 'som alla ska vara räkneord (t.ex. tre, första, dussin)'
                              : 'av blandade ordtyper'
          }

          I "selectedWords" ska du lista orden i EXAKT samma form som de förekommer i texten.
          
          Theme: "${finalTheme}".
          ${openAiInstructions}.
          ${gradeInstructionsText}

          SVARA I FÖLJANDE JSON-FORMAT:
          {
            "title": "Lektionens titel",
            "theme": "Ordtema",
            "selectedWords": "ord1, ord2, ord3...",
            "content": "Text på ${language} här...",
            "exercises": [
              {
                "type": "definitions",
                "title": "Ordförklaringar",
                "content": "<table><tr><th>Ord</th><th>Förklaring</th></tr><tr><td>ord1</td><td>Förklaring...</td></tr></table>"
              },
              {
                "type": "synonyms",
                "title": "Synonymer och motsatsord",
                "content": "<table><tr><th>Ord</th><th>Synonymer</th><th>Motsatsord</th></tr><tr><td>ord1</td><td>...</td><td>...</td></tr></table>"
              },
              {
                "type": "usage",
                "title": "Använd orden",
                "content": "<ol><li>Uppgift...</li></ol>"
              }
            ]
          }`,
          },
        ],
        6000,
        true,
        user.uid
      );

      if (stopProgressSimulation) stopProgressSimulation();
      setProgress(95);

      let messageContent = response.data.choices[0].message.content.trim();
      if (messageContent.startsWith('```json')) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith('```')) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const data = JSON.parse(messageContent);
      setProgress(100);

      // Add mark tags around selected words
      if (data.selectedWords) {
        const selectedWords = data.selectedWords.split(',').map(word => word.trim());
        let markedContent = data.content;
        const markedWords = new Set();

        selectedWords.forEach(word => {
          if (!markedWords.has(word.toLowerCase())) {
            const regex = new RegExp(`\\b${word}\\b`, 'i');
            if (markedContent.match(regex)) {
              markedContent = markedContent.replace(regex, match => {
                markedWords.add(match.toLowerCase());
                return `<mark>${match}</mark>`;
              });
            }
          }
        });

        data.content = markedContent;
      }

      // Format exercises
      const formattedExercises = data.exercises
        .map(
          exercise => `
          <div class="exercise">
            <h3 style="font-weight: bold; font-size: 20px; margin-bottom: 15px;">
              ${exercise.title}
            </h3>
            <div class="exercise-content">
              ${exercise.content}
            </div>
          </div>
        `
        )
        .join('');

      const formattedContent = `
        <div class="lesson-container">
          <h4 style="text-transform: uppercase; color: #666; font-size: 13px; margin-bottom: 4px;">
            ${data.theme || finalTheme}
          </h4>
          <h2 style="font-weight: bold; font-size: 30px; margin-bottom: 20px;">
            ${data.title || 'Ordförståelse'}
          </h2>
          
          <div id="image-insertion-point"></div>
          
          <div class="main-content">
            ${data.content
              .split('\n\n')
              .map(paragraph => `<p style="margin-bottom: 1.5em;">${paragraph.trim()}</p>`)
              .join('\n')}
          </div>
          
          <div class="exercises">
            ${formattedExercises}
          </div>
        </div>
      `;

      setTitleText(data.title);
      await new Promise(resolve => setTimeout(resolve, 800));
      setEditorContent(formattedContent);

      await trackLesson(user.uid, 'WordCompLesson.js', null, { language });
    } catch (error) {
      console.error('Error generating lesson:', error);
      showSnackbar('Ett fel uppstod när lektionen skulle genereras. Försök igen!', 'error');
    } finally {
      setIsGeneratingLesson(false);
    }
  };

  const handleImageButtonClick = () => setShowImageModal(true);
  const handleCloseModal = () => {
    setShowImageModal(false);
    setImageInstructions('');
  };

  const handleGenerateWithInstructions = async () => {
    handleCloseModal();
    await handleGenerateImages();
  };

  const handleGenerateImages = async () => {
    setIsGeneratingImages(true);
    try {
      const currentContent = editorContent;
      const insertPoint = currentContent.indexOf('<div id="image-insertion-point"></div>');

      const imagePrompt = `Create an image about a story with the theme: "${titleText}". ${imageInstructions}`;
      const imageUrl = await generateAndProcessImage(imagePrompt, user.uid, {
        size: '1024x1024',
        model: 'dall-e-3',
        quality: 'standard',
      });

      const imageHtml = `
        <div style="margin: 24px 0;">
          <img src="${imageUrl}" alt="Lesson illustration" 
               style="width: 100%; max-width: 1024px; height: auto; border-radius: 8px;">
        </div>
      `;

      const newContent = currentContent.replace(
        '<div id="image-insertion-point"></div>',
        imageHtml + '<div id="image-insertion-point"></div>'
      );

      setEditorContent(newContent);
      showSnackbar('Bild har genererats!', 'success');
    } catch (error) {
      console.error('Error generating image:', error);
      showSnackbar('Ett fel uppstod när bilden skulle genereras', 'error');
    } finally {
      setIsGeneratingImages(false);
    }
  };

  return (
    <Box sx={styles.container}>
      <Box
        sx={{
          ...styles.innerContainer,
          width: { xs: '100%', md: '87%' },
          margin: '0 auto',
        }}
      >
        <Typography
          variant='h1'
          gutterBottom
          sx={{
            fontWeight: 'bold',
            fontSize: '1.8em',
            mb: 3,
            mt: 5,
            textAlign: 'center',
          }}
        >
          Ordförståelse
        </Typography>

        <InfoBox
          icon={TextFieldsIcon}
          title='Ordförståelse'
          instructions={[
            'Välj antal ord och vilka ordklasser som ska fokuseras',
            'Få ordförklaringar, synonymer och exempel på användning',
            'Utveckla ordförrådet genom kontext och övningar',
          ]}
          theme={theme}
          styles={styles}
        />

        <OverlayLesson
          message='Vi arbetar just nu på att få in Skolverkets kursplan i lektionerna för läsförståelse'
          submessage='Vi är snart klara, så titta in på sidan snart igen så kommer du kunna generera ännu bättre lektioner för läsförståelse!'
          user={user}
        />

        <Box sx={{ ...styles.freshBox, padding: '30px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <ThemeSelector
                value={lessonTheme}
                onChange={e => setLessonTheme(e.target.value)}
                theme={theme}
                customTheme={customTheme}
                onCustomThemeChange={e => setCustomTheme(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant='subtitle1' sx={{ fontWeight: '500', mb: 1 }}>
                Årskurs:
              </Typography>
              <Box sx={{ mt: 0.5, mb: 3 }}>
                <GradeSelector grade={grade} setGrade={setGrade} />
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant='subtitle1' sx={{ fontWeight: '500' }}>
                Språk:
              </Typography>
              <Box sx={{ mt: 1, mb: 3 }}>
                <LanguageSelector language={language} setLanguage={setLanguage} />
              </Box>
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant='subtitle1' sx={{ fontWeight: '500' }}>
                Specifika instruktioner till AI:n:
              </Typography>
              <FormControl fullWidth margin='normal' sx={{ mb: 3, mt: 1 }}>
                <TextField
                  value={openAiInstructions}
                  onChange={e => setOpenAiInstructions(e.target.value)}
                  placeholder='T.ex. Inkludera lite svårare ord såsom defenestrera, kalibrera och frekventera.'
                  variant='outlined'
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <Typography variant='subtitle1' sx={{ fontWeight: '500', mb: 1 }}>
                  Antal ord att markera:
                </Typography>
                <Select
                  value={wordCount}
                  onChange={e => setWordCount(e.target.value)}
                  variant='outlined'
                  sx={{ mb: 3 }}
                >
                  {[5, 7, 9, 11, 13, 15].map(num => (
                    <MenuItem key={num} value={num}>
                      {num}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <Typography variant='subtitle1' sx={{ fontWeight: '500', mb: 1 }}>
                  Ordklasser:
                </Typography>
                <Select
                  value={wordType}
                  onChange={e => setWordType(e.target.value)}
                  variant='outlined'
                  sx={{ mb: 3 }}
                >
                  <MenuItem value='random'>Blandade ordklasser</MenuItem>
                  <MenuItem value='nouns'>Substantiv (namn på saker/personer)</MenuItem>
                  <MenuItem value='verbs'>Verb (handlingsord)</MenuItem>
                  <MenuItem value='adjectives'>Adjektiv (beskrivande ord)</MenuItem>
                  <MenuItem value='pronouns'>Pronomen (han, hon, den, det)</MenuItem>
                  <MenuItem value='adverbs'>Adverb (hur/när/var)</MenuItem>
                  <MenuItem value='prepositions'>Prepositioner (på, under, i)</MenuItem>
                  <MenuItem value='conjunctions'>Konjunktioner (och, men, eller)</MenuItem>
                  <MenuItem value='numerals'>Räkneord (tre, första, dussinet)</MenuItem>
                  <MenuItem value='interjections'>Interjektioner (utrop)</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Button
            variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
            color='primary'
            onClick={handleGenerateLesson}
            disabled={isGeneratingLesson}
            sx={{ mt: 4 }}
            startIcon={
              !isGeneratingLesson ? (
                <GenerateIcon style={{ color: '#fff' }} />
              ) : (
                <CircularProgress size={16} />
              )
            }
          >
            {isGeneratingLesson ? 'Genererar...' : 'Generera lektion'}
          </Button>

          {isGeneratingLesson && (
            <Box sx={{ mt: 6 }}>
              <ProgressBar progress={progress} label='Genererar lektion...' />
            </Box>
          )}

          {editorContent && (
            <>
              <Divider sx={{ mt: 3, mb: 3 }} />

              <Button
                variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                color='primary'
                onClick={handleImageButtonClick}
                disabled={isGeneratingImages}
                sx={{ mt: 3 }}
                startIcon={
                  !isGeneratingImages ? (
                    <ImageIcon style={{ color: '#fff' }} />
                  ) : (
                    <CircularProgress size={16} />
                  )
                }
              >
                {isGeneratingImages ? 'Genererar...' : 'Generera bild'}
              </Button>

              <Button
                variant='contained'
                startIcon={<PrintIcon />}
                sx={{ mt: 3, ml: 2 }}
                onClick={() => handlePrintReadingLesson(editorRef)}
              >
                Skriv ut/Spara
              </Button>

              <ImageGenerationModal
                open={showImageModal}
                onClose={handleCloseModal}
                onGenerate={handleGenerateWithInstructions}
                isGenerating={isGeneratingImages}
                instructions={imageInstructions}
                setInstructions={setImageInstructions}
                theme={theme}
              />
            </>
          )}

          <Box sx={{ mt: 7 }}>
            <Editor
              apiKey='fxkb0ty514q6ak7t7lo34gojjloffnpw3n9fr8tdz3swjxi3'
              onInit={(evt, editor) => (editorRef.current = editor)}
              value={editorContent}
              onEditorChange={content => setEditorContent(content)}
              init={{
                height: 800,
                menubar: false,
                plugins: [
                  'advlist',
                  'autolink',
                  'lists',
                  'link',
                  'image',
                  'charmap',
                  'preview',
                  'anchor',
                  'searchreplace',
                  'visualblocks',
                  'code',
                  'fullscreen',
                  'insertdatetime',
                  'media',
                  'table',
                  'code',
                  'help',
                  'wordcount',
                ],
                toolbar:
                  'undo redo | blocks | ' +
                  'bold italic forecolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | help',
                content_style:
                  'body { font-family: -apple-system, BlinkMacSystemFont, sans-serif; line-height: 28px !important; } ' +
                  'p { line-height: 28px !important; } ' +
                  'mark { font-weight: bold; padding: 5px; background: #dcccff; border-radius:5px; } ' +
                  'h3 { margin-top: 60px !important; } ' +
                  'li { margin-bottom: 12px !important; line-height: 28px !important; } ' +
                  'ul, ol { margin:20px !important } ' +
                  'table { width: 100% !important; border-collapse: collapse !important; } ' +
                  'th, td { padding: 10px !important; border: 1px solid #ddd !important; text-align: left !important; } ' +
                  'table, th, td { border: 1px solid #ddd !important; }',
                skin: theme.palette.mode === 'dark' ? 'oxide-dark' : 'oxide',
                content_css: theme.palette.mode === 'dark' ? 'dark' : 'default',
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default WordCompLesson;
