import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  Grid,
  Avatar,
  CircularProgress,
  FormControl,
  Select,
  Skeleton,
  MenuItem,
  ListSubheader,
  Slider,
  Divider,
  Modal,
  TextField,
  Tooltip,
  useTheme,
} from '@mui/material';
import GenerateIcon from '@mui/icons-material/AutoAwesome';
import ImageIcon from '@mui/icons-material/Image';
import GridOnIcon from '@mui/icons-material/GridOn';
import { OrbitProgress } from 'react-loading-indicators';
import { callApi } from './api';
import { generateAndProcessImage, trackLesson } from './functions';
import { useSnackbar } from './SnackbarHandler';
import ImageGenerationModal from './ImageGenerationModal';
import { getStyles } from '../index';
import { Mosaic } from 'react-loading-indicators';
import PrintIcon from '@mui/icons-material/Print';
import BrushIcon from '@mui/icons-material/Brush';
import { handlePrintFindWordsLesson } from './printFunctions';
import GradeSelector, { gradeInstructions } from './GradeSelector';
import LanguageSelector from './LanguageSelector';

import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import EditIcon from '@mui/icons-material/Edit';
import { ThemeSelector } from './ThemeSelector';
import InfoBox from './InfoBox';

import HoverableImage from './HoverableImage';
import Logger from './Logger';
import LessonDrawer from './LessonDrawer';
import SaveLessonDialog from './SaveLessonDialog';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import HistoryIcon from '@mui/icons-material/History';

import { ThemeSelectorFlow } from './ThemeSelectorFlow';

import {
  Science as ScienceIcon,
  Castle as CastleIcon,
  Psychology as PsychologyIcon,
  Pets as PetsIcon,
  Public as PublicIcon,
  EmojiEvents as EmojiEventsIcon,
  Museum as MuseumIcon,
  Nature as NatureIcon,
} from '@mui/icons-material';
import MenuBookIcon from '@mui/icons-material/MenuBook';

const FindWordsLesson = ({ user }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);

  const [words, setWords] = useState([]);
  const [wordGrid, setWordGrid] = useState([]);
  const [wordLocations, setWordLocations] = useState([]); // For debugging
  const [wordPositionsSet, setWordPositionsSet] = useState(new Set()); // For highlighting
  const [isGeneratingLesson, setIsGeneratingLesson] = useState(false);
  const [isGeneratingImages, setIsGeneratingImages] = useState(false);
  const [isGeneratingThemeImage, setIsGeneratingThemeImage] = useState(false);
  const [wordLengthRange, setWordLengthRange] = useState([4, 8]);
  const [generatedImages, setGeneratedImages] = useState(new Set());
  const [themeImage, setThemeImage] = useState(null);
  const [themeType, setThemeType] = useState('halloween');
  const [customTheme, setCustomTheme] = useState('');
  const [gridSize, setGridSize] = useState(12);
  const { showSnackbar } = useSnackbar();
  const [themeColor, setThemeColor] = useState('#000000');
  const [language, setLanguage] = useState('svenska');
  const theme = useTheme();
  const styles = getStyles(theme);
  const [generatingImageIndices, setGeneratingImageIndices] = useState(new Set());
  const [imageInstructions, setImageInstructions] = useState('');
  const [currentUpdateType, setCurrentUpdateType] = useState(null);
  const [imageGenerationType, setImageGenerationType] = useState(null);
  const [grade, setGrade] = useState('åk6');
  const [wordCount, setWordCount] = useState(5);
  const [openInstructionsModal, setOpenInstructionsModal] = useState(false);
  const [customInstructions, setCustomInstructions] = useState('');

  const [showImageModal, setShowImageModal] = useState(false);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [titleText, setTitleText] = useState('');

  const [currentGeneratingIndex, setCurrentGeneratingIndex] = useState(null);

  const [lessonTheme, setLessonTheme] = useState('generateWithAi');
  const [predefinedTheme, setPredefinedTheme] = useState(null);
  const lessonTitleRef = useRef(null); // Add this ref
  const transcriptRef = useRef('');
  const uploadedFileRef = useRef('');

  const wikipediaContentRef = useRef('');
  const randomizedContentRef = useRef('');
  const coursePlanContentRef = useRef('');

  const additionalThemes = [
    { type: 'subheader', label: 'Teman som lämpar sig extra bra för denna lektionstyp:' },
    {
      value: 'selma',
      label: 'Selma Lagerlöf',
      content: 'Gör en lektion om selma lagerlöf',
      Icon: MenuBookIcon,
    },
    {
      value: 'dronten',
      label: 'Dronten',
      content: 'Gör en lektion om den utdöda arten dronten',
      Icon: PetsIcon,
    },
    {
      value: 'vikingar',
      label: 'Vikingar',
      content: 'Gör en lektion om vikingatiden',
      Icon: CastleIcon,
    },
    {
      value: 'rymden',
      label: 'Rymden',
      content: 'Gör en lektion om vårt solsystem',
      Icon: PublicIcon,
    },
    {
      value: 'dinosaurier',
      label: 'Dinosaurier',
      content: 'Gör en lektion om olika dinosaurier',
      Icon: NatureIcon,
    },
    {
      value: 'nobel',
      label: 'Nobelpriset',
      content: 'Gör en lektion om nobelpriset och Alfred Nobel',
      Icon: EmojiEventsIcon,
    },
    {
      value: 'uppfinnare',
      label: 'Svenska uppfinnare',
      content: 'Gör en lektion om kända svenska uppfinnare',
      Icon: ScienceIcon,
    },
    {
      value: 'psychology',
      label: 'Känslor',
      content: 'Gör en lektion om olika känslor',
      Icon: PsychologyIcon,
    },
    {
      value: 'konst',
      label: 'Svensk konst',
      content: 'Gör en lektion om svensk konsthistoria',
      Icon: MuseumIcon,
    },
  ];

  useEffect(() => {
    Logger.log('lessonTheme: ', lessonTheme);
  }, [lessonTheme]);

  useEffect(() => {
    Logger.log('predefinedTheme: ', predefinedTheme);
  }, [predefinedTheme]);

  useEffect(() => {
    Logger.log('customTheme: ', customTheme);
  }, [customTheme]);

  useEffect(() => {
    Logger.log('youtubeTranscript: ', transcriptRef.current);
  }, [transcriptRef.current]);

  useEffect(() => {
    Logger.log('wikipediaContentRef: ', wikipediaContentRef.current);
  }, [wikipediaContentRef.current]);
  useEffect(() => {
    Logger.log('uploadedFileRef: ', uploadedFileRef.current);
  }, [uploadedFileRef.current]);

  useEffect(() => {
    Logger.log('randomizedContentRef: ', randomizedContentRef.current);
  }, [randomizedContentRef.current]);

  useEffect(() => {
    Logger.log('coursePlanContentRef: ', coursePlanContentRef.current);
  }, [coursePlanContentRef.current]);

  const handleImageUpdate = async (newImageUrl, wordIndex = null) => {
    if (wordIndex !== null) {
      // Word image update
      const updatedWords = [...words];
      updatedWords[wordIndex] = {
        ...updatedWords[wordIndex],
        image: newImageUrl,
      };
      setWords(updatedWords);
      showSnackbar(`Bild har uppdaterats`, 'success');
    } else {
      // Theme image update
      setThemeImage(newImageUrl);
      showSnackbar('Temabild har uppdaterats', 'success');
    }
  };

  const handleSaveLessonClick = () => {
    setSaveDialogOpen(true);
  };

  const handleSaveWithComment = async (comment, name, coverImage, tags) => {
    const lessonData = {
      words, // Save the full words array including images
      themeImage,
      titleText,
    };

    await trackLesson(
      user.uid,
      'FindWordsLesson.js',
      lessonData,
      null,
      true,
      comment,
      name,
      coverImage,
      tags
    );
    setDrawerOpen(true);
    showSnackbar('Lektionen sparad i din lektionsbank!', 'success');
  };

  const onSelectLesson = lesson => {
    const savedData = lesson.data;
    setWords(savedData.words);
    setThemeImage(savedData.themeImage);
    setWordGrid(generateWordGrid(savedData.words, gridSize));
    setTitleText(savedData.titleText || lesson.lessonName);
    setDrawerOpen(false);
    showSnackbar(`Lektionen har laddats in.`, 'success');

    setTimeout(() => {
      lessonTitleRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start', // This ensures it scrolls to the top of the element
      });
    }, 100);
  };
  const handleImageButtonClick = (type, index = null) => {
    setImageGenerationType(type);
    setCurrentGeneratingIndex(index);
    setShowImageModal(true);
  };

  const handleCloseModal = () => {
    setShowImageModal(false);
    setImageInstructions('');
  };

  const handleGenerateWithInstructions = async () => {
    handleCloseModal();

    if (imageGenerationType === 'topic') {
      await handleGenerateThemeImage();
    } else if (imageGenerationType === 'facts') {
      if (currentGeneratingIndex !== null) {
        await handleGenerateSingleImage(currentGeneratingIndex, imageInstructions);
      } else {
        await handleGenerateImages();
      }
    }
  };

  const generateWordGrid = (wordList, size) => {
    // Initialize empty grid
    const grid = Array(size)
      .fill()
      .map(() => Array(size).fill(''));
    const locations = []; // Store word placements for verification

    // Get unique letters for filling empty spaces
    const lettersUsed = new Set();
    wordList.forEach(({ word }) => {
      word
        .toUpperCase()
        .split('')
        .forEach(letter => lettersUsed.add(letter));
    });
    const lettersArray = Array.from(lettersUsed);

    // Helper to check if a word can be placed
    const canPlaceWord = (word, row, col, dirRow, dirCol) => {
      const wordLength = word.length;

      // Check bounds
      const endRow = row + (wordLength - 1) * dirRow;
      const endCol = col + (wordLength - 1) * dirCol;
      if (endRow < 0 || endRow >= size || endCol < 0 || endCol >= size) return false;

      // Check each position
      for (let i = 0; i < wordLength; i++) {
        const currentRow = row + i * dirRow;
        const currentCol = col + i * dirCol;
        const currentCell = grid[currentRow][currentCol];
        if (currentCell && currentCell !== word[i]) return false;
      }
      return true;
    };

    // Try to place each word with multiple attempts
    wordList.forEach(({ word }) => {
      const upperWord = word.toUpperCase();
      let placed = false;
      let attempts = 0;

      while (!placed && attempts < 100) {
        // Pick random starting position and direction
        const row = Math.floor(Math.random() * size);
        const col = Math.floor(Math.random() * size);
        const direction = Math.floor(Math.random() * 8);

        // Direction vectors for all 8 directions
        const dirRow = [-1, -1, -1, 0, 0, 1, 1, 1][direction];
        const dirCol = [-1, 0, 1, -1, 1, -1, 0, 1][direction];

        if (canPlaceWord(upperWord, row, col, dirRow, dirCol)) {
          // Place the word
          const placement = {
            word: upperWord,
            start: { row, col },
            end: {
              row: row + (upperWord.length - 1) * dirRow,
              col: col + (upperWord.length - 1) * dirCol,
            },
          };

          // Place each letter
          for (let i = 0; i < upperWord.length; i++) {
            const currentRow = row + i * dirRow;
            const currentCol = col + i * dirCol;
            grid[currentRow][currentCol] = upperWord[i];
          }

          locations.push(placement);
          placed = true;
        }
        attempts++;
      }

      if (!placed) {
        console.warn(`Failed to place word: ${upperWord}`);
      }
    });

    // Fill remaining empty spaces with random letters from used set
    for (let i = 0; i < size; i++) {
      for (let j = 0; j < size; j++) {
        if (!grid[i][j]) {
          grid[i][j] = lettersArray[Math.floor(Math.random() * lettersArray.length)];
        }
      }
    }

    // Compute positionsSet for highlighting
    const positionsSet = new Set();
    locations.forEach(placement => {
      const { word, start, end } = placement;
      const wordLength = word.length;
      const dirRow = Math.sign(end.row - start.row);
      const dirCol = Math.sign(end.col - start.col);

      for (let i = 0; i < wordLength; i++) {
        const currentRow = start.row + i * dirRow;
        const currentCol = start.col + i * dirCol;
        positionsSet.add(`${currentRow}-${currentCol}`);
      }
    });

    setWordLocations(locations); // For debugging
    setWordPositionsSet(positionsSet); // For highlighting
    return grid;
  };

  const handleGenerateLesson = async () => {
    setIsGeneratingLesson(true);
    try {
      const gradeInstructionsText = gradeInstructions[grade];

      let themeToUse;

      if (lessonTheme === 'youtube') {
        themeToUse = transcriptRef.current;
      } else if (lessonTheme === 'customTheme') {
        themeToUse = customTheme;
      } else if (lessonTheme === 'predefined') {
        themeToUse = predefinedTheme;
      } else if (lessonTheme === 'fileUpload') {
        themeToUse = uploadedFileRef.current;
      } else if (lessonTheme === 'wikipedia') {
        themeToUse = wikipediaContentRef.current;
      } else if (lessonTheme === 'randomizer') {
        themeToUse = randomizedContentRef.current;
      } else {
        //
        themeToUse = coursePlanContentRef.current;
      }

      Logger.log('themeToUse: ', themeToUse);

      const response = await callApi(
        [
          {
            role: 'system',
            content: 'Du är en assistent som genererar ord för ordpussel.',
          },
          {
            role: 'user',
            content: `Generera JSON med ${wordCount} ord på ${language} ${
              themeType === 'generateWithAi'
                ? 'relaterade till ett unikt och slumpmässigt valt tema.'
                : `relaterade till temat "${themeToUse}".`
            }
                    Orden ska vara lämpliga för ett ordpussel och vara mellan ${wordLengthRange[0]}-${wordLengthRange[1]} bokstäver långa.
           ${gradeInstructionsText}

         Säkerställ att allt som genereras är enbart en JSON array. Exempel på format:
          {
               "headline": "headline in ${language}",
            "words": [
              { "word": "spöke" },
              { "word": "pumpa" },
              { "word": "häxa" },
              { "word": "skelett" },
              { "word": "zombie" }
            ]
          }`,
          },
        ],
        4000,
        true,
        user.uid
      );

      let messageContent = response.data.choices[0].message.content.trim();
      if (messageContent.startsWith('```json')) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith('```')) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const data = JSON.parse(messageContent);
      const wordsWithImages = data.words.map(wordObj => ({
        ...wordObj,
        image: null,
      }));

      setWords(wordsWithImages);
      const newGrid = generateWordGrid(wordsWithImages, gridSize);
      setWordGrid(newGrid);
      setThemeImage(null);
      setTitleText(data.headline);

      showSnackbar('Nytt ordpussel har genererats', 'success');

      await trackLesson(user.uid, 'FindWordsLesson.js', null, {
        wordCount: wordCount,
        language: language,
        lessonTheme: lessonTheme,
      });

      Logger.log(JSON.stringify(wordsWithImages, null, 2));
    } catch (error) {
      console.error('Error generating lesson:', error);
      showSnackbar('Kunde inte generera nytt ordpussel', 'error');
    } finally {
      setIsGeneratingLesson(false);
    }
  };

  const handleGenerateThemeImage = async () => {
    setIsGeneratingThemeImage(true);
    try {
      const themePrompt = themeType === 'customTheme' ? customTheme : themeType;

      const themeImagePrompt = `Create a simple, clear image representing the theme "${titleText}". 
      Style: clean, colorful, suitable for  education. No text in the image.
      . ${imageInstructions}`;
      const themeImageUrl = await generateAndProcessImage(themeImagePrompt, user.uid, {
        size: '1024x1024',
        model: 'dall-e-3',
        quality: 'standard',
      });
      setThemeImage(themeImageUrl);
      showSnackbar('Temabild har genererats', 'success');
    } catch (error) {
      console.error('Error generating theme image:', error);
      showSnackbar('Kunde inte generera temabild', 'error');
    } finally {
      setIsGeneratingThemeImage(false);
    }
  };

  const handleGenerateImages = async () => {
    setGeneratedImages(new Set());
    const updatedWords = [...words];
    setIsGeneratingImages(true); // Add this line to set loading state at start

    try {
      for (const [index, word] of words.entries()) {
        try {
          setGeneratingImageIndices(prev => new Set([...prev, index]));
          const prompt = `Create a simple, clear image representing the word "${word.word}". No text in the image.`;

          const imageUrl = await generateAndProcessImage(prompt, user.uid, {
            size: '1024x1024',
            model: 'dall-e-3',
            quality: 'standard',
          });

          updatedWords[index] = {
            ...updatedWords[index],
            image: imageUrl,
          };

          setGeneratedImages(prev => new Set([...prev, index]));
          setWords(updatedWords);
          showSnackbar(`Genererat bild ${index + 1} av ${words.length}`, 'info');
        } catch (error) {
          console.error(`Error generating image for word "${word.word}":`, error);
          showSnackbar(`Kunde inte generera bild för "${word.word}"`, 'error');
        } finally {
          setGeneratingImageIndices(prev => {
            const newSet = new Set(prev);
            newSet.delete(index);
            return newSet;
          });
        }
      }
      showSnackbar('Alla bilder har genererats!', 'success');
    } catch (error) {
      console.error('Error in image generation process:', error);
      showSnackbar('Ett fel uppstod när bilderna skulle genereras', 'error');
    } finally {
      setIsGeneratingImages(false); // Add this line to clear loading state when done
    }
  };

  const handleGenerateSingleImage = async (wordIndex, instructions = '') => {
    const word = words[wordIndex];
    const updatedWords = [...words];

    try {
      setGeneratingImageIndices(prev => new Set([...prev, wordIndex]));
      const prompt = `Create a simple, clear image representing the word "${word.word}". No text in the image. ${instructions}`;

      const imageUrl = await generateAndProcessImage(prompt, user.uid, {
        size: '1024x1024',
        model: 'dall-e-3',
        quality: 'standard',
      });

      updatedWords[wordIndex] = {
        ...updatedWords[wordIndex],
        image: imageUrl,
      };

      setWords(updatedWords);
      setGeneratedImages(prev => new Set([...prev, wordIndex]));
      showSnackbar(`Bild genererad för "${word.word}"`, 'success');
    } catch (error) {
      console.error(`Error generating image for word "${word.word}":`, error);
      showSnackbar(`Kunde inte generera bild för "${word.word}"`, 'error');
    } finally {
      setGeneratingImageIndices(prev => {
        const newSet = new Set(prev);
        newSet.delete(wordIndex);
        return newSet;
      });
    }
  };

  return (
    <Box sx={styles.container}>
      <Box
        sx={{
          ...styles.innerContainer,
          width: { xs: '100%', md: '87%' },
          margin: '0 auto',
        }}
      >
        <Typography
          variant='h1'
          gutterBottom
          sx={{ fontWeight: 'bold', fontSize: '1.8em', mb: 3, mt: 5, textAlign: 'center' }}
        >
          Generera ordpussel
        </Typography>

        <Box sx={{ ...styles.buttonsContainer, ...styles.additionalButtonsContainer }}>
          <Tooltip title='Dina sparade lektioner'>
            <Button
              variant='contained'
              color='primary'
              disableRipple
              onClick={() => setDrawerOpen(true)}
              startIcon={<HistoryIcon />}
              sx={{
                padding: '8px',
                marginRight: '0px',
                paddingLeft: '14px',
                paddingRight: '3px',
                minWidth: 'auto',
                textTransform: 'none',
              }}
            />
          </Tooltip>
        </Box>

        <InfoBox
          icon={GridOnIcon}
          title='Powerpoint Instructions'
          instructions={[
            'Generera ett pussel där eleverna ska hitta särskilda ord',
            'Lägg till AI-genererade bilder',
            'Skriv ut lektion',
          ]}
          theme={theme}
          styles={styles}
        />

        <LessonDrawer
          userId={user.uid}
          lessonType='FindWordsLesson.js'
          theme={theme}
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          lessonIcon={GridOnIcon}
          onSelectLesson={onSelectLesson}
        />
        <ThemeSelectorFlow
          value={lessonTheme}
          onChange={e => setLessonTheme(e.target.value)}
          customTheme={customTheme}
          onCustomThemeChange={e => setCustomTheme(e.target.value)}
          onPredefinedThemeChange={e => setPredefinedTheme(e.target.value)}
          onYoutubeTranscriptChange={transcriptContent => {
            Logger.log('onYoutubeTranscriptChange körs med: ', transcriptContent);
            transcriptRef.current = transcriptContent;
          }}
          onFileUpload={content => {
            Logger.log('onFileUpload körs med: ', content);
            uploadedFileRef.current = content;
          }}
          onWikipediaContentChange={content => {
            Logger.log('Parent: Wikipedia content received', content.substring(0, 100));
            wikipediaContentRef.current = content;
          }}
          onRandomizedContentChange={content => {
            Logger.log('randomizedContentRef', content.substring(0, 100));
            randomizedContentRef.current = content;
          }}
          onCoursePlanContentChange={content => {
            Logger.log('onCoursePlanChange', content.substring(0, 100));
            coursePlanContentRef.current = content;
          }}
          lessonType='FindWordsLesson.js'
          user={user}
          theme={theme}
          styles={styles}
          onLessonSelect={lesson => {
            Logger.log('onLessonSelect körs');
            onSelectLesson(lesson);
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'start', // This ensures it scrolls to the top of the element
              });
            }, 100);
            showSnackbar('Lektionen har laddats in.', 'success');
          }}
          additionalThemes={additionalThemes}
        />
        <Box sx={styles.headlineLessonBox}>
          <Typography variant='h6' sx={styles.headlineLessonBoxTitle}>
            2. Generera lektionen
          </Typography>
        </Box>

        <Box sx={{ ...styles.freshBox, padding: '30px' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500', mb: 0 }}>
                Årskurs:
              </Typography>
              <Box sx={{ mt: 1, mb: 0 }}>
                <GradeSelector grade={grade} setGrade={setGrade} />
              </Box>
            </Grid>

            <Grid item xs={12} md={12} sx={{ mt: 0 }}>
              <Typography variant='subtitle1' sx={{ fontWeight: '500', mt: 0 }}>
                Språk:
              </Typography>
              <Box sx={{ mt: 1, mb: 3 }}>
                <LanguageSelector language={language} setLanguage={setLanguage} />
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500', mb: 0 }}>
                Antal ord:
              </Typography>
              <FormControl fullWidth margin='normal' sx={{ mb: 0, mt: 1 }}>
                <Select value={wordCount} onChange={e => setWordCount(e.target.value)}>
                  <MenuItem value={2}>2 ord</MenuItem>
                  <MenuItem value={3}>3 ord</MenuItem>
                  <MenuItem value={4}>4 ord</MenuItem>
                  <MenuItem value={5}>5 ord</MenuItem>
                  <MenuItem value={6}>6 ord</MenuItem>
                  <MenuItem value={8}>8 ord</MenuItem>
                  <MenuItem value={10}>10 ord</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500', mb: 0 }}>
                Ordlängd (antal bokstäver):
              </Typography>
              <Box sx={{ px: 2, mt: 3, mb: 1 }}>
                <Slider
                  value={wordLengthRange}
                  onChange={(event, newValue) => setWordLengthRange(newValue)}
                  valueLabelDisplay='auto'
                  min={3}
                  max={12}
                  marks={[
                    { value: 3, label: '3' },
                    { value: 6, label: '6' },
                    { value: 9, label: '9' },
                    { value: 12, label: '12' },
                  ]}
                />
              </Box>
              <Typography
                variant='body2'
                color='text.secondary'
                sx={{ mt: 1, textAlign: 'center' }}
              >
                Ord mellan {wordLengthRange[0]} och {wordLengthRange[1]} bokstäver
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ mt: 4 }}>
                <Button
                  variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                  color='primary'
                  onClick={handleGenerateLesson}
                  disabled={isGeneratingLesson}
                  startIcon={
                    !isGeneratingLesson ? <GenerateIcon /> : <CircularProgress size={20} />
                  }
                  sx={{ mr: 2 }}
                >
                  {isGeneratingLesson ? 'Genererar...' : 'Generera ordpussel'}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <ImageGenerationModal
          open={showImageModal}
          onClose={handleCloseModal}
          onGenerate={handleGenerateWithInstructions}
          isGenerating={
            imageGenerationType === 'topic' ? isGeneratingThemeImage : isGeneratingImages
          }
          instructions={imageInstructions}
          setInstructions={setImageInstructions}
          generationType={imageGenerationType}
          theme={theme}
        />
        {words.length > 0 && (
          <>
            <Box ref={lessonTitleRef} sx={{ ...styles.headlineLessonBox, mt: 10 }}>
              <Typography variant='h6' sx={styles.headlineLessonBoxTitle}>
                3. Din lektion
              </Typography>
            </Box>
            <Box sx={{ ...styles.freshBox, padding: '30px' }}>
              <Box sx={styles.lessonControlPanel}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    px: 1,
                  }}
                >
                  <Box>
                    <Button
                      variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                      color='primary'
                      onClick={() => handleImageButtonClick('topic')}
                      disabled={isGeneratingThemeImage}
                      startIcon={
                        !isGeneratingThemeImage ? <ImageIcon /> : <CircularProgress size={20} />
                      }
                      sx={{ mr: 2 }}
                    >
                      {isGeneratingThemeImage ? 'Genererar temabild...' : 'Generera temabild'}
                    </Button>

                    <Button
                      variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                      color='primary'
                      onClick={() => handleImageButtonClick('facts')}
                      disabled={isGeneratingImages}
                      startIcon={
                        !isGeneratingImages ? <ImageIcon /> : <CircularProgress size={20} />
                      }
                      sx={{ mr: 2 }}
                    >
                      {isGeneratingImages ? 'Genererar bilder...' : 'Generera bilder'}
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      variant='print'
                      color='primary'
                      onClick={() => {
                        handlePrintFindWordsLesson(
                          titleText,
                          themeImage,
                          wordGrid,
                          words,
                          themeColor,
                          wordPositionsSet
                        );
                      }}
                      startIcon={<PrintIcon />}
                    >
                      Skriv ut
                    </Button>

                    <Button
                      variant='print'
                      startIcon={<SaveAsIcon />}
                      sx={{ ml: 2 }}
                      onClick={handleSaveLessonClick}
                    >
                      Spara i lektionsbank
                    </Button>
                  </Box>
                </Box>
              </Box>
              <SaveLessonDialog
                open={saveDialogOpen}
                onClose={() => setSaveDialogOpen(false)}
                onSave={handleSaveWithComment}
                theme={theme}
                lessonObject={themeImage}
                lessonName={titleText}
              />

              <Box sx={{ mt: 4 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 4, mb: 9 }}>
                  <Box sx={{ position: 'relative' }}>
                    <HoverableImage
                      imageUrl={themeImage}
                      isLoading={isGeneratingThemeImage}
                      onGenerateClick={() => {
                        setCurrentUpdateType('topic');
                        handleImageButtonClick('topic', null);
                      }}
                      userId={user.uid}
                      onImageUpdate={url => handleImageUpdate(url, null)}
                      height='250px'
                      theme={theme}
                      showSnackbar={showSnackbar}
                    />
                  </Box>

                  {/* Editable Title */}
                  {isEditingTitle ? (
                    <TextField
                      value={titleText}
                      onChange={e => setTitleText(e.target.value)}
                      onBlur={() => {
                        setIsEditingTitle(false);
                        showSnackbar('Titeln på lektionen har uppdaterats', 'success'); // Display snackbar
                      }}
                      onKeyDown={e => {
                        if (e.key === 'Enter') {
                          setIsEditingTitle(false);
                          showSnackbar('Titeln på lektionen har uppdaterats', 'success'); // Display snackbar
                        }
                      }}
                      autoFocus
                      fullWidth
                      multiline={3}
                      variant='standard'
                      InputProps={{
                        style: { fontWeight: 700, fontSize: '3em' },
                        disableUnderline: true,
                      }}
                    />
                  ) : (
                    <Tooltip title='Klicka för att redigera titel' arrow>
                      <Typography
                        variant='h2'
                        sx={{ fontWeight: 700, fontSize: '3em', cursor: 'pointer' }}
                        onClick={() => setIsEditingTitle(true)}
                      >
                        {titleText}
                      </Typography>
                    </Tooltip>
                  )}
                </Box>

                {/* Word Grid */}
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: `repeat(${gridSize}, 1fr)`,
                    gap: 1,
                    mb: 4,

                    width: 'fit-content',
                    margin: '0 auto',
                  }}
                >
                  {wordGrid.map((row, rowIndex) =>
                    row.map((letter, colIndex) => (
                      <Box
                        key={`${rowIndex}-${colIndex}`}
                        sx={{
                          width: 67,
                          height: 67,
                          border: '2px solid',
                          borderColor: 'divider',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRadius: 2,
                          fontSize: '1.86em',
                          fontWeight: 500,
                          padding: '8px',
                          backgroundColor: wordPositionsSet.has(`${rowIndex}-${colIndex}`)
                            ? theme.palette.mode === 'dark'
                              ? '#2c3b48'
                              : '#d9d9d9'
                            : 'transparent',
                        }}
                      >
                        {letter}
                      </Box>
                    ))
                  )}
                </Box>

                {/* Word List */}
                <Grid container spacing={2} sx={{ mt: 7 }}>
                  {words.map((word, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 2,
                          p: 2,
                          border: '1px solid',
                          borderColor: 'divider',
                          borderRadius: 2,
                          position: 'relative',
                        }}
                      >
                        <Box sx={{ position: 'relative', width: 80, height: 80 }}>
                          <HoverableImage
                            imageUrl={word.image}
                            isLoading={generatingImageIndices.has(index)}
                            loadingIndex={index}
                            currentIndex={index}
                            onGenerateClick={() => {
                              setCurrentUpdateType('facts');
                              handleImageButtonClick('facts', index);
                            }}
                            userId={user.uid}
                            onImageUpdate={url => handleImageUpdate(url, index)}
                            theme={theme}
                            showSnackbar={showSnackbar}
                            tinyMode={true}
                            size={80}
                          />
                        </Box>

                        <Typography sx={{ fontSize: '1.2em', textTransform: 'capitalize' }}>
                          {word.word}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default FindWordsLesson;
