import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, query, where, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import {
  Drawer,
  Box,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  CircularProgress,
} from '@mui/material';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import DeleteIcon from '@mui/icons-material/Delete';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import CheckIcon from '@mui/icons-material/Check';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import { getStyles } from '../../index';

const HPDrawerComponent = ({ user, theme, open, onClose }) => {
  const [userProgress, setUserProgress] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const styles = getStyles(theme);

  useEffect(() => {
    if (open && user) {
      fetchUserProgress();
    }
  }, [open, user]);

  const fetchUserProgress = async () => {
    setLoading(true);
    try {
      const q = query(collection(db, 'hpTestProgress'), where('userId', '==', user.uid));
      const querySnapshot = await getDocs(q);
      const progress = querySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          ...data,
          testName: data.testName || 'Okänt test',
          testId: data.testId,
          answeredQuestionsCount: data.results ? data.results.length : 0,
          totalQuestions: data.totalQuestions || 0,
        };
      });
      setUserProgress(progress);
    } catch (error) {
      console.error('Error fetching user progress:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteProgress = async testId => {
    try {
      await deleteDoc(doc(db, 'hpTestProgress', `${user.uid}_${testId}`));
      setUserProgress(prevProgress => prevProgress.filter(p => p.testId !== testId));
    } catch (error) {
      console.error('Error deleting progress:', error);
    }
  };

  const handleSelectTest = testId => {
    onClose();
    navigate(`/app/hp/${testId}`);
  };

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={onClose}
      sx={{ width: { xs: '75%', sm: '50%', md: '30%' }, paddingTop: '0px' }}
      PaperProps={{
        sx: {
          width: { xs: '75%', sm: '50%', md: '30%' },
          padding: '20px',
          paddingTop: '0px',

          backgroundImage: 'none',
          borderLeft: theme.palette.mode === 'dark' ? '1px solid #34414c' : '1px solid #e0e0e0',
        },
      }}
    >
      <Box sx={styles.flexContainerSidebar}>
        <Typography variant='h6' sx={styles.header}>
          Pågående prov
        </Typography>
        <IconButton onClick={onClose} color='inherit'>
          <MenuOpenIcon />
        </IconButton>
      </Box>

      {loading ? (
        <Box
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}
        >
          <CircularProgress size={16} />
        </Box>
      ) : userProgress.length === 0 ? (
        <Box sx={{ display: 'flex', textAlign: 'left' }}>
          <Typography sx={{ fontStyle: 'italic' }}>
            Här hamnar dina pågående högskoleprov så du enkelt kan skifta mellan dem.
          </Typography>
        </Box>
      ) : (
        <List>
          {userProgress.map((progress, index) => (
            <ListItem
              key={index}
              button
              onClick={() => handleSelectTest(progress.testId)}
              className='lightBg'
            >
              <ListItemText
                primary={progress.testName}
                secondary={
                  <React.Fragment>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: 0.5,
                        marginTop: '7px',
                      }}
                    >
                      {progress.testInProgress ? (
                        <PlayArrowIcon fontSize='small' color='primary' sx={{ marginRight: 1 }} />
                      ) : (
                        <CheckCircleIcon fontSize='small' color='success' sx={{ marginRight: 1 }} />
                      )}
                      <Typography component='span' variant='body2' color='text.primary'>
                        {progress.testInProgress ? 'Pågående' : 'Slutfört'}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 0.5 }}>
                      <QuestionAnswerIcon fontSize='small' sx={{ marginRight: 1 }} />
                      {`Besvarade frågor: ${progress.answeredQuestionsCount} / ${progress.totalQuestions}`}
                    </Box>
                    {!progress.testInProgress && (
                      <>
                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 0.5 }}>
                          <CheckIcon fontSize='small' sx={{ marginRight: 1 }} />
                          {`Rätt svar: ${progress.results.filter(r => r.isCorrect).length}/${progress.results.length}`}
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <AccessTimeIcon fontSize='small' sx={{ marginRight: 1 }} />
                          {`Tid använd: ${Math.floor((55 * 60 - progress.timeLeft) / 60)}:${(55 * 60 - progress.timeLeft) % 60 < 10 ? '0' : ''}${(55 * 60 - progress.timeLeft) % 60}`}
                        </Box>
                      </>
                    )}
                  </React.Fragment>
                }
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge='end'
                  aria-label='delete'
                  onClick={() => handleDeleteProgress(progress.testId)}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      )}
    </Drawer>
  );
};

export default HPDrawerComponent;
