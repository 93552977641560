import React from 'react';
import { Box, Drawer, Typography, Button, IconButton } from '@mui/material';
import ChevronRight from '@mui/icons-material/ChevronRight';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useSnackbar } from './SnackbarHandler';

const CharacterDescriptionDrawer = ({ teacher, open, onClose, theme }) => {
    const handleCopy = () => {
        navigator.clipboard.writeText(teacher.description);
        showSnackbar('Beskrivning kopierad!', 'success');
    };
    const { showSnackbar } = useSnackbar();

    return (
        <Drawer
            anchor='right'
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    width: 340,
                    boxShadow: 'none',
                    borderLeft:
                        theme.palette.mode === 'dark' ? '1px solid #34414c' : '1px solid #e0e0e0',
                    backgroundImage: 'none',
                },
            }}
        >
            <Box sx={{ p: 3 }}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <IconButton
                        onClick={onClose}
                        color='inherit'
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <ChevronRight />
                    </IconButton>
                    <Typography
                        variant='h6'
                        sx={{
                            fontSize: '17px',
                            ml: 2,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        Karaktärsdetaljer
                    </Typography>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                        mt: 2,
                    }}
                >
                    <Typography variant='subtitle1' sx={{ fontWeight: '500' }}>
                        Beskrivning
                    </Typography>
                    <Button
                        variant='text'
                        startIcon={<ContentCopyIcon />}
                        onClick={handleCopy}
                        sx={{
                            backgroundColor: 'transparent',
                            border: 'none',
                            textTransform: 'none',
                            color: theme.palette.mode === 'dark' ? 'white' : '#333',
                            '& .MuiSvgIcon-root': {
                                fontSize: '16px',
                                color: theme.palette.mode === 'dark' ? 'white' : '#333',
                            },
                        }}
                    >
                        Kopiera
                    </Button>
                </Box>

                <Typography variant='body1' sx={{ mt: 2, mb: 2, fontSize: '13px' }}>
                    {teacher.description}
                </Typography>
            </Box>
        </Drawer>
    );
};

export default CharacterDescriptionDrawer;
