import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    getDocs,
    query,
    where,
    writeBatch,
    updateDoc,
} from 'firebase/firestore';
import { db } from './firebase';
import { callApi } from './api';
import Logger from './Logger';
import { sanitizeInput } from './functions';
export const scrollToBottom = ref => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
};

export const generateIdeas = async (content, conversationType, shouldSubmit = false) => {
    Logger.log('shouldSubmit: ', shouldSubmit);
    let msg = 'Ge mig exakt 3 frågor som vore intressanta att följdfråga om. ';

    if (conversationType === 'spanish') {
        msg += 'Var fråga ska vara på SPANSKA. ';
    }

    msg +=
        'Varje ämne ska vara i en särskild kommabaserad sträng utifrån följande JSON-format: { "fråga1", "fråga2", "fråga3"}. Använd ALLTID samma JSON-format. Använd INTE markdown formatting.';

    Logger.log('generateIdeas msg: ', msg);

    const response = await callApi(
        [
            { role: 'system', content: msg },
            { role: 'user', content: content },
        ],
        600
    );

    const ideasString = response.slice(2, -2);
    const ideasArray = ideasString.split('", "');
    const ideas = ['idea', 'idea', 'idea'];

    ideasArray.forEach((idea, index) => {
        if (index < ideas.length) {
            ideas[index] = idea.replace(/"/g, '');
        }
    });

    return ideas;
};

export const handleNewChat = async (
    user,
    conversationType,
    setCurrentChatId,
    setMessages,
    setChats
) => {
    Logger.log('handleNewChat user: ', user);
    const newChatId = await createNewChat(user, conversationType);
    if (newChatId) {
        Logger.log('New chat created with id:', newChatId);
        setCurrentChatId(newChatId);
        setMessages([]);
        setChats(prevChats => [...prevChats, { id: newChatId, name: 'Ny chat' }]);
    }
    return newChatId;
};

export const handleChatNameEdit = async (
    e,
    editingChat,
    updateChatName,
    setChats,
    setEditingChat
) => {
    if (e.key === 'Enter') {
        e.preventDefault();
        await finalizeChatNameEdit(editingChat, updateChatName, setChats, setEditingChat);
    }
};

export const finalizeChatNameEdit = async (
    editingChat,
    updateChatName,
    setChats,
    setEditingChat
) => {
    if (editingChat.id && editingChat.name.trim() !== '') {
        const sanitizedChatName = sanitizeInput(editingChat.name);
        await updateChatName(editingChat.id, sanitizedChatName);
        setChats(chats =>
            chats.map(chat =>
                chat.id === editingChat.id ? { ...chat, name: sanitizedChatName } : chat
            )
        );
        setEditingChat({ id: null, name: '' });
    }
};

export const saveChatName = async (editingChat, updateChatName, setChats, setEditingChat) => {
    await finalizeChatNameEdit(editingChat, updateChatName, setChats, setEditingChat);
};

export const startEditing = (chatId, currentName, setEditingChat) => {
    setEditingChat({ id: chatId, name: currentName });
};

export const sendMessage = async (
    input,
    user,
    messages,
    setMessages,
    setLoading,
    setInput,
    conversationType,
    chatId,
    numRecentMessages = 5,
    sender = 'user',
    img = ''
) => {
    const imgRegex = /<img[^>]+src="([^">]+)"/g;

    // Extract all image URLs from the input
    let match;
    const imageUrls = [];
    while ((match = imgRegex.exec(input)) !== null) {
        imageUrls.push(match[1]); // match[1] contains the URL
    }

    Logger.log('sendMessage called with:', {
        input,
        user: user?.uid,
        conversationType,
        chatId,
        img,
    });
    if (input.trim() === '' || !chatId) {
        console.error('Invalid input or chatId');
        return;
    }

    setLoading(true);
    const newMessage = { role: 'user', content: input };

    try {
        const messageId = await addDoc(collection(db, 'conversations'), {
            userId: user.uid,
            message: newMessage.content,
            role: newMessage.role,
            conversationType: conversationType,
            chatId: chatId,
            createdAt: new Date(),
        }).then(docRef => docRef.id);

        newMessage.id = messageId;
        setMessages([...messages, newMessage]);

        let systemMessageContent = 'Du är en chatt-bot...';
        if (conversationType === 'chat') {
            systemMessageContent =
                'Du är en generell chatt-bot som hjälper folk med deras läxor...';
        } else if (conversationType === 'history') {
            systemMessageContent = 'Du är en historielärare...';
        } else if (conversationType === 'spanish') {
            systemMessageContent =
                'Eres un profesor de español que puede hablar español. Sé alentador y trata de mantener la conversación en todo momento. Todas tus respuestas deben ser en español, incluso si el usuario escribe en sueco.';
        }

        const recentMessages = messages
            .slice(Math.max(messages.length - numRecentMessages, 0))
            .map(msg => ({
                role: msg.role === 'bot' ? 'assistant' : msg.role,
                content: msg.content,
            }));

        // const context = [
        //     { role: 'system', content: systemMessageContent },
        //     ...recentMessages,
        //     { role: 'user', content: input },

        // ];
        let context = '';

        if (img.length > 10) {
            context = [
                { role: 'system', content: systemMessageContent },
                ...recentMessages,
                {
                    role: 'user',
                    content: [
                        { type: 'text', text: input }, // Assuming input can contain text
                        ...imageUrls.map(url => ({
                            type: 'image_url',
                            image_url: { url },
                        })),
                    ],
                },
            ];
        } else {
            Logger.log('ingen bild');
            context = [
                { role: 'system', content: systemMessageContent },
                ...recentMessages,
                { role: 'user', content: input },
            ];
        }

        //Logger.log('Context sent to callApi:', context);
        const response = await callApi(context, 600);

        if (response) {
            const botMessage = { role: 'assistant', content: response };
            const botMessageId = await addDoc(collection(db, 'conversations'), {
                userId: user.uid,
                message: botMessage.content,
                role: botMessage.role,
                conversationType: conversationType,
                chatId: chatId,
                createdAt: new Date(),
            }).then(docRef => docRef.id);

            botMessage.id = botMessageId;
            setMessages(prevMessages => [...prevMessages, botMessage]);
        }

        setLoading(false);
        setInput('');

        if (messages.length === 0) {
            // This is the first message, update the chat name
            const chatName = input.slice(0, 20) + (input.length > 20 ? '...' : '');
            await updateDoc(doc(db, 'chats', chatId), {
                name: chatName,
                firstMessageContent: input,
            });
        }
    } catch (error) {
        console.error('Error sending message:', error);
        setLoading(false);
    }
};

export const fetchConversations = async (user, setMessages, conversationType, chatId) => {
    if (!user || !user.uid) {
        console.error('User ID is missing in fetchConversations');
        return;
    }

    try {
        const chatQuery = query(
            collection(db, 'conversations'),
            where('userId', '==', user.uid),
            where('conversationType', '==', conversationType),
            where('chatId', '==', chatId)
        );
        const chatSnapshot = await getDocs(chatQuery);
        const fetchedMessages = chatSnapshot.docs.map(doc => ({
            id: doc.id,
            role: doc.data().role,
            content: doc.data().message,
            createdAt: doc.data().createdAt.toDate(),
            conversationType: doc.data().conversationType,
            chatId: doc.data().chatId,
            isSaved: false,
            noteId: null,
        }));

        const messageIds = fetchedMessages.map(msg => msg.id);

        const fetchNotesForMessageIds = async ids => {
            const notesQuery = query(collection(db, 'notes'), where('messageId', 'in', ids));
            const notesSnapshot = await getDocs(notesQuery);
            return notesSnapshot.docs.map(note => ({
                messageId: note.data().messageId,
                noteId: note.id,
            }));
        };

        let allNotes = [];
        const batchPromises = [];
        for (let i = 0; i < messageIds.length; i += 30) {
            const batch = messageIds.slice(i, i + 30);
            batchPromises.push(fetchNotesForMessageIds(batch));
        }

        const notesResults = await Promise.all(batchPromises);
        allNotes = notesResults.flat();

        fetchedMessages.forEach(message => {
            const note = allNotes.find(note => note.messageId === message.id);
            if (note) {
                message.isSaved = true;
                message.noteId = note.noteId;
            }
        });

        fetchedMessages.sort((a, b) => a.createdAt - b.createdAt);
        setMessages(fetchedMessages);
    } catch (error) {
        console.error('Error fetching conversations:', error);
    }
};

export const deleteAllMessages = async (user, setMessages, conversationType, chatId) => {
    Logger.log('deleteAllMessages called with user:', user);
    if (!user || !user.uid) {
        console.error('User ID is missing in deleteAllMessages');
        return;
    }

    try {
        const q = query(
            collection(db, 'conversations'),
            where('userId', '==', user.uid),
            where('conversationType', '==', conversationType),
            where('chatId', '==', chatId)
        );
        const querySnapshot = await getDocs(q);

        const batch = writeBatch(db);
        querySnapshot.forEach(docSnapshot => {
            batch.delete(doc(db, 'conversations', docSnapshot.id));
        });
        await batch.commit();

        setMessages([]);
    } catch (error) {
        console.error('Error deleting messages:', error);
    }
};

export const createNewChat = async (user, conversationType) => {
    if (!user || !user.uid) {
        console.error('User ID is missing in createNewChat');
        return null;
    }

    try {
        const chatRef = await addDoc(collection(db, 'chats'), {
            userId: user.uid,
            conversationType: conversationType,
            createdAt: new Date(),
            name: 'Ny chatt',
            firstMessageContent: '',
        });
        return chatRef.id;
    } catch (error) {
        console.error('Error creating new chat:', error);
        return null;
    }
};

export const fetchChats = async (user, conversationType) => {
    if (!user || !user.uid) {
        console.error('User ID is missing in fetchChats');
        return [];
    }

    try {
        const chatsQuery = query(
            collection(db, 'chats'),
            where('userId', '==', user.uid),
            where('conversationType', '==', conversationType)
        );
        const chatsSnapshot = await getDocs(chatsQuery);
        return chatsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }));
    } catch (error) {
        console.error('Error fetching chats:', error);
        return [];
    }
};

export const updateChatName = async (chatId, newName) => {
    try {
        const chatRef = doc(db, 'chats', chatId);
        await updateDoc(chatRef, {
            name: newName,
        });
    } catch (error) {
        console.error('Error updating chat name:', error);
    }
};
