import React, { useState, useEffect, useRef } from 'react';
import { IconButton, Tooltip, CircularProgress, Button } from '@mui/material';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import { callOpenAITTS } from './api';
import Logger from './Logger';
import ReactCountryFlag from 'react-country-flag';

const languageToFlag = {
  amhariska: 'et',
  arabiska: 'sa',
  bengali: 'bd',
  'brasiliansk-portugisiska': 'br',
  bulgariska: 'bg',
  dari: 'af',
  danska: 'dk',
  engelska: 'gb',
  estniska: 'ee',
  farsi: 'ir',
  finska: 'fi',
  franska: 'fr',
  grekiska: 'gr',
  hebreiska: 'il',
  hindi: 'in',
  indonesiska: 'id',
  isländska: 'is',
  italienska: 'it',
  japanska: 'jp',
  kannada: 'in',
  kinesiska: 'cn',
  koreanska: 'kr',
  kroatiska: 'hr',

  kurdiska: 'iq',

  lettiska: 'lv',

  litauiska: 'lt',
  makedonska: 'mk',
  nederländska: 'nl',
  norska: 'no',
  polska: 'pl',
  pashtu: 'af',
  persiska: 'ir',
  portugisiska: 'pt',
  punjabi: 'in',
  rumänska: 'ro',
  ryska: 'ru',
  serbiska: 'rs',

  slovakiska: 'sk',

  slovenska: 'si',
  somali: 'so',
  sorani: 'iq',
  spanska: 'es',
  svenska: 'se',
  swahili: 'tz',
  tagalog: 'ph',
  tamil: 'in',
  telugu: 'in',
  thai: 'th',
  tjeckiska: 'cz',
  turkiska: 'tr',
  tyska: 'de',
  ukrainska: 'ua',
  ungerska: 'hu',
  urdu: 'pk',
  vietnamesiska: 'vn',
};

const Voice = ({
  content,
  voice = 'alloy',
  fontSize,
  userId = null,
  language = null,
  tooltipPlacement = 'bottom',
  showVolumeIcon = false,
  variant = 'icon',
  buttonText = 'Läs högt',
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const audioRef = useRef(new Audio());
  const abortControllerRef = useRef(null);

  const handleVoice = async () => {
    Logger.log('lang:', language);
    Logger.log('userid voice: ', userId);
    Logger.log('content: ', content);
    if (isPlaying) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setIsPlaying(false);
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
        abortControllerRef.current = null;
      }
    } else {
      if (!audioBlob) {
        setIsLoading(true);
        try {
          abortControllerRef.current = new AbortController();
          const blob = await callOpenAITTS(
            content,
            voice,
            abortControllerRef.current.signal,
            userId
          );
          setAudioBlob(blob);
          audioRef.current.src = URL.createObjectURL(blob);
        } catch (error) {
          if (error.name === 'AbortError') {
            Logger.log('TTS request was aborted');
          } else {
            console.error('Error generating speech:', error);
          }
          setIsLoading(false);
          return;
        }
        setIsLoading(false);
      }
      audioRef.current.play();
      setIsPlaying(true);
    }
  };

  useEffect(() => {
    const audio = audioRef.current;
    audio.onended = () => setIsPlaying(false);
    return () => {
      audio.onended = null;
      URL.revokeObjectURL(audio.src);
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  const iconSize = fontSize || 24;
  const progressSize = iconSize / 0.8;
  const buttonStyle = { width: `${iconSize * 1.5}px`, height: `${iconSize * 1.5}px` };

  const renderFlag = countryCode => {
    return (
      <ReactCountryFlag
        countryCode={countryCode.toUpperCase()}
        className='emojiFlag'
        style={{
          width: `${iconSize}px`,
          height: 'auto',
        }}
      />
    );
  };

  if (variant === 'button') {
    return (
      <Button
        variant='contained'
        startIcon={
          isLoading ? (
            <CircularProgress size={20} />
          ) : isPlaying ? (
            <VolumeOffIcon />
          ) : (
            <VolumeUpIcon />
          )
        }
        onClick={handleVoice}
        disabled={isLoading}
      >
        {isPlaying ? 'Stoppa' : buttonText}
      </Button>
    );
  }

  return (
    <Tooltip title={isPlaying ? 'Stoppa' : 'Läs upp'} placement={tooltipPlacement}>
      <IconButton
        onClick={handleVoice}
        size='small'
        style={buttonStyle}
        sx={{
          '&.MuiIconButton-root': {
            color: language ? 'white' : 'inherit',
            '& .emojiFlag': {
              color: language ? 'white' : 'inherit',
            },
            '& .MuiTouchRipple-root': {
              color: language ? 'white' : 'inherit',
            },
          },
        }}
      >
        {isLoading ? (
          <CircularProgress size={progressSize} />
        ) : !showVolumeIcon && language && languageToFlag[language] ? (
          renderFlag(languageToFlag[language])
        ) : isPlaying ? (
          <VolumeOffIcon sx={{ fontSize: iconSize }} />
        ) : (
          <VolumeUpIcon sx={{ fontSize: iconSize }} />
        )}
      </IconButton>
    </Tooltip>
  );
};

export default Voice;
