import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Typography, Button, CircularProgress, Divider } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import PodcastsIcon from '@mui/icons-material/Podcasts';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { getStyles } from '../index';
import AIDialog from './AIDialog';

import Logger from './Logger';
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
import QuizIcon from '@mui/icons-material/Quiz';
import { callApi } from './api';

import { addDoc, collection } from 'firebase/firestore';
import { db } from './firebase';
import { useSnackbar } from './SnackbarHandler';
import { marked } from 'marked';

const CourseChapterContent = ({
  handleChapterCompletion,
  selectedModule,
  user,
  selectedModuleIndex,
}) => {
  const theme = useTheme();
  const styles = getStyles(theme);
  const { courseName, moduleName, chapterName } = useParams();
  const [aiDialogOpen, setAiDialogOpen] = useState(false);
  const [loadingQuiz, setLoadingQuiz] = useState(false);
  const [loadingFlashcards, setLoadingFlashcards] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();

  const handleAskAI = () => {
    Logger.log('selectedModule: ', selectedModule);
    setAiDialogOpen(true);
  };

  const formatUrlPart = str => {
    return str
      .replace(/\s+/g, '-')
      .replace(/[åä]/g, 'a')
      .replace(/[ö]/g, 'o')
      .replace(/[^a-z0-9-]/gi, '')
      .toLowerCase();
  };

  const chapterIndex = selectedModule.chapters.findIndex(
    chapter => formatUrlPart(chapter.chapter_title) === chapterName
  );
  const chapterContent = selectedModule.chapters[chapterIndex]?.chapter_content;
  const isCompleted = selectedModule.chapters[chapterIndex]?.chapter_completed;

  const chapterTitle = selectedModule.chapters[chapterIndex]?.chapter_title;
  const moduleTitle = selectedModule.module_title;

  const generateQuiz = async () => {
    setLoadingQuiz(true);
    setIsLoading(true);
    const instructions = `Generate a quiz with 10 questions based on the following content. The quiz should be in Swedish. Each question should be a JSON object with a "question" field, an "options" field containing 4 options, and a "correct" field. Ensure all questions are returned in a single JSON array. Do not use any markdown formatting. Format example: [{ "question": "Who won the FIFA World Cup in 2018?", "options": ["Brazil", "France", "Germany", "Argentina"], "correct": "France" }]`;

    const messages = [
      { role: 'system', content: instructions },
      { role: 'user', content: chapterContent },
    ];

    try {
      let response = await callApi(messages, 4000, true, user.uid);

      if (typeof response === 'string') {
        if (response.startsWith('```json')) {
          response = response.slice(7, -3).trim();
          Logger.log('removed 3 characters from json');
        } else if (response.startsWith('```')) {
          response = response.slice(3, -3).trim();
        }
      }

      const quizContent = JSON.parse(response.data.choices[0].message.content.trim());

      const quizDoc = await addDoc(collection(db, 'quiz'), {
        userId: user.uid,
        results: quizContent,
        quizName: `${moduleTitle} - ${chapterTitle}`,
        createdAt: Date.now(),
        byAdmin: 'No',
        isPublic: true,
        questionType: 'multiple-choice',
        category: 'Course',
      });

      const formattedQuizName = formatUrlPart(`${moduleTitle} - ${chapterTitle}`);
      navigate(`/app/quiz/${quizDoc.id}/${formattedQuizName}`);
    } catch (error) {
      console.error('Error generating quiz:', error);
      showSnackbar('Det uppstod ett fel vid generering av quizet. Vänligen försök igen.', 'error');
    } finally {
      setLoadingQuiz(false);
      setIsLoading(false);
    }
  };

  const generateAndSaveFlashcards = async () => {
    setLoadingFlashcards(true);
    setIsLoading(true);
    const instructions = `Generate 10 flashcards based on the following content. The flashcards should be in Swedish. Each flashcard should be a JSON object with a "front" field for the question or prompt, and a "back" field for the answer. Ensure all flashcards are returned in a single JSON array. Format example: [{ "front": "What is the capital of France?", "back": "Paris" }]`;

    const messages = [
      { role: 'system', content: instructions },
      { role: 'user', content: chapterContent },
    ];

    try {
      let response = await callApi(messages, 4000, true, user.uid);

      if (typeof response === 'string') {
        if (response.startsWith('```json')) {
          response = response.slice(7, -3).trim();
          Logger.log('removed 3 characters from json');
        } else if (response.startsWith('```')) {
          response = response.slice(3, -3).trim();
        }
      }

      const flashcardsContent = JSON.parse(response.data.choices[0].message.content.trim());

      const sanitizedFlashcards = flashcardsContent.map(card => ({
        front: card.front,
        back: card.back,
        correct: 'false',
      }));

      const collectionRef = await addDoc(collection(db, 'flashcardCollections'), {
        userId: user.uid,
        collectionName: `${moduleTitle} - ${chapterTitle}`,
        flashcards: sanitizedFlashcards,
        isPublic: true,
        createdAt: Date.now(),
        numFlashcards: sanitizedFlashcards.length,
        category: 'Course',
        lastTrainingSession: Date.now(),
        upvotes: 0,
        downvotes: 0,
        userVotes: {},
      });

      showSnackbar('Flashcards har genererats och sparats!', 'success');
      const formattedFlashcardsName = formatUrlPart(`${moduleTitle} - ${chapterTitle}`);
      navigate(`/app/flashcards/${collectionRef.id}/${formattedFlashcardsName}`);
    } catch (error) {
      console.error('Error generating and saving flashcards:', error);
      showSnackbar('Fel vid generering av flashcards. Vänligen försök igen.', 'error');
    } finally {
      setLoadingFlashcards(false);
      setIsLoading(false);
    }
  };

  const parseMarkdown = content => {
    // Configure marked to use * for emphasis (italic) and ** for strong (bold)
    marked.setOptions({
      gfm: true,
      breaks: true,
    });

    return { __html: marked(content) };
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.innerContainer}>
        <Typography variant='h2' sx={{ ...styles.grayText, fontSize: '1em', fontWeight: '500' }}>
          {selectedModuleIndex + 1}. {moduleTitle}
        </Typography>
        <Typography
          variant='h1'
          sx={{
            ...styles.header,
            mb: 2,
            marginTop: '5px',
            marginLeft: { xs: '0px', md: 'inherit' },
          }}
        >
          {selectedModuleIndex + 1}.{chapterIndex + 1}. {chapterTitle}
        </Typography>

        <Box
          sx={{
            mb: 4,
            backgroundImage: 'none',
            backgroundColor: theme.palette.mode === 'dark' ? 'rgb(30, 41, 53)' : 'white',
            border: isCompleted
              ? theme.palette.mode === 'dark'
                ? '1px solid #366235' // Dark green for completed in dark mode
                : '1px solid #CBF2C9' // Light green for completed in light mode
              : theme.palette.mode === 'dark'
                ? '1px solid #35414d' // Dark border for incomplete in dark mode
                : '1px solid #eee', // Light border for incomplete in light mode
            borderRadius: '7px',
            padding: '30px',
          }}
        >
          <Box
            sx={{
              ...styles.softerText,
              '& h3': {
                marginTop: '45px',
              },
              '& h3:first-of-type': {
                marginTop: 0,
              },
              '& p': {
                lineHeight: '27px',
              },
            }}
          >
            {chapterContent ? (
              <div dangerouslySetInnerHTML={parseMarkdown(chapterContent)} />
            ) : (
              <Typography>No content available for this chapter.</Typography>
            )}
          </Box>
          <Divider sx={{ my: 5 }} />

          <Typography
            variant='h6'
            sx={{ ...styles.grayText, fontSize: '1.0em', fontWeight: '600', mb: 1 }}
          >
            Öva på innehållet:
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              <Button
                color='primary'
                variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                sx={{ mr: 2, mb: 2 }}
                onClick={generateQuiz}
                disabled={isLoading}
                startIcon={<QuizIcon />}
                startIcon={
                  !loadingQuiz ? (
                    <QuizIcon style={{ color: '#fff' }} />
                  ) : (
                    <CircularProgress size={18} sx={{ color: '#fff' }} />
                  )
                }
              >
                {loadingQuiz ? 'Genererar quiz...' : 'Quiz'}
              </Button>

              <Button
                color='primary'
                variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                sx={{ mr: 2, mb: 2 }}
                onClick={generateAndSaveFlashcards}
                disabled={isLoading}
                startIcon={
                  !loadingFlashcards ? (
                    <ViewAgendaIcon style={{ color: '#fff' }} />
                  ) : (
                    <CircularProgress size={18} sx={{ color: '#fff' }} />
                  )
                }
              >
                {loadingFlashcards ? 'Genererar flashcards...' : 'Flashcards'}
              </Button>

              <Button
                variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                color='primary'
                startIcon={<QuestionAnswerIcon />}
                sx={{ mb: 2, mr: 2 }}
                onClick={() => handleAskAI()}
                disabled={isLoading}
              >
                Fråga AI
              </Button>
              <Tooltip placement='top' title='Snart kan du generera en podcast om innehållet!'>
                <span>
                  <Button
                    color='primary'
                    variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                    startIcon={<PodcastsIcon />}
                    sx={{ mb: 2 }}
                    disabled={true}
                  >
                    Podcast
                  </Button>
                </span>
              </Tooltip>
            </Box>

            <Button
              onClick={() => handleChapterCompletion(chapterIndex)}
              color='primary'
              startIcon={isCompleted ? <CheckCircleIcon /> : <RadioButtonUncheckedIcon />}
              variant='contained'
              sx={{ mb: 2 }}
            >
              {isCompleted ? 'Markerad som slutförd' : 'Markera som slutförd'}
            </Button>
          </Box>

          <AIDialog
            userId={user.uid}
            user={user}
            open={aiDialogOpen}
            onClose={() => {
              setAiDialogOpen(false);
            }}
            initialQuestion={chapterContent}
            systemMessage='Du är en hjälpsam AI-assistent designad för att förklara och hjälpa till att förstå texten.'
            theme={theme}
            isInline={true}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CourseChapterContent;
