import React, { useState, useRef } from 'react';
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Grid,
  Divider,
  FormControl,
  FormGroup,
  Checkbox,
} from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import InfoBox from './InfoBox';
import BoltIcon from '@mui/icons-material/Bolt';
import CreateIcon from '@mui/icons-material/Create';
import ImageIcon from '@mui/icons-material/Image';
import GradeSelector from './GradeSelector';
import LanguageSelector from './LanguageSelector';
import { CustomFormControlLabel } from './functions';
import GenerateIcon from '@mui/icons-material/AutoAwesome';
import { callApi } from './api';
import { generateAndProcessImage, trackLesson } from './functions';
import { useSnackbar } from './SnackbarHandler';
import ImageGenerationModal from './ImageGenerationModal';
import Logger from './Logger';
import SaveDocumentButton from './SaveDocumentButton';
import PrintIcon from '@mui/icons-material/Print';
import { handlePrintStory } from './printFunctions';

const NarrativeLessonStoryGenerator = ({ planData, themeColor, theme, styles, user }) => {
  const [storyData, setStoryData] = useState(null);
  const [storyGrade, setStoryGrade] = useState('åk6');
  const [storyLanguage, setStoryLanguage] = useState('svenska');
  const [selectedCharacters, setSelectedCharacters] = useState([]);
  const [selectedEnvironments, setSelectedEnvironments] = useState([]);
  const [selectedPlots, setSelectedPlots] = useState([]);
  const [isGeneratingStory, setIsGeneratingStory] = useState(false);
  const [isGeneratingStoryCharacterImages, setIsGeneratingStoryCharacterImages] = useState(false);
  const [isGeneratingStorySceneImages, setIsGeneratingStorySceneImages] = useState(false);
  const [generatedStory, setGeneratedStory] = useState('');
  const { showSnackbar } = useSnackbar();
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageInstructions, setImageInstructions] = useState('');
  const [imageGenerationType, setImageGenerationType] = useState(null);
  const editorRef = useRef(null);

  const handleImageButtonClick = type => {
    setImageGenerationType(type);
    setShowImageModal(true);
  };

  const handleCloseModal = () => {
    setShowImageModal(false);
    setImageInstructions('');
  };

  const handleGenerateWithInstructions = async () => {
    handleCloseModal();

    if (imageGenerationType === 'characters') {
      await handleGenerateStoryCharacterImages();
    } else if (imageGenerationType === 'scenes') {
      await handleGenerateStorySceneImages();
    }
  };

  const handleGenerateStory = async () => {
    if (!selectedCharacters.length || !selectedEnvironments.length || !selectedPlots.length) {
      showSnackbar('Välj minst ett alternativ från varje kategori', 'warning');
      return;
    }

    setIsGeneratingStory(true);
    try {
      const response = await callApi(
        [
          {
            role: 'system',
            content: `Du är en kreativ berättare som skriver engagerande berättelser på ${storyLanguage} för elever i ${storyGrade}. Du returnerar ENDAST JSON.`,
          },
          {
            role: 'user',
            content: `Skriv en engagerande berättelse som innehåller följande element:
              Karaktärer: ${selectedCharacters.join(', ')}
              Miljöer: ${selectedEnvironments.join(', ')}
              Handling: ${selectedPlots.join(', ')}
              
              Returnera ett JSON-objekt med följande struktur:
              {
                "title": "Berättelsens titel",
                "characters": [
                  {
                    "name": "Karaktärens namn",
                    "description": "Kort beskrivning",
                    "imageUrl": null
                  }
                ],
                "mainStoryImage": null,
                "storyContent": [
                  {
                    "type": "paragraph",
                    "content": "Styckestext här"
                  }
                ],
                "environmentImages": [
                  {
                    "description": "Beskrivning av miljöbild",
                    "imageUrl": null
                  }
                ]
              }`,
          },
        ],
        4000,
        true,
        user.uid
      );

      let messageContent = response.data.choices[0].message.content.trim();
      if (messageContent.startsWith('```json')) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith('```')) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const data = JSON.parse(messageContent);
      setStoryData(data);
      updateEditorContent(data);
      showSnackbar('Berättelsen har genererats!', 'success');

      await trackLesson(user.uid, 'NarrativeLessonStoryGenerator.js', null, {
        storyLanguage,
      });
    } catch (error) {
      console.error('Error generating story:', error);
      showSnackbar('Ett fel uppstod när berättelsen skulle genereras', 'error');
    } finally {
      setIsGeneratingStory(false);
    }
  };

  const handleGenerateStoryCharacterImages = async () => {
    if (!storyData) return;

    setIsGeneratingStoryCharacterImages(true);
    try {
      const updatedCharacters = await Promise.all(
        storyData.characters.map(async char => {
          try {
            const imageUrl = await generateAndProcessImage(
              `Create a portrait of ${char.name}. ${char.description}. ${imageInstructions}`,
              user.uid,
              { size: '1024x1024', model: 'dall-e-3', quality: 'standard' }
            );
            return { ...char, imageUrl };
          } catch (error) {
            console.error(`Error generating image for ${char.name}:`, error);
            showSnackbar(`Kunde inte generera bild för ${char.name}`, 'error');
            return char;
          }
        })
      );

      setStoryData(prev => ({
        ...prev,
        characters: updatedCharacters,
      }));

      updateEditorContent({
        ...storyData,
        characters: updatedCharacters,
      });

      showSnackbar('Karaktärsbilder har genererats!', 'success');
    } catch (error) {
      console.error('Error in character image generation:', error);
      showSnackbar('Ett fel uppstod vid generering av karaktärsbilder', 'error');
    } finally {
      setIsGeneratingStoryCharacterImages(false);
    }
  };

  const handleGenerateStorySceneImages = async () => {
    if (!storyData) return;

    setIsGeneratingStorySceneImages(true);
    try {
      // Generate main story image
      let mainStoryImage = null;
      try {
        mainStoryImage = await generateAndProcessImage(
          `Create a main image for the story "${storyData.title}.". ${imageInstructions}.`,
          user.uid,
          { size: '1024x1024', model: 'dall-e-3', quality: 'standard' }
        );
      } catch (error) {
        console.error('Error generating main story image:', error);
        showSnackbar('Kunde inte generera huvudbild', 'error');
      }

      // Generate environment images
      const updatedEnvironments = await Promise.all(
        storyData.environmentImages.map(async env => {
          try {
            const imageUrl = await generateAndProcessImage(
              `Create an image of: ${env.description}.`,
              user.uid,
              { size: '1024x1024', model: 'dall-e-3', quality: 'standard' }
            );
            return { ...env, imageUrl };
          } catch (error) {
            console.error('Error generating environment image:', error);
            showSnackbar(`Kunde inte generera miljöbild`, 'error');
            return env;
          }
        })
      );

      const updatedStoryData = {
        ...storyData,
        mainStoryImage,
        environmentImages: updatedEnvironments,
      };

      setStoryData(updatedStoryData);
      updateEditorContent(updatedStoryData);

      showSnackbar('Miljöbilder har genererats!', 'success');
    } catch (error) {
      console.error('Error in scene image generation:', error);
      showSnackbar('Ett fel uppstod vid generering av miljöbilder', 'error');
    } finally {
      setIsGeneratingStorySceneImages(false);
    }
  };

  const updateEditorContent = data => {
    const htmlContent = `
    <h1 style="color: ${themeColor}">${data.title}</h1>
    
    <div class="characters-section" style="margin: 20px 0; display: grid; grid-template-columns: repeat(auto-fit, minmax(500px, 1fr)); gap: 20px;">
      ${data.characters
        .map(
          char => `
        <div style="display: flex; gap: 20px; background-color: #ffffff; padding: 15px; border-radius: 8px; box-shadow: 0 2px 4px rgba(0,0,0,0.1);">
          <div style="flex: 0 0 200px; width: 200px; height: 200px; position: relative;">
            <div style="width: 100%; height: 100%; background-color: #f0f0f0; border-radius: 5px; overflow: hidden; display: flex; align-items: center; justify-content: center;">
              ${
                char.imageUrl
                  ? `<img src="${char.imageUrl}" alt="${char.name}" style="width: 100%; height: 100%; object-fit: cover;">`
                  : '<div style="text-align: center;">Bild hamnar här</div>'
              }
            </div>
          </div>
          <div style="flex: 1; min-width: 0; display: flex; flex-direction: column;">
            <strong style="font-size: 28px; margin-bottom: 10px; display: block;">${char.name}</strong>
            <div style="font-size: 16px;">${char.description}</div>
          </div>
        </div>
      `
        )
        .join('')}
    </div>



    ${data.storyContent
      .map(
        section => `
      <p>${section.content}</p>
    `
      )
      .join('')}


    ${
      data.mainStoryImage
        ? `
      <div style="margin: 40px 0; text-align:center;">
        <img src="${data.mainStoryImage}" alt="Huvudbild" style="height:400px; width: 400px; margin:0 auto; ">
      </div>
    `
        : ''
    }


    <div class="environment-images" style="margin: 20px 0; display: flex; flex-wrap: wrap; gap: 20px;">
      ${data.environmentImages
        .map(
          env => `
        <div style="width: 300px;">
          <div style="width: 100%; height: 200px; background-color: #f0f0f0; display: flex; align-items: center; justify-content: center; border-radius: 5px; margin-bottom: 10px;">
            ${env.imageUrl ? `<img src="${env.imageUrl}" alt="${env.description}" style="max-width: 100%; max-height: 100%;">` : 'Miljöbild hamnar här'}
          </div>
          
        </div>
      `
        )
        .join('')}
    </div>
  `;

    setGeneratedStory(htmlContent);
  };

  if (!planData) return null;

  return (
    <Box sx={styles.container}>
      <Box
        sx={{
          ...styles.innerContainer,
          width: { xs: '100%', md: '87%' },
          margin: '0 auto',
        }}
      >
        <Box sx={{ mt: 4 }}>
          <Typography
            variant='h2'
            gutterBottom
            sx={{ fontWeight: 'bold', fontSize: '1.8em', mb: 3, mt: 5, textAlign: 'center' }}
          >
            BONUS: Generera separat berättelse
          </Typography>
          <InfoBox
            icon={BoltIcon}
            title='Powerpoint Instructions'
            instructions={[
              'Välj ut specifika karaktärer, miljöer och handlingar',
              'Generera en separat berättelse baserat på dessa element',
              'Lägg till fantasieggande AI-genererade bilder',
              'Skriv ut eller spara berättelsen',
            ]}
            theme={theme}
            styles={styles}
          />
          <Box sx={{ ...styles.freshBox, padding: '30px' }}>
            <Grid container spacing={3} sx={{ mb: 4 }}>
              <Grid item xs={12} md={6}>
                <Typography
                  variant='formLabelSinUnderline'
                  sx={{ fontWeight: '500', mb: 1, display: 'block' }}
                >
                  Årskurs för berättelsen:
                </Typography>
                <GradeSelector grade={storyGrade} setGrade={setStoryGrade} />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  variant='formLabelSinUnderline'
                  sx={{ fontWeight: '500', mb: 1, display: 'block' }}
                >
                  Språk för berättelsen:
                </Typography>
                <LanguageSelector language={storyLanguage} setLanguage={setStoryLanguage} />
              </Grid>
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <FormControl component='fieldset'>
                  <Typography sx={{ mb: 2, fontWeight: '600' }}>Karaktärer:</Typography>
                  <FormGroup>
                    {planData.whoOrWhich.map((character, index) => (
                      <CustomFormControlLabel
                        key={index}
                        theme={theme}
                        control={<Checkbox />}
                        label={
                          <Typography sx={{ fontSize: '0.975rem' }}>{character.name}</Typography>
                        }
                        checked={selectedCharacters.includes(character.name)}
                        onChange={() =>
                          setSelectedCharacters(prev =>
                            prev.includes(character.name)
                              ? prev.filter(c => c !== character.name)
                              : [...prev, character.name]
                          )
                        }
                        sx={{ mb: 2 }}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl component='fieldset'>
                  <Typography sx={{ color: themeColor, mb: 2, fontWeight: '600' }}>
                    Miljöer:
                  </Typography>
                  <FormGroup>
                    {planData.where.map((environment, index) => (
                      <CustomFormControlLabel
                        key={index}
                        theme={theme}
                        control={<Checkbox />}
                        label={<Typography sx={{ fontSize: '0.975rem' }}>{environment}</Typography>}
                        checked={selectedEnvironments.includes(environment)}
                        onChange={() =>
                          setSelectedEnvironments(prev =>
                            prev.includes(environment)
                              ? prev.filter(e => e !== environment)
                              : [...prev, environment]
                          )
                        }
                        sx={{ mb: 2 }}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl component='fieldset'>
                  <Typography sx={{ color: themeColor, mb: 2, fontWeight: '600' }}>
                    Handling:
                  </Typography>
                  <FormGroup>
                    {planData.what.map((plot, index) => (
                      <CustomFormControlLabel
                        key={index}
                        theme={theme}
                        control={<Checkbox />}
                        label={<Typography sx={{ fontSize: '0.975rem' }}>{plot}</Typography>}
                        checked={selectedPlots.includes(plot)}
                        onChange={() =>
                          setSelectedPlots(prev =>
                            prev.includes(plot) ? prev.filter(p => p !== plot) : [...prev, plot]
                          )
                        }
                        sx={{ mb: 2 }}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>

            <Box sx={{ mt: 3, mb: 4 }}>
              <Button
                variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                onClick={handleGenerateStory}
                disabled={isGeneratingStory}
                startIcon={isGeneratingStory ? <CircularProgress size={16} /> : <GenerateIcon />}
              >
                {isGeneratingStory ? 'Genererar berättelse...' : 'Generera berättelse'}
              </Button>
            </Box>

            {generatedStory && (
              <Box sx={{ mt: 4 }}>
                <Divider sx={{ mt: 3, mb: 4 }} />

                <Button
                  variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                  onClick={() => handleImageButtonClick('characters')}
                  disabled={isGeneratingStoryCharacterImages}
                  sx={{ mb: 6 }}
                  startIcon={
                    isGeneratingStoryCharacterImages ? (
                      <CircularProgress size={16} />
                    ) : (
                      <ImageIcon />
                    )
                  }
                >
                  {isGeneratingStoryCharacterImages
                    ? 'Genererar karaktärer...'
                    : 'Generera karaktärsbilder'}
                </Button>

                <Button
                  variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                  onClick={() => handleImageButtonClick('scenes')}
                  disabled={isGeneratingStorySceneImages}
                  sx={{ ml: 2, mb: 6 }}
                  startIcon={
                    isGeneratingStorySceneImages ? <CircularProgress size={16} /> : <ImageIcon />
                  }
                >
                  {isGeneratingStorySceneImages ? 'Genererar miljöer...' : 'Generera miljöbilder'}
                </Button>
                <Button
                  variant='contained'
                  onClick={() => handlePrintStory(storyData, themeColor)}
                  sx={{ ml: 2, mb: 6 }}
                  startIcon={<PrintIcon />}
                >
                  Skriv ut
                </Button>

                <SaveDocumentButton
                  editorContent={generatedStory}
                  showSnackbar={showSnackbar}
                  theme={theme}
                  storyData={storyData}
                />
                <ImageGenerationModal
                  open={showImageModal}
                  onClose={handleCloseModal}
                  onGenerate={handleGenerateWithInstructions}
                  isGenerating={
                    imageGenerationType === 'characters'
                      ? isGeneratingStoryCharacterImages
                      : isGeneratingStorySceneImages
                  }
                  instructions={imageInstructions}
                  setInstructions={setImageInstructions}
                  generationType={imageGenerationType}
                  theme={theme}
                />

                <Editor
                  apiKey='fxkb0ty514q6ak7t7lo34gojjloffnpw3n9fr8tdz3swjxi3'
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  value={generatedStory}
                  onEditorChange={(content, editor) => setGeneratedStory(content)}
                  init={{
                    height: 800,
                    menubar: false,
                    plugins: [
                      'advlist',
                      'autolink',
                      'lists',
                      'link',
                      'image',
                      'charmap',
                      'preview',
                      'anchor',
                      'searchreplace',
                      'visualblocks',
                      'code',
                      'fullscreen',
                      'insertdatetime',
                      'media',
                      'table',
                      'code',
                      'help',
                      'wordcount',
                    ],
                    toolbar:
                      'undo redo | blocks | ' +
                      'bold italic forecolor | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | ' +
                      'removeformat | help',
                    content_style:
                      'body { font-family: -apple-system, BlinkMacSystemFont, sans-serif; line-height: 28px !important; } p { line-height: 28px !important; } mark { font-weight: bold; padding: 5px; background: #dcccff; border-radius:5px; } h3 { margin-top: 60px !important; } ul li { margin-bottom: 12px !important; line-height: 28px !important; } ul, ol { margin:20px !important } table { width: 100% !important; border-collapse: collapse !important; } th, td { padding: 10px !important; border: 1px solid #ddd !important; text-align: left !important; } table, th, td { border: 1px solid #ddd !important; }',
                    skin: theme.palette.mode === 'dark' ? 'oxide-dark' : 'oxide',
                    content_css: theme.palette.mode === 'dark' ? 'dark' : 'default',
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NarrativeLessonStoryGenerator;
