import React from 'react';
import { Box, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import TeachersGuide from './TeachersGuide';

const InfoBox = ({
  icon: Icon,
  title,
  instructions,
  theme,
  styles,
  muiIconFontSize = null,
  teachersGuide = null,
  user = null,
}) => (
  <Box
    sx={{
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      gap: 3,
      mb: 3,
      backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'white',
      borderRadius: '12px',
      padding: '20px',
      border: theme.palette.mode === 'dark' ? undefined : '1px solid #e0e0e080',
    }}
  >
    {teachersGuide && (
      <Box
        sx={{
          position: 'absolute',
          top: 20,
          right: 20,
          zIndex: 1,
        }}
      >
        <TeachersGuide guideKey={teachersGuide} theme={theme} user={user} />
      </Box>
    )}

    <Box
      sx={{
        display: 'flex',
        alignItems: 'center', // Changed from 'flex-start' to 'center'
        gap: 3,
        backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : '#fbfbfb',
        width: '100%',
        padding: '20px',
        borderRadius: '12px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          alignSelf: 'center', // Added this to ensure icon stays centered
        }}
      >
        <Icon size={110} sx={{ color: '#7B68EE', fontSize: '130px' }} color='#7B68EE' />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '4px',
          flex: 1, // Added to ensure proper spacing
        }}
      >
        {instructions.map((instruction, index) => (
          <InstructionItem key={index} text={instruction} styles={styles} />
        ))}
      </Box>
    </Box>
  </Box>
);

const InstructionItem = ({ text, styles }) => (
  <Box sx={{ display: 'flex', alignItems: 'center' }}>
    <Box
      sx={{
        width: 20,
        height: 20,
        borderRadius: '50%',
        backgroundColor: 'rgba(123, 104, 238, 0.1)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        mr: 2,
      }}
    >
      <CheckIcon sx={{ fontSize: 18, color: '#7B68EE' }} />
    </Box>
    <Typography
      variant='body2'
      sx={{
        ...styles.grayText,
        fontSize: '18px',
        lineHeight: '31px',
        fontWeight: '500',
      }}
    >
      {text}
    </Typography>
  </Box>
);

export default InfoBox;
