// PublicTeachers.js
import React, { useState, useEffect, useRef } from 'react';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  Box,
  Avatar,
  InputAdornment,
  Chip,
  CircularProgress,
  useMediaQuery,
  IconButton,
  Collapse,
} from '@mui/material';
import { collection, getDocs, addDoc, query, where, setDoc, doc, getDoc } from 'firebase/firestore';
import { db } from './firebase';
import { useNavigate, Link } from 'react-router-dom';
import { useSnackbar } from './SnackbarHandler';
import { useTheme } from '@mui/material/styles';
import { getStyles } from '../index';
import SearchIcon from '@mui/icons-material/Search';
import { formatUpvotes, flagUrls } from './functions';
import AddIcon from '@mui/icons-material/Add';
import LoadingSpinner from './LoadingSpinner';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SchoolIcon from '@mui/icons-material/School';
import { styled } from '@mui/material/styles';
import Logger from './Logger';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import CategoryCarousel from './CategoryCarousel';

import SearchAutocomplete from './SearchAutocomplete';

const ImageBox = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 200,
  borderRadius: 15,
  overflow: 'hidden',
  position: 'relative',
  cursor: 'pointer',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  '&:hover': {
    '& .MuiTypography-root': {
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
    },
  },
}));

const ImageOverlay = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  fontSize: '15px',
  color: 'white',
  fontWeight: '500',
  padding: theme.spacing(2),
  transition: 'background-color 0.3s',
}));

const PublicTeachers = ({ user }) => {
  const [publicTeachers, setPublicTeachers] = useState([]);
  const [userTeachers, setUserTeachers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [categories, setCategories] = useState([]);
  const [categoryNames, setCategoryNames] = useState({});
  const [visibleTeachers, setVisibleTeachers] = useState({});
  const [featuredTeachers, setFeaturedTeachers] = useState([]);
  const [featuredTeachersNew, setFeaturedTeachersNew] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('Alla');
  const [featuredTeacherIds] = useState([
    'nTB3pwXxBdLHbhar839H',

    'FuMyYtfiX2WzZAwIyegU',

    'JlH6DR5kcaL9jRl49tRD',
    '33ZtJVKRpc80QVfXzD2x',

    'iZgt6mAdqIpt7pLjRS5w',
    'xXhoDvHHb6QxG1H1lmul',
    'X6KLiiwYT6UJXaGYHBQ2',
    'FWtOI0nejxC8gEWqkI7v',
    '5smnqJlTM0GzeCWScpJN',
    '33ZtJVKRpc80QVfXzD2x',
  ]);
  const [featuredTeacherNewIds] = useState([
    'OTlWafvdT4ZRr01yFyZd',
    'Vh3Z6JVs4JCL55EHIpk6',
    'pqSK7pqWXsyEsOkxpUy0',
    'jQOVP4WABFtWpIkHZWYD',
    '6bs1lGSApjJ1gbqbm3ua',
    'dL1NVd9HMYxQvTL2j9VO',
    'kvZhgusQRykTBUmNA8dG',
    'kuTk3RuecSRMXvximDsx',
    'nHNQmF42laxU8ccC6DDg',
  ]); // Replace with your desired IDs
  const [loading, setLoading] = useState(false);
  const [isLoadingTeachers, setIsLoadingTeachers] = useState(true);
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const theme = useTheme();
  const styles = getStyles(theme);
  const categoryRefs = useRef({});
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const traditionalTeachersRef = useRef(null);
  const [expandedCards, setExpandedCards] = useState({});
  const [creatorDisplayNames, setCreatorDisplayNames] = useState({});
  const [excludedTeacherIds] = useState([
    'qWm0UZ6E1tWR1C12ud0P',
    '7dlCEmCaKc7F0UiYIPqs',
    'IfSUqxsO1yIKdImFhXyB',
    '4xY9escRJb0K2dn9VHf3',
  ]); // Replace with the actual IDs you want to exclude

  const handleExpandClick = (event, teacherId) => {
    event.preventDefault(); // Prevent the Link from navigating
    event.stopPropagation(); // Stop the event from bubbling up
    setExpandedCards(prev => ({
      ...prev,
      [teacherId]: !prev[teacherId],
    }));
  };

  useEffect(() => {
    const fetchPublicTeachers = async () => {
      setIsLoadingTeachers(true);
      const teachersRef = collection(db, 'publicTeachers');
      const teachersSnapshot = await getDocs(teachersRef);
      const teachersList = teachersSnapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .filter(teacher => !excludedTeacherIds.includes(teacher.id)); // Filter out excluded IDs

      // Separate featured teachers
      const featured = teachersList
        .filter(teacher => featuredTeacherIds.includes(teacher.id))
        .sort((a, b) => {
          return featuredTeacherIds.indexOf(a.id) - featuredTeacherIds.indexOf(b.id);
        });

      setFeaturedTeachers(featured);

      const featuredNew = teachersList.filter(teacher =>
        featuredTeacherNewIds.includes(teacher.id)
      );
      setFeaturedTeachersNew(featuredNew);

      setPublicTeachers(teachersList);

      // Extract categories
      // Definiera en array med kategorier som ska exkluderas
      const excludedCategories = ['Språk'];

      // Uppdatera den befintliga koden
      const uniqueCategories = [
        ...new Set(teachersList.map(teacher => teacher.category || 'Övrigt')),
      ];
      setCategories(uniqueCategories);

      // Fetch category names for IDs
      const categoryNamesMap = {};
      for (const category of uniqueCategories) {
        if (typeof category === 'string' && category.length === 20) {
          // Assuming Firestore IDs are 20 characters long
          const categoryDoc = await getDoc(doc(db, 'categories', category));
          if (categoryDoc.exists()) {
            categoryNamesMap[category] = categoryDoc.data().name;
          }
        }
      }
      setCategoryNames(categoryNamesMap);

      // Initialize visible teachers
      const initialVisibleTeachers = {};
      uniqueCategories.forEach(category => {
        initialVisibleTeachers[category] = 6; // Show 6 teachers initially (3 per row, 2 rows)
      });
      setVisibleTeachers(initialVisibleTeachers);
      setIsLoadingTeachers(false);
    };

    const fetchUserTeachers = async () => {
      if (user) {
        const userTeachersRef = collection(db, 'teachers');
        const q = query(userTeachersRef, where('userId', '==', user.uid));
        const userTeachersSnapshot = await getDocs(q);
        const userTeachersList = userTeachersSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          copiedFromPublicId: doc.data().copiedFromPublicId,
        }));
        Logger.log('Fetched userTeachers:', userTeachersList);
        setUserTeachers(userTeachersList);
      }
    };

    fetchPublicTeachers();
    fetchUserTeachers();
  }, [user, featuredTeacherIds, featuredTeacherNewIds, excludedTeacherIds]);
  const getCategoryName = category => {
    if (typeof category === 'string' && category.length === 20) {
      return categoryNames[category] || 'Unknown Category';
    }
    return category;
  };
  useEffect(() => {
    const fetchCreatorDisplayNames = async () => {
      const creatorIds = [...new Set(publicTeachers.map(teacher => teacher.userId))];
      const displayNames = {};

      for (const creatorId of creatorIds) {
        if (creatorId) {
          const userRef = doc(db, 'users', creatorId);
          const userSnap = await getDoc(userRef);

          if (userSnap.exists()) {
            displayNames[creatorId] = userSnap.data().displayName || 'anonym';
          } else {
            displayNames[creatorId] = 'anonym';
          }
        }
      }

      setCreatorDisplayNames(displayNames);
    };

    if (publicTeachers.length > 0) {
      fetchCreatorDisplayNames();
    }
  }, [publicTeachers]);

  const handleUseTeacher = async (teacher, event) => {
    event.preventDefault();
    try {
      if (!user) {
        showSnackbar('Du måste vara inloggad för att använda en lärare.', 'error');
        return;
      }

      const teacherCopy = {
        ...teacher,
        userId: user.uid,
        createdAt: new Date(),
        updatedAt: new Date(),
        visibility: 'private',
        isPublicCopy: true,
      };

      await setDoc(doc(db, 'teachers', teacher.id), teacherCopy);

      showSnackbar(`${teacher.characterName} har stigit in i klassrummet.`, 'success');
      navigate(`/app/chat/${teacher.id}`);
    } catch (error) {
      console.error('Fel vid kopiering av lärare: ', error);
      showSnackbar('Det gick inte att kopiera läraren. Försök igen.', 'error');
    }
  };

  const isTeacherInUserCollection = publicTeacherId => {
    return userTeachers.some(userTeacher => userTeacher.id === publicTeacherId);
  };

  const filteredTeachers = publicTeachers.filter(
    teacher =>
      (teacher?.characterName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        teacher?.tagline?.toLowerCase().includes(searchTerm.toLowerCase())) ??
      false
  );

  const handleReadMore = (teacherId, teacherName) => {
    const formattedName = teacherName.toLowerCase().replace(/\s+/g, '-');
    navigate(`/app/larare/${teacherId}/${encodeURIComponent(formattedName)}`);
  };

  const handleLoadMore = category => {
    setLoading(true);
    setTimeout(() => {
      setVisibleTeachers(prev => ({
        ...prev,
        [category]: prev[category] + 6,
      }));
      setLoading(false);
    }, 500); // Simulating network delay
  };

  const scrollToCategory = category => {
    if (category === 'utvalda') {
      traditionalTeachersRef.current?.scrollIntoView({ behavior: 'smooth' });
    } else {
      categoryRefs.current[category]?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleCreateNewTeacher = () => {
    navigate(`/app/larare/`);
  };

  const getCategoryDescription = category => {
    switch (category) {
      case 'Matematik':
        return 'Utforska matematikens värld med våra specialiserade lärare.';
      case 'Språk':
        return 'Lär dig nya språk eller förbättra dina språkkunskaper.';
      case 'Vetenskap':
        return 'Upptäck vetenskapens under med våra kunniga lärare.';
      case 'Historia':
        return '';
      case 'Konst':
        return 'Släpp loss din kreativitet med hjälp av våra konstlärare.';
      case 'Litteratur':
        return 'Utforska litteraturens värld med våra inspirerande lärare.';
      case 'Musik':
        return 'Utveckla din musikalitet med våra erfarna musiklärare.';
      case 'Idrott':
        return 'Förbättra din fysiska prestationsförmåga med våra engagerade idrottslärare.';
      case 'Teknik':
        return 'Utforska den senaste tekniken med våra innovativa tekniklärare.';
      case 'Psykologi':
        return 'Förstå människans beteende med hjälp av våra kunniga psykologilärare.';
      case 'Filosofi':
        return 'Utmana ditt tänkande med våra insiktsfulla filosofilärare.';
      case 'Religion':
        return 'Utforska världens religioner med våra objektiva religionslärare.';
      case 'Mental hälsa':
        return 'Lär dig verktyg för bättre mental hälsa med våra empatiska lärare.';
      case 'Övrigt':
        return 'Upptäck nya och spännande ämnen med våra mångsidiga lärare.';
      case 'Galna karaktärer':
        return 'Några gränslösa karaktärer för variationens skull.';
      default:
        return 'Utforska spännande ämnen med våra erfarna lärare.';
    }
  };

  const getTeacherType = teacherName => {
    switch (teacherName) {
      case 'Johan Bauer':
        return 'Tysklärare';
      case 'David Eriksson':
        return 'Mattelärare';
      case 'Katarina Lindström':
        return 'Historialärare';
      case 'Maria Lindström':
        return 'Mental hälsa/Psykolog';
      case 'Emma Johnson':
        return 'Engelsklärare';
      case 'Marie Dubois':
        return 'Fransklärare';
      case 'Isabel García':
        return 'Spansklärare';
      // Add more cases as needed
      default:
        return 'General Teacher';
    }
  };

  return (
    <Box sx={styles.container}>
      <Box sx={{ ...styles.innerContainer, width: { xs: '100%', md: '100%' }, margin: '0 auto' }}>
        <Box sx={{ textAlign: { xs: 'left', md: 'left' } }}>
          <Typography variant='h1' sx={{ ...styles.header, mt: 5 }}>
            AI-karaktärer
          </Typography>

          <Box sx={{ ...styles.buttonsContainer, ...styles.additionalButtonsContainer }}>
            {isMobile ? (
              <Button
                variant='contained'
                color='primary'
                disableRipple
                component={Link}
                to='/app/larare'
                startIcon={<AddIcon />}
                sx={styles.shrinkButton}
              />
            ) : (
              <Button
                variant='contained'
                color='primary'
                component={Link}
                to='/app/larare'
                startIcon={<AddIcon />}
              >
                Ny karaktär
              </Button>
            )}
          </Box>
        </Box>

        {isLoadingTeachers ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '200px',
            }}
          >
            <LoadingSpinner />
          </Box>
        ) : (
          <>
            {/* New CategoryCarousel component */}
            <Box sx={{ marginTop: '35px', display: { xs: 'none', md: 'block' } }}>
              <CategoryCarousel />
            </Box>
            {/* Featured Teachers Section */}
            {featuredTeachers.length > 0 && searchTerm === '' && (
              <Box ref={traditionalTeachersRef} key='utvalda' sx={{ marginBottom: 4 }}>
                <Typography variant='h3' sx={{ ...styles.h3, mt: 8 }}>
                  Populära just nu
                </Typography>
                <Box
                  sx={{ color: theme.palette.mode === 'dark' ? '#c4c4c4' : '#333', mb: 4, mt: 1 }}
                ></Box>
                <Grid container spacing={2}>
                  {featuredTeachers.map(teacher => (
                    <Grid item xs={12} sm={6} md={4} key={teacher.id}>
                      <Link
                        to={`/app/chat/${teacher.id}`}
                        //onClick={e => handleUseTeacher(teacher, e)}
                        style={{ textDecoration: 'none' }}
                      >
                        <Card
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            borderRadius: '16px',
                            overflow: 'hidden',
                            boxShadow: 'none',
                            border: 'none',
                            background: theme.palette.mode === 'dark' ? '#1d2b37' : '#fff',
                            color: theme.palette.mode === 'dark' ? '#fff' : '#444',
                            transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                            '&:hover': {
                              transform: 'translateY(-5px)',
                            },
                          }}
                        >
                          <CardContent sx={{ position: 'relative' }}>
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                              }}
                            >
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box sx={{ position: 'relative' }}>
                                  <Avatar
                                    src={teacher.imageUrl}
                                    alt={teacher.characterName}
                                    sx={{
                                      width: 80,
                                      height: 100,
                                      marginRight: 2,
                                      borderRadius: '16px',
                                      border: '2px solid white',
                                      boxShadow:
                                        theme.palette.mode === 'dark'
                                          ? '0 2px 10px rgba(0,0,0,0.2)'
                                          : 'none',
                                    }}
                                  />
                                  {flagUrls[teacher.language] && (
                                    <Box
                                      component='img'
                                      src={flagUrls[teacher.language]}
                                      alt={`${teacher.language} `}
                                      sx={{
                                        position: 'absolute',
                                        top: '-4px',
                                        right: '6px',
                                        width: '22px',
                                        height: '22px',
                                        borderRadius: '50%',
                                        border: '1px solid white',
                                      }}
                                    />
                                  )}
                                </Box>

                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                  <Typography
                                    variant='h5'
                                    sx={{ fontWeight: 'bold', fontSize: '16px' }}
                                  >
                                    {teacher.characterName}
                                  </Typography>
                                  <Box sx={{ mt: 0, fontSize: '13px', fontWeight: '500' }}>
                                    @{creatorDisplayNames[teacher.userId] || 'anonym'}
                                  </Box>
                                  <Box sx={{ mt: 0 }}>
                                    <Typography variant='body2'>
                                      {teacher.description
                                        ? teacher.description.length > 50
                                          ? teacher.description.substring(0, 50) + '...'
                                          : teacher.description
                                        : 'Ingen beskrivning tillgänglig.'}
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      mt: 1,
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: '12px',
                                    }}
                                  >
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                      <ThumbUpOffAltIcon sx={{ fontSize: '14px' }} />
                                      <Typography
                                        variant='body2'
                                        sx={{ fontSize: '13px', lineHeight: 1, fontWeight: '500' }}
                                      >
                                        {formatUpvotes(teacher.upvotes)}
                                      </Typography>

                                      <Typography
                                        variant='body2'
                                        sx={{ color: '#8b9099', fontSize: '14px' }}
                                      >
                                        &nbsp;•&nbsp;{' '}
                                        {(() => {
                                          const titles = {
                                            'Dr. Linnéa Sundberg': 'Samtalspartner',
                                            'David Eriksson': 'Mattelärare',
                                            Jesus: 'Hitta inre frid',
                                            'Erik Holmström': 'Svensklärare',
                                            'Isabel García': 'Spansklärare',
                                            'Dr. Magnus Holm': 'Psykolog',
                                            'Lena Gustafsson': 'Studievägledare',
                                            'Detektiv Saga Norén': 'Textbaserat spel',
                                            'Escape Room': 'Textbaserat spel',
                                            'Din bästa vän': 'Samtalspartner',
                                          };
                                          return titles[teacher.characterName] || 'Samtalspartner';
                                        })()}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                              <IconButton sx={{ color: 'white', marginLeft: 'auto' }}>
                                <ChevronRightIcon />
                              </IconButton>
                            </Box>
                          </CardContent>
                        </Card>
                      </Link>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}

            <Box sx={{ marginBottom: 4 }}>
              <Typography variant='h3' sx={{ ...styles.h3, mt: 8 }}>
                Kategorier
              </Typography>
            </Box>

            {/* Category buttons */}
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginBottom: 2 }}>
              <Button
                key='Alla'
                variant='outlined'
                onClick={() => setSelectedCategory('Alla')}
                sx={{
                  ...styles.beautyBtn,
                  fontWeight: 'normal',
                  fontSize: '15px',
                  backgroundColor: theme.palette.mode === 'dark' ? '#2f3d4a' : '#fff',
                  paddingTop: { xs: '2px', md: '10px' },
                  paddingBottom: { xs: '2px', md: '10px' },
                  borderRadius: { xs: '30px', md: '10px' },
                  fontWeight: '500',
                  color: theme.palette.mode === 'dark' ? '#ffffff' : '#333',
                  '&:hover, &:focus, &:active, &.Mui-focusVisible': {
                    color: theme.palette.mode === 'dark' ? '#ffffff' : '#333',
                  },
                }}
              >
                Alla
              </Button>
              {categories.map(category => (
                <Button
                  key={category}
                  variant='outlined'
                  onClick={() => setSelectedCategory(category)}
                  sx={{
                    ...styles.beautyBtn,
                    fontWeight: 'normal',
                    fontSize: '15px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#2f3d4a' : '#fff',

                    paddingTop: { xs: '2px', md: '10px' },
                    paddingBottom: { xs: '2px', md: '10px' },
                    borderRadius: { xs: '30px', md: '10px' },
                    fontWeight: '500',
                    color: theme.palette.mode === 'dark' ? '#ffffff' : '#333',
                    '&:hover, &:focus, &:active, &.Mui-focusVisible': {
                      color: theme.palette.mode === 'dark' ? '#ffffff' : '#333',
                    },
                  }}
                >
                  {getCategoryName(category)}
                </Button>
              ))}
            </Box>

            {/* Single row of teachers based on selected category */}
            <Grid container spacing={2}>
              {filteredTeachers
                .filter(
                  teacher => selectedCategory === 'Alla' || teacher.category === selectedCategory
                )
                .map(teacher => (
                  <Grid item xs={12} sm={6} md={4} key={teacher.id}>
                    <Link
                      to={`/app/chat/${teacher.id}`}
                      //onClick={e => handleUseTeacher(teacher, e)}
                      style={{ textDecoration: 'none' }}
                    >
                      <Card
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          borderRadius: '16px',
                          overflow: 'hidden',
                          boxShadow: 'none',
                          border: 'none',
                          background: theme.palette.mode === 'dark' ? '#1d2b37' : '#fff',
                          color: theme.palette.mode === 'dark' ? '#fff' : '#444',
                          transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                          '&:hover': {
                            transform: 'translateY(-5px)',
                          },
                        }}
                      >
                        <CardContent sx={{ position: 'relative' }}>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Avatar
                                src={teacher.imageUrl}
                                alt={teacher.characterName}
                                sx={{
                                  width: 80,
                                  height: 100,
                                  marginRight: 2,
                                  borderRadius: '16px',
                                  border: '2px solid white',
                                  boxShadow:
                                    theme.palette.mode === 'dark'
                                      ? '0 2px 10px rgba(0,0,0,0.2)'
                                      : 'none',
                                }}
                              />
                              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography
                                  variant='h5'
                                  sx={{ fontWeight: 'bold', fontSize: '16px' }}
                                >
                                  {teacher.characterName}
                                </Typography>
                                <Box sx={{ mt: 0, fontSize: '13px', fontWeight: '500' }}>
                                  @{creatorDisplayNames[teacher.userId] || 'anonym'}
                                </Box>
                                <Box sx={{ mt: 0 }}>
                                  <Typography variant='body2'>
                                    {teacher.description
                                      ? teacher.description.length > 50
                                        ? teacher.description.substring(0, 50) + '...'
                                        : teacher.description
                                      : 'Ingen beskrivning tillgänglig.'}
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    mt: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '12px',
                                  }}
                                >
                                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                    <ThumbUpOffAltIcon sx={{ fontSize: '14px' }} />
                                    <Typography
                                      variant='body2'
                                      sx={{ fontSize: '13px', lineHeight: 1, fontWeight: '500' }}
                                    >
                                      {formatUpvotes(teacher.upvotes)}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                            <IconButton sx={{ color: 'white', marginLeft: 'auto' }}>
                              <ChevronRightIcon />
                            </IconButton>
                          </Box>
                        </CardContent>
                      </Card>
                    </Link>
                  </Grid>
                ))}
            </Grid>
          </>
        )}

        <Box sx={{ ...styles.bottomCta, boxShadow: 'none', backgroundColor: 'transparent' }}>
          <SchoolIcon
            sx={{
              fontSize: { xs: 40, md: 60 },
              color: theme.palette.primary.main,
              marginBottom: 2,
            }}
          />
          <Typography variant='h4' sx={{ ...styles.bottomCtaTitle }}>
            Skapa en AI-lärare
          </Typography>
          <Typography variant='body1' sx={{ ...styles.bottomCtaBody }}>
            Designa en unik AI-karaktär som passar dina specifika behov. Anpassa kunskapsområden,
            personlighet och interaktionsstil för en skräddarsydd upplevelse.
          </Typography>
          <Button
            component={Link}
            to='/app/larare'
            variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
            color='primary'
            size='large'
            startIcon={<AddIcon />}
            sx={{
              textTransform: 'none',
              fontWeight: 'bold',
              padding: '10px 20px',
              fontSize: '1.1rem',
              marginTop: '20px',
            }}
          >
            Skapa din egen AI-bot
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default PublicTeachers;
