import React, { useState } from 'react';
import JSZip from 'jszip';
import {
	Box,
	Button,
	Typography,
	Select,
	MenuItem,
	CircularProgress,
	Card,
	CardContent,
	IconButton,
} from '@mui/material';

import { useNavigate } from 'react-router-dom';

import {
	NavigateNext,
	NavigateBefore,
	VolumeUp,
	Translate,
	AutoFixHigh,
	SkipNext,
	SkipPrevious,
	ArrowBack as BackIcon,
} from '@mui/icons-material';

import LanguageSelector from './LanguageSelector';
import Voice from './Voice';
import SharedAppBar from './SharedAppBar';
import BookShelf, { getSelectedBookTitle } from './BookShelf';
const WORDS_THRESHOLD = 50; // Threshold for combining paragraphs
const MAX_PARAGRAPHS_PER_VIEW = 2; // Maximum paragraphs to show at once

const EpubReader = ({ user }) => {
	const [selectedBook, setSelectedBook] = useState('');
	const [chapters, setChapters] = useState([]);
	const [currentChapter, setCurrentChapter] = useState(0);
	const [paragraphs, setParagraphs] = useState([]);
	const [currentParagraphIndex, setCurrentParagraphIndex] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [chapterProgress, setChapterProgress] = useState('');
	const [voiceKey, setVoiceKey] = useState(0);
	const navigate = useNavigate();
	const [selectedLanguage, setSelectedLanguage] = useState('svenska');
	const [showShelf, setShowShelf] = useState(true);

	const handleBookSelect = bookPath => {
		setSelectedBook(bookPath);
		setShowShelf(false);
		loadBook(bookPath); // This was missing!
	};

	const loadChapter = async (chapterIndex, chapterList = chapters, zipData) => {
		try {
			const fileName = chapterList[chapterIndex];
			console.log('Loading chapter file:', fileName);
			if (!fileName) return;

			const content = await zipData.files[fileName].async('text');
			console.log('Got raw content length:', content.length);

			const parser = new DOMParser();
			const doc = parser.parseFromString(content, 'text/html');

			// Just get paragraphs and block elements with actual content
			const textElements = Array.from(doc.querySelectorAll('p, div'));
			const extractedParagraphs = textElements
				.filter(el => {
					const hasText = el.textContent.trim().length > 0;
					const isParagraph = el.tagName.toLowerCase() === 'p';
					return hasText && (isParagraph || el.children.length === 0);
				})
				.map(el => el.textContent.trim())
				.slice(0, 100);

			console.log('Extracted paragraphs:', extractedParagraphs.length);

			setParagraphs(extractedParagraphs);
			setCurrentParagraphIndex(0);
			setCurrentChapter(chapterIndex);
			updateProgress(0, extractedParagraphs.length);

			if (fileName === 'cover.xhtml' && extractedParagraphs.length === 0) {
				await loadChapter(chapterIndex + 1, chapterList, zipData);
			}
		} catch (error) {
			console.error('Chapter load error:', error);
			alert('Failed to load chapter');
		}
	};

	// Helper function to count words in a string
	const countWords = text => text.trim().split(/\s+/).length;

	// Function to get current paragraphs to display
	const getCurrentParagraphs = () => {
		const result = [];
		let currentIndex = currentParagraphIndex;

		while (currentIndex < paragraphs.length && result.length < MAX_PARAGRAPHS_PER_VIEW) {
			const currentParagraph = paragraphs[currentIndex];
			const wordCount = countWords(currentParagraph);

			if (wordCount < WORDS_THRESHOLD && result.length < MAX_PARAGRAPHS_PER_VIEW - 1) {
				// If current paragraph is short and we haven't hit our max, add it and the next one
				result.push(currentParagraph);
				if (currentIndex + 1 < paragraphs.length) {
					result.push(paragraphs[currentIndex + 1]);
					currentIndex += 2;
				} else {
					currentIndex++;
				}
			} else {
				// If paragraph is long enough, just add it
				result.push(currentParagraph);
				currentIndex++;
			}
		}

		return result;
	};

	const loadBook = async bookPath => {
		setIsLoading(true);
		try {
			console.log('Starting to load book:', bookPath);
			const response = await fetch(bookPath);
			const bookData = await response.arrayBuffer();
			console.log('Book data size:', bookData.byteLength);

			const zip = new JSZip();
			const zipData = await zip.loadAsync(bookData);
			console.log('Available files in EPUB:', Object.keys(zipData.files));

			const contentFiles = Object.keys(zipData.files)
				.filter(
					name =>
						name.endsWith('.html') || name.endsWith('.xhtml') || name.endsWith('.htm')
				)
				.sort();

			console.log('Found content files:', contentFiles);

			setChapters(contentFiles);

			if (contentFiles.length > 0) {
				await loadChapter(0, contentFiles, zipData);
			} else {
				console.error('No content files found in EPUB');
			}
		} catch (error) {
			console.error('Detailed book load error:', error);
			console.error('Error state:', { bookPath });
			alert('Could not load the book');
		}
		setIsLoading(false);
	};

	const handleBookChange = event => {
		const bookPath = event.target.value;
		setSelectedBook(bookPath);
		if (bookPath) {
			loadBook(bookPath);
		}
	};

	const updateProgress = (paragraphIndex, totalParagraphs) => {
		setChapterProgress(`Paragraf ${paragraphIndex + 1} av ${totalParagraphs}`);
	};

	const handleNext = () => {
		const currentParagraphs = getCurrentParagraphs();
		const nextIndex = currentParagraphIndex + currentParagraphs.length;

		if (nextIndex < paragraphs.length) {
			setCurrentParagraphIndex(nextIndex);
			updateProgress(nextIndex, paragraphs.length);
			setVoiceKey(prev => prev + 1);
		}
	};

	const handlePrev = () => {
		if (currentParagraphIndex > 0) {
			// Move back by maximum paragraphs per view or to the start
			const prevIndex = Math.max(0, currentParagraphIndex - MAX_PARAGRAPHS_PER_VIEW);
			setCurrentParagraphIndex(prevIndex);
			updateProgress(prevIndex, paragraphs.length);
			setVoiceKey(prev => prev + 1);
		}
	};

	const handleNextChapter = async () => {
		if (currentChapter < chapters.length - 1) {
			await loadChapter(currentChapter + 1);
		}
	};

	const handlePrevChapter = async () => {
		if (currentChapter > 0) {
			await loadChapter(currentChapter - 1);
		}
	};

	const handleAIAction = async action => {
		const currentParagraphs = getCurrentParagraphs();
		if (currentParagraphs.length === 0) return;

		switch (action) {
			case 'read':
				setVoiceKey(prev => prev + 1); // Force Voice component to reset when paragraphs change
				break;
			case 'translate':
				console.log('Translate:', currentParagraphs.join('\n'));
				break;
			case 'simplify':
				console.log('Simplify:', currentParagraphs.join('\n'));
				break;
		}
	};

	return (
		<Box sx={{ margin: '0 auto', position: 'relative' }}>
			<SharedAppBar onHomeClick={() => setShowShelf(true)} />

			{showShelf ? (
				<Box sx={{ mt: 6 }}>
					<BookShelf onSelect={handleBookSelect} selectedBook={selectedBook} />
				</Box>
			) : (
				<Box sx={{ maxWidth: '800px', margin: '0 auto', mt: 6 }}>
					<Typography variant='h6' sx={{ mb: 2, textAlign: 'center' }}>
						{getSelectedBookTitle(selectedBook)}
					</Typography>

					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center', // Changed from 'space-between' to 'center'
							mb: 2,
						}}
					>
						<Button
							sx={{ display: 'none' }}
							startIcon={<SkipPrevious />}
							onClick={handlePrevChapter}
							disabled={currentChapter === 0}
						>
							Föregående kapitel
						</Button>
						<Typography variant='subtitle1'>{chapterProgress}</Typography>
						<Button
							sx={{ display: 'none' }}
							endIcon={<SkipNext />}
							onClick={handleNextChapter}
							disabled={currentChapter === chapters.length - 1}
						>
							Nästa kapitel
						</Button>
					</Box>

					<Card elevation={3} sx={{ mb: 2, minHeight: '300px', borderRadius: '10px' }}>
						<CardContent>
							{isLoading ? (
								<Box sx={{ display: 'flex', justifyContent: 'center' }}>
									<CircularProgress />
								</Box>
							) : (
								<Box>
									{getCurrentParagraphs().map((paragraph, index) => (
										<Typography
											key={index}
											variant='body1'
											sx={{
												lineHeight: 1.8,
												fontSize: '1.1rem',
												mb:
													index < getCurrentParagraphs().length - 1
														? 2
														: 0,
											}}
										>
											{paragraph}
										</Typography>
									))}
								</Box>
							)}
						</CardContent>
					</Card>

					<Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mb: 3 }}>
						<Button
							variant='outlined'
							startIcon={<NavigateBefore />}
							onClick={handlePrev}
							disabled={currentParagraphIndex === 0}
						>
							Föregående
						</Button>
						<Button
							variant='outlined'
							endIcon={<NavigateNext />}
							onClick={handleNext}
							disabled={
								currentParagraphIndex + getCurrentParagraphs().length >=
								paragraphs.length
							}
						>
							Nästa
						</Button>
					</Box>

					<Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
						<Voice
							key={voiceKey}
							content={getCurrentParagraphs().join(' ')}
							voice='alloy'
							language={selectedLanguage}
							userId={user.uid}
							variant='button'
						/>
						<Button
							variant='contained'
							startIcon={<Translate />}
							onClick={() => handleAIAction('translate')}
							disabled={isLoading}
						>
							Översätt
						</Button>
						<Button
							variant='contained'
							startIcon={<AutoFixHigh />}
							onClick={() => handleAIAction('simplify')}
							disabled={isLoading}
						>
							Förenkla
						</Button>
					</Box>
				</Box>
			)}
		</Box>
	);
};

export default EpubReader;
