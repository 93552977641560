import React, { useState, useEffect, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  IconButton,
  Grid,
  CircularProgress,
  Typography,
  Box,
} from '@mui/material';
import { Close, Image, Favorite } from '@mui/icons-material';
import { styled } from '@mui/system';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useTheme } from '@mui/material/styles';
import { getStyles } from '../index';
import { useSnackbar } from './SnackbarHandler';
import BlurryBackdrop from './BlurryBackdrop';
import ContactForm from '../ContactForm';

const FeedbackDialog = React.memo(({ open, onClose, user }) => {
  const [feedback, setFeedback] = useState('');
  const [email, setEmail] = useState('');
  const [images, setImages] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const theme = useTheme();
  const styles = getStyles(theme);
  const { showSnackbar } = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (user && user.email) {
      setEmail(user.email);
    }
  }, [user]);

  // Handle cleanup when dialog closes
  const handleClose = useCallback(
    (event, reason) => {
      setTimeout(() => {
        onClose();
      }, 0);
    },
    [onClose]
  );

  // Cleanup function to reset state when dialog closes
  useEffect(() => {
    if (!open) {
      setFeedback('');
      setImages([]);
      setIsUploading(false);
      setIsSubmitting(false);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      BackdropComponent={BlurryBackdrop}
      maxWidth='lg'
      TransitionProps={{
        onExited: () => {
          setFeedback('');
          setImages([]);
        },
      }}
      PaperProps={{
        sx: {
          maxWidth: {
            xs: '100%',
            sm: '600px',
            md: '1050px',
          },
          width: '100%',
          borderRadius: '12px',
          backgroundColor: theme.palette.mode === 'dark' ? '#22303C' : '#f1f5f9',
          backgroundImage: theme.palette.mode === 'dark' ? 'none' : '#f1f5f9',
          padding: { xs: '0px', md: '20px' },
        },
      }}
    >
      <DialogTitle>
        <Box display='flex' alignItems='center' gap={1}>
          <Favorite sx={{ color: theme.palette.error.main }} />
          <Typography variant='h6' component='span' sx={{ fontWeight: '700' }}>
            Ge oss gärna feedback
          </Typography>
        </Box>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ mt: 2 }}>
        {open && <ContactForm email={email} autofocus={true} feedbackDialog={true} />}
      </DialogContent>
    </Dialog>
  );
});

export default FeedbackDialog;
