import React, { useState, useEffect, useRef } from 'react';
import { db } from './firebase';
import { addDoc, collection } from 'firebase/firestore';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ProgressBar from './ProgressBar';
import GenerateIcon from '@mui/icons-material/AutoAwesome';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import AddIcon from '@mui/icons-material/Add';
import { ThemeSelectorFlow } from './ThemeSelectorFlow';
import { trackLesson } from './functions';
import {
  Alert,
  FormControl,
  Input,
  Snackbar,
  Collapse,
  Grid,
  Tooltip,
  Fade,
  InputAdornment,
  Slider,
} from '@mui/material';
import { Link as LinkIcon } from '@mui/icons-material';
import axios from 'axios';
import SettingsIcon from '@mui/icons-material/Settings';
import mammoth from 'mammoth';
import PublicIcon from '@mui/icons-material/Public';
import LockIcon from '@mui/icons-material/Lock';
import LanguageSelector from './LanguageSelector';
import Logger from './Logger';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { East as ArrowIcon } from '@mui/icons-material';
import { decreaseTokens } from './functions';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import WikipediaIcon from './WikipediaIcon';
import TopicIcon from '@mui/icons-material/Subject';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import { fetchChats, fetchConversations } from './messageFunctions';
import { MenuItem, Select } from '@mui/material';
import AdditionalFlashFields from './AdditionalFlashFields';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { getStyles } from '../index';
import Suggestions from './Suggestions';
import { callApi } from './api';
import LoadingSpinner from './LoadingSpinner';
import { useSnackbar } from './SnackbarHandler';
import { AIModelSelect } from './TestComponents2';
import FilterListIcon from '@mui/icons-material/FilterList';
import IdeaGenerator from './IdeaGenerator';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import WordIcon from './Icons/WordIcon';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { sanitizeInput } from './functions';

import MyFlashcards from './flashcards/MyFlashcards';

const cheerio = require('cheerio');

import {
  AutoStories,
  Castle,
  Construction,
  EmojiEvents,
  Psychology,
  Science,
  School,
  Biotech,
  AutoGraph,
  Language,
  Cases,
  MenuBook,
  Psychology as PsychologyIcon,
  Functions,
} from '@mui/icons-material';

const FlashcardsCreateView = ({ user, onCollectionCreated, isDark, startTraining }) => {
  const [collectionName, setCollectionName] = useState('');
  const [flashcards, setFlashcards] = useState([{ front: '', back: '', correct: 'false' }]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [importString, setImportString] = useState('');
  const [showImport, setShowImport] = useState(false);
  const [showAIInput, setShowAIInput] = useState(true); // State to toggle AI input box
  const [aiInput, setAIInput] = useState(''); // State to store AI input
  const [inputType, setInputType] = useState('subject'); // State to manage which radio button is selected
  const [subjectInput, setSubjectInput] = useState('');
  const [textInput, setTextInput] = useState('');
  const [wikiUrlInput, setWikiUrlInput] = useState('');
  const [conversationTypes, setConversationTypes] = useState([]);
  const [selectedConversationType, setSelectedConversationType] = useState('Välj konversation');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isPublic, setIsPublic] = useState(true);
  const [viewIsLoading, setViewIsLoading] = useState(true);
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState('');
  const [selectedModel, setSelectedModel] = useState('Studera.AI');
  const [selectedSuggestion, setSelectedSuggestion] = useState('');
  const selectedSuggestionRef = useRef('');
  const [progress, setProgress] = useState(0);
  const progressIntervalRef = useRef(null);

  const [visibility, setVisibility] = useState('public');
  const collectionNameRef = useRef(null);
  const theme = useTheme();
  const styles = getStyles(theme);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [numFlashcards, setNumFlashcards] = useState(10);
  const [category, setCategory] = useState('Välj kategori');
  const [language, setLanguage] = useState('svenska');
  const [difficulty, setDifficulty] = useState('Medel');
  const [aiInstructions, setAiInstructions] = useState('');

  const [loadingSaving, setLoadingSaving] = useState(false);
  const [characterCount, setCharacterCount] = useState(5000);
  const { showSnackbar } = useSnackbar();

  const subjectInputRef = useRef(null);
  const textInputRef = useRef(null);
  const wikiUrlInputRef = useRef(null);

  const [renderedFlashcards, setRenderedFlashcards] = useState([]);
  const [renderInterval, setRenderInterval] = useState(null);

  const progressRef = useRef(null);
  const progressPercentRef = useRef(null);

  const [characterCountYouTube, setCharacterCountYouTube] = useState(5000);

  const youtubeUrlInputRef = useRef(null);

  const location = useLocation();

  const [customTheme, setCustomTheme] = useState('');
  const [lessonTheme, setLessonTheme] = useState('');
  const [predefinedTheme, setPredefinedTheme] = useState(null);

  const transcriptRef = useRef('');
  const uploadedFileRef = useRef('');

  const wikipediaContentRef = useRef('');
  const randomizedContentRef = useRef('');
  const coursePlanContentRef = useRef('');

  useEffect(() => {
    Logger.log('lessonTheme: ', lessonTheme);
  }, [lessonTheme]);

  useEffect(() => {
    Logger.log('predefinedTheme: ', predefinedTheme);
  }, [predefinedTheme]);

  useEffect(() => {
    Logger.log('customTheme: ', customTheme);
  }, [customTheme]);

  useEffect(() => {
    Logger.log('youtubeTranscript: ', transcriptRef.current);
  }, [transcriptRef.current]);

  useEffect(() => {
    Logger.log('wikipediaContentRef: ', wikipediaContentRef.current);
  }, [wikipediaContentRef.current]);
  useEffect(() => {
    Logger.log('uploadedFileRef: ', uploadedFileRef.current);
  }, [uploadedFileRef.current]);

  useEffect(() => {
    Logger.log('randomizedContentRef: ', randomizedContentRef.current);
  }, [randomizedContentRef.current]);

  useEffect(() => {
    Logger.log('coursePlanContentRef: ', coursePlanContentRef.current);
  }, [coursePlanContentRef.current]);

  const additionalThemes = [
    { type: 'subheader', label: 'Teman som lämpar sig extra bra för flashcards:' },

    {
      value: 'battles',
      label: 'Stora slag i historien',
      content:
        "Skapa flashcards om avgörande slag i världshistorien. På framsidan ska slagets namn stå (t.ex. 'Slaget vid Waterloo', 'Slaget vid Stalingrad'). På baksidan ska årtalet, platsen och utgången av slaget anges, samt en kort beskrivning av dess historiska betydelse och de viktigaste ledarna involverade.",
      Icon: EmojiEvents,
    },
    {
      value: 'leaders',
      label: 'Historiska ledare',
      content:
        "Skapa flashcards om inflytelserika historiska ledare. På framsidan ska ledarens namn stå (t.ex. 'Elizabeth I', 'Mahatma Gandhi'). På baksidan ska ledarens levnadstid anges, tillsammans med deras viktigaste prestationer, reformer eller beslut, samt deras påverkan på sin tids samhälle och efterföljande generationer.",
      Icon: Castle,
    },
    {
      value: 'revolutions',
      label: 'Revolutioner',
      content:
        "Skapa flashcards om betydelsefulla revolutioner. På framsidan ska revolutionens namn stå (t.ex. 'Franska revolutionen', 'Ryska revolutionen'). På baksidan ska revolutionens tidsperiod anges, tillsammans med dess huvudsakliga orsaker, viktiga händelser under revolutionen, och dess kort- och långsiktiga konsekvenser för samhället och världspolitiken.",
      Icon: Construction,
    },
    {
      value: 'dynasties',
      label: 'Dynastier',
      content:
        "Skapa flashcards om historiska dynastier. På framsidan ska dynastins namn stå (t.ex. 'Tudordynastin', 'Mingdynastin'). På baksidan ska dynastins tidsperiod och grundare anges, tillsammans med några av dess mest betydande härskare och deras prestationer, samt dynastins övergripande påverkan på landets historia och kultur.",
      Icon: Castle,
    },
    {
      value: 'presocratic',
      label: 'Försokratiska filosofer',
      content:
        'Skapa flashcards om försokratiska filosofer. På framsidan, skriv filosofens namn. På baksidan, inkludera deras huvudsakliga bidrag till filosofin, tidsperiod de var aktiva, och eventuella kända citat eller koncept de introducerade. Täck filosofer som Thales, Anaximander, Herakleitos, och Pythagoras.',
      Icon: School,
    },
    {
      value: 'plato',
      label: 'Platons dialoger',
      content:
        "Utforma flashcards för Platons viktiga dialoger. På framsidan, ange dialogens titel. På baksidan, beskriv huvudtemat, lista viktiga karaktärer (särskilt Sokrates roll), och inkludera centrala filosofiska frågor som diskuteras. Inkludera dialoger som 'Republiken', 'Symposion', och 'Faidon'.",
      Icon: MenuBook,
    },
    {
      value: 'aristotle',
      label: 'Aristoteles kategorier',
      content:
        'Skapa flashcards för Aristoteles tio kategorier. På framsidan, skriv kategorinamnet. På baksidan, ge en koncis definition, ett tydligt exempel, och förklara kortfattat hur kategorin relaterar till Aristoteles metafysik. Inkludera alla tio kategorier som substans, kvantitet, och kvalitet.',
      Icon: Cases,
    },
    {
      value: 'stoic',
      label: 'Stoiska dygder',
      content:
        'Utforma flashcards om de fyra kardinaldygderna inom stoicismen. På framsidan, ange dygdens namn. På baksidan, förklara dess innebörd inom stoisk filosofi, ge ett praktiskt exempel på hur den kan tillämpas, och nämn en framstående stoisk filosof som betonade denna dygd. Inkludera visdom, rättvisa, mod, och måttfullhet.',
      Icon: Psychology,
    },
    {
      value: 'fractals',
      label: 'Fraktalgeometri',
      content:
        'Skapa flashcards om fraktalgeometri. Skriv namnet på en känd fraktal på framsidan. På baksidan, beskriv fraktalens egenskaper, såsom dess dimensionalitet och självlikhet. Inkludera information om hur fraktalen genereras matematiskt och vem som först beskrev eller upptäckte den. Ge exempel på hur fraktalen används inom vetenskap, konst eller andra områden, samt en kort förklaring av dess betydelse inom fraktalgeometri.',
      Icon: AutoGraph,
    },
    {
      value: 'spanish_idioms',
      label: 'Vanliga spanska idiom',
      content:
        'Skapa flashcards om spanska idiom. På framsidan, visa ett spanskt idiom. På baksidan, ge dess betydelse, bokstavliga översättning och ett användningsexempel.',
      Icon: Language,
    },
    {
      value: 'cell_organelles',
      label: 'Cellens organeller',
      content:
        'Skapa flashcards om cellens olika organeller. På framsidan, skriv namnet på en organell (t.ex. mitokondrie, endoplasmatiskt retikulum, Golgiapparat). På baksidan, beskriv organellens huvudfunktion i cellen samt dess struktur och placering i förhållande till andra organeller.',
      Icon: Biotech,
    },
    {
      value: 'literary_movements',
      label: 'Litterära rörelser',
      content:
        'Utforma flashcards med namnet på en litterär rörelse på framsidan. På baksidan, ange rörelsens tidsperiod, dess huvudsakliga filosofiska eller estetiska principer, minst tre kännetecken för litteraturen inom rörelsen, nämn tre betydande författare associerade med rörelsen, samt ett betydelsefullt verk som exemplifierar rörelsen.',
      Icon: MenuBook,
    },
    {
      value: 'neuroplasticity',
      label: 'Neuroplasticitet',
      content:
        'Skapa flashcards om neuroplasticitet. På framsidan, skriv definitioner och grundmekanismer (t.ex. synaptisk plasticitet, kortikal omorganisation, neurogenes). På baksidan, ge detaljerade förklaringar av mekanismerna, beskriv hur de bidrar till hjärnans förmåga att förändras och anpassa sig, diskutera deras betydelse för inlärning, minne och återhämtning efter hjärnskador, och ge exempel på hur kunskap om neuroplasticitet tillämpas inom neurovetenskap, psykologi och rehabiliteringsmedicin.',
      Icon: Psychology,
    },
    {
      value: 'bv_formalism',
      label: 'Batalin-Vilkovisky-formalism',
      content:
        'Skapa flashcards om Batalin-Vilkovisky-formalismen. På framsidan, ange de grundläggande principerna och definiera antifields. På baksidan, förklara dess användning i kvantisering av gaugeteorier, särskilt för teorier med öppna algebror. Diskutera relationen till BRST-formalismen och hur BV-formalismen hanterar mer komplexa gaugestrukturer.',
      Icon: Functions,
    },

    {
      value: 'iliad',
      label: 'Iliaden',
      content:
        'Utforma en serie flashcards som täcker hela Iliadens epos. Framsidan ska visa viktiga karaktärer, gudar, händelser eller koncept. Baksidan ska ge detaljer om deras roll och betydelse i berättelsen. Inkludera kort om Akilles, Hektor, Paris, Helena, grekiska och trojanska gudar, viktiga strider, och den trojanska hästen. Avsluta med kort om Homeros, eposets struktur, och dess betydelse för grekisk mytologi och litteratur.',
      Icon: MenuBook,
    },
  ];

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sourceParam = params.get('source');

    switch (sourceParam) {
      case 'youtube':
        setInputType('youtube');
        break;
      case 'wiki':
        setInputType('wiki-url');
        break;
      case 'word':
        setInputType('text');
        break;
      case 'topic':
        setInputType('subject');
        break;
      default:
        setInputType('subject');
        break;
    }

    // Optionally, update the URL to remove the query parameter
    // navigate('/app/quiz', { replace: true });
  }, [location]);

  const fetchYoutubeTranscript = async (url, maxLength = 5000) => {
    try {
      console.log('Attempting to fetch transcript for URL:', url);
      const response = await axios.post('http://localhost:3001/api/youtube-transcript', { url });

      console.log('Server response:', response);
      if (response.status !== 200) {
        throw new Error(`Failed to fetch transcript. Status: ${response.status}`);
      }

      // Limit the transcript to maxLength characters
      const fullTranscript = response.data.transcript;
      return fullTranscript.length > maxLength
        ? fullTranscript.slice(0, maxLength)
        : fullTranscript;
    } catch (error) {
      console.error('Detailed error in fetchYoutubeTranscript:', error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error('Error response data:', error.response.data);
        console.error('Error response status:', error.response.status);
        console.error('Error response headers:', error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        console.error('Error request:', error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error message:', error.message);
      }
      showSnackbar(
        'Kunde inte hämta YouTube-transkript. Kontrollera URL:en och försök igen.',
        'error'
      );
      return '';
    }
  };

  const handleIdeaGenerated = idea => {
    subjectInputRef.current.value = idea;
  };
  const handleWikiIdeaGenerated = idea => {
    wikiUrlInputRef.current.value = idea;
  };

  const updateProgress = value => {
    if (progressRef.current) {
      progressRef.current.style.width = `${value}%`;
    }
    if (progressPercentRef.current) {
      progressPercentRef.current.textContent = `${Math.round(value)}%`;
    }
  };
  useEffect(() => {
    if (flashcards.length > 1) {
      setTimeout(() => {
        const addMore = document.querySelector('.addMore');
        if (addMore) {
          addMore.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
          });
        }
      }, 500);
    }
  }, [flashcards]);

  useEffect(() => {
    return () => {
      if (renderInterval) {
        clearInterval(renderInterval);
      }
    };
  }, [renderInterval]);

  useEffect(() => {
    setViewIsLoading(false);
  }, []);

  if (viewIsLoading) {
    return <LoadingSpinner />;
  }

  // useEffect(() => {
  //   Logger.log("Theme mode: ", theme.palette.mode);
  // });

  //     useEffect(() => {

  //             Logger.log("isDark: ", isDark);

  //     }, [isDark]);

  const handlePublicChange = event => {
    const newVisibility = event.target.checked ? 'public' : 'private';
    setVisibility(newVisibility);
    setIsPublic(newVisibility === 'public');
  };

  const handleCollectionNameChange = e => {
    setCollectionName(e.target.value);
  };
  // const fetchConversationTypes = async () => {
  //   const q = query(collection(db, 'conversations'), where('userId', '==', user.uid));
  //   const querySnapshot = await getDocs(q);
  //   const conversationsData = querySnapshot.docs.map(doc => doc.data().conversationType);
  //   const uniqueTypes = [...new Set(conversationsData)];
  //   setConversationTypes(uniqueTypes);
  // };

  const handleSelectYouTube = selected => {
    setInputType('youtube'), setCollectionName(selected.text);
    setFlashcards([{ front: '', back: '', correct: 'false' }]);

    if (selected.text) {
      youtubeUrlInputRef.current.value = selected.value;
    }

    if (youtubeUrlInputRef.current) {
      youtubeUrlInputRef.current.value = selected.value;
    }
  };

  const handleSelectWiki = selected => {
    setInputType('wiki-url'), setCollectionName(selected.text);

    setFlashcards([{ front: '', back: '', correct: 'false' }]);

    if (selected.text) {
      wikiUrlInputRef.current.value = selected.value;
    }

    if (wikiUrlInputRef.current) {
      wikiUrlInputRef.current.value = selected.value;
    }
  };

  const handleSelect = selected => {
    Logger.log('Selected text:', selected.text);

    setFlashcards([{ front: '', back: '', correct: 'false' }]);

    if (selected.value) {
      let hej = selected.text + ' - ' + selected.value;

      selectedSuggestionRef.current = hej;

      Logger.log('selectedSuggestion: ', selectedSuggestion);
      setInputType('subject');
      // setSubjectInput(selected.text);
      setCollectionName(selected.text);

      if (subjectInputRef.current) {
        subjectInputRef.current.value = selected.value;
      }

      Logger.log('Selected value:', selected.value);
      // Use the background value if it exists
    } else {
      // Use the text if there's no background value
      Logger.log('No background value, using text:', selected.text);
    }
    // Do something with the selected suggestion
  };

  const handleChatSelect = event => {
    const chatId = event.target.value;
    setSelectedChat(chatId);

    if (chatId) {
      setIsLoading(true);
      Logger.log('Fetching conversations for chat:', chatId);

      // Hitta den valda chatten för att få conversationType
      const selectedChatData = chats.find(chat => chat.id === chatId);

      if (selectedChatData) {
        fetchConversations(
          user,
          fetchedMessages => {
            Logger.log('Fetched messages:', fetchedMessages);
            if (fetchedMessages && fetchedMessages.length > 0) {
              const messagesText = fetchedMessages.map(msg => msg.content).join('\n');
              setTextInput(messagesText);
            } else {
              Logger.log('No messages found for this chat');
              setTextInput('Inga meddelanden hittades för denna chatt.');
            }
            setIsLoading(false);
          },
          selectedChatData.conversationType,
          chatId
        );
      } else {
        console.error('Selected chat not found in chats array');
        setError('Den valda chatten kunde inte hittas.');
        setIsLoading(false);
      }
    }
  };

  const handleInputTypeChange = (event, newInputType) => {
    if (newInputType !== null) {
      setInputType(newInputType);
      if (newInputType === 'chattar') {
        loadChats();
        //setTextInput('');
      } else {
        setSelectedChat('');
        setTextInput('');
      }
    }
  };

  const loadChats = () => {
    setIsLoading(true);
    Logger.log('Loading chats for user:', user.uid);

    // Hämta chattar för alla conversationTypes
    Promise.all([
      fetchChats(user, 'chat'),
      fetchChats(user, 'history'),
      fetchChats(user, 'spanish'),
    ])
      .then(results => {
        const allChats = results.flat();
        Logger.log('Fetched chats:', allChats);
        setChats(allChats);
        if (allChats.length === 0) {
          Logger.log('No chats found for this user.');
          setError('Inga chattar hittades för denna användare.');
        }
      })
      .catch(error => {
        console.error('Error fetching chats:', error);
        setError('Ett fel uppstod vid hämtning av chattar.');
      })
      .finally(() => setIsLoading(false));
  };

  const handleFileChange = async event => {
    Logger.log('handleFileChange körs');
    const file = event.target.files[0];
    if (file) {
      Logger.log('handleFileChange2 körs');
      const reader = new FileReader();
      reader.onload = async e => {
        const arrayBuffer = e.target.result;
        try {
          const result = await mammoth.extractRawText({ arrayBuffer });
          Logger.log('handleFileChange3 körs ', result.value);
          textInputRef.current.value = result.value;
          setTextInput(result.value);
        } catch (error) {
          setError('Fel vid läsning av Word-fil: ' + error.message);
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleFrontChange = (index, e) => {
    const newFlashcards = [...flashcards];
    newFlashcards[index].front = e.target.value;
    setFlashcards(newFlashcards);
  };

  const handleBackChange = (index, e) => {
    const newFlashcards = [...flashcards];
    newFlashcards[index].back = e.target.value;
    setFlashcards(newFlashcards);
  };

  const handleAddCard = (index = -1) => {
    const newFlashcards = [...flashcards];
    newFlashcards.splice(index + 1, 0, { front: '', back: '', correct: 'false' });
    setFlashcards(newFlashcards);
    setTimeout(() => {
      document.getElementById(`front-${index + 1}`).focus();
    }, 0);
  };

  const handleDeleteCard = index => {
    const newFlashcards = flashcards.filter((_, i) => i !== index);
    setFlashcards(newFlashcards);
  };

  const handleTabPress = (index, isBack, e) => {
    if (e.key === 'Tab' && !e.shiftKey) {
      e.preventDefault();
      if (isBack) {
        if (index === flashcards.length - 1) {
          handleAddCard(index);
        } else {
          document.getElementById(`front-${index + 1}`).focus();
        }
      } else {
        document.getElementById(`back-${index}`).focus();
      }
    }
  };

  const handleCorrectChange = (index, isCorrect) => {
    const newFlashcards = [...flashcards];
    newFlashcards[index].correct = isCorrect;
    setFlashcards(newFlashcards);
  };

  const handleSubmit = async () => {
    if (!collectionName.trim()) {
      showSnackbar('Samlingens namn får inte vara tomt.', 'info');
      collectionNameRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      return;
    }
    if (flashcards.some(card => !card.front.trim() || !card.back.trim())) {
      showSnackbar('Inga fält för framsida eller baksida får vara tomma.', 'info');
      return;
    }

    setLoadingSaving(true);
    setMessage('');
    let userId = user.uid;
    Logger.log('userId flashcard create: ', userId);

    try {
      // Sanitize the collection name
      const sanitizedCollectionName = sanitizeInput(collectionName);

      // Sanitize the flashcards
      const sanitizedFlashcards = flashcards.map(card => ({
        front: sanitizeInput(card.front),
        back: sanitizeInput(card.back),
        correct: card.correct,
      }));

      // Sanitize other string fields
      const sanitizedCategory = sanitizeInput(category);
      const sanitizedLanguage = sanitizeInput(language);
      const sanitizedDifficulty = sanitizeInput(difficulty);
      const sanitizedAiInstructions = sanitizeInput(aiInstructions);
      const sanitizedSelectedModel = sanitizeInput(selectedModel);

      const collectionRef = await addDoc(collection(db, 'flashcardCollections'), {
        userId,
        collectionName: sanitizedCollectionName,
        flashcards: sanitizedFlashcards,
        isPublic,
        createdAt: Date.now(),
        numFlashcards,
        category: sanitizedCategory,
        language: sanitizedLanguage,
        difficulty: sanitizedDifficulty,
        aiInstructions: sanitizedAiInstructions,
        selectedModel: sanitizedSelectedModel,
        lastTrainingSession: Date.now(),
        upvotes: 0,
        downvotes: 0,
        userVotes: {},
      });

      const newCollection = {
        id: collectionRef.id,
        userId,
        collectionName: sanitizedCollectionName,
        flashcards: sanitizedFlashcards,
        isPublic,
      };

      //      const collectionRef = await addDoc(collection(db, 'flashcardCollections'), {
      //        userId,
      //        collectionName,
      //        flashcards,
      //        isPublic,
      //        createdAt: Date.now(),
      //        numFlashcards,
      //        category,
      //        language,
      //        difficulty,
      //        aiInstructions,
      //        selectedModel,
      //              lastTrainingSession: Date.now(),
      //      upvotes: 0,
      //      downvotes: 0,
      //      userVotes: {}

      //      });

      // const newCollection = {
      //      id: collectionRef.id,
      //      userId,
      //      collectionName,
      //      flashcards,
      //      isPublic
      //    };

      onCollectionCreated(newCollection);
      showSnackbar(`Samlingen har skapats framgångsrikt!`, 'success');
      setCollectionName('');
      setFlashcards([{ front: '', back: '', correct: 'false' }]);

      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }, 50);
    } catch (error) {
      showSnackbar('Fel vid skapande av samling: ' + error.message, 'info');
    } finally {
      setLoadingSaving(false);
    }
  };

  const handleImportChange = e => {
    setImportString(e.target.value);
  };

  const handleImport = () => {
    try {
      const importArray = JSON.parse(`[${importString.replace(/(\w+):/g, '"$1":')}]`);

      const formattedFlashcards = importArray.map(pair => {
        const [front, back] = Object.entries(pair)[0];
        return {
          front: front,
          back: back,
          correct: 'false',
        };
      });

      setFlashcards(formattedFlashcards);
      setShowImport(false);
    } catch (error) {
      setError('Fel vid importering: ' + error.message);
    }
  };

  const handleAIInputChange = e => {
    if (inputType === 'subject') {
      setSubjectInput(subjectInputRef.current.value);
    } else if (inputType === 'text') {
      setTextInput(e.target.value);
    } else if (inputType === 'wiki-url') {
      setWikiUrlInput(e.target.value);
    }
  };

  const fetchWikipediaContent = async (url, maxLength = 5000) => {
    const pageTitle = url.split('/').pop();
    const lang = url.includes('sv.wikipedia.org') ? 'sv' : 'en';
    try {
      const response = await axios.get(
        `https://${lang}.wikipedia.org/api/rest_v1/page/html/${pageTitle}`
      );
      const $ = cheerio.load(response.data);
      let content = '';
      $('p, ul, ol').each((i, element) => {
        if (content.length < maxLength) {
          if (element.name === 'p') {
            content += $(element).text() + '\n\n';
          } else if (element.name === 'ul' || element.name === 'ol') {
            $(element)
              .find('li')
              .each((j, li) => {
                content += '• ' + $(li).text() + '\n';
              });
            content += '\n';
          }
        } else {
          return false; // Stop the .each() loop when maxLength is reached
        }
      });
      content = content.substring(0, maxLength); // Ensure we don't exceed maxLength
      Logger.log('Extracted text: ', content);
      return content;
    } catch (error) {
      // Error handling remains the same
      console.error('Error fetching Wikipedia content:', error);
      // ... rest of the error handling code ...
      return '';
    }
  };

  const simulateProgress = (startProgress, endProgress, duration) => {
    const stepSize = 0.5;
    const steps = (endProgress - startProgress) / stepSize;
    const stepDuration = duration / steps;
    let currentProgress = startProgress;

    const intervalId = setInterval(() => {
      currentProgress += stepSize;
      if (currentProgress >= endProgress) {
        clearInterval(intervalId);
      } else {
        setProgress(currentProgress);
      }
    }, stepDuration);

    return () => clearInterval(intervalId);
  };

  const handleGenerateFlashcards = async () => {
    setProgress(0);
    setLoading(true);
    setMessage('');
    setFlashcards([]);

    setTimeout(() => {
      const progressBar = document.querySelector('.progress-bar-container');
      if (progressBar) {
        progressBar.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 500);

    Logger.log('inputType ', inputType);
    Logger.log('aiInput ', aiInput);

    Logger.log('Generating flashcards with:', {
      numFlashcards,
      category,
      language,
      difficulty,
      aiInstructions,
    });

    let userInput;

    if (lessonTheme === 'youtube') {
      userInput = transcriptRef.current;
    } else if (lessonTheme === 'customTheme') {
      userInput = customTheme;
    } else if (lessonTheme === 'predefined') {
      userInput = predefinedTheme;
    } else if (lessonTheme === 'fileUpload') {
      userInput = uploadedFileRef.current;
    } else if (lessonTheme === 'wikipedia') {
      userInput = wikipediaContentRef.current;
    } else if (lessonTheme === 'randomizer') {
      userInput = randomizedContentRef.current;
    } else {
      //
      userInput = coursePlanContentRef.current;
    }

    Logger.log('themeToUse: ', userInput);
    let stopProgressSimulation = null;
    try {
      Logger.log('Selected model:', selectedModel);
      stopProgressSimulation = simulateProgress(0, 90, 15000);
      let diff =
        difficulty === 'Svårt' ? 'Extremt svåra flashcards, ytterst sällsynta.' : difficulty;

      const messages = [
        {
          role: 'system',
          content: `Generate EXACTLY ${numFlashcards} flashcards with the following format with "front" and "back" fields based on the users input. Everything should be in ${language}. Difficulty level: ${difficulty}. ${aiInstructions}. Follow this exact format every time: [{ "front": "question", "back": "answer" }, { "front": "question2", "back": "answer2" }].`,
        },
        { role: 'user', content: userInput },
      ];
      const response = await callApi(messages, undefined, true, user.uid);

      Logger.log('response: ', response);

      let messageContent = response.data.choices[0].message.content.trim();
      Logger.log('messageContent', messageContent);
      if (messageContent.startsWith('```json')) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith('```')) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      // Kontrollera om JSON-strukturen är korrekt
      const parsedFlashcards = JSON.parse(messageContent);
      Logger.log('parsedFlashcards', parsedFlashcards);

      if (!Array.isArray(parsedFlashcards)) {
        throw new Error('The response format is incorrect. Expected an array of flashcards.');
      }

      if (stopProgressSimulation) {
        stopProgressSimulation();
      }

      setProgress(100);

      // Se till att varje objekt har rätt nycklar

      const formattedFlashcards = parsedFlashcards.map(card => {
        if (card.front && card.back) {
          return {
            front: card.front,
            back: card.back,
            correct: 'false',
          };
        } else {
          throw new Error(
            'Flashcard format is incorrect. Each card must have "front" and "back" fields.'
          );
        }
      });

      //setFlashcards(formattedFlashcards);

      let index = 0;
      for (let i = 0; i < formattedFlashcards.length; i++) {
        await new Promise(resolve => setTimeout(resolve, 100)); // 100ms fördröjning
        setFlashcards(prev => [...prev, formattedFlashcards[i]]);
      }

      //setRenderInterval(interval);

      await trackLesson(user.uid, 'Flashcards.js', null, {
        lessonTheme: lessonTheme,
      });

      setAIInput('');

      selectedSuggestionRef.current = '';
    } catch (error) {
      showSnackbar('Fel vid generering av flashcards. Vänligen klicka på Generera igen. ', 'info');
      setProgress(0);
    } finally {
      setLoading(false);
      setProgress(0);
    }
  };

  return (
    <>
      <Box sx={styles.container}>
        <Box sx={{ ...styles.innerContainer }}>
          {error && (
            <Snackbar
              open={!!error}
              onClose={() => setError(null)}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              autoHideDuration={4000} // Optional: Automatically hides the alert after 6 seconds
            >
              <Alert
                onClose={() => setError(null)}
                severity='info'
                sx={{ width: '100%' }} // Ensures the Alert takes full width of the Snackbar
              >
                {` ${error}`}
              </Alert>
            </Snackbar>
          )}

          <Box sx={{ width: { xs: '100%', md: '90%' }, margin: '0 auto' }}>
            <ThemeSelectorFlow
              value={lessonTheme}
              onChange={e => setLessonTheme(e.target.value)}
              customTheme={customTheme}
              onCustomThemeChange={e => setCustomTheme(e.target.value)}
              onPredefinedThemeChange={e => setPredefinedTheme(e.target.value)}
              onYoutubeTranscriptChange={transcriptContent => {
                Logger.log('onYoutubeTranscriptChange körs med: ', transcriptContent);
                transcriptRef.current = transcriptContent;
              }}
              onFileUpload={content => {
                Logger.log('onFileUpload körs med: ', content);
                uploadedFileRef.current = content;
              }}
              onWikipediaContentChange={content => {
                Logger.log('Parent: Wikipedia content received', content.substring(0, 100));
                wikipediaContentRef.current = content;
              }}
              onRandomizedContentChange={content => {
                Logger.log('randomizedContentRef', content.substring(0, 100));
                randomizedContentRef.current = content;
              }}
              onCoursePlanContentChange={content => {
                Logger.log('onCoursePlanChange', content.substring(0, 100));
                coursePlanContentRef.current = content;
              }}
              lessonType='Flashcards'
              user={user}
              theme={theme}
              styles={styles}
              additionalThemes={additionalThemes}
              forFlashcards={true}
            />
          </Box>

          <Box sx={styles.headlineLessonBox}>
            <Typography variant='h6' sx={styles.headlineLessonBoxTitle}>
              2. Generera flashcards
            </Typography>
          </Box>

          {showAIInput && (
            <Box
              sx={{
                ...styles.darkBorder,
                backgroundColor: isDark === true ? '#212c36' : 'white',
                border: theme.palette.mode === 'dark' ? '1px solid #263442' : '1px solid #e0e0e091',
                borderRadius: { xs: '0px', md: '8px' },
                width: { xs: '100%', md: '90%' },
                margin: { xs: undefined, md: '0 auto' },
                padding: '30px',
              }}
            >
              <Grid container spacing={5}>
                <Grid item xs={12} md={6}>
                  <Typography variant='formLabelSinUnderline' sx={{ display: 'block' }}>
                    Namn på din samling
                  </Typography>

                  <TextField
                    ref={collectionNameRef}
                    placeholder='Namn på samlingen'
                    value={collectionName}
                    onChange={handleCollectionNameChange}
                    margin='normal'
                    fullWidth
                    sx={{ mt: 1 }}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography variant='formLabelSinUnderline' sx={{ display: 'block' }}>
                    Språk
                  </Typography>
                  <Box sx={{ mt: 1 }}>
                    <LanguageSelector language={language} setLanguage={setLanguage} />
                  </Box>
                </Grid>
              </Grid>

              <Collapse in={showMoreOptions}>
                <div style={{ marginTop: '20px' }}>
                  <AdditionalFlashFields
                    aiInstructions={aiInstructions}
                    setAiInstructions={setAiInstructions}
                    numFlashcards={numFlashcards}
                    setNumFlashcards={setNumFlashcards}
                    category={category}
                    setCategory={setCategory}
                    language={language}
                    setLanguage={setLanguage}
                    difficulty={difficulty}
                    setDifficulty={setDifficulty}
                    visibility={visibility}
                    handleVisibilityChange={handlePublicChange}
                    theme={theme}
                    selectedModel={selectedModel}
                    setSelectedModel={setSelectedModel}
                  />
                </div>
              </Collapse>

              <Box
                sx={{ display: 'flex', gap: 2, mt: 7, justifyContent: 'center' }}
                className='progress-bar-container'
              >
                <Button
                  onClick={handleGenerateFlashcards}
                  variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                  color='primary'
                  disabled={loading}
                  startIcon={
                    !loading ? (
                      <GenerateIcon style={{ color: '#fff' }} />
                    ) : (
                      <CircularProgress size={18} />
                    )
                  }
                >
                  Generera flashcards
                </Button>
                <Button
                  variant='print'
                  startIcon={showMoreOptions ? <ExpandLessIcon /> : <SettingsIcon />}
                  onClick={() => setShowMoreOptions(!showMoreOptions)}
                >
                  {showMoreOptions ? 'Dölj inställningar' : 'Inställningar'}
                </Button>
              </Box>

              {/*
<Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
  <Button
    onClick={() => setShowImport(!showImport)}
    variant="contained"
    color="primary"
    startIcon={<FileUploadIcon />}
    sx={{opacity:'0.5', "&:hover": {opacity:'1'}}}
  >
    Importera JSON
  </Button>
</Box>

*/}
              <Box sx={{ display: 'flex', gap: 2 }}>
                {showImport && (
                  <Box
                    sx={{
                      ...styles.darkBorder,
                      backgroundColor: isDark === true ? '#15202B' : 'white',
                      border: isDark ? 'none' : '',
                    }}
                  >
                    <Typography variant='h3' sx={styles.h3}>
                      Importera JSON
                    </Typography>
                    <TextField
                      label='JSON-sträng'
                      value={importString}
                      onChange={handleImportChange}
                      fullWidth
                      multiline
                      rows={4}
                      margin='normal'
                      sx={{ backgroundColor: isDark ? '#212c36' : 'white' }}
                      helperText={`Exempel: {"casa": "hus"}, {"gato": "katt"}, {"perro": "hund"}`}
                    />

                    <Button
                      variant='contained'
                      color='secondary'
                      onClick={handleImport}
                      sx={{ mt: 2, mb: 2, minWidth: 'auto' }}
                      startIcon={<ArrowCircleDownIcon />}
                    >
                      Importera kort
                    </Button>
                  </Box>
                )}
              </Box>

              {loading && (
                <>
                  <Box sx={{ mt: 6 }}>
                    <ProgressBar progress={progress} label='Genererar flascards...' />
                  </Box>
                </>
              )}
            </Box>
          )}

          {flashcards.length === 0 && !loading && (
            <Button
              variant='contained'
              color='primary'
              onClick={() => handleAddCard()}
              sx={{ mt: 2, mb: 2, width: '170px', display: 'none' }}
              startIcon={<AddIcon />}
            >
              Lägg till kort
            </Button>
          )}

          <Box sx={{ mt: 5 }}>
            {flashcards.map((flashcard, index) => (
              <Fade in={true} key={index} timeout={50} style={{ position: 'static' }}>
                <Box
                  key={index}
                  sx={{
                    mb: 2,
                    display: 'flex',
                    gap: 2,
                    alignItems: 'center',

                    width: { xs: '100%', md: '90%' },
                    margin: { xs: undefined, md: '0 auto' },
                  }}
                >
                  <TextField
                    id={`front-${index}`}
                    label='Framsida'
                    value={flashcard.front}
                    onChange={e => handleFrontChange(index, e)}
                    onKeyDown={e => handleTabPress(index, false, e)}
                    fullWidth
                    multiline
                    rows={3}
                    margin='normal'
                    sx={{
                      ...styles.flashcardFrontBackInput,
                      backgroundColor: isDark ? '#212c36' : 'white',
                      borderColor: isDark ? '#3b4651' : '',
                      fontWeight: '600',
                    }}
                  />
                  <TextField
                    id={`back-${index}`}
                    label='Baksida'
                    value={flashcard.back}
                    onChange={e => handleBackChange(index, e)}
                    onKeyDown={e => handleTabPress(index, true, e)}
                    fullWidth
                    multiline
                    rows={3}
                    margin='normal'
                    sx={{
                      ...styles.flashcardFrontBackInput,
                      backgroundColor: isDark ? '#212c36' : 'white',
                      borderColor: isDark ? '#3b4651' : '',
                      fontWeight: '600',
                    }}
                  />
                  <Tooltip title='Ta bort flashcard'>
                    <IconButton
                      onClick={() => handleDeleteCard(index)}
                      aria-label='delete'
                      color='text.main'
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Fade>
            ))}
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                gap: '12px',
                width: '100%',
              }}
            >
              <Button
                startIcon={<AddIcon />}
                variant='print'
                onClick={() => handleAddCard(flashcards.length - 1)}
                sx={{ mt: 4, mb: 4 }}
              >
                Lägg till flashcard
              </Button>
            </div>
          </Box>

          {flashcards.length > 0 && (
            <>
              <Box
                sx={{
                  width: { xs: '100%', md: '90%' },
                  margin: { xs: undefined, md: '0 auto' },
                }}
                className='addMore'
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '12px',
                    width: '100%',
                  }}
                >
                  <Button
                    variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                    color='primary'
                    onClick={handleSubmit}
                    disabled={loadingSaving}
                    sx={{ width: 'auto', marginBottom: '50px', mt: 4 }}
                    end={!loadingSaving ? <ArrowIcon /> : <CircularProgress size={18} />}
                  >
                    Starta träning <ArrowIcon sx={{ ml: 1 }} />
                  </Button>
                </div>
              </Box>
            </>
          )}
          {message && (
            <Typography variant='body1' color='textSecondary' sx={{ mt: 2 }}>
              {message}
            </Typography>
          )}
          <Box sx={{ mt: 12, width: { xs: '100%', md: '90%' }, margin: '0 auto' }}>
            <Box sx={styles.headlineLessonBox}>
              <Typography variant='h6' sx={{ ...styles.headlineLessonBoxTitle, mt: 15 }}>
                Mina flashcards
              </Typography>
            </Box>
            <Box sx={{ ...styles.freshBox }}>
              <MyFlashcards
                user={user}
                theme={theme}
                styles={styles}
                onStartTraining={startTraining}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default FlashcardsCreateView;
