export const teacherGuides = {
  argumentativeText: `
    <h1>Lärarhandledning för Argumenterande text med Cirkelmodellen</h1>
    
    <p>Denna handledning syftar till att guida dig genom processen att undervisa elever i att skriva argumenterande texter med hjälp av cirkelmodellen. Genom att följa denna modell kommer eleverna steg för steg att utveckla sina färdigheter i att skapa egna argumenterande texter.</p>

    <h2>Förberedelser</h2>
    <p>Innan du börjar, välj ut tre ämnen som är relevanta och engagerande för din elevgrupp. Exempel på sådana ämnen kan vara:</p>
    <ul>
      <li>Djurförsök</li>
      <li>Veckopeng från 10 år</li>
      <li>Sänka rösträtten till 16 år</li>
    </ul>

    <h2>Steg 1: Introduktion till Argumentation</h2>
    <p><strong>Exempel 1: Djurförsök</strong></p>
    <p>Börja med att presentera en text om djurförsök som innehåller både argument för och emot. Läs texten tillsammans med eleverna och diskutera argumenten. Uppmuntra eleverna att tänka kritiskt kring de presenterade argumenten och att uttrycka sina egna åsikter.</p>

    <h2>Steg 2: Utveckling av Motargument</h2>
    <p><strong>Exempel 2: Veckopeng</strong></p>
    <p>Presentera en text som endast innehåller argument för att ge veckopeng från 10 år. Eleverna ska nu själva komma på motargument. De som snabbt kommer på motargument skriver dessa i sina häften eller på datorn och delar dem med klassen genom att skriva på tavlan. Detta hjälper elever som har svårt att komma igång att använda sina klasskamraters argument som inspiration.</p>

    <h2>Steg 3: Självständig Argumentation</h2>
    <p><strong>Exempel 3: Rösträtt</strong></p>
    <p>Ge eleverna en text om rösträtt utan några färdiga argument. Låt dem själva komma på både för- och motargument. Denna övning uppmuntrar till självständigt tänkande och stärker deras förmåga att formulera egna åsikter.</p>

    <h2>Steg 4: Självständigt Arbete</h2>
    <p><strong>Exempel 4: Eget Ämnesval</strong></p>

    <p>Som en avslutande övning kan eleverna få välja ett eget ämne att skriva om. De ska samla fakta och formulera argument både för och emot ämnet. Detta steg ger eleverna möjlighet att tillämpa vad de lärt sig och utveckla sina skrivfärdigheter ytterligare.</p>

    <p>Genom att följa dessa steg hjälper du eleverna att utveckla en djupare förståelse för argumenterande skrivande och att bli mer självständiga i sina tankar och åsikter.</p>
  `,
  onTrackLesson: `
    <h1>Lektion - På spåret</h1>

    <p>Lektion "På spåret" är utformad för att hjälpa elever i årskurs 4-9 att öva på att skriva texter och förklara olika ämnen genom att utveckla och använda fler ord utan att direkt avslöja ämnet. Vi använder cirkelmodellen som grund för denna lektion.</p>

    <h2>Instruktioner för läraren</h2>

    <h3>Introduktion av ämnet</h3>
    <ul>
      <li>Välj ett ämne och presentera det för klassen utan att avslöja vad det är. Använd ledtrådar och beskrivningar som eleverna kan använda för att gissa ämnet.</li>
    </ul>

    <h3>Diskussion och analys</h3>
    <ul>
      <li>Diskutera med eleverna vad som utgör ledtrådar vid olika poängnivåer. Analysera tillsammans hur dessa ledtrådar hjälper till att avslöja ämnet eller hur de kan förbättras.</li>
    </ul>

    <h3>Elevaktivitet</h3>
    <ul>
      <li>Låt eleverna gissa ämnet baserat på ledtrådarna. När alla har gissat och alla ledtrådar har presenterats, låt eleverna välja ett eget ämne att skriva ledtrådar kring.</li>
    </ul>

    <h3>Individuellt arbete</h3>
    <ul>
      <li>Eleverna kan antingen få välja egna ämnen eller så kan du som lärare dela ut ämnen. Se till att eleverna antingen har förkunskaper om ämnet eller tillhandahåll material för att läsa in sig.</li>
    </ul>

    <h3>Grej of the Day</h3>
    <p>Använd "Grej of the Day" som en metod för att dela ut ämnen till varje elev, så att de får möjlighet att utforska och dela med sig av sina kunskaper till klassen.</p>

    <h3>Reflektion och feedback</h3>
    <p>Avsluta lektionen med att reflektera över processen och ge feedback på elevernas arbete. Diskutera vad som fungerade bra och vad som kan förbättras till nästa gång.</p>

    <p>Denna lektion syftar till att utveckla elevernas förmåga att tänka kritiskt och kreativt, samtidigt som de förbättrar sina skriv- och kommunikationsfärdigheter.</p>
  `,
};
