import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  Select,
  IconButton,
  Tooltip,
  MenuItem,
  Typography,
  Divider,
  TextField,
  CircularProgress,
  Grid,
  Avatar,
} from '@mui/material';
import GenerateIcon from '@mui/icons-material/AutoAwesome';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InfoBox from './InfoBox';
import { GiTeacher } from 'react-icons/gi';
import LanguageSelector from './LanguageSelector';
import { handlePrintQuiz } from './printFunctions';
import TeacherQuizDisplay from './TeacherQuizDisplay';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import ContentCutIcon from '@mui/icons-material/ContentCut';

import MenuBookIcon from '@mui/icons-material/MenuBook';

import SaveAsIcon from '@mui/icons-material/SaveAs';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import HoverableImage from './HoverableImage';
import { callApi } from './api'; // Adjust import path as needed
import GradeSelector, { gradeInstructions } from './GradeSelector';
import { useSnackbar } from './SnackbarHandler';

import ProgressBar from './ProgressBar'; // Import the ProgressBar component
import ImageGenerationModal from './ImageGenerationModal';

import Logger from './Logger';
import { FourSquare } from 'react-loading-indicators';

import PptxGenerator from './PptxGenerator';

import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import QuestionTypeSelector from './quiz/QuestionTypeSelector';
import ImageIcon from '@mui/icons-material/Image';

import QuizIcon from '@mui/icons-material/Quiz';
import EditIcon from '@mui/icons-material/Edit';
import { generateAndProcessImage, trackLesson } from './functions';

import { useSearchParams, useParams } from 'react-router-dom';
import { ThemeSelector } from './ThemeSelector';
import { ThemeSelectorFlow } from './ThemeSelectorFlow';
import PrintIcon from '@mui/icons-material/Print';

import LessonDrawer from './LessonDrawer';
import SaveLessonDialog from './SaveLessonDialog';

import HistoryIcon from '@mui/icons-material/History';

import { db } from './firebase';
import { collection, query, where, limit, getDocs } from 'firebase/firestore';

import {
  Science as ScienceIcon,
  Castle as CastleIcon,
  Psychology as PsychologyIcon,
  Pets as PetsIcon,
  Public as PublicIcon,
  Brush as BrushIcon,
  EmojiEvents as EmojiEventsIcon,
  Museum as MuseumIcon,
  Nature as NatureIcon,
} from '@mui/icons-material';

const GrejOfTheDay = ({ user, theme, styles }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);

  const [searchParams] = useSearchParams();

  const urlToTheme = {
    jul: 'christmas',
    host: 'fall',
    var: 'spring',
    vinter: 'winter',
    sommar: 'summer',
    pask: 'easter',
    halloween: 'halloween',
    nyar: 'newYear',
  };

  const [lessonTheme, setLessonTheme] = useState(() => {
    const urlTheme = searchParams.get('tema');
    return urlToTheme[urlTheme] || urlTheme || 'generateWithAi';
  });

  const [language, setLanguage] = useState('svenska');
  const [highLevelFactCount, setHighLevelFactCount] = useState(4);
  const [triviaPerFactCount, setTriviaPerFactCount] = useState(3);
  const [isGeneratingLesson, setIsGeneratingLesson] = useState(false);
  const [lessonData, setLessonData] = useState(null);
  const [themeColor, setThemeColor] = useState('#000000');
  const [isGeneratingTopicImage, setIsGeneratingTopicImage] = useState(false);
  const [isGeneratingFactImages, setIsGeneratingFactImages] = useState(false);
  const [progress, setProgress] = useState(0);
  const [lessonProgress, setLessonProgress] = useState(0); // Add this new state
  const [questionType, setQuestionType] = useState('multipleChoice');
  const [isGeneratingQuiz, setIsGeneratingQuiz] = useState(false);
  const [quizData, setQuizData] = useState(null);
  const [generatedFactImages, setGeneratedFactImages] = useState(new Set());
  const { showSnackbar } = useSnackbar();
  const [grade, setGrade] = useState('åk6');
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageInstructions, setImageInstructions] = useState('');
  const [imageGenerationType, setImageGenerationType] = useState(null);
  const [loadingImageIndex, setLoadingImageIndex] = useState(null);
  const [openAiInstructions, setOpenAiInstructions] = useState('');
  const [selectedFactKey, setSelectedFactKey] = useState(null);
  const [customTheme, setCustomTheme] = useState('');

  const [isEditingClue, setIsEditingClue] = useState(false);
  const [isEditingTopic, setIsEditingTopic] = useState(false);

  // Add these new state variables at the top with other state
  const [editingSections, setEditingSections] = useState({});
  const [editingFactKey, setEditingFactKey] = useState(null);
  const [editedTitles, setEditedTitles] = useState({});
  const [youtubeTranscript, setYoutubeTranscript] = useState('');
  const [predefinedTheme, setPredefinedTheme] = useState(null);
  const lessonTitleRef = useRef(null); // Add this ref
  const transcriptRef = useRef('');
  const uploadedFileRef = useRef('');

  const wikipediaContentRef = useRef('');
  const randomizedContentRef = useRef('');
  const coursePlanContentRef = useRef('');

  const topicSectionRef = useRef(null);
  const factsSectionRef = useRef(null);

  useEffect(() => {
    Logger.log('lessonTheme: ', lessonTheme);
  }, [lessonTheme]);

  useEffect(() => {
    Logger.log('predefinedTheme: ', predefinedTheme);
  }, [predefinedTheme]);

  useEffect(() => {
    Logger.log('customTheme: ', customTheme);
  }, [customTheme]);

  useEffect(() => {
    Logger.log('youtubeTranscript: ', transcriptRef.current);
  }, [transcriptRef.current]);

  useEffect(() => {
    Logger.log('wikipediaContentRef: ', wikipediaContentRef.current);
  }, [wikipediaContentRef.current]);
  useEffect(() => {
    Logger.log('uploadedFileRef: ', uploadedFileRef.current);
  }, [uploadedFileRef.current]);

  useEffect(() => {
    Logger.log('randomizedContentRef: ', randomizedContentRef.current);
  }, [randomizedContentRef.current]);

  useEffect(() => {
    Logger.log('coursePlanContentRef: ', coursePlanContentRef.current);
  }, [coursePlanContentRef.current]);

  const additionalThemes = [
    { type: 'subheader', label: 'Teman som lämpar sig extra bra för denna lektionstyp:' },
    {
      value: 'selma',
      label: 'Selma Lagerlöf',
      content: 'Gör en lektion om selma lagerlöf',
      Icon: MenuBookIcon,
    },
    {
      value: 'dronten',
      label: 'Dronten',
      content: 'Gör en lektion om den utdöda arten dronten',
      Icon: PetsIcon,
    },
    {
      value: 'vikingar',
      label: 'Vikingar',
      content: 'Gör en lektion om vikingatiden',
      Icon: CastleIcon,
    },
    {
      value: 'rymden',
      label: 'Rymden',
      content: 'Gör en lektion om vårt solsystem',
      Icon: PublicIcon,
    },
    {
      value: 'dinosaurier',
      label: 'Dinosaurier',
      content: 'Gör en lektion om olika dinosaurier',
      Icon: NatureIcon,
    },
    {
      value: 'nobel',
      label: 'Nobelpriset',
      content: 'Gör en lektion om nobelpriset och Alfred Nobel',
      Icon: EmojiEventsIcon,
    },
    {
      value: 'uppfinnare',
      label: 'Svenska uppfinnare',
      content: 'Gör en lektion om kända svenska uppfinnare',
      Icon: ScienceIcon,
    },
    {
      value: 'psychology',
      label: 'Känslor',
      content: 'Gör en lektion om olika känslor',
      Icon: PsychologyIcon,
    },
    {
      value: 'konst',
      label: 'Svensk konst',
      content: 'Gör en lektion om svensk konsthistoria',
      Icon: MuseumIcon,
    },
  ];

  const { lessonId } = useParams();
  useEffect(() => {
    const loadLessonFromId = async () => {
      if (lessonId) {
        try {
          const q = query(
            collection(db, 'lesson_usage'),
            where('__name__', '>=', lessonId),
            where('__name__', '<=', lessonId + '\uf8ff'),
            where('lessonType', '==', 'GrejOfTheDay.js'),
            limit(1)
          );

          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            const docData = querySnapshot.docs[0].data();
            setLessonData(docData.data);

            // Scroll to lesson content
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              });
            }, 100);

            showSnackbar('Lektionen har laddats in.', 'success');
          } else {
            showSnackbar('Kunde inte hitta lektionen', 'error');
          }
        } catch (error) {
          console.error('Error loading lesson:', error);
          showSnackbar('Ett fel uppstod när lektionen skulle laddas', 'error');
        }
      }
    };

    loadLessonFromId();
  }, lessonId);

  const handleSaveLessonClick = () => {
    setSaveDialogOpen(true);
  };

  const handleSaveWithComment = async (comment, name, coverImage, tags) => {
    Logger.log('handleSaveWithComment: ', JSON.stringify(lessonData, null, 2));
    await trackLesson(
      user.uid,
      'GrejOfTheDay.js',
      lessonData,
      null,
      true,
      comment,
      name,
      coverImage,
      tags
    );
    setDrawerOpen(true);
    showSnackbar('Lektionen sparad i din lektionsbank!', 'success');
  };

  // Add these new handler functions with other functions
  const handleSectionDelete = factKey => {
    setLessonData(prev => {
      const newData = { ...prev };
      const newHighLevelFacts = { ...prev.highLevelFacts };
      delete newHighLevelFacts[factKey];
      newData.highLevelFacts = newHighLevelFacts;
      return newData;
    });
    showSnackbar('Faktasektionen har tagits bort', 'success');
  };

  const handleSectionEdit = factKey => {
    setEditingFactKey(factKey);
    setEditingSections(prev => ({
      ...prev,
      [factKey]: true,
    }));
  };

  const handleSectionSave = factKey => {
    const newTitle = editedTitles[factKey] || factKey;

    setLessonData(prev => {
      const newData = { ...prev };
      // Create a new ordered object to maintain section order
      const orderedHighLevelFacts = {};

      // First, copy all existing facts in their current order
      Object.entries(prev.highLevelFacts).forEach(([key, value]) => {
        if (key === factKey) {
          // For the edited section, use the new title
          orderedHighLevelFacts[newTitle] = value;
        } else {
          orderedHighLevelFacts[key] = value;
        }
      });

      newData.highLevelFacts = orderedHighLevelFacts;
      return newData;
    });

    setEditingSections(prev => ({
      ...prev,
      [factKey]: false,
    }));

    setEditedTitles(prev => {
      const newTitles = { ...prev };
      delete newTitles[factKey];
      return newTitles;
    });

    showSnackbar('Faktasektionen har uppdaterats', 'success');
  };

  const handleFactUpdate = (factKey, newTitle, newFacts) => {
    setLessonData(prev => {
      const newData = { ...prev };
      const newHighLevelFacts = { ...prev.highLevelFacts };
      const oldFactData = newHighLevelFacts[factKey];

      if (factKey !== newTitle && newTitle.trim() !== '') {
        delete newHighLevelFacts[factKey];
        newHighLevelFacts[newTitle] = {
          ...oldFactData,
          facts: newFacts,
        };
      } else {
        newHighLevelFacts[factKey] = {
          ...oldFactData,
          facts: newFacts,
        };
      }

      newData.highLevelFacts = newHighLevelFacts;
      return newData;
    });
  };

  const handleImageUpdate = (imageUrl, isTopicImage, factKey = null) => {
    if (isTopicImage) {
      setLessonData(prevData => ({
        ...prevData,
        topicImage: imageUrl,
      }));
    } else if (factKey) {
      setLessonData(prevData => ({
        ...prevData,
        highLevelFacts: {
          ...prevData.highLevelFacts,
          [factKey]: {
            ...prevData.highLevelFacts[factKey],
            image: imageUrl,
          },
        },
      }));
      setGeneratedFactImages(prev => new Set([...prev, factKey]));
    }
  };
  const handleImageButtonClick = (type, factKey = null) => {
    setImageGenerationType(type);
    setSelectedFactKey(factKey);
    setShowImageModal(true);
  };
  const handleCloseModal = () => {
    setShowImageModal(false);
    setImageInstructions('');
  };

  const handleGenerateWithInstructions = async () => {
    handleCloseModal();

    if (imageGenerationType === 'topic') {
      await handleGenerateTopicImage();
    } else if (imageGenerationType === 'facts') {
      if (selectedFactKey) {
        await handleGenerateFactImage(selectedFactKey);
      } else {
        await handleGenerateFactImages();
      }
    }
  };

  const scrollToSection = ref => {
    ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const simulateProgress = (startProgress, endProgress, duration, setProgressFn) => {
    const stepSize = 0.5;
    const steps = (endProgress - startProgress) / stepSize;
    const stepDuration = duration / steps;
    let currentProgress = startProgress;

    const intervalId = setInterval(() => {
      currentProgress += stepSize;
      if (currentProgress >= endProgress) {
        clearInterval(intervalId);
      } else {
        setProgressFn(currentProgress);
      }
    }, stepDuration);

    // Return a cleanup function that can be called to stop the simulation
    return () => {
      clearInterval(intervalId);
    };
  };
  const handleGenerateTopicImage = async () => {
    setIsGeneratingTopicImage(true);
    scrollToSection(topicSectionRef);
    try {
      const prompt = `Create a image of ${lessonData.topic}. Do not add any text inside the image. ${imageInstructions}`;

      const imageUrl = await generateAndProcessImage(prompt, user.uid, {
        size: '1024x1024',
        model: 'dall-e-3',
        quality: 'standard',
      });

      setLessonData(prevData => ({
        ...prevData,
        topicImage: imageUrl,
      }));

      showSnackbar('Bild för temat är nu genererad.', 'success');
    } catch (error) {
      showSnackbar('Något gick fel när vi försökte generera bilder. Försök igen.', 'error');
    } finally {
      setIsGeneratingTopicImage(false);
    }
  };

  const handleGenerateFactImages = async () => {
    setIsGeneratingFactImages(true);
    setGeneratedFactImages(new Set());
    scrollToSection(factsSectionRef);
    try {
      const entries = Object.entries(lessonData.highLevelFacts);
      let completedCount = 0;
      const totalCount = entries.length;

      for (const [key, value] of entries) {
        setLoadingImageIndex(key);
        try {
          const prompt = `Create an image for the fact: "${key}" within the topic of "${lessonData.topic}". Do not add any text inside the image. ${imageInstructions}`;

          const imageUrl = await generateAndProcessImage(prompt, user.uid, {
            size: '1024x1024',
            model: 'dall-e-3',
            quality: 'standard',
          });

          setLessonData(prevData => ({
            ...prevData,
            highLevelFacts: {
              ...prevData.highLevelFacts,
              [key]: {
                ...prevData.highLevelFacts[key],
                image: imageUrl,
              },
            },
          }));

          setGeneratedFactImages(prev => new Set([...prev, key]));
          completedCount++;
          showSnackbar(`Genererat bild ${completedCount} av ${totalCount}`, 'info');
        } catch (error) {
          console.error(`Error generating image for fact "${key}":`, error);
          showSnackbar(`Kunde inte generera bild för "${key}"`, 'warning');
          continue;
        }
      }

      showSnackbar('Alla bilder har genererats!', 'success');
    } catch (error) {
      console.error('Error in image generation process:', error);
      showSnackbar('Ett fel uppstod i bildgenereringsprocessen', 'error');
    } finally {
      setIsGeneratingFactImages(false);
      setLoadingImageIndex(null);
    }
  };

  const handleGenerateFactImage = async factKey => {
    setIsGeneratingFactImages(true);
    setLoadingImageIndex(factKey);
    try {
      const prompt = `Create an image for the fact: "${factKey}" within the topic of "${lessonData.topic}". Do not add any text inside the image. ${imageInstructions}`;

      const imageUrl = await generateAndProcessImage(prompt, user.uid, {
        size: '1024x1024',
        model: 'dall-e-3',
        quality: 'standard',
      });

      handleImageUpdate(imageUrl, false, factKey);
      showSnackbar('Bilden har genererats!', 'success');
    } catch (error) {
      console.error(`Error generating image for fact "${factKey}":`, error);
      showSnackbar(`Kunde inte generera bild för "${factKey}"`, 'warning');
    } finally {
      setIsGeneratingFactImages(false);
      setLoadingImageIndex(null);
    }
  };

  const handleGenerateLesson = async () => {
    setIsGeneratingLesson(true);
    setLessonProgress(0);
    let stopProgressSimulation = null;
    try {
      const generateTheme = lessonTheme === 'generateWithAi';
      let themeToUse;

      if (lessonTheme === 'youtube') {
        themeToUse = transcriptRef.current;
      } else if (lessonTheme === 'customTheme') {
        themeToUse = customTheme;
      } else if (lessonTheme === 'predefined') {
        themeToUse = predefinedTheme;
      } else if (lessonTheme === 'fileUpload') {
        themeToUse = uploadedFileRef.current;
      } else if (lessonTheme === 'wikipedia') {
        themeToUse = wikipediaContentRef.current;
      } else if (lessonTheme === 'randomizer') {
        themeToUse = randomizedContentRef.current;
      } else {
        //
        themeToUse = coursePlanContentRef.current;
      }

      Logger.log('themeToUse: ', themeToUse);

      stopProgressSimulation = simulateProgress(0, 90, 15000, setLessonProgress);

      const response = await callApi(
        [
          {
            role: 'system',
            content: `You are an assistant that generates interesting school lessons. ${openAiInstructions}`,
          },
          {
            role: 'user',
            content: `Generate a JSON object with a school lesson to learn new facts about a topic in "${language}". 
            It should be exactly ${triviaPerFactCount} few and short interesting facts per high-level fact about the topic. 
            The amount of high-level facts should be exactly ${highLevelFactCount}. 
            Include empty image placeholders (null) for "topicImage" and "image" fields in each high-level fact. 
            Prioritize novelty and uniqueness in each generation. Do NOT repeat themes from previous responses. ${
              generateTheme
                ? 'Generate a COMPLETELY UNIQUE and RANDOM theme.'
                : lessonTheme === 'customTheme'
                  ? `Generate content about the specific topic "${themeToUse}".`
                  : `Generate a specific and UNIQUE topic WITHIN the chosen domain "${themeToUse}".`
            } Example response:
{
  "topic": "Gravitation",
  "clue": "En kraft som håller oss kvar på jorden",
  "topicImage": null,
  "highLevelFacts": {
    "Tyngdkraft på jorden": {
      "image": null,
      "facts": [
        "fact 1",
        "fact 2",
        "fact 3",
        "fact 4",
        "fact 5"
      ]
    },
    "Gravitation i rymden": {
      "image": null,
      "facts": [
        "fact 1",
        "fact 2",
        "fact 3",
        "fact 4",
        "fact 5"
      ]
    },
    "Hur gravitation upptäcktes": {
      "image": null,
      "facts": [
        "fact 1",
        "fact 2",
        "fact 3",
        "fact 4",
        "fact 5"
      ]
    }
  }
}`,
          },
        ],
        8000,
        true,
        user.uid
      );

      // Stop the progress simulation
      if (stopProgressSimulation) {
        stopProgressSimulation();
      }

      setLessonProgress(95);

      let messageContent = response.data.choices[0].message.content.trim();

      if (messageContent.startsWith('```json')) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith('```')) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const data = JSON.parse(messageContent);

      setLessonProgress(100);
      await new Promise(resolve => setTimeout(resolve, 800)); // Longer delay for visibility

      setLessonData(data);

      showSnackbar('Grej of the day har blivit genererad.', 'success');

      await trackLesson(user.uid, 'GrejOfTheDay.js', null, {
        language: language,
        lessonTheme: lessonTheme,
      });
    } catch (error) {
      console.error('Error generating lesson:', error);
      showSnackbar('Nåt gick fel. Vänligen försök igen.', 'error');
    } finally {
      setIsGeneratingLesson(false);
    }
  };

  const handleQuestionTypeChange = event => {
    setQuestionType(event.target.value);
  };

  const handleGenerateQuiz = async () => {
    setIsGeneratingQuiz(true);
    setProgress(0);
    let stopProgressSimulation = null;

    setQuizData(null);

    try {
      stopProgressSimulation = simulateProgress(0, 90, 15000, setProgress);

      // Format instructions based on question type, similar to Quiz.js
      let instructions = '';
      if (questionType === 'fill-in-the-blank') {
        instructions = `Generate a fill in the blanks quiz with 10 questions based on the provided text. Everything should be in ${language}. Each question should be a JSON object with a 'question' field containing the text with a blank (_), an 'options' field containing 4 options, and a 'correct' field. Ensure all questions are returned in a single JSON array.`;
      } else if (questionType === 'true-or-false') {
        instructions = `Generate a true or false quiz with 10 questions based on the provided text. The quiz should be in ${language}. Each question should be a JSON object with a "question" field, an "options" field containing only "Sant" and "Falskt" as options, and a "correct" field. Ensure all questions are returned in a single JSON array.`;
      } else {
        instructions = `Generate a quiz with 10 questions based on the provided text. The quiz should be in ${language}. Each question should be a JSON object with a "question" field, an "options" field containing 4 options, and a "correct" field. Ensure all questions are returned in a single JSON array. Do not use any markdown formatting.`;
      }

      const response = await callApi(
        [
          {
            role: 'system',
            content: instructions,
          },
          {
            role: 'user',
            content: `Generate questions based on this lesson content:

Topic: ${lessonData.topic}
Clue: ${lessonData.clue}

High-Level Facts:
${Object.entries(lessonData.highLevelFacts)
  .map(
    ([section, data]) => `
${section}:
${data.facts.join('\n')}`
  )
  .join('\n')}

Format the response as a JSON array of questions. For example:
[
  {
    "question": "Question text here",
    "options": ${
      questionType === 'true-or-false'
        ? '["Sant", "Falskt"]'
        : '["Option A", "Option B", "Option C", "Option D"]'
    },
    "correct": "Correct answer here"
  }
]`,
          },
        ],
        4000,
        true,
        user.uid
      );

      if (stopProgressSimulation) {
        stopProgressSimulation();
      }

      setProgress(95);

      let messageContent = response.data.choices[0].message.content.trim();
      if (messageContent.startsWith('```json')) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith('```')) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const questions = JSON.parse(messageContent);
      setProgress(100);
      await new Promise(resolve => setTimeout(resolve, 800));

      setQuizData(questions); // This will now be an array of questions, matching Quiz.js format

      showSnackbar('Quiz har genererats!', 'success');
    } catch (error) {
      console.error('Error generating quiz:', error);
      showSnackbar('Ett fel uppstod när quizet skulle genereras.', 'error');
    } finally {
      setIsGeneratingQuiz(false);
    }
  };
  const isGeneratingAnyImages = isGeneratingTopicImage || isGeneratingFactImages;

  return (
    <>
      <Box sx={styles.container}>
        <Box
          sx={{
            ...styles.innerContainer,
            width: { xs: '100%', md: '87%' },
            margin: '0 auto',
          }}
        >
          <Typography
            variant='h1'
            gutterBottom
            sx={{ fontWeight: 'bold', fontSize: '1.8em', mb: 3, mt: 5, textAlign: 'center' }}
          >
            Grej of the Day
          </Typography>
          <LessonDrawer
            userId={user.uid}
            lessonType='GrejOfTheDay.js'
            theme={theme}
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}
            lessonIcon={GiTeacher}
            onSelectLesson={lesson => {
              setLessonData(lesson.data);

              setDrawerOpen(false);

              showSnackbar(`Lektionen har laddats in.`, 'success');
              setTimeout(() => {
                lessonTitleRef.current?.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start', // This ensures it scrolls to the top of the element
                });
              }, 100);
            }}
          />
          <Box sx={{ ...styles.buttonsContainer, ...styles.additionalButtonsContainer }}>
            <Tooltip title='Dina sparade lektioner'>
              <Button
                variant='contained'
                color='primary'
                disableRipple
                onClick={() => setDrawerOpen(true)}
                startIcon={<HistoryIcon />}
                sx={{
                  padding: '8px',
                  marginRight: '0px',
                  paddingLeft: '14px',
                  paddingRight: '3px',
                  minWidth: 'auto',
                  textTransform: 'none',
                }}
              />
            </Tooltip>
          </Box>

          <InfoBox
            icon={GiTeacher}
            title='Powerpoint Instructions'
            instructions={[
              'Välj underlag för lektionen som t.ex. tema, video eller egna instruktioner',
              'Generera lektionen och anpassa utifrån dina behov',
              'Ladda ned som powerpoint',
            ]}
            theme={theme}
            styles={styles}
          />
          <ThemeSelectorFlow
            value={lessonTheme}
            onChange={e => setLessonTheme(e.target.value)}
            customTheme={customTheme}
            onCustomThemeChange={e => setCustomTheme(e.target.value)}
            onPredefinedThemeChange={e => setPredefinedTheme(e.target.value)}
            onYoutubeTranscriptChange={transcriptContent => {
              Logger.log('onYoutubeTranscriptChange körs med: ', transcriptContent);
              transcriptRef.current = transcriptContent;
            }}
            onFileUpload={content => {
              Logger.log('onFileUpload körs med: ', content);
              uploadedFileRef.current = content;
            }}
            onWikipediaContentChange={content => {
              Logger.log('Parent: Wikipedia content received', content.substring(0, 100));
              wikipediaContentRef.current = content;
            }}
            onRandomizedContentChange={content => {
              Logger.log('randomizedContentRef', content.substring(0, 100));
              randomizedContentRef.current = content;
            }}
            onCoursePlanContentChange={content => {
              Logger.log('onCoursePlanChange', content.substring(0, 100));
              coursePlanContentRef.current = content;
            }}
            lessonType='GrejOfTheDay.js'
            user={user}
            theme={theme}
            styles={styles}
            onLessonSelect={lesson => {
              Logger.log('onLessonSelect körs');
              setLessonData(lesson.data);
              setTimeout(() => {
                lessonTitleRef.current?.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start', // This ensures it scrolls to the top of the element
                });
              }, 100);
              showSnackbar('Lektionen har laddats in.', 'success');
            }}
            additionalThemes={additionalThemes}
          />
          <Box sx={styles.headlineLessonBox}>
            <Typography variant='h6' sx={styles.headlineLessonBoxTitle}>
              2. Generera lektionen
            </Typography>
          </Box>
          <Box sx={{ ...styles.freshBox, padding: '30px' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Typography variant='subtitle1' sx={{ fontWeight: '500', mt: 0 }}>
                  Språk:
                </Typography>
                <Box sx={{ mt: 0.5 }}>
                  <LanguageSelector language={language} setLanguage={setLanguage} />
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant='subtitle1' sx={{ fontWeight: '500', mt: 3 }}>
                  Antal fakta-sektioner:
                </Typography>
                <FormControl fullWidth margin='normal' sx={{ mb: 3, mt: 1 }}>
                  <Select
                    value={highLevelFactCount}
                    onChange={e => setHighLevelFactCount(e.target.value)}
                  >
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={6}>6</MenuItem>
                    <MenuItem value={8}>8</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant='subtitle1' sx={{ fontWeight: '500', mt: 3 }}>
                  Antal kuriositeter per fakta-sektion:
                </Typography>
                <FormControl fullWidth margin='normal' sx={{ mb: 3, mt: 1 }}>
                  <Select
                    value={triviaPerFactCount}
                    onChange={e => setTriviaPerFactCount(e.target.value)}
                  >
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4} disabled>
                      4
                    </MenuItem>
                    <MenuItem value={5} disabled>
                      5
                    </MenuItem>
                    <MenuItem value={6} disabled>
                      6
                    </MenuItem>
                    <MenuItem value={7} disabled>
                      7
                    </MenuItem>
                    <MenuItem value={8} disabled>
                      8
                    </MenuItem>
                    <MenuItem value={9} disabled>
                      9
                    </MenuItem>
                    <MenuItem value={10} disabled>
                      10
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Typography variant='subtitle1' sx={{ fontWeight: '500', mt: 1 }}>
                  Specifika instruktioner:
                </Typography>
                <FormControl fullWidth margin='normal' sx={{ mb: 3, mt: 1 }}>
                  <TextField
                    multiline
                    rows={2}
                    value={openAiInstructions}
                    onChange={e => setOpenAiInstructions(e.target.value)}
                    placeholder='T.ex. Ta endast med information mellan åren 1933-1936.'
                    variant='outlined'
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Box>
              <Button
                variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                color='primary'
                onClick={handleGenerateLesson}
                disabled={isGeneratingLesson || isGeneratingAnyImages}
                sx={{ mt: 2 }}
                startIcon={
                  !isGeneratingLesson ? (
                    <GenerateIcon style={{ color: '#fff' }} />
                  ) : (
                    <CircularProgress style={{ color: '#fff' }} size={16} />
                  )
                }
              >
                {isGeneratingLesson ? 'Genererar grej of the day...' : 'Generera grej of the day'}
              </Button>
            </Box>

            {isGeneratingLesson && (
              <Box sx={{ mt: 7 }}>
                <ProgressBar progress={lessonProgress} label='Genererar grej of the day...' />
              </Box>
            )}
          </Box>

          {lessonData && (
            <>
              <Box ref={lessonTitleRef} sx={{ ...styles.headlineLessonBox, mt: 10 }}>
                <Typography variant='h6' sx={styles.headlineLessonBoxTitle}>
                  3. Din lektion
                </Typography>
              </Box>
              <Box sx={{ ...styles.freshBox, padding: '30px' }}>
                <Box sx={styles.lessonControlPanel}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      px: 1,
                    }}
                  >
                    <Box>
                      <Button
                        variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                        color='primary'
                        onClick={() => handleImageButtonClick('topic')}
                        disabled={isGeneratingTopicImage || isGeneratingFactImages}
                        startIcon={
                          !isGeneratingTopicImage ? (
                            <ImageIcon style={{ color: '#fff' }} />
                          ) : (
                            <CircularProgress style={{ color: '#fff' }} size={16} />
                          )
                        }
                      >
                        {isGeneratingTopicImage ? 'Genererar...' : 'Lägg till temabild'}
                      </Button>

                      <Button
                        variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                        color='primary'
                        onClick={() => handleImageButtonClick('facts')}
                        disabled={isGeneratingTopicImage || isGeneratingFactImages}
                        sx={{ ml: 2 }}
                        startIcon={
                          !isGeneratingFactImages ? (
                            <ImageIcon style={{ color: '#fff' }} />
                          ) : (
                            <CircularProgress style={{ color: '#fff' }} size={16} />
                          )
                        }
                      >
                        {isGeneratingFactImages ? 'Genererar...' : 'Lägg till faktabilder'}
                      </Button>
                    </Box>
                    <Box>
                      <PptxGenerator
                        lessonData={{
                          ...lessonData,
                          quizData: quizData, // Pass quiz data if you want to include it
                        }}
                        theme={theme}
                        disabled={isGeneratingAnyImages}
                      />

                      <Button
                        variant='print'
                        startIcon={<SaveAsIcon />}
                        sx={{ ml: 2 }}
                        onClick={handleSaveLessonClick}
                      >
                        Spara i lektionsbank
                      </Button>
                    </Box>
                  </Box>
                </Box>

                <SaveLessonDialog
                  open={saveDialogOpen}
                  onClose={() => setSaveDialogOpen(false)}
                  onSave={handleSaveWithComment}
                  theme={theme}
                  lessonName={'Grej of the day'}
                  lessonObject={lessonData}
                  lessonType='GrejOfTheDay.js'
                />

                <ImageGenerationModal
                  open={showImageModal}
                  onClose={handleCloseModal}
                  onGenerate={handleGenerateWithInstructions}
                  isGenerating={
                    imageGenerationType === 'topic'
                      ? isGeneratingTopicImage
                      : isGeneratingFactImages
                  }
                  instructions={imageInstructions}
                  setInstructions={setImageInstructions}
                  generationType={imageGenerationType}
                  theme={theme}
                />

                <Box sx={{ mt: 4 }}>
                  <Typography
                    variant='h3'
                    sx={{ fontWeight: 'bold', textAlign: 'center', mb: 4, mt: 8 }}
                  >
                    Grej of the day
                  </Typography>

                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {/* Clue Section */}
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '500px',
                          backgroundColor: theme.palette.mode === 'dark' ? '#15202b' : '#f5f5f5',
                          borderRadius: '10px',
                        }}
                      >
                        <HelpOutlineIcon sx={{ fontSize: 90, mb: 2 }} />
                        {isEditingClue ? (
                          <TextField
                            value={lessonData.clue}
                            onChange={e =>
                              setLessonData(prev => ({ ...prev, clue: e.target.value }))
                            }
                            onBlur={() => {
                              setIsEditingClue(false);
                              showSnackbar('Ledtråden har uppdaterats', 'success');
                            }}
                            onKeyDown={e => {
                              if (e.key === 'Enter') {
                                setIsEditingClue(false);
                                showSnackbar('Ledtråden har uppdaterats', 'success');
                              }
                            }}
                            autoFocus
                            fullWidth
                            variant='standard'
                            sx={{
                              width: '75%',
                              '& .MuiInputBase-input': {
                                textAlign: 'center',
                                fontSize: '2.125rem',
                                fontFamily: 'inherit',
                              },
                            }}
                            InputProps={{
                              disableUnderline: true,
                            }}
                          />
                        ) : (
                          <Box
                            sx={{
                              position: 'relative',
                              display: 'inline-block',
                              width: '100%',
                              textAlign: 'center',
                              '&:hover .edit-icon': {
                                opacity: 1,
                              },
                            }}
                          >
                            <Typography
                              variant='h4'
                              sx={{
                                display: 'inline-flex',
                                alignItems: 'center',
                                textAlign: 'center',

                                gap: 1,
                                width: '90%',
                                justifyContent: 'center',
                              }}
                            >
                              {lessonData.clue}...
                              <Box
                                component='span'
                                className='edit-icon'
                                sx={{
                                  opacity: 0,
                                  transition: 'opacity 0.2s',
                                  display: 'inline-flex',
                                  ml: 1,
                                }}
                              >
                                <Tooltip title='Redigera' placement='top'>
                                  <IconButton size='small' onClick={() => setIsEditingClue(true)}>
                                    <EditIcon />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4, mb: 4 }}>
                        <KeyboardDoubleArrowDownIcon
                          sx={{
                            fontSize: '4rem',
                            color: '#6424c1',
                            //animation: 'bounce 2s infinite',
                          }}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} ref={topicSectionRef}>
                      {/* Topic Section */}
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          paddingTop: '60px',
                          paddingBottom: '60px',
                          flexDirection: 'column',
                          backgroundColor: theme.palette.mode === 'dark' ? '#15202b' : '#f5f5f5',
                          borderRadius: '10px',
                        }}
                      >
                        {isEditingTopic ? (
                          <TextField
                            value={lessonData.topic}
                            onChange={e =>
                              setLessonData(prev => ({ ...prev, topic: e.target.value }))
                            }
                            onBlur={() => {
                              setIsEditingTopic(false);
                              showSnackbar('Ämnet har uppdaterats', 'success');
                            }}
                            onKeyDown={e => {
                              if (e.key === 'Enter') {
                                setIsEditingTopic(false);
                                showSnackbar('Ämnet har uppdaterats', 'success');
                              }
                            }}
                            autoFocus
                            fullWidth
                            variant='standard'
                            sx={{
                              width: '90%',
                              margin: '0 auto',
                              '& .MuiInputBase-input': {
                                textAlign: 'center',
                                fontWeight: 'bold',
                                fontSize: '3rem',
                                fontFamily: 'inherit',
                              },
                            }}
                            InputProps={{
                              disableUnderline: true,
                            }}
                          />
                        ) : (
                          <Box
                            sx={{
                              position: 'relative',
                              display: 'inline-block',

                              width: '90%',
                              margin: '0 auto',
                              textAlign: 'center',

                              '&:hover .edit-icon': {
                                opacity: 1,
                              },
                            }}
                          >
                            <Box
                              sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              <Typography
                                variant='h3'
                                sx={{
                                  fontWeight: 'bold',
                                  display: 'inline-flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  gap: 1,
                                  width: '90%',
                                }}
                              >
                                {lessonData.topic}
                                <Box
                                  component='span'
                                  className='edit-icon'
                                  sx={{
                                    opacity: 0,
                                    transition: 'opacity 0.2s',
                                    display: 'inline-flex',
                                    ml: 1,
                                  }}
                                >
                                  <Tooltip title='Redigera' placement='top'>
                                    <IconButton
                                      size='small'
                                      onClick={() => setIsEditingTopic(true)}
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              </Typography>
                            </Box>
                          </Box>
                        )}

                        <Box sx={{ position: 'relative', mt: 7 }}>
                          <HoverableImage
                            imageUrl={lessonData.topicImage}
                            isLoading={isGeneratingTopicImage}
                            userId={user.uid}
                            theme={theme}
                            showSnackbar={showSnackbar}
                            onGenerateClick={() => handleImageButtonClick('topic')}
                            onImageUpdate={newImageUrl => handleImageUpdate(newImageUrl, true)}
                            height='300px'
                          />
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4, mb: 4 }}>
                        <KeyboardDoubleArrowDownIcon
                          sx={{
                            fontSize: '4rem',
                            color: '#6424c1',
                            //animation: 'bounce 2s infinite',
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        mt: 8,
                        '& p': {
                          lineHeight: '27px',
                        },
                        '& li': {
                          marginBottom: '12px',
                          lineHeight: '25px',
                          '& strong': {
                            fontWeight: '600',
                          },
                        },
                        '& h1': {
                          color: themeColor,
                        },
                        '& h2': {
                          marginTop: '60px',
                          marginBottom: '0px',
                          color: themeColor,
                        },
                        '& h3': {
                          marginTop: '40px',
                          marginBottom: '0px',
                          color: themeColor,
                        },
                        '& hr': {
                          height: '1px',
                          border: 'none',
                          backgroundColor: theme => theme.palette.divider,
                          marginTop: '50px',
                          marginBottom: '50px',
                        },
                        '& ul, & ol': {
                          '& li::marker': {
                            color: themeColor,
                          },
                        },
                      }}
                    >
                      {/* High-Level Facts */}
                      <Grid container spacing={4} ref={factsSectionRef}>
                        {Object.entries(lessonData.highLevelFacts).map(([section, data], index) => (
                          <Grid item xs={12} md={6} key={index}>
                            <Box
                              sx={{
                                position: 'relative',
                                '&:hover .section-controls': {
                                  opacity: 1,
                                  visibility: 'visible',
                                },
                              }}
                            >
                              <Box
                                className='section-controls'
                                sx={{
                                  position: 'absolute',
                                  top: 0,
                                  right: 0,
                                  opacity: 0,
                                  visibility: 'hidden',
                                  transition: 'all 0.2s ease',
                                  display: 'flex',
                                  gap: 1,
                                  zIndex: 1,
                                }}
                              >
                                <Tooltip
                                  title={editingSections[section] ? 'Spara' : 'Redigera'}
                                  placement='top'
                                >
                                  <IconButton
                                    size='small'
                                    onClick={() =>
                                      editingSections[section]
                                        ? handleSectionSave(section)
                                        : handleSectionEdit(section)
                                    }
                                  >
                                    {editingSections[section] ? (
                                      <SaveAsIcon fontSize='small' />
                                    ) : (
                                      <EditIcon fontSize='small' />
                                    )}
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title='Ta bort sektion' placement='top'>
                                  <IconButton
                                    size='small'
                                    onClick={() => handleSectionDelete(section)}
                                  >
                                    <DeleteOutlineIcon fontSize='small' />
                                  </IconButton>
                                </Tooltip>
                              </Box>

                              {editingSections[section] ? (
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                  <TextField
                                    value={editedTitles[section] || section}
                                    onChange={e => {
                                      const newTitle = e.target.value;
                                      setEditedTitles(prev => ({
                                        ...prev,
                                        [section]: newTitle,
                                      }));
                                    }}
                                    sx={{
                                      width: '80%',
                                      '& .MuiInputBase-input': {
                                        fontSize: '1.25rem',
                                        fontWeight: 'bold',
                                      },
                                    }}
                                  />
                                  <Box sx={{ mt: 2 }}>
                                    {/* Image section remains the same */}
                                    <Box sx={{ position: 'relative', mb: 2 }}>
                                      <HoverableImage
                                        imageUrl={lessonData.highLevelFacts[section].image}
                                        isLoading={
                                          isGeneratingFactImages && loadingImageIndex === section
                                        }
                                        loadingIndex={loadingImageIndex}
                                        currentIndex={section}
                                        userId={user.uid}
                                        theme={theme}
                                        showSnackbar={showSnackbar}
                                        onGenerateClick={() =>
                                          handleImageButtonClick('facts', section)
                                        }
                                        onImageUpdate={newImageUrl =>
                                          handleImageUpdate(newImageUrl, false, section)
                                        }
                                        height='344px'
                                      />
                                    </Box>
                                    {data.facts.map((fact, idx) => (
                                      <TextField
                                        key={idx}
                                        fullWidth
                                        multiline
                                        value={fact}
                                        onChange={e => {
                                          const newFacts = [...data.facts];
                                          newFacts[idx] = e.target.value;
                                          // Just update local state, don't save yet
                                          setLessonData(prev => ({
                                            ...prev,
                                            highLevelFacts: {
                                              ...prev.highLevelFacts,
                                              [section]: {
                                                ...prev.highLevelFacts[section],
                                                facts: newFacts,
                                              },
                                            },
                                          }));
                                        }}
                                        sx={{ mb: 2 }}
                                      />
                                    ))}
                                    <Button
                                      variant={
                                        theme.palette.mode === 'light' ? 'purp' : 'contained'
                                      }
                                      color='primary'
                                      onClick={() => handleSectionSave(section)}
                                      startIcon={<SaveAsIcon />}
                                      sx={{ mt: 2 }}
                                    >
                                      Spara ändringar
                                    </Button>
                                  </Box>
                                </Box>
                              ) : (
                                <>
                                  <Typography variant='h6' sx={{ fontWeight: 'bold', mb: 2 }}>
                                    {section}
                                  </Typography>

                                  <Box sx={{ position: 'relative', mb: 2 }}>
                                    <HoverableImage
                                      imageUrl={lessonData.highLevelFacts[section].image}
                                      isLoading={
                                        isGeneratingFactImages && loadingImageIndex === section
                                      }
                                      loadingIndex={loadingImageIndex}
                                      currentIndex={section}
                                      userId={user.uid}
                                      theme={theme}
                                      showSnackbar={showSnackbar}
                                      onGenerateClick={() =>
                                        handleImageButtonClick('facts', section)
                                      }
                                      onImageUpdate={newImageUrl =>
                                        handleImageUpdate(newImageUrl, false, section)
                                      }
                                      height='344px'
                                    />
                                  </Box>

                                  <ul>
                                    {data.facts.map((fact, idx) => (
                                      <li key={idx}>
                                        <Typography variant='body1'>{fact}</Typography>
                                      </li>
                                    ))}
                                  </ul>
                                </>
                              )}
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Box>

              <Box sx={{ ...styles.headlineLessonBox, mt: 10 }}>
                <Typography variant='h6' sx={styles.headlineLessonBoxTitle}>
                  Quiz — Testa elevens kunskap en vecka senare
                </Typography>
              </Box>
              <Box sx={{ ...styles.freshBox, padding: '30px' }}>
                <QuestionTypeSelector
                  selected={questionType}
                  setQuestionType={setQuestionType}
                  theme={theme}
                />

                <Box>
                  <Button
                    variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                    color='primary'
                    onClick={handleGenerateQuiz}
                    disabled={isGeneratingQuiz}
                    startIcon={
                      !isGeneratingQuiz ? (
                        <QuizIcon style={{ color: '#fff' }} />
                      ) : (
                        <CircularProgress size={16} />
                      )
                    }
                    sx={{ mt: 3 }}
                  >
                    {isGeneratingQuiz ? 'Genererar quiz...' : 'Generera quiz baserat på GOTD'}
                  </Button>
                </Box>

                {isGeneratingQuiz && <ProgressBar progress={progress} label='Genererar quiz...' />}
              </Box>
              {quizData && (
                <>
                  <TeacherQuizDisplay
                    quiz={quizData}
                    quizName={lessonData.title}
                    questionType={questionType}
                    onPrint={() =>
                      handlePrintQuiz(
                        quizData,
                        lessonData.title,
                        questionType,
                        theme.palette.primary.main
                      )
                    }
                  />
                </>
              )}
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default GrejOfTheDay;
