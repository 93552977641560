// AskAIDrawer.js
import React, { useState, useEffect } from 'react';
import {
    Drawer,
    Box,
    IconButton,
    Typography,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@mui/material';
import ChevronRight from '@mui/icons-material/ChevronRight';
import ChatIcon from '@mui/icons-material/Chat';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import ClipLoader from 'react-spinners/ClipLoader';

// Import other dependencies ...

const AskAIDrawer = ({
    drawerOpen,
    setDrawerOpen,
    oldishLoading,
    chats,
    editingChat,
    savingChatName,
    handleChatSelectWithReset,
    isDark,
    handleChatNameChange,
    handleSaveChatName,
    handleStartEditing,
}) => {
    const [groupedChats, setGroupedChats] = useState({
        today: [],
        yesterday: [],
        thisWeek: [],
        thisMonth: [],
        aWhileAgo: [],
    });

    useEffect(() => {
        const categorizeChats = () => {
            const now = new Date();
            const yesterday = new Date(now);
            yesterday.setDate(yesterday.getDate() - 1);
            const oneWeekAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
            const oneMonthAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);

            const grouped = chats.reduce(
                (acc, chat) => {
                    // Check if chat.createdAt exists and is valid
                    if (chat.createdAt && chat.createdAt.seconds) {
                        const chatDate = new Date(chat.createdAt.seconds * 1000);
                        if (chatDate >= new Date(now.setHours(0, 0, 0, 0))) {
                            acc.today.push(chat);
                        } else if (chatDate >= new Date(yesterday.setHours(0, 0, 0, 0))) {
                            acc.yesterday.push(chat);
                        } else if (chatDate >= oneWeekAgo) {
                            acc.thisWeek.push(chat);
                        } else if (chatDate >= oneMonthAgo) {
                            acc.thisMonth.push(chat);
                        } else {
                            acc.aWhileAgo.push(chat);
                        }
                    } else {
                        // If createdAt is undefined or invalid, add to aWhileAgo
                        acc.aWhileAgo.push(chat);
                    }
                    return acc;
                },
                { today: [], yesterday: [], thisWeek: [], thisMonth: [], aWhileAgo: [] }
            );

            setGroupedChats(grouped);
        };

        categorizeChats();
    }, [chats]);

    const renderChatGroup = (groupTitle, chats) => {
        if (chats.length === 0) return null;

        return (
            <>
                <Typography
                    variant='subtitle1'
                    sx={{
                        fontWeight: '500',
                        fontSize: '15px',
                        paddingTop: '15px',

                        marginTop: '10px',
                    }}
                >
                    {groupTitle}
                </Typography>
                <List>
                    {chats.map(chat => (
                        <ListItem
                            variant='compactList'
                            className='rounded'
                            sx={{
                                padding: 0,
                                margin: 0,
                            }}
                            key={chat.id}
                            button={editingChat.id !== chat.id}
                            onClick={() =>
                                editingChat.id !== chat.id && handleChatSelectWithReset(chat.id)
                            }
                        >
                            <ListItemIcon sx={{ minWidth: '33px !important' }}>
                                <ChatBubbleOutlineIcon
                                    sx={{ marginRight: '0px', marginLeft: '8px' }}
                                />
                            </ListItemIcon>
                            {editingChat.id === chat.id ? (
                                <>
                                    <TextField
                                        value={editingChat.name}
                                        onChange={handleChatNameChange}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault();
                                                handleSaveChatName();
                                            }
                                        }}
                                        sx={{ '& .MuiInputBase-input': { padding: '4px' } }}
                                        autoFocus
                                        onClick={e => e.stopPropagation()}
                                    />
                                    {savingChatName ? (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                marginLeft: 1,
                                            }}
                                        >
                                            <ClipLoader size={20} color={'#123abc'} />
                                        </Box>
                                    ) : (
                                        <IconButton
                                            onClick={e => {
                                                e.stopPropagation();
                                                handleSaveChatName();
                                            }}
                                        >
                                            <SaveIcon />
                                        </IconButton>
                                    )}
                                </>
                            ) : (
                                <>
                                    <ListItemText
                                        primary={chat.name}
                                        sx={{
                                            paddingLeft: 0,
                                            marginLeft: 0,
                                            fontSize: '14px',
                                            '& .MuiTypography-root': {
                                                color: isDark ? '#b0b4b8' : '#4a5568',
                                                fontSize: '14px',
                                                fontWeight: '500',
                                            },
                                        }}
                                    />
                                    <IconButton
                                        onClick={e => {
                                            e.stopPropagation();
                                            handleStartEditing(chat.id, chat.name);
                                        }}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </>
                            )}
                        </ListItem>
                    ))}
                </List>
            </>
        );
    };

    return (
        <Drawer
            sx={{ width: { xs: '75%', sm: '50%', md: '25%' }, paddingTop: '0px' }}
            PaperProps={{
                sx: {
                    width: { xs: '75%', sm: '50%', md: '25%' },
                    boxShadow: 'none',
                    borderLeft: isDark ? '1px solid #34414c' : '1px solid #e0e0e0',
                    backgroundImage: 'none',
                },
            }}
            anchor='right'
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}
        >
            <Box sx={{ p: 3 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <IconButton onClick={() => setDrawerOpen(false)} color='inherit'>
                        <ChevronRight />
                    </IconButton>
                    <Typography variant='h6' sx={{ fontSize: '17px', ml: 2 }}>
                        Konversationer
                    </Typography>
                </Box>

                {oldishLoading ? (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'top',
                            justifyContent: 'top',
                            height: '100%',
                        }}
                    >
                        <ClipLoader size={20} color={'#123abc'} loading={true} />
                        <Typography variant='body1' sx={{ marginLeft: 1 }}></Typography>
                    </Box>
                ) : (
                    <>
                        {renderChatGroup('I dag', groupedChats.today)}
                        {renderChatGroup('I går', groupedChats.yesterday)}
                        {renderChatGroup('Denna vecka', groupedChats.thisWeek)}
                        {renderChatGroup('Denna månad', groupedChats.thisMonth)}
                        {renderChatGroup('För ett tag sedan', groupedChats.aWhileAgo)}
                    </>
                )}
            </Box>
        </Drawer>
    );
};

export default AskAIDrawer;
