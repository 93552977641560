import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  DialogActions,
  Button,
  Grid,
  Tab,
  Tabs,
  IconButton,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { collection, query, where, getDocs, orderBy, limit, startAfter } from 'firebase/firestore';
import { db } from './firebase';
import { format } from 'date-fns';
import BlurryBackdrop from './BlurryBackdrop';
import { sv } from 'date-fns/locale';

import CheckIcon from '@mui/icons-material/Check';
import Logger from './Logger';

const ImageGalleryDialog = ({ open, onClose, onSelect, userId, theme }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [lastVisible, setLastVisible] = useState(null);
  const [sharedLastVisible, setSharedLastVisible] = useState(null);

  const IMAGES_PER_PAGE = 10;

  const fetchUserImages = async (isInitial = true) => {
    Logger.log('userId: ', userId);

    try {
      const imagesRef = collection(db, 'images');
      let q = query(
        imagesRef,
        where('userId', '==', userId),
        orderBy('createdAt', 'desc'),
        limit(IMAGES_PER_PAGE)
      );

      if (!isInitial && lastVisible) {
        q = query(
          imagesRef,
          where('userId', '==', userId),
          orderBy('createdAt', 'desc'),
          startAfter(lastVisible),
          limit(IMAGES_PER_PAGE)
        );
      }

      const querySnapshot = await getDocs(q);
      const newImages = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));

      setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setHasMore(querySnapshot.docs.length === IMAGES_PER_PAGE);

      if (isInitial) {
        setImages(newImages);
      } else {
        setImages(prev => [...prev, ...newImages]);
      }
    } catch (error) {
      console.error('Error fetching user images:', error);
    }
  };

  const fetchSharedImages = async (isInitial = true) => {
    try {
      const imagesRef = collection(db, 'images');
      let q = query(
        imagesRef,
        where('userId', '!=', userId),
        orderBy('userId'),
        orderBy('createdAt', 'desc'),
        limit(IMAGES_PER_PAGE)
      );

      if (!isInitial && sharedLastVisible) {
        q = query(
          imagesRef,
          where('userId', '!=', userId),
          orderBy('userId'),
          orderBy('createdAt', 'desc'),
          startAfter(sharedLastVisible),
          limit(IMAGES_PER_PAGE)
        );
      }

      const querySnapshot = await getDocs(q);
      const newImages = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));

      setSharedLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setHasMore(querySnapshot.docs.length === IMAGES_PER_PAGE);

      if (isInitial) {
        setImages(newImages);
      } else {
        setImages(prev => [...prev, ...newImages]);
      }
    } catch (error) {
      console.error('Error fetching shared images:', error);
    }
  };

  useEffect(() => {
    if (open) {
      setLoading(true);
      setImages([]);
      setHasMore(true);
      setLastVisible(null);
      setSharedLastVisible(null);

      if (activeTab === 0) {
        fetchUserImages().finally(() => setLoading(false));
      } else {
        fetchSharedImages().finally(() => setLoading(false));
      }
    }
  }, [open, activeTab]);

  const handleLoadMore = async () => {
    setLoadingMore(true);
    if (activeTab === 0) {
      await fetchUserImages(false);
    } else {
      await fetchSharedImages(false);
    }
    setLoadingMore(false);
  };

  const handleTabChange = (_, newValue) => {
    setSelectedImage(null);
    setActiveTab(newValue);
  };

  const handleImageSelect = image => {
    setSelectedImage(image);
  };

  const handleConfirmSelection = () => {
    if (selectedImage) {
      onSelect(selectedImage);
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='lg'
      fullWidth
      BackdropComponent={BlurryBackdrop}
      PaperProps={{
        sx: {
          borderRadius: '20px',
          backgroundColor: theme.palette.mode === 'dark' ? '#22303C' : 'white',
          backgroundImage: theme.palette.mode === 'dark' ? 'none' : 'white',
          padding: { xs: '0px', md: '20px' },
        },
      }}
    >
      <DialogTitle
        sx={{
          px: 3,
          py: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          variant='h6'
          component='div'
          sx={{ fontWeight: 'bold', fontSize: '1.6rem', padding: '5px' }}
        >
          Bildgalleri
        </Typography>
        <IconButton onClick={onClose} size='small'>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        sx={{
          px: 3,
          borderBottom: 1,
          borderColor: 'divider',
          '& .MuiTab-root': {
            textTransform: 'none',
            fontWeight: 500,
            fontSize: '1.1rem',
          },
        }}
      >
        <Tab label='Mina bilder' />
        <Tab label='Delade bilder' />
      </Tabs>

      <DialogContent sx={{ p: 3 }}>
        {loading ? (
          <Box
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 400 }}
          >
            <CircularProgress />
          </Box>
        ) : images.length === 0 && activeTab === 0 ? (
          <Box sx={{ display: 'flex', height: '400px' }}>
            <Typography variant='body1'>
              Du har inte genererat eller laddat upp några bilder ännu. Klicka på "Delade bilder"
              och välj någon som våra andra användare har genererat. :)
            </Typography>
          </Box>
        ) : (
          <>
            <Grid container spacing={2}>
              {images.map(image => (
                <Grid item xs={12} sm={6} md={2.4} key={image.id}>
                  <Box
                    sx={{
                      position: 'relative',
                      cursor: 'pointer',
                      borderRadius: 1,
                      overflow: 'hidden',
                      border: '1px solid #e5e7eb',
                      aspectRatio: '1/1', // Make it square
                      width: '100%',
                      maxWidth: 218,
                      '&:hover': {
                        '& .image-overlay': {
                          opacity: 1,
                        },
                      },
                    }}
                    onClick={() => handleImageSelect(image)}
                  >
                    <Box
                      component='img'
                      src={image.imageUrl}
                      alt={image.prompt}
                      sx={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        display: 'block',
                      }}
                    />

                    <Box
                      className='image-overlay'
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        bgcolor: 'rgba(0, 0, 0, 0.7)',
                        opacity: 0,
                        transition: 'opacity 0.2s',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                        p: 1,
                      }}
                    >
                      <Box sx={{ display: 'none', gap: 1, alignItems: 'center', mb: 0.5 }}>
                        <SearchIcon sx={{ color: 'white', fontSize: '0.9rem' }} />
                        <Typography
                          variant='caption'
                          sx={{
                            color: 'white',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {image.prompt}
                        </Typography>
                      </Box>

                      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                        <CalendarTodayIcon sx={{ color: 'white', fontSize: '0.9rem' }} />
                        <Typography variant='caption' sx={{ color: 'white' }}>
                          {format(image.createdAt.toDate(), 'PP', { locale: sv })}
                        </Typography>
                      </Box>
                    </Box>

                    {/* Corner ribbon with checkmark */}
                    {selectedImage?.id === image.id && (
                      <Box
                        sx={{
                          position: 'absolute',
                          top: 0,
                          right: 0,
                          width: 60,
                          height: 60,
                          overflow: 'hidden',
                          zIndex: 2,
                          '&::before': {
                            content: '""',
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            width: '200%',
                            height: '200%',
                            backgroundColor: '#8b5cf6',
                            transform: 'rotate(45deg) translateY(-70%)',
                          },
                        }}
                      >
                        <CheckIcon
                          sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            color: 'white',
                            fontSize: '2.2rem',
                            zIndex: 3,
                            transform: 'rotate(0deg)',
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                </Grid>
              ))}
            </Grid>
            {hasMore && (
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                <Button
                  variant='outlined'
                  onClick={handleLoadMore}
                  disabled={loadingMore}
                  sx={{ textTransform: 'none' }}
                >
                  {loadingMore ? 'Laddar...' : 'Ladda fler bilder'}
                </Button>
              </Box>
            )}

            <DialogActions
              sx={{
                p: 2,
                borderTop: '1px solid',
                borderColor: 'divider',
                position: 'sticky',
                bottom: 0,
                bgcolor: 'background.paper',
                zIndex: 1,
              }}
            >
              <Button
                variant='contained'
                variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                color='primary'
                onClick={handleConfirmSelection}
                disabled={!selectedImage}
                sx={{ textTransform: 'none', paddingLeft: '30px', paddingRight: '30px' }}
              >
                Välj bild
              </Button>
            </DialogActions>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ImageGalleryDialog;
