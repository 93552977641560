import React, { useState, useRef } from 'react';
import {
  Box,
  Typography,
  Avatar,
  CircularProgress,
  Menu,
  MenuItem,
  IconButton,
} from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import CollectionsIcon from '@mui/icons-material/Collections';
import UploadIcon from '@mui/icons-material/Upload';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { FourSquare } from 'react-loading-indicators';
import ImageGalleryDialog from './ImageGalleryDialog';
import { ref, uploadBytes, getDownloadURL } from './firebase';
import { db, storage } from './firebase';
import { collection, addDoc } from 'firebase/firestore';
import Logger from './Logger';

import { getStyles } from '../index';
const HoverableImage = ({
  imageUrl,
  isLoading,
  loadingIndex,
  currentIndex,
  onGenerateClick,
  userId,
  onImageUpdate,
  height = '400px',
  dividerColor = 'rgba(255, 255, 255, 0.2)',
  theme,
  maxFileSize = 5 * 1024 * 1024,
  acceptedFileTypes = ['image/jpeg', 'image/png', 'image/webp'],
  showSnackbar,
  tinyMode = false,
  size, // New prop for tiny images
}) => {
  // Logger.log('HoverableImage Props:', {
  //   imageUrl: imageUrl || 'none',
  //   isLoading,
  //   loadingIndex: loadingIndex ?? 'none',
  //   currentIndex: currentIndex ?? 'none',
  //   userId: userId || 'none',
  //   height,
  //   dividerColor,
  //   maxFileSize: `${maxFileSize / 1024 / 1024}MB`,
  //   acceptedFileTypes: acceptedFileTypes.join(', '),
  //   tinyMode,
  //   size,
  //   callbacks: {
  //     onGenerateClick: !!onGenerateClick,
  //     onImageUpdate: !!onImageUpdate,
  //     showSnackbar: !!showSnackbar,
  //   },
  // });

  const styles = getStyles(theme);
  const [hoveredSection, setHoveredSection] = useState(null);
  const [showGallery, setShowGallery] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const fileInputRef = useRef(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const isCurrentlyLoading = isLoading && (loadingIndex === null || loadingIndex === currentIndex);

  const handleGallerySelect = selectedImage => {
    onImageUpdate(selectedImage.imageUrl);
    setShowGallery(false);
  };

  const validateFile = file => {
    if (!file) return { isValid: false, error: 'Ingen fil vald.' };

    if (!acceptedFileTypes.includes(file.type)) {
      return {
        isValid: false,
        error: `Ogiltigt filformat. Endast ${acceptedFileTypes.map(type => type.split('/')[1].toUpperCase()).join(', ')} accepteras.`,
      };
    }

    if (file.size > maxFileSize) {
      return {
        isValid: false,
        error: `Filen är för stor. Max storlek är ${maxFileSize / 1024 / 1024}MB.`,
      };
    }

    return { isValid: true };
  };

  const handleFileUpload = async event => {
    const file = event.target.files?.[0];
    const validation = validateFile(file);

    if (!validation.isValid) {
      showSnackbar(validation.error, 'error');
      return;
    }

    if (file) {
      try {
        setIsUploading(true);
        setUploadProgress(0);

        const imageRef = ref(storage, `lessonImages/${userId}/${Date.now()}_${file.name}`);
        await uploadBytes(imageRef, file);
        const downloadURL = await getDownloadURL(imageRef);

        await addDoc(collection(db, 'images'), {
          userId,
          imageUrl: downloadURL,
          createdAt: new Date(),
          uploadedByUser: true,
          fileName: file.name,
          fileSize: file.size,
          fileType: file.type,
        });

        onImageUpdate(downloadURL);
        showSnackbar('Bilden har laddats upp!', 'success');

        setIsUploading(false);
        setUploadProgress(0);

        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      } catch (error) {
        console.error('Error uploading file:', error);
        showSnackbar('Ett fel uppstod vid uppladdningen. Försök igen.', 'error');
        setIsUploading(false);
        setUploadProgress(0);
      }
    }
  };

  // Menu handlers for circular layout
  const handleMenuOpen = event => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleMenuAction = action => {
    handleMenuClose();
    switch (action) {
      case 'generate':
        onGenerateClick();
        break;
      case 'upload':
        fileInputRef.current?.click();
        break;
      case 'gallery':
        setShowGallery(true);
        break;
    }
  };

  // Render different layouts based on tinyMode prop
  return (
    <>
      <Box sx={{ position: 'relative' }}>
        <Avatar
          variant={tinyMode ? 'circular' : 'rounded'}
          src={
            imageUrl ||
            `https://placehold.co/${tinyMode ? size : '500x400'}?text=${tinyMode ? 'Bild' : 'Lägg%20till%20bild'}&font=Raleway`
          }
          alt={`Image ${currentIndex + 1}`}
          sx={{
            width: tinyMode ? size : '100%',
            height: tinyMode ? size : height,
            borderRadius: tinyMode ? '50%' : '8px',
          }}
        />

        <input
          type='file'
          ref={fileInputRef}
          onChange={handleFileUpload}
          accept={acceptedFileTypes.join(',')}
          style={{ display: 'none' }}
        />

        {/* Loading overlay */}
        {(isCurrentlyLoading || isUploading) && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              background: 'rgba(225, 225, 225, 0.92)',
              backdropFilter: 'blur(10px)',
              borderRadius: tinyMode ? '50%' : '8px',
              overflow: 'hidden',
              '&::before': tinyMode
                ? {
                    content: '""',
                    position: 'absolute',
                    inset: -2,
                    border: '4px solid transparent',
                    borderRadius: '50%',
                    background:
                      'linear-gradient(90deg, transparent, #8b5cf6, transparent) border-box',
                    WebkitMask: 'linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)',
                    WebkitMaskComposite: 'destination-out',
                    maskComposite: 'exclude',
                    animation: 'spin 1.5s linear infinite',
                  }
                : {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    height: '4px',
                    background: 'linear-gradient(90deg, transparent, #8b5cf6, transparent)',
                    animation: 'loadingBar 2s ease-in-out infinite',
                  },
              '@keyframes spin': {
                '0%': {
                  transform: 'rotate(0deg)',
                },
                '100%': {
                  transform: 'rotate(360deg)',
                },
              },
              '@keyframes loadingBar': {
                '0%': {
                  transform: 'translateX(-100%)',
                },
                '100%': {
                  transform: 'translateX(100%)',
                },
              },
            }}
          >
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                p: 2,
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  inset: tinyMode ? -4 : -8,
                  border: '2px solid rgba(139, 92, 246, 0.15)',
                  borderRadius: '50%',
                  animation: 'rotate 3s linear infinite',
                },
                '@keyframes rotate': {
                  '0%': {
                    transform: 'rotate(0deg)',
                  },
                  '100%': {
                    transform: 'rotate(360deg)',
                  },
                },
              }}
            >
              <FourSquare
                color='#8b5cf6'
                size={tinyMode ? 'small' : 'medium'}
                text=''
                textColor=''
              />
            </Box>
          </Box>
        )}

        {/* Hover overlay - Different for circular and rectangular */}
        {tinyMode ? (
          // Circular overlay with menu
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
              borderRadius: '50%',
              opacity: 0,
              transition: 'opacity 0.2s',
              '&:hover': {
                opacity: 1,
              },
              pointerEvents: isLoading ? 'none' : 'auto',
              visibility: isLoading ? 'hidden' : 'visible',
            }}
          >
            <IconButton
              onClick={handleMenuOpen}
              sx={{
                color: 'white',
                width: '100%',
                height: '100%',
                borderRadius: '50%',
              }}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={menuAnchorEl}
              open={Boolean(menuAnchorEl)}
              onClose={handleMenuClose}
              PaperProps={{
                sx: {
                  ...styles.roundMenu,
                  borderRadius: '8px',
                },
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <MenuItem onClick={() => handleMenuAction('generate')} sx={styles.menuItemStyle}>
                <ImageIcon fontSize='small' style={{ marginRight: 8, color: '#7B68EE' }} />
                {imageUrl ? 'Generera ny bild' : 'Generera bild'}
              </MenuItem>
              <MenuItem onClick={() => handleMenuAction('upload')} sx={styles.menuItemStyle}>
                <UploadIcon fontSize='small' style={{ marginRight: 8, color: '#7B68EE' }} />
                Ladda upp bild
              </MenuItem>
              <MenuItem onClick={() => handleMenuAction('gallery')} sx={styles.menuItemStyle}>
                <CollectionsIcon fontSize='small' style={{ marginRight: 8, color: '#7B68EE' }} />
                Välj från galleri
              </MenuItem>
            </Menu>
          </Box>
        ) : (
          // Original rectangular overlay with three sections
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              flexDirection: 'column',
              borderRadius: '8px',
              opacity: 0,
              transition: 'opacity 0.2s',
              pointerEvents: isLoading ? 'none' : 'auto',
              visibility: isLoading ? 'hidden' : 'visible',
              overflow: 'hidden',
              '&:hover': {
                opacity: 1,
              },
            }}
          >
            {/* Top section - Generate */}
            <Box
              onClick={onGenerateClick}
              onMouseEnter={() => setHoveredSection('generate')}
              onMouseLeave={() => setHoveredSection(null)}
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor:
                  hoveredSection === 'generate' ? 'rgba(0, 0, 0, 0.85)' : 'rgba(0, 0, 0, 0.7)',
                cursor: 'pointer',
                transition: 'all 0.2s ease',
                zIndex: hoveredSection === 'generate' ? 2 : 1,
                borderBottom: `1px solid ${dividerColor}`,
              }}
            >
              <ImageIcon
                sx={{
                  color: 'white',
                  fontSize: '2rem',
                  mb: 1,
                  transform: hoveredSection === 'generate' ? 'scale(1.1)' : 'scale(1)',
                  transition: 'transform 0.2s ease',
                }}
              />
              <Typography
                sx={{
                  color: 'white',
                  fontSize: '0.9rem',
                  fontWeight: hoveredSection === 'generate' ? 'bold' : 'normal',
                  transform: hoveredSection === 'generate' ? 'scale(1.05)' : 'scale(1)',
                  transition: 'all 0.2s ease',
                }}
              >
                {imageUrl ? 'Generera ny bild' : 'Generera bild'}
              </Typography>
            </Box>

            {/* Bottom half container */}
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
              {/* Bottom left - Upload */}
              <Box
                onClick={() => fileInputRef.current?.click()}
                onMouseEnter={() => setHoveredSection('upload')}
                onMouseLeave={() => setHoveredSection(null)}
                sx={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor:
                    hoveredSection === 'upload' ? 'rgba(0, 0, 0, 0.9)' : 'rgba(0, 0, 0, 0.75)',
                  cursor: 'pointer',
                  transition: 'all 0.2s ease',
                  zIndex: hoveredSection === 'upload' ? 2 : 1,
                  borderRight: `1px solid ${dividerColor}`,
                }}
              >
                <UploadIcon
                  sx={{
                    color: 'white',
                    fontSize: '2rem',
                    mb: 1,
                    transform: hoveredSection === 'upload' ? 'scale(1.1)' : 'scale(1)',
                    transition: 'transform 0.2s ease',
                  }}
                />
                <Typography
                  sx={{
                    color: 'white',
                    fontSize: '0.9rem',
                    fontWeight: hoveredSection === 'upload' ? 'bold' : 'normal',
                    transform: hoveredSection === 'upload' ? 'scale(1.05)' : 'scale(1)',
                    transition: 'all 0.2s ease',
                  }}
                >
                  Ladda upp bild
                </Typography>
              </Box>

              {/* Bottom right - Gallery */}
              <Box
                onClick={() => setShowGallery(true)}
                onMouseEnter={() => setHoveredSection('gallery')}
                onMouseLeave={() => setHoveredSection(null)}
                sx={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor:
                    hoveredSection === 'gallery' ? 'rgba(0, 0, 0, 0.9)' : 'rgba(0, 0, 0, 0.75)',
                  cursor: 'pointer',
                  transition: 'all 0.2s ease',
                  zIndex: hoveredSection === 'gallery' ? 2 : 1,
                }}
              >
                <CollectionsIcon
                  sx={{
                    color: 'white',
                    fontSize: '2rem',
                    mb: 1,
                    transform: hoveredSection === 'gallery' ? 'scale(1.1)' : 'scale(1)',
                    transition: 'transform 0.2s ease',
                  }}
                />
                <Typography
                  sx={{
                    color: 'white',
                    fontSize: '0.9rem',
                    fontWeight: hoveredSection === 'gallery' ? 'bold' : 'normal',
                    transform: hoveredSection === 'gallery' ? 'scale(1.05)' : 'scale(1)',
                    transition: 'all 0.2s ease',
                  }}
                >
                  Välj från galleri
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Box>

      <ImageGalleryDialog
        open={showGallery}
        onClose={() => setShowGallery(false)}
        onSelect={handleGallerySelect}
        userId={userId}
        theme={theme}
      />
    </>
  );
};

export default HoverableImage;
