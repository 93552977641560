const coursePlans = {
  historia: {
    coursePlanLink:
      'https://www.skolverket.se/undervisning/grundskolan/laroplan-och-kursplaner-for-grundskolan/laroplan-lgr22-for-grundskolan-samt-for-forskoleklassen-och-fritidshemmet?url=907561864%2Fcompulsorycw%2Fjsp%2Fsubject.htm%3FsubjectCode%3DGRGRHIS01%26tos%3Dgr&sv.url=12.5dfee44715d35a5cdfa219f',

    courePlanText: `
    <h1>Kursplan - Historia</h1>
  <p>Människans förståelse av det förflutna är inflätad i hennes föreställningar om samtiden och perspektiv på framtiden. På så sätt påverkar det förflutna både våra liv i dag och våra val inför framtiden. Kvinnor och män har i alla tider skapat historiska berättelser för att tolka verkligheten och påverka sin omgivning. Ett historiskt perspektiv ger oss redskap att förstå och förändra vår egen tid.</p>
    <h2>Ämnets syfte</h2>
    <p>
Undervisningen i ämnet historia ska syfta till att eleverna utvecklar sitt his­torie­­medvetande och sin historiska bildning. Det blir möjligt genom att ele­ver­na i undervisningen får utveckla kunskaper om historiska förhållanden och his­­toriska begrepp, får insikter i hur historia skapas genom tolkningar av källor samt utvecklar förståelse för hur historia kan brukas i olika samman­hang. På så sätt får eleverna förståelse för hur det förflutna har format nutiden och därmed präglar våra förväntningar på framtiden.
</p><p>
Undervisningen ska ge eleverna förutsättningar att tillägna sig en historisk referen­sram och en kronologisk överblick över hur kvinnor och män genom tider­na har skapat och förändrat samhällen och kulturer. Därigenom ska ele­ver­na få förståelse för olika kulturella sammanhang och levnadssätt genom his­torien och för historiska skeenden som har påverkat nutiden. Under­vis­ningen ska vidare bidra till att eleverna får förståelse för att varje tids männi­skor måste bedömas utifrån sin samtids villkor och värderingar. Genom att ge pers­pektiv på långa historiska linjer med relevans för nutiden, såsom levnads­vil­lkor, migration och makt, ska undervisningen ge eleverna en fördjupad för­ståel­se för hur historisk utveckling präglas av både kontinuitet och förändring.
<p>
Undervisningen ska bidra till att eleverna utvecklar kunskaper om hur vi kan veta något om det förflutna genom historiskt källmaterial. Eleverna ska också ges förutsättningar att utveckla förmågan att ställa frågor till och värdera källor som ligger till grund för historiska kunskaper. Arbetet med källor ska även ge eleverna förutsättningar att leva sig in i det förflutna.
</p><p>
Undervisningen ska vidare bidra till att eleverna utvecklar förståelse för hur historia används i samhället och i vardagslivet. Därigenom ska eleverna få olika perspektiv på hur bruket av historiska berättelser och referenser kan på­verka människors identiteter, värderingar och föreställningar.
</p><p>
Undervisningen i ämnet historia ska ge eleverna förutsättningar att ut­veck­la
</p>
    <ul>
      <li>kunskaper om händelser, aktörer och förändringsprocesser under olika tidsperioder samt om historiska begrepp och långa historiska linjer,</li>
      <li>förmåga att ställa frågor till historiska källor samt att tolka, kritiskt granska och värdera dessa, och</li>
      <li>förmåga att reflektera över hur historia kan brukas i olika sammanhang och för olika syften.</li>
    </ul>

  `,

    '1-3': {
      sections: [
        {
          title: 'Att leva tillsammans',
          topics: [
            'Migration inom ett land och mellan länder. Vad detta kan ha för orsaker och få för konsekvenser.',
            'Skildringar av människors levnadsvillkor förr i tiden, till exempel i barnlitteratur, sånger och filmer. Minnen berättade av människor som lever nu.',
            'Samtal om och reflektion över moraliska frågor och livsfrågor med betydelse för eleven, till exempel kamratskap, könsroller och döden.',
            'Samtal om och reflektion över normer och regler i elevens livsmiljöer, däribland i skolan och i digitala miljöer.',
            'Trafikregler och hur man beter sig i trafiken på ett säkert sätt.',
          ],
        },
        {
          title: 'Att leva i närområdet',
          topics: [
            'Några utmärkande drag i närområdets natur- och kulturlandskap. Spår av den senaste istiden.',
            'Hemortens historia och vad närområdets platser, byggnader och vardagliga föremål kan berätta om livet där under olika tider.',
            'Kristendomens roll i skolan och på hemorten förr i tiden.',
            'Centrala samhällsfunktioner, till exempel sjukvård, räddningstjänst och skola.',
            'Yrken och verksamheter i närområdet.',
          ],
        },
        {
          title: 'Att leva i världen',
          topics: [
            'Miljöfrågor utifrån elevens vardag, till exempel frågor om trafik, energi och livsmedel.',
            'Rumsliga förutsättningar i natur och miljö för befolkning och bebyggelse, till exempel mark, vatten och klimat.',
            'Namn och läge på världsdelarna och världshaven samt på länder och platser som är betydelsefulla för eleven.',
            'Människans uppkomst, vandringar, samlande och jakt samt förändrade levnadsvillkor i samband med övergången till jordbruk.',
            'Norden befolkas. Människors levnadsvillkor under nordisk stenålder, bronsålder och järnålder.',
            'Berättelser i antik och nordisk mytologi samt i samisk religion.',
            'Några högtider, symboler och berättelser inom kristendom, islam och judendom. Några berättelser ur Bibeln och deras innebörder.',
            'Mänskliga rättigheter inklusive alla människors lika värde och barnets rättigheter i enlighet med FN:s konvention om barnets rättigheter (barnkonventionen).',
            'Grundläggande demokratiska principer. Vad åsikts- och yttrandefrihet samt majoritetsprincipen kan innebära i skolan och i samhället.',
            'Pengars användning och värde. Olika exempel på betalningsformer och vad några vanliga varor och tjänster kan kosta.',
            'Aktuella samhällsfrågor i olika medier.',
          ],
        },
        {
          title: 'Att undersöka verkligheten',
          topics: [
            'Metoder för att söka information, till exempel textläsning, intervjuer och observationer. Samtal om olika källors användbarhet och tillförlitlighet.',
            'Jordgloben, analoga och digitala kartor samt storleksrelationer och väderstreck.',
            'Mentala kartor, till exempel över närområdet, skolvägar eller andra platser som är betydelsefulla för eleven.',
            'Tidslinjer och tidsbegreppen dåtid, nutid och framtid.',
          ],
        },
      ],
    },
    '4-6': {
      sections: [
        {
          title: 'Kulturmöten och statsbildning i Norden, cirka 800–1500',
          topics: [
            'Nordens kulturmöten med andra delar av världen genom vikingars resor och medeltidens handelssystem.',
            'Statsbildning och kristendomens etablerande i Norden samt konsekvenser av dessa förändringar.',
            'Likheter och skillnader i levnadsvillkor för barn, kvinnor och män under tidsperioden.',
            'Vad historiska källor är, till exempel arkeologiska fynd, texter, muntliga berättelser och digitalt material, och hur de kan användas för att ge kunskaper om det förflutna.',
            'Historiebruk kopplat till tidsperioden, till exempel hur vikingar framställs i populärkulturen.',
          ],
        },
        {
          title: 'Maktförhållanden och levnadsvillkor i Norden, cirka 1500–1800',
          topics: [
            'Kampen om den politiska makten i Norden. Reformationen och framväxten av en stark kungamakt i Sverige. Uppror och motstånd mot kungamakten.',
            'Det svenska Östersjöväldet. Orsaker till dess uppkomst och upplösning samt konsekvenser för människor runt Östersjön.',
            'Likheter och skillnader i levnadsvillkor mellan olika grupper i ståndssamhället.',
            'Vad historiska källor från tidsperioden, till exempel brev, dagböcker och kartor, kan berätta om det förflutna.',
            'Historiebruk kopplat till tidsperioden, till exempel i gatunamn, minnesmärken och reklam.',
          ],
        },
        {
          title: 'Folkökning, ändrade maktförhållanden och emigration, cirka 1800–1900',
          topics: [
            'Nya maktförhållanden i Sverige och mellan länder i Norden.',
            'Jordbrukets omvandling och den stora folkökningen samt översiktligt om den tidiga industrialiseringen i Sverige. Olika konsekvenser av detta för barns, kvinnors och mäns levnadsvillkor.',
            'Olika orsaker till och konsekvenser av emigrationen från Sverige.',
            'Vad historiska källor från tidsperioden, till exempel kyrkböcker, fotografier och domstolsprotokoll, kan berätta om det förflutna.',
            'Historiebruk kopplat till tidsperioden, till exempel hur historiska händelser och aktörer framställs i böcker och på museer.',
          ],
        },
      ],
    },
    '7-9': {
      sections: [
        {
          title: 'Samhällsomvandlingar: framväxten av civilisationer och industrisamhällen',
          topics: [
            'Framväxten av högkulturer i olika delar av världen, till exempel i Afrika, Amerika och Asien. Antiken, dess utmärkande drag som epok och dess betydelse för vår egen tid.',
            'Europeisk kolonisation och slavhandel. Konsekvenser av detta för människor och kulturer samt för det ökade globala handelsutbytet mellan Europa, Asien, Afrika och Amerika.',
            'Industrialiseringen i Europa och Sverige. Olika orsaker till industrialiseringen och konsekvenser av denna för människor och miljö.',
            'Revolutioner och framväxten av nya idéer, samhällsklasser och politiska ideologier.',
            'Tolkning av historiska källor från någon tidsperiod och granskning utifrån källkritiska kriterier. Värdering av källornas relevans utifrån historiska frågor.',
            'Analys av historiebruk kopplat till någon tidsperiod, till exempel hur olika aktörer använder historia för att skapa eller stärka nationella identiteter.',
          ],
        },
        {
          title: 'Imperialism och världskrig, cirka 1850–1950',
          topics: [
            'Europeisk nationalism, imperialism och framväxten av olika former av demokrati och diktatur.',
            'De båda världskrigen, deras orsaker och konsekvenser. Förtryck, folkfördrivningar och folkmord. Förintelsen och Gulag. Människors motstånd mot förtryck.',
            'Tolkning av historiska källor från tidsperioden och granskning utifrån källkritiska kriterier. Värdering av källornas relevans utifrån historiska frågor.',
            'Analys av historiebruk kopplat till tidsperioden, till exempel hur olika aktörer använder historia för att skapa opinion eller legitimera makt.',
          ],
        },
        {
          title: 'Demokratisering och ökad globalisering, cirka 1900 till nutid',
          topics: [
            'Demokratiseringen i Sverige och framväxten av välfärdssamhället. Bildandet av politiska partier, nya folkrörelser, till exempel kvinnorörelsen, och kampen för allmän rösträtt för kvinnor och män. Kontinuitet och förändring i synen på kön, jämställdhet och sexualitet.',
            'Kalla krigets konflikter samt nya maktförhållanden och utmaningar i världen efter det kalla krigets slut.',
            'Kontinuitet och förändring med utgångspunkt i långa historiska linjer kring levnadsvillkor, migration och makt.',
            'Tolkning av historiska källor från tidsperioden och granskning utifrån källkritiska kriterier. Värdering av källornas relevans utifrån historiska frågor.',
            'Analys av historiebruk kopplat till tidsperioden, till exempel hur individer och grupper använder historia för att kritisera samtida fenomen och påverka våra föreställningar om framtiden.',
          ],
        },
      ],
    },
  },

  teknik: {
    coursePlanLink:
      'https://www.skolverket.se/undervisning/grundskolan/laroplan-och-kursplaner-for-grundskolan/laroplan-lgr22-for-grundskolan-samt-for-forskoleklassen-och-fritidshemmet?url=907561864%2Fcompulsorycw%2Fjsp%2Fsubject.htm%3FsubjectCode%3DGRGRTEK01%26tos%3Dgr&sv.url=12.5dfee44715d35a5cdfa219f',

    courePlanText: `
    <h1>Kursplan - Teknik</h1>
    <p>Tekniska lösningar har i alla tider varit betydelsefulla för människan och för samhällens utveckling. Drivkrafterna bakom teknikutvecklingen har ofta varit nyfikenhet och en strävan att uppfylla behov eller lösa problem som uppstått. Kunskaper om den teknik som omger oss och hur den formas och förändras kan bidra till utveckling av nya kreativa lösningar och ett ansvarsfullt sätt att förhålla sig till teknik. Sådana kunskaper är betydelsefulla i vår tid då det ställs höga krav på tekniskt kunnande i vardags- och arbetslivet, samtidigt som många av dagens samhällsfrågor har inslag av teknik.</p>

    <h2>Ämnets syfte</h2>
    <p>Undervisningen i ämnet teknik ska syfta till att eleverna utvecklar intresse för och kunskaper om tekniken som omger oss. Eleverna ska ges möjligheter att utveckla förståelse för att teknik har betydelse för och påverkar människan, samhället och miljön. På så sätt kan eleverna utveckla en teknisk medvetenhet och en förmåga att relatera tekniska lösningar och den egna användningen av teknik till frågor som rör hållbar utveckling. Undervisningen ska ge eleverna möjligheter att reflektera över teknikens historiska utveckling. Därmed får de bättre förutsättningar att förstå samtidens tekniska företeelser, hur tekniken och samhällsutvecklingen påverkar varandra samt hur teknik kan användas på ett ansvarsfullt sätt.</p>

    <p>I undervisningen ska eleverna ges möjligheter att använda ämnets begrepp och att utveckla kunskaper om hur tekniska lösningar används och fungerar. Genom att tekniska lösningar görs synliga och begripliga i undervisningen ges eleverna förutsättningar att orientera sig och agera i en teknikintensiv värld.</p>

    <p>Undervisningen ska ge eleverna förutsättningar att genomföra teknikutvecklings- och konstruktionsarbeten. När eleverna praktiskt får arbeta fram egna tekniska idéer och lösningar på problem och använda teknikens uttrycksformer bidrar det till att eleverna utvecklar förmåga att ta sig an tekniska utmaningar på ett medvetet och innovativt sätt. De ges därmed även möjligheter att utveckla kunskaper om teknikens arbetsmetoder.</p>

    <p>Undervisningen i ämnet teknik ska ge eleverna förutsättningar att utveckla</p>
    <ul>
      <li>förmåga att reflektera över olika val av tekniska lösningar, deras konsekvenser för individen, samhället och miljön samt hur tekniken har förändrats över tid,</li>
      <li>kunskaper om tekniska lösningar och hur ingående delar samverkar för att uppnå ändamålsenlighet och funktion, och</li>
      <li>förmåga att genomföra teknikutvecklings- och konstruktionsarbeten.</li>
    </ul>`,

    '1-3': {
      sections: [
        {
          title: 'Teknik, människa, samhälle och miljö',
          topics: [
            'Några föremål och något tekniskt system i elevernas vardag, hur de är anpassade efter människans behov samt hur de har förändrats över tid.',
            'Säkerhet vid elevernas användning av teknik, till exempel elektricitet och olika tjänster via internet.',
          ],
        },
        {
          title: 'Tekniska lösningar',
          topics: [
            'Hur några föremål i elevernas vardag används och fungerar. Enkla mekanismer, till exempel i verktyg och leksaker av olika slag. Begrepp som används i samband med detta.',
            'Vad datorer används till och deras delar för inmatning, utmatning och lagring av information. Föremål i elevernas vardag som styrs med hjälp av programmering, till exempel hushållsmaskiner och smarta telefoner.',
            'Material för konstruktionsarbete. Materialens egenskaper och hur materialen benämns och kan sammanfogas.',
          ],
        },
        {
          title: 'Arbetsmetoder för utveckling av tekniska lösningar',
          topics: [
            'Undersökande av hur några föremål i elevernas vardag är utformade och hur deras funktion kan förbättras.',
            'Egna konstruktioner där man använder enkla mekanismer.',
            'Styrning av föremål med programmering.',
            'Dokumentation av tekniska lösningar: skisser, bilder, ord samt enkla fysiska och digitala modeller.',
          ],
        },
      ],
    },
    '4-6': {
      sections: [
        {
          title: 'Teknik, människa, samhälle och miljö',
          topics: [
            'Några tekniska system och hur de påverkar människa och miljö, till exempel vatten- och avloppssystem och system för återvinning. Hur systemen har förändrats över tid och några orsaker till detta.',
            'Möjligheter, risker och säkerhet vid teknikanvändning i vardagen, till exempel vid användning av elektricitet och vid överföring av information i digitala miljöer.',
            'Konsekvenser av teknikval: olika tekniska lösningars för- och nackdelar för människa och miljö.',
          ],
        },
        {
          title: 'Tekniska lösningar',
          topics: [
            'Hur några komponenter i vanliga tekniska system benämns och samverkar, till exempel i en cykel eller i ett enkelt produktions- eller transportsystem.',
            'Föremål som innehåller rörliga delar och hur de rörliga delarna är sammanfogade med hjälp av olika mekanismer för att överföra och förstärka krafter.',
            'Några av datorns delar och deras funktioner, till exempel processor och arbetsminne. Hur datorer styrs av program och kan kopplas samman i nätverk.',
            'Tekniska lösningar som utnyttjar elkomponenter och enkel elektronik för att åstadkomma ljud, ljus eller rörelse, till exempel larm och belysning. Begrepp som används i samband med detta.',
            'Hur hållfasta och stabila konstruktioner är uppbyggda, till exempel skal, armering och fackverk. Material som används i hållfasta och stabila konstruktioner.',
          ],
        },
        {
          title: 'Arbetsmetoder för utveckling av tekniska lösningar',
          topics: [
            'Teknikutvecklingsarbetets olika faser: identifiering av behov, undersökning, förslag till lösningar, konstruktion och utprövning.',
            'Egna konstruktioner där man använder mekanismer, elektriska kopplingar samt hållfasta och stabila strukturer.',
            'Styrning av egna konstruktioner eller andra föremål med programmering.',
            'Dokumentation av tekniska lösningar: skisser med vyer och måttangivelser, ord samt fysiska och digitala modeller.',
          ],
        },
      ],
    },
    '7-9': {
      sections: [
        {
          title: 'Teknik, människa, samhälle och miljö',
          topics: [
            'Internet och några andra globala tekniska system samt deras fördelar, risker och begränsningar.',
            'Möjligheter, risker och säkerhet vid teknikanvändning i samhället, däribland vid lagring av data.',
            'Konsekvenser av teknikval utifrån ekologiska, ekonomiska och sociala aspekter av hållbar utveckling.',
            'Hur tekniken möjliggjort vetenskapliga upptäckter och hur vetenskapen har möjliggjort tekniska innovationer.',
            'Hur föreställningar om teknik påverkar individers användning av tekniska lösningar och yrkesval.',
          ],
        },
        {
          title: 'Tekniska lösningar',
          topics: [
            'Hur komponenter och delsystem benämns och samverkar inom tekniska system, till exempel informations- och kommunikationsteknik och transportsystem.',
            'Tekniska lösningar för styrning och reglering med hjälp av elektronik och olika typer av sensorer. Hur tekniska lösningar som utnyttjar elektronik kan programmeras. Begrepp som används i samband med detta.',
            'Tekniska lösningar för hållfasta och stabila konstruktioner samt betydelsen av materialens egenskaper, till exempel drag- och tryckhållfasthet, hårdhet och elasticitet.',
            'Bearbetning av råvara till färdig produkt och hantering av avfall i någon industriell process, till exempel vid tillverkning av livsmedel och förpackningar.',
          ],
        },
        {
          title: 'Arbetsmetoder för utveckling av tekniska lösningar',
          topics: [
            'Teknikutvecklingsarbetets olika faser: identifiering av behov, undersökning, förslag till lösningar, konstruktion och utprövning. Hur faserna i arbetsprocessen samverkar i det egna arbetet och i teknikutvecklingsarbeten i samhället, till exempel inom arkitektur och kollektivtrafik.',
            'Hur digitala verktyg kan användas i teknikutvecklingsarbete, till exempel för att göra ritningar och simuleringar.',
            'Egna konstruktioner där man använder styrning eller reglering med hjälp av programmering.',
            'Dokumentation av tekniska lösningar: skisser, ritningar, fysiska och digitala modeller samt rapporter som beskriver teknikutvecklings- och konstruktionsarbeten.',
          ],
        },
      ],
    },
  },

  svenska: {
    coursePlanLink:
      'https://www.skolverket.se/undervisning/grundskolan/laroplan-och-kursplaner-for-grundskolan/laroplan-lgr22-for-grundskolan-samt-for-forskoleklassen-och-fritidshemmet?url=1530314731%2Fcompulsorycw%2Fjsp%2Fsubject.htm%3FsubjectCode%3DGRGRSVE01%26tos%3Dgr',

    courePlanText: `
    <h1>Kursplan - Svenska</h1>
    <p>Språk är människans främsta redskap för att tänka, kommunicera och lära. Genom språket utvecklar människor sin identitet, uttrycker känslor och tankar och förstår hur andra känner och tänker. Att ha ett rikt och varierat språk är betydelsefullt för att kunna förstå och verka i ett samhälle där kulturer, livsåskådningar, generationer och språk möts.</p>
    
    <h2>Ämnets syfte</h2>
    <p>Undervisningen i ämnet svenska ska syfta till att eleverna utvecklar kunskaper i och om svenska språket. Genom undervisningen ska eleverna ges förutsättningar att utveckla sitt tal- och skriftspråk så att de får tilltro till sin språkförmåga och kan uttrycka sig i olika sammanhang och för skilda syften. Därigenom ska eleverna ges möjlighet att utveckla språket för att tänka, kommunicera och lära.</p>
    
    <p>Undervisningen ska stimulera elevernas intresse för att läsa och skriva. Genom undervisningen ska eleverna ges möjlighet att utveckla kunskaper om hur man formulerar egna åsikter och tankar i olika slags texter och genom skilda medier. Undervisningen ska även syfta till att eleverna utvecklar förmågan att skapa och bearbeta texter, enskilt och tillsammans med andra. Eleverna ska ges möjlighet att kommunicera i digitala miljöer med interaktiva och föränderliga texter. Eleverna ska även stimuleras till att uttrycka sig genom olika estetiska uttrycksformer. Vidare ska undervisningen bidra till att eleverna utvecklar kunskaper om hur man söker och kritiskt värderar information från olika källor.</p>
    
    <p>I undervisningen ska eleverna möta samt få utveckla kunskaper om skönlitteratur från olika tider och skilda delar av världen. Eleverna ska ges möjlighet att läsa, analysera och resonera om skönlitterära verk i olika genrer. Undervisningen ska också bidra till att eleverna utvecklar kunskaper om olika former av sakprosa. I mötet med olika typer av texter, scenkonst och annat estetiskt berättande ska eleverna ges förutsättningar att utveckla sitt språk, den egna identiteten och sin förståelse för omvärlden.</p>
    
    <p>Genom undervisningen ska eleverna ges möjlighet att utveckla kunskaper om svenska språket, dess normer, uppbyggnad, historia och utveckling samt om hur språkbruk varierar beroende på sociala sammanhang och medier. På så sätt ska undervisningen bidra till att stärka elevernas medvetenhet om och tilltro till den egna språkliga och kommunikativa förmågan. Undervisningen ska också bidra till att eleverna får förståelse för att sättet man kommunicerar på kan få konsekvenser för en själv och för andra människor. Därigenom ska eleverna ges förutsättningar att ta ansvar för det egna språkbruket i olika sammanhang och medier.</p>
    
    <p>Undervisningen ska även bidra till att eleverna får möta och bekanta sig med såväl de nordiska grannspråken som de nationella minoritetsspråken.</p>
    
    <p>Undervisningen i ämnet svenska ska ge eleverna förutsättningar att utveckla</p>
    <ul>
      <li>förmåga att formulera sig och kommunicera i tal och skrift, urskilja språkliga strukturer och följa språkliga normer,</li>
      <li>förmåga att anpassa språket efter olika syften, mottagare och sammanhang,</li>
      <li>förmåga att läsa och analysera skönlitteratur och andra texter för olika syften,</li>
      <li>förmåga att söka information från olika källor och värdera dessa, och</li>
      <li>kunskaper om språk och språkbruk i Sverige och Norden.</li>
    </ul>`,

    '1-3': {
      sections: [
        {
          title: 'Läsa och skriva',
          topics: [
            'Gemensam och enskild läsning. Sambandet mellan ljud och bokstav. Strategier för att avkoda, förstå och tolka ord, begrepp och texter.',
            'Att återge delar av innehållet i olika texter samt resonera om budskap i texterna och jämföra med egna erfarenheter.',
            'Gemensamt och enskilt skrivande. Strategier för att skriva ord, meningar och olika typer av texter med anpassning till deras uppbyggnad och språkliga drag. Skapande av texter där ord och bild samspelar, såväl med som utan digitala verktyg.',
            'Grundläggande textbearbetning.',
            'Handstil och att skriva med digitala verktyg.',
            'Språkliga strukturer och normer. Grundläggande skrivregler, med gemener och versaler, de vanligaste skiljetecknen samt stavning av vanligt förekommande ord i elevnära texter.',
            'Alfabetet och alfabetisk ordning.',
          ],
        },
        {
          title: 'Tala, lyssna och samtala',
          topics: [
            'Strukturerade samtal. Att berätta om vardagliga händelser samt att uttrycka känslor, kunskaper och åsikter. Att lyssna, ställa frågor och ge kommentarer.',
            'Att lyssna aktivt och återberätta viktiga delar av ett innehåll.',
            'Att ge och ta emot muntliga instruktioner.',
            'Muntliga presentationer och muntligt berättande. Föremål, bilder, digitala medier och verktyg samt andra hjälpmedel som kan stödja presentationer.',
          ],
        },
        {
          title: 'Texter',
          topics: [
            'Skönlitteratur för barn från olika tider och skilda delar av världen. Sånger, muntligt berättande, bilderböcker, kapitelböcker, lyrik, dramatik, sagor och myter. Texter som belyser människors upplevelser och erfarenheter.',
            'Berättande texters budskap, uppbyggnad och innehåll. Hur en berättande text kan organiseras med inledning, händelseförlopp och avslutning samt personbeskrivningar.',
            'Några skönlitterärt betydelsefulla barnboksförfattare och illustratörer.',
            'Sakprosatexter för barn. Beskrivande, förklarande och instruerande texter och hur deras innehåll kan organiseras.',
            'Texter som kombinerar ord och bild samt texter i digitala miljöer för barn.',
          ],
        },
        {
          title: 'Språkbruk',
          topics: [
            'Språkliga strategier för att minnas och lära, till exempel grafiska modeller.',
            'Ord och begrepp för att på ett varierat sätt uttrycka känslor, kunskaper och åsikter.',
            'Hur ord och yttranden uppfattas av omgivningen beroende på kroppsspråk, tonfall och ords nyanser. Språkbruk samt möjligheter och risker vid egen kommunikation i digitala medier.',
            'Skillnader mellan tal- och skriftspråk.',
          ],
        },
        {
          title: 'Informationssökning och källkritik',
          topics: [
            'Informationssökning i böcker, tidskrifter och på webbplatser för barn samt i söktjänster på internet.',
            'Hur texters avsändare påverkar innehållet.',
          ],
        },
      ],
    },
    '4-6': {
      sections: [
        {
          title: 'Läsa och skriva',
          topics: [
            'Gemensam och enskild läsning. Strategier för att förstå och tolka ord, begrepp och texter från olika medier. Att urskilja texters budskap, både det direkt uttalade och sådant som är indirekt uttryckt.',
            'Sammanfattning av texter.',
            'Resonemang om texter med koppling till sammanhang inom och utanför texten samt till den egna läsupplevelsen.',
            'Gemensamt och enskilt skrivande. Strategier för att skriva olika typer av texter med anpassning till deras uppbyggnad och språkliga drag. Skapande av texter där ord, bild och ljud samspelar, såväl med som utan digitala verktyg.',
            'Bearbetning av egna och gemensamma texter till innehåll och form. Att ge och ta emot respons på texter.',
            'Handstil och att skriva med digitala verktyg.',
            'Språkliga strukturer och normer. Meningsbyggnad, med huvudsatser, bisatser och skiljetecken samt textbindning med hjälp av sambandsord. Stavning, ords böjningsformer och ordklasser.',
            'Ordböcker och digitala verktyg för stavning och ordförståelse.',
          ],
        },
        {
          title: 'Tala, lyssna och samtala',
          topics: [
            'Olika former av samtal. Att lyssna aktivt, ställa frågor, uttrycka tankar och känslor samt resonera och argumentera i olika samtalssituationer och i samband med demokratiska beslutsprocesser.',
            'Muntliga presentationer och muntligt berättande för olika mottagare. Disposition med inledning, innehåll och avslutning. Stödord, bilder, digitala medier och verktyg samt andra hjälpmedel för att planera och genomföra en muntlig presentation. Hur gester och kroppsspråk kan påverka en presentation.',
          ],
        },
        {
          title: 'Texter',
          topics: [
            'Skönlitteratur för barn och unga från olika tider, från Sverige, Norden och övriga världen. Lyrik, dramatik, sagor och myter. Texter som belyser människors villkor och identitets- och livsfrågor.',
            'Berättande texters budskap, språkliga drag och uppbyggnad. Tillbakablickar, miljö- och personbeskrivningar samt dialoger.',
            'Några skönlitterärt betydelsefulla barn- och ungdomsboksförfattare och deras verk.',
            'Sakprosatexter för barn och unga. Beskrivande, förklarande, instruerande och argumenterande texter. Texternas innehåll, uppbyggnad och typiska språkliga drag.',
            'Texter som kombinerar ord, bild och ljud samt texter i digitala miljöer. Texternas innehåll, uppbyggnad och typiska språkliga drag.',
          ],
        },
        {
          title: 'Språkbruk',
          topics: [
            'Språkliga strategier för att minnas och lära, till exempel grafiska modeller och stödord.',
            'Ord och begrepp för att på ett varierat sätt uttrycka känslor, kunskaper och åsikter. Ords och begrepps nyanser och värdeladdning.',
            'Formellt och informellt språk. Skillnader i språkanvändning beroende på mottagare, syfte och sammanhang. Ansvarsfullt agerande vid kommunikation i digitala och andra medier.',
            'Språkbruk i Sverige och Norden. Några svenska dialekter. De nordiska grannspråken. De nationella minoritetsspråken i Sverige.',
          ],
        },
        {
          title: 'Informationssökning och källkritik',
          topics: [
            'Informationssökning i några olika medier och källor, till exempel i uppslagsböcker, genom intervjuer och i söktjänster på internet.',
            'Hur man jämför källor och prövar deras tillförlitlighet med ett källkritiskt förhållningssätt.',
          ],
        },
      ],
    },
    '7-9': {
      sections: [
        {
          title: 'Läsa och skriva',
          topics: [
            'Gemensam och enskild läsning. Strategier för att förstå, tolka och analysera texter från olika medier. Att urskilja texters budskap, tema och motiv samt deras syfte, avsändare och sammanhang. Att urskilja innehåll som kan vara direkt uttalat eller indirekt uttryckt i texten.',
            'Sammanfattning av texter.',
            'Analys av texter med koppling till upphovsperson, tid och andra texter samt utifrån egna erfarenheter, olika livsfrågor och omvärldsfrågor.',
            'Gemensamt och enskilt skrivande. Strategier för att skriva olika typer av texter med anpassning till deras uppbyggnad och språkliga drag. Skapande av texter där ord, bild och ljud samspelar.',
            'Disposition och redigering av texter med hjälp av digitala verktyg. Bearbetning av egna och gemensamma texter till innehåll och form. Att ge och ta emot respons på texter.',
            'Språkliga strukturer och normer. Stavning, meningsbyggnad, styckeindelning och olika typer av textbindning. Ordklasser och satsdelar.',
            'Språkliga handböcker och digitala verktyg för ordförståelse, språkriktighet och variation.',
          ],
        },
        {
          title: 'Tala, lyssna och samtala',
          topics: [
            'Olika former av samtal. Att delta aktivt, uttrycka känslor, tankar och kunskaper, lyssna, ställa frågor och föra resonemang, samt formulera och bemöta argument.',
            'Att leda ett samtal och sammanfatta huvuddragen i vad som sagts.',
            'Muntliga presentationer och muntligt berättande för olika mottagare. Anpassning av språk, innehåll och disposition till syfte, mottagare och sammanhang. Talmanus och olika verktyg för att planera och genomföra muntliga presentationer, såväl med som utan digital teknik.',
          ],
        },
        {
          title: 'Texter',
          topics: [
            'Skönlitteratur för ungdomar och vuxna från olika tider, från Sverige, Norden och övriga världen. Epik, lyrik och dramatik. Texter som belyser människors villkor och identitets- och livsfrågor.',
            'Språkliga drag, uppbyggnad och berättarperspektiv i berättande och poetiska texter. Bildspråk, gestaltningar, parallellhandling, tillbakablickar, miljö- och personbeskrivningar samt dialoger.',
            'Några skönlitterära genrer och hur de stilistiskt och innehållsligt skiljer sig ifrån varandra.',
            'Några skönlitterärt betydelsefulla författare och deras verk samt de historiska och kulturella sammanhang som verken har tillkommit i.',
            'Sakprosatexter för ungdomar och vuxna. Beskrivande, förklarande, utredande, instruerande och argumenterande texter. Texternas syfte, innehåll, uppbyggnad och språkliga drag. Kombinationer av olika texttyper.',
            'Texter i digitala miljöer samt andra texter som kombinerar ord, bild och ljud. Språkliga och dramaturgiska komponenter samt hur uttrycken kan samspela med varandra, till exempel i filmiskt berättande, teaterföreställningar och webbtexter.',
          ],
        },
        {
          title: 'Språkbruk',
          topics: [
            'Språkliga strategier för att minnas och lära genom att identifiera nyckelord och föra anteckningar.',
            'Ord och begrepp för att på ett varierat sätt uttrycka känslor, kunskaper och åsikter. Ords och begrepps nyanser och värdeladdning.',
            'Skillnader i språkanvändning beroende på syfte, mottagare och sammanhang. Språkets betydelse för att utöva inflytande.',
            'Språkbruk, yttrandefrihet och integritet i digitala och andra medier och i olika sammanhang.',
            'Språkbruk i Sverige och Norden. Några svenska dialekter och sociolekter. Framträdande skillnader och likheter mellan de nordiska språken. De nationella minoritetsspråken i Sverige och deras ställning i samhället.',
            'Språkbruk och språkförändring genom tiderna, till exempel vilka språk som påverkat svenskan historiskt och i nutid.',
          ],
        },
        {
          title: 'Informationssökning och källkritik',
          topics: [
            'Informationssökning på bibliotek och på internet, i böcker och massmedier samt genom intervjuer.',
            'Hur man refererar, citerar och gör källhänvisningar, även referenser till digitala medier.',
            'Hur man sovrar i en stor informationsmängd och prövar källors tillförlitlighet med ett källkritiskt förhållningssätt.',
          ],
        },
      ],
    },
  },

  samhällskunskap: {
    coursePlanLink:
      'https://www.skolverket.se/undervisning/grundskolan/laroplan-och-kursplaner-for-grundskolan/laroplan-lgr22-for-grundskolan-samt-for-forskoleklassen-och-fritidshemmet?url=907561864%2Fcompulsorycw%2Fjsp%2Fsubject.htm%3FsubjectCode%3DGRGRSGR01%26tos%3Dgr',

    courePlanText: `
      <h1>Kursplan - Samhällskunskap</h1>
      <p>Människor har alltid varit beroende av att samarbeta när de skapar och utvecklar samhällen. I dag står människor i olika delar av världen inför både möjligheter och problem kopplade till globalisering, interkulturella relationer och digitalisering. Kunskaper om samhället ger oss verktyg så att vi kan orientera oss i en komplex värld, ta ansvar för vårt handlande och främja hållbar utveckling.</p>
      <h2>Ämnets syfte</h2>
      <p>Undervisningen i ämnet samhällskunskap ska syfta till att eleverna utvecklar förtrogenhet med demokrati och mänskliga rättigheter. Den ska bidra till att eleverna tillägnar sig kunskaper om värden och principer som utmärker ett demokratiskt samhälle samt om demokratiska processer och arbetssätt. På så sätt ska undervisningen ge eleverna förståelse för vad det innebär att vara en aktiv och ansvarstagande medborgare.</p>
      <p>Undervisningen ska bidra till att eleverna utvecklar förståelse för hur individen och samhället påverkar varandra. Därför ska eleverna ges förutsättningar att utveckla kunskaper om sociala, ekonomiska, politiska, rättsliga och mediala förhållanden och strukturer i samhället. Vidare ska undervisningen ge eleverna förutsättningar att utveckla förståelse för sina egna och andras levnadsvillkor och hur de kan påverkas av faktorer som kön och socioekonomisk bakgrund.</p>
      <p>Undervisningen ska ge eleverna förutsättningar att analysera samhällsfrågor ur olika perspektiv samt möjlighet att utveckla förståelse för hur olika intressen och åsikter uppstår och kommer till uttryck. Eleverna ska också ges verktyg att kritiskt granska hur olika aktörer försöker påverka samhällsutvecklingen genom information, ståndpunkter och argument i olika sammanhang och källor. Genom undervisningen ska eleverna vidare ges möjlighet att uttrycka och pröva sina ställningstaganden i möten med andra uppfattningar. Därigenom ska eleverna stimuleras att engagera sig och delta i ett öppet meningsutbyte om samhällsfrågor.</p>
      <p>Undervisningen i ämnet samhällskunskap ska ge eleverna förutsättningar att utveckla</p>
      <ul>
        <li>kunskaper om demokratiska värden och beslutsprocesser samt om mänskliga rättigheter,</li>
        <li>kunskaper om sociala, ekonomiska, politiska, rättsliga och mediala förhållanden och strukturer i samhället, och</li>
        <li>förmåga att analysera samhällsfrågor ur olika perspektiv och kritiskt granska hur de framställs i olika källor.</li>
      </ul>`,

    '1-3': {
      sections: [
        {
          title: 'Att leva tillsammans',
          topics: [
            'Migration inom ett land och mellan länder. Vad detta kan ha för orsaker och få för konsekvenser.',
            'Skildringar av människors levnadsvillkor förr i tiden, till exempel i barnlitteratur, sånger och filmer. Minnen berättade av människor som lever nu.',
            'Samtal om och reflektion över moraliska frågor och livsfrågor med betydelse för eleven, till exempel kamratskap, könsroller och döden.',
            'Samtal om och reflektion över normer och regler i elevens livsmiljöer, däribland i skolan och i digitala miljöer.',
            'Trafikregler och hur man beter sig i trafiken på ett säkert sätt.',
          ],
        },
        {
          title: 'Att leva i närområdet',
          topics: [
            'Några utmärkande drag i närområdets natur- och kulturlandskap. Spår av den senaste istiden.',
            'Hemortens historia och vad närområdets platser, byggnader och vardagliga föremål kan berätta om livet där under olika tider.',
            'Kristendomens roll i skolan och på hemorten förr i tiden.',
            'Centrala samhällsfunktioner, till exempel sjukvård, räddningstjänst och skola.',
            'Yrken och verksamheter i närområdet.',
          ],
        },
        {
          title: 'Att leva i världen',
          topics: [
            'Miljöfrågor utifrån elevens vardag, till exempel frågor om trafik, energi och livsmedel.',
            'Rumsliga förutsättningar i natur och miljö för befolkning och bebyggelse, till exempel mark, vatten och klimat.',
            'Namn och läge på världsdelarna och världshaven samt på länder och platser som är betydelsefulla för eleven.',
            'Människans uppkomst, vandringar, samlande och jakt samt förändrade levnadsvillkor i samband med övergången till jordbruk.',
            'Norden befolkas. Människors levnadsvillkor under nordisk stenålder, bronsålder och järnålder.',
            'Berättelser i antik och nordisk mytologi samt i samisk religion.',
            'Några högtider, symboler och berättelser inom kristendom, islam och judendom. Några berättelser ur Bibeln och deras innebörder.',
            'Mänskliga rättigheter inklusive alla människors lika värde och barnets rättigheter i enlighet med FN:s konvention om barnets rättigheter (barnkonventionen).',
            'Grundläggande demokratiska principer. Vad åsikts- och yttrandefrihet samt majoritetsprincipen kan innebära i skolan och i samhället.',
            'Pengars användning och värde. Olika exempel på betalningsformer och vad några vanliga varor och tjänster kan kosta.',
            'Aktuella samhällsfrågor i olika medier.',
          ],
        },
        {
          title: 'Att undersöka verkligheten',
          topics: [
            'Metoder för att söka information, till exempel textläsning, intervjuer och observationer. Samtal om olika källors användbarhet och tillförlitlighet.',
            'Jordgloben, analoga och digitala kartor samt storleksrelationer och väderstreck.',
            'Mentala kartor, till exempel över närområdet, skolvägar eller andra platser som är betydelsefulla för eleven.',
            'Tidslinjer och tidsbegreppen dåtid, nutid och framtid.',
          ],
        },
      ],
    },
    '4-6': {
      sections: [
        {
          title: 'Individer och gemenskaper',
          topics: [
            'Sociala roller och normer i olika sammanhang, till exempel inom familjen och i vänskapsrelationer. Könsroller, jämställdhet och sexualitet.',
            'Sociala skyddsnät för barn i olika livssituationer, i skolan och i samhället.',
          ],
        },
        {
          title: 'Samhällsresurser och fördelning',
          topics: [
            'Privatekonomi och relationen mellan arbete, inkomst och konsumption.',
            'Det offentligas ekonomi. Vad skatter är och vad kommuner, regioner och stat använder skattemedel till.',
            'Exempel på skilda ekonomiska och sociala villkor för barn, i Sverige och i olika delar av världen.',
          ],
        },
        {
          title: 'Beslutsfattande och politiska idéer',
          topics: [
            'Vad demokrati är och hur demokratiska beslut fattas. Hur individer och grupper kan påverka beslut, genom att rösta i allmänna val och till exempel genom elevråd i skolan eller genom att skapa opinion i sociala medier.',
            'Riksdagen och regeringen och deras olika uppdrag. Politiska val och partier i Sverige. Skiljelinjer i några aktuella politiska frågor.',
          ],
        },
        {
          title: 'Rättigheter och rättsskipning',
          topics: [
            'Samhällets behov av lagstiftning samt några olika lagar och påföljder. Kriminalitet och möjliga konsekvenser för individ och samhälle.',
            'De mänskliga rättigheterna och deras betydelse, inklusive barnets rättigheter i enlighet med barnkonventionen. Principen om likabehandling, inklusive skydd mot diskriminering.',
            'De nationella minoriteterna judar, romer, urfolket samerna, sverigefinnar och tornedalingar: kultur, historia och rättigheter.',
          ],
        },
        {
          title: 'Information och kommunikation',
          topics: [
            'Mediernas roll som informationsspridare, opinionsbildare och granskare av samhällets makthavare samt som underhållare.',
            'Hur digitala och andra medier kan användas ansvarsfullt utifrån sociala, etiska och rättsliga aspekter.',
          ],
        },
        {
          title: 'Granskning av samhällsfrågor',
          topics: [
            'Aktuella samhällsfrågor och olika perspektiv på dessa.',
            'Hur budskap, avsändare och syfte kan urskiljas och granskas med ett källkritiskt förhållningssätt i såväl digitala medier som i andra typer av källor som rör samhällsfrågor.',
          ],
        },
      ],
    },
    '7-9': {
      sections: [
        {
          title: 'Individer och gemenskaper',
          topics: [
            'Människors identiteter, ekonomiska resurser och möjligheter i samhället och hur dessa kan påverkas av bland annat socioekonomisk bakgrund, kön, ålder och etnicitet. Begreppen makt, rättvisa, jämlikhet och jämställdhet.',
            'Svenska välfärdsstrukturer och hur de fungerar, till exempel sjukvårdssystemet och arbetslöshetsförsäkringen.',
            'Migration till, samt integration och segregation i Sverige i dag.',
          ],
        },
        {
          title: 'Samhällsresurser och fördelning',
          topics: [
            'Hur hushållens, företagens, bankernas och det offentligas ekonomi hänger samman. Orsaker till förändringar i samhällsekonomin och vilka effekter de kan få.',
            'Länders och regioners beroende av varandra i en globaliserad ekonomi. Skilda förutsättningar för olika länder och regioner.',
            'Orsaker till och konsekvenser av en ojämlik fördelning av inkomster och förmögenheter mellan människor i Sverige och i olika delar av världen.',
            'Arbetsmarknadens och arbetslivets förändringar och villkor, till exempel med avseende på lönebildning, arbetsmiljö och arbetsrätt.',
          ],
        },
        {
          title: 'Beslutsfattande och politiska idéer',
          topics: [
            'Vad begreppen demokrati och diktatur, monarki och republik samt parlamentarism och presidentstyre innebär. Exempel på hur dessa olika stats- och styrelseskick kan fungera.',
            'Politiska ideologier och skiljelinjer mellan politiska partier i Sverige.',
            'Sveriges politiska system med Europeiska unionen, riksdag, regering, Sametinget, regioner och kommuner. Sveriges grundlagar.',
            'Var olika beslut fattas och exempel på hur besluten påverkar individer, grupper och samhället i stort. Individers och gruppers möjligheter att påverka den demokratiska processen.',
            'FN:s syfte, huvuduppgifter och verksamhet samt det nordiska samarbetets bakgrund och innehåll.',
          ],
        },
        {
          title: 'Rättigheter och rättsskipning',
          topics: [
            'Kränkningar av de mänskliga rättigheterna i olika delar av världen och internationellt arbete för att främja mänskliga rättigheter.',
            'De nationella minoriteternas situation i Sverige samt den svenska minoritetspolitiken och dess framväxt. Samernas ställning som urfolk.',
            'Friheter, rättigheter och skyldigheter i demokratiska samhällen. Dilemman som hänger samman med demokratiska rättigheter och skyldigheter, till exempel gränsen mellan yttrandefrihet och kränkningar i sociala medier.',
            'Rättssystemet i Sverige och principer för rättssäkerhet. Möjliga orsaker till och konsekvenser av olika typer av kriminalitet, till exempel korruption, våldsbrott, sexualbrott och hedersrelaterat våld och förtryck. Kriminalvårdens uppgifter.',
          ],
        },
        {
          title: 'Information och kommunikation',
          topics: [
            'Hur media produceras, distribueras och konsumeras samt vilka möjligheter och svårigheter det kan innebära för mediernas roll i ett demokratiskt samhälle.',
            'Nyhetsvärdering och hur den kan påverka människors bilder av omvärlden. Hur individer och grupper framställs i media, till exempel utifrån kön och etnicitet, och hur detta kan påverka normbildning och värderingar.',
          ],
        },
        {
          title: 'Granskning av samhällsfrågor',
          topics: [
            'Lokala, nationella och globala samhällsfrågor och olika perspektiv på dessa.',
            'Kritisk granskning av information, ståndpunkter och argument som rör samhällsfrågor i såväl digitala medier som i andra typer av källor.',
          ],
        },
      ],
    },
  },

  religionskunskap: {
    coursePlanLink:
      'https://www.skolverket.se/undervisning/grundskolan/laroplan-och-kursplaner-for-grundskolan/laroplan-lgr22-for-grundskolan-samt-for-forskoleklassen-och-fritidshemmet?url=907561864%2Fcompulsorycw%2Fjsp%2Fsubject.htm%3FsubjectCode%3DGRGRREL01%26tos%3Dgr&sv.url=12.5dfee44715d35a5cdfa219f',

    courePlanText: `
    <h1>Kursplan - Religionskunskap</h1>
    <p>Människor har i alla tider och alla samhällen försökt att förstå och förklara sina levnadsvillkor och de sociala sammanhang som de ingår i. Religioner och andra livsåskådningar är därför centrala inslag i den mänskliga kulturen. I dagens samhälle, som är präglat av mångfald, är kunskaper om religioner och andra livsåskådningar viktiga för att skapa ömsesidig förståelse mellan människor.</p>
    
    <h2>Ämnets syfte</h2>
    <p>Undervisningen i ämnet religionskunskap ska syfta till att eleverna utvecklar kun­skaper om religion och livsåskådning i det svenska samhället och i olika de­lar av världen. Genom undervisningen ska eleverna få förståelse för hur männi­skor inom olika religiösa traditioner lever med och uttrycker sin religion på olika sätt. Eleverna ska också ges möjlighet att reflektera över vad religion och livsåskådning kan betyda för människors identitet och hur egna ut­gångs­punkter påverkar förståelsen av religion och livsåskådning.</p>
    
    <p>Undervisningen ska allsidigt belysa vilken roll religion kan spela i sam­hället och hur samhällsförhållanden påverkar utvecklingen av religioner och and­ra livsåskådningar. Genom undervisningen ska eleverna få kunskaper om hur kristna traditioner har påverkat det svenska samhället och hur kristen­domens roll i samhällslivet har förändrats över tid.</p>
    
    <p>Undervisningen ska stimulera eleverna att reflektera över olika livsfrågor och etiska förhållningssätt samt ge eleverna verktyg för att kunna analysera och ta ställning i etiska och moraliska frågor. På så sätt ska undervisningen bid­ra till elevernas möjligheter att utveckla en personlig livshållning och bered­skap att handla ansvarsfullt i förhållande till sig själva och sin om­giv­ning.</p>
    
    <p>Undervisningen i ämnet religionskunskap ska ge eleverna förutsättningar att utveckla</p>
    <ul>
      <li>kunskaper om religioner och andra livsåskådningar samt om olika tolkningar och varierande praktiker inom dessa,</li>
      <li>förmåga att kritiskt granska frågor som rör relationen mellan religion och samhälle, och</li>
      <li>förmåga att resonera om etik, moraliska frågor och livsfrågor utifrån olika perspektiv.</li>
    </ul>`,

    '1-3': {
      sections: [
        {
          title: 'Att leva tillsammans',
          topics: [
            'Migration inom ett land och mellan länder. Vad detta kan ha för orsaker och få för konsekvenser.',
            'Skildringar av människors levnadsvillkor förr i tiden, till exempel i barn­litte­ratur, sånger och filmer. Minnen berättade av människor som lever nu.',
            'Samtal om och reflektion över moraliska frågor och livsfrågor med be­ty­delse för eleven, till exempel kamratskap, könsroller och döden.',
            'Samtal om och reflektion över normer och regler i elevens livsmiljöer, däri­bland i skolan och i digitala miljöer.',
            'Trafikregler och hur man beter sig i trafiken på ett säkert sätt.',
          ],
        },
        {
          title: 'Att leva i närområdet',
          topics: [
            'Några utmärkande drag i närområdets natur- och kulturlandskap. Spår av den senaste istiden.',
            'Hemortens historia och vad närområdets platser, byggnader och vardagliga före­mål kan berätta om livet där under olika tider.',
            'Kristendomens roll i skolan och på hemorten förr i tiden.',
            'Centrala samhällsfunktioner, till exempel sjukvård, räddningstjänst och sko­la.',
            'Yrken och verksamheter i närområdet.',
          ],
        },
        {
          title: 'Att leva i världen',
          topics: [
            'Miljöfrågor utifrån elevens vardag, till exempel frågor om trafik, energi och livsmedel.',
            'Rumsliga förutsättningar i natur och miljö för befolkning och bebyggelse, till exempel mark, vatten och klimat.',
            'Namn och läge på världsdelarna och världshaven samt på länder och platser som är betydelsefulla för eleven.',
            'Människans uppkomst, vandringar, samlande och jakt samt förändrade lev­nads­villkor i samband med övergången till jordbruk.',
            'Norden befolkas. Människors levnadsvillkor under nordisk stenålder, brons­ålder och järnålder.',
            'Berättelser i antik och nordisk mytologi samt i samisk religion.',
            'Några högtider, symboler och berättelser inom kristendom, islam och ju­den­dom. Några berättelser ur Bibeln och deras innebörder.',
            'Mänskliga rättigheter inklusive alla människors lika värde och bar­nets rättig­heter i enlighet med FN:s konvention om barnets rättigheter (barn­konventionen).',
            'Grundläggande demokratiska principer. Vad åsikts- och yttrandefrihet samt majoritetsprincipen kan innebära i skolan och i samhället.',
            'Pengars användning och värde. Olika exempel på betalningsformer och vad några vanliga varor och tjänster kan kosta.',
            'Aktuella samhällsfrågor i olika medier.',
          ],
        },
        {
          title: 'Att undersöka verkligheten',
          topics: [
            'Metoder för att söka information, till exempel textläsning, intervjuer och obser­vationer. Samtal om olika källors användbarhet och tillförlitlighet.',
            'Jordgloben, analoga och digitala kartor samt storleksrelationer och väder­streck.',
            'Mentala kartor, till exempel över närområdet, skolvägar eller andra platser som är betydelsefulla för eleven.',
            'Tidslinjer och tidsbegreppen dåtid, nutid och framtid.',
          ],
        },
      ],
    },
    '4-6': {
      sections: [
        {
          title: 'Religioner och andra livsåskådningar',
          topics: [
            'Religionens betydelse i människors liv och människors olika sätt att uttrycka sin religiositet.',
            'Ritualer och religiöst motiverade levnadsregler samt heliga platser och rum i kristendom, islam och judendom.',
            'Centrala tankegångar med koppling till ritualer, levnadsregler och heliga plat­ser inom kristendom, islam och judendom, till exempel som de uttrycks i berättelser i Bibeln och andra religiösa urkunder.',
            'Några huvuddrag inom hinduism och buddhism.',
            'Likheter och skillnader mellan och inom några religioner.',
            'Begreppen religion och livsåskådning.',
          ],
        },
        {
          title: 'Religion och samhälle',
          topics: [
            'Hur religioner framställs och representeras på olika sätt i media och i andra samman­hang och hur det kan påverka människors bilder av sig själva och andra.',
            'Kristendomens betydelse för värderingar och kultur i det svenska samhället förr och nu.',
            'Människors religiösa och livsåskådningsmässiga tillhörigheter i Sverige i dag samt hur detta har förändrats över tid.',
          ],
        },
        {
          title: 'Etik och livsfrågor',
          topics: [
            'Samtal om och reflektion över vardagliga moraliska frågor utifrån ele­ver­nas egna argument och olika religiösa tolkningar. Sådana frågor kan till exempel handla om ansvar, utanförskap, kränkningar, jämställdhet och sexua­litet.',
            'Samtal om och reflektion över livsfrågor utifrån elevernas egna tankar och olika religiösa tolkningar. Sådana frågor kan till exempel handla om vad som är viktigt i livet och olika föreställningar om vad som händer efter döden.',
          ],
        },
      ],
    },
    '7-9': {
      sections: [
        {
          title: 'Religioner och andra livsåskådningar',
          topics: [
            'Vad religion och livsåskådning kan betyda för människors behov av me­ning, gemenskap, identitet och förståelse av omvärlden.',
            'Centrala tankegångar inom kristendom, islam, judendom, hinduism och budd­hism.',
            'Likheter och skillnader i religiösa tolkningar och praktiker mellan och inom olika religioner. Huvudinriktningar inom kristendom och andra religioner.',
            'Tolkning av och samtal om religiös och livsåskådningsmässig symbolik och mening i Bibeln och andra religiösa urkunder, samt i ritualer och este­ti­ska uttryck.',
            'Religionernas uppkomst, spridning och geografiska utbredning i dag.',
            'Orientering om sekulära livsåskådningar och livshållningar, till exempel hu­manism och ateism.',
          ],
        },
        {
          title: 'Religion och samhälle',
          topics: [
            'Kritisk granskning av hur religion och religiositet framställs och rep­re­sen­te­ras på olika sätt i media och i andra sammanhang och hur det kan påverka in­di­vider och samhället i stort.',
            'Från enhetskyrka till religiös mångfald och sekularisering i det svenska sam­hället. Religionens betydelse i samhället i Sverige jämfört med några andra delar av världen.',
            'Konflikter och samförstånd mellan olika religiösa och olika sekulära syn­sätt, till exempel i frågor om religionsfrihet, sexualitet och synen på kvinnors och mäns roller.',
          ],
        },
        {
          title: 'Etik och livsfrågor',
          topics: [
            'Grundprinciper inom några etiska modeller, till exempel konsekvensetik och pliktetik.',
            'Analys av och reflektion över etiska frågor utifrån elevernas egna argument samt utifrån tolkningar inom religioner och andra livsåskådningar och uti­från etiska modeller. Sådana frågor kan till exempel handla om frihet, rätt­visa och solidaritet.',
            'Samtal om och reflektion över livsfrågor utifrån elevernas egna tankar samt uti­från tolkningar inom religioner och andra livsåskådningar. Sådana frå­gor kan till exempel handla om identitet, kärlek, sexualitet och meningen med livet.',
          ],
        },
      ],
    },
  },

  modersmål: {
    coursePlanLink:
      'https://www.skolverket.se/undervisning/grundskolan/laroplan-och-kursplaner-for-grundskolan/laroplan-lgr22-for-grundskolan-samt-for-forskoleklassen-och-fritidshemmet?url=907561864%2Fcompulsorycw%2Fjsp%2Fsubject.htm%3FsubjectCode%3DGRGRMDR01%26tos%3Dgr&sv.url=12.5dfee44715d35a5cdfa219f',

    courePlanText: `
    <h1>Kursplan - Modersmål utom nationella minoritetsspråk</h1>
    <p>Språk är människans främsta redskap för att tänka, kommunicera och lära. Genom språket utvecklar människor sin identitet, uttrycker känslor och tankar och förstår hur andra känner och tänker. Att ha ett rikt och varierat språk är betydelsefullt för att kunna förstå och verka i ett samhälle där kulturer, livsåskådningar, generationer och språk möts. Att ha tillgång till sitt modersmål underlättar också språkutveckling och lärande inom olika områden.</p>

    <h2>Ämnets syfte</h2>
    <p>Undervisningen i ämnet modersmål ska syfta till att eleverna utvecklar kunskaper i och om modersmålet. Genom undervisningen ska eleverna ges möjlighet att utveckla sitt tal- och skriftspråk så att de får tilltro till sin språkförmåga och kan uttrycka sig i olika sammanhang och för skilda syften. Undervisningen ska bidra till att eleverna erövrar kunskaper om modersmålets uppbyggnad och blir medvetna om modersmålets betydelse för det egna lärandet i olika skolämnen.</p>
    
    <p>Undervisningen ska stimulera elevernas intresse för att samtala, läsa och skriva på modersmålet. Genom undervisningen ska eleverna ges möjlighet att läsa, analysera och resonera om skönlitterära verk i olika genrer. Därigenom ska eleverna ges möjlighet att utveckla sitt språk, sin identitet och förståelse för omvärlden. Undervisningen ska också bidra till att eleverna utvecklar kunskaper i att formulera egna åsikter och tankar i olika slags texter. De ska även stimuleras till att uttrycka sig genom andra estetiska uttrycksformer.</p>
    
    <p>Undervisningen ska ge eleverna förutsättningar att utveckla sin kulturella identitet och sin flerspråkighet. Genom undervisningen ska eleverna därför ges möjligheter att utveckla sina kunskaper om kulturer och områden där modersmålet talas. Undervisningen ska också bidra till att eleverna utvecklar ett jämförande förhållningssätt till kulturer och språk.</p>

    <p>Undervisningen i ämnet modersmål ska ge eleverna förutsättningar att utveckla</p>
    <ul>
      <li>förmåga att formulera sig och kommunicera i tal och skrift, urskilja språkliga strukturer och följa språkliga normer,</li>
      <li>förmåga att anpassa språket efter olika syften, mottagare och sammanhang,</li>
      <li>förmåga att läsa och analysera skönlitteratur och andra texter för olika syften,</li>
      <li>förmåga att använda sitt modersmål som ett medel för sin språkutveckling och sitt lärande, och</li>
      <li>kunskaper om traditioner, kulturella företeelser och samhällsfrågor i områden där modersmålet talas i jämförelse med förhållanden i Sverige.</li>
    </ul>
    `,

    '1-3': {
      sections: [
        {
          title: 'Läsa och skriva',
          topics: [
            'Lässtrategier för att förstå och tolka texter från olika medier samt för att anpassa läsningen efter textens form och innehåll.',
            'Strategier för skrivande av olika typer av texter om ämnen som är välbekanta för eleven.',
            'Läsriktning och skrivteckens form och ljud i jämförelse med svenska.',
            'Ordföljd och interpunktion samt stavningsregler för vanligt förekommande ord i elevnära texter. Jämförelser med svenskans ordföljd, interpunktion och stavningsregler.',
          ],
        },
        {
          title: 'Tala, lyssna och samtala',
          topics: [
            'Samtal om vardagliga företeelser och händelser.',
            'Muntliga presentationer och muntligt berättande med anpassning till olika mottagare. Bilder och andra hjälpmedel som kan stödja presentationen.',
            'Uttal, betoning och satsmelodi samt uttalets betydelse för att göra sig förstådd.',
            'Modersmålets uttal i jämförelse med svenskans.',
          ],
        },
        {
          title: 'Texter',
          topics: [
            'Berättande texter och poetiska texter för barn i form av bilderböcker, kapitelböcker, dikter, sagor och sägner från områden där modersmålet talas.',
            'Beskrivande och förklarande texter för barn med anknytning till traditioner och företeelser i områden där modersmålet talas.',
            'Muntliga traditioner ur modersmålets tradition, till exempel rim, ramsor och gåtor.',
          ],
        },
        {
          title: 'Språkbruk',
          topics: [
            'Ord och begrepp för att uttrycka känslor, kunskaper och åsikter.',
            'Ord och begrepp med anknytning till traditioner och företeelser i områden där modersmålet talas.',
          ],
        },
        {
          title: 'Kultur och samhälle',
          topics: [
            'Traditioner och högtider som eleven möter i olika sammanhang.',
            'Lekar och musik från områden där modersmålet talas.',
          ],
        },
      ],
    },
    '4-6': {
      sections: [
        {
          title: 'Läsa och skriva',
          topics: [
            'Lässtrategier för att förstå och tolka texter från olika medier samt för att urskilja texters budskap, både det direkt uttalade och sådant som är indirekt uttryckt.',
            'Strategier för skrivande av olika typer av texter med anpassning till deras uppbyggnad och språkliga drag.',
            'Modersmålets grundläggande struktur i jämförelse med svenskans.',
            'Ordböcker och andra hjälpmedel för stavning och ordförståelse.',
          ],
        },
        {
          title: 'Tala, lyssna och samtala',
          topics: [
            'Samtal om egna och andras upplevelser samt om vardagliga företeelser och händelser.',
            'Muntliga presentationer och muntligt berättande med anpassning till olika mottagare. Stödord, bilder, digitala medier och verktyg samt andra hjälpmedel för att planera och genomföra en muntlig presentation.',
            'Uttal, betoning och satsmelodi samt uttalets betydelse för att göra sig förstådd.',
            'Modersmålets uttal i jämförelse med svenskans.',
          ],
        },
        {
          title: 'Texter',
          topics: [
            'Berättande texter och poetiska texter för barn och unga i form av skönlitteratur, sagor och sägner från områden där modersmålet talas. Texter som belyser människors villkor och identitets- och livsfrågor.',
            'Beskrivande, förklarande och instruerande texter för barn och unga med anknytning till traditioner och företeelser i områden där modersmålet talas.',
            'Texters innehåll, typiska språkliga drag samt deras ord och begrepp.',
          ],
        },
        {
          title: 'Språkbruk',
          topics: [
            'Ord och begrepp för att uttrycka känslor, kunskaper och åsikter. Ords och begrepps nyanser och värdeladdning.',
            'Synonymer, motsatsord och andra relationer mellan ord.',
          ],
        },
        {
          title: 'Kultur och samhälle',
          topics: [
            'Seder, bruk och traditioner i områden där modersmålet talas i jämförelse med svenska seder, bruk och traditioner.',
            'Elevnära företeelser i områden där modersmålet talas i jämförelse med elevnära företeelser i Sverige.',
          ],
        },
      ],
    },
    '7-9': {
      sections: [
        {
          title: 'Läsa och skriva',
          topics: [
            'Lässtrategier för att förstå, tolka och analysera olika typer av texter från olika medier. Att urskilja texters syfte, avsändare och sammanhang. Att urskilja innehåll som kan vara explicit eller implicit uttryckt i texten.',
            'Strategier för skrivande av olika typer av texter med anpassning till deras uppbyggnad och språkliga drag. Skapande av texter där ord, bild och ljud samspelar.',
            'Modersmålets grundläggande struktur i jämförelse med svenskan. Ordbildning och meningsbyggnad på modersmålet i jämförelse med svenskan.',
            'Hjälpmedel för informationssökning och ordförståelse.',
          ],
        },
        {
          title: 'Tala, lyssna och samtala',
          topics: [
            'Samtal och diskussioner om ämnen som är välbekanta för eleven samt om tankar, känslor, åsikter och aktuella händelser.',
            'Muntliga presentationer och muntligt berättande med anpassning av språk, innehåll och disposition efter syfte och mottagare.',
            'Uttal, betoning och satsmelodi i jämförelse med svenskan samt olika talade variationer av modersmålet.',
          ],
        },
        {
          title: 'Texter',
          topics: [
            'Skönlitteratur för ungdomar från olika tider och områden där modersmålet talas. Texter som belyser människors villkor och identitets- och livsfrågor.',
            'Språkliga drag, ord och begrepp i skönlitteratur för ungdomar.',
            'Beskrivande, förklarande, instruerande och argumenterande texter, till exempel tidningsartiklar och arbetsbeskrivningar. Texternas syfte, innehåll och typiska språkliga drag.',
            'Texter som kombinerar ord, bild och ljud och deras språkliga och dramaturgiska komponenter, till exempel i tv-serier, teaterföreställningar och webbtexter.',
          ],
        },
        {
          title: 'Språkbruk',
          topics: [
            'Ord och begrepp för att uttrycka känslor, kunskaper och åsikter. Ords och begrepps nyanser och värdeladdning. Bildspråk och idiomatiska uttryck.',
            'Översättning av och jämförelser mellan ord, begrepp, fraser och texter på modersmålet och på svenska.',
            'Skillnader i språkanvändning beroende på syfte, mottagare och sammanhang. Språkets betydelse för att utöva inflytande.',
          ],
        },
        {
          title: 'Kultur och samhälle',
          topics: [
            'Kulturella uttrycksformer från områden där modersmålet talas, till exempel bildkonst, musik och arkitektur.',
            'Aktuella samhällsfrågor i områden där modersmålet talas, i jämförelse med liknande frågor i Sverige.',
          ],
        },
      ],
    },
  },

  matematik: {
    coursePlanLink:
      'https://www.skolverket.se/undervisning/grundskolan/laroplan-och-kursplaner-for-grundskolan/laroplan-lgr22-for-grundskolan-samt-for-forskoleklassen-och-fritidshemmet?url=907561864%2Fcompulsorycw%2Fjsp%2Fsubject.htm%3FsubjectCode%3DGRGRMAT01%26tos%3Dgr&sv.url=12.5dfee44715d35a5cdfa219f',

    courePlanText: `
    <h1>Kursplan - Matematik</h1>
    <p>Matematiken har en flertusenårig historia med bidrag från många kulturer. Den utvecklas såväl ur praktiska behov som ur människans nyfikenhet och lust att utforska matematiken som sådan. Matematisk verksamhet är till sin art en kreativ, reflekterande och problemlösande aktivitet som är nära kopplad till den samhälleliga, sociala, naturvetenskapliga, tekniska och digitala utveckllingen. Kunskaper i matematik ger människor förutsättningar att fatta välgrundade beslut i vardagslivets många valsituationer och ökar möjligheterna att delta i samhällets beslutsprocesser.</p>
    <h2>Ämnets syfte</h2>
    <p>Undervisningen i ämnet matematik ska syfta till att eleverna utvecklar kunskaper om matematik och matematikens användning i vardagen och inom olika ämnesområden. Undervisningen ska bidra till att eleverna utvecklar intresse för matematik och tilltro till sin förmåga att använda matematik i olika sammanhang. Den ska också ge eleverna möjlighet att uppleva estetiska värden i möten med matematiska mönster, former och samband.</p>
    <p>Genom undervisningen ska eleverna ges förutsättningar att utveckla förtrogenhet med grundläggande matematiska begrepp och metoder och deras användbarhet. Vidare ska eleverna genom undervisningen ges möjligheter att utveckla kunskaper i att använda digitala verktyg och programmering för att kunna undersöka problemställningar och matematiska begrepp, göra beräkningar samt för att presentera och tolka data.</p>
    <p>Undervisningen ska bidra till att eleverna utvecklar kunskaper för att kunna formulera och lösa problem samt reflektera över och värdera valda strategier, modeller och resultat. Eleverna ska även ges förutsättningar att utveckla kunskaper för att kunna tolka vardagliga och matematiska situationer samt för att kunna beskriva och formulera dessa med hjälp av matematikens uttrycksformer.</p>
    <p>Undervisningen ska bidra till att eleverna utvecklar förmågan att argumentera logiskt och föra matematiska resonemang. Eleverna ska genom undervisningen också ges möjlighet att utveckla en förtrogenhet med matematikens uttrycksformer och hur dessa kan användas för att kommunicera om matematik i vardagliga och matematiska sammanhang.</p>
    <p>Undervisningen ska ge eleverna förutsättningar att utveckla kunskaper om historiska sammanhang där viktiga begrepp och metoder i matematiken har utvecklats. Genom undervisningen ska eleverna även ges möjligheter att reflektera över matematikens betydelse, användning och begränsning i vardagslivet, i andra skolämnen och under historiska skeenden och därigenom kunna se matematikens sammanhang och relevans.</p>


<p>Undervisningen i ämnet matematik ska ge eleverna förutsättningar att utveckla</p>
<ul>
    <li>förmåga att använda och beskriva matematiska begrepp och samband mellan begrepp,</li>
    <li>förmåga att välja och använda lämpliga matematiska metoder för att göra beräkningar och lösa rutinuppgifter,</li>
    <li>förmåga att formulera och lösa problem med hjälp av matematik och värdera valda strategier,</li>
    <li>förmåga att föra och följa matematiska resonemang, och</li>
    <li>förmåga att använda matematikens uttrycksformer för att samtala om och redogöra för frågeställningar, beräkningar och slutsatser.</li>
</ul>
    `,

    '1-3': {
      sections: [
        {
          title: 'Taluppfattning och tals användning',
          topics: [
            'Naturliga tal och deras egenskaper samt hur talen delas upp och används för att ange antal och ordning.',
            'Positionssystemet och hur det används för att beskriva naturliga tal.',
            'Symboler för tal och symbolernas utveckling i några olika kulturer genom historien.',
            'Tal i bråkform som del av helhet och del av antal samt hur delarna benämns och uttrycks som enkla bråk. Hur enkla bråk förhåller sig till naturliga tal.',
            'Hur naturliga tal och enkla tal i bråkform används i elevnära situationer.',
            'De fyra räknesättens egenskaper och samband samt användning i olika situationer.',
            'Metoder för beräkningar med naturliga tal, vid huvudräkning, överslagsräkning och skriftlig beräkning. Användning av digitala verktyg vid beräkningar.',
            'Rimlighetsbedömning vid uppskattningar och beräkningar.',
          ],
        },
        {
          title: 'Algebra',
          topics: [
            'Matematiska likheter och likhetstecknets betydelse.',
            'Obekanta tal och hur de kan betecknas med en symbol.',
            'Enkla mönster i talföljder och enkla geometriska mönster samt hur de konstrueras, beskrivs och uttrycks.',
            'Entydiga stegvisa instruktioner och hur de konstrueras, beskrivs och följs som grund för programmering. Hur symboler används vid stegvisa instruktioner.',
          ],
        },
        {
          title: 'Geometri',
          topics: [
            'Vanliga lägesord för att beskriva föremåls och objekts läge i rummet.',
            'Grundläggande geometriska tvådimensionella objekt samt objekten klot, kon, cylinder och rätblock. Egenskaper hos dessa objekt och deras inbördes relationer. Konstruktion av geometriska objekt.',
            'Jämförelser och uppskattningar av storheter. Mätning av längd, massa, volym och tid med vanliga nutida och äldre måttenheter.',
            'Skala vid enkel förminskning och förstoring.',
            'Symmetri i vardagen och hur symmetri kan konstrueras.',
          ],
        },
        {
          title: 'Sannolikhet och statistik',
          topics: [
            'Slumpmässiga händelser i konkreta situationer.',
            'Enkla tabeller och diagram och hur de används för att sortera data och beskriva resultat från undersökningar, såväl med som utan digitala verktyg.',
          ],
        },
        {
          title: 'Samband och förändring',
          topics: ['Proportionella samband, däribland dubbelt och hälften.'],
        },
        {
          title: 'Problemlösning',
          topics: [
            'Strategier för att lösa matematiska problem i elevnära situationer.',
            'Formulering av matematiska frågeställningar utifrån vardagliga situationer.',
          ],
        },
      ],
    },
    '4-6': {
      sections: [
        {
          title: 'Taluppfattning och tals användning',
          topics: [
            'Rationella tal, däribland negativa tal, och deras egenskaper samt hur talen kan delas upp och användas.',
            'Positionssystemet och hur det används för att beskriva hela tal och tal i decimalform.',
            'Olika talsystem och några talsystem som använts i olika kulturer genom historien.',
            'Tal i procentform och deras samband med tal i bråk- och decimalform.',
            'Hur tal i bråk- och decimalform kan användas i vardagliga situationer.',
            'De fyra räknesätten och regler för deras användning vid beräkningar med naturliga tal.',
            'Metoder för beräkningar med naturliga tal och enkla tal i bråk- och decimalform vid överslagsräkning, huvudräkning och skriftlig beräkning. Användning av digitala verktyg vid beräkningar.',
            'Rimlighetsbedömning vid uppskattningar och beräkningar.',
          ],
        },
        {
          title: 'Algebra',
          topics: [
            'Matematiska likheter och hur likhetstecknet används för att teckna enkla ekvationer.',
            'Variabler och deras användning i enkla algebraiska uttryck och ekvationer.',
            'Metoder, däribland algebraiska, för att lösa enkla ekvationer.',
            'Mönster i talföljder och geometriska mönster samt hur de konstrueras, beskrivs och uttrycks.',
            'Programmering i visuella programmeringsmiljöer. Hur algoritmer skapas och används vid programmering.',
          ],
        },
        {
          title: 'Geometri',
          topics: [
            'Grundläggande geometriska två- och tredimensionella objekt samt deras egenskaper och inbördes relationer. Konstruktion av geometriska objekt, såväl med som utan digitala verktyg.',
            'Jämförelse, uppskattning och mätning av längd, area, massa, volym, tid och vinkel med standardiserade måttenheter samt enhetsbyten i samband med detta.',
            'Metoder för hur omkrets och area hos olika tvådimensionella geometriska figurer kan bestämmas och uppskattas.',
            'Skala vid förminskning och förstoring samt användning av skala i elevnära situationer.',
            'Symmetri i planet och hur symmetri kan konstrueras.',
          ],
        },
        {
          title: 'Sannolikhet och statistik',
          topics: [
            'Slumpmässiga händelser, chans och risk med utgångspunkt i observationer, simuleringar och statistiskt material. Jämförelse av sannolikhet vid olika slumpmässiga försök.',
            'Enkel kombinatorik i konkreta situationer.',
            'Tabeller och diagram för att beskriva resultat från undersökningar, såväl med som utan digitala verktyg. Tolkning av data i tabeller och diagram.',
            'Lägesmåtten medelvärde, typvärde och median samt hur de används i statistiska undersökningar.',
          ],
        },
        {
          title: 'Samband och förändring',
          topics: [
            'Proportionalitet samt hur proportionella samband uttrycks i bråk-, decimal- och procentform.',
            'Koordinatsystem och gradering av koordinataxlar.',
            'Grafer för att uttrycka proportionella samband.',
          ],
        },
        {
          title: 'Problemlösning',
          topics: [
            'Strategier för att lösa matematiska problem i elevnära situationer.',
            'Formulering av matematiska frågeställningar utifrån vardagliga situationer.',
          ],
        },
      ],
    },
    '7-9': {
      sections: [
        {
          title: 'Taluppfattning och tals användning',
          topics: [
            'Reella tal och deras egenskaper samt talens användning i matematiska situationer.',
            'Talsystemets utveckling från naturliga tal till reella tal.',
            'Tal i potensform. Grundpotensform för att uttrycka små och stora tal samt användning av prefix.',
            'Matematiska lagar och regler samt deras användning vid beräkningar med tal i bråk-, decimal- och potensform.',
            'Metoder för beräkningar med tal i bråk- och decimalform vid överslagsräkning, huvudräkning och skriftlig beräkning. Användning av digitala verktyg vid beräkningar.',
            'Rimlighetsbedömning vid uppskattningar och beräkningar.',
          ],
        },
        {
          title: 'Algebra',
          topics: [
            'Matematiska likheter samt hur likhetstecknet används för att teckna ekvationer och funktioner.',
            'Variablers användning i algebraiska uttryck, formler, ekvationer och funktioner.',
            'Metoder för att lösa linjära ekvationer och enkla andragradsekvationer.',
            'Mönster i talföljder och geometriska mönster samt hur de konstrueras, beskrivs och uttrycks generellt.',
            'Programmering i visuell och textbaserad programmeringsmiljö. Hur algoritmer skapas, testas och förbättras vid programmering.',
          ],
        },
        {
          title: 'Geometri',
          topics: [
            'Geometriska objekt samt deras egenskaper och inbördes relationer. Konstruktion av geometriska objekt, såväl med som utan digitala verktyg.',
            'Metoder för beräkning av area, omkrets och volym hos geometriska objekt, samt enhetsbyten i samband med detta.',
            'Geometriska satser och formler samt argumentation för deras giltighet.',
            'Skala vid förminskning och förstoring av två- och tredimensionella objekt.',
            'Likformighet och kongruens.',
          ],
        },
        {
          title: 'Sannolikhet och statistik',
          topics: [
            'Sannolikhet och metoder för att beräkna sannolikhet i olika situationer. Bedömningar av risker och chanser utifrån datorsimuleringar och statistiskt material.',
            'Kombinatoriska principer och hur de kan användas i olika situationer.',
            'Tabeller, diagram och grafer samt hur de tolkas och används för att beskriva resultat av egna och andras undersökningar, såväl med som utan digitala verktyg.',
            'Lägesmått och spridningsmått samt hur de används för bedömning av resultat vid statistiska undersökningar.',
          ],
        },
        {
          title: 'Samband och förändring',
          topics: [
            'Proportionalitet och hur det används för att uttrycka skala, likformighet och förändring.',
            'Härledda enheter, till exempel km/h och kr/kg.',
            'Procent och förändringsfaktor för att uttrycka förändring samt beräkningar med procent i vardagliga situationer och inom olika ämnesområden.',
            'Räta linjens ekvation och förändringstakt. Användning av räta linjens ekvation för att beskriva samband.',
            'Funktioner och hur de används för att beskriva samband och förändring samt undersöka förändringstakt. Hur funktioner uttrycks i form av grafer, tabeller och funktionsuttryck.',
          ],
        },
        {
          title: 'Problemlösning',
          topics: [
            'Strategier för att lösa matematiska problem i olika situationer och inom olika ämnesområden samt värdering av valda strategier och metoder.',
            'Formulering av matematiska frågeställningar utifrån olika situationer och ämnesområden.',
            'Enkla matematiska modeller och hur de kan användas i olika situationer.',
          ],
        },
      ],
    },
  },

  kemi: {
    coursePlanLink:
      'https://www.skolverket.se/undervisning/grundskolan/laroplan-och-kursplaner-for-grundskolan/laroplan-lgr22-for-grundskolan-samt-for-forskoleklassen-och-fritidshemmet?url=907561864%2Fcompulsorycw%2Fjsp%2Fsubject.htm%3FsubjectCode%3DGRGRKEM01%26tos%3Dgr&sv.url=12.5dfee44715d35a5cdfa219f',

    courePlanText: `
    <h1>Kursplan - Kemi</h1>
    <p>Naturvetenskapen har sitt ursprung i människans nyfikenhet och behov av att veta mer om sig själv och sin omvärld. Kunskaper i kemi har stor betydelse för samhällsutvecklingen inom så skilda områden som hälsa, materialutveckling, miljöteknik och resurshushållning. Med kunskaper om materiens uppbyggnad och oförstörbarhet får människor redskap för att kunna främja hållbar utveckling.</p>

    <h2>Ämnets syfte</h2>
    <p>Undervisningen i ämnet kemi ska syfta till att eleverna utvecklar nyfikenhet på och intresse för att veta mer om omvärlden. Eleverna ska därför ges möjligheter att ställa frågor om kemiska processer och materiens egenskaper och uppbyggnad utifrån egna upplevelser och aktuella händelser. Undervisningen ska bidra till att eleverna utvecklar kunskaper om kemin i naturen, i samhället och i människokroppen samt ge dem förutsättningar att använda kemins begrepp och förklaringsmodeller för att beskriva och förklara kemiska samband. Eleverna kan på så sätt utveckla sin förmåga att skilja mellan naturvetenskapliga och andra sätt att beskriva och förklara omvärlden.</p>

    <p>Genom undervisningen ska eleverna ges möjligheter att använda kunskaper i kemi för att formulera egna och granska andras argument. Därigenom ska eleverna utveckla sin förmåga att kommunicera samt hantera praktiska och etiska valsituationer i frågor som rör miljö och hälsa. Eleverna ska även ges förutsättningar att söka svar på ämnesspecifika frågor med hjälp av olika typer av källor. På så sätt ska undervisningen bidra till att eleverna utvecklar ett kritiskt tänkande och tilltro till sin förmåga att hantera frågor som rör naturvetenskap och som har betydelse för dem själva och samhället.</p>

    <p>Undervisningen ska även ge eleverna förutsättningar att söka svar på frågor om kemiska processer och materien med hjälp av egna systematiska undersökningar. På så sätt ska eleverna ges möjligheter att utveckla förståelse för att påståenden kan prövas och att kunskaper i kemi växer fram med hjälp av naturvetenskapliga arbetsmetoder. I det praktiska arbetet ska eleverna även ges möjligheter att utveckla färdigheter i att hantera material, utrustning och digitala verktyg.</p>

    <p>Undervisningen i ämnet kemi ska ge eleverna förutsättningar att utveckla</p>
    <ul>
      <li>kunskaper om kemins begrepp och förklaringsmodeller för att beskriva och förklara samband i naturen, i samhället och i människokroppen,</li>
      <li>förmåga att använda kemi för att granska information, kommunicera och ta ställning i frågor som rör miljö och hälsa, och</li>
      <li>förmåga att genomföra systematiska undersökningar i kemi.</li>
    </ul>`,

    '1-3': {
      sections: [
        {
          title: 'Året runt i naturen',
          topics: [
            'Årstidsväxlingar i naturen. Några djurs och växters livscykler och anpassningar till olika livsmiljöer och årstider.',
            'Djur, växter och svampar i närmiljön, hur de kan grupperas samt namn på några vanligt förekommande arter.',
            'Enkla näringskedjor som beskriver samband mellan organismer i ekosystem.',
          ],
        },
        {
          title: 'Kropp och hälsa',
          topics: [
            'Några av människans organ, deras namn och översiktliga funktion.',
            'Människans upplevelser av ljus, ljud, värme, smak och doft med hjälp av olika sinnen.',
            'Betydelsen av kost, sömn, hygien, motion och sociala relationer för att må bra.',
          ],
        },
        {
          title: 'Kraft och rörelse',
          topics: [
            'Tyngdkraft, tyngdpunkt, jämvikt, balans och friktion som kan upplevas och observeras vid lek och rörelse.',
            'Solsystemets himlakroppar och deras rörelser. Människan i rymden.',
          ],
        },
        {
          title: 'Material och ämnen',
          topics: [
            'Hur material kan sorteras efter några egenskaper, till exempel utseende, om de är magnetiska och om de flyter eller sjunker i vatten. Hur materialen kan återvinnas.',
            'Några blandningar och hur de kan delas upp i sina olika beståndsdelar, till exempel genom avdunstning och filtrering.',
            'Vattnets olika former: fast, flytande och gas. Avdunstning, kokning, kondensering, smältning och stelning.',
          ],
        },
        {
          title: 'Systematiska undersökningar',
          topics: [
            'Enkla fältstudier, observationer och experiment. Utförande och dokumentation av undersökningarna med ord, bilder och digitala verktyg.',
            'Några berättelser om hur naturvetenskaplig kunskap vuxit fram.',
          ],
        },
      ],
    },

    '4-6': {
      sections: [
        {
          title: 'Kemin i naturen, i samhället och i människokroppen',
          topics: [
            'Materiens uppbyggnad visualiserad med hjälp av enkla partikelmodeller.',
            'Indelning av ämnen och material utifrån egenskaperna löslighet, ledningsförmåga, surt eller basiskt.',
            'Vattnets egenskaper och kretslopp.',
            'Luftens egenskaper och sammansättning.',
            'Fotosyntes och förbränning som exempel på kemiska reaktioner i naturen.',
            'Fossila och förnybara bränslen och deras påverkan på klimatet.',
            'Matens innehåll och näringsämnenas betydelse för hälsan.',
            'Vanliga kemikalier i hemmet. Deras användning och påverkan på miljön och människan samt hur de är märkta och bör hanteras.',
            'Råvarors förädling till produkter, till exempel metaller, papper och plast. Hur produkterna kan återanvändas eller återvinnas.',
          ],
        },
        {
          title: 'Systematiska undersökningar och granskning av information',
          topics: [
            'Observationer och experiment med såväl analoga som digitala verktyg. Planering, utförande, värdering av resultat samt dokumentation med ord, bilder och tabeller.',
            'Några upptäckter inom kemiområdet och deras betydelse för människans levnadsvillkor och syn på naturen.',
            'Kritisk granskning och användning av information som rör kemi.',
          ],
        },
      ],
    },

    '7-9': {
      sections: [
        {
          title: 'Kemin i naturen, i samhället och i människokroppen',
          topics: [
            'Materiens uppbyggnad, kretslopp och oförstörbarhet visualiserat med hjälp av partikelmodeller. Grundämnen, molekyl- och jonföreningar samt hur ämnen omvandlas genom kemiska reaktioner. Atomer, elektroner och kärnpartiklar.',
            'Separations- och analysmetoder, till exempel filtrering, fällning, pH-mätning och identifikation av ämnen.',
            'Vatten som lösningsmedel och transportör av ämnen, till exempel i mark, växter och människokroppen.',
            'Några kemiska processer i mark, luft och vatten samt deras koppling till frågor om miljö och hälsa, till exempel växthuseffekten, vattenrening och spridning av miljögifter.',
            'Kolatomens egenskaper och kretslopp i naturen, i samhället och i människokroppen.',
            'Kolhydrater, proteiner och fetter samt deras funktioner i människokroppen.',
            'Utveckling av produkter och material, till exempel läkemedel, funktionskläder och batterier.',
            'Några produkters livscykler och påverkan på miljön.',
          ],
        },
        {
          title: 'Systematiska undersökningar och granskning av information',
          topics: [
            'Observationer och experiment med såväl analoga som digitala verktyg. Formulering av undersökningsbara frågor, planering, utförande, värdering av resultat samt dokumentation med bilder, tabeller, diagram och rapporter.',
            'Sambandet mellan kemiska undersökningar och utvecklingen av begrepp och förklaringsmodeller. De kemiska förklaringsmodellernas historiska framväxt, användbarhet och föränderlighet.',
            'Informationssökning, kritisk granskning och användning av information som rör kemi. Argumentation och ställningstaganden i aktuella frågor som rör miljö och hälsa.',
          ],
        },
      ],
    },
  },

  engelska: {
    coursePlanLink:
      'https://www.skolverket.se/undervisning/grundskolan/laroplan-och-kursplaner-for-grundskolan/laroplan-lgr22-for-grundskolan-samt-for-forskoleklassen-och-fritidshemmet?url=907561864%2Fcompulsorycw%2Fjsp%2Fsubject.htm%3FsubjectCode%3DGRGRENG01%26tos%3Dgr&sv.url=12.5dfee44715d35a5cdfa219f',

    courePlanText: `
    <h1>Kursplan - Engelska</h1>
    <p>Språk är människans främsta redskap för att tänka, kommunicera och lära. Att ha kunskaper i flera språk kan ge nya perspektiv på omvärlden, ökade möjligheter till kontakter och större förståelse för olika sätt att leva. Engelska språket omger oss i vardagen och används inom så skilda områden som politik, utbildning och ekonomi. Kunskaper i engelska ökar därför individens möjligheter att ingå i olika sociala och kulturella sammanhang och att delta i internationellt studie- och arbetsliv.</p>

    <h2>Ämnets syfte</h2>
    <p>Undervisningen i ämnet engelska ska syfta till att eleverna utvecklar kunskaper i engelska språket och kunskaper om områden och sammanhang där engelska används. Undervisningen ska ge eleverna förutsättningar att utveckla sin flerspråkighet samt tilltro till sin förmåga att använda språket i olika situationer och för skilda syften.</p>
    
    <p>Genom undervisningen ska eleverna ges möjlighet att utveckla en allsidig kommunikativ förmåga. Denna förmåga innebär att förstå talat och skrivet språk, att kunna formulera sig och samspela med andra i tal och skrift och att kunna anpassa sitt språk till olika situationer, syften och mottagare. I den kommunikativa förmågan ingår även språklig säkerhet och att kunna använda olika strategier för att underlätta kommunikationen när språkkunskaperna inte räcker till.</p>
    
    <p>I mötet med talat språk och texter ska eleverna ges möjlighet att utveckla förmågan att sätta innehållet i relation till egna erfarenheter, livsvillkor och intressen. Undervisningen ska även ge eleverna möjligheter att utveckla förståelse av olika livsvillkor samt kulturella och sociala förhållanden i områden och i sammanhang där engelska används.</p>
    
    <p>Undervisningen ska bidra till att eleverna utvecklar kunskaper i att söka, värdera, välja och tillägna sig innehållet i talat språk och texter från olika källor. De ska också ges förutsättningar att kunna använda olika hjälpmedel för lärande, förståelse, skapande och kommunikation. Undervisningen ska stimulera elevernas intresse för språk och kulturer och förmedla nyttan av språkkunskaper.</p>
    
    <p>Undervisningen i ämnet engelska ska ge eleverna förutsättningar att utveckla</p>
    <ul>
      <li>förståelse av engelska i tal och skrift,</li>
      <li>förmåga att formulera sig och kommunicera på engelska i tal och skrift,</li>
      <li>förmåga att anpassa språket efter olika syften, mottagare och sammanhang, och</li>
      <li>förståelse av kulturella och sociala förhållanden i olika sammanhang och områden där engelska används.</li>
    </ul>`,

    '1-3': {
      sections: [
        {
          title: 'Kommunikationens innehåll',
          topics: [
            'Ämnesområden som är välbekanta för eleverna.',
            'Vardagliga situationer, intressen, personer och platser.',
            'Vardagsliv och levnadssätt i olika sammanhang och områden där engelska används.',
          ],
        },
        {
          title: 'Lyssna och läsa – reception',
          topics: [
            'Tydligt talad engelska och enkla texter som är instruerande, beskrivande och kontaktskapande, från olika medier, även i kombination med illustrationer.',
            'Enkla dialoger och samtal.',
            'Filmer och dramatiserade berättelser för barn.',
            'Sånger, ramsor, dikter och sagor.',
            'Ord och fraser i närmiljön, till exempel på skyltar, i reklam och andra enkla texter.',
          ],
        },
        {
          title: 'Tala, skriva och samtala – produktion och interaktion',
          topics: [
            'Enkla presentationer, meddelanden, beskrivningar och dialoger i tal och skrift.',
            'Enkla samtal.',
            'Sånger, ramsor och dramatiseringar.',
          ],
        },
      ],
    },

    '4-6': {
      sections: [
        {
          title: 'Kommunikationens innehåll',
          topics: [
            'Ämnesområden som är välbekanta för eleverna.',
            'Vardagliga situationer, intressen, personer, platser, aktiviteter och händelser.',
            'Åsikter, känslor och erfarenheter.',
            'Vardagsliv, levnadssätt och sociala relationer i olika sammanhang och områden där engelska används, även i jämförelse med egna erfarenheter och kunskaper. Engelskans utbredning i världen.',
          ],
        },
        {
          title: 'Lyssna och läsa – reception',
          topics: [
            'Tydligt talad engelska och enkla texter som är instruerande, beskrivande och kontaktskapande, från olika medier.',
            'Dialoger, samtal och intervjuer.',
            'Berättelser och annan fiktion för barn och unga, även i talad eller dramatiserad form.',
            'Sånger, dikter och sagor.',
            'Muntliga och skriftliga meddelanden samt information, till exempel reklam, tidtabeller och notiser.',
            'Strategier för att uppfatta betydelsebärande ord och sammanhang samt för att anpassa lyssnande och läsning till framställningens form och innehåll.',
            'Sökning av enkel information i ett avgränsat urval av muntliga och skriftliga källor av olika slag för användning i egen produktion och interaktion.',
            'Språkliga företeelser, däribland uttal, ord och fasta språkliga uttryck, grammatiska strukturer samt stavning, i det språk eleverna möter.',
            'Hur olika formuleringar används för att inleda och avsluta olika typer av framställningar och samtal.',
          ],
        },
        {
          title: 'Tala, skriva och samtala – produktion och interaktion',
          topics: [
            'Samtal och skrivande för kontakt och kommunikation.',
            'Presentationer, instruktioner, meddelanden, berättelser och beskrivningar i sammanhängande tal och skrift.',
            'Strategier, däribland omformuleringar, frågor och stödjande fraser, för att bidra till och underlätta samtal och skriftlig interaktion, även digital.',
            'Språkliga företeelser, däribland uttal, ord, artighetsfraser, tilltalsord och andra fasta språkliga uttryck, samt grammatiska strukturer och stavning i elevernas egen produktion och interaktion.',
            'Bearbetning av egna muntliga och skriftliga framställningar för att förtydliga och anpassa kommunikationen efter syfte och mottagare.',
          ],
        },
      ],
    },

    '7-9': {
      sections: [
        {
          title: 'Kommunikationens innehåll',
          topics: [
            'Aktuella och för eleven bekanta ämnesområden.',
            'Vardagliga situationer, intressen, aktiviteter och händelseförlopp.',
            'Åsikter, känslor, erfarenheter och framtidsplaner samt relationer och etiska frågor.',
            'Levnadsvillkor, traditioner, sociala relationer och kulturella företeelser i olika sammanhang och områden där engelska används, även i jämförelse med egna erfarenheter och kunskaper.',
          ],
        },
        {
          title: 'Lyssna och läsa – reception',
          topics: [
            'Talad engelska, även med inslag av regional och sociolektal variation, och texter, från olika medier.',
            'Talad engelska och texter som är instruerande, informerande, beskrivande, berättande, diskuterande, argumenterande och kontaktskapande – varje slag för sig eller i olika kombinationer – till exempel samtal, intervjuer, nyheter, reportage och tidningsartiklar.',
            'Skönlitteratur och annan fiktion, även i talad och filmatiserad form.',
            'Sånger och dikter.',
            'Strategier för att uppfatta detaljer och förstå sammanhang samt för att anpassa lyssnande och läsning till framställningens form, innehåll och syfte.',
            'Sökning och värdering av innehåll i muntliga och skriftliga källor av olika slag, utifrån olika syften.',
            'Språkliga företeelser, däribland uttal, grammatiska strukturer och satsbyggnad, ord med olika stilvärden och fasta språkliga uttryck samt stavning, i det språk eleverna möter.',
            'Hur sammanbindande ord och andra uttryck används för att skapa struktur och språkligt sammanhängande helheter.',
          ],
        },
        {
          title: 'Tala, skriva och samtala – produktion och interaktion',
          topics: [
            'Samtal, diskussion och skrivande där eleverna berättar, beskriver, instruerar och motiverar sina åsikter.',
            'Strategier för att bidra till och underlätta samtal och skriftlig interaktion, även digital, till exempel genom att ge bekräftelse, ställa följdfrågor, lyssna aktivt, formulera om, förklara, bidra med nya infallsvinklar och avsluta på ett artigt sätt.',
            'Språkliga företeelser, däribland uttal, ord och fasta språkliga uttryck, grammatiska strukturer och satsbyggnad samt stavning i elevernas egen produktion och interaktion.',
            'Bearbetning av egna muntliga och skriftliga framställningar för att förtydliga, variera, precisera och anpassa kommunikationen efter syfte, mottagare och sammanhang.',
          ],
        },
      ],
    },
  },

  geografi: {
    coursePlanLink:
      'https://www.skolverket.se/undervisning/grundskolan/laroplan-och-kursplaner-for-grundskolan/laroplan-lgr22-for-grundskolan-samt-for-forskoleklassen-och-fritidshemmet?url=907561864%2Fcompulsorycw%2Fjsp%2Fsubject.htm%3FsubjectCode%3DGRGRGEO01%26tos%3Dgr&sv.url=12.5dfee44715d35a5cdfa219f',

    courePlanText: `
    <h1>Kursplan - Geografi</h1>
    <p>Jordytan består av en mosaik av livsmiljöer som är unika, föränderliga och sårbara. Geografi ger oss kunskaper om dessa varierande miljöer och bidrar till förståelse av människors levnadsvillkor samt om samspelet mellan människa, samhälle och natur. Kunskaper i geografi ger ökade möjligheter att förstå vår omvärld och beredskap att främja hållbar utveckling.</p>
    
    <h2>Ämnets syfte</h2>
    <p>Undervisningen i ämnet geografi ska syfta till att eleverna utvecklar kunskaper om geografiska förhållanden och mönster på olika platser och i olika regioner. Undervisningen ska vidga och fördjupa elevernas kunskaper om naturgivna processer och människans verksamheter i olika delar av världen, och hur dessa påverkar landskap och livsmiljöer. Genom undervisningen ska eleverna ges förutsättningar att växla mellan olika rumsliga perspektiv: lokala, regionala och globala, liksom mellan olika tidsperspektiv.</p>
    
    <p>Undervisningen ska ge eleverna förutsättningar att utveckla kunskaper om miljö- och utvecklingsfrågor kopplade till klimatförändringar, människans tillgång till och användning av naturresurser, samt befolkningsfrågor och levnadsförhållanden i olika delar av världen. Därigenom ska eleverna ges förutsättningar att analysera och reflektera över olika lösningar på vår tids och framtidens globala utmaningar utifrån ekologiska, sociala och ekonomiska perspektiv på hållbar utveckling.</p>
    
    <p>Undervisningen ska ge eleverna kunskaper om kartan och kännedom om namn, lägen och storleksrelationer. Undervisningen ska även ge eleverna förutsättningar att utveckla kunskaper i att ställa geografiska frågor, analysera och dra slutsatser om natur- och kulturlandskap och om människors levnadsvillkor med hjälp av olika geografiska källor, metoder, verktyg och begrepp.</p>
    
    <p>Undervisningen i ämnet geografi ska ge eleverna förutsättningar att utveckla</p>
    <ul>
      <li>kunskaper om geografiska förhållanden och mönster samt om hur naturens processer och människors verksamheter formar och förändrar landskap och livsmiljöer i olika delar av världen,</li>
      <li>kunskaper om miljö- och utvecklingsfrågor utifrån ekologiska, sociala och ekonomiska perspektiv på hållbar utveckling, och</li>
      <li>förmåga att utifrån geografiska frågor beskriva och analysera omvärlden med hjälp av geografins metoder och verktyg.</li>
    </ul>`,

    '1-3': {
      sections: [
        {
          title: 'Att leva tillsammans',
          topics: [
            'Migration inom ett land och mellan länder. Vad detta kan ha för orsaker och få för konsekvenser.',
            'Skildringar av människors levnadsvillkor förr i tiden, till exempel i barnlitteratur, sånger och filmer. Minnen berättade av människor som lever nu.',
            'Samtal om och reflektion över moraliska frågor och livsfrågor med betydelse för eleven, till exempel kamratskap, könsroller och döden.',
            'Samtal om och reflektion över normer och regler i elevens livsmiljöer, däribland i skolan och i digitala miljöer.',
            'Trafikregler och hur man beter sig i trafiken på ett säkert sätt.',
          ],
        },
        {
          title: 'Att leva i närområdet',
          topics: [
            'Några utmärkande drag i närområdets natur- och kulturlandskap. Spår av den senaste istiden.',
            'Hemortens historia och vad närområdets platser, byggnader och vardagliga föremål kan berätta om livet där under olika tider.',
            'Kristendomens roll i skolan och på hemorten förr i tiden.',
            'Centrala samhällsfunktioner, till exempel sjukvård, räddningstjänst och skola.',
            'Yrken och verksamheter i närområdet.',
          ],
        },
        {
          title: 'Att leva i världen',
          topics: [
            'Miljöfrågor utifrån elevens vardag, till exempel frågor om trafik, energi och livsmedel.',
            'Rumsliga förutsättningar i natur och miljö för befolkning och bebyggelse, till exempel mark, vatten och klimat.',
            'Namn och läge på världsdelarna och världshaven samt på länder och platser som är betydelsefulla för eleven.',
            'Människans uppkomst, vandringar, samlande och jakt samt förändrade levnadsvillkor i samband med övergången till jordbruk.',
            'Norden befolkas. Människors levnadsvillkor under nordisk stenålder, bronsålder och järnålder.',
            'Berättelser i antik och nordisk mytologi samt i samisk religion.',
            'Några högtider, symboler och berättelser inom kristendom, islam och judendom. Några berättelser ur Bibeln och deras innebörder.',
            'Mänskliga rättigheter inklusive alla människors lika värde och barnets rättigheter i enlighet med FN:s konvention om barnets rättigheter (barnkonventionen).',
            'Grundläggande demokratiska principer. Vad åsikts- och yttrandefrihet samt majoritetsprincipen kan innebära i skolan och i samhället.',
            'Pengars användning och värde. Olika exempel på betalningsformer och vad några vanliga varor och tjänster kan kosta.',
            'Aktuella samhällsfrågor i olika medier.',
          ],
        },
        {
          title: 'Att undersöka verkligheten',
          topics: [
            'Metoder för att söka information, till exempel textläsning, intervjuer och observationer. Samtal om olika källors användbarhet och tillförlitlighet.',
            'Jordgloben, analoga och digitala kartor samt storleksrelationer och väderstreck.',
            'Mentala kartor, till exempel över närområdet, skolvägar eller andra platser som är betydelsefulla för eleven.',
            'Tidslinjer och tidsbegreppen dåtid, nutid och framtid.',
          ],
        },
      ],
    },

    '4-6': {
      sections: [
        {
          title: 'Geografiska förhållanden, mönster och processer',
          topics: [
            'Introduktion till jordens endogena och exogena processer, samt hur dessa formar och förändrar landskapet.',
            'Jordens naturresurser, till exempel vatten, odlingsmark, skogar och mineraler. Var på jorden olika naturresurser finns och hur människors användning av resurserna påverkar landskapet och människans livsmiljöer.',
            'Klimat- och vegetationszoner samt på vilka sätt klimatet påverkar människans livsmiljöer.',
            'Utmärkande drag för några natur- och kulturlandskap i Sverige, Europa och världen.',
            'Namn och läge på geografiska objekt i Sverige, Europa och världen. Ett urval av hav, sjöar, floder, berg, öknar, regioner, länder och städer.',
          ],
        },
        {
          title: 'Hållbar utveckling',
          topics: [
            'Hur val och prioriteringar på individ- och samhällsnivå kan påverka miljön och främja hållbar utveckling.',
            'Några grundläggande orsaker till och konsekvenser av fattigdom och ohälsa, till exempel bristande tillgång till utbildning, hälsovård och vatten. Arbete för att förbättra människors levnadsvillkor, till exempel genom Förenta Nationerna (FN).',
          ],
        },
        {
          title: 'Geografins metoder och verktyg',
          topics: [
            'Digitala och analoga kartor och deras uppbyggnad med gradnät, färger, symboler och skala. Topografiska och tematiska kartor.',
            'Fältstudier för att undersöka natur- och kulturlandskap.',
            'Beskrivningar och enkla analyser av platser och regioner med hjälp av kartor och andra geografiska källor, metoder, verktyg och begrepp.',
          ],
        },
      ],
    },

    '7-9': {
      sections: [
        {
          title: 'Geografiska förhållanden, mönster och processer',
          topics: [
            'Jordens endogena och exogena processer, samt hur dessa formar och förändrar landskapet.',
            'Rumslig utbredning av sårbara platser på jorden, till exempel områden som riskerar att återkommande drabbas av översvämningar, torka eller jordbävningar. Hur samhällsplanering och människans verksamheter påverkar sårbarhet och hur risker kan förebyggas.',
            'Globala produktions- och konsumtionsmönster samt flöden av varor och tjänster.',
            'Orsaker till och konsekvenser av demografiska mönster och förändringar: befolkningsfördelning, befolkningsutveckling, migrationsmönster och urbanisering.',
            'Namn och läge på platser och regioner som är relevanta för studierna av geografiska förhållanden, mönster, processer och hållbarhetsfrågor.',
          ],
        },
        {
          title: 'Hållbar utveckling',
          topics: [
            'Vad klimat är och faktorer som påverkar klimatet. Människans påverkan på klimatet och konsekvenser av klimatförändringar för människa, samhälle och natur i olika delar av världen.',
            'Människors tillgång till och användning av förnybara och icke-förnybara naturresurser och hur det påverkar människans livsmiljöer. Intressekonflikter kring naturresurser.',
            'Samband mellan ekonomisk och social levnadsstandard och faktorer som demografi, jämställdhet, utbildning och naturresurser.',
            'Lokalt, regionalt och globalt arbete för att främja hållbar utveckling.',
          ],
        },
        {
          title: 'Geografins metoder och verktyg',
          topics: [
            'Digitala och analoga kartors uppbyggnad samt olika kartprojektioner och deras egenskaper. Geografiska informationssystem (GIS) och exempel på hur de används i samhället.',
            'Beskrivningar och analyser av platser och regioner med hjälp av kartor, enkla former av GIS, fältstudier och andra geografiska källor, metoder, verktyg och begrepp.',
          ],
        },
      ],
    },
  },

  fysik: {
    coursePlanLink:
      'https://www.skolverket.se/undervisning/grundskolan/laroplan-och-kursplaner-for-grundskolan/laroplan-lgr22-for-grundskolan-samt-for-forskoleklassen-och-fritidshemmet?url=907561864%2Fcompulsorycw%2Fjsp%2Fsubject.htm%3FsubjectCode%3DGRGRFYS01%26tos%3Dgr&sv.url=12.5dfee44715d35a5cdfa219f',

    courePlanText: `
    <h1>Kursplan - Fysik</h1>
    <p>Naturvetenskapen har sitt ursprung i människans nyfikenhet och behov av att veta mer om sig själv och sin omvärld. Kunskaper i fysik har stor betydelse för samhällsutvecklingen inom så skilda områden som energiförsörjning, medicinsk behandling och meteorologi. Med kunskaper om energi och materia får människor redskap för att kunna främja hållbar utveckling.</p>
    
    <h2>Ämnets syfte</h2>
    <p>Undervisningen i ämnet fysik ska syfta till att eleverna utvecklar nyfikenhet på och intresse för att veta mer om omvärlden. Eleverna ska därför ges möjligheter att ställa frågor om fysikaliska företeelser utifrån egna upplevelser och aktuella händelser. Undervisningen ska bidra till att eleverna utvecklar kunskaper om fysiken i naturen och samhället samt ge dem förutsättningar att använda fysikens begrepp och förklaringsmodeller för att beskriva och förklara fysikaliska fenomen. Eleverna kan på så sätt utveckla sin förmåga att skilja mellan naturvetenskapliga och andra sätt att beskriva och förklara omvärlden.</p>
    
    <p>Genom undervisningen ska eleverna ges möjligheter att använda kunskaper i fysik för att formulera egna och granska andras argument. Därigenom ska eleverna utveckla sin förmåga att kommunicera samt hantera praktiska och etiska valsituationer i frågor som rör energi, teknik och miljö. Eleverna ska även ges förutsättningar att söka svar på ämnesspecifika frågor med hjälp av olika typer av källor. På så sätt ska undervisningen bidra till att eleverna utvecklar ett kritiskt tänkande och tilltro till sin förmåga att hantera frågor som rör naturvetenskap och har betydelse för dem själva och samhället.</p>
    
    <p>Undervisningen ska även ge eleverna förutsättningar att söka svar på frågor om fysikaliska fenomen med hjälp av egna systematiska undersökningar. På så sätt ska eleverna ges möjligheter att utveckla förståelse för att påståenden kan prövas och att kunskaper i fysik växer fram med hjälp av naturvetenskapliga arbetsmetoder. I det praktiska arbetet ska eleverna även ges möjligheter att utveckla färdigheter i att hantera material, utrustning och digitala verktyg.</p>
    
    <p>Undervisningen i ämnet fysik ska ge eleverna förutsättningar att utveckla</p>
    <ul>
      <li>kunskaper om fysikens begrepp och förklaringsmodeller för att beskriva och förklara samband i naturen och samhället,</li>
      <li>förmåga att använda fysik för att granska information, kommunicera och ta ställning i frågor som rör energi, teknik och miljö, och</li>
      <li>förmåga att genomföra systematiska undersökningar i fysik.</li>
    </ul>`,

    '1-3': {
      sections: [
        {
          title: 'Året runt i naturen',
          topics: [
            'Årstidsväxlingar i naturen. Några djurs och växters livscykler och anpassningar till olika livsmiljöer och årstider.',
            'Djur, växter och svampar i närmiljön, hur de kan grupperas samt namn på några vanligt förekommande arter.',
            'Enkla näringskedjor som beskriver samband mellan organismer i ekosystem.',
          ],
        },
        {
          title: 'Kropp och hälsa',
          topics: [
            'Några av människans organ, deras namn och översiktliga funktion.',
            'Människans upplevelser av ljus, ljud, värme, smak och doft med hjälp av olika sinnen.',
            'Betydelsen av kost, sömn, hygien, motion och sociala relationer för att må bra.',
          ],
        },
        {
          title: 'Kraft och rörelse',
          topics: [
            'Tyngdkraft, tyngdpunkt, jämvikt, balans och friktion som kan upplevas och observeras vid lek och rörelse.',
            'Solsystemets himlakroppar och deras rörelser. Människan i rymden.',
          ],
        },
        {
          title: 'Material och ämnen',
          topics: [
            'Hur material kan sorteras efter några egenskaper, till exempel utseende, om de är magnetiska och om de flyter eller sjunker i vatten. Hur materialen kan återvinnas.',
            'Några blandningar och hur de kan delas upp i sina olika beståndsdelar, till exempel genom avdunstning och filtrering.',
            'Vattnets olika former: fast, flytande och gas. Avdunstning, kokning, kondensering, smältning och stelning.',
          ],
        },
        {
          title: 'Systematiska undersökningar',
          topics: [
            'Enkla fältstudier, observationer och experiment. Utförande och dokumentation av undersökningarna med ord, bilder och digitala verktyg.',
            'Några berättelser om hur naturvetenskaplig kunskap vuxit fram.',
          ],
        },
      ],
    },
    '4-6': {
      sections: [
        {
          title: 'Fysiken i naturen och samhället',
          topics: [
            'Hur dag, natt, årstider och år kan förklaras utifrån rörelser hos solsystemets himlakroppar.',
            'Vanliga väderfenomen och deras orsaker, till exempel hur vindar och nederbörd uppstår.',
            'Energiformer samt olika typer av energikällor och deras påverkan på miljön.',
            'Energiflöden mellan föremål som har olika temperatur. Hur man kan påverka energiflödena med hjälp av olika värmeledande och isolerande material.',
            'Hur ljus och ljud breder ut sig och kan reflekteras.',
            'Elektriska kretsar med batterier. Hur de kan kopplas och hur de kan användas i vardaglig elektrisk utrustning.',
            'Krafter och rörelser som kan observeras och mätas i vardagssituationer.',
            'Några instrument samt hur de används för att mäta fysikaliska storheter, till exempel temperatur och kraft.',
          ],
        },
        {
          title: 'Systematiska undersökningar och granskning av information',
          topics: [
            'Observationer och experiment med såväl analoga som digitala verktyg. Planering, utförande, värdering av resultat samt dokumentation med ord, bilder och tabeller.',
            'Några upptäckter inom fysikområdet och deras betydelse för människans levnadsvillkor och syn på naturen.',
            'Kritisk granskning och användning av information som rör fysik.',
          ],
        },
      ],
    },
    '7-9': {
      sections: [
        {
          title: 'Fysiken i naturen och samhället',
          topics: [
            'Universums uppkomst, uppbyggnad och utveckling samt förutsättningar för att finna planeter och liv i andra solsystem.',
            'Partikelmodell av materiens egenskaper samt fasövergångar, tryck, volym, densitet och temperatur.',
            'Fysikaliska förklaringsmodeller av jordens strålningsbalans, växthuseffekten och klimatförändringar.',
            'Energins flöde och oförstörbarhet samt olika energislags kvalitet. Olika typer av energikällor samt deras för- och nackdelar för samhället och miljön.',
            'Partikelstrålning och elektromagnetisk strålning, deras användningsområden och risker.',
            'Hur ljus breder ut sig, reflekteras och bryts.',
            'Hur ljud uppstår, breder ut sig och kan registreras på olika sätt.',
            'Sambandet mellan elektricitet och magnetism samt mellan ström och spänning i elektriska kretsar. Hur kretsarna kan användas i elektrisk utrustning.',
            'Krafter, rörelser och rörelseförändringar samt hur kunskaper om detta kan användas, till exempel i frågor om trafiksäkerhet.',
            'Några instrument för att mäta fysikaliska storheter, till exempel kraft och ström. Användning av mätvärden i enkla beräkningar, till exempel beräkningar av densitet och hastighet.',
          ],
        },
        {
          title: 'Systematiska undersökningar och granskning av information',
          topics: [
            'Observationer och experiment med såväl analoga som digitala verktyg. Formulering av undersökningsbara frågor, planering, utförande, värdering av resultat samt dokumentation med bilder, tabeller, diagram och rapporter.',
            'Sambandet mellan undersökningar av fysikaliska fenomen och utvecklingen av begrepp och förklaringsmodeller. De fysikaliska förklaringsmodellernas historiska framväxt, användbarhet och föränderlighet.',
            'Informationssökning, kritisk granskning och användning av information som rör fysik. Argumentation och ställningstaganden i aktuella frågor som rör energi, teknik och miljö.',
          ],
        },
      ],
    },
  },

  biologi: {
    coursePlanLink:
      'https://www.skolverket.se/undervisning/grundskolan/laroplan-och-kursplaner-for-grundskolan/laroplan-lgr22-for-grundskolan-samt-for-forskoleklassen-och-fritidshemmet?url=907561864%2Fcompulsorycw%2Fjsp%2Fsubject.htm%3FsubjectCode%3DGRGRBIO01%26tos%3Dgr&sv.url=12.5dfee44715d35a5cdfa219f',

    courePlanText: `
    <h1>Kursplan - Biologi</h1>
    <p>Naturvetenskapen har sitt ursprung i människans nyfikenhet och behov av att veta mer om sig själv och sin omvärld. Kunskaper i biologi har stor betydelse för samhällsutvecklingen inom så skilda områden som naturbruk, miljö och hälsa. Med kunskaper om naturen och människan får människor redskap för att påverka sitt eget välbefinnande, men också för att kunna främja hållbar utveckling.</p>
    
    <h2>Ämnets syfte</h2>
    <p>
Undervisningen i ämnet biologi ska syfta till att eleverna utvecklar nyfikenhet på och intresse för att veta mer om sig själva och omvärlden. Eleverna ska där­för ges möjligheter att ställa frågor om naturen och människan utifrån egna upp­levelser och aktuella händelser. Undervisningen ska bidra till att eleverna ut­vecklar kunskaper om naturen och människokroppen samt ge dem förut­sätt­ningar att använda biologins begrepp och förklaringsmodeller för att beskriva och förklara biologiska samband. Eleverna ska få inblick i naturvetenskapens världs­bild med evolutionsteorin som grund och kan på så sätt utveckla sin för­måga att skilja mellan naturvetenskapliga och andra sätt att beskriva och för­klara omvärlden.
</p>
<p>
Genom undervisningen ska eleverna ges möjligheter att använda kun­skaper i biologi för att formulera egna och granska andras argument. Däri­ge­nom ska eleverna utveckla sin förmåga att kommunicera samt hantera prak­ti­ska och etiska valsituationer i frågor som rör miljö och hälsa. Eleverna ska även ges förutsättningar att söka svar på ämnesspecifika frågor med hjälp av oli­ka typer av källor. På så sätt ska undervisningen bidra till att eleverna ut­veck­lar ett kritiskt tänkande och tilltro till sin förmåga att hantera frågor som rör naturvetenskap och som har betydelse för dem själva och samhället.
</p><p>
Undervisningen ska även ge eleverna förutsättningar att söka svar på frågor om naturen och människan med hjälp av egna systematiska undersökningar. På så sätt ska eleverna ges möjligheter att utveckla förståelse för att på­ståen­den kan prövas och att kunskaper i biologi växer fram med hjälp av natur­veten­skapliga arbetsmetoder. I det praktiska arbetet ska eleverna även ges möj­ligheter att utveckla färdigheter i att hantera material, utrustning och digi­tala verktyg.
</p><p>
Undervisningen i ämnet biologi ska ge eleverna förutsättningar att utveckla</p>
    <ul>
      <li>kunskaper om biologins begrepp och förklaringsmodeller för att beskriva och förklara samband i naturen och människokroppen,</li>
      <li>förmåga att använda biologi för att granska information, kommunicera och ta ställning i frågor som rör miljö och hälsa, och</li>
      <li>förmåga att genomföra systematiska undersökningar i biologi.</li>
    </ul>`,

    '1-3': {
      sections: [
        {
          title: 'Året runt i naturen',
          topics: [
            'Årstidsväxlingar i naturen. Några djurs och växters livscykler och anpassningar till olika livsmiljöer och årstider.',
            'Djur, växter och svampar i närmiljön, hur de kan grupperas samt namn på några vanligt förekommande arter.',
            'Enkla näringskedjor som beskriver samband mellan organismer i ekosystem.',
          ],
        },
        {
          title: 'Kropp och hälsa',
          topics: [
            'Några av människans organ, deras namn och översiktliga funktion.',
            'Människans upplevelser av ljus, ljud, värme, smak och doft med hjälp av olika sinnen.',
            'Betydelsen av kost, sömn, hygien, motion och sociala relationer för att må bra.',
          ],
        },
        {
          title: 'Material och ämnen',
          topics: [
            'Hur material kan sorteras efter några egenskaper, till exempel utseende, om de är magnetiska och om de flyter eller sjunker i vatten. Hur materialen kan återvinnas.',
            'Några blandningar och hur de kan delas upp i sina olika beståndsdelar, till exempel genom avdunstning och filtrering.',
            'Vattnets olika former: fast, flytande och gas. Avdunstning, kokning, kondensering, smältning och stelning.',
          ],
        },
        {
          title: 'Systematiska undersökningar',
          topics: [
            'Enkla fältstudier, observationer och experiment. Utförande och dokumentation av undersökningarna med ord, bilder och digitala verktyg.',
            'Några berättelser om hur naturvetenskaplig kunskap vuxit fram.',
          ],
        },
      ],
    },

    '4-6': {
      sections: [
        {
          title: 'Natur och miljö',
          topics: [
            'Vad liv är och hur livets utveckling kan förklaras med evolutionsteorin. Biologisk mångfald och organismers anpassningar till miljön.',
            'Näringskedjor och kretslopp i närmiljön. Djurs, växters och svampars samspel med varandra och hur några miljöfaktorer påverkar dem. Fotosyntes och cellandning.',
            'Hur djur, växter och svampar kan identifieras och grupperas på ett systematiskt sätt, samt namn på några vanligt förekommande arter.',
            'Människans beroende av och påverkan på naturen med koppling till naturbruk, hållbar utveckling och ekosystemtjänster. Naturen som resurs och vårt ansvar när vi nyttjar den.',
          ],
        },
        {
          title: 'Kropp och hälsa',
          topics: [
            'Människans organsystem. Några organs namn, utseende, placering, funktion och samverkan.',
            'Några vanliga sjukdomar och hur de kan förebyggas och behandlas. Hur den psykiska och fysiska hälsan påverkas av levnadsförhållanden, kost, sömn, hygien, motion och beroendeframkallande medel.',
            'Människans pubertet, reproduktion, sexualitet och identitet samt frågor om relationer, kärlek och ansvar.',
          ],
        },
        {
          title: 'Systematiska undersökningar och granskning av information',
          topics: [
            'Fältstudier och experiment med såväl analoga som digitala verktyg. Planering, utförande, värdering av resultat samt dokumentation med ord, bilder och tabeller.',
            'Några upptäckter inom biologiområdet och deras betydelse för människans levnadsvillkor och syn på naturen.',
            'Kritisk granskning och användning av information som rör biologi.',
          ],
        },
      ],
    },

    '7-9': {
      sections: [
        {
          title: 'Natur och miljö',
          topics: [
            'Livets uppkomst, utveckling och mångfald samt evolutionens mekanismer. Arvsmassans egenskaper och förhållandet mellan arv och miljö.',
            'Några gentekniska metoder samt möjligheter, risker och etiska frågor kopplade till genteknik.',
            'Lokala och globala ekosystem. Sambanden mellan populationer och tillgängliga resurser. Fotosyntes, cellandning, materiens kretslopp och energins flöden.',
            'Människans påverkan på naturen lokalt och globalt samt hur man på individ- och samhällsnivå kan främja hållbar utveckling. Betydelsen av biologisk mångfald och ekosystemtjänster.',
          ],
        },
        {
          title: 'Kropp och hälsa',
          topics: [
            'Kroppens celler samt några organ och organsystem och deras uppbyggnad, funktion och samverkan.',
            'Virus, bakterier, infektioner, smittspridning och antibiotikaresistens. Hur infektionssjukdomar kan förebyggas och behandlas.',
            'Hur den psykiska och fysiska hälsan påverkas av levnadsförhållanden, kost, sömn, motion, stress och beroendeframkallande medel samt hur hälsoproblem kan begränsas på individ- och samhällsnivå.',
            'Människans reproduktion, sexualitet och identitet samt frågor om relationer, kärlek, ansvar, samtycke och ömsesidighet. Sexuellt överförbara sjukdomar och preventivmedel.',
          ],
        },
        {
          title: 'Systematiska undersökningar och granskning av information',
          topics: [
            'Fältstudier och experiment med såväl analoga som digitala verktyg. Formulering av undersökningsbara frågor, planering, utförande, värdering av resultat samt dokumentation med bilder, tabeller, diagram och rapporter.',
            'Sambandet mellan biologiska undersökningar och utvecklingen av begrepp och förklaringsmodeller. De biologiska förklaringsmodellernas historiska framväxt, användbarhet och föränderlighet.',
            'Informationssökning, kritisk granskning och användning av information som rör biologi. Argumentation och ställningstaganden i aktuella frågor som rör miljö och hälsa.',
          ],
        },
      ],
    },
  },
};

export default coursePlans;
