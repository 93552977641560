import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  TextField,
  Button,
  Typography,
  CircularProgress,
  InputAdornment,
} from '@mui/material';
import { collection, addDoc, query, where, getDocs } from 'firebase/firestore';
import { db } from './firebase';
import { callApi } from './api';
import { getStyles } from '../index';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import GenerateIcon from '@mui/icons-material/AutoAwesome';
import { useSnackbar } from './SnackbarHandler';
import Logger from './Logger';

const CourseForm = ({ user, fetchUserCourses }) => {
  const [topic, setTopic] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const theme = useTheme();
  const styles = getStyles(theme);
  const progressRef = useRef(null);
  const progressPercentRef = useRef(null);

  const formatUrlPart = str => {
    return str
      .replace(/\s+/g, '-')
      .replace(/[åä]/g, 'a')
      .replace(/[ö]/g, 'o')
      .replace(/[^a-z0-9-]/gi, '')
      .toLowerCase();
  };

  const generateUniqueSlug = async topic => {
    let baseSlug = formatUrlPart(topic);
    let uniqueSlug = baseSlug;
    let counter = 1;

    let querySnapshot;
    const q = query(collection(db, 'courses'), where('slug', '==', uniqueSlug));
    querySnapshot = await getDocs(q);

    while (!querySnapshot.empty) {
      uniqueSlug = `${baseSlug}-${counter}`;
      counter++;
      const qCheck = query(collection(db, 'courses'), where('slug', '==', uniqueSlug));
      querySnapshot = await getDocs(qCheck);
    }

    return uniqueSlug;
  };

  const updateProgress = value => {
    if (progressRef.current) {
      progressRef.current.style.width = `${value}%`;
    }
    if (progressPercentRef.current) {
      progressPercentRef.current.textContent = `${Math.round(value)}%`;
    }
    setProgress(value);
  };

  const simulateProgress = (startProgress, endProgress, duration) => {
    const stepSize = 0.5;
    const steps = (endProgress - startProgress) / stepSize;
    const stepDuration = duration / steps;
    let currentProgress = startProgress;

    const intervalId = setInterval(() => {
      currentProgress += stepSize;
      if (currentProgress >= endProgress) {
        clearInterval(intervalId);
      } else {
        updateProgress(currentProgress);
      }
    }, stepDuration);

    return () => clearInterval(intervalId);
  };

  const handleGenerateCourse = async () => {
    if (topic.trim().length < 3) {
      showSnackbar('Skriv in ett riktigt ämne', 'info');
      return;
    }

    setIsLoading(true);
    updateProgress(0);

    try {
      const prompt = `Generera en kurs om ${topic} med 3 olika delkurser, där varje delkurs innehåller en titel, en kort beskrivning på 2-3 meningar samt 5 kapitel per delkurs. Inkludera även en övergripande beskrivning av hela kursen på 3-4 meningar. Alla svar ska vara på svenska. Svaret ska vara ett JSON-objekt med följande struktur:
      {
        "course_description": "En övergripande beskrivning av hela kursen på 2-3 meningar.",
        "modules": [
          {
            "module_title": "Titel på delkursen",
            "module_description": "En kort beskrivning av delkursen.",
            "chapters": [
              {
                "chapter_title": "Titel på kapitlet",
                "chapter_description": "Kort beskrivning av kapitlet.",
                "chapter_completed": false
              },
              ...
            ]
          },
          ...
        ]
      }`;

      const stopSimulation = simulateProgress(0, 90, 15000);

      let response = await callApi(
        [
          { role: 'system', content: 'Du är en hjälpsam AI-assistent som genererar kursinnehåll.' },
          { role: 'user', content: prompt },
        ],
        undefined,
        undefined,
        user.uid
      );

      stopSimulation();
      updateProgress(94);

      Logger.log(response);

      if (typeof response === 'string') {
        if (response.startsWith('```json')) {
          response = response.slice(7, -3).trim();
          Logger.log('removed 3 characters from json');
        } else if (response.startsWith('```')) {
          response = response.slice(3, -3).trim();
        }
      }

      const parsedResponse = JSON.parse(response);

      const courseData = {
        userId: user.uid,
        topic: topic,
        description: parsedResponse.course_description,
        modules: parsedResponse.modules,
        createdAt: new Date(),
        slug: await generateUniqueSlug(topic),
      };

      const docRef = await addDoc(collection(db, 'courses'), courseData);
      console.log('Course saved with ID: ', docRef.id);

      updateProgress(100);
      fetchUserCourses();

      navigate(`/app/kurser/${courseData.slug}`);
    } catch (error) {
      console.error('Error generating course:', error);
      showSnackbar('Ett fel uppstod vid generering av kursen. Vänligen försök igen.', 'info');
    } finally {
      setIsLoading(false);
    }
  };

  const popularTopics = ['Andra världskriget', 'Napoleon', 'Matematikens grunder'];

  return (
    <>
      <Box sx={{ ...styles.buttonsContainer, ...styles.additionalButtonsContainer }}>
        <Button
          variant='contained'
          color='primary'
          startIcon={<GenerateIcon />}
          component={Link}
          to={`/app/kurser/gratis-kurser`}
        >
          Utvalda kurser
        </Button>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '70vh',
          width: '100%',
          p: 4,
        }}
      >
        <Typography
          variant='h4'
          component='h1'
          gutterBottom
          sx={{
            fontWeight: 'bold',
            mb: 2,
            textAlign: 'center',
            fontSize: { xs: '30px', md: '40px' },
          }}
        >
          Skapa en kurs med AI
        </Typography>
        <Typography
          variant='subtitle1'
          gutterBottom
          sx={{ ...styles.softerText, mb: 4, textAlign: 'center' }}
        >
          Skapa en kurs som stödjer dig i att nå dina mål i skolan, karriären och privatlivet.
        </Typography>
        <Box
          sx={{
            width: '100%',
            maxWidth: 780,
            mb: 3,
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: 'stretch',
            gap: { xs: 2, md: 0 },
          }}
        >
          <TextField
            value={topic}
            onChange={e => setTopic(e.target.value)}
            placeholder='Skriv valfritt ämne...'
            fullWidth
            sx={{
              ...styles.whiteBg,
              flexGrow: 1,
              mb: { xs: 2, md: 0 },
              mr: { md: 2 },
            }}
          />
          <Button
            onClick={handleGenerateCourse}
            variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
            sx={{
              width: { xs: '100%', md: '230px' },
              padding: '13px',
              borderRadius: '16px',
            }}
            disabled={isLoading}
            startIcon={
              !isLoading ? (
                <GenerateIcon style={{ color: '#fff' }} />
              ) : (
                <CircularProgress size={18} sx={{ color: '#fff' }} />
              )
            }
          >
            {isLoading ? 'Genererar kurs...' : 'Generera kurs'}
          </Button>
        </Box>

        {isLoading && (
          <Box sx={{ width: '100%', maxWidth: 690, mt: 2 }}>
            <Typography variant='body2' color='text.secondary' sx={{ ...styles.h5, mb: 1 }}>
              Genererar kurs om <strong>{topic}</strong>...
            </Typography>
            <Box
              sx={{
                width: '100%',
                height: '20px',
                backgroundColor: theme.palette.mode === 'dark' ? '#22303c' : 'white',
                borderRadius: '14px',
                overflow: 'hidden',
              }}
            >
              <Box
                ref={progressRef}
                sx={{
                  width: '0%',
                  height: '100%',
                  backgroundColor: theme.palette.mode === 'dark' ? '#6F38EA' : '#6339c4',
                  transition: 'width 0.1s linear',
                }}
              />
            </Box>
            <Typography
              ref={progressPercentRef}
              variant='body2'
              color='text.secondary'
              align='right'
            >
              0%
            </Typography>
          </Box>
        )}

        <Box
          sx={{
            mt: 3,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              maxWidth: 800,
              width: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                justifyContent: 'center',
              }}
            >
              <Typography
                variant='subtitle2'
                sx={{
                  ...styles.softerText,
                  fontWeight: '600',
                  marginRight: '10px',
                  whiteSpace: 'nowrap',
                  fontSize: '16px',
                  marginBottom: { xs: 2, md: 'inherit' },
                }}
              >
                Populära ämnen:
              </Typography>
              <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', justifyContent: 'center' }}>
                {popularTopics.map(popularTopic => (
                  <Button
                    key={popularTopic}
                    onClick={() => setTopic(popularTopic)}
                    sx={{
                      ...styles.beautyBtn,
                      ...styles.catBtn,
                      borderRadius: '7px',
                      fontSize: '14px',
                      border: 'none',
                      '&:hover, &:focus, &:active, &.Mui-focusVisible': {
                        border: 'none',
                      },
                    }}
                    variant='outlined'
                  >
                    {popularTopic}
                  </Button>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CourseForm;
