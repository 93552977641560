import React from 'react';
import { Box, Container, Grid, Typography, useTheme } from '@mui/material';

const TeacherStatsSection = () => {
  const theme = useTheme();

  // Stats Data
  const stats = [
    {
      value: '3000+',
      label: 'Användare',
    },
    {
      value: '8,500+',
      label: 'Skapade lektioner',
    },
    {
      value: '400+',
      label: 'Skolor',
    },
  ];

  return (
    <Container>
      <Grid container spacing={4} justifyContent='center'>
        {stats.map((stat, index) => (
          <Grid item xs={12} md={4} key={index}>
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant='h3' sx={{ fontWeight: '700' }}>
                {stat.value}
              </Typography>
              <Typography variant='h6' sx={{ opacity: 0.8 }}>
                {stat.label}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default TeacherStatsSection;
