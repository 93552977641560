import React from 'react';
import { Paper, Box, Typography, Button } from '@mui/material';
import ForumIcon from '@mui/icons-material/Forum';
import ConstructionIcon from '@mui/icons-material/Construction';

const FeedbackPaper = ({
  title = 'Din åsikt är viktig för oss',
  description = 'Klicka här för att lämna feedback eller förslag på funktioner',
  buttonText = 'Ge feedback',
  icon = <ConstructionIcon sx={{ fontSize: '40px', color: '#8b5cf6' }} />,
  onClick,
  theme,
  width,
}) => {
  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <Paper
        onClick={onClick}
        elevation={2}
        sx={{
          position: 'relative',
          borderRadius: '16px',
          p: 3,
          mt: 8,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: theme.palette.mode === 'dark' ? '#1a1a1a' : '#ffffff',
          border: theme.palette.mode === 'dark' ? '1px solid #263442' : '1px solid #ededed',
          boxShadow:
            theme.palette.mode === 'dark'
              ? '0 10px 40px rgba(139, 92, 246, 0.2)'
              : '0 10px 40px rgba(139, 92, 246, 0.1)',
          cursor: 'pointer',
          transition: 'all 0.3s ease',
          '&:hover': {
            transform: 'translateY(-3px)',
            boxShadow:
              theme.palette.mode === 'dark'
                ? '0 10px 40px rgba(139, 92, 246, 0.2)'
                : '0 10px 40px rgba(139, 92, 246, 0.1)',
          },
          width: {
            xs: '100%',
            sm: width || '100%',
          },
          maxWidth: width || '100%',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
          <Box
            sx={{
              p: 1.5,
              borderRadius: 2,
              backgroundColor:
                theme.palette.mode === 'dark' ? 'rgba(139, 92, 246, 0.1)' : '#f5f3ff',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {icon}
          </Box>
          <Box>
            <Typography
              variant='h6'
              sx={{
                fontWeight: 700,
                mb: 0.5,
                background: 'linear-gradient(90deg, #8b5cf6, #6366f1)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              {title}
            </Typography>
            <Typography variant='body1' color='text.secondary' sx={{ fontWeight: '500' }}>
              {description}
            </Typography>
          </Box>
        </Box>
        <Button variant='purp'>{buttonText}</Button>
      </Paper>
    </Box>
  );
};

export default FeedbackPaper;
