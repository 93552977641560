import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
} from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import CloseIcon from '@mui/icons-material/Close';

// Original QuestionDisplay component
const QuestionDisplay = ({ question, index, type }) => {
  const theme = useTheme();

  const getLetterForIndex = idx => String.fromCharCode(65 + idx);

  const letterBoxStyles = {
    width: 30,
    height: 30,
    bgcolor: 'background.paper',
    border: '2px solid',
    borderColor: 'text.main',
    color: 'text.main',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    mr: 2,
    borderRadius: 1,
    fontSize: '0.9rem',
    fontWeight: 'bold',
    '&:hover': {
      bgcolor: 'text.main',
      color: 'white',
    },
  };

  const renderQuestionContent = () => {
    switch (type) {
      case 'multiple-choice':
        return (
          <Box>
            {question.options.map((option, optIdx) => (
              <Box key={optIdx} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Box sx={letterBoxStyles}>{getLetterForIndex(optIdx)}</Box>
                <Typography>{option}</Typography>
              </Box>
            ))}
          </Box>
        );

      case 'fill-in-the-blank':
        return (
          <Box>
            <Typography
              variant='body1'
              sx={{
                mb: 6,
                mt: 1,
                fontWeight: 600,
                fontSize: '1.3rem',
                color: theme.palette.text.primary,
              }}
            >
              {question.question.split('_').map((part, i, arr) => (
                <React.Fragment key={i}>
                  {part}
                  {i < arr.length - 1 && (
                    <TextField
                      variant='standard'
                      size='small'
                      sx={{
                        width: '120px',
                        mx: 1,
                        borderBottom: `1px solid ${theme.palette.divider}`,
                      }}
                    />
                  )}
                </React.Fragment>
              ))}
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Typography
                variant='subtitle2'
                color='textSecondary'
                sx={{ fontSize: '18px', mb: 2 }}
              >
                Alternativ:
              </Typography>
              {question.options.map((option, idx) => (
                <Box key={idx} sx={{ display: 'flex', alignItems: 'center', ml: 2, mb: 2 }}>
                  <Box sx={letterBoxStyles}>{getLetterForIndex(idx)}</Box>
                  <Typography variant='body2' sx={{ fontSize: '16px' }}>
                    {option}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        );

      case 'true-or-false':
        return (
          <Box>
            {['Sant', 'Falskt'].map((option, idx) => (
              <Box key={idx} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Box sx={letterBoxStyles}>{getLetterForIndex(idx)}</Box>
                <Typography>{option}</Typography>
              </Box>
            ))}
          </Box>
        );

      default:
        return (
          <Box>
            {question.options.map((option, optIdx) => (
              <Box key={optIdx} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Box sx={letterBoxStyles}>{getLetterForIndex(optIdx)}</Box>
                <Typography>{option}</Typography>
              </Box>
            ))}
          </Box>
        );
    }
  };

  return (
    <Card
      elevation={0}
      sx={{
        mb: 3,
        backgroundColor: theme.palette.background.paperHover,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '15px',
        padding: '20px',
      }}
    >
      <CardContent>
        <Typography
          variant='h6'
          sx={{
            fontWeight: 500,
            color: theme.palette.text.secondary,
            fontSize: '1rem',
          }}
        >
          Fråga {index + 1}
        </Typography>

        <Typography
          variant='body1'
          sx={{
            mb: 6,
            mt: 1,
            fontWeight: 600,
            fontSize: '1.3rem',
            color: theme.palette.text.primary,
          }}
        >
          {type !== 'fill-in-the-blank' && question.question}
        </Typography>

        {renderQuestionContent()}
      </CardContent>
    </Card>
  );
};

const TeacherQuizDisplay = ({ quiz, quizName, questionType, onPrint }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(true); // Start open by default

  const handleClose = () => setOpen(false);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth='md'
      fullWidth
      scroll='paper'
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: 1000,
          borderRadius: '20px',
          backgroundColor: theme.palette.mode === 'dark' ? '#22303C' : 'white',
          backgroundImage: theme.palette.mode === 'dark' ? 'none' : 'white',
          padding: { xs: '0px', md: '20px' },
        },
      }}
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant='h6' sx={{ fontWeight: '600' }}>
          {quizName}
        </Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Box sx={{ width: '100%' }}>
          {quiz.map((question, index) => (
            <QuestionDisplay key={index} question={question} index={index} type={questionType} />
          ))}

          <Card
            elevation={0}
            sx={{
              mt: 4,
              backgroundColor: theme.palette.background.paperHover,
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: '15px',
              padding: '20px',
            }}
          >
            <CardContent>
              <Typography variant='h6' gutterBottom sx={{ fontWeight: '600' }}>
                Facit
              </Typography>
              {quiz.map((question, index) => (
                <Box key={index} sx={{ mb: 2, mt: 4 }}>
                  <Typography>
                    Fråga {index + 1}: {question.correct}
                  </Typography>
                </Box>
              ))}
            </CardContent>
          </Card>
        </Box>
      </DialogContent>

      <DialogActions sx={{ p: 2 }}>
        <Button variant='print' onClick={handleClose} sx={{ mr: 1 }}>
          Stäng
        </Button>
        <Button variant='purp' onClick={onPrint} startIcon={<PrintIcon />}>
          Skriv ut
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TeacherQuizDisplay;
