import React, { useState } from 'react';
import {
  Grid,
  Typography,
  Paper,
  CircularProgress,
  Box,
  Container,
  TextField,
  Stack,
  useTheme,
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { motion } from 'framer-motion';
import { StyledButton } from './StyledComponents';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { useSnackbar } from './app/SnackbarHandler';
import EmailIcon from '@mui/icons-material/Email';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PhoneIcon from '@mui/icons-material/Phone';
const API_URL = process.env.NODE_ENV === 'production' ? '/api' : 'http://localhost:3001/api';

const ContactForm = ({
  bgcolor = 'background.default',
  email = '',
  autofocus = false,
  feedbackDialog = false,
}) => {
  const theme = useTheme();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { showSnackbar } = useSnackbar();

  const headerText = feedbackDialog ? 'Dela din feedback' : 'Har du några frågor?';

  const descriptionText = feedbackDialog
    ? 'Vi värdesätter dina synpunkter och förslag! Dela gärna med dig av din feedback så att vi kan fortsätta förbättra vår tjänst.'
    : 'Kontakta oss om du har frågor, feedback, samarbetsförslag, vill boka en demo, eller helt enkelt har en fundering!';

  return (
    <Box sx={{ position: 'relative' }}>
      <Box sx={{ bgcolor: bgcolor, py: feedbackDialog ? 8 : 12 }}>
        <Container maxWidth='lg'>
          <Grid container spacing={8} alignItems='center'>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                alignSelf: 'center',
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <motion.div
                initial={{ opacity: 0, x: -50 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.8 }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 2,
                    mb: 4,
                  }}
                >
                  <ContactMailIcon sx={{ fontSize: '4.5rem', color: '#8B5CF6' }} />
                </Box>
                <Typography
                  variant='h3'
                  sx={{
                    fontWeight: 800,
                    mb: 2,
                    fontSize: { xs: '2rem', md: '2.5rem' },
                  }}
                >
                  {headerText}
                </Typography>
                <Typography
                  variant='body1'
                  sx={{
                    mb: 4,
                    color: 'text.secondary',
                    fontSize: '1.2rem',
                    lineHeight: 1.7,
                    whiteSpace: 'pre-line',
                  }}
                >
                  {descriptionText}
                </Typography>

                <Stack spacing={3} sx={{ mt: 5, alignItems: 'center' }}>
                  <Box
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 2 }}
                  >
                    <AlternateEmailIcon sx={{ fontSize: '1.7rem', color: '#8B5CF6' }} />
                    <Typography variant='h6' sx={{ fontSize: '1.25rem', color: 'text.secondary' }}>
                      info@studera.ai
                    </Typography>
                  </Box>

                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, display: 'none' }}>
                    <PhoneIcon sx={{ fontSize: '2rem', color: 'primary.main' }} />
                    <Typography variant='h6' sx={{ fontSize: '1.25rem', color: 'text.secondary' }}>
                      +46 735 91 33 20
                    </Typography>
                  </Box>
                </Stack>
              </motion.div>
            </Grid>
            <Grid item xs={12} md={6}>
              <motion.div
                initial={{ opacity: 0, x: 50 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.8 }}
              >
                <Paper
                  elevation={0}
                  sx={{
                    p: 6,
                    borderRadius: 4,
                    bgcolor: theme.palette.mode === 'dark' ? '#15202b' : 'white',
                    boxShadow: '0 10px 40px rgba(0,0,0,0.04)',
                  }}
                >
                  <form
                    onSubmit={async e => {
                      e.preventDefault();
                      const formData = new FormData(e.target);
                      const name = formData.get('name');
                      const email = formData.get('email');
                      const message = formData.get('message');

                      if (!name || !email || !message) {
                        showSnackbar('Vänligen fyll i alla fält', 'warning');
                        return;
                      }

                      if (!/\S+@\S+\.\S+/.test(email)) {
                        showSnackbar('Vänligen ange en giltig e-postadress', 'warning');
                        return;
                      }

                      try {
                        setIsSubmitting(true);
                        const response = await fetch(`${API_URL}/send-email`, {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json',
                          },
                          body: JSON.stringify({
                            name,
                            email,
                            message,
                          }),
                        });

                        if (response.ok) {
                          showSnackbar('Tack för ditt meddelande!', 'success');
                          e.target.reset();
                        } else {
                          throw new Error('Failed to send');
                        }
                      } catch (error) {
                        showSnackbar('Något gick fel. Försök igen senare.', 'error');
                      } finally {
                        setIsSubmitting(false);
                      }
                    }}
                  >
                    <Stack spacing={3}>
                      <TextField
                        fullWidth
                        label='Namn'
                        name='name'
                        variant='outlined'
                        autoFocus={autofocus}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            borderRadius: 2,
                          },
                        }}
                      />
                      <TextField
                        fullWidth
                        label='E-postadress'
                        name='email'
                        type='email'
                        variant='outlined'
                        defaultValue={email}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            borderRadius: 2,
                          },
                        }}
                      />
                      <TextField
                        fullWidth
                        label='Meddelande'
                        name='message'
                        multiline
                        rows={2}
                        variant='outlined'
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            borderRadius: 2,
                          },
                        }}
                      />
                      <StyledButton
                        type='submit'
                        variant='blue'
                        size='large'
                        fullWidth
                        disabled={isSubmitting}
                        sx={{
                          borderRadius: '10px',
                          padding: '12px 32px',
                          fontSize: '1.1rem',
                        }}
                        endIcon={
                          isSubmitting ? (
                            <CircularProgress size={16} color='inherit' />
                          ) : (
                            <ArrowForwardIcon />
                          )
                        }
                      >
                        Skicka meddelande
                      </StyledButton>
                    </Stack>
                  </form>
                </Paper>
              </motion.div>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default ContactForm;
