import React from 'react';
import { Box, Typography, Paper, useTheme, styled, Container } from '@mui/material';
import { AppBar, Toolbar } from '@mui/material';
import { Category, Bookmarks, Assignment, Home } from '@mui/icons-material';
import {
  NewReleases, // For "Nyheter"
  School, // For "Grundskola"
  Psychology, // For "Högstadie"
  Celebration, // For "Jultema"
} from '@mui/icons-material';
// Export these for use in EpubReader
export const AVAILABLE_BOOKS = [
  {
    id: 1,
    title: 'Jag, Lars Hård',
    path: '/lars.epub',
    cover: '/lars.jpg',
    category: 'nyheter',
  },

  {
    id: 4,
    title: 'Nils Holgerssons underbara resa',
    path: '/nils.epub',
    cover: '/nils.jpg',
    category: 'nyheter',
  },
  {
    id: 5,
    title: 'Doktor Glas',
    path: '/glas.epub',
    cover: '/glas.jpg',
    category: 'nyheter',
  },

  {
    id: 7,
    title: 'Jag, Lars Hård',
    path: '/lars.epub',
    cover: '/lars.jpg',
    category: 'nyheter',
  },

  {
    id: 8,
    title: 'Nils Holgerssons underbara resa',
    path: '/nils.epub',
    cover: '/nils.jpg',
    category: 'nyheter',
  },
  {
    id: 9,
    title: 'Doktor Glas',
    path: '/glas.epub',
    cover: '/glas.jpg',
    category: 'nyheter',
  },

  {
    id: 2,
    title: 'Nils Holgerssons underbara resa',
    path: '/nils.epub',
    cover: '/nils.jpg',
    category: 'grundskola',
  },
  {
    id: 3,
    title: 'Doktor Glas',
    path: '/glas.epub',
    cover: '/glas.jpg',
    category: 'hogstadie',
  },

  {
    id: 6,
    title: 'Nils Holgerssons underbara resa',
    path: '/nils.epub',
    cover: '/nils.jpg',
    category: 'jultema',
  },
];

export const getSelectedBookTitle = bookPath => {
  if (!bookPath) return '';
  const book = AVAILABLE_BOOKS.find(book => book.path === bookPath);
  return book ? book.title : '';
};

const CategoryHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  paddingLeft: '20px',
  background:
    theme.palette.mode === 'light'
      ? '#d7e2ec'
      : 'linear-gradient(to right, #303030, #424242, #303030)',
  marginBottom: theme.spacing(6),
}));

const BookContainer = styled(Paper)(({ theme, selected }) => ({
  cursor: 'pointer',
  transition: 'all 0.3s ease',
  transform: selected ? 'translateY(-8px)' : 'translateY(0)',
  '&:hover': {
    transform: 'translateY(-8px)',
  },
  width: '160px',
  position: 'relative',
  overflow: 'hidden',
  borderRadius: '10px',
}));

const ShelfPaper = styled(Paper)(({ theme }) => ({
  height: '40px',
  position: 'relative',
  width: '100vw',
  left: '50%',
  right: '50%',
  marginLeft: '-50vw',
  marginRight: '-50vw',
  backgroundImage: 'url("/shelf.png")',
  backgroundSize: 'cover',
  backgroundRepeat: 'repeat-x',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: -10,
    left: 0,
    right: 0,
    height: '10px',
    background: 'linear-gradient(0deg, transparent 0%, rgba(0,0,0,0.2) 100%)',
  },
}));

const CategoryIcon = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& > svg': {
    fontSize: 28,
    color: theme.palette.text.primary,
  },
}));

const CATEGORIES = {
  nyheter: {
    title: 'Nyheter',
    books: AVAILABLE_BOOKS.filter(book => book.category === 'nyheter'),
  },
  grundskola: {
    title: 'Grundskola',
    books: AVAILABLE_BOOKS.filter(book => book.category === 'grundskola'),
  },
  hogstadie: {
    title: 'Högstadie',
    books: AVAILABLE_BOOKS.filter(book => book.category === 'hogstadie'),
  },
  jultema: {
    title: 'Jultema',
    books: AVAILABLE_BOOKS.filter(book => book.category === 'jultema'),
  },
};

const BookShelf = ({ onSelect, selectedBook }) => {
  const theme = useTheme();

  return (
    <Box sx={{ overflow: 'hidden' }}>
      <Box sx={{ mt: 6 }}>
        {Object.entries(CATEGORIES).map(([key, category]) => (
          <Box key={key} sx={{ mb: 8 }}>
            <CategoryHeader>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                {key === 'nyheter' && <NewReleases />}
                {key === 'grundskola' && <School />}
                {key === 'hogstadie' && <Psychology />}
                {key === 'jultema' && <Celebration />}
                <Typography variant='h5' sx={{ fontWeight: 600, fontSize: '1.22rem' }}>
                  {category.title}
                </Typography>
              </Box>
            </CategoryHeader>

            <Container maxWidth='lg' sx={{ mb: -2, position: 'relative', zIndex: 1 }}>
              <Box sx={{ mb: 0 }}>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 3,
                    pb: 0,
                    overflowX: 'auto',
                    '&::-webkit-scrollbar': {
                      height: 8,
                    },
                    '&::-webkit-scrollbar-track': {
                      backgroundColor: theme.palette.grey[200],
                      borderRadius: 4,
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: theme.palette.grey[400],
                      borderRadius: 4,
                      '&:hover': {
                        backgroundColor: theme.palette.grey[500],
                      },
                    },
                  }}
                >
                  {category.books.length > 0 ? (
                    category.books.map(book => (
                      <BookContainer
                        key={book.id}
                        selected={selectedBook === book.path}
                        onClick={() => onSelect(book.path)}
                        elevation={selectedBook === book.path ? 8 : 4}
                      >
                        <Box
                          sx={{
                            position: 'relative',
                            paddingTop: '150%',
                          }}
                        >
                          <Box
                            component='img'
                            src={book.cover}
                            alt={book.title}
                            sx={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                            }}
                          />
                          <Box
                            sx={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              width: '20px',
                              height: '100%',
                              background:
                                'linear-gradient(90deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0) 100%)',
                            }}
                          />
                        </Box>
                      </BookContainer>
                    ))
                  ) : (
                    <Box
                      sx={{
                        width: '100%',
                        height: '240px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography variant='body1' color='text.secondary'>
                        Inga böcker tillgängliga
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </Container>
            <ShelfPaper elevation={3} />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default BookShelf;
