import React from 'react';

import { Box, Skeleton, Card, CardContent, Grid } from '@mui/material';

export const LessonsSkeleton = ({ theme, tabCount = 4, lessonCount = 3 }) => (
  <>
    <Box
      sx={{
        borderColor: 'divider',
        background: theme.palette.mode === 'dark' ? '#22303c' : '#f9f9fa',
        borderTopLeftRadius: '9px',
        borderTopRightRadius: '9px',
        p: 1,
        display: 'flex',
        gap: 2,
        px: 2,
        overflow: 'hidden',
      }}
    >
      {Array.from({ length: tabCount }).map((_, index) => (
        <Skeleton
          key={index}
          variant='text'
          width={120}
          height={48}
          sx={{
            borderRadius: 2,
            transform: 'scale(1, 0.8)',
          }}
        />
      ))}
    </Box>
    <Box sx={{ p: 3 }}>
      <Grid container spacing={3}>
        {Array.from({ length: lessonCount }).map((_, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                height: '100%',
                p: 2,
                backgroundColor:
                  theme.palette.mode === 'dark'
                    ? 'rgba(255, 255, 255, 0.05)'
                    : 'rgba(0, 0, 0, 0.02)',
                borderRadius: 2,
              }}
            >
              <Skeleton
                variant='rectangular'
                height={140}
                sx={{
                  borderRadius: 1,
                  mb: 1,
                }}
              />
              <Skeleton variant='text' width='80%' height={24} />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  </>
);

export const CourseListSkeleton = ({ theme, numberOfCourses = 3, compact = false }) => (
  <Grid container spacing={3}>
    {[...Array(numberOfCourses)].map((_, index) => (
      <Grid item xs={12} sm={6} md={compact ? 3 : 4} key={index}>
        <Card
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            boxShadow: 'none',
            borderRadius: '7px',
            border: compact
              ? 'none'
              : theme.palette.mode === 'dark'
                ? '1px solid #35414d'
                : '1px solid #eee',
          }}
        >
          <CardContent
            sx={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
              padding: compact ? '20px' : '30px',
              backgroundColor: compact
                ? theme.palette.mode === 'dark'
                  ? '#2c3740'
                  : '#f9f9f9'
                : theme.palette.mode === 'dark'
                  ? 'rgba(255, 255, 255, 0.05)'
                  : 'white',
            }}
          >
            <Box>
              <Skeleton
                variant='circular'
                width={compact ? 30 : 40}
                height={compact ? 30 : 40}
                animation='wave'
              />
            </Box>

            <Skeleton
              variant='text'
              width='80%'
              height={compact ? 24 : 32}
              animation='wave'
              sx={{ mt: 2 }}
            />

            <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px', mt: 1 }}>
              <Skeleton variant='circular' width={15} height={15} animation='wave' />
              <Skeleton variant='text' width={50} height={18} animation='wave' />
              <Skeleton variant='circular' width={15} height={15} animation='wave' sx={{ ml: 1 }} />
              <Skeleton variant='text' width={70} height={18} animation='wave' />
            </Box>

            {!compact && (
              <>
                <Skeleton variant='text' width='100%' height={60} animation='wave' sx={{ mt: 2 }} />

                <Box
                  sx={{
                    backgroundColor: theme.palette.mode === 'dark' ? '#2d3e4d' : '#f9f9f9',
                    borderRadius: '6px',
                    padding: '15px',
                    mt: 3,
                  }}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                    <Skeleton variant='text' width={80} height={20} animation='wave' />
                    <Skeleton variant='text' width={80} height={20} animation='wave' />
                  </Box>
                  <Skeleton
                    variant='rectangular'
                    width='100%'
                    height={6}
                    animation='wave'
                    sx={{ borderRadius: 3, mb: 1 }}
                  />
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Skeleton variant='text' width={60} height={16} animation='wave' />
                  </Box>
                </Box>

                <Skeleton
                  variant='rectangular'
                  width='100%'
                  height={36}
                  animation='wave'
                  sx={{ mt: 2, borderRadius: 1 }}
                />
              </>
            )}
          </CardContent>
        </Card>
      </Grid>
    ))}
  </Grid>
);

export const CategoryCarouselSkeleton = ({ theme }) => (
  <Box
    sx={{
      mb: 4,
      borderRadius: '16px',
      overflow: 'hidden',
      backgroundColor: theme.palette.mode === 'dark' ? 'rgb(29, 43, 55)' : 'rgb(223, 225, 227)',
    }}
  >
    <Box sx={{ padding: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Skeleton variant='text' width='30%' height={40} animation='wave' />
        <Skeleton variant='circular' width={40} height={40} animation='wave' />
      </Box>
      <Skeleton variant='text' width='80%' height={24} animation='wave' sx={{ mb: 3 }} />
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
        {[...Array(4)].map((_, index) => (
          <Box key={index} sx={{ width: 'calc(25% - 16px)', minWidth: '200px' }}>
            <Card
              sx={{
                height: '100%',
                borderRadius: '12px',
                backgroundColor:
                  theme.palette.mode === 'dark' ? 'rgb(40, 54, 66)' : 'rgba(255, 255, 255, 0.37)',
              }}
            >
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Skeleton
                    variant='circular'
                    width={50}
                    height={50}
                    animation='wave'
                    sx={{ mr: 1 }}
                  />
                  <Box>
                    <Skeleton variant='text' width={100} height={24} animation='wave' />
                    <Skeleton variant='text' width={80} height={20} animation='wave' />
                  </Box>
                </Box>
                <Skeleton variant='text' width='100%' height={60} animation='wave' sx={{ mb: 1 }} />
                <Box
                  sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                  <Skeleton variant='text' width={60} height={20} animation='wave' />
                  <Skeleton variant='circular' width={24} height={24} animation='wave' />
                </Box>
              </CardContent>
            </Card>
          </Box>
        ))}
      </Box>
    </Box>
  </Box>
);

export const FlashcardSkeleton = () => (
  <Card sx={{ mb: 0, boxShadow: 'none' }}>
    <CardContent
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        padding: '8px',
        boxShadow: 'none',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Skeleton variant='text' width='80%' height={24} animation='wave' />
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Skeleton variant='circular' width={20} height={20} animation='wave' sx={{ mr: 1 }} />
            <Skeleton variant='text' width={60} height={20} animation='wave' />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Skeleton variant='text' width={60} height={30} animation='wave' />
            <Skeleton variant='text' width={40} height={30} animation='wave' />
          </Box>
        </Box>
      </Box>
    </CardContent>
  </Card>
);

export const QuizSkeleton = () => (
  <Card sx={{ mb: 0, boxShadow: 'none' }}>
    <CardContent
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        padding: '8px',
        boxShadow: 'none',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: '60%',
        }}
      >
        <Skeleton variant='text' width='80%' height={24} animation='wave' />
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
          <Skeleton variant='circular' width={20} height={20} animation='wave' sx={{ mr: 1 }} />
          <Skeleton variant='text' width={60} height={20} animation='wave' />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
        }}
      >
        <Skeleton variant='text' width={80} height={20} animation='wave' />
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 1 }}>
          <Skeleton variant='text' width={60} height={30} animation='wave' />
          <Skeleton variant='text' width={40} height={30} animation='wave' />
        </Box>
      </Box>
    </CardContent>
  </Card>
);

export const TeacherSkeleton = () => (
  <Box
    sx={{
      width: 'calc(33.33% - 16px)',
      minWidth: '200px',
      '@media (max-width: 900px)': {
        width: 'calc(50% - 8px)',
      },
      '@media (max-width: 600px)': {
        width: '100%',
      },
    }}
  >
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 2,
        padding: 1,
        borderRadius: 10,
      }}
    >
      <Skeleton variant='circular' width={36} height={36} animation='wave' />
      <Box sx={{ flexGrow: 1, minWidth: 0 }}>
        <Skeleton variant='text' width='80%' height={20} animation='wave' />
        <Skeleton variant='text' width='60%' height={16} animation='wave' />
      </Box>
    </Box>
  </Box>
);

export default TeacherSkeleton;
