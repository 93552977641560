import React, { useState, useRef, useEffect } from 'react';
import { Box, Typography, IconButton, useTheme } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { getStyles } from '../index';
const QuizCarousel = ({ title, quizzes, renderQuizCard }) => {
  const theme = useTheme();

  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const carouselRef = useRef(null);
  const styles = getStyles(theme);
  const handleMouseDown = e => {
    setIsDragging(true);
    setStartX(e.pageX - carouselRef.current.offsetLeft);
    setScrollLeft(carouselRef.current.scrollLeft);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = e => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - carouselRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    carouselRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleScroll = direction => {
    const container = carouselRef.current;
    if (container) {
      const scrollAmount = direction === 'left' ? -container.offsetWidth : container.offsetWidth;
      container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  return (
    <Box sx={{ mb: 4 }}>
      <Box sx={{ position: 'relative' }}>
        <IconButton
          onClick={() => handleScroll('left')}
          sx={{
            position: 'absolute',
            left: -20,
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 2,
            bgcolor: 'background.paper',
            '&:hover': { bgcolor: 'action.hover' },
            display: { xs: 'none', sm: 'flex' },
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
        <Box
          ref={carouselRef}
          onMouseDown={handleMouseDown}
          onMouseLeave={handleMouseLeave}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
          sx={{
            display: 'flex',
            overflowX: 'auto',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': { display: 'none' },
            cursor: isDragging ? 'grabbing' : 'grab',
            '&::after': {
              content: '""',
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              width: '100px',
              background: `linear-gradient(to right, transparent, ${theme.palette.background.default})`,
              pointerEvents: 'none',
            },
          }}
        >
          {quizzes.map((quiz, index) => (
            <Box
              key={index}
              sx={{
                flex: '0 0 auto',
                width: '300px',
                mr: 2,
                '&:last-child': { mr: 0 },
              }}
            >
              {renderQuizCard(quiz)}
            </Box>
          ))}
        </Box>
        <IconButton
          onClick={() => handleScroll('right')}
          sx={{
            position: 'absolute',
            right: -20,
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 2,
            bgcolor: 'background.paper',
            '&:hover': { bgcolor: 'action.hover' },
            display: { xs: 'none', sm: 'flex' },
          }}
        >
          <ChevronRightIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default QuizCarousel;
