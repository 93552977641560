import React, { useState, useEffect } from 'react';
import {
  collection,
  query,
  orderBy,
  getDocs,
  where,
  Timestamp,
  onSnapshot,
} from 'firebase/firestore';
import { db } from './firebase';
import { format, isToday, isYesterday, startOfYesterday, startOfToday } from 'date-fns';
import { sv } from 'date-fns/locale';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
  Box,
  Chip,
  Tooltip,
  Grow,
  Grid,
  Card,
} from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import RepeatIcon from '@mui/icons-material/Repeat';
import PersonIcon from '@mui/icons-material/Person';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import RemoveIcon from '@mui/icons-material/Remove';

const AdminDashboard = () => {
  const [usageData, setUsageData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userCounts, setUserCounts] = useState({});
  const [realtimeUsageData, setRealtimeUsageData] = useState([]); // Add this new state
  const [userData, setUserData] = useState({}); // Add this new state
  const [newRowIds, setNewRowIds] = useState(new Set());

  const adminUsers = [
    'Z1dF5PGfjvdBGS9egzACvcSRZiN2',
    'x2rjH7JxWxc6GnFgPSTxGxN1bm63',
    'Z6BLSMsm38Un5mgW0O6Z3TToHq12',
  ];

  useEffect(() => {
    const fetchStaticData = async () => {
      try {
        const yesterdayStart = startOfYesterday();

        const lessonQuery = query(
          collection(db, 'lesson_usage'),
          where('timestamp', '>=', Timestamp.fromDate(yesterdayStart)),
          orderBy('timestamp', 'desc')
        );
        const lessonSnapshot = await getDocs(lessonQuery);

        const usersSnapshot = await getDocs(collection(db, 'users'));
        const userDataMap = {};
        usersSnapshot.forEach(doc => {
          const data = doc.data();
          userDataMap[doc.id] = {
            email: data.email,
            guest: data.guest,
            uid: doc.id,
            school: data.school,
            municipality: data.municipality,
            tokens: data.tokens,
          };
        });

        setUserData(userDataMap);

        const data = lessonSnapshot.docs
          .map(doc => ({
            id: doc.id,
            ...doc.data(),
            userData: userDataMap[doc.data().userId] || { guest: true, uid: doc.data().userId },
            timestamp: doc.data().timestamp?.toDate(),
          }))
          .filter(item => !adminUsers.includes(item.userData.uid));

        const counts = data.reduce((acc, item) => {
          const userId = item.userData.uid;
          acc[userId] = (acc[userId] || 0) + 1;
          return acc;
        }, {});

        setUserCounts(counts);
        setUsageData(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching static data:', error);
        setLoading(false);
      }
    };

    fetchStaticData();
  }, []);

  useEffect(() => {
    const yesterdayStart = startOfYesterday();

    const tableQuery = query(
      collection(db, 'lesson_usage'),
      where('timestamp', '>=', Timestamp.fromDate(yesterdayStart)),
      orderBy('timestamp', 'desc')
    );

    const unsubscribe = onSnapshot(
      tableQuery,
      snapshot => {
        // Track new document IDs
        const newIds = new Set();
        snapshot.docChanges().forEach(change => {
          if (change.type === 'added') {
            newIds.add(change.doc.id);
            // Remove highlight after 5 seconds
            setTimeout(() => {
              setNewRowIds(prev => {
                const updated = new Set(prev);
                updated.delete(change.doc.id);
                return updated;
              });
            }, 5000);
          }
        });
        setNewRowIds(prev => new Set([...prev, ...newIds]));

        const updatedData = snapshot.docs
          .map(doc => ({
            id: doc.id,
            ...doc.data(),
            userData: userData[doc.data().userId] || { guest: true, uid: doc.data().userId },
            timestamp: doc.data().timestamp?.toDate(),
          }))
          .filter(item => !adminUsers.includes(item.userData.uid));

        const newCounts = updatedData.reduce((acc, item) => {
          const userId = item.userData.uid;
          acc[userId] = (acc[userId] || 0) + 1;
          return acc;
        }, {});

        setUserCounts(newCounts);
        setRealtimeUsageData(updatedData);
      },
      error => {
        console.error('Error in real-time listener:', error);
      }
    );

    return () => unsubscribe();
  }, [userData]);

  const formatPercentageChange = (today, yesterday) => {
    if (yesterday === 0) return today > 0 ? '+∞%' : '0%';
    const change = ((today - yesterday) / yesterday) * 100;
    const sign = change > 0 ? '+' : '';
    return `${sign}${change.toFixed(1)}%`;
  };

  const calculateSchoolStats = data => {
    const filteredData = data.filter(item => !adminUsers.includes(item.userData.uid));

    const schoolStats = {};
    const municipalityStats = {};

    filteredData.forEach(item => {
      const school = item.userData.school || 'Ospecificerad';
      const municipality = item.userData.municipality || 'Ospecificerad';
      const userId = item.userData.uid;

      if (!schoolStats[school]) {
        schoolStats[school] = {
          name: school,
          totalUsers: new Set(),
          totalLessons: 0,
        };
      }

      if (!municipalityStats[municipality]) {
        municipalityStats[municipality] = {
          name: municipality,
          totalUsers: new Set(),
          totalLessons: 0,
          schools: new Set(),
        };
      }

      schoolStats[school].totalUsers.add(userId);
      schoolStats[school].totalLessons++;

      municipalityStats[municipality].totalUsers.add(userId);
      municipalityStats[municipality].totalLessons++;
      municipalityStats[municipality].schools.add(school);
    });

    const schoolData = Object.values(schoolStats)
      .map(stat => ({
        name: stat.name,
        users: stat.totalUsers.size,
        lessons: stat.totalLessons,
      }))
      .sort((a, b) => b.lessons - a.lessons)
      .slice(0, 10);

    const municipalityData = Object.values(municipalityStats)
      .map(stat => ({
        name: stat.name,
        users: stat.totalUsers.size,
        lessons: stat.totalLessons,
        schools: stat.schools.size,
      }))
      .sort((a, b) => b.lessons - a.lessons)
      .slice(0, 10);

    return { schoolData, municipalityData };
  };

  const StatsCard = ({ title, today, yesterday }) => {
    const difference = today - yesterday;
    const percentageChange = formatPercentageChange(today, yesterday);
    let color = difference > 0 ? 'success.main' : difference < 0 ? 'error.main' : 'text.secondary';

    return (
      <Card>
        <Box sx={{ p: 2 }}>
          <Typography variant='subtitle2' color='text.secondary'>
            {title}
          </Typography>
          <Box sx={{ mt: 1, display: 'flex', alignItems: 'baseline' }}>
            <Typography variant='h4' sx={{ mr: 1 }}>
              {today}
            </Typography>
            <Typography variant='body2' sx={{ color }}>
              {percentageChange}
            </Typography>
          </Box>
          <Typography variant='caption' color='text.secondary' sx={{ mt: 1, display: 'block' }}>
            Igår: {yesterday}
          </Typography>
        </Box>
      </Card>
    );
  };

  const calculateTimePeriods = data => {
    const filteredData = data.filter(item => !adminUsers.includes(item.userData.uid));

    const periods = {
      morgon: { start: 6, end: 10, count: 0 },
      förmiddag: { start: 10, end: 13, count: 0 },
      eftermiddag: { start: 13, end: 17, count: 0 },
      kväll: { start: 17, end: 22, count: 0 },
      natt: { start: 22, end: 6, count: 0 },
    };

    filteredData.forEach(item => {
      const hour = item.timestamp.getHours();

      if (hour >= 6 && hour < 10) periods.morgon.count++;
      else if (hour >= 10 && hour < 13) periods.förmiddag.count++;
      else if (hour >= 13 && hour < 17) periods.eftermiddag.count++;
      else if (hour >= 17 && hour < 22) periods.kväll.count++;
      else periods.natt.count++;
    });

    // Hitta period med högst antal
    const maxPeriod = Object.entries(periods).sort(([, a], [, b]) => b.count - a.count)[0];

    return {
      periods,
      maxPeriod: {
        name: maxPeriod[0],
        count: maxPeriod[1].count,
      },
    };
  };

  const calculateStats = () => {
    // Split data into today and yesterday
    const todayData = usageData.filter(item => isToday(item.timestamp));
    const yesterdayData = usageData.filter(item => isYesterday(item.timestamp));

    const getStatsForDay = data => {
      // Get user activity

      const filteredData = data.filter(item => !adminUsers.includes(item.userData.uid));

      const userMap = filteredData.reduce((acc, item) => {
        const userId = item.userData.uid;
        if (!acc[userId]) {
          acc[userId] = {
            count: 0,
            isGuest: item.userData.guest,
            identifier: item.userData.guest
              ? `Gäst (${userId.substring(userId.startsWith('guest-') ? 6 : 0, userId.startsWith('guest-') ? 11 : 5)})`
              : item.userData.email,
          };
        }
        acc[userId].count++;
        return acc;
      }, {});

      const users = Object.values(userMap);

      // Get lesson types
      const lessonTypes = data.reduce((acc, item) => {
        const type = item.lessonType;
        acc[type] = (acc[type] || 0) + 1;
        return acc;
      }, {});

      return {
        totalLessons: data.length,
        uniqueUsers: users.length,
        multiLessonUsers: users.filter(u => u.count > 1).length,
        guestSingleLesson: users.filter(u => u.isGuest && u.count === 1).length,
        guestMultiLesson: users.filter(u => u.isGuest && u.count > 1).length,
        lessonTypes,
        topUsers: users
          .sort((a, b) => b.count - a.count)
          .slice(0, 12)
          .map(u => ({
            identifier: u.identifier,
            count: u.count,
          })),
      };
    };

    const today = getStatsForDay(todayData);
    const yesterday = getStatsForDay(yesterdayData);

    // Combine lesson types for chart
    const allTypes = new Set([
      ...Object.keys(today.lessonTypes),
      ...Object.keys(yesterday.lessonTypes),
    ]);
    const lessonTypeData = Array.from(allTypes)
      .map(type => ({
        name: type,
        Idag: today.lessonTypes[type] || 0,
        Igår: yesterday.lessonTypes[type] || 0,
      }))
      .sort((a, b) => b.Idag - a.Idag);

    // Beräkna tidsperioder
    const timePeriodsToday = calculateTimePeriods(todayData);
    const timePeriodsYesterday = calculateTimePeriods(yesterdayData);
    const { schoolData, municipalityData } = calculateSchoolStats([...todayData, ...yesterdayData]);
    return {
      today,
      yesterday,
      lessonTypeData,
      timePeriodsToday,
      timePeriodsYesterday,
      schoolData,
      municipalityData,
    };
  };

  const formatUserIdentifier = userData => {
    if (!userData) return 'Unknown User';

    if (userData.guest) {
      const guestId = userData.uid.startsWith('guest-')
        ? userData.uid.substring(6, 11)
        : userData.uid.substring(0, 5);
      return guestId;
    }

    return userData.email || 'Unknown Email';
  };

  const groupUsageByDate = data => {
    return data.reduce((groups, item) => {
      let dateGroup = isToday(item.timestamp) ? 'Idag' : 'Igår';
      if (!groups[dateGroup]) {
        groups[dateGroup] = [];
      }
      groups[dateGroup].push(item);
      return groups;
    }, {});
  };

  const UserCell = ({ userData, userCounts }) => {
    const count = userCounts[userData.uid] || 0;

    if (!userData.guest) {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <PersonIcon fontSize='small' />
          {userData.email}
          {count > 1 && (
            <Tooltip title={`Denna användare har genererat ${count} lektioner sedan igår`}>
              <Chip label={count} size='small' color='secondary' />
            </Tooltip>
          )}
        </Box>
      );
    }

    const guestId = formatUserIdentifier(userData);
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Tooltip title={`Gäst ID: ${userData.uid}`}>
          <Chip
            label={`Gäst (${guestId})`}
            size='small'
            color={count > 1 ? 'primary' : 'default'}
            sx={{
              '& .MuiChip-label': {
                display: 'flex',
                alignItems: 'center',
                gap: 0.5,
              },
            }}
            icon={count > 1 ? <RepeatIcon /> : undefined}
          />
        </Tooltip>
        {count > 1 && (
          <Tooltip title={`Denna gäst har genererat ${count} lektioner sedan igår`}>
            <Chip label={count} size='small' color='secondary' />
          </Tooltip>
        )}
      </Box>
    );
  };

  if (loading) {
    return (
      <Box display='flex' justifyContent='center' alignItems='center' minHeight='100vh'>
        <CircularProgress />
      </Box>
    );
  }

  const groupedData = groupUsageByDate(realtimeUsageData);
  const stats = calculateStats();

  return (
    <Box sx={{ p: 3, maxWidth: '1400px', margin: '0 auto' }}>
      <Typography variant='h4' component='h1' gutterBottom sx={{ mb: 4 }}>
        Användaraktivitet
      </Typography>

      {/* Stats Overview */}
      <Box sx={{ mb: 4 }}>
        <Grid container spacing={3}>
          {/* Key Metrics */}
          <Grid item xs={12} md={6} lg={3}>
            <StatsCard
              title='Antal lektioner idag'
              today={stats.today.totalLessons}
              yesterday={stats.yesterday.totalLessons}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <StatsCard
              title='Användare med 2+ lektioner'
              today={stats.today.multiLessonUsers}
              yesterday={stats.yesterday.multiLessonUsers}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <StatsCard
              title='Gästkonton (1 lektion)'
              today={stats.today.guestSingleLesson}
              yesterday={stats.yesterday.guestSingleLesson}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <StatsCard
              title='Gästkonton (2+ lektioner)'
              today={stats.today.guestMultiLesson}
              yesterday={stats.yesterday.guestMultiLesson}
            />
          </Grid>

          {/* Lesson Types Chart */}
          <Grid item xs={12} md={12}>
            <Card>
              <Box sx={{ p: 2 }}>
                <Typography variant='h6' sx={{ mb: 2 }}>
                  Lektionstyper
                </Typography>
                <Box sx={{ height: 300 }}>
                  <ResponsiveContainer width='100%' height='100%'>
                    <BarChart data={stats.lessonTypeData}>
                      <XAxis dataKey='name' />
                      <YAxis />
                      <RechartsTooltip />
                      <Legend />
                      <Bar dataKey='Idag' fill='#2196f3' />
                      <Bar dataKey='Igår' fill='#90caf9' />
                    </BarChart>
                  </ResponsiveContainer>
                </Box>
              </Box>
            </Card>
          </Grid>

          {/* Replace the single grid item with these two: */}

          {/* Today's top users - KEEPING THE EXISTING STRUCTURE */}
          <Grid item xs={12} md={6}>
            <Card>
              <Box sx={{ p: 2 }}>
                <Typography variant='h6' sx={{ mb: 2 }}>
                  Mest aktiva användare idag
                </Typography>
                {stats.today.topUsers.map((user, index) => (
                  <Box
                    key={index}
                    sx={{
                      mb: 2,
                      pb: 1,
                      borderBottom:
                        index < stats.today.topUsers.length - 1 ? '1px solid #eee' : 'none',
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Typography
                        variant='body2'
                        sx={{ maxWidth: '70%', overflow: 'hidden', textOverflow: 'ellipsis' }}
                      >
                        {user.identifier}
                      </Typography>
                      <Typography variant='body2' fontWeight='medium'>
                        {user.count} lektioner
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Card>
          </Grid>

          {/* Yesterday's top users - SAME STRUCTURE */}
          <Grid item xs={12} md={6}>
            <Card>
              <Box sx={{ p: 2 }}>
                <Typography variant='h6' sx={{ mb: 2 }}>
                  Mest aktiva användare igår
                </Typography>
                {stats.yesterday.topUsers.map((user, index) => (
                  <Box
                    key={index}
                    sx={{
                      mb: 2,
                      pb: 1,
                      borderBottom:
                        index < stats.yesterday.topUsers.length - 1 ? '1px solid #eee' : 'none',
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Typography
                        variant='body2'
                        sx={{ maxWidth: '70%', overflow: 'hidden', textOverflow: 'ellipsis' }}
                      >
                        {user.identifier}
                      </Typography>
                      <Typography variant='body2' fontWeight='medium'>
                        {user.count} lektioner
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Card>
          </Grid>

          <Grid item xs={12} md={6} lg={3} sx={{ display: 'none' }}>
            <Card>
              <Box sx={{ p: 2 }}>
                <Typography variant='subtitle2' color='text.secondary'>
                  Mest aktiva perioden
                </Typography>
                <Box sx={{ mt: 1, display: 'flex', alignItems: 'baseline' }}>
                  <Typography variant='h4' sx={{ mr: 1, textTransform: 'capitalize' }}>
                    {stats.timePeriodsToday.maxPeriod.name}
                  </Typography>
                  <Typography variant='body2' color='success.main'>
                    {stats.timePeriodsToday.maxPeriod.count} st
                  </Typography>
                </Box>

                {Object.entries(stats.timePeriodsToday.periods).map(([period, data]) => {
                  const yesterdayCount = stats.timePeriodsYesterday.periods[period].count;
                  const diff = data.count - yesterdayCount;
                  const diffColor =
                    diff > 0 ? 'success.main' : diff < 0 ? 'error.main' : 'text.secondary';

                  return (
                    <Box
                      key={period}
                      sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5, mt: 1 }}
                    >
                      <Typography variant='caption' sx={{ textTransform: 'capitalize' }}>
                        {period}:
                      </Typography>
                      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                        <Typography variant='caption'>
                          {data.count} / {yesterdayCount}
                        </Typography>
                        <Typography variant='caption' sx={{ color: diffColor, minWidth: '45px' }}>
                          {diff > 0 ? '+' : ''}
                          {diff}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Card>
          </Grid>

          {/* School and Municipality Statistics */}
          <Grid item xs={12} md={6}>
            <Card>
              <Box sx={{ p: 2 }}>
                <Typography variant='h6' sx={{ mb: 2 }}>
                  Topp 10 kommuner
                </Typography>
                <Box sx={{ height: 300 }}>
                  <ResponsiveContainer width='100%' height='100%'>
                    <BarChart data={stats.municipalityData}>
                      <XAxis
                        dataKey='name'
                        angle={-45}
                        textAnchor='end'
                        height={80}
                        tick={{ fontSize: 11 }}
                      />
                      <YAxis />
                      <RechartsTooltip
                        content={({ payload, label }) => {
                          if (!payload || !payload.length) return null;
                          const data = payload[0].payload;
                          return (
                            <Paper sx={{ p: 1 }}>
                              <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                {label}
                              </Typography>
                              <Typography variant='body2'>Lektioner: {data.lessons}</Typography>
                              <Typography variant='body2'>Användare: {data.users}</Typography>
                              <Typography variant='body2'>Skolor: {data.schools}</Typography>
                            </Paper>
                          );
                        }}
                      />
                      <Bar dataKey='lessons' fill='#2196f3' name='Antal lektioner' />
                    </BarChart>
                  </ResponsiveContainer>
                </Box>
              </Box>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card>
              <Box sx={{ p: 2 }}>
                <Typography variant='h6' sx={{ mb: 2 }}>
                  Topp 10 skolor
                </Typography>
                <Box sx={{ height: 300 }}>
                  <ResponsiveContainer width='100%' height='100%'>
                    <BarChart data={stats.schoolData}>
                      <XAxis
                        dataKey='name'
                        angle={-45}
                        textAnchor='end'
                        height={80}
                        tick={{ fontSize: 11 }}
                      />
                      <YAxis />
                      <RechartsTooltip
                        content={({ payload, label }) => {
                          if (!payload || !payload.length) return null;
                          const data = payload[0].payload;
                          return (
                            <Paper sx={{ p: 1 }}>
                              <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                {label}
                              </Typography>
                              <Typography variant='body2'>Lektioner: {data.lessons}</Typography>
                              <Typography variant='body2'>Användare: {data.users}</Typography>
                            </Paper>
                          );
                        }}
                      />
                      <Bar dataKey='lessons' fill='#4caf50' name='Antal lektioner' />
                    </BarChart>
                  </ResponsiveContainer>
                </Box>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>

      {/* Detailed Table */}
      {Object.entries(groupedData).map(([dateGroup, items]) => (
        <Box key={dateGroup} sx={{ mb: 4 }}>
          <Typography variant='h5' component='h2' gutterBottom sx={{ mb: 2 }}>
            {dateGroup}
          </Typography>

          <TableContainer component={Paper} sx={{ mb: 4 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Tid</TableCell>
                  <TableCell>Användare</TableCell>
                  <TableCell>Skola</TableCell>
                  <TableCell>Kommun</TableCell>
                  <TableCell>Tokens</TableCell>
                  <TableCell>Lektionstyp</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((item, index) => (
                  <Grow
                    key={item.id}
                    in={true}
                    timeout={300 * (index % 10)}
                    style={{ transformOrigin: '0 0 0' }}
                  >
                    <TableRow
                      hover
                      sx={{
                        position: 'relative',
                        '&::after': newRowIds.has(item.id)
                          ? {
                              content: '""',
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              backgroundColor: 'primary.light',
                              opacity: 0.2,
                              animation: 'pulseAndFade 5s ease-out forwards',
                            }
                          : {},
                        '@keyframes pulseAndFade': {
                          '0%': {
                            opacity: 0.4,
                            transform: 'translateX(0)',
                          },
                          '2%': {
                            transform: 'translateX(-10px)',
                          },
                          '4%': {
                            transform: 'translateX(10px)',
                          },
                          '6%': {
                            transform: 'translateX(-10px)',
                          },
                          '8%': {
                            transform: 'translateX(10px)',
                          },
                          '10%': {
                            transform: 'translateX(0)',
                            opacity: 0.4,
                          },
                          '100%': {
                            transform: 'translateX(0)',
                            opacity: 0,
                          },
                        },
                      }}
                    >
                      <TableCell>{format(item.timestamp, 'HH:mm', { locale: sv })}</TableCell>
                      <TableCell>
                        <UserCell userData={item.userData} userCounts={userCounts} />
                      </TableCell>
                      <TableCell>{item.userData.school || '-'}</TableCell>
                      <TableCell>{item.userData.municipality || '-'}</TableCell>
                      <TableCell>
                        {item.userData.guest ? '-' : item.userData.tokens || '0'}
                      </TableCell>
                      <TableCell>{item.lessonType}</TableCell>
                    </TableRow>
                  </Grow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ))}
    </Box>
  );
};

export default AdminDashboard;
