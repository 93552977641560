import React, { useState, useEffect, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  Button,
  TextField,
  Grid,
  CircularProgress,
  Divider,
  Tabs,
  Tab,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {
  doc,
  getDoc,
  updateDoc,
  addDoc,
  collection,
  query,
  where,
  getDocs,
  orderBy,
} from 'firebase/firestore';
import { db } from './firebase';
import { useSnackbar } from './SnackbarHandler';
import LanguageSelector from './LanguageSelector';
import InfoBox from './InfoBox';
import { callApi } from './api';
import SaveIcon from '@mui/icons-material/Save';
import { getStyles } from '../index';
import { useTheme } from '@mui/material/styles';
import { Grading as GradingIcon } from '@mui/icons-material';
import mammoth from 'mammoth';
import GenerateIcon from '@mui/icons-material/AutoAwesome';
import SegmentIcon from '@mui/icons-material/Segment';
import { useNavigate, Link } from 'react-router-dom';
import Logger from './Logger';
import AddIcon from '@mui/icons-material/Add';

import { marked } from 'marked';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PrintIcon from '@mui/icons-material/Print';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SchoolIcon from '@mui/icons-material/School';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import LanguageIcon from '@mui/icons-material/Language';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

import ProgressBar from './ProgressBar';

const CustomInputSwitch = ({ isManualEntry, setIsManualEntry, theme }) => {
  const handleClick = () => {
    setIsManualEntry(!isManualEntry);
  };

  return (
    <Box
      sx={{
        borderRadius: '38px',
        backgroundColor: theme.palette.mode === 'dark' ? '#3a454f' : '#f0f0f0',
        padding: '4px',
      }}
    >
      <Box
        onClick={handleClick}
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '240px',
          height: '36px',
          borderRadius: '18px',
          backgroundColor: theme.palette.mode === 'dark' ? '#3a454f' : '#f0f0f0',
          cursor: 'pointer',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            width: '50%',
            height: '100%',
            borderRadius: '18px',
            backgroundColor: '#8b5cf6',
            transition: 'transform 0.3s ease',
            transform: isManualEntry ? 'translateX(100%)' : 'translateX(0)',
          }}
        />
        <Typography
          variant='body2'
          sx={{
            flex: 1,
            textAlign: 'center',
            zIndex: 1,
            color: !isManualEntry ? '#fff' : theme.palette.mode === 'dark' ? '#fff' : '#000',
            transition: 'color 0.3s ease',
            fontWeight: '600',
          }}
        >
          Ladda upp fil
        </Typography>
        <Typography
          variant='body2'
          sx={{
            flex: 1,
            textAlign: 'center',
            zIndex: 1,
            color: isManualEntry ? '#fff' : theme.palette.mode === 'dark' ? '#fff' : '#000',
            transition: 'color 0.3s ease',
            fontWeight: '600',
          }}
        >
          Skriv in text
        </Typography>
      </Box>
    </Box>
  );
};

const FormativeComments = ({ user }) => {
  const [educationLevel, setEducationLevel] = useState('Gymnasium');
  const [selectedRubric, setSelectedRubric] = useState('');
  const [language, setLanguage] = useState('svenska');
  const [essayText, setEssayText] = useState('');
  const [file, setFile] = useState(null);
  const [rubrics, setRubrics] = useState([]);
  const [isManualEntry, setIsManualEntry] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { showSnackbar } = useSnackbar();
  const [essayName, setEssayName] = useState('');

  const [gradingResults, setGradingResults] = useState(null);
  const [editing, setEditing] = useState(false);
  const [editedResults, setEditedResults] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [reviewed, setReviewed] = useState(false);

  const navigate = useNavigate();
  const theme = useTheme();
  const styles = getStyles(theme);
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    const fetchRubrics = async () => {
      const rubricsCollection = collection(db, 'rubrics');
      const q = query(rubricsCollection, where('formativeComments', '==', true));
      const querySnapshot = await getDocs(q);
      const rubricList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setRubrics(rubricList);
      if (rubricList.length > 0) {
        setSelectedRubric(rubricList[0].id);
      }
    };

    fetchRubrics();
  }, []);

  const onDrop = useCallback(acceptedFiles => {
    setFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    },
    multiple: false,
  });

  const simulateProgress = (startProgress, endProgress, duration) => {
    const stepSize = 0.5;
    const steps = (endProgress - startProgress) / stepSize;
    const stepDuration = duration / steps;
    let currentProgress = startProgress;

    const intervalId = setInterval(() => {
      currentProgress += stepSize;
      if (currentProgress >= endProgress) {
        clearInterval(intervalId);
      } else {
        setProgress(currentProgress);
      }
    }, stepDuration);

    return () => clearInterval(intervalId);
  };

  const extractTextFromWordDocument = async file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async event => {
        try {
          const arrayBuffer = event.target.result;
          const result = await mammoth.extractRawText({ arrayBuffer });
          resolve(result.value);
        } catch (error) {
          reject(error);
        }
      };
      reader.onerror = error => reject(error);
      reader.readAsArrayBuffer(file);
    });
  };

  const handleSubmit = async event => {
    event.preventDefault();
    setIsLoading(true);
    setProgress(0);
    let content = '';
    let fileName = '';

    try {
      if (isManualEntry) {
        if (!essayText) {
          throw new Error('Vänligen ange text');
        }
        if (!essayName) {
          throw new Error('Vänligen ange namn på text');
        }
        content = essayText;
        fileName = essayName;
      } else {
        if (!file) {
          throw new Error('Vänligen ladda upp en fil');
        }
        content = await extractTextFromWordDocument(file);
        fileName = file.name;
      }

      const selectedRubricObject = rubrics.find(rubric => rubric.id === selectedRubric);

      if (!selectedRubricObject) {
        throw new Error('Vänligen välj en bedömningsmatris');
      }

      const prompt = `Ge formativa bedömningar till en elev baserat på följande text baserat på den angivna bedömningsmatrisen. Texten är på ${language === 'swedish' ? 'svenska' : language} och är skriven på ${educationLevel}-nivå. Ge en analys för varje kriterium, förslag på förbättringar och ett betyg. Formatera svaret som markdown med följande struktur:


## Kriterieanalys

### [Kriteriumets namn]
- **Analys:** [Analys av detta kriterium på 3-4 meningar]
- **Förbättringsförslag:** [Förslag på förbättring på 3-4 meningar]
[Upprepa för varje kriterium]


---


## Bedömningsresultat



### Övergripande analys
[Ange övergripande analys av texten här]

### Positiv feedback
[Ange positiv feedback om textens styrkor här]

### Förbättringsplan
[Ange en strukturerad plan för att förbättra texten här]


---


## Felrapport



### Grammatikfel
[Lista med grammatikfel och förslag på korrigeringar]

### Meningsbyggnadsfel
[Beskrivning av problem med meningsbyggnad]

### Sammanhangsproblem
[Identifierade problem med textens sammanhang]

### Tydlighetsproblem
[Områden där texten kan förbättras för ökad tydlighet]

### Logiska inkonsekvenser
[Identifierade logiska problem eller motsägelser i texten]

### Stavfel
[Lista med stavfel och förslag på korrigeringar]

Bedömningsmatris:
${JSON.stringify(selectedRubricObject)}

Text:
${content}`;

      const stopSimulation = simulateProgress(0, 90, 15000);

      let response = await callApi(
        [
          {
            role: 'system',
            content:
              'Du är en hjälpsam AI-assistent som bedömer texter och ger formativa omdömen till elever.',
          },
          { role: 'user', content: prompt },
        ],
        undefined,
        undefined,
        user.uid
      );

      stopSimulation();
      setProgress(95);

      Logger.log(response);

      // Create the essay data object
      const essayData = {
        userId: user.uid,
        content: content,
        results: response,
        fileName: fileName,
        educationLevel: educationLevel,
        language: language,
        rubricId: selectedRubric,
        rubricName: selectedRubricObject.name,
        createdAt: new Date(),
      };

      // Save the results to the database
      const docRef = await addDoc(collection(db, 'essays'), essayData);
      setProgress(100);
      showSnackbar('Texten har bedömts och sparats', 'success');

      // Set the grading results
      setGradingResults({
        ...essayData,
        id: docRef.id,
      });
      setEditedResults(response);
    } catch (error) {
      console.error('Error grading essay:', error);
      showSnackbar(error.message || 'Ett oväntat fel uppstod. Vänligen försök igen.', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = () => {
    setEditing(true);
  };

  const handleSave = async () => {
    try {
      const docRef = doc(db, 'essays', gradingResults.id);
      await updateDoc(docRef, { results: editedResults });
      setGradingResults({ ...gradingResults, results: editedResults });
      setEditing(false);
      showSnackbar('Ändringar sparade.', 'success');
    } catch (error) {
      console.error('Error updating essay data:', error);
    }
  };

  const handleCancel = () => {
    setEditedResults(gradingResults.results);
    setEditing(false);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleCopy = () => {
    navigator.clipboard
      .writeText(gradingResults.results)
      .then(() => showSnackbar('Kopierad till urklipp!', 'success'))
      .catch(err => console.error('Fel vid kopiering: ', err));
  };

  const handleReviewToggle = async () => {
    try {
      const docRef = doc(db, 'essays', gradingResults.id);
      const newReviewedState = !reviewed;
      await updateDoc(docRef, { reviewed: newReviewedState });
      setReviewed(newReviewedState);

      if (newReviewedState) {
        showSnackbar('Omdöme markerat som granskat.', 'success');
      } else {
        showSnackbar('Omdöme markerat som icke granskat.', 'info');
      }
    } catch (error) {
      console.error('Error updating review status:', error);
      showSnackbar('Ett fel uppstod vid uppdatering av granskningsstatus.', 'error');
    }
  };

  const formatDate = date => {
    if (date instanceof Date) {
      return date.toLocaleString();
    } else if (date && typeof date.toDate === 'function') {
      return date.toDate().toLocaleString();
    } else {
      return 'Invalid Date';
    }
  };

  return (
    <>
      <Box sx={styles.container}>
        <Box
          sx={{
            ...styles.innerContainer,
            width: { xs: '100%', md: '87%' },
            margin: '0 auto',
          }}
        >
          <Typography
            variant='h1'
            gutterBottom
            sx={{ fontWeight: 'bold', fontSize: '1.8em', mb: 3, mt: 5, textAlign: 'center' }}
          >
            Formativ bedömning
          </Typography>
          <InfoBox
            icon={GradingIcon}
            title='Powerpoint Instructions'
            instructions={[
              'Skapa eller välj en fördefinierad bedömningsmatris',
              'Ladda upp eller skriv in valfri text',
              'Granska den AI-genererade analysen och få tillgång till ditt eget arkiv',
            ]}
            theme={theme}
            styles={styles}
          />
          <Box sx={{ ...styles.freshBox, padding: '30px' }}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500', mb: 1 }}>
                    Bedömningsmatris:
                  </Typography>
                  <RadioGroup
                    value={selectedRubric}
                    onChange={e => setSelectedRubric(e.target.value)}
                    sx={{ mt: 1 }}
                  >
                    {rubrics.map(rubric => (
                      <FormControlLabel
                        key={rubric.id}
                        value={rubric.id}
                        control={<Radio />}
                        label={rubric.name}
                        sx={{
                          margin: 0,
                          '& .MuiTypography-root': { lineHeight: 1 },
                          '& .MuiRadio-root': { padding: '4px' },
                        }}
                      />
                    ))}
                  </RadioGroup>

                  <Button
                    variant='contained'
                    color='primary'
                    component={Link}
                    startIcon={<AddIcon />}
                    to='/app/matris/ny'
                    sx={{ mt: 2 }}
                  >
                    Skapa bedömningsmatris
                  </Button>
                </Grid>

                <Grid item xs={12} sm={8}>
                  <Box>
                    <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500', mb: 0 }}>
                      Språk:
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant='subtitle' sx={{ ...styles.grayText, fontSize: '13px' }}>
                      Ordförrådet kommer att bedömas baserat på det språk som väljs.
                    </Typography>
                  </Box>
                  <Box sx={{ mt: 1, mb: 2 }}>
                    <LanguageSelector language={language} setLanguage={setLanguage} />
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <Typography variant='formLabelSinUnderline' sx={{ fontWeight: '500', mb: 0 }}>
                      Ladda upp fil eller skriv text:
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant='subtitle' sx={{ ...styles.grayText, fontSize: '13px' }}>
                      Klistra in text eller ladda upp ditt dokument (*.doc, *.docx)
                    </Typography>
                  </Box>

                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, mt: 2 }}>
                    <CustomInputSwitch
                      isManualEntry={isManualEntry}
                      setIsManualEntry={setIsManualEntry}
                      theme={theme}
                    />
                  </Box>

                  {isManualEntry ? (
                    <>
                      <TextField
                        multiline
                        rows={10}
                        fullWidth
                        value={essayText}
                        onChange={e => setEssayText(e.target.value)}
                        placeholder='Skriv eller klistra in texten här'
                        sx={{ mb: 2 }}
                      />
                      <Box>
                        <Typography
                          variant='formLabelSinUnderline'
                          sx={{ fontWeight: '500', mb: 1 }}
                        >
                          Namn:
                        </Typography>
                      </Box>
                      <TextField
                        fullWidth
                        value={essayName}
                        onChange={e => setEssayName(e.target.value)}
                        placeholder='Namn på texten'
                        required
                        sx={{ mb: 2, mt: 1 }}
                      />
                    </>
                  ) : (
                    <Box
                      {...getRootProps()}
                      sx={{
                        border: '2px dashed #cccccc',
                        backgroundColor: theme.palette.mode === 'dark' ? '#1c262f' : '#fcfcfc',
                        borderRadius: '4px',
                        padding: '20px',
                        textAlign: 'center',
                        cursor: 'pointer',
                        mb: 2,
                      }}
                    >
                      <input {...getInputProps()} />
                      <CloudUploadIcon sx={{ fontSize: 48, color: 'primary.main' }} />
                      {isDragActive ? (
                        <Typography>Släpp filen här ...</Typography>
                      ) : (
                        <Typography>
                          Dra och släpp en .doc eller .docx fil här, eller klicka för att välja en
                          fil
                        </Typography>
                      )}
                      {file && <Typography>Vald fil: {file.name}</Typography>}
                    </Box>
                  )}

                  <Button
                    type='submit'
                    variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                    color='primary'
                    fullWidth
                    sx={{ mt: 3 }}
                    disabled={isLoading}
                    startIcon={
                      !isLoading ? (
                        <GenerateIcon style={{ color: '#fff' }} />
                      ) : (
                        <CircularProgress size={16} sx={{ color: '#fff' }} />
                      )
                    }
                  >
                    {isLoading ? 'Analyserar text...' : 'Analysera text'}
                  </Button>

                  {isLoading && (
                    <Grid item xs={12}>
                      <ProgressBar progress={progress} label='Analyserar text...' />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </form>
            {gradingResults && (
              <>
                <Typography
                  variant='h2'
                  sx={{ ...styles.header, borderBottom: '0px', marginTop: '20px', mb: 1 }}
                >
                  {gradingResults.fileName}
                </Typography>

                <Box sx={{ display: 'flex', gap: '16px', marginBottom: '16px' }}>
                  <Box
                    sx={{ display: 'flex', alignItems: 'center', gap: '4px', ...styles.grayText }}
                  >
                    <SchoolIcon sx={{ fontSize: '18px', mr: 0.3 }} />
                    <Typography variant='body2' sx={{ ...styles.grayText, fontWeight: '500' }}>
                      {gradingResults.educationLevel}
                    </Typography>
                  </Box>

                  <Box
                    sx={{ display: 'flex', alignItems: 'center', gap: '4px', ...styles.grayText }}
                  >
                    <HistoryEduIcon sx={{ fontSize: '18px', mr: 0.3 }} />
                    <Typography variant='body2' sx={{ ...styles.grayText, fontWeight: '500' }}>
                      {gradingResults.rubricName}
                    </Typography>
                  </Box>

                  <Box
                    sx={{ display: 'flex', alignItems: 'center', gap: '4px', ...styles.grayText }}
                  >
                    <LanguageIcon sx={{ fontSize: '18px', mr: 0.3 }} />
                    <Typography variant='body2' sx={{ ...styles.grayText, fontWeight: '500' }}>
                      {gradingResults.language}
                    </Typography>
                  </Box>

                  <Box
                    sx={{ display: 'flex', alignItems: 'center', gap: '4px', ...styles.grayText }}
                  >
                    <CalendarTodayIcon sx={{ fontSize: '18px', mr: 0.3 }} />
                    <Typography variant='body2' sx={{ ...styles.grayText, fontWeight: '500' }}>
                      {formatDate(gradingResults.createdAt)}
                    </Typography>
                  </Box>
                </Box>

                <Divider />

                <Box sx={{ marginTop: '20px' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      gap: '10px',
                      marginBottom: '10px',
                    }}
                  >
                    {!editing && (
                      <>
                        <Button
                          variant='outlined'
                          startIcon={<EditIcon />}
                          onClick={handleEdit}
                          sx={{ ...styles.softerBtn }}
                        >
                          Redigera
                        </Button>
                        <Button
                          variant='outlined'
                          startIcon={<ContentCopyIcon />}
                          onClick={handleCopy}
                          sx={{ ...styles.softerBtn }}
                        >
                          Kopiera
                        </Button>
                        {/*
                  <Button
                    variant='outlined'
                    color='primary'
                    onClick={handlePrint}
                    startIcon={<PrintIcon />}
                    sx={{ ...styles.softerBtn }}
                  >
                    Skriv ut
                  </Button>
                  */}
                        <Button
                          variant='outlined'
                          startIcon={
                            <CheckCircleIcon
                              sx={{
                                color: reviewed
                                  ? theme.palette.mode === 'dark'
                                    ? '#63e363'
                                    : 'green'
                                  : 'inherit',
                              }}
                            />
                          }
                          onClick={handleReviewToggle}
                          sx={{ ...styles.softerBtn }}
                        >
                          {reviewed ? 'Granskad' : 'Markera som granskad'}
                        </Button>
                      </>
                    )}
                  </Box>
                  {editing ? (
                    <>
                      <Box sx={{ mb: 3 }}>
                        <Button
                          onClick={handleSave}
                          variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                          sx={{ marginRight: '10px' }}
                          startIcon={<SaveIcon />}
                        >
                          Spara
                        </Button>
                        <Button onClick={handleCancel} variant='outlined'>
                          Avbryt
                        </Button>
                      </Box>
                      <Tabs
                        value={activeTab}
                        onChange={handleTabChange}
                        sx={{ marginBottom: '10px' }}
                      >
                        <Tab label='Redigera' />
                        <Tab label='Förhandsvisa' />
                      </Tabs>
                      {activeTab === 0 ? (
                        <TextField
                          multiline
                          fullWidth
                          rows={50}
                          value={editedResults}
                          onChange={e => setEditedResults(e.target.value)}
                          sx={{ marginTop: '10px', marginBottom: '10px' }}
                        />
                      ) : (
                        <Box
                          sx={{ border: '1px solid #ccc', padding: '10px', borderRadius: '4px' }}
                        >
                          <div dangerouslySetInnerHTML={{ __html: marked(editedResults) }} />
                        </Box>
                      )}
                      <Button
                        onClick={handleSave}
                        variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                        sx={{ marginRight: '10px' }}
                        startIcon={<SaveIcon />}
                      >
                        Spara
                      </Button>
                      <Button onClick={handleCancel} variant='outlined'>
                        Avbryt
                      </Button>
                    </>
                  ) : (
                    <Box
                      sx={{
                        '& p': { lineHeight: '27px' },
                        '& li': {
                          marginBottom: '12px',
                          lineHeight: '27px',
                          '& strong': { fontWeight: '600' },
                        },
                        '& h3': { marginTop: '40px' },
                        '& hr': {
                          height: '1px',
                          border: 'none',
                          backgroundColor: theme => theme.palette.divider,
                          marginTop: '50px',
                          marginBottom: '50px',
                        },
                      }}
                    >
                      <div dangerouslySetInnerHTML={{ __html: marked(gradingResults.results) }} />
                    </Box>
                  )}
                </Box>
              </>
            )}
          </Box>{' '}
        </Box>
      </Box>
    </>
  );
};

export default FormativeComments;
