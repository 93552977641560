import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton } from '@mui/material';

import LightbulbIcon from '@mui/icons-material/Lightbulb';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import SmokingRoomsIcon from '@mui/icons-material/SmokingRooms';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import PublicIcon from '@mui/icons-material/Public';
import EmailIcon from '@mui/icons-material/Email';
import FunctionsIcon from '@mui/icons-material/Functions';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import NatureIcon from '@mui/icons-material/Nature';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import BiotechIcon from '@mui/icons-material/Biotech';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SpaIcon from '@mui/icons-material/Spa';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import SavingsIcon from '@mui/icons-material/Savings';
import SchoolIcon from '@mui/icons-material/School';
import HistoryIcon from '@mui/icons-material/History';
import CalculateIcon from '@mui/icons-material/Calculate';
import RefreshIcon from '@mui/icons-material/Refresh';
import TranslateIcon from '@mui/icons-material/Translate';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ScienceIcon from '@mui/icons-material/Science';
import PsychologyIcon from '@mui/icons-material/Psychology';
import LanguageIcon from '@mui/icons-material/Language';
import CodeIcon from '@mui/icons-material/Code';
import RoleplayDialog from './RoleplayDialog';
import ExplainDialog from './ExplainDialog';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import MovieIcon from '@mui/icons-material/Movie';
import PaletteIcon from '@mui/icons-material/Palette';
import OpenInNewIcon from '@mui/icons-material/OpenInNew'; // Add this import
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CloudIcon from '@mui/icons-material/Cloud';
import CastForEducationIcon from '@mui/icons-material/CastForEducation';
import EngineeringIcon from '@mui/icons-material/Engineering';
import CampaignIcon from '@mui/icons-material/Campaign';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import BarChartIcon from '@mui/icons-material/BarChart';

import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ForestIcon from '@mui/icons-material/Forest';
import PeopleIcon from '@mui/icons-material/People';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import FactCheckIcon from '@mui/icons-material/FactCheck';

import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import CategoryIcon from '@mui/icons-material/Category';
import NotesIcon from '@mui/icons-material/Notes';
import BoltIcon from '@mui/icons-material/Bolt';

import StorefrontIcon from '@mui/icons-material/Storefront';

import AnnouncementIcon from '@mui/icons-material/Announcement';
import SummarizeDialog from './SummarizeDialog';
import SummarizeIcon from '@mui/icons-material/Summarize'; // Add this for the summarize icon
import CompareDialog from './CompareDialog';
import CompareIcon from '@mui/icons-material/Compare';

import AutorenewIcon from '@mui/icons-material/Autorenew';

const PromptBox = ({ icon, text, onClick, theme, language = false }) => {
  const isDialogPrompt = [
    'Förklara för mig',
    'Sammanfatta det viktigaste',
    'Jämför och förklara skillnader',
    'Starta ett rollspel',
  ].includes(text);

  const getRandomColorWithFixedSL = (saturation, lightness) => {
    const hue = Math.floor(Math.random() * 360); // Random hue between 0 and 359
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  };

  const randomColor = React.useMemo(() => getRandomColorWithFixedSL(53, 44), []);

  return (
    <Box
      sx={{
        position: 'relative',
        width: '170px',
        height: language ? '100px' : '130px',
        backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'white',
        boxShadow:
          theme.palette.mode === 'dark' ? 'none' : 'rgba(99, 99, 99, 0.11) 0px 2px 8px 0px',
        borderRadius: '15px',
        border: theme.palette.mode === 'dark' ? '1px solid #323d48' : '1px solid #e2e8f0',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        padding: '12px',

        cursor: 'pointer',
        '&:hover': {
          backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : '#fdfdfd',
        },
      }}
      onClick={onClick}
    >
      <IconButton
        sx={{
          alignSelf: 'flex-start',
          color: 'white',
          fontSize: '10px',
          padding: '0px',
          '& .MuiSvgIcon-root': {
            fontSize: '20px',
            color: randomColor,
          },
        }}
      >
        {icon}
      </IconButton>
      <Typography
        variant='body2'
        sx={{
          color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'gray',
          textAlign: 'left',
          marginTop: '11px',
          fontWeight: '500',
          overflowWrap: 'break-word',
        }}
      >
        {text}
      </Typography>

      {isDialogPrompt && (
        <OpenInNewIcon
          sx={{
            position: 'absolute',
            bottom: '8px',
            right: '8px',
            fontSize: '16px',
            color:
              theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.4)',
          }}
        />
      )}
    </Box>
  );
};
//Hej
const PromptBoxes = ({ handleMenuItemClick, theme, language = '', styles }) => {
  const [displayedPrompts, setDisplayedPrompts] = useState([]);
  const [explainDialogOpen, setExplainDialogOpen] = useState(false);
  const [displayedCategory, setDisplayedCategory] = useState(null);
  const [summarizeDialogOpen, setSummarizeDialogOpen] = useState(false);

  const [roleplayDialogOpen, setRoleplayDialogOpen] = useState(false);
  const [compareDialogOpen, setCompareDialogOpen] = useState(false);

  useEffect(() => {
    // Check URL parameters when component mounts
    const params = new URLSearchParams(window.location.search);
    const dialogParam = params.get('p');

    // Open appropriate dialog based on parameter
    switch (dialogParam) {
      case 'explain':
        setExplainDialogOpen(true);
        break;
      case 'summarize':
        setSummarizeDialogOpen(true);
        break;
      case 'compare':
        setCompareDialogOpen(true);
        break;
      case 'roleplay':
        setRoleplayDialogOpen(true);
        break;
      default:
        break;
    }

    if (dialogParam) {
      // Remove the parameter without causing a page reload
      const newUrl = window.location.pathname + window.location.hash;
      window.history.replaceState({}, '', newUrl);
    }
  }, []);

  const handleExplanationSubmit = prompt => {
    handleMenuItemClick(prompt);
  };

  const categories = [
    {
      category: 'Rollspel',
      icon: <QuestionAnswerIcon />,
      items: [
        { icon: <QuestionAnswerIcon />, text: 'Småprata med en kompis' },
        { icon: <RestaurantIcon />, text: 'Beställa mat på restaurang' },
        { icon: <LocalLibraryIcon />, text: 'Handla i en klädbutik' },
        { icon: <AccountBalanceIcon />, text: 'Boka ett hotellrum' },
        { icon: <BiotechIcon />, text: 'Besök hos en läkare' },
        { icon: <PublicIcon />, text: 'Fråga om vägen' },
        { icon: <StorefrontIcon />, text: 'Besök på en marknad' },
      ],
    },
    {
      category: 'Verb',
      icon: <CategoryIcon />,
      items: [
        { icon: <AccessTimeIcon />, text: 'Presens (nutid)' },
        { icon: <HistoryIcon />, text: 'Preteritum (dåtid)' },
        { icon: <HistoryIcon />, text: 'Perfekt (dåtid)' },
        { icon: <HistoryIcon />, text: 'Pluskvamperfekt (dåtid)' },
        { icon: <AccessTimeIcon />, text: 'Futurum (framtid)' },
        { icon: <AnnouncementIcon />, text: 'Imperativ (befallande form)' },
        { icon: <AutorenewIcon />, text: 'Gerundium (pågående form)' },
        { icon: <CategoryIcon />, text: 'Infinitiv (grundform)' },
        { icon: <HistoryIcon />, text: 'Konjunktiv (dåtid)' },
        { icon: <AccessTimeIcon />, text: 'Konjunktiv (presens)' },
        { icon: <AccessTimeIcon />, text: 'Konjunktiv (futurum)' },
      ],
    },
    {
      category: 'Läsförståelse',
      icon: <NotesIcon />,
      items: [
        { icon: <MenuBookIcon />, text: 'Berättelse (presens)' },
        { icon: <MenuBookIcon />, text: 'Berättelse (dåtid)' },
        { icon: <MenuBookIcon />, text: 'Berättelse (futurum)' },
        { icon: <MenuBookIcon />, text: 'Berättelse (blandade tempus)' },
      ],
    },
    {
      category: 'Fyll _ ordet',
      icon: <BoltIcon />,
      items: [
        { icon: <BoltIcon />, text: 'Enkla' },
        { icon: <BoltIcon />, text: 'Medelsvåra' },
        { icon: <BoltIcon />, text: 'Väldigt svåra' },
      ],
    },
  ];

  const handleCategoryClick = category => {
    setDisplayedCategory(category);
  };

  const handleBackToCategories = () => {
    setDisplayedCategory(null);
  };

  const getPromptForItem = text => {
    const languageMap = {
      spanish: 'spanska',
      french: 'franska',
      german: 'tyska',
      english: 'engelska',
      swedish: 'svenska',
    };

    language = languageMap[language] || 'spanska';

    switch (text) {
      case 'Småprata med en kompis':
        return `Simulera en lång konversation på ${language} där du är barndomsvän som pratar om intressen, familj och vardag och jag är din bästa vän som vill småprata lite. Ge mig en fråga i taget och vänta på svar.`;
      case 'Beställa mat på restaurang':
        return `Simulera en lång konversation på ${language} där du är en servitör och jag är en gäst som ska beställa mat. Ge mig en fråga i taget och vänta på svar.`;
      case 'Handla i en klädbutik':
        return `Simulera en lång konversation på ${language} där du är en klädförsäljare som hjälper till med att hitta rätt storlek och stil och jag är en gäst som ska prova kläder. Ge mig en fråga i taget och vänta på svar.`;
      case 'Boka ett hotellrum':
        return `Simulera en lång konversation på ${language} där du är en hotellreceptionist och jag är en gäst som ska sköta bokningen. Ge mig en fråga i taget och vänta på svar.`;
      case 'Besök hos en läkare':
        return `Simulera en lång konversation på ${language} där du är en läkare som ställer frågor om symtom och ger råd och jag är en patient som beskriver sina symtom och följer råd. Ge mig en fråga i taget och vänta på svar.`;
      case 'Fråga om vägen':
        return `Simulera en lång konversation på ${language} där du är en lokal invånare som ger vägbeskrivningar och jag är en turist som frågar om vägen till olika platser. Ge mig en fråga i taget och vänta på svar.`;
      case 'Besök på en marknad':
        return `Simulera en lång konversation på ${language} där du är en enträgen marknadshandlare som säljer olika produkter och jag är en kund som prutar och köper frukt, grönsaker och andra varor. Ge mig en fråga i taget och vänta på svar.`;
      case 'Presens (nutid)':
        return `Du ska ge mig meningar på svenska som jag ska översätta som involverar presens i ${language}. Ge mig en mening i taget.`;
      case 'Preteritum (dåtid)':
        return `Du ska ge mig meningar på svenska som jag ska översätta som involverar preteritum i ${language}. Ge mig en mening i taget.`;
      case 'Perfekt (dåtid)':
        return `Du ska ge mig meningar på svenska som jag ska översätta som involverar perfekt i ${language}. Ge mig en mening i taget.`;
      case 'Pluskvamperfekt (dåtid)':
        return `Du ska ge mig meningar på svenska som jag ska översätta som involverar pluskvamperfekt i ${language}. Ge mig en mening i taget.`;
      case 'Futurum (framtid)':
        return `Du ska ge mig meningar på svenska som jag ska översätta som involverar futurum i ${language}. Ge mig en mening i taget.`;
      case 'Imperativ (befallande form)':
        return `Du ska ge mig meningar på svenska som jag ska översätta som involverar imperativ i ${language}. Ge mig en mening i taget.`;
      case 'Gerundium (pågående form)':
        return `Du ska ge mig meningar på svenska som jag ska översätta som involverar gerundium i ${language}. Ge mig en mening i taget.`;
      case 'Infinitiv (grundform)':
        return `Du ska ge mig meningar på svenska som jag ska översätta som involverar infinitiv i ${language}. Ge mig en mening i taget.`;
      case 'Konjunktiv (dåtid)':
        return `Du ska ge mig meningar på svenska som jag ska översätta som involverar konjunktiv i dåtid i ${language}. Ge mig en mening i taget.`;
      case 'Konjunktiv (presens)':
        return `Du ska ge mig meningar på svenska som jag ska översätta som involverar konjunktiv i presens i ${language}. Ge mig en mening i taget.`;
      case 'Konjunktiv (futurum)':
        return `Du ska ge mig meningar på svenska som jag ska översätta som involverar konjunktiv i futurum i ${language}. Ge mig en mening i taget.`;
      case 'Berättelse (presens)':
        return `Skriv en kort berättelse på ${language} i presens. Efteråt ska användaren förklara grundpoängen i berättelsen och du ska utvärdera svaret.`;
      case 'Berättelse (dåtid)':
        return `Skriv en kort berättelse på ${language} i dåtid. Efteråt ska användaren förklara grundpoängen i berättelsen och du ska utvärdera svaret.`;
      case 'Berättelse (futurum)':
        return `Skriv en kort berättelse på ${language} i futurum. Efteråt ska användaren förklara grundpoängen i berättelsen och du ska utvärdera svaret.`;
      case 'Berättelse (blandade tempus)':
        return `Skriv en kort berättelse på ${language} med konjunktiv, infinitiv, pluskvamperfekt, gerundium och andra tempus. Efteråt ska användaren förklara grundpoängen i berättelsen och du ska utvärdera svaret.`;
      case 'Enkla':
        return `Du ska ge mig meningar på ${language} där ett ord är uteslutet som jag ska gissa vilket det är. Ge mig en mening i taget. Meningen ska vara enkel.`;
      case 'Medelsvåra':
        return `Du ska ge mig meningar på ${language} där ett ord är uteslutet som jag ska gissa vilket det är. Ge mig en mening i taget. Meningen ska vara svår.`;
      case 'Väldigt svåra':
        return `Du ska ge mig meningar på ${language} där ett ord är uteslutet som jag ska gissa vilket det är. Ge mig en mening i taget. Meningen ska vara väldigt svår.`;
      default:
        return `Prompt for ${text} in ${language}`;
    }
  };

  const randomizePrompts = () => {
    const shuffled = [...prompts].sort(() => 0.5 - Math.random());
    // Ensure all four special prompts are always included
    const explainPrompt = prompts.find(p => p.text === 'Förklara för mig');
    const summarizePrompt = prompts.find(p => p.text === 'Sammanfatta det viktigaste');
    const comparePrompt = prompts.find(p => p.text === 'Jämför och förklara skillnader');
    const roleplayPrompt = prompts.find(p => p.text === 'Starta ett rollspel');
    const otherPrompts = shuffled.filter(
      p =>
        p.text !== 'Förklara för mig' &&
        p.text !== 'Sammanfatta det viktigaste' &&
        p.text !== 'Jämför och förklara skillnader' &&
        p.text !== 'Starta ett rollspel'
    );
    setDisplayedPrompts([
      explainPrompt,
      summarizePrompt,
      comparePrompt,
      roleplayPrompt,
      ...otherPrompts.slice(0, 0), // Changed to 0 since we now have 4 fixed prompts
    ]);
  };

  useEffect(() => {
    randomizePrompts();
  }, []);

  const prompts = [
    {
      icon: <MenuBookIcon />,
      text: 'Förklara för mig',
      prompt: '',
      onClick: () => setExplainDialogOpen(true),
      urlParam: 'explain',
    },
    {
      icon: <SummarizeIcon />,
      text: 'Sammanfatta det viktigaste',
      prompt: '',
      onClick: () => setSummarizeDialogOpen(true),
      urlParam: 'summarize',
    },
    {
      icon: <CompareIcon />,
      text: 'Jämför och förklara skillnader',
      prompt: '',
      onClick: () => setCompareDialogOpen(true),
      urlParam: 'compare',
    },
    {
      icon: <PeopleIcon />,
      text: 'Starta ett rollspel',
      prompt: '',
      onClick: () => setRoleplayDialogOpen(true),
      urlParam: 'roleplay',
    },

    {
      icon: <MenuBookIcon />,
      text: 'Sammanfatta en akademisk artikel',
      prompt:
        'Hjälp mig att sammanfatta huvudpunkterna i en akademisk artikel inom mitt studieområde.',
    },
    {
      icon: <CalculateIcon />,
      text: 'Förklara statistisk analys',
      prompt:
        'Förklara grunderna i statistisk analys och dess tillämpningar inom forskningsmetodik.',
    },
    {
      icon: <SchoolIcon />,
      text: 'Skapa en studieplan',
      prompt: 'Hjälp mig att skapa en effektiv studieplan för att förbereda mig inför tentamen.',
    },
    {
      icon: <AccessTimeIcon />,
      text: 'Tips för tidshantering',
      prompt: 'Ge mig strategier för att hantera min tid effektivt som universitetsstudent.',
    },
    {
      icon: <HistoryIcon />,
      text: 'Analysera en historisk händelse',
      prompt:
        'Hjälp mig att analysera orsakerna och konsekvenserna av en viktig historisk händelse.',
    },
    {
      icon: <ScienceIcon />,
      text: 'Förklara den vetenskaplig metoden',
      prompt: 'Beskriv den vetenskapliga metoden och hur den tillämpas inom akademisk forskning.',
    },
    {
      icon: <PsychologyIcon />,
      text: 'Diskutera psykologisk teori',
      prompt: 'Förklara en central psykologisk teori och dess relevans i modern forskning.',
    },
    {
      icon: <LanguageIcon />,
      text: 'Tips för akademisk engelska',
      prompt:
        'Ge mig tips för att förbättra min akademiska engelska, särskilt för uppsatsskrivning.',
    },
    {
      icon: <AccountBalanceIcon />,
      text: 'Förklara en ekonomisk modell',
      prompt:
        'Beskriv en grundläggande ekonomisk modell och dess tillämpningar i verkliga situationer.',
    },
    {
      icon: <CodeIcon />,
      text: 'Hjälp med programmeringskoncept',
      prompt: 'Förklara ett avancerat programmeringskoncept och ge ett exempel på dess användning.',
    },
    {
      icon: <BiotechIcon />,
      text: 'Förklara ett genetiskt begrepp',
      prompt: 'Beskriv ett komplext genetiskt begrepp och dess betydelse inom modern biologi.',
    },
    {
      icon: <LightbulbIcon />,
      text: 'Brainstorma forskningsidéer',
      prompt: 'Hjälp mig att brainstorma potentiella forskningsämnen inom mitt studieområde.',
    },
    {
      icon: <PublicIcon />,
      text: 'Fråga mig om världens huvudstäder',
      prompt:
        'Vi börjar med en frågesport om världens huvudstäder för att förbättra mina geografikunskaper. Fråga mig först om huvudstaden i vilket land du vill, så ska jag försöka svara rätt.',
    },

    {
      icon: <MenuBookIcon />,
      text: 'Skriv en kort novell om vänskap',
      prompt:
        'Skriv en kort novell på 500 ord som utforskar temat vänskap mellan två oväntade karaktärer.',
    },
    {
      icon: <LightbulbIcon />,
      text: 'Förklara AI för nybörjare',
      prompt:
        'Förklara artificiell intelligens på ett enkelt sätt för någon utan teknisk bakgrund.',
    },
    {
      icon: <NatureIcon />,
      text: 'Skriv en dikt om naturen',
      prompt: 'Skriv en dikt som fångar naturens skönhet och kraft.',
    },
    {
      icon: <FitnessCenterIcon />,
      text: 'Utveckla en träningsrutin',
      prompt: 'Skapa en effektiv träningsrutin för nybörjare som vill komma i form.',
    },
    {
      icon: <AcUnitIcon />,
      text: 'Diskutera klimatförändringar',
      prompt: 'Diskutera orsakerna till klimatförändringar och möjliga lösningar.',
    },
    {
      icon: <BiotechIcon />,
      text: 'Förklara fotosyntes',
      prompt: 'Förklara processen fotosyntes på ett enkelt sätt.',
    },
    {
      icon: <AccessTimeIcon />,
      text: 'Beskriv en dag i framtiden',
      prompt: 'Föreställ dig hur en vanlig dag kommer att se ut om 20 år och beskriv den.',
    },
    {
      icon: <SpaIcon />,
      text: 'Skapa en plan för stresshantering',
      prompt: 'Utveckla en strategi för att hantera stress i vardagen.',
    },
    {
      icon: <RocketLaunchIcon />,
      text: 'Skriv en kort science fiction-berättelse',
      prompt: 'Skriv en kort berättelse inom science fiction-genren med en oväntad twist.',
    },
    {
      icon: <LocalLibraryIcon />,
      text: 'Skapa en lista över bokrekommendationer',
      prompt: 'Lista och beskriv fem böcker som alla borde läsa minst en gång.',
    },
    {
      icon: <SavingsIcon />,
      text: 'Skapa en plan för personlig ekonomi',
      prompt: 'Utveckla en plan för att hantera din personliga ekonomi och spara pengar.',
    },
    {
      icon: <MenuBookIcon />,
      text: 'Analysera en klassisk litterär text',
      prompt: 'Analysera temat och karaktärsutvecklingen i "Hamlet" av Shakespeare.',
    },
    {
      icon: <CalculateIcon />,
      text: 'Förklara trigonometri för nybörjare',
      prompt: 'Ge en enkel förklaring av grundläggande trigonometri och dess tillämpningar.',
    },
    {
      icon: <TranslateIcon />,
      text: 'Öva engelska idiom',
      prompt: 'Förklara fem engelska idiom och använd dem i exempelmeningar.',
    },
    {
      icon: <CalculateIcon />,
      text: 'Förklara sannolikhetsteori',
      prompt: 'Ge en grundläggande förklaring av sannolikhet och ge exempel på dess användning.',
    },
    {
      icon: <CalculateIcon />,
      text: 'Lös en andragradsekvation',
      prompt: 'Lös ekvationen x² - 5x + 6 = 0 och förklara lösningsmetoden.',
    },
    {
      icon: <TranslateIcon />,
      text: 'Lär dig japanska siffror',
      prompt: 'Lista de japanska siffrorna 1-10 och använd dem i exempelmeningar.',
    },
    {
      icon: <TranslateIcon />,
      text: 'Öva spanska konjunktioner',
      prompt: 'Lista fem vanliga spanska konjunktioner och använd dem i komplexa meningar.',
    },
    {
      icon: <TranslateIcon />,
      text: 'Lär dig koreanska hälsningsfraser',
      prompt: 'Lista tio vanliga koreanska hälsningsfraser och deras betydelser.',
    },
    {
      icon: <HistoryIcon />,
      text: 'Beskriv den franska revolutionen',
      prompt: 'Förklara orsakerna till den franska revolutionen och dess konsekvenser för Europa.',
    },
    {
      icon: <TranslateIcon />,
      text: 'Lär dig portugisiska fraser för resor',
      prompt:
        'Lista fem viktiga portugisiska fraser som är användbara för resenärer och använd dem i exempelmeningar.',
    },
    {
      icon: <CalculateIcon />,
      text: 'Lös exponentiella ekvationer',
      prompt: 'Lös ekvationen 2^(x) = 16 och förklara stegen.',
    },

    {
      icon: <BiotechIcon />,
      text: 'Förklara biologisk process',
      prompt:
        'Beskriv fotosyntesprocessen i detalj och förklara dess betydelse för livet på jorden.',
    },
    {
      icon: <ArchitectureIcon />,
      text: 'Analysera arkitektonisk stil',
      prompt:
        'Analysera den gotiska arkitektoniska stilen och dess kännetecken, med fokus på Notre-Dame-katedralen i Paris.',
    },
    {
      icon: <MovieIcon />,
      text: 'Granska film',
      prompt:
        "Ge en kritisk analys av filmen 'Blade Runner 2049', med fokus på teman och cinematografi.",
    },
    {
      icon: <PaletteIcon />,
      text: 'Jämför konstperioder',
      prompt:
        'Jämför och kontrastera renässansen och barockperioden inom konsten, med exempel på verk från varje period.',
    },
    {
      icon: <LanguageIcon />,
      text: 'Undersök språkliga skillnader',
      prompt:
        'Undersök de huvudsakliga skillnaderna mellan spanska och italienska, med fokus på grammatik och uttal.',
    },
    {
      icon: <PsychologyAltIcon />,
      text: 'Diskutera psykologisk teori',
      prompt:
        'Diskutera Freuds psykoanalytiska teori och dess tillämpningar inom modern psykoterapi.',
    },
    {
      icon: <AttachMoneyIcon />,
      text: 'Förklara finansiellt koncept',
      prompt:
        'Förklara begreppet inflation och dess påverkan på en nations ekonomi, med aktuella exempel.',
    },
    {
      icon: <BiotechIcon />,
      text: 'Beskriv genetisk mekanism',
      prompt:
        'Beskriv mekanismen för genetisk rekombination och dess roll i evolutionen och artbildning.',
    },
    {
      icon: <CloudIcon />,
      text: 'Förklara meteorologiskt fenomen',
      prompt:
        'Förklara El Niño-fenomenet, dess orsaker och globala konsekvenser för klimat och väder.',
    },
    {
      icon: <CastForEducationIcon />,
      text: 'Jämför pedagogiska metoder',
      prompt:
        'Jämför Montessori-metoden och traditionell klassrumsundervisning, och diskutera deras respektive effektivitet.',
    },
    {
      icon: <EngineeringIcon />,
      text: 'Beskriv ingenjörsprincip',
      prompt:
        'Beskriv principen om hållfasthet inom materialteknik och dess praktiska tillämpningar i byggnadskonstruktion.',
    },
    {
      icon: <CampaignIcon />,
      text: 'Analysera retorisk strategi',
      prompt:
        "Analysera den retoriska strategin i Martin Luther Kings tal 'I Have a Dream', med fokus på språkliga verktyg och övertygelsetekniker.",
    },
    {
      icon: <AutoStoriesIcon />,
      text: 'Jämför litterära verk',
      prompt:
        "Jämför och kontrastera George Orwells '1984' och Aldous Huxleys 'Brave New World' med avseende på deras dystopiska visioner.",
    },
    {
      icon: <BarChartIcon />,
      text: 'Tolka statistiska data',
      prompt:
        'Tolka de statistiska data som presenteras i denna graf över global uppvärmning och förklara deras betydelse för klimatpolitiken.',
    },
    {
      icon: <ScienceIcon />,
      text: 'Förklara kvantmekanik',
      prompt:
        "Ge en grundläggande förklaring av Schrödinger's katt-tankeexperimentet och dess betydelse för förståelsen av kvantmekanik.",
    },
    {
      icon: <AccountTreeIcon />,
      text: 'Skapa projektplan',
      prompt:
        'Hjälp mig skapa en detaljerad projektplan för att organisera en vetenskapsmässa på vår skola, inklusive tidslinjer och ansvarsområden.',
    },
    {
      icon: <ForestIcon />,
      text: 'Diskutera ekosystem',
      prompt:
        'Diskutera Amazonas regnskogsekosystem och dess betydelse för global biodiversitet och klimatreglering.',
    },
    {
      icon: <PeopleIcon />,
      text: 'Analysera gruppdynamik',
      prompt:
        'Analysera gruppdynamiken i ett typiskt projektteam på arbetsplatsen och ge förslag på hur man kan förbättra samarbetet och produktiviteten.',
    },
    {
      icon: <EmojiObjectsIcon />,
      text: 'Brainstorma innovativa lösningar',
      prompt:
        'Hjälp mig brainstorma innovativa lösningar på problemet med plastföroreningar i världshaven.',
    },
    {
      icon: <FactCheckIcon />,
      text: 'Granska källkritik',
      prompt:
        'Granska denna nyhetsartikel om en ny medicinsk behandling kritiskt och bedöm dess trovärdighet baserat på källorna och presentationen av data.',
    },
  ];

  return (
    <Box sx={{ position: 'relative', marginTop: '0px', textAlign: 'center' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '16px',
        }}
      >
        {displayedCategory !== null && (
          <IconButton
            onClick={handleBackToCategories}
            sx={{
              color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : '#c1cbd5',
              '& .MuiSvgIcon-root': {
                fontSize: '24px',
              },
            }}
          >
            <ArrowBackIcon />
          </IconButton>
        )}
        {language.length > 0 && (
          <Typography
            variant='subtitle'
            sx={{
              ...styles.softerText,
              fontWeight: '500',
              fontSize: '18px',
              textAlign: 'center',
              display: 'inline',
              marginLeft: displayedCategory !== null ? '8px' : '0',
            }}
          ></Typography>
        )}
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: '16px',
          flexWrap: 'wrap',
        }}
      >
        {language.length > 0
          ? displayedCategory === null
            ? categories.map((category, index) => (
                <PromptBox
                  key={index}
                  icon={category.icon}
                  text={category.category}
                  theme={theme}
                  language={true}
                  onClick={() => handleCategoryClick(category.category)}
                />
              ))
            : categories
                .find(cat => cat.category === displayedCategory)
                ?.items.map((item, index) => (
                  <PromptBox
                    key={index}
                    icon={item.icon}
                    text={item.text}
                    language={true}
                    theme={theme}
                    onClick={() => handleMenuItemClick(getPromptForItem(item.text))}
                  />
                ))
          : displayedPrompts.map((prompt, index) => (
              <PromptBox
                key={index}
                icon={prompt.icon}
                text={prompt.text}
                theme={theme}
                onClick={() => {
                  if (prompt.onClick) {
                    prompt.onClick();
                  } else {
                    handleMenuItemClick(prompt.prompt);
                  }
                }}
              />
            ))}
      </Box>
      {!language.length > 0 && (
        <IconButton
          onClick={randomizePrompts}
          sx={{
            color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : '#c1cbd5',
            marginBottom: '10px',
            display: 'none',
            '& .MuiSvgIcon-root': {
              fontSize: '14px',
            },
          }}
        >
          <RefreshIcon />
        </IconButton>
      )}

      <ExplainDialog
        open={explainDialogOpen}
        onClose={() => setExplainDialogOpen(false)}
        onSubmit={handleExplanationSubmit}
        theme={theme}
      />
      <CompareDialog
        open={compareDialogOpen}
        onClose={() => setCompareDialogOpen(false)}
        onSubmit={handleMenuItemClick}
        theme={theme}
      />
      <RoleplayDialog
        open={roleplayDialogOpen}
        onClose={() => setRoleplayDialogOpen(false)}
        onSubmit={handleMenuItemClick}
        theme={theme}
      />
      <SummarizeDialog
        open={summarizeDialogOpen}
        onClose={() => setSummarizeDialogOpen(false)}
        onSubmit={handleMenuItemClick}
        theme={theme}
      />
    </Box>
  );
};

export default PromptBoxes;
