import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Divider,
  Box,
  Button,
  Typography,
  Paper,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import FeedbackIcon from '@mui/icons-material/Feedback';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ForumIcon from '@mui/icons-material/Forum';
import { FaFileWord } from 'react-icons/fa';
import RateReviewIcon from '@mui/icons-material/RateReview';
import PrintIcon from '@mui/icons-material/Print';
import SaveIcon from '@mui/icons-material/Save';
import { Document, Packer, Paragraph, TextRun, HeadingLevel } from 'docx';
import { teacherGuides } from './teacherGuides';
import FeedbackDialog from './FeedbackDialog';
import FeedbackPaper from './FeedbackPaper';

const TeachersGuide = ({ guideKey, theme, user }) => {
  const [open, setOpen] = React.useState(false);
  const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);
  const content = teacherGuides[guideKey];

  const handlePrint = () => {
    const printContent = document.querySelector('.guide-content');
    const printWindow = window.open('', '_blank');
    printWindow.document.write(`
      <html>
        <head>
          <title>Lärarhandledning</title>
          <style>
            body { font-family: Arial, sans-serif; padding: 20px; }
            h1 { font-size: 24px; margin-bottom: 16px; }
            h2 { font-size: 20px; margin-top: 32px; margin-bottom: 12px; }
            p { font-size: 14px; line-height: 1.5; }
          </style>
        </head>
        <body>
          ${printContent.innerHTML}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  const handleSave = async () => {
    try {
      const contentDiv = document.querySelector('.guide-content');
      const children = [];

      // Get all direct child elements
      contentDiv.childNodes.forEach(node => {
        if (node.tagName === 'H1') {
          children.push(
            new Paragraph({
              heading: HeadingLevel.HEADING_1,
              children: [new TextRun({ text: node.textContent, bold: true, size: 32 })],
              spacing: { after: 300 },
            })
          );
        } else if (node.tagName === 'H2') {
          children.push(
            new Paragraph({
              heading: HeadingLevel.HEADING_2,
              children: [new TextRun({ text: node.textContent, bold: true, size: 28 })],
              spacing: { before: 500, after: 200 },
            })
          );
        } else if (node.tagName === 'P') {
          children.push(
            new Paragraph({
              children: [new TextRun({ text: node.textContent, size: 24 })],
              spacing: { after: 200 },
            })
          );
        } else if (node.tagName === 'UL') {
          Array.from(node.children).forEach(li => {
            children.push(
              new Paragraph({
                children: [
                  new TextRun({ text: '• ', size: 24 }),
                  new TextRun({ text: li.textContent, size: 24 }),
                ],
                indent: { left: 720 },
              })
            );
          });
        }
      });

      const doc = new Document({
        sections: [
          {
            properties: {},
            children: children,
          },
        ],
      });

      const blob = await Packer.toBlob(doc);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `Lärarhandledning_${guideKey}_studera-ai.docx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error saving document:', error);
    }
  };
  const openFeedbackDialog = () => {
    setFeedbackDialogOpen(true);
  };

  return (
    <>
      <Button
        variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
        startIcon={<MenuBookIcon />}
        onClick={() => setOpen(true)}
      >
        Lärarhandledning
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth='md' fullWidth>
        <Box sx={{ position: 'relative', p: 2 }}>
          <Box sx={{ display: 'flex', gap: 2, position: 'absolute', left: 35, top: 30 }}>
            <Button variant='contained' startIcon={<PrintIcon />} onClick={handlePrint}>
              Skriv ut
            </Button>
            <Button variant='contained' startIcon={<FaFileWord />} onClick={handleSave}>
              Spara som Word-fil
            </Button>
          </Box>
          <IconButton
            onClick={() => setOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent sx={{ mt: 5 }}>
            <style>
              {`
                .guide-content h1 { font-size: 27px; margin-bottom: 16px; }
                .guide-content h2 { font-size: 23px; margin-top: 44px; margin-bottom: 12px; }
                .guide-content p { font-size: 16px; line-height: 1.6; }
                .guide-content strong { color: #333; }
                .guide-content ul { padding-left: 20px; margin-top: 12px; margin-bottom: 12px; }
                .guide-content li { font-size: 16px; line-height: 1.6; margin-bottom: 8px; }
              `}
            </style>
            <div className='guide-content' dangerouslySetInnerHTML={{ __html: content }} />

            <Divider sx={{ mt: 4, mb: 4 }} />

            <FeedbackPaper onClick={openFeedbackDialog} theme={theme} />
          </DialogContent>
        </Box>
      </Dialog>
      <FeedbackDialog
        open={feedbackDialogOpen}
        onClose={() => setFeedbackDialogOpen(false)}
        user={user}
      />
    </>
  );
};

export default TeachersGuide;
