import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  Divider,
  CircularProgress,
  Grid,
  Tooltip,
  IconButton,
  Checkbox,
  Paper,
  Collapse,
  FormControl,
  Avatar,
  TextField,
} from '@mui/material';

import TheatersIcon from '@mui/icons-material/Theaters';
import GenerateIcon from '@mui/icons-material/AutoAwesome';
import ImageIcon from '@mui/icons-material/Image';
import PrintIcon from '@mui/icons-material/Print';
import { GiBookshelf } from 'react-icons/gi';
import { useSearchParams, useParams } from 'react-router-dom';
import { callApi } from './api';
import InfoBox from './InfoBox';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { handlePrintReadingComp } from './printFunctions';
import MenuBookIcon from '@mui/icons-material/MenuBook';

import { generateAndProcessImage } from './functions';
import { useSnackbar } from './SnackbarHandler';
import ProgressBar from './ProgressBar';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import EditIcon from '@mui/icons-material/Edit';

import { FourSquare } from 'react-loading-indicators';

import { getAnalytics, logEvent } from 'firebase/analytics';
import { ThemeSelectorFlow } from './ThemeSelectorFlow';
import { ThemeSelector } from './ThemeSelector';
import ImageGenerationModal from './ImageGenerationModal';
import GradeSelector, { gradeInstructions } from './GradeSelector';
import LanguageSelector from './LanguageSelector';
import { useTheme } from '@mui/material/styles';
import { getStyles } from '../index';
import { CustomFormControlLabel, trackLesson, handleImageGenerationError } from './functions';
import Logger from './Logger';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import LessonAdaptationPanel from './LessonAdaptationPanel';

import LessonDrawer from './LessonDrawer';
import SaveLessonDialog from './SaveLessonDialog';
import HoverableImage from './HoverableImage';
import HistoryIcon from '@mui/icons-material/History';

import ScienceIcon from '@mui/icons-material/Science';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import NewspaperIcon from '@mui/icons-material/Newspaper';

import PersonIcon from '@mui/icons-material/Person';
import NatureIcon from '@mui/icons-material/Nature';
import TheaterComedyIcon from '@mui/icons-material/TheaterComedy';
import RateReviewIcon from '@mui/icons-material/RateReview';
import SportsBaseballIcon from '@mui/icons-material/SportsBaseball';

import { db } from './firebase';
import { collection, query, where, limit, getDocs } from 'firebase/firestore';

const BasicReadingCompLesson = ({ user }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);

  const [language, setLanguage] = useState('svenska');
  const [lessonTheme, setLessonTheme] = useState('generateWithAi');
  const [customTheme, setCustomTheme] = useState('');
  const [titleText, setTitleText] = useState('');
  const [grade, setGrade] = useState('åk6');
  const [progress, setProgress] = useState(0);
  const [isGeneratingLesson, setIsGeneratingLesson] = useState(false);
  const [isGeneratingImages, setIsGeneratingImages] = useState(false);
  const [editorContent, setEditorContent] = useState('');
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageInstructions, setImageInstructions] = useState('');
  const [openAiInstructions, setOpenAiInstructions] = useState('');
  const editorRef = useRef(null);
  const { showSnackbar } = useSnackbar();
  const theme = useTheme();
  const styles = getStyles(theme);
  const [lessonData, setLessonData] = useState(null);
  const [isAdapting, setIsAdapting] = useState(false);

  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isEditingContent, setIsEditingContent] = useState(false);
  const [loadingImageIndex, setLoadingImageIndex] = useState(null);
  const [editingSections, setEditingSections] = useState({});

  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const [selectedExercises, setSelectedExercises] = useState({
    'on-the-lines': true,
    'between-the-lines': true,
    'beyond-the-lines': true,
    'true-false': false,
    'multiple-choice': false,
    'open-ended': false,
  });

  const [showForm, setShowForm] = useState(true);

  const [predefinedTheme, setPredefinedTheme] = useState(null);
  const lessonTitleRef = useRef(null); // Add this ref
  const transcriptRef = useRef('');
  const uploadedFileRef = useRef('');

  const wikipediaContentRef = useRef('');
  const randomizedContentRef = useRef('');
  const coursePlanContentRef = useRef('');

  const topicSectionRef = useRef(null);
  const factsSectionRef = useRef(null);

  const getAdditionalThemes = lessonType => {
    switch (lessonType) {
      case 'BasicReadingCompLessonVariantInstructingText.js':
        return [
          { type: 'subheader', label: 'Teman som lämpar sig extra bra för denna lektionstyp:' },
          {
            value: 'science',
            label: 'Så fungerar DNA',
            content: 'Instruerande text om hur DNA fungerar.',
            Icon: ScienceIcon,
          },
        ];
      case 'BasicReadingCompLessonVariantFactText.js':
        return [
          { type: 'subheader', label: 'Teman som lämpar sig extra bra för denna lektionstyp:' },
          {
            value: 'science',
            label: 'Vattnets kretslopp',
            content: 'Faktatext om vattnets kretslopp',
            Icon: ScienceIcon,
          },
        ];
      case 'BasicReadingCompLessonVariantExplainingText.js':
        return [
          { type: 'subheader', label: 'Teman som lämpar sig extra bra för denna lektionstyp:' },
          {
            value: 'science',
            label: 'Förklara fotosyntes',
            content: 'Förklarande text om fotosyntes',
            Icon: ScienceIcon,
          },
        ];

      case 'BasicReadingCompLessonVariantChronicle.js':
        return [
          { type: 'subheader', label: 'Teman som lämpar sig extra bra för denna lektionstyp:' },
          {
            value: 'vetenskap',
            label: 'Vetenskap',
            content: 'Krönika om vetenskap',
            Icon: ScienceIcon,
          },
          {
            value: 'film',
            label: 'Film',
            content: 'Krönika om film ',
            Icon: TheatersIcon,
          },
          {
            value: 'noje',
            label: 'Nöje',
            content: 'Krönika om nöje och underhållning',
            Icon: TheaterComedyIcon,
          },
          {
            value: 'sport',
            label: 'Sport',
            content: 'Krönika om sport och idrott',
            Icon: SportsBaseballIcon,
          },
        ];
      case 'BasicReadingCompLessonVariantReview.js':
        return [
          { type: 'subheader', label: 'Teman som lämpar sig extra bra för denna lektionstyp:' },
          {
            value: 'filmrecension',
            label: 'Filmrecension',
            content: 'Recension om en film.',
            Icon: TheatersIcon,
          },
          {
            value: 'bokrecension',
            label: 'Bokrecension',
            content: 'Recension om en bok.',
            Icon: MenuBookIcon,
          },
          {
            value: 'konsertrecension',
            label: 'Konsertrecension',
            content: 'Recension om en konsert eller musikuppträdande.',
            Icon: TheaterComedyIcon,
          },
          {
            value: 'produktrecension',
            label: 'Produktrecension',
            content: 'Recension om en produkt eller vara.',
            Icon: RateReviewIcon,
          },
        ];

      default:
        return [
          { type: 'subheader', label: 'Teman som lämpar sig extra bra för denna lektionstyp:' },
          {
            value: 'selma',
            label: 'Litteratur',
            content: 'Gör en lektion inom läsförståelse om litteratur',
            Icon: MenuBookIcon,
          },
          {
            value: 'nature',
            label: 'Naturvetenskap',
            content: 'Gör en lektion inom läsförståelse om naturvetenskap',
            Icon: ScienceIcon,
          },
          {
            value: 'history',
            label: 'Historia',
            content: 'Gör en lektion inom läsförståelse om historiska händelser',
            Icon: HistoryEduIcon,
          },
          {
            value: 'news',
            label: 'Nyheter & Samhälle',
            content: 'Gör en lektion inom läsförståelse baserad på nyhetsartiklar',
            Icon: NewspaperIcon,
          },
          {
            value: 'culture',
            label: 'Kultur & Traditioner',
            content: 'Gör en lektion inom läsförståelse om kulturella fenomen',
            Icon: TheaterComedyIcon,
          },
          {
            value: 'biography',
            label: 'Biografier',
            content: 'Gör en lektion inom läsförståelse baserad på biografier',
            Icon: PersonIcon,
          },
          {
            value: 'environment',
            label: 'Miljö & Hållbarhet',
            content: 'Gör en lektion inom läsförståelse om miljöfrågor',
            Icon: NatureIcon,
          },
        ];
    }
  };

  const LESSON_VARIANTS = {
    default: {
      title: 'Grundläggande läsförståelse',
      lessonType: 'BasicReadingCompLesson.js',
      icon: MenuBookIcon,
    },
    factText: {
      title: 'Faktatext',
      lessonType: 'BasicReadingCompLessonVariantFactText.js',
      icon: ScienceIcon,
    },
    instructingText: {
      title: 'Instruerande text',
      lessonType: 'BasicReadingCompLessonVariantInstructingText.js',
      icon: ScienceIcon,
    },
    explainingText: {
      title: 'Förklarande text',
      lessonType: 'BasicReadingCompLessonVariantExplainingText.js',
      icon: ScienceIcon,
    },

    chronicle: {
      title: 'Krönika',
      lessonType: 'BasicReadingCompLessonVariantChronicle.js',
      icon: NewspaperIcon,
    },

    review: {
      title: 'Recension',
      lessonType: 'BasicReadingCompLessonVariantReview.js',
      icon: RateReviewIcon,
    },
  };

  const [searchParams] = useSearchParams();

  const { lessonId } = useParams();
  const variant = searchParams.get('type') || 'default';
  const lessonConfig = LESSON_VARIANTS[variant] || LESSON_VARIANTS.default;
  const additionalThemes = getAdditionalThemes(lessonConfig.lessonType);

  useEffect(() => {
    Logger.log('lessonTheme: ', lessonTheme);
  }, [lessonTheme]);

  useEffect(() => {
    Logger.log('predefinedTheme: ', predefinedTheme);
  }, [predefinedTheme]);

  useEffect(() => {
    Logger.log('customTheme: ', customTheme);
  }, [customTheme]);

  useEffect(() => {
    Logger.log('youtubeTranscript: ', transcriptRef.current);
  }, [transcriptRef.current]);

  useEffect(() => {
    Logger.log('wikipediaContentRef: ', wikipediaContentRef.current);
  }, [wikipediaContentRef.current]);
  useEffect(() => {
    Logger.log('uploadedFileRef: ', uploadedFileRef.current);
  }, [uploadedFileRef.current]);

  useEffect(() => {
    Logger.log('randomizedContentRef: ', randomizedContentRef.current);
  }, [randomizedContentRef.current]);

  useEffect(() => {
    Logger.log('coursePlanContentRef: ', coursePlanContentRef.current);
  }, [coursePlanContentRef.current]);

  useEffect(() => {
    const loadLessonFromId = async () => {
      if (lessonId) {
        try {
          const q = query(
            collection(db, 'lesson_usage'),
            where('__name__', '>=', lessonId),
            where('__name__', '<=', lessonId + '\uf8ff'),
            where('lessonType', '==', lessonConfig.lessonType),
            limit(1)
          );

          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            const docData = querySnapshot.docs[0].data();
            setLessonData(docData.data);
            setTitleText(docData.data.title || '');

            // Scroll to lesson content
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              });
            }, 100);

            showSnackbar('Lektionen har laddats in.', 'success');
          } else {
            showSnackbar('Kunde inte hitta lektionen', 'error');
          }
        } catch (error) {
          console.error('Error loading lesson:', error);
          showSnackbar('Ett fel uppstod när lektionen skulle laddas', 'error');
        }
      }
    };

    loadLessonFromId();
  }, [lessonId, lessonConfig.lessonType]);

  const handlePrintClick = async () => {
    if (lessonData && lessonData.images && lessonData.images.every(image => image.imageUrl)) {
      Logger.log('Saving lesson since all images have been generated');
      Logger.log(JSON.stringify(lessonData, null, 2));
      await trackLesson(user.uid, lessonConfig.lessonType, lessonData, null, true);
    }

    try {
      const analytics = getAnalytics();
      logEvent(analytics, 'button_click', {
        button_name: 'Print',
        page: 'BasicReadingCompLesson',
      });
    } catch (error) {
      Logger.log('Nåt fel skedde med logEvent analytics i BasicReadingCompLesson.');
    }

    handlePrintReadingComp(lessonData, theme);
  };

  const handleSaveLessonClick = () => {
    setSaveDialogOpen(true);
  };

  const handleSaveWithComment = async (comment, name, coverImage, tags) => {
    Logger.log(JSON.stringify(lessonData, null, 2));
    await trackLesson(
      user.uid,
      lessonConfig.lessonType,
      lessonData,
      null,
      true,
      comment,
      name,
      coverImage,
      tags
    );
    setDrawerOpen(true);
    showSnackbar('Lektionen sparad i din lektionsbank!', 'success');
  };

  const renderEditableSection = (section, titleKey, contentKey, index, exercise) => {
    const isEditing = editingSections[`${section}-${index}`];
    const isMultipleChoice = section === 'multiple-choice';

    return (
      <Box
        sx={{
          position: 'relative',
          marginTop: 7,
          '&:hover': {
            '& .section-controls': {
              opacity: 1,
              visibility: 'visible',
            },
          },
        }}
      >
        <Box
          className='section-controls'
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            opacity: 0,
            visibility: 'hidden',
            transition: 'all 0.2s ease',
            display: 'flex',
            gap: 1,
            zIndex: 1,
          }}
        >
          <Tooltip title={isEditing ? 'Spara' : 'Redigera'} placement='top'>
            <IconButton
              size='small'
              onClick={() =>
                isEditing ? handleSectionSave(section, index) : handleSectionEdit(section, index)
              }
            >
              {isEditing ? <SaveAsIcon fontSize='small' /> : <EditIcon fontSize='small' />}
            </IconButton>
          </Tooltip>
          <Tooltip title='Ta bort sektion' placement='top'>
            <IconButton
              size='small'
              onClick={() => {
                const updatedExercises = lessonData.exercises.filter((_, i) => i !== index);
                setLessonData(prev => ({
                  ...prev,
                  exercises: updatedExercises,
                }));
                showSnackbar('Sektionen har tagits bort', 'success');
              }}
            >
              <DeleteOutlineIcon fontSize='small' />
            </IconButton>
          </Tooltip>
        </Box>

        {isEditing ? (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              value={exercise[titleKey]}
              onChange={e => handleExerciseUpdate(index, titleKey, e.target.value)}
              sx={{ ...styles.editingTextField, width: '80%' }}
              InputProps={{
                sx: {
                  fontSize: '1.5em',
                  fontWeight: 'bold',
                },
              }}
            />
            <Box
              sx={{
                backgroundColor: theme => (theme.palette.mode === 'dark' ? '#15202b' : '#e0e0e029'),
                borderRadius: '10px',
                padding: '20px',
              }}
            >
              {isMultipleChoice
                ? // Multiple choice editing interface
                  exercise[contentKey].map((item, i) => (
                    <Box key={i} sx={{ mb: 3 }}>
                      <TextField
                        fullWidth
                        label='Fråga'
                        value={item.question}
                        onChange={e => {
                          const newContent = [...exercise[contentKey]];
                          newContent[i] = { ...newContent[i], question: e.target.value };
                          handleExerciseUpdate(index, contentKey, newContent);
                        }}
                        sx={{ ...styles.editingTextField, mb: 2 }}
                      />
                      {item.options.map((option, optionIndex) => (
                        <TextField
                          key={optionIndex}
                          fullWidth
                          label={`Alternativ ${optionIndex + 1}`}
                          value={option}
                          onChange={e => {
                            const newContent = [...exercise[contentKey]];
                            newContent[i].options[optionIndex] = e.target.value;
                            handleExerciseUpdate(index, contentKey, newContent);
                          }}
                          sx={{ ...styles.editingTextField, mb: 1 }}
                        />
                      ))}
                    </Box>
                  ))
                : // Regular content editing interface
                  exercise[contentKey].map((item, i) => (
                    <TextField
                      key={i}
                      fullWidth
                      multiline
                      value={item}
                      onChange={e => {
                        const newItems = [...exercise[contentKey]];
                        newItems[i] = e.target.value;
                        handleExerciseUpdate(index, contentKey, newItems);
                      }}
                      sx={{
                        mb: 2,
                        '& .MuiInputBase-root': {
                          fontSize: '1rem',
                          lineHeight: '1.5',
                        },
                        ...styles.editingTextField,
                      }}
                    />
                  ))}
              <Button
                variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                color='primary'
                startIcon={<SaveAsIcon />}
                onClick={() => handleSectionSave(section, index)}
                sx={{ mt: 2, textTransform: 'none' }}
              >
                Spara ändringar
              </Button>
            </Box>
          </Box>
        ) : (
          <>
            <Typography variant='h6' sx={{ fontWeight: 'bold', mb: 2, fontSize: '1.5em' }}>
              {exercise[titleKey] || ''}
            </Typography>
            <Box
              sx={{
                backgroundColor: theme => (theme.palette.mode === 'dark' ? '#15202b' : '#e0e0e029'),
                borderRadius: '10px',
                padding: '20px',
                '& li': {
                  marginBottom: '12px',
                  lineHeight: '25px',
                },
              }}
            >
              <ul>
                {isMultipleChoice
                  ? // Multiple choice display
                    exercise[contentKey].map((item, i) => (
                      <li key={i}>
                        <Typography variant='body1' sx={{ mb: 1, mt: 4, fontWeight: 'medium' }}>
                          {item.question}
                        </Typography>
                        <Box sx={{ pl: 3 }}>
                          {item.options.map((option, optionIndex) => (
                            <Typography key={optionIndex} variant='body1' sx={{ mb: 0.5 }}>
                              {String.fromCharCode(65 + optionIndex)}) {option}
                            </Typography>
                          ))}
                        </Box>
                      </li>
                    ))
                  : // Regular content display
                    exercise[contentKey].map((item, i) => (
                      <li key={i}>
                        <Typography variant='body1'>{item}</Typography>
                      </li>
                    ))}
              </ul>
            </Box>
          </>
        )}
      </Box>
    );
  };

  const handleSectionEdit = (section, index) => {
    setEditingSections(prev => ({
      ...prev,
      [`${section}-${index}`]: true,
    }));
  };

  const handleSectionSave = (section, index) => {
    setEditingSections(prev => ({
      ...prev,
      [`${section}-${index}`]: false,
    }));
    showSnackbar('Sektionen har uppdaterats', 'success');
  };

  const handleExerciseUpdate = (index, key, value) => {
    setLessonData(prev => {
      const newExercises = [...prev.exercises];
      newExercises[index] = {
        ...newExercises[index],
        [key]: value,
      };
      return {
        ...prev,
        exercises: newExercises,
      };
    });
  };

  const handleKeyDown = (e, section, index) => {
    if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
      handleSectionSave(section, index);
    }
  };

  const simulateProgress = (startProgress, endProgress, duration) => {
    const stepSize = 0.5;
    const steps = (endProgress - startProgress) / stepSize;
    const stepDuration = duration / steps;
    let currentProgress = startProgress;

    const intervalId = setInterval(() => {
      currentProgress += stepSize;
      if (currentProgress >= endProgress) {
        clearInterval(intervalId);
      } else {
        setProgress(currentProgress);
      }
    }, stepDuration);

    return () => clearInterval(intervalId);
  };

  const handleGenerateLesson = async () => {
    const hasSelectedExercises = Object.values(selectedExercises).some(value => value === true);

    if (!hasSelectedExercises) {
      showSnackbar('Du måste välja minst en övningstyp.', 'info');
      return;
    }
    setLessonData(null);
    setProgress(0);
    let stopProgressSimulation = null;
    setIsGeneratingLesson(true);
    const gradeInstructionsText = gradeInstructions[grade];

    try {
      let themeToUse;

      if (lessonTheme === 'youtube') {
        themeToUse = transcriptRef.current;
      } else if (lessonTheme === 'customTheme') {
        themeToUse = customTheme;
      } else if (lessonTheme === 'predefined') {
        themeToUse = predefinedTheme;
      } else if (lessonTheme === 'fileUpload') {
        themeToUse = uploadedFileRef.current;
      } else if (lessonTheme === 'wikipedia') {
        themeToUse = wikipediaContentRef.current;
      } else if (lessonTheme === 'randomizer') {
        themeToUse = randomizedContentRef.current;
      } else {
        //
        themeToUse = coursePlanContentRef.current;
      }

      Logger.log('themeToUse: ', themeToUse);
      stopProgressSimulation = simulateProgress(0, 90, 15000);

      let response = await callApi(
        [
          {
            role: 'system',
            content: `You are an expert teacher creating engaging reading comprehension lessons in ${language}. Follow the exact JSON structure provided in the prompt.`,
          },
          {
            role: 'user',
            content: `Generera en text skriven på ${language} uppdelad i tydliga stycken på nivå för ${grade} med följande frågetyper:
${selectedExercises['true-false'] ? '- 5 sant/falskt påståenden\n' : ''}
${selectedExercises['multiple-choice'] ? '- 5 flervalsfrågor med 3 alternativ var\n' : ''}
${selectedExercises['open-ended'] ? '- 3 öppna frågor som testar djupare förståelse\n' : ''}
Texten ska vara engagerande och åldersanpassad.

Theme: "${themeToUse}".
${openAiInstructions}.
${gradeInstructionsText}

Svara ALLTID i följande JSON-format:
{
  "title": "Lektionens titel",
  "theme": "Lektionens tema",
  "content": "Huvudtexten här...",
  "exercises": [
    ${
      selectedExercises['on-the-lines']
        ? `{
        "type": "on-the-lines",
        "title": "På raderna (svaret finns i texten)",
        "content": ["Fråga som kan besvaras direkt från texten 1", "Fråga som kan besvaras direkt från texten 2", "Fråga som kan besvaras direkt från texten 3"]
      },`
        : ''
    }
    ${
      selectedExercises['between-the-lines']
        ? `{
        "type": "between-the-lines",
        "title": "Mellan raderna (dra slutsatser från texten)",
        "content": ["Fråga som kräver att man drar slutsatser 1", "Fråga som kräver att man drar slutsatser 2", "Fråga som kräver att man drar slutsatser 3"]
      },`
        : ''
    }
    ${
      selectedExercises['beyond-the-lines']
        ? `{
        "type": "beyond-the-lines",
        "title": "Bortom raderna (egna tankar och analys)",
        "content": ["Analytisk fråga som kräver eget tänkande 1", "Analytisk fråga som kräver eget tänkande 2", "Analytisk fråga som kräver eget tänkande 3"]
      },`
        : ''
    }
    ${
      selectedExercises['true-false']
        ? `{
        "type": "true-false",
        "title": "Sant eller falskt?",
        "content": ["Påstående 1", "Påstående 2", "Påstående 3", "Påstående 4", "Påstående 5"]
      },`
        : ''
    }
${
  selectedExercises['multiple-choice']
    ? `{
    "type": "multiple-choice",
    "title": "Flervalsfrågor",
    "content": [
      {
        "question": "Fråga 1",
        "options": ["Alt 1", "Alt 2", "Alt 3"]
      },
      {
        "question": "Fråga 2",
        "options": ["Alt 1", "Alt 2", "Alt 3"]
      },
      {
        "question": "Fråga 3",
        "options": ["Alt 1", "Alt 2", "Alt 3"]
      },
      {
        "question": "Fråga 4",
        "options": ["Alt 1", "Alt 2", "Alt 3"]
      },
      {
        "question": "Fråga 5",
        "options": ["Alt 1", "Alt 2", "Alt 3"]
      }
    ]
  },`
    : ''
}
    ${
      selectedExercises['open-ended']
        ? `{
        "type": "open-ended",
        "title": "Diskussionsfrågor",
        "content": ["Öppen fråga 1", "Öppen fråga 2", "Öppen fråga 3"]
      }`
        : ''
    }
  ]
}`,
          },
        ],
        10000,
        true,
        user.uid
      );

      if (stopProgressSimulation) {
        stopProgressSimulation();
      }

      setProgress(95);
      let messageContent = response.data.choices[0].message.content.trim();

      if (messageContent.startsWith('```json')) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith('```')) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const data = JSON.parse(messageContent);

      setLessonData({
        ...data,
        images: [{ imageUrl: null }, { imageUrl: null }],
      });

      setProgress(100);
      Logger.log(JSON.stringify(data, null, 2));
      setTitleText(data.title);
      await new Promise(resolve => setTimeout(resolve, 800));
      setShowForm(false);
      await trackLesson(user.uid, lessonConfig.lessonType, null, {
        language: language,
        lessonTheme: lessonTheme,
      });
    } catch (error) {
      console.error('Error generating lesson:', error);
      showSnackbar('Ett fel uppstod när lektionen skulle genereras. Försök igen!', 'error');
    } finally {
      setIsGeneratingLesson(false);
    }
  };

  const handleGenerateImages = async () => {
    if (!lessonData) return;
    setIsGeneratingImages(true);
    handleCloseModal();

    try {
      const imagePrompt = `Create an image about a story with the theme: "${titleText}". ${imageInstructions}`;
      const imagePromises = lessonData.images.map(() =>
        generateAndProcessImage(imagePrompt, user.uid)
      );
      const imageUrls = await Promise.all(imagePromises);

      setLessonData(prevData => ({
        ...prevData,
        images: imageUrls.map(imageUrl => ({ imageUrl })),
      }));

      showSnackbar('Bilderna har genererats!', 'success');
    } catch (error) {
      handleImageGenerationError(error, true, showSnackbar);
    } finally {
      setIsGeneratingImages(false);
      setLoadingImageIndex(null);
    }
  };

  const handleGenerateSpecificImage = async index => {
    setIsGeneratingImages(true);
    setLoadingImageIndex(index);
    handleCloseModal();

    try {
      const imagePrompt = `Create an image about a story with the theme: "${titleText}". ${imageInstructions}`;
      const imageUrl = await generateAndProcessImage(imagePrompt, user.uid);

      if (imageUrl) {
        setLessonData(prevData => {
          const newImages = [...prevData.images];
          newImages[index] = { imageUrl };
          return {
            ...prevData,
            images: newImages,
          };
        });
        showSnackbar('Bilden har genererats!', 'success');
      }
    } catch (error) {
      handleImageGenerationError(error, false, showSnackbar);
    } finally {
      setIsGeneratingImages(false);
      setLoadingImageIndex(null);
    }
  };

  const handleImageButtonClick = (index = null) => {
    setSelectedImageIndex(index);
    setShowImageModal(true);
  };

  const handleCloseModal = () => {
    setShowImageModal(false);
    setImageInstructions('');
  };

  const handleGenerateWithInstructions = async () => {
    if (selectedImageIndex !== null) {
      handleCloseModal();
      await handleGenerateSpecificImage(selectedImageIndex);
    } else {
      handleCloseModal();
      await handleGenerateImages();
    }
  };

  return (
    <Box sx={styles.container}>
      <Box sx={{ ...styles.innerContainer, width: { xs: '100%', md: '87%' }, margin: '0 auto' }}>
        <Typography
          variant='h1'
          gutterBottom
          sx={{ fontWeight: 'bold', fontSize: '1.8em', mb: 3, mt: 5, textAlign: 'center' }}
        >
          {lessonConfig.title}
        </Typography>

        <Box sx={{ ...styles.buttonsContainer, ...styles.additionalButtonsContainer }}>
          <Tooltip title='Dina sparade lektioner'>
            <Button
              variant='contained'
              color='primary'
              disableRipple
              onClick={() => setDrawerOpen(true)}
              startIcon={<HistoryIcon />}
              sx={{
                padding: '8px',
                marginRight: '0px',
                paddingLeft: '14px',
                paddingRight: '3px',
                minWidth: 'auto',
                textTransform: 'none',
              }}
            />
          </Tooltip>
        </Box>

        <LessonDrawer
          userId={user.uid}
          lessonType={lessonConfig.lessonType}
          theme={theme}
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          lessonIcon={MenuBookIcon}
          onSelectLesson={lesson => {
            setLessonData(lesson.data);
            setDrawerOpen(false);
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'start', // This ensures it scrolls to the top of the element
              });
            }, 100);
            showSnackbar(`Lektionen har laddats in.`, 'success');
          }}
        />

        <InfoBox
          icon={lessonConfig.icon}
          title='Grundläggande läsförståelse'
          instructions={[
            'Välj vilka typer av frågor du vill ha (på/mellan/bortom raderna)',
            'Generera en åldersanpassad text med valda frågetyper',
            'Möjlighet att lägga till bilder och anpassa materialet',
          ]}
          theme={theme}
          styles={styles}
        />
        <ThemeSelectorFlow
          key={variant}
          value={lessonTheme}
          onChange={e => setLessonTheme(e.target.value)}
          customTheme={customTheme}
          onCustomThemeChange={e => setCustomTheme(e.target.value)}
          onPredefinedThemeChange={e => setPredefinedTheme(e.target.value)}
          onYoutubeTranscriptChange={transcriptContent => {
            Logger.log('onYoutubeTranscriptChange körs med: ', transcriptContent);
            transcriptRef.current = transcriptContent;
          }}
          onFileUpload={content => {
            Logger.log('onFileUpload körs med: ', content);
            uploadedFileRef.current = content;
          }}
          onWikipediaContentChange={content => {
            Logger.log('Parent: Wikipedia content received', content.substring(0, 100));
            wikipediaContentRef.current = content;
          }}
          onRandomizedContentChange={content => {
            Logger.log('randomizedContentRef', content.substring(0, 100));
            randomizedContentRef.current = content;
          }}
          onCoursePlanContentChange={content => {
            Logger.log('onCoursePlanChange', content.substring(0, 100));
            coursePlanContentRef.current = content;
          }}
          lessonType={lessonConfig.lessonType}
          user={user}
          theme={theme}
          styles={styles}
          onLessonSelect={lesson => {
            Logger.log('onLessonSelect körs');
            setLessonData(lesson.data);
            setTimeout(() => {
              lessonTitleRef.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'start', // This ensures it scrolls to the top of the element
              });
            }, 100);
            showSnackbar('Lektionen har laddats in.', 'success');
          }}
          additionalThemes={additionalThemes}
        />
        <Box sx={styles.headlineLessonBox}>
          <Typography variant='h6' sx={styles.headlineLessonBoxTitle}>
            2. Generera lektionen
          </Typography>
        </Box>
        <Box
          sx={{
            ...styles.freshBox,
            padding: '30px',
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant='subtitle1' sx={{ fontWeight: '500', mb: 1, mt: 0 }}>
                Årskurs:
              </Typography>
              <Box sx={{ mt: 0.5, mb: 3 }}>
                <GradeSelector grade={grade} setGrade={setGrade} />
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant='subtitle1' sx={{ fontWeight: '500', mt: 0 }}>
                Språk:
              </Typography>
              <Box sx={{ mt: 1, mb: 3 }}>
                <LanguageSelector language={language} setLanguage={setLanguage} />
              </Box>
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant='subtitle1' sx={{ fontWeight: '500' }}>
                Specifika instruktioner:
              </Typography>
              <FormControl fullWidth margin='normal' sx={{ mb: 3, mt: 1 }}>
                <TextField
                  value={openAiInstructions}
                  onChange={e => setOpenAiInstructions(e.target.value)}
                  placeholder='T.ex. Inkludera lite svårare ord såsom defenestrera, kalibrera och frekventera.'
                  variant='outlined'
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant='subtitle1' sx={{ fontWeight: '500', mb: 1 }}>
                Välj övningstyper:
              </Typography>
              <Grid container spacing={2}>
                {[
                  ['on-the-lines', 'På raderna (svaret finns i texten)'],
                  ['between-the-lines', 'Mellan raderna (dra slutsatser)'],
                  ['beyond-the-lines', 'Bortom raderna (egna tankar)'],
                  ['true-false', 'Sant eller falskt (påståenden)'],
                  ['multiple-choice', 'Flervalsfrågor'],
                  ['open-ended', 'Diskussionsfrågor'],
                ].map(([key, label]) => (
                  <Grid item xs={12} sm={6} key={key}>
                    <CustomFormControlLabel
                      control={<Checkbox />}
                      label={label}
                      checked={selectedExercises[key]}
                      onChange={e =>
                        setSelectedExercises(prev => ({
                          ...prev,
                          [key]: e.target.checked,
                        }))
                      }
                      theme={theme}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>

          <Button
            variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
            color='primary'
            onClick={handleGenerateLesson}
            disabled={isGeneratingLesson}
            sx={{ mt: 7 }}
            startIcon={
              !isGeneratingLesson ? (
                <GenerateIcon style={{ color: '#fff' }} />
              ) : (
                <CircularProgress size={16} />
              )
            }
          >
            {isGeneratingLesson ? 'Genererar...' : 'Generera lektion'}
          </Button>

          {isGeneratingLesson && (
            <Box sx={{ mt: 6 }}>
              <ProgressBar progress={progress} label='Genererar lektion...' />
            </Box>
          )}
        </Box>
        {lessonData && (
          <>
            <Box ref={lessonTitleRef} sx={{ ...styles.headlineLessonBox, mt: 10 }}>
              <Typography variant='h6' sx={styles.headlineLessonBoxTitle}>
                3. Din lektion
              </Typography>
            </Box>

            <Box sx={{ ...styles.freshBox, padding: '30px' }}>
              <SaveLessonDialog
                open={saveDialogOpen}
                onClose={() => setSaveDialogOpen(false)}
                onSave={handleSaveWithComment}
                theme={theme}
                lessonName={lessonData?.title || ''}
                lessonObject={lessonData}
                lessonType={lessonConfig.lessonType}
              />
              <ImageGenerationModal
                open={showImageModal}
                onClose={handleCloseModal}
                onGenerate={handleGenerateWithInstructions}
                isGenerating={isGeneratingImages}
                instructions={imageInstructions}
                setInstructions={setImageInstructions}
                theme={theme}
              />

              <Box sx={{ mt: 0 }}>
                <Box sx={styles.lessonControlPanel}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      px: 1,
                    }}
                  >
                    <Box>
                      <Button
                        variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                        color='primary'
                        onClick={() => handleImageButtonClick(null)}
                        disabled={isGeneratingImages}
                        startIcon={
                          !isGeneratingImages ? (
                            <ImageIcon style={{ color: '#fff' }} />
                          ) : (
                            <CircularProgress size={16} />
                          )
                        }
                      >
                        {isGeneratingImages ? 'Genererar...' : 'Generera bilder'}
                      </Button>
                    </Box>
                    <Box>
                      <Button
                        variant='print'
                        startIcon={<PrintIcon />}
                        sx={{ ml: 2 }}
                        onClick={handlePrintClick}
                      >
                        Skriv ut
                      </Button>
                      <Button
                        variant='print'
                        startIcon={<SaveAsIcon />}
                        sx={{ ml: 2 }}
                        onClick={handleSaveLessonClick}
                      >
                        Spara i lektionsbank
                      </Button>
                    </Box>
                  </Box>
                </Box>

                {/* Title Section */}
                {isEditingTitle ? (
                  <TextField
                    value={lessonData.title}
                    onChange={e => setLessonData(prev => ({ ...prev, title: e.target.value }))}
                    onBlur={() => {
                      setIsEditingTitle(false);
                      showSnackbar('Titeln har uppdaterats', 'success');
                    }}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        setIsEditingTitle(false);
                        showSnackbar('Titeln har uppdaterats', 'success');
                      }
                    }}
                    autoFocus
                    fullWidth
                    variant='standard'
                    sx={{
                      backgroundColor: 'transparent',
                      '& .MuiInputBase-input': {
                        textAlign: 'center',
                        fontWeight: 'bold',
                        fontSize: '2.6em',
                        marginBottom: '1.2rem',
                        marginTop: '1.5rem',
                      },
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                ) : (
                  <Box
                    sx={{
                      position: 'relative',
                      display: 'inline-block',
                      margin: '0 auto',
                      width: '100%',
                      mb: 3,
                      mt: 5,
                      ':hover .edit-icon': {
                        opacity: 1,
                      },
                    }}
                  >
                    <Typography
                      variant='h1'
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '2.6em',

                        textAlign: 'center',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 2,
                      }}
                    >
                      {lessonData.title || ' '}
                      <Box
                        component='span'
                        sx={{
                          opacity: 0,
                          transition: 'opacity 0.2s',
                          display: 'inline-flex',
                          gap: 0.5,
                        }}
                        className='edit-icon'
                      >
                        <Tooltip title='Redigera' placement='top'>
                          <IconButton size='small' onClick={() => setIsEditingTitle(true)}>
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Typography>
                  </Box>
                )}

                {/* Content Section */}
                <Box
                  sx={{
                    backgroundColor: theme =>
                      theme.palette.mode === 'dark' ? '#15202b' : '#e0e0e029',
                    borderRadius: '10px',
                    padding: '20px',
                    mb: 4,
                    mt: 4,
                  }}
                >
                  <Box
                    sx={{
                      '&:hover .controls': {
                        opacity: 1,
                      },
                      display: 'flex',
                      alignItems: 'flex-start',
                      gap: 1,
                    }}
                  >
                    {isEditingContent ? (
                      <Box sx={{ display: 'flex', alignItems: 'flex-start', width: '100%' }}>
                        <TextField
                          fullWidth
                          multiline
                          rows={15}
                          value={lessonData.content}
                          onChange={e =>
                            setLessonData(prev => ({ ...prev, content: e.target.value }))
                          }
                          onBlur={() => {
                            setIsEditingContent(false);
                            showSnackbar('Innehållet har uppdaterats', 'success');
                          }}
                          autoFocus
                          sx={{
                            ...styles.editingTextField,
                            '& .MuiInputBase-input': {
                              lineHeight: 1.8,
                            },
                          }}
                        />

                        <Tooltip title='Spara ändringar' placement='top'>
                          <IconButton
                            onClick={() => {
                              setIsEditingContent(false);
                              showSnackbar('Innehållet har uppdaterats', 'success');
                            }}
                          >
                            <SaveAsIcon fontSize='small' />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    ) : (
                      <Typography
                        variant='body1'
                        sx={{
                          lineHeight: 1.8,
                          flex: 1,
                          display: 'flex',
                          alignItems: 'flex-start',
                          gap: 1,
                          whiteSpace: 'pre-wrap',
                        }}
                      >
                        {lessonData.content || ' '}
                        <Box
                          component='span'
                          className='controls'
                          sx={{
                            opacity: 0,
                            transition: 'opacity 0.2s',
                            display: 'inline-flex',
                            gap: 0.5,
                            ml: 1,
                            mt: 0.5,
                          }}
                        >
                          <Tooltip title='Redigera' placement='top'>
                            <IconButton size='small' onClick={() => setIsEditingContent(true)}>
                              <EditIcon fontSize='small' />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Typography>
                    )}
                  </Box>
                </Box>

                {/* Images Grid */}
                <Grid container spacing={2} sx={{ mb: 4, justifyContent: 'center' }}>
                  {lessonData.images.map((image, index) => (
                    <Grid item xs={12} md={6} key={index}>
                      <HoverableImage
                        imageUrl={image.imageUrl}
                        isLoading={isGeneratingImages}
                        loadingIndex={loadingImageIndex}
                        currentIndex={index}
                        userId={user.uid}
                        theme={theme}
                        showSnackbar={showSnackbar}
                        onGenerateClick={() => handleImageButtonClick(index)}
                        onImageUpdate={newImageUrl => {
                          setLessonData(prev => {
                            const newImages = [...prev.images];
                            newImages[index] = { imageUrl: newImageUrl };
                            return { ...prev, images: newImages };
                          });
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>

                {/* Exercises Sections */}
                {lessonData.exercises.map((exercise, index) => (
                  <Box key={index}>
                    {renderEditableSection(exercise.type, 'title', 'content', index, exercise)}
                  </Box>
                ))}
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default BasicReadingCompLesson;
