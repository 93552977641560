import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { db } from './firebase';
import { addDoc, collection, query, where, getDocs } from 'firebase/firestore';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { styles } from '../index';
import { Fade, InputAdornment } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { useSnackbar } from './SnackbarHandler';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ShortTextIcon from '@mui/icons-material/ShortText';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import {
  Snackbar,
  Alert,
  FormControl,
  FormControlLabel,
  InputLabel,
  Divider,
  Select,
  Radio,
  RadioGroup,
  ToggleButtonGroup,
  ToggleButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Popper,
  Paper,
  Grow,
} from '@mui/material';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { TypeAnimation } from 'react-type-animation';
import GenerateIcon from '@mui/icons-material/AutoAwesome';
import CachedIcon from '@mui/icons-material/Cached';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import AllOutIcon from '@mui/icons-material/AllOut';
import Tooltip from '@mui/material/Tooltip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { callApi } from './api';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { AIModelSelect } from './TestComponents2';
import { useTheme } from '@mui/material/styles';
import { getStyles } from '../index';
import Logger from './Logger';
import SendIcon from '@mui/icons-material/Send';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import IdeaGenerator from './IdeaGenerator';

// Function to format response with spans for word animation
const formatResponseWithSpans = response => {
  if (typeof response !== 'string') {
    return ''; // Handle invalid input
  }

  const words = response.split(' ');
  let formattedResponse = '';
  words.forEach((word, index) => {
    const animationDelay = index * 0.03;
    const animationDuration = 0.09 + Math.random() * 0.06;
    formattedResponse += `<span class="word-animation" style="animation-delay: ${animationDelay}s; animation-duration: ${animationDuration}s;" data-key="${Date.now()}-${index}">${word}</span> `;
  });
  return formattedResponse;
};

const TypeWriter = ({ user }) => {
  const [title, setTitle] = useState('');
  const [paragraph, setParagraph] = useState('');
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [subLoading, setSubLoading] = useState(false);
  const [currentLoadingButton, setCurrentLoadingButton] = useState('');
  const [generatedText, setGeneratedText] = useState('');
  const [lectures, setLectures] = useState([]);
  const [error, setError] = useState(null);
  const [userInput, setUserInput] = useState('');
  const [selectedText, setSelectedText] = useState('');
  const textFieldRef = useRef(null);
  const [loadingButtons, setLoadingButtons] = useState({});
  const [selectedModel, setSelectedModel] = useState('Studera.AI');
  const [generatedParagraphs, setGeneratedParagraphs] = useState([]);
  const { showSnackbar } = useSnackbar();
  const [isSendLoading, setIsSendLoading] = useState(false);
  const [paragraphsToDelete, setParagraphsToDelete] = useState({});
  const [tone, setTone] = useState('Neutral'); // State to manage which radio button is selected

  const subjectInputRef = useRef(null);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const theme = useTheme();
  const styles = getStyles(theme);

  const handleIdeaGenerated = idea => {
    subjectInputRef.current.value = idea;
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      Logger.log('Enter key pressed');
      // Här anropar du den funktion som ska exekvera när Enter trycks
      handleGenerateParagraph();
    }
  };

  const handleToneChange = event => {
    setTone(event.target.value);
  };

  // const handleToneChange = (event, newInputType) => {
  //   if (newInputType !== null) {
  //     setTone(newInputType);

  //   }
  // };

  const handleDeleteParagraph = id => {
    setParagraphsToDelete(prev => ({ ...prev, [id]: true }));
    setTimeout(() => {
      setGeneratedParagraphs(prevParagraphs =>
        prevParagraphs.filter(paragraph => paragraph.id !== id)
      );
      setParagraphsToDelete(prev => {
        const newState = { ...prev };
        delete newState[id];
        return newState;
      });
    }, 500);
  };

  const getLastXParagraphs = x => {
    const cleanParagraphs = generatedParagraphs
      .slice(-x)
      .map(p => p.text.replace(/<\/?span[^>]*>/g, ''));
    return cleanParagraphs.join('\n\n');
  };

  useEffect(() => {
    fetchLectures();
  }, []);

  const fetchLectures = async () => {
    if (!user) return;

    const q = query(collection(db, 'lectures'), where('userId', '==', user.uid));
    const querySnapshot = await getDocs(q);
    const lecturesData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setLectures(lecturesData);
  };

  const handleTitleChange = e => {
    setTitle(e.target.value);
    subjectInputRef.current.value = e.target.value;
  };

  const handleGenerateParagraph = async () => {
    setLoading(true);
    setGeneratedText('');
    setGeneratedParagraphs([]);
    setResponse(null);
    try {
      // Första API-anropet för att generera paragrafen i ett JSON-objekt
      const paragraphMessages = [
        {
          role: 'system',
          content: `Generera en paragraf på svenska baserat på användarens input i en ${tone} ton. Svara med ett JSON-objekt i följande format:
          {
            "response": {
              "paragraph": "<din genererade paragraf här>"
            }
          }
        Skriv på SVENSKA. Använd inte markdown-formatering.`,
        },
        { role: 'user', content: `Skriv om ${subjectInputRef.current.value}` },
      ];

      const paragraphResponse = await callApi(paragraphMessages, undefined, true, user.uid);
      let paragraphContent = paragraphResponse.data.choices[0].message.content.trim();

      if (paragraphContent.startsWith('```json')) {
        paragraphContent = paragraphContent.slice(7, -3).trim();
      } else if (paragraphContent.startsWith('```')) {
        paragraphContent = paragraphContent.slice(3, -3).trim();
      }

      const parsedParagraph = JSON.parse(paragraphContent);
      const generatedParagraph = parsedParagraph.response.paragraph;

      // Visa den genererade paragrafen omedelbart
      const formattedText = formatResponseWithSpans(generatedParagraph);
      setGeneratedText(formattedText);
      setGeneratedParagraphs(prev => [...prev, { id: Date.now(), text: formattedText }]);
      setParagraph(generatedParagraph);

      // Sätt loading till false för att indikera att den första paragrafen är klar
      setLoading(false);

      // Andra API-anropet för att generera resten av JSON-objektet (i bakgrunden)
      const jsonMessages = [
        {
          role: 'system',
          content: `Baserat på följande paragraf, generera ett komplett JSON-objekt med relaterade ämnen och information. Använd exakt följande JSON-format och inkludera 4 mycket korta ämnen på svenska för varje sektion. Skriv på SVENSKA:
          {
            "response": {
              "paragraph": "${generatedParagraph}",
              "expand_on_topics_from_paragraph": ["<ämne 1>", "<ämne 2>", "<ämne 3>", "<ämne 4>"],
              "other_topics_in_same_subject": ["<ämne 1>", "<ämne 2>", "<ämne 3>", "<ämne 4>"],
              "key_statistics": ["<statistik 1>", "<statistik 2>", "<statistik 3>", "<statistik 4>"],
              "examples_and_case_studies": ["<exempel 1>", "<exempel 2>", "<exempel 3>", "<exempel 4>"],
              "expert_opinions": ["<åsikt 1>", "<åsikt 2>", "<åsikt 3>", "<åsikt 4>"],
              "contrasting_views": ["<åsikt 1>", "<åsikt 2>", "<åsikt 3>", "<åsikt 4>"]
            }
          }`,
        },
        {
          role: 'user',
          content: `Generera komplett JSON-objekt för paragrafen: ${generatedParagraph}`,
        },
      ];

      const jsonResponse = await callApi(jsonMessages, undefined, true, user.uid);
      let messageContent = jsonResponse.data.choices[0].message.content.trim();

      if (messageContent.startsWith('```json')) {
        messageContent = messageContent.slice(7, -3).trim();
      } else if (messageContent.startsWith('```')) {
        messageContent = messageContent.slice(3, -3).trim();
      }

      const parsedResponse = JSON.parse(messageContent);
      setResponse(parsedResponse.response);
    } catch (error) {
      showSnackbar('Nåt gick fel. Försök igen.', 'info');
      console.error('Error generating paragraph:', error);
      setLoading(false);
    }
  };

  const handleExpandTopic = async instruction => {
    setSubLoading(true);
    setCurrentLoadingButton(instruction);

    const lastThreeParagraphs = getLastXParagraphs(3);
    Logger.log('lastThreeParagraphs: ', lastThreeParagraphs);

    try {
      const messages = [
        {
          role: 'system',
          content: `Generera en lång paragraf med instruktionen: "${instruction}" i en ${tone} ton  baserat på "${lastThreeParagraphs} - Do NOT lose the context.". Put the response in a JSON format as per below. Add exactly 5 very short topic to each section in the json string below. Your response is in Swedish. Use the EXACT JSON format below.  Do not use any markdown formatting:
              {
                "response": {
                  "paragraph": "<your paragraph here>",
                  "expand_on_topics_from_paragraph": ["<topic 1>", "<topic 2>", ...],
                  "other_topics_in_same_subject": ["<topic 1>", "<topic 2>", ...],
                  "expert_opinions": ["<opinion 1>", "<opinion 2>", ...]

                }
              }`,
        },
        {
          role: 'user',
          content: `Latest paragraph: ${lastThreeParagraphs}\nInstruction: ${instruction}`,
        },
      ];

      const response = await callApi(messages, undefined, true, user.uid);

      Logger.log('response: ', response.data);

      const data = response.data.choices[0].message.content.trim();
      const parsedResponse = JSON.parse(data);
      Logger.log('parsedResponse: ', parsedResponse);

      const formattedText = formatResponseWithSpans(parsedResponse.response.paragraph);

      Logger.log('formattedText in handleExpandTopic: ', formattedText);

      const formattedTextWithoutSpans = parsedResponse.response.paragraph;
      Logger.log('formattedTextWithoutSpans: ', formattedTextWithoutSpans);
      setGeneratedText(formattedTextWithoutSpans);

      setGeneratedText(prevText => `${prevText}\n\n${formattedText}`);

      setGeneratedParagraphs(prev => [...prev, { id: Date.now(), text: formattedText }]);

      setResponse(parsedResponse.response);
      setParagraph(parsedResponse.response.paragraph); // Save the latest paragraph
    } catch (error) {
      showSnackbar('Nåt gick fel. Försök igen.', 'info');
      console.error('Error expanding topic:', error);
    } finally {
      setSubLoading(false);
      setCurrentLoadingButton('');
    }
  };

  const handleSaveLecture = async () => {
    if (!user) return;

    try {
      await addDoc(collection(db, 'lectures'), {
        userId: user.uid,
        date: new Date(),
        paragraphs: generatedParagraphs.map(p => p.text.replace(/<\/?span[^>]*>/g, '')),
      });
      fetchLectures();
    } catch (error) {
      console.error('Error saving lecture:', error);
    }
  };

  const handleButtonClick = async (action, paragraphIndex) => {
    setSubLoading(true);
    setCurrentLoadingButton(action);
    const buttonKey = action === 'Fortsatt' ? 'Fortsatt' : `${action}-${paragraphIndex}`;
    setLoadingButtons(prev => ({ ...prev, [buttonKey]: true }));

    let targetParagraph;
    let isRewrite = false;

    if (action === 'Fortsatt') {
      targetParagraph = getLastXParagraphs(5);
      action = 'Skriv en ny paragraf utan att repetera.';
    } else if (
      action === 'Skriv om' ||
      action === 'Expandera' ||
      action === 'Förkorta' ||
      action === 'Försvåra/komplicera'
    ) {
      targetParagraph = generatedParagraphs[paragraphIndex].text.replace(/<\/?span[^>]*>/g, '');
      isRewrite = true;
    } else {
      targetParagraph = getLastXParagraphs(5);
    }

    Logger.log('targetParagraph: ', targetParagraph);

    try {
      const messages = [
        {
          role: 'system',
          content: `Generera en lång paragraf med instruktionen: "${action}" i en ${tone} ton baserat på följande stycken: "${targetParagraph}"  - Do NOT lose the context. Skriv allt på svenska.  Put the response in a JSON format as per below. Add exactly 3 topics in swedish to each section in the json string below. Use the EXACT JSON format below. Do not use any markdown formatting:
            {
                "response": {
                  "paragraph": "<your paragraph here>",
                  "expand_on_topics_from_paragraph": ["<topic 1>", "<topic 2>", ...],
                  "other_topics_in_same_subject": ["<topic 1>", "<topic 2>", ...],
                 "key_statistics": ["<statistic 1>", "<statistic 2>", ...],
                  "expert_opinions": ["<opinion 1>", "<opinion 2>", ...],
                  "contrasting_views": ["<view 1>", "<view 2>", ...]
              }
            }`,
        },
        { role: 'user', content: `Paragraph: ${targetParagraph}\nInstruction: ${action}` },
      ];

      const response = await callApi(messages, undefined, true, user.uid);

      Logger.log('response: ', response.data);

      const data = response.data.choices[0].message.content.trim();
      const parsedResponse = JSON.parse(data);
      Logger.log('parsedResponse: ', parsedResponse);

      const formattedText = formatResponseWithSpans(parsedResponse.response.paragraph);
      Logger.log('formattedText in handleExpandTopic: ', formattedText);

      const formattedTextWithoutSpans = parsedResponse.response.paragraph;
      Logger.log('formattedTextWithoutSpans: ', formattedTextWithoutSpans);

      if (isRewrite) {
        // Update only the specific paragraph
        setGeneratedParagraphs(prev =>
          prev.map((p, index) => (index === paragraphIndex ? { ...p, text: formattedText } : p))
        );
      } else {
        // Add new paragraph
        setGeneratedParagraphs(prev => [...prev, { id: Date.now(), text: formattedText }]);
      }

      setGeneratedText(formattedTextWithoutSpans);
      setResponse(parsedResponse.response);
      setParagraph(parsedResponse.response.paragraph);
    } catch (error) {
      showSnackbar('Nåt gick fel. Försök igen.', 'info');
      console.error('Error handling button click:', error);
    } finally {
      setLoadingButtons(prev => ({ ...prev, [buttonKey]: false }));
      setSubLoading(false);
      setCurrentLoadingButton('');
    }
  };

  const handleUserInputChange = e => {
    setUserInput(e.target.value);
  };

  const handleUserInputSubmit = async () => {
    if (!userInput.trim()) return;
    setIsSendLoading(true);
    await handleButtonClick(userInput);
    setUserInput('');
    setIsSendLoading(false);
  };

  const handleTextSelection = () => {
    const selection = window.getSelection().toString();
    if (selection) {
      setSelectedText(selection);
    }
  };

  const handleCitation = () => {
    if (selectedText) {
      setUserInput(prev => `${prev}"${selectedText}" `);
      setSelectedText('');
      if (textFieldRef.current) {
        textFieldRef.current.focus();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mouseup', handleTextSelection);
    return () => {
      document.removeEventListener('mouseup', handleTextSelection);
    };
  }, []);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.innerContainer}>
        <div>
          <style>
            {`
@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

    @keyframes fadeOutAnimation {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }

.word-animation {
  display: inline-block;
  opacity: 0;
  animation: fadeInAnimation 0.1s linear forwards;
}


    .paragraph-delete-animation {
      animation: fadeOutAnimation 0.5s linear forwards;
    }

`}
          </style>
        </div>
        <Typography variant='h4' sx={styles.header}>
          Generera text med AI
        </Typography>

        <Box
          sx={{
            ...styles.darkBorder,
            padding: '20px',
            width: '100%',
            backgroundColor: theme.palette.mode === 'dark' ? '#212c36' : 'white',
            border: theme.palette.mode === 'dark' ? '1px solid #263442' : '1px solid #e0e0e091',
          }}
        >
          <Typography variant='formLabelSinUnderline'>Skriv ett ämne:</Typography>

          <TextField
            placeholder='T.ex. Anna Karenina, Platons dialoger eller Svensk 1700-tals litteratur)'
            inputRef={subjectInputRef}
            onChange={handleTitleChange}
            onKeyPress={handleKeyPress}
            margin='normal'
            fullWidth
            sx={{ mt: 1 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IdeaGenerator
                    user={user}
                    onIdeaGenerated={handleIdeaGenerated}
                    systemMessage="ge mig en idé på ett ämne att skriva en uppsats om på 1 mening (exempel: Franska revolutionen eller Andra världskriget). Följ denna struktur varje gång: [{ 'idea': 'the  idea'}]"
                    position='end'
                    size='small'
                  />
                </InputAdornment>
              ),
            }}
          />

          <Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                alignItems: { xs: 'stretch', sm: 'flex-start' },
                gap: 2,
              }}
            >
              <Box sx={{ maxWidth: '300px', mt: 2, flexGrow: 1 }}>
                <Tooltip title='Resultaten kommer variera beroende på vilken AI-modell du väljer.'>
                  <Typography variant='formLabel'>AI-modell:</Typography>
                </Tooltip>
                <AIModelSelect
                  selectedModel={selectedModel}
                  setSelectedModel={setSelectedModel}
                  disabledOptions={['Gemini Pro', 'Mistral Large']}
                />
              </Box>

              <FormControl sx={{ mt: { xs: 0, md: 2 }, maxWidth: '300px', flexGrow: 1 }}>
                <Typography variant='formLabelSinUnderline'>Ton:</Typography>

                <Select id='tone-select' value={tone} sx={{ mt: 1 }} onChange={handleToneChange}>
                  <MenuItem value='Neutral'>Neutral</MenuItem>
                  <MenuItem value='Akademisk'>Akademisk</MenuItem>
                  <MenuItem value='Humoristisk'>Humoristisk</MenuItem>
                  <MenuItem value='Sarkastisk'>Sarkastisk</MenuItem>
                  <MenuItem value='Poetisk'>Poetisk</MenuItem>
                  <MenuItem value='Mystisk'>Mystisk</MenuItem>
                  <MenuItem value='Dramatisk'>Dramatisk</MenuItem>
                  <MenuItem value='Melankolisk'>Melankolisk</MenuItem>
                  <MenuItem value='Barock'>Barock</MenuItem>
                  <MenuItem value='Kafkaesk'>Kafkaesk</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Button
              ref={anchorRef}
              onMouseEnter={() => setOpen(true)}
              onMouseLeave={() => setOpen(false)}
              sx={{
                ...styles.smallerBtn,
                display: 'none',
                top: '20px',
                left: '300px',
                border: `1px solid ${theme.palette.mode === 'dark' ? '#38444d' : '#ececec'}`,
                '&:hover': {
                  border: `1px solid ${theme.palette.mode === 'dark' ? '#38444d' : '#ececec'}`,
                },
              }}
            >
              <img
                src='/img/ChatGPT.png'
                style={{ ...styles.roundImage, marginRight: '8px' }}
                alt='Studera AI'
              />
              Studera AI
              <ExpandMoreIcon sx={{ ml: 1 }} />
            </Button>

            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
              sx={{ position: 'relative', zIndex: 999 }}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                    borderRadius: '20px',
                    boxShadow: '0 0 20px 1px rgba(0,0,0,.16)',
                  }}
                >
                  <Paper onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
                    <Box component='ul' sx={{ p: 0, m: 0, listStyle: 'none' }}>
                      <MenuItem
                        selected
                        sx={{
                          ...styles.menuItems,
                          fontSize: '14px',
                          fontWeight: '600',
                          borderTopLeftRadius: '20px',
                          borderTopRightRadius: '20px',
                        }}
                      >
                        <ListItemIcon>
                          <img src='/img/ChatGPT.png' style={styles.roundImage} alt='ChatGPT' />
                        </ListItemIcon>
                        ChatGPT
                      </MenuItem>
                      <Tooltip title='Stöd för fler AI-modeller kommer snart'>
                        <MenuItem
                          disabled
                          sx={{ ...styles.menuItems, fontSize: '14px', fontWeight: '600' }}
                        >
                          <ListItemIcon>
                            <img src='/img/GPT4o.png' style={styles.roundImage} alt='GPT 4o' />
                          </ListItemIcon>
                          GPT 4o
                        </MenuItem>
                        <MenuItem
                          disabled
                          sx={{ ...styles.menuItems, fontSize: '14px', fontWeight: '600' }}
                        >
                          <ListItemIcon>
                            <img
                              src='/img/Claude 3 Sonnet.png'
                              style={styles.roundImage}
                              alt='Claude 3 Sonett'
                            />
                          </ListItemIcon>
                          Claude 3 Sonnet
                        </MenuItem>
                        <MenuItem
                          disabled
                          sx={{ ...styles.menuItems, fontSize: '14px', fontWeight: '600' }}
                        >
                          <ListItemIcon>
                            <img
                              src='/img/Claude 3.5 Sonnet.png'
                              style={styles.roundImage}
                              alt='Claude 3.5 Sonett'
                            />
                          </ListItemIcon>
                          Claude 3.5 Sonnet
                        </MenuItem>
                        <MenuItem
                          disabled
                          sx={{ ...styles.menuItems, fontSize: '14px', fontWeight: '600' }}
                        >
                          <ListItemIcon>
                            <img
                              src='/img/Gemini Pro.png'
                              style={styles.roundImage}
                              alt='Gemini Pro'
                            />
                          </ListItemIcon>
                          Gemini Pro
                        </MenuItem>
                        <MenuItem
                          disabled
                          sx={{ ...styles.menuItems, fontSize: '14px', fontWeight: '600' }}
                        >
                          <ListItemIcon>
                            <img
                              src='/img/Mistral Large.png'
                              style={styles.roundImage}
                              alt='Mistral Large'
                            />
                          </ListItemIcon>
                          Mistral Large
                        </MenuItem>
                      </Tooltip>
                    </Box>
                  </Paper>
                </Grow>
              )}
            </Popper>

            <Button
              variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
              color='primary'
              onClick={handleGenerateParagraph}
              disabled={loading}
              sx={{ mt: 2 }}
              startIcon={
                loading ? (
                  <CircularProgress size={18} />
                ) : (
                  <GenerateIcon style={{ color: '#fff' }} />
                )
              }
            >
              Generera text
            </Button>
          </Box>
        </Box>
        {!generatedText && (
          <Box
            sx={{
              ...styles.darkBorder,
              padding: '20px',
              mt: 5,
              backgroundColor: theme.palette.mode === 'dark' ? '#212c36' : 'white',
              border: theme.palette.mode === 'dark' ? 'none' : styles.darkBorder.border,
            }}
          >
            <Typography variant='h1' sx={styles.h3}>
              Generera text med AI - Så fungerar det
            </Typography>

            <Typography style={styles.h5} sx={{ marginTop: '10px' }}>
              Exempel på vad du kan göra:
            </Typography>
            <ul style={{ listStyleType: 'none', padding: 0 }}>
              <li style={{ marginBottom: '10px' }}>
                <Typography>
                  <CheckCircleIcon
                    style={{ verticalAlign: 'middle', marginRight: '5px', color: '#45bd45' }}
                  />
                  Börja med att generera en paragraf med valfritt ämne.
                </Typography>
              </li>
              <li style={{ marginBottom: '10px' }}>
                <Typography>
                  <CheckCircleIcon
                    style={{ verticalAlign: 'middle', marginRight: '5px', color: '#45bd45' }}
                  />
                  För musen över paragrafen för att kunna skriva om, expandera, förkorta, försvåra
                  eller radera
                </Typography>
              </li>
              <li style={{ marginBottom: '10px' }}>
                <Typography>
                  <CheckCircleIcon
                    style={{ verticalAlign: 'middle', marginRight: '5px', color: '#45bd45' }}
                  />
                  Fortsätt generera text genom att antingen klicka på fortsätt eller välj några av
                  ämnena som vår AI genererar baserat på vad som skrivits.
                </Typography>
              </li>

              <li style={{ marginBottom: '10px' }}>
                <Typography>
                  <CheckCircleIcon
                    style={{ verticalAlign: 'middle', marginRight: '5px', color: '#45bd45' }}
                  />
                  För att få vår AI att ge tydligast svar bör du använda textrutan för att instruera
                  exakt vad du vill (t.ex. "Jämför romantiken med modernismen")
                </Typography>
              </li>
              <li style={{ marginBottom: '10px' }}>
                <Typography>
                  <CheckCircleIcon
                    style={{ verticalAlign: 'middle', marginRight: '5px', color: '#45bd45' }}
                  />
                  Markerar du valfri text kommer en citat-ikon dyka upp undertill, klicka på den för
                  att exempelvis skriva in "Expandera [citat]".
                </Typography>
              </li>

              <li style={{ marginBottom: '10px' }}>
                <Typography>
                  <CheckCircleIcon
                    style={{ verticalAlign: 'middle', marginRight: '5px', color: '#45bd45' }}
                  />
                  Trots att vår AI bibehåller kontext från tidigare meddelanden kan den stundvis
                  förlora kontexten.
                </Typography>
              </li>
            </ul>

            <Alert severity='info' sx={{ borderRadius: '8px', marginTop: '20px' }}>
              <strong>Obs:</strong> Har du kört fast så börja om genom att trycka på "Generera
              paragraf" högst upp.
            </Alert>
          </Box>
        )}

        {generatedText && (
          <Fade in={true}>
            <Box sx={{ mt: 4, whiteSpace: 'pre-line' }}>
              <Divider />
              <Typography variant='h3' sx={{ ...styles.h3, mb: 2, mt: 3 }}>
                Resultat
              </Typography>
              <Box
                sx={{
                  backgroundColor: theme.palette.mode === 'dark' ? '#212c36' : 'white',
                  padding: '20px',
                  borderRadius: '10px',
                }}
              >
                {generatedParagraphs.map(({ id, text }, index) => (
                  <Box
                    key={id}
                    sx={{
                      position: 'relative',
                      padding: '20px',
                      paddingBottom: '0px',
                      borderRadius: '4px',
                      border: `2px solid ${theme.palette.mode === 'dark' ? 'transparent' : 'transparent'}`,
                      backgroundColor: theme.palette.mode === 'dark' ? '#212c36' : 'white',
                      '&:hover': {
                        border: `2px solid ${theme.palette.mode === 'dark' ? '#8d37ff' : '#8d37ff'}`,
                      },
                      '&:hover .paragraph-icons': {
                        opacity: 1,
                      },
                      opacity: paragraphsToDelete[id] ? 0 : 1,
                      transition: 'opacity 0.5s',
                    }}
                  >
                    <Typography component='div' dangerouslySetInnerHTML={{ __html: text }} />

                    <DeleteIcon
                      className='delete-icon'
                      sx={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        opacity: 0,
                        transition: 'opacity 0.3s',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleDeleteParagraph(id)}
                    />

                    <Box
                      className='paragraph-icons'
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        paddingBottom: '7px',
                        opacity: { xs: 1, md: 0 },
                        transition: 'opacity 0.3s',
                      }}
                    >
                      <Tooltip title='Generera igen'>
                        <IconButton onClick={() => handleButtonClick('Skriv om', index)}>
                          {loadingButtons[`Skriv om-${index}`] ? (
                            <CircularProgress size={18} />
                          ) : (
                            <CachedIcon />
                          )}
                        </IconButton>
                      </Tooltip>

                      <Tooltip title='Expandera'>
                        <IconButton onClick={() => handleButtonClick('Expandera', index)}>
                          {loadingButtons[`Expandera-${index}`] ? (
                            <CircularProgress size={18} />
                          ) : (
                            <AllOutIcon />
                          )}
                        </IconButton>
                      </Tooltip>

                      <Tooltip title='Förkorta'>
                        <IconButton onClick={() => handleButtonClick('Förkorta', index)}>
                          {loadingButtons[`Förkorta-${index}`] ? (
                            <CircularProgress size={18} />
                          ) : (
                            <ShortTextIcon />
                          )}
                        </IconButton>
                      </Tooltip>

                      <Tooltip title='Försvåra/komplicera'>
                        <IconButton onClick={() => handleButtonClick('Försvåra/komplicera', index)}>
                          {loadingButtons[`Försvåra/komplicera-${index}`] ? (
                            <CircularProgress size={18} />
                          ) : (
                            <TrendingUpIcon />
                          )}
                        </IconButton>
                      </Tooltip>
                      <Tooltip title='Radera'>
                        <IconButton onClick={() => handleDeleteParagraph(id)}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>

                      {selectedText && (
                        <Tooltip title='Citera'>
                          <IconButton onClick={handleCitation}>
                            <FormatQuoteIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                  </Box>
                ))}
              </Box>
              <br />
              <Tooltip title='Fortsätt generera'>
                <Button
                  disabled={subLoading}
                  variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                  color='primary'
                  onClick={() => handleButtonClick('Fortsatt')}
                >
                  Fortsätt generera{' '}
                  {loadingButtons['Fortsatt'] ? (
                    <CircularProgress
                      size={18}
                      style={{ verticalAlign: 'middle', marginLeft: '5px' }}
                    />
                  ) : (
                    <KeyboardDoubleArrowRightIcon />
                  )}
                </Button>
              </Tooltip>
              <br />
              <br />
              eller...
              <br />
              <br />
              <TextField
                ref={textFieldRef}
                value={userInput}
                onChange={handleUserInputChange}
                sx={{
                  backgroundColor: theme.palette.mode === 'dark' ? '#15202b' : '#f1f5f9',
                  border: 'none',
                }}
                InputProps={{
                  sx: {
                    borderRadius: 10,
                    width: '100%',
                    backgroundColor: theme.palette.mode === 'dark' ? '#212c36' : 'white',
                    borderColor: 'transparent',
                    '&:hover': {
                      borderColor: 'transparent',
                    },
                  },
                  endAdornment: (
                    <InputAdornment position='end'>
                      <Button
                        onClick={handleUserInputSubmit}
                        sx={{
                          minWidth: '40px',
                          minHeight: '40px',
                          padding: '0',
                          backgroundColor: '#ebebeb',
                          borderRadius: '20px',
                          boxShadow: 'none',
                          '&:hover': {
                            backgroundColor: '#eee9ff',
                            boxShadow: 'none',
                            '& .MuiSvgIcon-root': {
                              color: '#8b5cf6',
                            },
                          },
                        }}
                      >
                        {isSendLoading ? (
                          <CircularProgress size={18} sx={{ color: '#8b5cf6' }} />
                        ) : (
                          <SendIcon
                            sx={{
                              color: '#000',
                              transition: 'color 0.3s ease',
                              paddingLeft: '2px',
                            }}
                          />
                        )}
                      </Button>
                    </InputAdornment>
                  ),
                }}
                placeholder="Skriv specifik instruktion här (t.ex. 'Skriv mer om X och Y' )"
                variant='outlined'
                fullWidth
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    handleUserInputSubmit();
                  }
                }}
              />
            </Box>
          </Fade>
        )}

        {response && (
          <>
            <Box sx={{ mt: 4 }}>
              <Typography sx={styles.h3}>Ämnen genererade baserat på det som skrivs:</Typography>
              <br />
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                {Object.keys(response).map(
                  category =>
                    Array.isArray(response[category]) &&
                    response[category].length > 0 && (
                      <Box key={category}>
                        <Typography sx={styles.h4}>
                          {(() => {
                            switch (category) {
                              case 'expand_on_topics_from_paragraph':
                                return 'Baserat på senaste paragrafen';
                              case 'other_topics_in_same_subject':
                                return 'Andra ämnen i samma härad';
                              case 'contextual_information':
                                return 'Kontextuell info';
                              case 'examples_and_case_studies':
                                return 'Exempel';
                              case 'questions_for_further_exploration':
                                return 'Frågor för utforskning';
                              case 'historical_context':
                                return 'Historisk kontext';
                              case 'key_statistics':
                                return 'Statistik';
                              case 'ethical_considerations':
                                return 'Etiska överväganden';
                              case 'expert_opinions':
                                return 'Expertåsikter';
                              case 'fun_facts':
                                return 'Roliga fakta';
                              case 'learning_resources':
                                return 'Resurser';
                              case 'contrasting_views':
                                return 'Kontrasterande åsikter';
                              default:
                                return category.replace(/_/g, ' ');
                            }
                          })()}
                        </Typography>

                        {response[category].map((topic, index) => (
                          <Button
                            key={index}
                            variant='contained'
                            color='primary'
                            sx={{
                              ...styles.purpleBtn,
                              padding: '5px',
                              paddingLeft: '15px',
                              paddingRight: '15px',
                              fontSize: '14px',
                              display: 'inline-flex',
                              alignItems: 'center', // This will vertically align the button contents
                              justifyContent: 'center',
                            }}
                            onClick={() => handleExpandTopic(topic)}
                            startIcon={
                              subLoading && currentLoadingButton === topic ? (
                                <CircularProgress size={16} />
                              ) : null
                            }
                            disabled={subLoading}
                          >
                            {topic}
                          </Button>
                        ))}
                      </Box>
                    )
                )}
              </Box>
            </Box>

            <Box>
              <Button
                variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
                color='primary'
                onClick={handleSaveLecture}
                startIcon={<SaveAsIcon />}
                sx={{ mt: 2 }}
              >
                Spara text
              </Button>
            </Box>
          </>
        )}

        {lectures.length > 0 && (
          <>
            <Box sx={{ mt: 4 }}>
              <Typography variant='h6'>Sparade texter:</Typography>
              {lectures.map(lecture => (
                <Box key={lecture.id} sx={{ mt: 2 }}>
                  <Typography variant='subtitle1'>
                    {new Date(lecture.date.seconds * 1000).toLocaleString()}
                  </Typography>
                  {lecture.paragraphs.map((paragraph, index) => (
                    <Typography key={index} sx={{ mt: 1, whiteSpace: 'pre-line' }}>
                      {paragraph}
                    </Typography>
                  ))}
                </Box>
              ))}
            </Box>
          </>
        )}

        {error && (
          <Snackbar
            open={!!error}
            onClose={() => setError(null)}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            autoHideDuration={4000} // Optional: Automatically hides the alert after 6 seconds
          >
            <Alert
              onClose={() => setError(null)}
              severity='info'
              sx={{ width: '100%' }} // Ensures the Alert takes full width of the Snackbar
            >
              {`Knak i fogarna: ${error}`}
            </Alert>
          </Snackbar>
        )}
      </Box>
    </Box>
  );
};

export default TypeWriter;
