import React, { useState, useRef, useEffect } from 'react';
import {
  Typography,
  Grid,
  Box,
  Card,
  CardContent,
  Stepper,
  Step,
  StepLabel,
  IconButton,
  Container,
  CircularProgress,
  Button,
  Dialog,
  Tooltip,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LaunchIcon from '@mui/icons-material/Launch';
import { East as ArrowIcon } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import { CheckCircleOutline } from '@mui/icons-material';
import coursePlans from './coursePlans';

import { getStyles } from '../index';

export const CoursePlanSelector = ({ subject, onTopicSelect, styles, theme, forQuiz }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [instructionText, setInstructionText] = useState('');
  const [containerHeight, setContainerHeight] = useState('auto');
  const [dialogOpen, setDialogOpen] = useState(false);
  const gradeViewRef = useRef(null);
  const sectionViewRef = useRef(null);
  const topicViewRef = useRef(null);
  const confirmationViewRef = useRef(null);
  const [isSuccess, setIsSuccess] = useState(false);

  const isDark = theme.palette.mode === 'dark';

  const [loadingStep, setLoadingStep] = useState(0);
  const [isTopicLoading, setIsTopicLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [subjectText, setSubjectText] = useState('');
  const [areaText, setAreaText] = useState('');
  const [topicText, setTopicText] = useState('');
  const [additionalText, setAdditionalText] = useState('');

  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, [subject]);

  useEffect(() => {
    let height = 0;
    if (activeStep === 0 && gradeViewRef.current) {
      height = gradeViewRef.current.offsetHeight;
      console.log('height step 1: ', height);
    } else if (activeStep === 1 && sectionViewRef.current) {
      height = sectionViewRef.current.offsetHeight;
      console.log('height step 2: ', height);
    } else if (activeStep === 2 && topicViewRef.current) {
      height = topicViewRef.current.offsetHeight;
      console.log('height step 3: ', height);
    } else if (activeStep === 3 && isSuccess) {
      height = 330;
      console.log('height success box: ', height);
    } else if (activeStep === 3 && confirmationViewRef.current) {
      height = 490;
      console.log('height step 4: ', height);
    }
    setContainerHeight(height);
  }, [activeStep, selectedGrade, selectedSection, selectedTopic, isSuccess]);

  React.useEffect(() => {
    setSelectedGrade(null);
    setSelectedSection(null);
    setSelectedTopic(null);
    setInstructionText('');
    setActiveStep(0);
    setIsSuccess(false);
  }, [subject]);

  const gradeGroups = ['1-3', '4-6', '7-9'];
  const steps = ['Välj årskurs', 'Välj område', 'Välj ämnesområde', 'Bekräfta val'];

  const handleStepClick = stepIndex => {
    if (stepIndex < activeStep) {
      setActiveStep(stepIndex);
      if (stepIndex === 0) {
        setSelectedGrade(null);
        setSelectedSection(null);
        setSelectedTopic(null);
        setInstructionText('');
        setIsSuccess(false);
      } else if (stepIndex === 1) {
        setSelectedSection(null);
        setSelectedTopic(null);
        setInstructionText('');
        setIsSuccess(false);
      } else if (stepIndex === 2) {
        setSelectedTopic(null);
        setInstructionText('');
        setIsSuccess(false);
      }
    }
  };

  const handleConfirmTopic = async () => {
    const combinedText = `Ämne: ${subjectText}\n\nOmråde: ${areaText}\n\nÄmnesområde: ${topicText}\n\nFler instruktioner: ${additionalText}`;
    onTopicSelect(combinedText);
    setIsSuccess(true);
  };

  const getLoadingText = () => {
    const baseText = 'Lägger till instruktioner för ';
    switch (loadingStep) {
      case 0:
        return baseText + 'ämne...';
      case 1:
        return baseText + 'område...';
      case 2:
        return baseText + 'ämnesområde...';
      default:
        return baseText;
    }
  };

  const handleGradeSelect = grade => {
    setSelectedGrade(grade);
    setActiveStep(1);
  };

  const handleSectionSelect = section => {
    setSelectedSection(section);
    setActiveStep(2);
  };

  const getBackgroundColor = () => {
    return isDark ? 'rgba(255, 255, 255, 0.05)' : 'rgba(223, 222, 225, 0.19)';
  };

  const getHoverBackgroundColor = () => {
    return isDark ? 'rgba(255, 255, 255, 0.1)' : 'rgba(139, 92, 246, 0.1)';
  };

  if (!coursePlans?.[subject]) return null;

  return (
    <Container
      maxWidth='md'
      sx={{
        paddingTop: '24px',
        paddingBottom: '24px',
        backgroundColor: isDark ? 'rgba(255, 255, 255, 0.05)' : 'rgba(252, 252, 252, 0.17)',
        border: isDark ? '1px solid #51585f' : '1px solid #ededed69',
        borderRadius: '10px',
        position: 'relative',
        height: isTopicLoading ? '386px' : 'auto',
        overflow: 'hidden',
      }}
    >
      {(isLoading || isTopicLoading) && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: isDark ? 'rgb(28, 38, 47)' : 'rgba(252, 252, 252, 1)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 10,
            borderRadius: '10px',
          }}
        >
          <Typography
            variant='h6'
            sx={{
              textAlign: 'center',
              color: isDark ? 'rgba(255, 255, 255, 0.9)' : 'rgba(0, 0, 0, 0.8)',
              fontSize: '1.2rem',
              fontWeight: 500,
            }}
          >
            {isLoading
              ? `Laddar kursplan för ${subject.charAt(0).toUpperCase() + subject.slice(1)} från Skolverket...`
              : getLoadingText()}
          </Typography>

          <img
            src='/img/skolverket.png'
            alt='Skolverket'
            style={{
              width: '140px',
              marginBottom: '24px',
              marginTop: '27px',
              display: isLoading || isTopicLoading ? 'block' : 'none',
            }}
          />

          <CircularProgress
            size={32}
            sx={{
              color: '#7b68ee',
              mt: 1,
            }}
          />
        </Box>
      )}

      <Box sx={{ width: '100%', mt: 4 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 5, position: 'relative' }}>
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            sx={{
              flex: 1,
              '& .MuiStepIcon-root': {
                color: 'rgba(0, 0, 0, 0.38)',
              },
              '& .MuiStepIcon-root.Mui-active': {
                color: '#7b68eebf',
              },
              '& .MuiStepIcon-root.Mui-completed': {
                color: '#7b68ee',
              },
              '& .MuiStepLabel-label': {
                fontSize: '0.95rem',
              },
            }}
          >
            {steps.map((label, index) => (
              <Step
                key={label}
                onClick={() => handleStepClick(index)}
                sx={{
                  cursor: index < activeStep ? 'pointer' : 'default',
                  '& .MuiStepLabel-label:hover': {
                    textDecoration: index < activeStep ? 'underline' : 'none',
                  },
                }}
              >
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <Box
          sx={{
            position: 'relative',
            height: containerHeight,
            transition: 'height 300ms ease',
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              transition: 'transform 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
              transform: `translateX(-${activeStep * 100}%)`,
            }}
          >
            {/* Grade Selection */}
            <Box ref={gradeViewRef} sx={{ minWidth: '100%', p: 0 }}>
              <Grid container spacing={3}>
                {gradeGroups.map(grade => (
                  <Grid item xs={12} sm={4} key={grade}>
                    <Card
                      onClick={() => handleGradeSelect(grade)}
                      sx={{
                        cursor: 'pointer',
                        height: '100px',
                        backgroundColor: getBackgroundColor(),
                        borderRadius: '12px',
                        border: isDark ? 'none' : '1px solid #e0e0e080',
                        transition: 'all 0.3s ease',
                        position: 'relative',
                        textAlign: 'center',
                        justifyContent: 'center',
                        boxShadow: 'none',
                        '&:hover': {
                          backgroundColor: getHoverBackgroundColor(),
                        },
                      }}
                    >
                      <CardContent
                        sx={{
                          height: '100%',
                          display: 'flex',
                          boxShadow: 'none',
                          textAlign: 'center',
                          alignItems: 'center',
                          justifyContent: 'center',
                          paddingBottom: '16px !important',
                        }}
                      >
                        <Typography
                          variant='subtitle1'
                          sx={{
                            color: isDark ? 'rgba(255, 255, 255, 0.7)' : '#2c2f33',
                            fontWeight: 600,
                            fontSize: 18,
                            textAlign: 'center',
                          }}
                        >
                          Årskurs {grade}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>

            {/* Section Selection */}
            <Box ref={sectionViewRef} sx={{ minWidth: '100%', p: 0 }}>
              <Grid container spacing={3}>
                {selectedGrade &&
                  coursePlans[subject][selectedGrade].sections.map(section => (
                    <Grid item xs={12} sm={6} key={section.title}>
                      <Card
                        onClick={() => handleSectionSelect(section)}
                        sx={{
                          cursor: 'pointer',
                          height: '100px',
                          backgroundColor: getBackgroundColor(),
                          borderRadius: '12px',
                          border: isDark ? 'none' : '1px solid #e0e0e080',
                          transition: 'all 0.3s ease',
                          justifyContent: 'center',
                          boxShadow: 'none',
                          position: 'relative',
                          '&:hover': {
                            backgroundColor: getHoverBackgroundColor(),
                          },
                        }}
                      >
                        <CardContent
                          sx={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            paddingBottom: '16px !important',
                          }}
                        >
                          <Typography
                            variant='subtitle1'
                            sx={{
                              color: isDark ? 'rgba(255, 255, 255, 0.7)' : '#2c2f33',
                              fontWeight: 600,
                              fontSize: 18,
                            }}
                          >
                            {section.title}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
              </Grid>
            </Box>

            {/* Topic Selection */}
            <Box ref={topicViewRef} sx={{ minWidth: '100%', p: 0 }}>
              <Grid
                container
                spacing={3}
                sx={{
                  height: '100%',
                  alignItems: 'stretch',
                }}
              >
                {selectedSection?.topics.map(topic => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    key={topic}
                    sx={{
                      display: 'flex',
                    }}
                  >
                    <Card
                      onClick={() => {
                        const topicText = `Ämne: ${subject.charAt(0).toUpperCase() + subject.slice(1)}\n\nOmråde: ${selectedSection.title} \n\nÄmnesområde: ${topic}\n\nFler instruktioner: `;

                        setIsTopicLoading(true);
                        setLoadingStep(0);

                        // Create a promise chain for the loading steps
                        const handleLoadingSteps = async () => {
                          const stepDuration = 800;

                          await new Promise(resolve => setTimeout(resolve, stepDuration));
                          setLoadingStep(1);

                          await new Promise(resolve => setTimeout(resolve, stepDuration));
                          setLoadingStep(2);

                          await new Promise(resolve => setTimeout(resolve, stepDuration));
                          setLoadingStep(3);

                          // Only update the view after loading is complete

                          setSelectedTopic(topic);
                          setSubjectText(subject.charAt(0).toUpperCase() + subject.slice(1));
                          setAreaText(selectedSection.title);
                          setTopicText(topic);
                          setActiveStep(3);
                          setIsTopicLoading(false);
                          setActiveStep(3);
                        };

                        handleLoadingSteps();
                      }}
                      sx={{
                        cursor: 'pointer',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: getBackgroundColor(),
                        borderRadius: '12px',
                        border: isDark ? 'none' : '1px solid #e0e0e080',
                        transition: 'all 0.3s ease',
                        position: 'relative',
                        boxShadow: 'none',
                        '&:hover': {
                          backgroundColor: getHoverBackgroundColor(),
                        },
                      }}
                    >
                      <CardContent
                        sx={{
                          flexGrow: 1,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          p: 3,
                        }}
                      >
                        <Typography
                          variant='subtitle1'
                          sx={{
                            color: isDark ? 'rgba(255, 255, 255, 0.7)' : '#2c2f33',
                            fontWeight: 600,
                            fontSize: 15,
                          }}
                        >
                          {topic}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>

            {/* Confirmation Step */}
            <Box ref={confirmationViewRef} sx={{ minWidth: '100%', p: 0, height: '0px' }}>
              <Box sx={{ textAlign: 'center', p: 3 }}>
                {!isSuccess ? (
                  <>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant='subtitle2' sx={{ mb: 1, textAlign: 'left' }}>
                          Ämne:
                        </Typography>
                        <TextField
                          fullWidth
                          value={subjectText}
                          onChange={e => setSubjectText(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant='subtitle2' sx={{ mb: 1, textAlign: 'left' }}>
                          Område:
                        </Typography>
                        <TextField
                          fullWidth
                          value={areaText}
                          onChange={e => setAreaText(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant='subtitle2' sx={{ mb: 1, mt: 2, textAlign: 'left' }}>
                          Ämnesområde:
                        </Typography>
                        <TextField
                          fullWidth
                          multiline
                          rows={2}
                          value={topicText}
                          onChange={e => setTopicText(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant='subtitle2' sx={{ mb: 1, mt: 2, textAlign: 'left' }}>
                          Fler instruktioner:
                        </Typography>
                        <TextField
                          fullWidth
                          placeholder='T.ex. fokusera enbart på första världskriget.'
                          value={additionalText}
                          onChange={e => setAdditionalText(e.target.value)}
                        />
                      </Grid>
                    </Grid>

                    <Button variant='purp' onClick={handleConfirmTopic} sx={{ mt: 4 }}>
                      Bekräfta <ArrowIcon sx={{ ml: 1 }} />
                    </Button>
                  </>
                ) : (
                  <Box sx={{ ...styles.successBox }}>
                    <CheckCircleOutline sx={{ fontSize: 60, color: 'success.main', mb: 3 }} />
                    <Typography
                      variant='h6'
                      sx={{
                        color: 'success.main',
                        fontWeight: 500,
                        textAlign: 'center',
                        width: '80%',
                        margin: '0 auto',
                        mb: 3,
                      }}
                    >
                      Ämnet är nu valt och du kan fortsätta med att generera{' '}
                      {forQuiz ? 'ditt quiz' : 'din lektion'} nedan.
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Tooltip title='Läs mer om kursplanen' placement='bottom'>
          <Box
            onClick={() => setDialogOpen(true)}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 1,
              mt: 6,
              cursor: 'pointer',
              width: '200px',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <Typography
              variant='body2'
              sx={{
                textTransform: 'uppercase',
                textAlign: 'center',
                fontSize: '0.8rem',
                fontWeight: '500',
              }}
            >
              Kursplan från:
            </Typography>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                '&:hover': {
                  opacity: 0.8,
                },
              }}
            >
              <img src='/img/skolverket.png' width='140px' alt='Kursplan från Skolverket' />
            </Box>
          </Box>
        </Tooltip>
      </Box>

      {/* Course Plan Info Dialog */}
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} maxWidth='md' fullWidth>
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            pb: 0,
            fontSize: '2rem',
            fontWeight: 700,
            textAlign: 'center',
            pt: 3,
          }}
        >
          Kursplan — {subject.charAt(0).toUpperCase() + subject.slice(1)}
          <IconButton
            onClick={() => setDialogOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ textAlign: 'center', mb: 6, mt: 4 }}>
            <img src='/img/skolverket.png' alt='Kursplan från Skolverket' />
          </Box>

          <style>
            {`
              .guide-content h1 { font-size: 27px; margin-bottom: 16px; }
              .guide-content h2 { font-size: 23px; margin-top: 44px; margin-bottom: 12px; }
              .guide-content p { font-size: 16px; line-height: 1.6; }
              .guide-content strong { color: #333; }
              .guide-content ul { padding-left: 20px; margin-top: 12px; margin-bottom: 12px; }
              .guide-content li { font-size: 16px; line-height: 1.6; margin-bottom: 8px; }
            `}
          </style>
          <div
            className='guide-content'
            dangerouslySetInnerHTML={{ __html: coursePlans[subject]?.courePlanText || '' }}
          />

          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4, mb: 6 }}>
            <Button
              variant={theme.palette.mode === 'light' ? 'purp' : 'contained'}
              color='primary'
              startIcon={<LaunchIcon />}
              href={coursePlans[subject]?.coursePlanLink}
              target='_blank'
              sx={{
                textTransform: 'none',
                px: 4,
                py: 1,
              }}
            >
              Visa kursplan på Skolverket
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Container>
  );
};
